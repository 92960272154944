import styled from "styled-components/macro";

import { Maybe, Plan } from "src/network/graphql/generatedGraphqlSDK";

import { styleProp, themeColor } from "src/utils/styledComponents.utils";

const Container = styled.div<{ buttonColor?: string | null; buttonAdditionalColor?: string | null }>`
  width: 100%;
  height: 44px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${styleProp("buttonColor", themeColor("gray.500"))};

  border-radius: 24px;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  cursor: pointer;
  color: ${themeColor("white")};

  &:hover {
    background-color: ${styleProp("buttonAdditionalColor", themeColor("gray.500"))};
  }

  transition: background-color 250ms ease-out;
`;

interface PlanButtonProps {
  plan?: Maybe<Plan>;
  text?: Maybe<string>;
  buttonAction?: (plan: Plan) => void;
  buttonColor?: Maybe<string>;
  buttonAdditionalColor?: Maybe<string>;
}

export default function PlanButton({ plan, text, buttonAction, buttonColor, buttonAdditionalColor }: PlanButtonProps) {
  const onButtonClick = () => {
    if (plan && buttonAction) {
      buttonAction(plan);
    }
  };

  return (
    <Container
      buttonColor={buttonColor}
      buttonAdditionalColor={buttonAdditionalColor}
      onClick={onButtonClick}
      data-cy="plan-button"
    >
      {text}
    </Container>
  );
}
