import { components, SingleValueProps } from "react-select";
import Flag from "react-world-flags";

import { FlagWrapper } from "src/components/common/LanguageSelector/LanguageSelectOption";
import { Stack } from "src/components/common/layout/Stack.styled";
import { LanguageOption } from "src/components/providers/AppConfigProvider";
import { themeColor } from "src/utils/styledComponents.utils";
import styled from "styled-components/macro";
import { ifProp } from "styled-tools";

const StyledSelectStack = styled(Stack)<{ darkBackground: boolean }>`
  align-items: center;
  height: 26px;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: 600;
  color: ${ifProp("darkBackground", themeColor("white"), themeColor("gray.900"))};
`;

export default function LanguageSelectSingleValue({ selectProps, ...props }: SingleValueProps<LanguageOption>) {
  //  @ts-ignore
  const { darkBackground } = selectProps;
  const { getValue, hasValue, children } = props;
  const [languageOption] = getValue();

  return (
    <components.SingleValue {...props} selectProps={selectProps}>
      <StyledSelectStack direction="row" spacing={12} darkBackground={darkBackground}>
        {hasValue ? (
          <>
            <FlagWrapper>
              <Flag code={languageOption.languageCode.split("-")[1]} />
            </FlagWrapper>
            <span>{languageOption.label}</span>
          </>
        ) : (
          children
        )}
      </StyledSelectStack>
    </components.SingleValue>
  );
}
