import styled from "styled-components/macro";

import { themeColor } from "src/utils/styledComponents.utils";

import ZoomedTimeInterval from "src/components/features/VideoTrimmer/TimelineZoomer/ZoomedTimeInterval";

// TODO: rename
const Timeline = styled.div`
  position: relative;
  width: 100%;
  height: 6px;
  border-radius: 5px;
  background-color: ${themeColor("gray.275")};
`;

interface TimelineZoomerProps {
  pixelPerSecondRatio: number;
}

export default function TimelineZoomer({ pixelPerSecondRatio }: TimelineZoomerProps) {
  return (
    <Timeline>
      <ZoomedTimeInterval pixelPerSecond={pixelPerSecondRatio} />
    </Timeline>
  );
}
