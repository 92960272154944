import { Footage, FootageOrigin } from "src/network/graphql/generatedGraphqlSDK";

import { themeColor, themeZ } from "src/utils/styledComponents.utils";

import styled from "styled-components/macro";

import Icon from "src/components/common/Icon";
import FootagePreviewItem from "src/components/features/SmartLibrary/footagesLibrary/FootagePreviewItem";
import { useUploaderContext } from "src/components/providers/UploaderProvider";
import { FileUploadStatus } from "src/services/BaseUpload.service";
import { AppearAnimatedDiv } from "src/components/features/AutomaticCreateWizard/common/automatic-create-wizard-layout.styled";

const FootageItemWrapper = styled(AppearAnimatedDiv)`
  position: relative;
  padding-top: 20px;
  padding-inline: 10px;

  @media (min-width: 768px) {
    padding-inline: 0 30px;
  }
`;

const DeleteButton = styled.div`
  position: absolute;
  top: 12px;
  right: 0px;
  width: 20px;
  height: 20px;
  background-color: ${themeColor("blue.700")};
  border-radius: 50%;
  z-index: ${themeZ("footageDeleteButton")};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    circle {
      color: ${themeColor("blue.700")};
    }
  }

  @media (min-width: 768px) {
    right: 22px;
  }
`;

interface FootageItemProps {
  footage: {
    footageId: string;
    languageCode: string;
    thumbnailUrl: string | null | undefined;
    origin: FootageOrigin;
  };
  onUploadedFootageDelete: (footageId: string) => void;
  onLanguageSubmit: (footage: Footage, selectedLanguage: string) => void;
}

export default function FootageItem({ footage, onUploadedFootageDelete, onLanguageSubmit }: FootageItemProps) {
  const { getUploader } = useUploaderContext();
  const uploader = getUploader(footage.footageId);
  const isDeleteButtonEnabled =
    uploader?.status === FileUploadStatus.PENDING ||
    uploader?.status === FileUploadStatus.UPLOADING ||
    footage.origin === FootageOrigin.Youtube;

  return (
    <FootageItemWrapper>
      {isDeleteButtonEnabled && (
        <DeleteButton
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onUploadedFootageDelete(footage.footageId as string);
          }}
        >
          <Icon.CircledX />
        </DeleteButton>
      )}

      <FootagePreviewItem
        key={footage.footageId}
        footageId={footage.footageId as string}
        onLanguageSubmit={onLanguageSubmit}
        displayFooter={false}
        displayProgress={false}
        allowClick={false}
      />
    </FootageItemWrapper>
  );
}
