import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { createModel } from "@rematch/core";
import { compact, merge, orderBy, set, union, uniq, uniqBy } from "lodash/fp";

import { AspectRatio } from "src/constants/video.constants";

import { RootModel } from "src/models/index";
import { selectId } from "src/models/selectId";
import { RootState } from "src/models/store";

import apiClient from "src/network/ApiClient";
import * as gql from "src/network/graphql/generatedGraphqlSDK";
import { FootageStatus } from "src/network/graphql/generatedGraphqlSDK";

import { differenceInDays } from "date-fns";
import { ASPECT_RATIO_OPTIONS_DICT } from "src/components/features/SmartLibrary/smartLibrary.constants";
import { ClipSequenceFilters } from "src/components/features/SmartLibrary/smartLibrary.types";
import { checkIfNeedToReplaceExampleSequence } from "src/components/features/SmartLibrary/smartLibrary.utils";
import { planSelectors } from "src/models/Plan.model";
import { getLocaleTextDirection } from "src/utils/localization.utils";
import { FOOTAGE_PREVIEW_PATH } from "src/constants/url.constants";

type LeanSequence = {
  sid: gql.Maybe<string>;
  title: string;
  type: gql.ClipSequenceType;
  status: gql.ClipSequenceStatus;
  aspectRatio: AspectRatio;
  languageCode: string;
  favorite: boolean;
  downloadedAt: string;
};

type SequencesByAspectRatio = PartialRecord<AspectRatio, LeanSequence[]>;

type SequencesByTypeAndRatio = PartialRecord<gql.ClipSequenceType, SequencesByAspectRatio>;

const footageAdapter = createEntityAdapter<gql.Footage>({ selectId });
const footageAdapterSelectors = footageAdapter.getSelectors((state: RootState) => state.footage);

const selectFootageOrigin = createSelector(footageAdapterSelectors.selectById, (footage) => footage?.origin);

const selectHasFootageScoring = createSelector(footageAdapterSelectors.selectById, (footage) =>
  footage?.clips?.some((clip) => {
    // TODO: remove @ts-ignote when API is ready
    // @ts-ignore
    const clipScore = clip?.score;
    // TODO: remove @ts-ignote when API is ready
    // @ts-ignore
    const firstOriginalScore = clip.sequences?.find((seq) => seq.type === gql.ClipSequenceType.Highlight)?.score;
    return !!(clipScore || firstOriginalScore);
  }),
);

// get sequence of clip by clips id and sequence id
const selectClipSequence = createSelector(
  footageAdapterSelectors.selectById,
  (state: RootState, footageId: string, clipId: string) => clipId,
  (state: RootState, footageId: string, clipId: string, clipSequenceId: string) => clipSequenceId,
  (footage, clipId, clipSequenceId) =>
    footage?.clips?.find((clip) => clip.id === clipId)?.sequences?.find((seq) => seq.id === clipSequenceId),
);

const selectClipDataByFootageAndExternalSequenceSid = createSelector(
  (state: RootState, footage: gql.Footage) => footage,
  (state: RootState, footage: gql.Footage, externalSequenceSidId: string) => externalSequenceSidId,
  (footage, externalSequenceSidId) => {
    const clip = footage?.clips?.find((cl: gql.Clip) =>
      cl?.sequences?.find((seq) => seq.externalSequenceId === externalSequenceSidId),
    );
    const clipSequenceId = clip?.sequences?.find((seq) => seq.externalSequenceId === externalSequenceSidId)?.id;
    return { clipId: clip?.id, clipSequenceId, clipFrom: clip?.clipFrom, duration: clip?.duration, type: clip?.type };
  },
);

const selectFilteredClips = createSelector(
  footageAdapterSelectors.selectById,
  (state: RootState, footageId: string, filterScoring: string[]) => filterScoring,
  (footage, filterScoring) => {
    const scoreRanges = filterScoring.map((rangeStr: string) => {
      const [start, end] = rangeStr.split("-").map(Number);

      return [start, end] as const;
    });

    if (!scoreRanges.length) {
      return footage?.clips;
    }

    return footage?.clips?.filter((clip) => {
      // TODO: remove @ts-ignote when API is ready
      // @ts-ignore
      const clipScore = clip?.score;
      // TODO: remove @ts-ignote when API is ready
      // @ts-ignore
      const firstOriginalScore = clip.sequences?.find((seq) => seq.type === gql.ClipSequenceType.Highlight)?.score;
      if (!clipScore && !firstOriginalScore) {
        return footage?.clips;
      }
      return scoreRanges.some(
        (scoreRange) => clipScore >= scoreRange[0] && (clipScore ?? firstOriginalScore) <= scoreRange[1],
      );
    });
  },
);

const selectThumbnailUrl = createSelector(footageAdapterSelectors.selectById, (footage) => {
  gql.FootageOrigin.CreateProjectManually;

  if (
    footage?.thumbnail?.filePath &&
    (footage?.origin === gql.FootageOrigin.CreateProjectManually || footage?.origin === gql.FootageOrigin.Archive)
  ) {
    // TODO: change when API returns correct thumbnail
    // currently it returns thumbnail from the first clip
    // set assetId to the thumbnail.filePath
    const assetId = footage?.thumbnail?.filePath;
    return `/c/asset/${assetId}/Content/1/16-9/1.png`;
  }
  if (footage?.thumbnail?.filePath) {
    return `/c/footage/${footage?.id}/thumbnail.png`;
  }
  return null;
});

const selectSequencesLanguages = createSelector(footageAdapterSelectors.selectById, (footage) => {
  const languageCodes = footage?.clips?.map((clip) => clip.sequences?.map((seq) => seq?.languageCode)).flat();

  return uniq(compact(languageCodes));
});

const selectSequencesDurationRange = createSelector(
  (state: RootState) => state,
  (state: RootState) => state.sequences.entities,
  (state: RootState, footage: gql.Footage) => footage,
  (state, sequencesDict, footage) => {
    const seqExtIds: string[] = [];

    footage.clips?.forEach((clip) =>
      clip.sequences?.forEach((seq) => seq?.externalSequenceId && seqExtIds.push(seq.externalSequenceId)),
    );
    const durations: number[] = [];
    seqExtIds.forEach((extId) => {
      const externalSequenceDuration = sequencesDict[extId]?.duration;
      externalSequenceDuration && durations.push(externalSequenceDuration);
    });
    if (durations.length) {
      const minDuration = Math.min(...(durations as number[]));
      const maxDuration = Math.max(...(durations as number[]));
      return { minDuration, maxDuration };
    }
    return null;
  },
);

const filteredSequences = (
  sequences: { [key: string]: any }[],
  currentFilters: ClipSequenceFilters,
  durationRange: { minDuration: number; maxDuration: number } | null,
) =>
  sequences.filter(
    (seq) =>
      (!currentFilters.language.length || (seq.languageCode && currentFilters.language.includes(seq.languageCode))) &&
      (!currentFilters.aspectRatio.length || currentFilters.aspectRatio.includes(seq.ratio)) &&
      (!currentFilters.duration ||
        (!seq.duration && (durationRange && currentFilters.duration[0] === durationRange.minDuration &&
          currentFilters.duration[1] === durationRange.maxDuration)) ||
        (seq.duration && seq.duration >= currentFilters.duration[0] && seq.duration <= currentFilters.duration[1])) &&
      (!currentFilters.socialMedia.length ||
        currentFilters.socialMedia.some((media) => ASPECT_RATIO_OPTIONS_DICT[seq.ratio as AspectRatio].supportedSocialMedias.includes(media))) &&
      (!currentFilters.onlyFavorites || seq.favorite), // prettier-ignore
  ) ?? [];

const selectRecentlyEditedSequences = createSelector(
  (state: RootState, footage: gql.Footage) => footage,
  (state: RootState) => state.sequences.entities,
  (state: RootState, footage: gql.Footage, currentFilters: ClipSequenceFilters) => currentFilters,
  (
    state: RootState,
    footage: gql.Footage,
    currentFilters: ClipSequenceFilters,
    durationRange: { minDuration: number; maxDuration: number } | null,
  ) => durationRange,
  (
    state: RootState,
    footage: gql.Footage,
    currentFilters: ClipSequenceFilters,
    durationRange: { minDuration: number; maxDuration: number } | null,
    amount: number,
  ) => amount,
  (footage, sequencesDict, currentFilters, durationRange, amount) => {
    const allEditedSequences: { [key: string]: any }[] = [];

    footage.clips?.forEach((clip) =>
      clip.sequences?.forEach((seq) => {
        if (seq.editedAt) {
          allEditedSequences.push({ ...sequencesDict[seq.externalSequenceId!], ...seq });
        }
      }),
    );

    const filteredAndSlicedSequences = filteredSequences(allEditedSequences, currentFilters, durationRange);

    if (filteredAndSlicedSequences) {
      return filteredAndSlicedSequences
        .sort((a, b) => (new Date(b.editedAt) as any) - (new Date(a.editedAt) as any))
        ?.splice(0, amount);
    }

    return [];
  },
);

const selectFilteredSequencesByTypeAndRatio = createSelector(
  (state: RootState, clip: gql.Clip) => clip.sequences,
  (state: RootState) => state.sequences.entities,
  (state: RootState, clip: gql.Clip, currentFilters: ClipSequenceFilters) => currentFilters,
  (
    state: RootState,
    clip: gql.Clip,
    currentFilters: ClipSequenceFilters,
    durationRange: { minDuration: number; maxDuration: number } | null,
  ) => durationRange,
  (clipSequences, sequencesDict, currentFilters, durationRange) => {
    const sequences = clipSequences?.map((seq) => ({ ...sequencesDict[seq.externalSequenceId!], ...seq }));

    if (!sequences) {
      return {};
    }

    const filtered = filteredSequences(sequences, currentFilters, durationRange);

    return filtered?.reduce<SequencesByTypeAndRatio>((acc, seq) => {
      const { externalSequenceId: sid, type, ratio: aspectRatio, id: seqId, languageCode, favorite } = seq;
      return {
        ...acc,
        [type]: {
          ...(acc[type as keyof typeof acc] ?? {}),
          [aspectRatio]: [
            ...(acc[type as keyof typeof acc]?.[aspectRatio as AspectRatio] ?? []),
            {
              ...seq,
              sid,
              type,
              aspectRatio,
              id: seqId,
              languageCode,
              favorite,
            },
          ],
        },
      };
    }, {});
  },
);

const selectHasNextPage = (state: RootState) => state.footage.pagination.hasMore;

const selectFootagesByIds = createSelector(
  footageAdapterSelectors.selectEntities,
  (state: RootState, footageIds: string[]) => footageIds,
  (footages, footageIds) => {
    const arr: gql.Footage[] = [];
    footageIds.forEach((id) => footages[id] && arr.push(footages[id] as gql.Footage));
    return arr;
  },
);

const selectIsFootageOlderThanDaysAmount = createSelector(
  (state: RootState) => state,
  footageAdapterSelectors.selectById,
  (state: RootState, footageId: string, expDaysAmount: number) => expDaysAmount,
  (state, footage, daysAmount) => {
    const showExpiredInfo = planSelectors.selectIsTierFeatureDisallowed(state, "UNLOCK_FOOTAGE_EXPIRED");

    if (showExpiredInfo && footage?.createdAt) {
      const daysSinceCreation = differenceInDays(new Date(), new Date(footage.createdAt));
      return daysSinceCreation >= daysAmount;
    }

    return false;
  },
);

const selectVideoSrc = createSelector(
  (state: RootState) => state,
  (state: RootState, footageId: string) => footageId,
  (state: RootState, sequenceSid: string, baseURL: string) => baseURL,
  (state, footageId, baseURL) => {
    const token = encodeURIComponent(encodeURIComponent(state.session.authToken));
    return `${baseURL}/${FOOTAGE_PREVIEW_PATH}/${footageId}/ps/${token}/manifest.mpd`;
  },
);

const selectFootageTextDirection = createSelector(footageAdapterSelectors.selectById, (footage) => {
  if (!footage?.languageCode) {
    return "ltr";
  }

  return getLocaleTextDirection(footage.languageCode);
});

export const footageSelectors = {
  ...footageAdapterSelectors,
  selectThumbnailUrl,
  selectClipSequence,
  selectFilteredClips,
  selectRecentlyEditedSequences,
  selectFilteredSequencesByTypeAndRatio,
  selectSequencesDurationRange,
  selectSequencesLanguages,
  selectHasNextPage,
  selectHasFootageScoring,
  selectFootagesByIds,
  selectFootageOrigin,
  selectIsFootageOlderThanDaysAmount,
  selectClipDataByFootageAndExternalSequenceSid,
  selectVideoSrc,
  selectFootageTextDirection,
};

interface FootageModelState {
  ids: string[];
  currentBoardId: string | null;
  entities: Record<string, gql.Footage>;
  pagination: {
    isInitialized: boolean;
    footageIds: string[];
    hasMore: boolean;
  };
}

const initialState = {
  ...footageAdapter.getInitialState({}),
  currentBoardId: null,
  pagination: {
    isInitialized: false,
    footageIds: [],
    hasMore: false,
  },
} as FootageModelState;

const footage = createModel<RootModel>()({
  state: initialState,

  reducers: {
    setFootages: (state, payload: gql.Footage[]) => footageAdapter.addMany(state, payload),

    setFootagesPagination: (state, payload: { footageIds: string[]; hasMore: boolean }) => {
      const uniqueFootageIds = new Set([...state.pagination.footageIds, ...payload.footageIds]);
      const uniqueFootageIdsArray = Array.from(uniqueFootageIds);

      return {
        ...state,
        pagination: {
          isInitialized: true,
          footageIds: uniqueFootageIdsArray,
          hasMore: payload.hasMore,
        },
      };
    },

    setFootage: (state, payload: gql.Footage) => {
      const newState = footageAdapter.setOne(state, payload);

      const isNewFootage =
        newState.pagination.isInitialized &&
        (!state.entities[payload.id] || new Date(payload.createdAt) > new Date(state.entities[payload.id]?.createdAt));

      return {
        ...newState,
        pagination: isNewFootage
          ? {
              ...newState.pagination,
              footageIds: uniq([payload.id, ...newState.pagination.footageIds]),
            }
          : newState.pagination,
      };
    },

    addNewFootage: (state, payload: gql.Footage) => ({
      ...state,
      entities: {
        ...state.entities,
        [payload.id]: payload,
      },
      pagination: {
        ...state.pagination,
        footageIds: orderBy(
          (id) => new Date(id === payload.id ? payload.createdAt : state.entities[id].createdAt),
          "desc",
          [payload.id, ...state.pagination.footageIds],
        ),
      },
    }),

    setUpdatedFootage: (state, payload: gql.Footage) => {
      if (payload.status === FootageStatus.Deleted || payload.status === FootageStatus.UploadingAndDeleted) {
        return state;
      }

      const firstFootageId = state.pagination.footageIds[0];
      const firstFootage = state.entities[firstFootageId];

      const newFootageCreationDate = new Date(payload.createdAt);
      const firstFootageCreationDate = new Date(firstFootage?.createdAt);
      const isNew =
        state.pagination.isInitialized && (!firstFootage || newFootageCreationDate > firstFootageCreationDate);

      const updatedFootage = merge(state.entities[payload.id], payload);
      updatedFootage.clips = updatedFootage?.clips?.map((clip) => ({
        ...clip,
        sequences: uniqBy("id", clip.sequences),
      }));

      return {
        ...state,

        entities: {
          ...state.entities,
          [payload.id]: updatedFootage,
        },

        pagination: isNew
          ? {
              ...state.pagination,
              footageIds: union(payload.id, state.pagination.footageIds),
            }
          : state.pagination,
      };
    },

    removeFootage: (state, key: string) => {
      const nextState = {
        ...state,
        pagination: {
          ...state.pagination,
          footageIds: state.pagination.footageIds.filter((id) => id !== key),
        },
      };

      return footageAdapter.removeOne(nextState, key);
    },

    changeClipSequenceFavorite: (
      state,
      payload: { footageId: string; clipId: string; id: string; favorite: boolean },
    ) => {
      const currentFootage = state.entities[payload.footageId];
      const clipIndex = currentFootage?.clips?.findIndex((item: gql.Clip) => item.id === payload.clipId);
      const sequenceIndex = currentFootage?.clips?.[clipIndex ?? -1]?.sequences?.findIndex(
        (seq: gql.ClipSequence) => seq.id === payload.id,
      );

      return {
        ...state,
        entities: set(
          [payload.footageId, "clips", clipIndex!, "sequences", sequenceIndex!, "favorite"],
          payload.favorite,
          state.entities,
        ),
      };
    },

    setUpdatedClipSequence: (
      state,
      payload: { footageId: string; externalSequenceId: string; clipSequence: gql.ClipSequence },
    ) => {
      const currentFootage = state.entities[payload.footageId];
      const clipIndex = currentFootage?.clips?.findIndex((item: gql.Clip) =>
        item.sequences?.some((seq: gql.ClipSequence) => seq.externalSequenceId === payload.externalSequenceId),
      );
      const sequenceIndex = currentFootage?.clips?.[clipIndex ?? -1]?.sequences?.findIndex(
        (seq: gql.ClipSequence) => seq.externalSequenceId === payload.externalSequenceId,
      );

      return {
        ...state,
        entities: set(
          [payload.footageId, "clips", clipIndex!, "sequences", sequenceIndex!],
          payload.clipSequence,
          state.entities,
        ),
      };
    },

    removeClipSequence: (state, payload: gql.DeleteClipSequenceMutationVariables) => {
      const currentFootage = state.entities[payload.footageId];
      const clipIndex = currentFootage?.clips?.findIndex((item: gql.Clip) => item.id === payload.clipId);

      return {
        ...state,
        entities: set(
          [payload.footageId, "clips", clipIndex!, "sequences"],
          currentFootage?.clips?.[clipIndex ?? -1]?.sequences?.filter((seq: gql.ClipSequence) => seq.id !== payload.id),
          state.entities,
        ),
      };
    },

    reset: () => initialState,

    setCurrentBoardId: (state, currentBoardId: string | null) => ({
      ...state,
      currentBoardId,
    }),
  },

  effects: (dispatch) => ({
    async fetchFootages(variables: gql.GetFootagesQueryVariables & { boardId?: string | null }) {
      const res = await apiClient.getFootages(variables);
      dispatch.footage.setFootages(res.nodes);
      dispatch.footage.setFootagesPagination({
        footageIds: res.nodes.map((x) => x.id),
        hasMore: !!res.pageInfo.hasNextPage,
      });
      dispatch.footage.setCurrentBoardId(variables?.boardId || null);
      return res.nodes.length;
    },

    async fetchFootagesByBoardId(variables: gql.GetFootagesByBoardIdQueryVariables) {
      const res = await apiClient.getFootagesByBoardId(variables);
      dispatch.footage.setFootages(res.nodes);
      dispatch.footage.setFootagesPagination({
        footageIds: res.nodes.map((x) => x.id),
        hasMore: !!res.pageInfo.hasNextPage,
      });
      dispatch.footage.setCurrentBoardId(variables?.boardId || null);
      return res.nodes.length;
    },

    async fetchFootage(id) {
      const res = await apiClient.getFootage({ id });
      dispatch.footage.setFootage(res);
    },

    async createFootage(input: gql.CreateOneFootageInput) {
      const res = await apiClient.createFootage({ input });
      return res;
    },

    async updateFootage(input: gql.UpdateOneFootageInput) {
      const res = await apiClient.updateFootage({ input });

      dispatch.footage.setFootage(res);
    },

    async deleteFootage(input: gql.DeleteOneFootageInput, state) {
      const res = await apiClient.deleteFootage({ input });
      const offset = state.footage.ids.length - 1;

      dispatch.footage.removeFootage(res.id);

      if (state.footage.pagination.hasMore) {
        dispatch.footage.fetchFootages({
          sorting: {
            field: gql.FootageSortFields.CreatedAt,
            direction: gql.SortDirection.Desc,
          },
          paging: {
            offset,
            limit: 1,
          },
          filter: {
            status: {
              notIn: [FootageStatus.Deleted, FootageStatus.FailedAndDeleted, FootageStatus.UploadingAndDeleted],
            },
          },
        });
      }
    },

    async updateClipSequence(variables: gql.UpdateClipSequenceMutationVariables) {
      const res = await apiClient.updateClipSequence(variables);
      dispatch.footage.fetchFootage(variables.footageId);
      return res;
    },

    async deleteClipSequence(variables: gql.DeleteClipSequenceMutationVariables) {
      await checkIfNeedToReplaceExampleSequence(dispatch, variables.footageId, variables.clipId, variables.id);
      const res = await apiClient.DeleteClipSequence(variables);
      dispatch.footage.removeClipSequence(variables);
      return res;
    },

    async cloneClipSequence(variables: gql.CloneClipSequenceMutationVariables) {
      await checkIfNeedToReplaceExampleSequence(dispatch, variables.footageId, variables.clipId, variables.id);
      const res = await apiClient.cloneClipSequence(variables);
      return res;
    },

    async translateClipSequence(variables: gql.TranslateClipSequenceMutationVariables) {
      await checkIfNeedToReplaceExampleSequence(dispatch, variables.footageId, variables.clipId, variables.id);
      const res = await apiClient.translateClipSequence(variables);
      return res;
    },

    async replaceExampleClipSequence(variables: gql.ReplaceExampleClipSequenceMutationVariables) {
      const res = await apiClient.replaceExampleClipSequence(variables);
      return res;
    },

    async renameClipSequence(variables: gql.RenameClipSequenceMutationVariables) {
      await checkIfNeedToReplaceExampleSequence(dispatch, variables.footageId, variables.clipId, variables.id);
      const res = await apiClient.renameClipSequence(variables);
      return res;
    },

    async addClipSequenceFavorite(variables: gql.AddClipSequenceFavoriteMutationVariables) {
      dispatch.footage.changeClipSequenceFavorite({
        footageId: variables.footageId,
        clipId: variables.clipId,
        id: variables.id,
        favorite: true,
      });
      await apiClient.addClipSequenceFavorite(variables);
    },

    async deleteClipSequenceFavorite(variables: gql.DeleteClipSequenceFavoriteMutationVariables) {
      dispatch.footage.changeClipSequenceFavorite({
        footageId: variables.footageId,
        clipId: variables.clipId,
        id: variables.id,
        favorite: false,
      });
      await apiClient.deleteClipSequenceFavorite(variables);
    },

    async interactClipSequence(variables: gql.InteractClipSequenceMutationVariables) {
      const clipSequence = await apiClient.interactClipSequence(variables);
      dispatch.footage.setUpdatedClipSequence({
        footageId: variables.footageId,
        externalSequenceId: variables.externalSequenceId,
        clipSequence,
      });
    },

    async splitFootageByUserTimestamps(variables: gql.SplitFootageByUserTimestampsMutationVariables) {
      const res = await apiClient.splitFootageByUserTimestamps(variables);
      return res;
    },
  }),
});

export default footage;
