import { useMemo, useRef } from "react";
import { ChapterTimeInterval, Word } from "src/types/video-trimmer.types";
import { themeColor } from "src/utils/styledComponents.utils";
import useIsVideoLoading from "src/hooks/useIsVideoLoading";
import { UseHistoryStateReturnValue } from "src/hooks/useHistoryState";
import { Stack } from "src/components/common/layout/Stack.styled";
import { Center, FlexBox } from "src/components/common/layout/Box.styled";
import { CircularLoader } from "src/components/common/loaders/CircularLoader";
import ShakaVideoPlayer from "src/components/common/media/ShakaVideoPlayer/ShakaVideoPlayer";
import VideoTranscriptProvider from "src/components/features/VideoTrimmer/providers/VideoTranscriptProvider/VideoTranscriptProvider";
import VideoWordsProvider from "src/components/features/VideoTrimmer/providers/VideoWordsProvider/VideoWordsProvider";
import VideoChaptersProvider from "src/components/features/VideoTrimmer/providers/VideoChaptersProvider/VideoChaptersProvider";
import VideoTranscript from "src/components/features/VideoTrimmer/VideoTranscript/VideoTranscript";
import * as footagePreviewPlayerAnalytics from "src/analytics/footagePreviewPlayer.analytics";
import { InitialHistoryState } from "src/components/features/VideoTrimmer/common/hooks/useInitiatedHistory";
import { TextDirection } from "src/utils/localization.utils";
import styled from "styled-components/macro";
import FootageVideoControls from "src/components/features/FootagePlayer/FootageVideoControls";
import VideoPlaybackShortcuts from "src/components/features/VideoTrimmer/shortcuts/VideoPlaybackShortcuts";
import Icon from "src/components/common/Icon";
import { absoluteRoutes, applyPathParams } from "src/utils/routes.utils";
import { useNavigate } from "react-router-dom";
import { useFootagePreviewItemLoadingState } from "src/hooks/useFootagePreviewItemLoadingState";
import { AspectRatio, getAspectRatioValue } from "src/constants/video.constants";
import AutoSizer from "src/components/common/layout/AutoSizer";
import { Text2 } from "src/components/common/layout/typography.styled";
import * as footageAnalytics from "src/analytics/footage.analytics";
import VideoCurrentTimeProvider from "src/components/features/VideoTrimmer/providers/VideoCurrentTimeProvider/VideoCurrentTimeProvider";
import TranscriptActions from "src/components/features/VideoTrimmer/VideoTranscript/TranscriptActions";
import VideoPlaybackProvider from "src/components/features/VideoTrimmer/providers/VideoPlaybackProvider/VideoPlaybackProvider";
import TimelineZoomProvider from "src/components/features/VideoTrimmer/providers/TimelineZoomProvider/TimelineZoomProvider";
import { VerticalDivider } from "src/components/features/SmartLibrary/SmartLibrary.styled";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Body = styled(Stack)`
  flex: 1;
  overflow: hidden;
  padding-inline: 40px;
  width: 100vw;
`;

const TranscriptColumn = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-block: 50px;
  flex: 0.6;
  overflow: hidden;
`;

const VideoColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.4;
  overflow: hidden;
`;

const VideoPlayerContainer = styled.div`
  container-name: VideoPlayerContainer;
  container-type: inline-size;
  position: relative;
  margin: auto;
  outline: 1px solid ${themeColor("gray.300")};
  width: 100%;
`;

const NoPreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 30%;
  padding: 20px;
  border: 1px solid ${themeColor("gray.300")};
  text-align: center;
`;

const StyledShakaVideoPlayer = styled(ShakaVideoPlayer)``;

const CircularLoaderContainer = styled(Center)`
  position: absolute;
  inset: 0;
`;

const Header = styled(FlexBox)`
  background-color: ${themeColor("gray.50")};
  padding: 6px 32px;
  align-items: center;
`;

const HorizontalDivider = styled(FlexBox)`
  background: ${themeColor("gray.200")};
  height: 5px;
  width: 100%;
`;

const FlexAutoSizer = styled(AutoSizer)`
  display: flex;
  flex-direction: column;
  padding: 30px;
  position: relative;
  overflow: visible;
  padding-block-end: 120px;
`;

const RedirectToFootageSnippets = styled.div`
  display: flex;
  align-self: center;
  align-items: center;
  padding: 4px 12px;
  transition: background-color 0.3s ease-out;
  color: ${themeColor("gray.900")};
  background-color: ${themeColor("blue.1500")};
  border-radius: 8px;
  gap: 8px;
  cursor: pointer;
  font-family: Open Sans;
  font-weight: 600;
  font-size: 12px;
  &:hover {
    background-color: ${themeColor("blue.400")};
  }
`;

/* TODO: Add Create Clip button */
// const StyledWhiteRainbowButton = styled(WhiteRainbowButton)`
//   margin-right: 8px;
// `;

export interface FootagePlayerProps {
  videoSrc: string;
  videoDuration: number;
  wordsHistory: UseHistoryStateReturnValue<Word[]>;
  chaptersHistory: UseHistoryStateReturnValue<ChapterTimeInterval[]>;
  languageCode: string;
  textDirection: TextDirection;
  footageId: string;
  canEditTranscript: boolean;
  videoAvailability: { isAvailable: boolean; message: string };
  sequenceCount: number;
  videoAspectRatio: AspectRatio;
}

export default function FootagePlayer({
  videoSrc,
  videoDuration,
  wordsHistory,
  chaptersHistory,
  languageCode,
  textDirection,
  footageId,
  canEditTranscript,
  videoAvailability,
  videoAspectRatio,
  sequenceCount,
}: FootagePlayerProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const isVideoLoading = useIsVideoLoading(videoRef);
  const navigate = useNavigate();
  const { shouldDisplayLoader } = useFootagePreviewItemLoadingState({ footageId });
  const vW = videoRef.current?.videoWidth;
  const vH = videoRef.current?.videoHeight;

  const aspectRatio = useMemo(() => {
    if (videoAspectRatio) {
      return videoAspectRatio;
    }
    if (vW && vH) {
      return `${vW} :  ${vH}`;
    }
    return "16:9";
  }, [videoAspectRatio, vW, vH]);

  return (
    <VideoCurrentTimeProvider videoRef={videoRef}>
      <VideoChaptersProvider
        chaptersHistory={chaptersHistory}
        domains={[{ start: 0, end: videoDuration }]}
        allowedActions={{ addChapter: { isAllowed: false }, removeChapter: { isAllowed: false } }}
      >
        <TimelineZoomProvider videoRef={videoRef} videoDuration={videoDuration} timelineWindowElement={null}>
          <VideoPlaybackProvider
            cropsHistory={[] as unknown as UseHistoryStateReturnValue<InitialHistoryState>}
            wordsHistory={wordsHistory}
            chaptersHistory={chaptersHistory as UseHistoryStateReturnValue<ChapterTimeInterval[]>}
            videoRef={videoRef}
            videoDuration={videoDuration}
          >
            <VideoPlaybackShortcuts videoRef={videoRef} />

            <VideoTranscriptProvider
              wordsHistory={wordsHistory}
              videoDuration={videoDuration}
              videoRef={videoRef}
              textDirection={textDirection}
              ignoreMaxBlanks
            >
              <VideoWordsProvider
                wordsHistory={wordsHistory}
                videoDuration={videoDuration}
                languageCode={languageCode || "en/US"}
                allowedActions={{
                  correct: {
                    isAllowed: canEditTranscript,
                    message: "You can Correct the Transcription once the video has finished processing",
                  },
                  highlight: {
                    isAllowed: canEditTranscript,
                    message: "You can Correct the Transcription once the video has finished processing",
                  },
                }}
                analyticsActions={footagePreviewPlayerAnalytics}
              >
                <Container>
                  <Header>
                    {/* TODO: Add Create Clip button */}
                    {/* <StyledWhiteRainbowButton */}
                    {/*   height="26px" */}
                    {/*   width="90px" */}
                    {/*   borderRadius="8px" */}
                    {/*   text="Create Clip" */}
                    {/*   fontSize="12px" */}
                    {/*   onClick={() => { */}
                    {/*     navigate(applyPathParams(absoluteRoutes.platform.children.clipCreator, { footageId })); */}
                    {/*   }} */}
                    {/* /> */}
                    <RedirectToFootageSnippets
                      onClick={() => {
                        navigate(applyPathParams(absoluteRoutes.platform.children.footage, { footageId }));
                        footageAnalytics.trackFootageSnippetButtonClick(footageId, "footage-preview-snippet-button");
                      }}
                    >
                      {shouldDisplayLoader ? (
                        <CircularLoader size={18} thickness={2} />
                      ) : (
                        <Icon.Film size={18} color="black" />
                      )}
                      Review {sequenceCount} Snippets
                    </RedirectToFootageSnippets>
                    <VerticalDivider />
                    <TranscriptActions analyticsActions={footagePreviewPlayerAnalytics} />
                  </Header>
                  <HorizontalDivider />

                  <Body direction="row" spacing={60}>
                    <TranscriptColumn>
                      <VideoTranscript />
                    </TranscriptColumn>

                    <VideoColumn>
                      {videoAvailability?.isAvailable ? (
                        <FlexAutoSizer aspectRatio={getAspectRatioValue(aspectRatio as AspectRatio)}>
                          {({ width, height }) => (
                            <VideoPlayerContainer style={{ width, height }}>
                              <StyledShakaVideoPlayer
                                onClick={() => {
                                  const video = videoRef?.current;
                                  if (video) {
                                    if (video.paused) {
                                      video.play();
                                    } else {
                                      video.pause();
                                    }
                                  }
                                }}
                                videoRef={videoRef}
                                src={videoSrc}
                                cached={false}
                                width="100%"
                                height="100%"
                                config={{
                                  streaming: {
                                    forceHTTPS: true,
                                    bufferBehind: 30,
                                    bufferingGoal: 30,
                                    durationBackoff: 60,
                                    safeSeekOffset: 2,
                                  },
                                }}
                              />
                              {isVideoLoading && (
                                <CircularLoaderContainer>
                                  <CircularLoader size={100} />
                                </CircularLoaderContainer>
                              )}
                              <FootageVideoControls videoRef={videoRef} />
                            </VideoPlayerContainer>
                          )}
                        </FlexAutoSizer>
                      ) : (
                        <Center>
                          <NoPreviewWrapper>
                            <Text2>{videoAvailability?.message}</Text2>
                          </NoPreviewWrapper>
                        </Center>
                      )}
                    </VideoColumn>
                  </Body>
                </Container>
              </VideoWordsProvider>
            </VideoTranscriptProvider>
          </VideoPlaybackProvider>
        </TimelineZoomProvider>
      </VideoChaptersProvider>
    </VideoCurrentTimeProvider>
  );
}
