import { PropsWithChildren } from "react";
import { createPortal } from "react-dom";
import useResizeObserver from "use-resize-observer";
import styled from "styled-components/macro";

interface PopupProps {
  x: number;
  popupWidth: number;
}

const Popup = styled.div.attrs(({ x, popupWidth }: PopupProps) => ({
  style: {
    transform: `translateX(${x}px)`,
    visibility: popupWidth ? "visible" : "hidden",
  },
}))<PopupProps>`
  position: absolute;
  bottom: 100%;
  left: 0;
  translate: -50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 50px;
  user-select: none;
`;

interface TimePointPopupProps extends PropsWithChildren {
  relativeTimePositionPx: number;
  popupTarget: HTMLElement | null;
}

export default function TimePointPopup({ relativeTimePositionPx, popupTarget, children }: TimePointPopupProps) {
  const { width: popupWidth = 0, ref: popupRef } = useResizeObserver();

  if (!popupTarget || relativeTimePositionPx < 0 || relativeTimePositionPx > popupTarget.clientWidth) {
    return null;
  }

  const popupsPortalContainerWidth = popupTarget.clientWidth;
  const minPopupX = popupWidth / 2;
  const maxPopupX = popupsPortalContainerWidth - popupWidth / 2;
  const popupX = Math.min(maxPopupX, Math.max(minPopupX, relativeTimePositionPx));

  return createPortal(
    <Popup ref={popupRef} x={popupX} popupWidth={popupWidth}>
      {children}
    </Popup>,
    popupTarget,
  );
}
