import { STEPS, SUBSTEPS } from "src/components/features/AutomaticCreateWizard/automaticCreate.constants";
import { AspectRatio } from "src/constants/video.constants";
import { EditRules } from "src/network/graphql/generatedGraphqlSDK";
import analyticsService from "src/services/Analytics.service";

export type CreateModalTrigger = "create-button" | "upload-thumbnail";
export const trackCreteSelectModalOpen = (trigger: CreateModalTrigger) =>
  analyticsService.track("create-select-modal", { trigger, action: "open" });

// manual wizard events start
export const trackStartProjectCreating = (type: "regular flow" | "fast flow") =>
  analyticsService.track("manual-wizard-start", { type });

export const trackSaveNewProject = (data: { aspectRatio: AspectRatio; editRules: EditRules }, sequenceSid: string) =>
  analyticsService.track("new-project-saved", { data, sequenceSid });

export const trackSpeakerDetectionDone = () => analyticsService.track("speaker-detection-done");
// manual wizard events end

// automation wizard events start
export const trackStartAutomationWizard = (firstExperienceUser: boolean) =>
  analyticsService.track("automation-wizard-start", { firstExperienceUser });

export const trackAutomationWizardStep = (
  step: STEPS | SUBSTEPS,
  firstExperienceUser: boolean,
  action: "apply" | "reject",
) => analyticsService.track("automation-wizard-step", { step, firstExperienceUser, action });

export const trackAutomationWizardControls = (component: string, action?: string, values?: object) =>
  analyticsService.track("automation-wizard-control", { component, action, ...values });

export const trackAutomationWizardSuccess = (data: {
  firstExperienceUser: boolean;
  brandKitCreated: boolean;
  brandLogoChanged: boolean;
  brandColorChanged: boolean;
  brandVisualPackageChanged: boolean;
  footageCount: number;
  footageOriginYouTube: number;
  footageOriginUserUpload: number;
}) => analyticsService.track("automation-wizard-success", { ...data });
// automation wizard events end
