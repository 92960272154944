import styled from "styled-components/macro";

import ProgressBar from "src/components/common/loaders/ProgressBar";
import { FileUploadStatus } from "src/services/BaseUpload.service";
import { themeColor } from "src/utils/styledComponents.utils";

import { UPLOADING_STATUS_LABEL_DICT } from "src/components/features/SmartLibrary/smartLibrary.constants";

const ProgressContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
`;

const InfoCont = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 5px;
`;

const ProgressBarCont = styled.div`
  width: 100%;
  padding-top: 4px;
`;

const PercentCont = styled.div`
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: 600;
  line-height: 14px;
  color: ${themeColor("white")};
`;

const StatusCont = styled(PercentCont)`
  font-weight: 400;
  margin-right: 5px;
`;

interface FootageProgressProps {
  status: FileUploadStatus;
  progress: number;
}

export default function FootageProgress({ status, progress }: FootageProgressProps) {
  const showProgress = `${(progress * 100).toFixed(0)}%`;

  return (
    <ProgressContainer>
      <InfoCont>
        <StatusCont>{UPLOADING_STATUS_LABEL_DICT[status]}</StatusCont>
        <PercentCont>{showProgress}</PercentCont>
      </InfoCont>
      <ProgressBarCont>
        <ProgressBar progress={showProgress} isPaused={status === FileUploadStatus.PAUSED} />
      </ProgressBarCont>
    </ProgressContainer>
  );
}
