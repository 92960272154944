import { StyledTooltip, TitleWrapper } from "src/components/features/SmartLibrary/SmartLibrary.styled";
import ActionsMenuPopover from "src/components/common/actionsMenu/ActionsMenuPopover";
import Icon from "src/components/common/Icon";
import ActionsMenuOption from "src/components/common/actionsMenu/ActionsMenuOption";
import styled from "styled-components/macro";
import { themeColor } from "src/utils/styledComponents.utils";
import { Footage } from "src/network/graphql/generatedGraphqlSDK";

const PrevItemFooter = styled.div`
  margin-top: 10px;
  padding-top: 4px;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FooterFirstRow = styled.div`
  width: 100%;
  height: 26px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const FooterSecondRow = styled.span`
  width: 100%;
  font-size: 11px;
  font-weight: 400;
  line-height: 12px;
  color: ${themeColor("gray.400")};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const FilenameText = styled.span`
  display: inline-block;
  max-width: 80%;
  font-family: "Open sans";
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: ${themeColor("gray.900")};
`;

const OptionsMenuWrapper = styled.div`
  width: 40px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
`;

interface FootagePrevFooterProps {
  footage: Footage;
  isOverflowing: boolean;
  itemTitleRef: any;
  displayContextMenu: boolean;
  onOptionsMenuClick: (e: any) => void;
  menuOptions: any[];
  fromNowDistance: string | null;
}

export function FootagePrevFooter({
  footage,
  isOverflowing,
  itemTitleRef,
  displayContextMenu,
  onOptionsMenuClick,
  menuOptions,
  fromNowDistance,
}: FootagePrevFooterProps) {
  return (
    <PrevItemFooter>
      <FooterFirstRow>
        <FilenameText>
          <StyledTooltip
            text={footage?.filename as string}
            placement="bottom"
            isOverflowing={isOverflowing}
            disabled={!isOverflowing}
          >
            <TitleWrapper ref={itemTitleRef}>{footage?.filename}</TitleWrapper>
          </StyledTooltip>
        </FilenameText>
        {displayContextMenu && (
          <OptionsMenuWrapper onClick={onOptionsMenuClick}>
            <ActionsMenuPopover variant="dark" triggerIcon={<Icon.OverflowVertical />}>
              {menuOptions.map((option: any) => (
                <ActionsMenuOption
                  key={option.name}
                  icon={option.icon}
                  onSelect={option.action}
                  hidden={option.hidden}
                  disabled={option.disabled}
                >
                  {option.name}
                </ActionsMenuOption>
              ))}
            </ActionsMenuPopover>
          </OptionsMenuWrapper>
        )}
      </FooterFirstRow>
      <FooterSecondRow>{`Created ${fromNowDistance}`}</FooterSecondRow>
    </PrevItemFooter>
  );
}
