import styled, {
  css,
  ThemeProps,
  DefaultTheme,
  FlattenInterpolation,
  FlattenSimpleInterpolation,
} from "styled-components/macro";

import { Size } from "src/theme";
import { themeColor, themeProp } from "src/utils/styledComponents.utils";
import { BoxProps, boxStyle } from "src/components/common/layout/Box.styled";

const sizeStyles = {
  xs: css`
    min-height: 20px;
    padding-block: 2px;
    padding-inline: 18px;
    font-size: 10px;
    line-height: 12px;
    font-weight: 700;
  `,

  small: css`
    min-height: 28px;
    padding-block: 2px;
    padding-inline: 24px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
  `,

  medium: css`
    min-height: 38px;
    padding-block: 6px;
    padding-inline: 32px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
  `,

  large: css`
    min-height: 46px;
    padding-block: 11px;
    padding-inline: 40px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
  `,
} satisfies Partial<Record<Size, FlattenSimpleInterpolation>>;

const variantStyles = {
  primary: css`
    background-color: ${themeColor("blue.500")};
    color: ${themeColor("white")};
    border: none;

    &:not(:disabled) {
      &:hover {
        background-color: ${themeColor("blue.400")};
      }

      &:active {
        background-color: ${themeColor("blue.900")};
      }
    }

    &:disabled {
      background-color: ${themeColor("gray.200")};
    }
  `,

  ghost: css`
    border-color: ${themeColor("blue.500")};
    background-color: #fff;
    color: ${themeColor("blue.500")};

    &:not(:disabled) {
      &:hover {
        border-color: ${themeColor("blue.300")};
        background-color: ${themeColor("blue.300")};
      }

      &:active {
        background-color: ${themeColor("blue.400")};
      }
    }

    &:disabled {
      border-color: ${themeColor("gray.300")};
      color: ${themeColor("gray.300")};
    }
  `,

  link: css`
    display: inline;
    padding-inline: 5px;
    border-color: transparent;
    background-color: transparent;
    color: ${themeColor("gray.500")};

    &:not(:disabled) {
      &:hover {
        color: ${themeColor("blue.300")};
      }

      &:active {
        color: ${themeColor("blue.400")};
      }
    }

    &:disabled {
      color: ${themeColor("gray.300")};
    }
  `,

  link2: css`
    display: inline;
    padding-inline: 5px;
    border-color: transparent;
    background-color: transparent;
    color: ${themeColor("blue.500")};
    text-decoration: underline;

    &:hover {
      text-decoration: unset;
    }
  `,

  rainbow: css`
    background: linear-gradient(90deg, #ea429d 0%, #7d55d2 54.17%, #0073c3 99.44%);
    color: ${themeColor("white")};
    border: none;
    border-radius: 8px;

    &:not(:disabled) {
      &:hover {
        background: linear-gradient(90deg, #ea429d 0%, #ea429d 9.11%, #ea75b4 24.25%, #ae8bfa 54.15%, #1f9ffb 99.42%);
      }

      &:active {
        background: linear-gradient(90deg, #ea429d 0%, #ea429d 9.11%, #ea75b4 24.25%, #ae8bfa 54.15%, #1f9ffb 99.42%);
      }
    }

    &:disabled {
      background-color: ${themeColor("gray.200")};
    }
  `,
} satisfies Partial<Record<string, FlattenInterpolation<ThemeProps<DefaultTheme>>>>;

interface ButtonProps extends BoxProps {
  size: keyof typeof sizeStyles;
  variant: keyof typeof variantStyles;
}

export const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-radius: ${themeProp("borderRadii.medium")}px;
  transition-property: background-color, color, border-color;
  transition-duration: 250ms;
  transition-timing-function: ease-out;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;

  ${boxStyle};
  ${({ size }) => sizeStyles[size]}
  ${({ variant }) => variantStyles[variant]}

  svg {
    margin-right: 8px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;
