import { useCallback } from "react";

import { useSwiperContext } from "src/components/common/Swiper/Swiper";
import { SubmitButton } from "src/components/features/Onboarding/onboarding.styled";
import { SwiperNextSlideButtonProps } from "src/components/common/Swiper/SwiperNextSlideButton";

export function OnboardingNextButton({ children, onClick, ...props }: SwiperNextSlideButtonProps) {
  const { allowSlideNext, slideNext } = useSwiperContext();
  const onButtonClick = useCallback(() => (onClick ? onClick(slideNext) : slideNext()), [onClick, slideNext]);

  return (
    <SubmitButton {...props} onClick={onButtonClick} disabled={!allowSlideNext} style={{ width: 130 }}>
      {children}
    </SubmitButton>
  );
}
