import { pick } from "lodash/fp";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "src/models/store";
import { EditRules, EditRulesInput, InputMaybe, Preset } from "src/network/graphql/generatedGraphqlSDK";

export const VIDEO_EDIT_RULES = {
  useIntro: {
    elementName: "Intro",
    disabled: false,
    isSelected: false,
  },
  useOutro: {
    elementName: "Outro",
    disabled: false,
    isSelected: false,
  },
  useSpeakers: {
    elementName: "Speaker Title",
    disabled: false,
    isSelected: false,
  },
  useTexts: {
    elementName: "Graphic Text",
    disabled: false,
    isSelected: false,
  },
  useSlides: {
    elementName: "Text Slide",
    disabled: false,
    isSelected: false,
  },
  useMusic: {
    elementName: "Music",
    disabled: false,
    isSelected: false,
  },
  useHighlight: {
    elementName: "Subtitle Highlights",
    disabled: false,
    isSelected: false,
  },
  useFrame: {
    elementName: "Background",
    disabled: false,
    isSelected: false,
  },
  useVideos: {
    elementName: "Content-Based Footage",
    disabled: true,
    isSelected: false,
  },
  useImages: {
    elementName: "Content-Based Images",
    disabled: true,
    isSelected: false,
  },
  useTransitions: {
    elementName: "Transitions",
    disabled: true,
    isSelected: false,
  },
  useBulletframe: {
    elementName: "Bullet Slide",
    disabled: true,
    isSelected: false,
  },
};

export const EDIT_RULES_PICKER_VALUES = [
  "useIntro",
  "useOutro",
  "useSpeakers",
  "useTexts",
  "useSlides",
  "useMusic",
  "useHighlight",
  "useFrame",
];

export default function useEditRules({ currentPreset }: { currentPreset: Preset | undefined }) {
  const editRulesFetched = useSelector((state: RootState) => state.session.editRules);
  const videoTypes = useSelector((state: RootState) => state.session.videoTypes);
  const defaultVideoTypeFromAdmin = useSelector((state: RootState) => state.session.defaultVideoType);
  const [originalValuesEditRules, setOriginalValuesEditRules] = useState<EditRules | undefined>();
  const [updatedEditRules, setUpdatedEditRules] = useState<EditRules | undefined>();

  const videoTypeSid = useMemo(() => {
    if (videoTypes?.length) {
      return defaultVideoTypeFromAdmin?.sid || currentPreset?.defaultVideoType || videoTypes[0]?.sid || "";
    }
    return "";
  }, [currentPreset?.defaultVideoType, defaultVideoTypeFromAdmin, videoTypes]);

  const defaultVideoType = useMemo(() => videoTypes.find((x) => x.sid === videoTypeSid), [videoTypeSid, videoTypes]);

  useEffect(() => {
    if (!editRulesFetched.length) {
      return;
    }

    const selectedEditRulesOriginalValue =
      currentPreset?.editRules ?? editRulesFetched.find((rulesObj) => rulesObj.sid === defaultVideoType?.editRulesSid);

    const updatedRules = pick(EDIT_RULES_PICKER_VALUES, selectedEditRulesOriginalValue);

    setOriginalValuesEditRules(selectedEditRulesOriginalValue);
    setUpdatedEditRules(updatedRules);
  }, [currentPreset?.editRules, defaultVideoType?.editRulesSid, editRulesFetched]);

  const setEditRules = ({
    fieldsToPick,
    currentEditedData,
    fullData,
  }: {
    fieldsToPick: string[];
    currentEditedData: EditRules;
    fullData: EditRules;
  }) =>
    ({
      ...pick(fieldsToPick, fullData),
      ...currentEditedData,
      useLogo: true,
      useVideos: false,
      useIcons: false,
      useImages: false,
    } as InputMaybe<EditRulesInput>);

  return { videoTypeSid, originalValuesEditRules, updatedEditRules, setEditRules };
}
