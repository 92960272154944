import { useMemo } from "react";
import { useSelector } from "react-redux";

import { RootState } from "src/models/store";

import { Plan } from "src/network/graphql/generatedGraphqlSDK";

import { planSelectors } from "src/models/Plan.model";
import { userSelectors } from "src/models/User.model";

import { ChargeType } from "src/constants/model.constants";

import ContactUsButton from "src/components/features/Plans/PlansElements/ContactUsButton";
import PlanButton from "src/components/features/Plans/PlansElements/PlanButton";

interface PlansButtonCreatorProps {
  planOfCard: Plan;
  chosenChargeType: ChargeType | null;
  selectPlanByUser: (planSelected: Plan) => void;
  selectPlanByGuest: (planSelected: Plan) => void;
}

export default function PlansButtonCreator({
  planOfCard,
  chosenChargeType,
  selectPlanByUser,
  selectPlanByGuest,
}: PlansButtonCreatorProps) {
  const currentUser = useSelector(userSelectors.selectCurrentUser);
  const usersPlan = useSelector(planSelectors.selectUsersPlan);
  const usersSubscription = useSelector((state: RootState) => state.session.subscription);

  const text = useMemo(() => {
    const currentTier = usersPlan?.tier!;
    const plansTier = planOfCard.tier!;
    const fromYearlyToMonthlyDowngrade =
      usersSubscription?.chargeType === ChargeType.Yearly && chosenChargeType === ChargeType.Monthly;

    switch (true) {
      case planOfCard.salesOnly:
      case usersPlan?.tier && fromYearlyToMonthlyDowngrade:
        return "Contact us";

      // case plansTier && (!currentUser || !usersPlan?.tier):
      //   return "Start free trial"; // remove start free trail get default

      case currentUser && plansTier > currentTier:
        return "Upgrade";

      case currentUser && plansTier < currentTier:
        return "Downgrade";

      default:
        return "Get started";
    }
  }, [chosenChargeType, currentUser, planOfCard, usersPlan, usersSubscription]);

  const onClick = useMemo(() => {
    switch (true) {
      case text === "Contact us":
        return undefined;

      case !!currentUser:
        return selectPlanByUser;

      default:
        return selectPlanByGuest;
    }
  }, [currentUser, selectPlanByGuest, selectPlanByUser, text]);

  return onClick ? (
    <PlanButton
      plan={planOfCard}
      text={text}
      buttonAction={onClick}
      buttonColor={planOfCard.buttonColor}
      buttonAdditionalColor={planOfCard.buttonAditionalColor}
    />
  ) : (
    <ContactUsButton
      buttonColor={planOfCard.buttonColor}
      buttonAdditionalColor={planOfCard.buttonAditionalColor}
      buttonWidth="256px"
      fontSize="18px"
    />
  );
}
