import BaseUploadService, { FileUploadStatus, IUploadOptions, MEGA_BYTE } from "./BaseUpload.service";

export interface IAssetUploadOptions extends IUploadOptions {
  assetId: string;
  authToken: string;
  uploadUrl: string;
}
export default class UploadToPeechService extends BaseUploadService {
  private assetId: string;
  private authToken: string;
  private uploadUrl: string;

  constructor(file: File, uploadOptions: IAssetUploadOptions) {
    uploadOptions.chunkSize = MEGA_BYTE;
    super(file, uploadOptions as IUploadOptions);
    this.authToken = uploadOptions.authToken;
    this.uploadUrl = uploadOptions.uploadUrl;
    this.assetId = uploadOptions.assetId;
  }

  async start() {
    super.start();
    this.status = FileUploadStatus.UPLOADING;
    try {
      const chunkSize = this.uploadOptions.chunkSize!;
      let startBytes = 0;
      let endBytes = Math.min(startBytes + chunkSize, this.file.size);

      const uploadNextChunk = async (): Promise<void> => {
        if (startBytes >= this.file.size) {
          return Promise.resolve();
        }

        const currentStartBytes = startBytes;
        const currentEndBytes = endBytes;
        startBytes = endBytes;
        endBytes = Math.min(startBytes + chunkSize, this.file.size);

        await this.uploadChunk(currentStartBytes, currentEndBytes);
        return uploadNextChunk(); // Start the next chunk immediately after the current chunk is uploaded
      };

      const initialPromises = [];
      for (let i = 0; i < this.uploadOptions.maxConcurrentChunks!; i += 1) {
        initialPromises.push(uploadNextChunk());
      }
      await Promise.all(initialPromises);

      this.onSuccess();
    } catch (error) {
      const err = new Error(`Upload failed with status ${error}`);
      this.onError(err);
      throw err;
    }
  }
  // upload a file chunk and retry if needed
  async uploadChunk(startByte: number, endByte: number, retries = 0): Promise<void> {
    try {
      const chunk = this.file.slice(startByte, endByte);
      const lastChunk = endByte >= this.file.size;
      const data = new FormData();
      data.append("sid", this.assetId!);
      data.append("contentVersion", "0");
      data.append("type", "Asset");
      data.append("start", startByte.toString());
      data.append("size", this.file.size.toString());
      data.append("buffer", chunk);
      if (lastChunk) {
        data.append("end", "1");
      }

      const response = await fetch(this.uploadUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.authToken}`,
        },
        body: data,
      });

      if (response.status === 200) {
        this.uploadedBytes += endByte - startByte;
        this.eventEmitter.emit("progress", this.progress);
      } else {
        const err = new Error(`Upload failed with status ${response.status}`);
        this.onError(err);
        throw err;
      }
    } catch (e) {
      if (retries < this.uploadOptions.maxRetries!) {
        await this.sleep(this.uploadOptions.retryDelay!);
        await this.uploadChunk(startByte, endByte, retries + 1);
      } else {
        const err = e as Error;
        this.onError(err);
      }
    }
  }
}
