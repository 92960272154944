import { useSelector } from "react-redux";
import { RootState } from "src/models/store";
import { footageSelectors } from "src/models/Footage.model";
import { FootageStatus } from "src/network/graphql/generatedGraphqlSDK";
import { FileUploadStatus } from "src/services/BaseUpload.service";
import { useChunkedUploader } from "src/hooks/useChunkedUploader";
import UploadToGcsService from "src/services/UploadToGcs.service";
import { useUploaderContext } from "src/components/providers/UploaderProvider";
import { useMemo, useState } from "react";
import { useAppConfig } from "src/components/providers/AppConfigProvider";

export interface FootagePlayerProps {
  footageId: string;
}

export const useFootagePreviewItemLoadingState = ({ footageId }: FootagePlayerProps) => {
  const { FOOTAGE_EXPIRED } = useAppConfig();
  const { uploadStatus, progress, uploadThumbnail, setUploader } = useChunkedUploader();
  const { getUploader, deleteUploader } = useUploaderContext();
  const [reachMaxUploadDuration, setReachMaxUploadDuration] = useState(false);

  const uploader = getUploader(footageId) as UploadToGcsService | undefined;

  const footage = useSelector((state: RootState) => footageSelectors.selectById(state, footageId));
  const footageUpdating = useSelector((state: RootState) => !!state.loading.effects.footage.updateFootage.loading);
  const shouldDisplayLoader =
    footage?.status === FootageStatus.CreatingAssets ||
    footage?.status === FootageStatus.IngestingFootage ||
    (footage?.status === FootageStatus.Uploading && footage?.youtubeId);
  const shouldShowProgress = ["PENDING", "UPLOADING", "SUCCESS"].includes(uploadStatus) || shouldDisplayLoader;
  const shouldShowUploadingProgress = footage?.status === FootageStatus.Uploading && !reachMaxUploadDuration;
  const isLanguageSelectEnabled =
    shouldShowUploadingProgress && uploader?.status === FileUploadStatus.PENDING && !reachMaxUploadDuration;
  const shouldShowStatusTime = footage?.status === FootageStatus.CreatingAssets;
  const isFootageFailure = footage?.status === FootageStatus.Failure;

  const isFootageExpired = useSelector((state: RootState) => footageSelectors.selectIsFootageOlderThanDaysAmount(state, footageId, FOOTAGE_EXPIRED.expiredDays)); // prettier-ignore

  const shouldDisplayWarningTag = useSelector((state: RootState) => footageSelectors.selectIsFootageOlderThanDaysAmount(state, footageId, FOOTAGE_EXPIRED.warningDays)); // prettier-ignore
  const isFootageReadyToOpen = !!footage?.clips?.length && !isFootageFailure;

  const displayContextMenu = useMemo(
    () =>
      isFootageReadyToOpen ||
      isFootageFailure ||
      footage?.status === FootageStatus.Uploading ||
      uploader?.status === FileUploadStatus.PENDING,

    [footage?.status, isFootageFailure, isFootageReadyToOpen, uploader?.status],
  );

  return {
    uploadStatus,
    progress,
    uploadThumbnail,
    setUploader,
    getUploader,
    deleteUploader,
    reachMaxUploadDuration,
    setReachMaxUploadDuration,
    uploader,
    footageUpdating,
    shouldDisplayLoader,
    shouldShowProgress,
    shouldShowUploadingProgress,
    isLanguageSelectEnabled,
    shouldShowStatusTime,
    isFootageFailure,
    isFootageExpired,
    shouldDisplayWarningTag,
    displayContextMenu,
    isFootageReadyToOpen,
  };
};
