import { PropsWithChildren, RefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";

import useShallowMemo from "src/hooks/useShallowMemo";
import { UseHistoryStateReturnValue } from "src/hooks/useHistoryState";

import VideoCropContext, {
  VideoCropContextValue,
} from "src/components/features/VideoCropper/providers/VideoCropProvider/VideoCropContext";
import { timeIntervalUtils } from "src/utils/timeInterval.utils";
import { useCurrentTime } from "src/components/features/VideoTrimmer/providers/VideoCurrentTimeProvider/VideoCurrentTimeContext";
import { TimeIntervalEdge } from "src/types/video-trimmer.types";
import { flow, set, sortBy } from "lodash/fp";
import { roundBy } from "src/utils/math.utils";
import { CropperHistory, CropsStyle, CropTimeInterval } from "src/types/video-cropper.types";
import { DragPosition, getCropData, getDrawData } from "src/utils/cropData.utils";
import * as cropAnalytics from "src/analytics/sequenceCropEditor.analytics";
import { useVideoChapters } from "src/components/features/VideoTrimmer/providers/VideoChaptersProvider/VideoChaptersContext";
import useCropData from "src/components/features/VideoCropper/hooks/useCropData";
import { InitialHistoryState } from "src/components/features/VideoTrimmer/common/hooks/useInitiatedHistory";
import Color from "color";
import { useSelector } from "react-redux";
import { RootState } from "src/models/store";
import { sequenceSelectors } from "src/models/Sequence.model";
import { convertTimeToFrameTime, getFrameNumber } from "src/utils/time.utils";
import { isChrome } from "src/utils/mobile.utils";

const MIN_INTERVAL_DURATION = 0.1;
const SEEK_OFFSET = 0.001;
const RESIZE_DRAG_PRECISION = 0.000001;
const FRAME_DURATION = 1 / 30;

interface TimelineCropProviderProps extends PropsWithChildren {
  cropsHistory: UseHistoryStateReturnValue<InitialHistoryState>;
  sequenceSid: string;
  videoRef?: RefObject<HTMLVideoElement>;
  cropStageRef?: RefObject<HTMLDivElement>;
}

export type SeekDirection = "backward" | "forward";

export default function VideoCropProvider({
  children,
  cropsHistory,
  sequenceSid,
  videoRef,
  cropStageRef,
}: TimelineCropProviderProps) {
  const minIntervalDuration = MIN_INTERVAL_DURATION;
  const seekOffset = SEEK_OFFSET;
  const resizeDragPrecision = RESIZE_DRAG_PRECISION;
  const frameDuration = FRAME_DURATION;
  const currentTime = useCurrentTime();
  const [renderedFrame, setRenderedFrame] = useState(0);
  const [copiedDragPosition, setCopiedDragPosition] = useState<DragPosition | null>(null);

  const sequence = useSelector((state: RootState) => sequenceSelectors.selectById(state, sequenceSid ?? ""));
  const { chapters } = useVideoChapters();

  // crop history state
  const {
    state: { crops, style },
    set: setCrops,
    startBatching: startCropBatchUpdate,
    endBatching: endCropBatchUpdate,
  } = cropsHistory as UseHistoryStateReturnValue<CropperHistory>;

  // active crop index in time interval
  const [activeCropIndex, setActiveCropIndex] = useState<number>(0);

  // resized crop index in time interval (while resizing crop time interval)
  const [resizedCropIndex, setResizedCropIndex] = useState(-1);

  const isChromeBrowser = isChrome();
  const renderTimeRef = useRef<number>(0);

  // get the rendered video mediaTime
  const getRenderedFrameMediaTime = useCallback(
    (timestamps: number, metaData: { mediaTime: number }) => {
      const { mediaTime } = metaData;
      const rendered = getFrameNumber(mediaTime);
      renderTimeRef.current = +(rendered / 30).toFixed(6) + SEEK_OFFSET;
      setRenderedFrame(rendered);
      if (videoRef?.current) {
        videoRef?.current.requestVideoFrameCallback(getRenderedFrameMediaTime);
      }
    },
    [videoRef],
  );
  useEffect(() => {
    if (videoRef?.current && isChromeBrowser) {
      videoRef?.current.requestVideoFrameCallback(getRenderedFrameMediaTime);
    }
  }, [videoRef, getRenderedFrameMediaTime, isChromeBrowser]);

  const onSeeked = () => {
    if (videoRef?.current) {
      videoRef!.current!.removeEventListener("seeked", onSeeked);
      setTimeout(() => {
        const requestedFrame = Math.floor(videoRef!.current!.currentTime * 30);
        let rendered = Math.floor(renderTimeRef.current * 30);
        if (Math.round(renderTimeRef.current * 30) - renderTimeRef.current * 30 <= 0.001) {
          rendered = Math.round(renderTimeRef.current * 30);
        }
        if (rendered < requestedFrame) {
          videoRef!.current!.addEventListener("seeked", onSeeked);
          videoRef!.current!.currentTime += SEEK_OFFSET;
        }
      }, 5);
    }
  };

  useEffect(() => {
    const time = isChromeBrowser ? renderTimeRef.current : currentTime;
    const sortedCrops = sortBy("start", crops);
    let newActiveCropIndex = sortedCrops.findIndex(
      (crop) => time >= convertTimeToFrameTime(crop.start) && time < convertTimeToFrameTime(crop.end),
    );

    if (newActiveCropIndex === -1) {
      newActiveCropIndex = sortedCrops.findIndex((crop) => time >= crop.start && time < crop.end);
    }

    if (newActiveCropIndex !== null && activeCropIndex !== newActiveCropIndex) setActiveCropIndex(newActiveCropIndex);
    if (resizedCropIndex !== -1) setActiveCropIndex(resizedCropIndex);
  }, [crops, currentTime, renderedFrame, activeCropIndex, resizedCropIndex, isChromeBrowser]);

  // crop drag and scale
  const {
    draggingPosition,
    setDraggingPosition,
    cropScale,
    resizeLimit,
    getCropFill,
    getCropFit,
    rescaleCrop,
    alignCrop,
  } = useCropData({
    sequenceSid,
    videoRef,
    cropStageRef,
    crops,
    activeCropIndex,
  });

  // video backgroundColor
  const backgroundColor = useMemo(
    () => (style?.brandColorIndex && sequence?.colors?.[style.brandColorIndex]?.color) || style?.color || "#000",
    [sequence?.colors, style.brandColorIndex, style?.color],
  );

  // check if crop is first or last in chapter
  const cropInChapterCheck = useCallback(
    (index: number) => ({
      firstCropInChapter: chapters.some(
        (chapter) => convertTimeToFrameTime(chapter.start) === convertTimeToFrameTime(crops[index]?.start),
      ),
      lastCropInChapter: chapters.some(
        (chapter) => convertTimeToFrameTime(chapter.end) === convertTimeToFrameTime(crops[index]?.end),
      ),
    }),
    [chapters, crops],
  );

  // set dragging position state
  const setCropCords = useCallback(() => {
    setCrops((prevState) => {
      const cropToResize = prevState.crops[activeCropIndex];
      const newCrops = [...prevState.crops];

      const videoRect = videoRef?.current?.getBoundingClientRect();

      const stageRect = cropStageRef?.current?.getBoundingClientRect();
      if (!videoRect || !stageRect) return { ...prevState, crops: newCrops };
      newCrops[activeCropIndex] = {
        ...cropToResize,
        crop: getCropData(videoRect, stageRect),
        draw: getDrawData(videoRect, stageRect),
      };
      return { ...prevState, crops: newCrops };
    });
  }, [activeCropIndex, cropStageRef, setCrops, videoRef]);

  // start crop time interval resizing
  const startCropTimeIntervalResizing = useCallback(
    (index: number, edge: TimeIntervalEdge) => {
      const { firstCropInChapter, lastCropInChapter } = cropInChapterCheck(index);
      if ((firstCropInChapter && edge === "start") || (lastCropInChapter && edge === "end")) return;

      startCropBatchUpdate();
      setResizedCropIndex(index);
    },
    [cropInChapterCheck, startCropBatchUpdate],
  );

  // resize crop time interval
  const resizeCropTimeInterval = useCallback(
    (index: number, edge: TimeIntervalEdge, timeDelta: number) => {
      const { firstCropInChapter, lastCropInChapter } = cropInChapterCheck(index);
      if ((firstCropInChapter && edge === "start") || (lastCropInChapter && edge === "end")) return;
      setCrops((prevState) => {
        const cropToResize = prevState.crops[index];
        const leftSibling = prevState.crops[index - 1];
        const rightSibling = prevState.crops[index + 1];
        const applyMinDuration = (c: CropTimeInterval) =>
          set([edge], edge === "start" ? c.end - minIntervalDuration : c.start + minIntervalDuration, c);
        const limitResizeBoundaries = (crop: CropTimeInterval) => {
          const applyIfNeeded = (interval: CropTimeInterval, sibling: CropTimeInterval | null, isStart: boolean) => {
            if (edge === (isStart ? "start" : "end") && sibling) {
              return isStart
                ? timeIntervalUtils.applyMinStart(interval, sibling.start + minIntervalDuration)
                : timeIntervalUtils.applyMaxEnd(interval, sibling.end - minIntervalDuration);
            }
            return interval;
          };

          const limitedInterval = flow(
            (c) => applyIfNeeded(c, leftSibling, true),
            (c) => applyIfNeeded(c, rightSibling, false),
          )(crop);

          return limitedInterval;
        };

        const resizedCrop = flow(
          (c) => set([edge], roundBy(resizeDragPrecision, c[edge] + timeDelta), c),
          (c) => limitResizeBoundaries(c),
          (c) => timeIntervalUtils.applyBorders(c, prevState.crops[index].domain),
          (c) => (timeIntervalUtils.duration(c) < minIntervalDuration ? applyMinDuration(c) : c),
        )(cropToResize);

        // set resizedCrop and sticky siblings
        const newState = flow(
          (s: CropperHistory) => set(["crops", index], resizedCrop, s),
          // eslint-disable-next-line prefer-arrow-callback
          function stickyRightSibling(s: CropperHistory) {
            return !lastCropInChapter ? set(["crops", index + 1], { ...rightSibling, start: resizedCrop.end }, s) : s;
          },
          // eslint-disable-next-line prefer-arrow-callback
          function stickyLeftSibling(s: CropperHistory) {
            return !firstCropInChapter ? set(["crops", index - 1], { ...leftSibling, end: resizedCrop.start }, s) : s;
          },
        )(prevState);
        videoRef!.current!.currentTime =
          resizedCrop?.[edge] && edge === "start" ? resizedCrop[edge] + seekOffset : resizedCrop[edge] - seekOffset;

        return newState;
      });
    },
    [cropInChapterCheck, setCrops, videoRef, seekOffset, minIntervalDuration, resizeDragPrecision],
  );

  // end crop time interval
  const endCropTimeIntervalResizing = useCallback(
    (index: number, edge: TimeIntervalEdge) => {
      setCrops((prevState) => {
        if (
          (edge === "start" && !prevState?.crops?.[index - 1]) ||
          (edge === "end" && !prevState?.crops?.[index + 1]) ||
          !prevState?.crops?.[index]
        ) {
          return prevState;
        }
        const timeDifference = prevState.crops[index].domain.start;

        if (edge === "start") {
          prevState.crops[index].start = convertTimeToFrameTime(prevState.crops[index].start, timeDifference);
          prevState.crops[index - 1].end = convertTimeToFrameTime(prevState.crops[index - 1].end, timeDifference);
        } else {
          prevState.crops[index].end = convertTimeToFrameTime(prevState.crops[index].end, timeDifference);
          prevState.crops[index + 1].start = convertTimeToFrameTime(prevState.crops[index + 1].start, timeDifference);
        }
        videoRef!.current!.currentTime =
          prevState.crops[index] && edge === "start"
            ? convertTimeToFrameTime(prevState.crops[index].start, timeDifference) + frameDuration
            : convertTimeToFrameTime(prevState.crops[index].end, timeDifference) - frameDuration;
        return prevState;
      });
      endCropBatchUpdate();
      setResizedCropIndex(-1);
    },
    [endCropBatchUpdate, frameDuration, setCrops, videoRef],
  );

  // check if crop time interval is splittable
  const isCropSplittable = useCallback(() => {
    const targetCrop = crops[activeCropIndex];
    if (!targetCrop) return false;
    const tooCloseToStart =
      convertTimeToFrameTime(currentTime - targetCrop.start, targetCrop.domain.start) < minIntervalDuration;
    const tooCloseToEnd =
      convertTimeToFrameTime(targetCrop.end - currentTime, targetCrop.domain.start) < minIntervalDuration;
    return !tooCloseToEnd && !tooCloseToStart;
  }, [crops, activeCropIndex, currentTime, minIntervalDuration]);

  // check if crop time interval is deletable
  const isCropDeletable = useCallback(() => {
    const targetCrop = crops[activeCropIndex];
    const { firstCropInChapter, lastCropInChapter } = cropInChapterCheck(activeCropIndex);
    const singleCropInChapter = firstCropInChapter && lastCropInChapter;
    if (!targetCrop || !targetCrop?.domain || singleCropInChapter) return false;
    return !timeIntervalUtils.equals(targetCrop, targetCrop.domain);
  }, [crops, activeCropIndex, cropInChapterCheck]);

  // split crop time interval
  const splitCrop = useCallback(() => {
    isCropSplittable() &&
      setCrops((prevState) => {
        const crop = prevState.crops[activeCropIndex];
        const [a, b] = timeIntervalUtils.slice(crop, convertTimeToFrameTime(currentTime, crop.domain.start));
        if (!a || !b) return prevState;
        return {
          ...prevState,
          crops: [...prevState.crops.slice(0, activeCropIndex), a, b, ...prevState.crops.slice(activeCropIndex + 1)],
        };
      });
    cropAnalytics.trackSplit("split-button");
  }, [setCrops, currentTime, activeCropIndex, isCropSplittable]);

  // set crop style
  const setStyle = useCallback(
    (partialStyle: Partial<CropsStyle>) => {
      setCrops((prevState) => {
        const newStyle = { ...prevState.style };
        const isBlur = !!partialStyle?.color && Color(partialStyle.color).object().alpha === 0;
        const brandColorIndex =
          Number(partialStyle?.brandColorIndex) > -1 && !isBlur ? partialStyle?.brandColorIndex : null;

        newStyle.blur = isBlur;
        newStyle.brandColorIndex = brandColorIndex;
        newStyle.color = partialStyle?.color && !isBlur ? partialStyle.color : prevState.style.color;

        return {
          ...prevState,
          style: newStyle,
        };
      });
    },
    [setCrops],
  );

  // delete crop time interval: merge with sibling
  const deleteCrop = useCallback(() => {
    isCropDeletable() &&
      setCrops((prevState) => {
        let aIndex = activeCropIndex - 1;
        let bIndex = activeCropIndex;
        const firstCropInDomain = aIndex < 0;
        const { firstCropInChapter } = cropInChapterCheck(activeCropIndex);
        if (firstCropInDomain || firstCropInChapter) {
          aIndex = activeCropIndex + 1;
          bIndex = activeCropIndex;
        }
        const a = prevState.crops[aIndex];
        const b = prevState.crops[bIndex];
        if (!a || !b) {
          return prevState;
        }
        return {
          ...prevState,
          crops: [
            ...prevState.crops.slice(0, Math.min(aIndex, bIndex)),
            timeIntervalUtils.merge(a, b),
            ...prevState.crops.slice(Math.max(aIndex, bIndex) + 1),
          ],
        };
      });
    cropAnalytics.trackRemove("delete-button");
  }, [setCrops, activeCropIndex, cropInChapterCheck, isCropDeletable]);

  // seek crop time interval
  const seekCrop = useCallback(
    (direction: SeekDirection) => {
      if (!direction) return;
      const indexToSeekTo = direction === "forward" ? activeCropIndex + 1 : Math.max(activeCropIndex - 1, 0);
      const cropToSeekTo = crops[indexToSeekTo];

      if (!cropToSeekTo || !videoRef?.current) return;
      videoRef.current?.pause();

      // remove before add event listener
      videoRef!.current!.removeEventListener("seeked", onSeeked);
      isChromeBrowser && videoRef?.current?.addEventListener("seeked", onSeeked);

      if (direction === "backward" && videoRef.current.currentTime > crops[activeCropIndex].start + 0.05) {
        videoRef.current.currentTime = crops[activeCropIndex].start + seekOffset;
        return;
      }

      videoRef.current.currentTime = cropToSeekTo.start + seekOffset;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeCropIndex, crops, videoRef, seekOffset],
  );

  // trigger seek crop time interval forward
  const seekForward = useCallback(() => {
    cropAnalytics.trackSeekForward("forward-button");
    seekCrop("forward");
  }, [seekCrop]);

  // trigger seek crop time interval backward
  const seekBackward = useCallback(() => {
    cropAnalytics.trackSeekBackward("backward-button");
    seekCrop("backward");
  }, [seekCrop]);

  const copyDragPosition = useCallback(() => {
    setCopiedDragPosition(draggingPosition);
  }, [draggingPosition]);

  const pasteDragPosition = useCallback(() => {
    if (!copiedDragPosition) return;
    setDraggingPosition(copiedDragPosition);
    setTimeout(() => {
      setCropCords();
    }, 1);
  }, [copiedDragPosition, setCropCords, setDraggingPosition]);

  const contextValue = useShallowMemo<VideoCropContextValue>({
    crops,
    cropsHistory,
    activeCropIndex,
    resizedCropIndex,
    draggingPosition,
    setDraggingPosition,
    getCropFill,
    getCropFit,
    cropScale,
    resizeLimit,
    rescaleCrop,
    setCropCords,
    startCropTimeIntervalResizing,
    resizeCropTimeInterval,
    endCropTimeIntervalResizing,
    isCropSplittable,
    isCropDeletable,
    splitCrop,
    deleteCrop,
    seekForward,
    seekBackward,
    alignCrop,
    setStyle,
    style,
    backgroundColor,
    copyDragPosition,
    pasteDragPosition,
    copiedDragPosition,
  });

  return <VideoCropContext.Provider value={contextValue}>{children}</VideoCropContext.Provider>;
}
