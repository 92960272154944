import { PropsWithChildren } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { useAppConfig } from "src/components/providers/AppConfigProvider";

export default function AppGoogleOAuthProvider({ children }: PropsWithChildren) {
  const config = useAppConfig();

  return <GoogleOAuthProvider clientId={config.GOOGLE_CLIENT_ID}>{children}</GoogleOAuthProvider>;
}
