import { ComponentType, SVGProps } from "react";
import styled, { css, StyledComponent } from "styled-components/macro";

import { Size, Theme, ThemeColor } from "src/theme";
import { styleProp, themeColor } from "src/utils/styledComponents.utils";
import BaseIcon from "./Icon";

export const ICON_SIZES = {
  large: 36,
  medium: 30,
  small: 24,
  xs: 20,
} satisfies Partial<Record<Size, number>>;

interface StyledProps {
  size?: number | "full" | keyof typeof ICON_SIZES;
  color?: ThemeColor;
}

type SVGComponent = ComponentType<SVGProps<SVGSVGElement>>;

const styledIcon = (icon: SVGComponent) => styled(icon).attrs(({ color, ...props }) => ({
  color: color && themeColor(color as ThemeColor)(props),
}))<StyledProps>`
  ${(props) => {
    if (props.size === undefined) {
      return false;
    }

    if (typeof props.size === "number") {
      return css<StyledProps>`
        width: ${styleProp("size")}px;
        height: ${styleProp("size")}px;
      `;
    }

    if (props.size === "full") {
      return css`
        width: 100%;
        height: 100%;
      `;
    }

    return css`
      width: ${ICON_SIZES[props.size]}px;
      height: ${ICON_SIZES[props.size]}px;
    `;
  }}
`;

type IconName = keyof typeof BaseIcon;
type IconType = Record<IconName, StyledComponent<SVGComponent, Theme, StyledProps>>;

const Icon = Object.entries(BaseIcon).reduce(
  (acc, [name, component]) => ({ ...acc, [name]: styledIcon(component) }),
  {},
) as IconType;

export default Icon;
