import { useForm, useFormContext } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const { array, object, string } = yup;

const speakersFormSchema = object({
  saveForFuture: yup.boolean().required().default(false),

  speakers: array(
    object({
      sid: string().required(),
      name: string().max(60),
      title: string().max(60),
    }),
  )
    .required()
    .default([]),
});

export type SpeakersForm = yup.InferType<typeof speakersFormSchema>;

export const useSpeakersForm = () =>
  useForm<SpeakersForm>({
    mode: "onChange",
    resolver: yupResolver(speakersFormSchema),
  });

export const useSpeakersFormContext = useFormContext<SpeakersForm>;
