import { themeColor } from "src/utils/styledComponents.utils";
import styled, { keyframes } from "styled-components/macro";

const Container = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
`;

const PlugHover = keyframes`
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.5;
  }
`;

const HoverBlock = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${themeColor("gray.400")};
  border-radius: 8px;
  z-index: 2;
  animation: ${PlugHover} 2s infinite;
  transition: opacity 1500ms cubic-bezier(1, 0.12, 1, 0.12);
`;

export default function LoadingItemPlug() {
  return (
    <Container>
      <HoverBlock />
    </Container>
  );
}
