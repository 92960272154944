import { FieldPath, FieldValues, useFormContext, FieldError as FieldErrorType } from "react-hook-form";
import { get } from "lodash/fp";

import apiClient from "src/network/ApiClient";

import BaseFieldError, { FieldErrorProps as BaseFieldErrorProps } from "src/components/common/form/FieldError";
import BaseInput, { InputProps } from "src/components/common/form/inputs/Input.styled";
import BasePasswordInput from "src/components/common/form/inputs/PasswordInput";

interface FieldErrorProps<TFieldValues extends FieldValues> extends Omit<BaseFieldErrorProps, "children"> {
  name: FieldPath<TFieldValues>;
  showIfTouched?: boolean;
}

export function FieldError<TFieldValues extends FieldValues>({
  name,
  showIfTouched,
  ...props
}: FieldErrorProps<TFieldValues>) {
  const { formState } = useFormContext<TFieldValues>();
  const msg = (get(name, formState.errors) as FieldErrorType)?.message;
  const touched = !!get(name, formState.touchedFields);

  return <BaseFieldError {...props}>{!showIfTouched || touched ? msg : undefined}</BaseFieldError>;
}

interface NetworkErrorProps extends Omit<BaseFieldErrorProps, "children"> {
  error: Error;
}

export function NetworkError({ error, ...props }: NetworkErrorProps) {
  return (
    <BaseFieldError {...props}>
      {error && (apiClient.isClientError(error) ? error.response.errors?.[0].message : "Unknown error")}
    </BaseFieldError>
  );
}

export function Input<TFieldValues extends FieldValues>({
  name,
  ...props
}: Omit<JSX.IntrinsicElements["input"], "name" | "ref"> & InputProps & { name: FieldPath<TFieldValues> }) {
  const { register, formState } = useFormContext<TFieldValues>();
  return <BaseInput {...register(name)} {...props} isError={!!formState.errors[name]} />;
}

export function PasswordInput<TFieldValues extends FieldValues>({
  name,
  ...props
}: Omit<JSX.IntrinsicElements["input"], "name" | "ref"> & InputProps & { name: FieldPath<TFieldValues> }) {
  const { register, formState } = useFormContext<TFieldValues>();
  return <BasePasswordInput {...register(name)} {...props} isError={!!formState.errors[name]} />;
}
