import { useParams, useSearchParams } from "react-router-dom";

import { SKIP_SPEAKER_DETECTION_QUERY_PARAM } from "src/constants/video.constants";

import SequenceProcessing from "src/components/features/SequenceProcessing";

export default function SequenceProcessingPage() {
  const { sequenceSid } = useParams();
  const [searchParams] = useSearchParams();

  if (!sequenceSid) throw new Error("sequenceSid is required");

  return (
    <SequenceProcessing
      sequenceSid={sequenceSid}
      skipSpeakerDetection={searchParams.has(SKIP_SPEAKER_DETECTION_QUERY_PARAM)}
    />
  );
}
