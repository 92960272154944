import styled from "styled-components/macro";

import { themeColor } from "src/utils/styledComponents.utils";

import * as timelineAnalytics from "src/analytics/timeline.analytics";
import useVideoTimeInterval from "src/components/features/VideoTrimmer/common/hooks/useVideoTimeInterval";
import { useTimelineZoom } from "src/components/features/VideoTrimmer/providers/TimelineZoomProvider/TimelineZoomContext";

interface ContainerProps {
  x1: number;
  x2: number;
}

export const ResizeThumbGrabArea = styled.div`
  z-index: 1;
  display: flex;
  width: 10px;
  cursor: col-resize;
`;

const Container = styled.div.attrs<ContainerProps>(({ x1, x2 }) => ({
  style: {
    transform: `translateX(${x1}px)`,
    width: `${x2 - x1}px`,
  },
}))<ContainerProps>`
  display: flex;
  position: absolute;
  height: 6px;
  top: 0;
  bottom: 0;
  left: 0;
  margin-block: auto;
  border-radius: 5px;
  background-color: ${themeColor("gray.400")};
`;

const DragArea = styled.div`
  flex: 1;
  cursor: move;
`;

interface ZoomTimeIntervalProps {
  pixelPerSecond: number;
}

export default function ZoomedTimeInterval({ pixelPerSecond }: ZoomTimeIntervalProps) {
  const { zoomedTimeInterval, shiftZoomedTimeInterval, resizeZoomedTimeInterval } = useTimelineZoom();

  const { x1, x2, dragTriggerProps, leftResizeTriggerProps, rightResizeTriggerProps } = useVideoTimeInterval(
    zoomedTimeInterval,
    {
      pixelPerSecond,
      onDrag: shiftZoomedTimeInterval,
      onResize: resizeZoomedTimeInterval,
      onDragEnd: timelineAnalytics.trackTimelineScroll,
      onResizeEnd(edge, fullDeltaX) {
        // eslint-disable-next-line default-case
        switch (true) {
          case edge === "start" && fullDeltaX > 0:
          case edge === "end" && fullDeltaX < 0:
            timelineAnalytics.trackZoomIn("zoom-slider");
            break;

          case edge === "start" && fullDeltaX < 0:
          case edge === "end" && fullDeltaX > 0:
            timelineAnalytics.trackZoomOut("zoom-slider");
            break;
        }
      },
    },
  );

  return (
    <Container x1={x1} x2={x2}>
      <ResizeThumbGrabArea {...leftResizeTriggerProps} />
      <DragArea {...dragTriggerProps} />
      <ResizeThumbGrabArea {...rightResizeTriggerProps} />
    </Container>
  );
}
