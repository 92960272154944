import { Fragment, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "src/models/store";

import { useGetAssetImageURL } from "src/hooks/useGetAssetURL";

import { AssetType } from "src/constants/model.constants";

import { toPng } from "src/utils/image.utils";

import styled from "styled-components/macro";

import ImageDropzone from "src/components/common/dropzones/ImageDropzone";
import { CircularLoader } from "src/components/common/loaders/CircularLoader";
import {
  HeaderText,
  LogoContainer,
} from "src/components/features/AutomaticCreateWizard/common/automatic-create-wizard-layout.styled";
import { FormContextInterface } from "src/components/features/AutomaticCreateWizard/FormContext.interface";

const LoaderOverlay = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: #ffffffaa;
  width: 100%;
  height: 100%;
  padding-top: 16px;
`;

export default function BrandLogo({ formContext }: FormContextInterface) {
  const dispatch = useDispatch<Dispatch>();
  const { watch, setValue } = formContext;
  const getAssetImageURL = useGetAssetImageURL();
  const logoAssetSid = watch("brandKit.logo.assetId");
  const isLoadingAssets = useSelector((state: RootState) => state.loading.models.assets.loading);

  const updateLogo = useCallback(
    async (content?: File | Blob, name?: string) => {
      setValue("brandKit.logo.assetId", "", { shouldValidate: true });

      let assetId = "";

      if (name && content) {
        const pngContent = await toPng(content, { maxHeight: 1000, maxWidth: 1500 });
        if (pngContent) {
          assetId = await dispatch.assets.createAsset({
            type: AssetType.Logo,
            name,
            content: pngContent,
          });
        }
      }

      setValue("brandKit.logo.assetId", assetId, { shouldValidate: true });
    },
    [dispatch.assets, setValue],
  );

  const onDropzoneFile = useCallback((file: File) => updateLogo(file, file.name), [updateLogo]);
  const onDropzoneClear = useCallback(() => updateLogo(), [updateLogo]);

  return (
    <Fragment>
      <HeaderText>Ensure Consistent And Professional </HeaderText>
      <HeaderText>
        Video Branding By <strong>Uploading Your Logo.</strong>
      </HeaderText>
      <LogoContainer>
        <div style={{ position: "relative" }}>
          <ImageDropzone
            acceptedImageTypes={["png", "jpg", "jpeg"]}
            onDrop={onDropzoneFile}
            onClear={onDropzoneClear}
            // eslint-disable-next-line no-alert
            onReject={() => alert("rejected")}
            droppedImageURL={!logoAssetSid ? null : getAssetImageURL(logoAssetSid)}
            maxSizeBytes={1024 * 1024}
          />

          {isLoadingAssets && (
            <LoaderOverlay>
              <CircularLoader size={50} />
            </LoaderOverlay>
          )}
        </div>
      </LogoContainer>
    </Fragment>
  );
}
