import { createModel } from "@rematch/core";
import { RootModel } from "src/models/index";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import {
  UpdateBulkOfWordsInTranscriptMutationVariables,
  GetWordsByExternalAssetIdQueryVariables,
  Word as gqlWord,
  V2_Word,
  GetFootageTranscriptQueryVariables,
} from "src/network/graphql/generatedGraphqlSDK";
import apiClient from "src/network/ApiClient";
import { RootState } from "src/models/store";
import { Word, WordTimeInterval } from "src/types/video-trimmer.types";
import { AppConfig } from "src/components/providers/AppConfigProvider";
import { sortBy } from "lodash/fp";
import { sequenceSelectors } from "src/models/Sequence.model";
import { isFillerWord } from "src/utils/words.utils";
import { footageSelectors } from "src/models/Footage.model";

interface WordsModel {
  footageId: string;
  words: gqlWord[] | V2_Word[];
  footageWords?: gqlWord[] | V2_Word[];
  lastVersion: number;
}

const wordsAdapter = createEntityAdapter<WordsModel>({ selectId: (model) => model.footageId });
const wordsAdapterSelectors = wordsAdapter.getSelectors((state: RootState) => state.words); // prettier-ignore

const formatWord = ({ word, startTime, endTime, newLine, highlight, version }: gqlWord): WordTimeInterval => ({
  word: word!,
  start: startTime! / 1000,
  end: endTime! / 1000,
  newLine: !!newLine,
  highlight,
  version: version ?? 0,
});

const selectAbsoluteSequenceWords = createSelector(
  wordsAdapterSelectors.selectById,
  (state: RootState, footageSid: string, appConfig: AppConfig) => appConfig.FILLER_WORDS,
  (state: RootState, footageSid: string, appConfig: AppConfig, sequenceSid: string) =>
    sequenceSelectors.selectById(state, sequenceSid),
  (words, fillerWords, sequence) => {
    if (!sequence) {
      return [];
    }

    const wordTimeIntervals: Array<Word> = [];
    words?.words.forEach((word) => {
      const isFiller = isFillerWord(word.word!, fillerWords[sequence.languageCode!]);
      const isHighlighted = word.highlight;

      wordTimeIntervals.push({
        kind: (() => {
          if (isFiller) return "fillerWord";
          if (isHighlighted) return "highlightedWord";
          return "word";
        })(),
        ...formatWord(word as gqlWord),
        highlight: word.highlight,
        originalStart: word.startTime,
        originalEnd: word.endTime,
      });
    });

    return sortBy("start", wordTimeIntervals);
  },
);

const selectAbsoluteFootageWords = createSelector(
  wordsAdapterSelectors.selectById,
  (state: RootState, footageSid: string, appConfig: AppConfig) => appConfig.FILLER_WORDS,
  (state: RootState, footageId: string) => footageSelectors.selectById(state, footageId),
  (words, fillerWords, footage) => {
    if (!footage) {
      return [];
    }

    const wordTimeIntervals: Array<Word> = [];
    words?.words.forEach((word) => {
      const isFiller = isFillerWord(word.word!, fillerWords[footage.languageCode!]);
      const isHighlighted = word.highlight;

      wordTimeIntervals.push({
        kind: (() => {
          if (isFiller) return "fillerWord";
          if (isHighlighted) return "highlightedWord";
          return "word";
        })(),
        ...formatWord(word as gqlWord),
        highlight: word.highlight,
        originalStart: word.startTime,
        originalEnd: word.endTime,
      });
    });

    return sortBy("start", wordTimeIntervals);
  },
);

const selectLastVersion = createSelector(wordsAdapterSelectors.selectById, (words) => words?.lastVersion);

export const wordsSelectors = {
  ...wordsAdapterSelectors,
  selectAbsoluteSequenceWords,
  selectAbsoluteFootageWords,
  selectLastVersion,
};

const words = createModel<RootModel>()({
  state: wordsAdapter.getInitialState(),

  reducers: {
    setWords: (state, payload: WordsModel) => wordsAdapter.setOne(state, payload),
  },

  effects: (dispatch) => ({
    async updateWords(variables: UpdateBulkOfWordsInTranscriptMutationVariables) {
      const response = await apiClient.updateWords(variables);
      // eslint-disable-next-line no-console
      console.log("[cc] updateWords response", { response });
    },
    async fetchWords(
      variables: GetWordsByExternalAssetIdQueryVariables & { sequenceSid: string; useV2ClosedCaptions: boolean },
    ) {
      let fetchedWords: gqlWord[] | V2_Word[] = [];

      if (variables.useV2ClosedCaptions) {
        const wordsV2 = await apiClient.getWords(variables);
        fetchedWords = wordsV2?.words ?? [];
      } else {
        fetchedWords = await apiClient.getSequenceCaptions({ sequenceSid: variables.sequenceSid });
      }
      dispatch.words.setWords({
        footageId: variables.footageId,
        words: fetchedWords,
        lastVersion: Math.max(...fetchedWords!.map((word) => word?.version!)),
      });

      // eslint-disable-next-line no-console
      console.log("[cc] fetchWords", {
        footageId: variables.footageId,
        words: fetchedWords,
        lastVersion: Math.max(...fetchedWords!.map((word) => word?.version!)),
      });

      // we need to support both v1 and v2 captions for the content selector when we are in manual create mode
      if (!variables.useV2ClosedCaptions) {
        dispatch.sequenceCaptions.setSequenceCaptions({ sid: variables.sequenceSid, words: fetchedWords as gqlWord[] });
      }
      return {
        footageId: variables.footageId,
        words: fetchedWords,
        lastVersion: Math.max(...fetchedWords!.map((word) => word?.version!)),
      };
    },
    async fetchFootageWords(variables: GetFootageTranscriptQueryVariables) {
      const { words: footageWords } = await apiClient.getFootageTranscript(variables);

      dispatch.words.setWords({
        footageId: variables.id,
        words: footageWords ?? [],
        lastVersion: Math.max(...footageWords!.map((word) => word?.version!)),
      });
    },
  }),
});

export default words;
