import { useCallback, useEffect, useMemo } from "react";
import { EditRules } from "src/network/graphql/generatedGraphqlSDK";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/models/store";
import { presetSelectors } from "src/models/Preset.model";
import useEditRules from "src/hooks/useEditRules";
import { AssetType, FolderType } from "src/constants/model.constants";
import { folderStructureSelectors } from "src/models/FolderStructure.model";

export function useBrandkitFormSetters(formContext: { watch?: any; setValue?: any }, usersCurrentPresetSid: string) {
  const { watch, setValue } = formContext;
  const dispatch = useDispatch();
  const usersCurrentPreset = useSelector((state: RootState) => presetSelectors.selectById(state, usersCurrentPresetSid ?? "")); // prettier-ignore
  const defaultPreset = useSelector((state: RootState) => presetSelectors.getDefaultPreset(state));
  const isCreatingBrandKit = useSelector((state: RootState) => state.loading.effects.presets.createPreset.loading);
  const isUpdatingBrandKit = useSelector((state: RootState) => state.loading.effects.presets.updatePreset.loading);
  const packages = useSelector((state: RootState) => folderStructureSelectors.selectVisualPackages(state, FolderType.LottiePackage, AssetType.LottiePackagePreview)); // prettier-ignore
  const isLoadingVisualPackages = useSelector(({ loading: { models } }: RootState) => models.assets.loading || models.folderStructures.loading); // prettier-ignore

  const defaultVideoTypeSid = watch("brandKit.SORs.defaultVideoType");
  const selectedEditRulesSid = watch("brandKit.SORs.editRulesSid");
  const currentEditRules = watch("brandKit.SORs.editRules");
  const tempSelectedEditRulesSid = watch("brandKit.temporaryPresetValues.SORs.editRulesSid");
  const { videoTypeSid, originalValuesEditRules, setEditRules, updatedEditRules } = useEditRules({
    currentPreset: usersCurrentPreset,
  });
  const notDefaultAssets = useMemo(
    () =>
      usersCurrentPreset?.assets
        ?.filter((asset) => asset && !asset.isDefault)
        .map((asset) => ({
          assetSid: asset?.assetSid,
          usageType: asset?.usageType,
          isDefault: false,
        })) ?? [],
    [usersCurrentPreset?.assets],
  );

  const setLogoFromCurrentPreset = useCallback(() => {
    const logoAssetId = usersCurrentPreset?.assets?.find((asset) => asset?.isDefault)?.assetSid;
    setValue("brandKit.logo.assetId", logoAssetId ?? "", { shouldValidate: true });
  }, [setValue, usersCurrentPreset?.assets]);

  const setColorsFromCurrentPreset = useCallback(() => {
    const logoColors = usersCurrentPreset?.colors;
    setValue(
      "brandKit.colors",
      logoColors?.map((colObj) => ({
        dominancy: colObj?.dominancy ?? 0,
        color: colObj?.color ?? "",
        percent: colObj?.percent ?? 0,
        grayness: colObj?.grayness ?? 0,
      })),
      { shouldValidate: true },
    );
  }, [setValue, usersCurrentPreset?.colors]);

  const setVisualsFromCurrentPreset = useCallback(() => {
    const visualPackageId = usersCurrentPreset?.defaultVisualPackage;
    setValue("brandKit.visuals.visualPackageId", visualPackageId ?? "", { shouldValidate: true });
  }, [setValue, usersCurrentPreset?.defaultVisualPackage]);

  const setInitialSORs = useCallback(() => {
    if (videoTypeSid && !defaultVideoTypeSid) {
      setValue("brandKit.SORs.defaultVideoType", videoTypeSid, { shouldValidate: true });
      setValue("brandKit.temporaryPresetValues.SORs.defaultVideoType", videoTypeSid!, {
        shouldValidate: true,
      });
    }
  }, [videoTypeSid, defaultVideoTypeSid, setValue]);

  const setTemporaryPresetData = useCallback(() => {
    const logoAssetId = defaultPreset?.assets?.find((asset) => asset?.isDefault)?.assetSid;
    logoAssetId && setValue("brandKit.temporaryPresetValues.logo.assetId", logoAssetId ?? "");

    const logoColors = defaultPreset?.colors;
    logoColors &&
      setValue(
        "brandKit.temporaryPresetValues.colors",
        logoColors?.map((colObj) => ({
          dominancy: colObj?.dominancy ?? 0,
          color: colObj?.color ?? "",
          percent: colObj?.percent ?? 0,
          grayness: colObj?.grayness ?? 0,
        })),
      );

    const visualPackageId = defaultPreset?.defaultVisualPackage;
    visualPackageId && setValue("brandKit.temporaryPresetValues.visuals.visualPackageId", visualPackageId ?? "");
  }, [defaultPreset?.assets, defaultPreset?.colors, defaultPreset?.defaultVisualPackage, setValue]);

  const updateCurrentPreset = useCallback(async () => {
    const currentAssetsArray = [...notDefaultAssets];
    currentAssetsArray.push({ assetSid: watch("brandKit.logo.assetId"), usageType: null, isDefault: true });

    await dispatch.presets.updatePreset({
      sid: usersCurrentPresetSid,
      values: {
        assets: currentAssetsArray,
        colors: watch("brandKit.colors"),
        defaultVisualPackage: watch("brandKit.visuals.visualPackageId"),
        defaultVideoType: watch("brandKit.SORs.defaultVideoType"),
        editRules: setEditRules({
          fieldsToPick: ["name", "volume", "noContentVolume"],
          currentEditedData: watch("brandKit.SORs.editRules") as EditRules,
          fullData: originalValuesEditRules as EditRules,
        }),
      },
    });
  }, [dispatch.presets, notDefaultAssets, originalValuesEditRules, setEditRules, usersCurrentPresetSid, watch]);

  useEffect(() => {
    if (usersCurrentPreset) {
      setLogoFromCurrentPreset();
      setColorsFromCurrentPreset();
      setVisualsFromCurrentPreset();
    }
  }, [setColorsFromCurrentPreset, setLogoFromCurrentPreset, setVisualsFromCurrentPreset, usersCurrentPreset]);

  useEffect(() => {
    // ignore changes when create or update preset
    if (isCreatingBrandKit || isUpdatingBrandKit) return;
    if (originalValuesEditRules) {
      if (selectedEditRulesSid !== originalValuesEditRules.sid) {
        setValue("brandKit.SORs.editRulesSid", originalValuesEditRules.sid!, { shouldValidate: true });
        setValue("brandKit.SORs.editRules", { ...currentEditRules!, ...updatedEditRules }, { shouldValidate: true });
      }

      if (tempSelectedEditRulesSid !== originalValuesEditRules.sid) {
        setValue("brandKit.temporaryPresetValues.SORs.editRulesSid", originalValuesEditRules.sid!, {
          shouldValidate: true,
        });
        setValue(
          "brandKit.temporaryPresetValues.SORs.editRules",
          { ...currentEditRules!, ...updatedEditRules },
          { shouldValidate: true },
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentEditRules,
    originalValuesEditRules,
    selectedEditRulesSid,
    setValue,
    tempSelectedEditRulesSid,
    updatedEditRules,
    usersCurrentPreset?.editRules,
  ]);

  useEffect(() => {
    if (!packages.length && !isLoadingVisualPackages) {
      dispatch.folderStructures.fetchFolderStructuresByType(FolderType.LottiePackage);
      dispatch.assets.fetchAssets({ filter: { typeIn: [AssetType.LottiePackagePreview] } });
    }
  }, [dispatch.assets, dispatch.folderStructures, dispatch.session, isLoadingVisualPackages, packages.length]);

  useEffect(() => {
    if (!defaultVideoTypeSid) {
      dispatch.session.getVideoTypes();
      dispatch.session.getEditRules();
    }
  }, [defaultVideoTypeSid, dispatch]);

  return {
    setTemporaryPresetData,
    updateCurrentPreset,
    setInitialSORs,
  };
}
