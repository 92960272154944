import styled from "styled-components/macro";

import Icon from "src/components/common/Icon";
import { H3 } from "src/components/common/layout/typography.styled";
import { ModalComponentProps } from "src/components/providers/ModalProvider";
import { themeColor } from "src/utils/styledComponents.utils";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  height: 100%;
  width: 600px;

  * {
    font-family: "Open Sans", sans-serif !important;
  }
`;

const MessageCont = styled.div`
  width: 90%;
  text-align: center;
  margin-block: 20px;
  white-space: pre-line;
`;

const CloseDiv = styled.div`
  position: absolute;
  top: 1px;
  right: 1px;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
    stroke: ${themeColor("gray.400")};
  }
`;

export default function MaxChaptersLimitReachedModal({ close }: ModalComponentProps) {
  return (
    <Container>
      <CloseDiv onClick={close}>
        <Icon.Close />
      </CloseDiv>
      <H3 style={{ textAlign: "center", paddingBottom: 10 }}>Maximum Trimming Limit!</H3>

      <MessageCont>{`You've reached the maximum allowed number\n of video trims on this project.`}</MessageCont>
    </Container>
  );
}
