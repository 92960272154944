import { createModel } from "@rematch/core";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";

import { RootState } from "src/models/store";
import { RootModel } from "src/models/index";
import { selectId } from "src/models/selectId";

import apiClient from "src/network/ApiClient";
import {
  Preset,
  CreatePresetMutationVariables,
  UpdatePresetMutationVariables,
  DeletePresetMutationVariables,
  GetPresetsQueryVariables,
  SetDefaultPresetMutationVariables,
} from "src/network/graphql/generatedGraphqlSDK"; // TODO: use * as gql
import { PresetStatus } from "src/constants/model.constants";

const presetAdapter = createEntityAdapter<Preset>({ selectId });
const presetAdapterSelectors = presetAdapter.getSelectors((state: RootState) => state.presets);

const getDefaultPreset = createSelector(
  presetAdapterSelectors.selectAll,
  (presets) => presets.find((preset: Preset) => preset.status === PresetStatus.Default) ?? presets[0],
);

// TODO: change
const isUserFirstExperience = createSelector(
  presetAdapterSelectors.selectAll,
  (presets) => !presets?.some((x) => !x.exampleAsset),
);

const getExamplePreset = createSelector(presetAdapterSelectors.selectAll, (presets) =>
  presets.find((preset: Preset) => preset.exampleAsset),
);

export const presetSelectors = {
  ...presetAdapterSelectors,
  getDefaultPreset,
  isUserFirstExperience,
  getExamplePreset,
};

const presets = createModel<RootModel>()({
  state: presetAdapter.getInitialState(),

  reducers: {
    setPreset: (state, payload: Preset) => presetAdapter.setOne(state, payload),
    setPresets: (state, payload: Preset[]) => presetAdapter.setMany(state, payload),
    removePreset: (state, payload: string) => presetAdapter.removeOne(state, payload),
    resetPresetState: () => presetAdapter.getInitialState(),
  },

  effects: (dispatch) => ({
    async getPresets(variables: GetPresetsQueryVariables) {
      const fetchedPresets = await apiClient.getPresets(variables);
      dispatch.presets.setPresets(fetchedPresets);
    },

    async createPreset(variables: CreatePresetMutationVariables) {
      const preset = await apiClient.createPreset(variables);
      dispatch.presets.setPreset(preset);
      return preset.sid;
    },

    async updatePreset(variables: UpdatePresetMutationVariables) {
      const preset = await apiClient.updatePreset(variables);
      dispatch.presets.setPreset(preset);
      return preset.sid!;
    },

    async deletePreset(variables: DeletePresetMutationVariables) {
      const deletedStatus = await apiClient.deletePreset(variables);
      dispatch.presets.removePreset(variables.sid);
      return deletedStatus;
    },

    async setDefaultPreset(variables: SetDefaultPresetMutationVariables) {
      const preset = await apiClient.setDefaultPreset(variables);
      dispatch.presets.setPreset(preset);
    },
  }),
});

export default presets;
