import { RefObject, useMemo } from "react";
import styled from "styled-components/macro";
import { themeColor } from "src/utils/styledComponents.utils";
import { ifProp } from "styled-tools";
import { useVideoCrop } from "src/components/features/VideoCropper/providers/VideoCropProvider/VideoCropContext";

type BorderType = {
  left: boolean;
  top: boolean;
  right: boolean;
  bottom: boolean;
};

const borderWith = "4px";

const Stage = styled.div<{ border: BorderType }>`
  position: absolute;
  pointer-events: none;
  height: 100%;
  width: 100%;
  border-style: solid;
  border-color: ${themeColor("blue.500")};
  border-left-width: ${ifProp("border.left", `${borderWith}`, "0")};
  border-top-width: ${ifProp("border.top", `${borderWith}`, "0")};
  border-right-width: ${ifProp("border.right", `${borderWith}`, "0")};
  border-bottom-width: ${ifProp("border.bottom", `${borderWith}`, "0")};
`;

interface CropGuidProps extends React.PropsWithChildren<{}> {
  isDragging?: boolean;
  cropStageRef: RefObject<HTMLDivElement>;
}

export default function CropGuide({ children, cropStageRef, isDragging = false }: CropGuidProps) {
  const { draggingPosition } = useVideoCrop();

  // Configure the stage border (left, top, right, bottom) based on the drag position and stage size
  const border: BorderType = useMemo(() => {
    if (!cropStageRef.current || !isDragging) return { left: false, top: false, right: false, bottom: false };

    const { x, y, w, h } = draggingPosition;
    const { width, height } = cropStageRef.current.getBoundingClientRect();
    const left = x === 0;
    const top = y === 0;
    const right = Math.round(width) === x + parseFloat(w as string);
    const bottom = Math.round(height) === y + parseFloat(h as string);
    return { left, top, right, bottom };
  }, [draggingPosition, cropStageRef, isDragging]);

  return <Stage border={border}>{children}</Stage>;
}
