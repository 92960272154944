// TODO: move

import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uniq } from "lodash/fp";

import { Chapter, ClipType } from "src/network/graphql/generatedGraphqlSDK";

import { AspectRatio } from "src/constants/video.constants";

import { Dispatch, RootState } from "src/models/store";
import { sequenceSelectors } from "src/models/Sequence.model";
import { sequenceAudioSamplesSelectors } from "src/models/SequenceAudioSamples.model";

import { useAppConfig } from "src/components/providers/AppConfigProvider";
import { chaptersToChapterTimeIntervals } from "src/components/features/VideoTrimmer/common/converters";
import { VideoTrimmerProps } from "src/components/features/VideoTrimmer/VideoTrimmer";
import { wordsSelectors } from "src/models/Words.model";
import useInitiatedHistory from "src/components/features/VideoTrimmer/common/hooks/useInitiatedHistory";
import { cropSelectors } from "src/models/Crop.model";
import useBeforeUnloadBlocker from "src/hooks/useBeforeUnloadBlocker";
import { Word } from "src/types/video-trimmer.types";
import { UseHistoryStateReturnValue } from "src/hooks/useHistoryState";
import { assetSelectors } from "src/models/Asset.model";
import { sequenceCaptionsSelectors } from "src/models/SequenceCaptions.model";
import useLocalStorage from "src/hooks/useLocalStorage";
import { footageSelectors } from "src/models/Footage.model";

export default function useInitSequenceVideoEditor(sequenceSid: string, inManualCreate = false): VideoTrimmerProps {
  const FETCH_SEQUENCE_AUDIO_SAMPLES_MAX_RETRIES = 2;
  const [volumeMapBlackList, setValue] = useLocalStorage("volumeMapBlackList", "[]");
  const [sequenceAudioSamplesFetchRetries, setSequenceAudioSamplesFetchRetries] = useState(1);
  const { PLAYBACK_URL } = useAppConfig();
  const appConfig = useAppConfig();
  const dispatch = useDispatch<Dispatch>();
  const sequence = useSelector((state: RootState) => sequenceSelectors.selectById(state, sequenceSid));
  const chapters = useSelector((state: RootState) => sequenceSelectors.selectVideoChapters(state, sequenceSid));
  const firstChapterAssetSid = chapters?.find((chapter) => chapter?.assetSid)?.assetSid!;
  const asset = useSelector((state: RootState) => assetSelectors.selectById(state, firstChapterAssetSid));
  const videoSrc = useSelector((state: RootState) => sequenceSelectors.selectVideoSrc(state, sequenceSid, PLAYBACK_URL)); // prettier-ignore
  const videoDuration = useSelector((state: RootState) => sequenceSelectors.selectVideoDuration(state, sequenceSid));
  const videoAudioSamples = useSelector((state: RootState) => sequenceAudioSamplesSelectors.selectById(state, sequenceSid)?.audioSamples ?? []); // prettier-ignore
  const footageSid = sequence?.footageSid! || asset?.footageSid!;
  const footage = useSelector((state: RootState) => (sequence?.footageSid ? footageSelectors.selectById(state, footageSid) : null)); // prettier-ignore
  const clipData = useSelector((state: RootState) => sequence?.sid && footage && footageSelectors.selectClipDataByFootageAndExternalSequenceSid(state, footage, sequence?.sid)); // prettier-ignore
  const initialWords = useSelector((state: RootState) => wordsSelectors.selectAbsoluteSequenceWords(state, footageSid, appConfig, sequenceSid)); // prettier-ignore
  const initialCaptionWords = useSelector((state: RootState) => sequenceCaptionsSelectors.selectAbsoluteSequenceWords(state, sequenceSid, appConfig)); // prettier-ignore
  const initialCrops = useSelector((state: RootState) => cropSelectors.selectTimelineCropData(state, sequenceSid));
  const videoAspectRatio = (sequence?.aspectRatio ?? "1:1") as AspectRatio;
  const useV2ClosedCaptions = inManualCreate ? false : sequence?.useV2ClosedCaptions;
  const isUpdatingSequence = useSelector(
    (state: RootState) =>
      !!state.loading.effects.sequences.reorderSequenceChapters.loading ||
      !!state.loading.effects.words.updateWords.loading ||
      !!state.loading.effects.crop.updateCropDetails.loading,
  );

  const isTopicByTimeConcated = useMemo(
    () => !!(clipData !== "" && clipData && clipData.type && clipData.type === ClipType.TopicByTimeConcated),
    [clipData],
  );

  const initialChapters = useMemo(
    () => chaptersToChapterTimeIntervals((sequence?.chapters ?? []) as Chapter[]),
    [sequence?.chapters],
  );

  const chapterDomains = useMemo(
    () => initialChapters.filter((chapter) => chapter.isSource).map((chapter) => chapter.domain),
    [initialChapters],
  );

  const chaptersHistory = useInitiatedHistory(initialChapters, []);
  const wordsHistory = useInitiatedHistory(
    useV2ClosedCaptions ? (initialWords as Word[]) : initialCaptionWords,
    [],
  ) as UseHistoryStateReturnValue<Word[]>;
  const cropsHistory = useInitiatedHistory(initialCrops, {
    crops: [],
    style: { color: undefined, blur: undefined, brandColorIndex: undefined },
  });

  useEffect(() => {
    if (isUpdatingSequence) return;
    dispatch.sequences.fetchSequence({ sequenceSid });

    if (firstChapterAssetSid && footageSid) {
      dispatch.words.fetchWords({
        footageId: footageSid,
        externalAssetSid: firstChapterAssetSid,
        sequenceSid,
        useV2ClosedCaptions: !!useV2ClosedCaptions,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, sequenceSid, isUpdatingSequence, footageSid, firstChapterAssetSid]);

  useEffect(() => {
    if (isUpdatingSequence) return;

    dispatch.crop.fetchCropDetails({
      externalSequenceSid: sequenceSid,
      outputAspectRatio: sequence?.aspectRatio!,
    });
  }, [sequence?.aspectRatio, sequenceSid, dispatch, isUpdatingSequence]);

  useEffect(() => {
    if (isUpdatingSequence) return;
    if (sequence?.sid) {
      // TODO: use axios retry instead
      const fetch = async () => {
        try {
          await dispatch.sequenceAudioSamples.fetchSequenceAudioSamples(sequence);
        } catch {
          // increase retries
          setSequenceAudioSamplesFetchRetries(sequenceAudioSamplesFetchRetries + 1);
        }
      };

      let blackList: string[] = [];
      try {
        // get black list from local storage
        blackList = JSON.parse(volumeMapBlackList) || [];
      } catch (error) {
        blackList = [];
      }

      try {
        const isInBlacklist = blackList.includes(sequence.sid);
        // fetch audio samples if not in blacklist and retries are less than max
        if (!isInBlacklist && sequenceAudioSamplesFetchRetries <= FETCH_SEQUENCE_AUDIO_SAMPLES_MAX_RETRIES) {
          fetch();
        } else if (!isInBlacklist) {
          // add sequence to blacklist
          blackList.push(sequence.sid);
          const uniqueBlackList = uniq(blackList);
          setValue(JSON.stringify(uniqueBlackList));
        }
      } catch {
        // eslint-disable-next-line no-console
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, sequence?.sid, isUpdatingSequence, sequenceAudioSamplesFetchRetries]);

  useBeforeUnloadBlocker(chaptersHistory.canUndo || wordsHistory.canUndo || cropsHistory.canUndo);

  return {
    sequenceSid,
    videoSrc,
    videoDuration,
    videoAspectRatio,
    videoAudioSamples,
    chapterDomains,
    chaptersHistory,
    wordsHistory,
    cropsHistory,
    isTopicByTimeConcated,
  };
}
