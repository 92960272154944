import { range } from "lodash/fp";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "usehooks-ts";

import { footageSelectors } from "src/models/Footage.model";
import { userSelectors } from "src/models/User.model";
import { Dispatch, RootState } from "src/models/store";
import {
  EditRules,
  Footage,
  FootageInput,
  FootageOrigin,
  FootageSortFields,
  FootageStatus,
  SortDirection,
} from "src/network/graphql/generatedGraphqlSDK";

import socketClient from "src/network/SocketClient";

import useEndScrollHandler from "src/hooks/useEndScrollHandler";

import WhiteRainbowButton from "src/components/common/buttons/WhiteRainbowButton";
import LoadingItemPlug from "src/components/common/loaders/LoadingItemPlug";
import {
  Container,
  GridContainer,
  GridItem,
  ScrolableContainer,
  TopBlock,
} from "src/components/features/SmartLibrary/SmartLibrary.styled";
import FootagePreviewItem from "src/components/features/SmartLibrary/footagesLibrary/FootagePreviewItem";
import { UploaderTypes, useUploaderContext } from "src/components/providers/UploaderProvider";

import { useNavigate } from "react-router-dom";
import * as footageAnalytics from "src/analytics/footage.analytics";
import UploadFootageButton from "src/components/features/SmartLibrary/footagesLibrary/UploadFootageButton";
import { PlanStatus } from "src/constants/model.constants";
import { useCreationCallback } from "src/hooks/useCreationCallback";
import useEditRules from "src/hooks/useEditRules";
import useLocalStorage from "src/hooks/useLocalStorage";
import { presetSelectors } from "src/models/Preset.model";
import { absoluteRoutes } from "src/utils/routes.utils";
import { getVideoFileInfo } from "src/utils/uploader.utils";
import { isEmpty } from "lodash";
import { FootageUpdateEventDataType, onFootageUpdateEvent } from "../smartLibrary.utils";

const FOOTAGES_AMOUNT_ON_PAGE = 60;

interface FootageLibraryProps {
  boardId?: string | null;
}

export default function FootageLibrary({ boardId = null }: FootageLibraryProps) {
  boardId = boardId === "null" ? null : boardId;
  const { createUploader, getUploader } = useUploaderContext();
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();

  const currentUser = useSelector((state: RootState) => userSelectors.selectById(state, state.session.userId));
  const defaultPreset = useSelector((state: RootState) => presetSelectors.getDefaultPreset(state));
  const footageIds = useSelector((state: RootState) => state.footage.pagination.footageIds);
  const footagesLoading = useSelector(
    (state: RootState) =>
      state.loading.effects.footage.fetchFootages.loading ||
      state.loading.effects.footage.fetchFootagesByBoardId.loading,
  );
  const startCreationProcess = useCreationCallback();
  const containerRef = useRef<HTMLDivElement>(null);
  const hasNextPage = useSelector(footageSelectors.selectHasNextPage);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [uploadedFootagesIdsFromStorage, setUploadedFootagesIdsFromStorage] = useLocalStorage<string[]>(
    "uploadedFootagesIds",
    [],
  );
  const footageFetchSuccess = useSelector((state: RootState) => state.loading.effects.footage.fetchFootages.success);
  const [footagesFetchedOnce, setFootagesFetchedOnce] = useState(false);

  const isOneColumn = useMediaQuery("(max-width: 600px)");

  const { videoTypeSid, originalValuesEditRules, updatedEditRules, setEditRules } = useEditRules({
    currentPreset: defaultPreset,
  });

  const [SORSData, setSORSData] = useState({
    defaultVideoTypeSid: "",
    selectedEditRulesSid: "",
    currentEditRules: {},
  });

  const onUploadDrop = useCallback(
    async (files: File[], isDragging: boolean) => {
      // track upload component select files
      footageAnalytics.trackUploadComponentSelectFiles({
        trigger: isDragging ? "dragAndDrop" : "filePicker",
        filesAmount: files.length,
        multiFiles: files.length > 1,
      });

      const footagesIds: string[] = [];

      const promises = files.map(async (file) => {
        const videoMediaInfo = await getVideoFileInfo(file);
        const footageData: FootageInput = {
          origin: FootageOrigin.UserUpload,
          filename: file.name.substring(0, file.name.lastIndexOf(".")) || file.name,
          languageCode: currentUser?.languageCode || "en-US",
        };

        if (defaultPreset?.sid) {
          footageData.automationData = {
            externalPresetSid: defaultPreset?.sid,
            editRules: setEditRules({
              fieldsToPick: ["name"],
              currentEditedData: SORSData.currentEditRules as EditRules,
              fullData: originalValuesEditRules as EditRules,
            }),
          };
        }

        const footage = await dispatch.footage.createFootage({
          footage: footageData,
        });

        // track footage creation
        footageAnalytics.trackCreateFootage(footage, "platform-library", videoMediaInfo || {});

        await createUploader(footage.id, file, UploaderTypes.FOOTAGE);

        dispatch.footage.addNewFootage(footage);

        footagesIds.push(footage.id);
      });

      // eslint-disable-next-line no-console
      await Promise.all(promises).catch(console.error);
      setUploadedFootagesIdsFromStorage(footagesIds);
      navigate(absoluteRoutes.automaticCreate.self);

      // eslint-disable-next-line no-console
      return Promise.all(promises).catch(console.error);
    },
    [
      currentUser?.languageCode,
      defaultPreset?.sid,
      dispatch.footage,
      createUploader,
      setEditRules,
      originalValuesEditRules,
      SORSData.currentEditRules,
      setUploadedFootagesIdsFromStorage,
      navigate,
    ],
  );

  const fetchMore = useCallback(async () => {
    const payload = {
      boardId,
      sorting: {
        field: FootageSortFields.CreatedAt,
        direction: SortDirection.Desc,
      },
      paging: {
        offset: footageIds.length,
        limit: FOOTAGES_AMOUNT_ON_PAGE,
      },
      filter: {
        status: {
          notIn: [FootageStatus.Deleted, FootageStatus.FailedAndDeleted, FootageStatus.UploadingAndDeleted],
        },
      },
    };
    if (!footagesLoading && (boardId === "null" || isEmpty(boardId)) && hasNextPage) {
      await dispatch.footage.fetchFootages(payload);
    } else if (!footagesLoading && boardId && hasNextPage) {
      await dispatch.footage.fetchFootagesByBoardId({ ...payload, boardId });
    }
  }, [footagesLoading, hasNextPage, dispatch.footage, boardId, footageIds.length]);

  useEffect(() => {
    dispatch.footage.reset();
    setFootagesFetchedOnce(false);
  }, [boardId, dispatch.footage]);

  useEffect(() => {
    const payload = {
      boardId,
      sorting: {
        field: FootageSortFields.CreatedAt,
        direction: SortDirection.Desc,
      },
      paging: {
        offset: 0,
        limit: FOOTAGES_AMOUNT_ON_PAGE,
      },
      filter: {
        status: {
          notIn: [FootageStatus.Deleted, FootageStatus.FailedAndDeleted, FootageStatus.UploadingAndDeleted],
        },
      },
    };
    const fetchFirstAll = async () => {
      await dispatch.footage.fetchFootages(payload);
    };

    const fetchFirstByBoardId = async () => {
      boardId &&
        (await dispatch.footage.fetchFootagesByBoardId({
          ...payload,
          boardId,
        }));
    };

    if (!footagesFetchedOnce && (boardId === "null" || isEmpty(boardId))) {
      fetchFirstAll();
    } else if (!footagesFetchedOnce && boardId) {
      fetchFirstByBoardId();
    }
    const onFootageUpdate = (event: FootageUpdateEventDataType) => {
      onFootageUpdateEvent(dispatch, event);
    };

    socketClient.on("footages", onFootageUpdate);

    return () => {
      socketClient.off("footages", onFootageUpdate);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch.footage, footagesFetchedOnce, boardId]);

  useEffect(() => {
    dispatch.plans.getPlans({
      filter: {
        statusIn: [PlanStatus.Active, PlanStatus.Default],
      },
    });
    dispatch.session.getVideoTypes();
    dispatch.session.getEditRules();
  }, [dispatch]);

  useEffect(() => {
    if (videoTypeSid && !SORSData.defaultVideoTypeSid) {
      setSORSData((prevState) => ({
        ...prevState,
        defaultVideoTypeSid: videoTypeSid,
      }));
    }
  }, [SORSData.defaultVideoTypeSid, videoTypeSid]);

  useEffect(() => {
    if (!SORSData.defaultVideoTypeSid) {
      return;
    }
    if (
      originalValuesEditRules &&
      SORSData.selectedEditRulesSid !== originalValuesEditRules.sid
    ) {
      setSORSData((prevState) => ({
        ...prevState,
        selectedEditRulesSid: originalValuesEditRules.sid!,
        currentEditRules: { ...prevState.currentEditRules!, ...updatedEditRules },
      }));
    }
  }, [SORSData.defaultVideoTypeSid, SORSData.selectedEditRulesSid, originalValuesEditRules, updatedEditRules]); // prettier-ignore

  const onLanguageSubmit = useCallback(
    async (footage: Footage, selectedLanguage: string) => {
      if (footage && selectedLanguage) {
        await dispatch.footage.updateFootage({
          id: footage.id,
          update: { languageCode: selectedLanguage },
        });
        footageAnalytics.trackFootageLanguageChange({
          id: footage.id,
          languageCode: selectedLanguage,
        } as Footage);
        getUploader(footage.id)?.start();
      }
    },
    [dispatch, getUploader],
  );

  useEndScrollHandler(containerRef, async () => {
    fetchMore();
  });

  useEffect(() => {
    if (footageFetchSuccess && !footagesFetchedOnce) {
      setFootagesFetchedOnce(true);
    }
  }, [footageFetchSuccess, footagesFetchedOnce]);

  return (
    <Container>
      <TopBlock>
        <WhiteRainbowButton
          onClick={() => {
            startCreationProcess("create-button", boardId);
          }}
          text="+ Create Video"
          variant="white"
          width={isOneColumn ? "100%" : "162px"}
        />
      </TopBlock>
      <ScrolableContainer ref={containerRef}>
        <GridContainer>
          <GridItem>
            <UploadFootageButton
              handleDrop={onUploadDrop}
              onClick={() => startCreationProcess("upload-thumbnail", boardId)}
            />
          </GridItem>
          {footageIds.map((footageId) => (
            <FootagePreviewItem
              boardId={boardId}
              key={footageId}
              footageId={footageId as string}
              onLanguageSubmit={onLanguageSubmit}
            />
          ))}
          {footagesLoading &&
            range(0, 9).map((x) => (
              <GridItem key={x}>
                <LoadingItemPlug key={x} />
              </GridItem>
            ))}
        </GridContainer>
      </ScrolableContainer>
    </Container>
  );
}
