import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { Dispatch } from "src/models/store";

import { H2 } from "src/components/common/layout/typography.styled";
import { CircularLoader } from "src/components/common/loaders/CircularLoader";
import { Container } from "src/components/auth/common/layout.styled";

export default function Logout() {
  const dispatch = useDispatch<Dispatch>();

  useEffect(() => {
    dispatch.users.logout();
  }, [dispatch]);

  return (
    <Container>
      <Container>
        <H2>Logging out..</H2>
        <CircularLoader size={100} marginTop={50} />
      </Container>
    </Container>
  );
}
