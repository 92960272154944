import { themeColor } from "src/utils/styledComponents.utils";
import { css } from "styled-components";

export const CheckerboardBg = css`
  background: ${themeColor("white")};
  background-image: linear-gradient(45deg, ${themeColor("gray.300", 0.5)} 25%, transparent 25%),
    linear-gradient(-45deg, ${themeColor("gray.300", 0.5)} 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, ${themeColor("gray.300", 0.5)} 75%),
    linear-gradient(-45deg, transparent 75%, ${themeColor("gray.300", 0.5)} 75%);
  background-size: 10px 10px;
  background-position: 0 0, 0 5px, 5px -5px, -5px 0px;
`;
