import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useFormContext } from "react-hook-form";
import * as yup from "yup";

import { ColorAdd } from "src/network/graphql/generatedGraphqlSDK";

import { StepPath } from "src/components/features/AutomaticCreateWizard/steps/steps";

const automaticCreateFormSchema = yup
  .object({
    uploading: yup
      .object({
        footagesArray: yup
          .array(
            yup.object({
              footageId: yup.string().required(),
              languageCode: yup.string().required(),
              thumbnailUrl: yup.string().nullable(),
              origin: yup.string().required(),
            }),
          )
          .required()
          .min(1)
          .required(),
        usersCurrentPresetSid: yup.string().nullable(),
      })
      .required(),

    brandKit: yup
      .object({
        temporaryPresetValues: yup
          .object({
            logo: yup.object({
              assetId: yup.string(),
            }),
            colors: yup.array(
              yup.object({
                dominancy: yup.number(),
                color: yup.string(),
                percent: yup.number(),
                grayness: yup.number(),
              } satisfies Record<keyof ColorAdd, yup.BaseSchema>),
            ),
            visuals: yup.object({
              visualPackageId: yup.string(),
            }),
            SORs: yup.object({
              defaultVideoType: yup.string().required(),
              editRulesSid: yup.string().required(),
              editRules: yup
                .object({
                  useIntro: yup.boolean().optional().nullable(),
                  useOutro: yup.boolean().optional().nullable(),
                  useSpeakers: yup.boolean().optional().nullable(),
                  useTexts: yup.boolean().optional().nullable(),
                  useSlides: yup.boolean().optional().nullable(),
                  useMusic: yup.boolean().optional().nullable(),
                  useHighlight: yup.boolean().optional().nullable(),
                  useFrame: yup.boolean().optional().nullable(),
                })
                .required(),
            }),
          })
          .nullable(),

        logo: yup
          .object({
            assetId: yup.string().required(),
          })
          .required(),

        colors: yup
          .array(
            yup.object({
              dominancy: yup.number().required(),
              color: yup.string().required(),
              percent: yup.number().required(),
              grayness: yup.number().required(),
            } satisfies Record<keyof ColorAdd, yup.BaseSchema>),
          )
          .required(),

        visuals: yup
          .object({
            visualPackageId: yup.string().required(),
          })
          .required(),

        SORs: yup.object({
          defaultVideoType: yup.string().required(),
          editRulesSid: yup.string().required(),
          editRules: yup
            .object({
              useIntro: yup.boolean().optional().nullable(),
              useOutro: yup.boolean().optional().nullable(),
              useSpeakers: yup.boolean().optional().nullable(),
              useTexts: yup.boolean().optional().nullable(),
              useSlides: yup.boolean().optional().nullable(),
              useMusic: yup.boolean().optional().nullable(),
              useHighlight: yup.boolean().optional().nullable(),
              useFrame: yup.boolean().optional().nullable(),
            })
            .required(),
        }),
      })
      .required(),

    finish: yup.object({ completed: yup.boolean().required() }).required(),
  } satisfies Record<StepPath, yup.ObjectSchema<{}>>)
  .required();

type PartialSteps<Form extends object> = {
  [Step in keyof Form]: {
    [Field in keyof Form[Step]]+?: Form[Step][Field];
  };
};

export type AutomaticCreateForm = PartialSteps<yup.InferType<typeof automaticCreateFormSchema>>;

export const useAutomaticCreateForm = () =>
  useForm<AutomaticCreateForm>({
    mode: "onChange",
    resolver: yupResolver(automaticCreateFormSchema),
    defaultValues: {},
  });

export const useAutomaticCreateFormContext = useFormContext<AutomaticCreateForm>;
