import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useAppConfig } from "src/components/providers/AppConfigProvider";
import { assetSelectors } from "src/models/Asset.model";
import { RootState } from "src/models/store";

interface AssetThumbnailHookOptions {
  sid: string;
  aspectRatio: string;
  width: number;
}

export function useAssetThumbnailUrl({ sid, aspectRatio, width }: AssetThumbnailHookOptions) {
  const { CHUNKS_URL } = useAppConfig();
  const authToken = useSelector((state: RootState) => state.session.authToken);
  const asset = useSelector((state: RootState) => assetSelectors.selectById(state, sid));

  return useMemo(() => {
    if (!asset) {
      return null;
    }

    const { contentIdentifier } = asset;
    const thumbnailVersion = contentIdentifier?.thumbnailVersion || 0;

    return `${CHUNKS_URL}/asset/${asset.sid}/Content/${width}/${aspectRatio.replace(
      ":",
      "-",
    )}/${thumbnailVersion}.png?ps=${authToken}`;
  }, [asset, aspectRatio, CHUNKS_URL, authToken, width]);
}
