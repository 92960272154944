import styled from "styled-components/macro";
import { themeColor, themeZ } from "src/utils/styledComponents.utils";

export const Header = styled.div`
  z-index: ${themeZ("header")};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 55px;
  width: 100%;
  padding-inline: 30px;
  box-shadow: 0px 4px 4px 0px rgba(6, 33, 52, 0.02);
  background-color: ${themeColor("white")};

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const LeftSide = styled.div`
  height: 100%;
  width: fit-content;
  max-width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const RightSide = styled(LeftSide)`
  justify-content: flex-end;
  overflow: unset;
`;

export const BackBtn = styled.div`
  margin-right: 14px;
  height: 100%;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    color: ${themeColor("blue.200")};
    transform: rotate(90deg);
  }
`;

export const TextWrapper = styled.div`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 600;
  color: ${themeColor("gray.900")};
  padding-left: 5px;
  margin-left: 15px;
  min-width: 170px;
`;

export const HeaderElementWrapper = styled.div`
  margin-left: 20px;

  @media (max-width: 768px) {
    margin-left: 10px;
  }
`;
