/* eslint-disable no-async-promise-executor */
import toast from "react-hot-toast";

import { RematchDispatch } from "@rematch/core";
import * as sequenceAnalytics from "src/analytics/sequence.analytics";
import { TriggerOrigin } from "src/analytics/sequence.analytics";
import { AspectRatio } from "src/constants/video.constants";
import { RootModel } from "src/models";
import { footageSelectors } from "src/models/Footage.model";
import { sequenceSelectors } from "src/models/Sequence.model";
import store, { Dispatch } from "src/models/store";
import { Clip, ClipSequence, Footage, FootageOrigin, FootageStatus } from "src/network/graphql/generatedGraphqlSDK";
import appMonitoringService from "src/services/AppMonitoring.service";
import { addDays, differenceInDays } from "date-fns";

export const onSequenceDuplicate = async (
  dispatch: Dispatch,
  footageId: string,
  clip: Clip,
  clipSequenceId: string,
  ratioTarget: AspectRatio,
  ratioSource: AspectRatio,
  trigger: TriggerOrigin,
  footageOrigin: FootageOrigin,
) => {
  const promise = new Promise(async (resolve, reject) => {
    try {
      const result = await dispatch.footage.cloneClipSequence({
        footageId,
        clipId: clip.id,
        id: clipSequenceId,
        input: {
          ratio: ratioTarget,
        },
      });
      sequenceAnalytics.duplicateSequence(
        trigger,
        footageId,
        clipSequenceId,
        ratioTarget !== ratioSource,
        ratioTarget,
        ratioSource,
        footageOrigin,
      );
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
  toast.promise(promise, {
    loading: "Duplicating project",
    success: "Project duplicated successfully",
    error: "Project duplication failed, try again",
  });
};

export const onSequenceTranslate = async (
  dispatch: Dispatch,
  footageId: string,
  clip: Clip,
  clipSequenceId: string,
  languageCodeTarget: string,
  languageCodeSource: string,
  trigger: TriggerOrigin,
  footageOrigin: FootageOrigin,
) => {
  if (languageCodeTarget !== languageCodeSource) {
    const promise = new Promise(async (resolve, reject) => {
      try {
        const result = await dispatch.footage.translateClipSequence({
          footageId,
          clipId: clip.id,
          id: clipSequenceId,
          input: {
            languageCode: languageCodeTarget,
          },
        });
        sequenceAnalytics.translateSequence(
          trigger,
          footageId,
          clipSequenceId,
          languageCodeTarget,
          languageCodeSource,
          footageOrigin,
        );
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
    toast.promise(promise, {
      loading: "Translating project",
      success: "Project translated successfully",
      error: "Project translation failed, try again",
    });
  }
};

export const onReplaceExampleSequence = async (
  dispatch: RematchDispatch<RootModel>,
  footageId: string,
  clipId: string,
  clipSequenceId: string,
) => {
  const promise = new Promise<ClipSequence>(async (resolve, reject) => {
    try {
      const result = await dispatch.footage.replaceExampleClipSequence({
        footageId,
        clipId,
        id: clipSequenceId,
      });
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
  toast.promise(promise, {
    loading: "Preparing project",
    success: "Project ready",
    error: "Project failed, try again",
  });
  return promise;
};

export const checkIfNeedToReplaceExampleSequence = async (
  dispatch: RematchDispatch<RootModel>,
  footageId: string,
  clipId: string,
  clipSequenceId: string,
) => {
  const promise = new Promise<ClipSequence | null>(async (resolve, reject) => {
    try {
      const footageOrigin = footageSelectors.selectFootageOrigin(store.getState(), footageId);
      // if footage is not from example project, resolve - not need to check if need to replace example sequence
      if (footageOrigin !== FootageOrigin.ExampleProject) {
        resolve(null);
        return;
      }
      const clipSequence = footageSelectors.selectClipSequence(store.getState(), footageId, clipId, clipSequenceId);
      let seq = sequenceSelectors.selectById(store.getState(), clipSequence?.externalSequenceId!);
      // if sequence is not in store, fetch it
      if (!seq?.footageSid) {
        // if return seq in null - need to replace example sequence
        seq = await dispatch.sequences.fetchSequence({ sequenceSid: clipSequence?.externalSequenceId! });
      }

      // seq footageSid is not the same as footageId, need to replace example sequence
      // seq can be null if seq in user 0 after fetch (above)
      if (seq?.footageSid !== footageId) {
        const replaceResponse = await onReplaceExampleSequence(dispatch, footageId, clipId, clipSequenceId);
        resolve(replaceResponse);
      } else {
        resolve(null);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.trace("checkIfNeedToReplaceExampleSequence error", error);
      appMonitoringService.log(error);
      reject();
    }
  });
  return promise;
};

export type FootageUpdateEventDataType = { data: Footage | { entity: Footage; deleted?: boolean } };

export const onFootageUpdateEvent = (dispatch: RematchDispatch<RootModel>, event: FootageUpdateEventDataType) => {
  const { entity } = event.data as { entity: Footage; deleted?: boolean };
  if (entity) {
    // eslint-disable-next-line no-console
    console.log(`Footage update event received, current status: '${entity.status}'`, { event });
    dispatch.footage.setUpdatedFootage(entity);
  } else {
    // Backward compatibility.
    const footage = event.data as Footage;
    // eslint-disable-next-line no-console
    console.log(`Footage update event received, current status: '${footage.status}'`, { event });
    dispatch.footage.setUpdatedFootage(footage);
  }
};

export const daysUntilExpiration = (createdAt: string, expiredDays: number) => {
  const createdDate = new Date(createdAt);
  const expirationDate = addDays(createdDate, expiredDays);
  const currentDate = new Date();
  return differenceInDays(expirationDate, currentDate);
};

export type OriginalVideoAvailability = {
  isAvailable: boolean;
  isProcessing: boolean;
  message: string;
};

export const getIsOriginalVideoAvailable = (footage: Footage): OriginalVideoAvailability => ({
  isAvailable: !!footage?.filesPaths?.previewFootage,
  isProcessing: footage?.origin !== FootageOrigin.ExampleProject ? footage?.status !== FootageStatus.Completed : false,
  message:
    footage?.origin === FootageOrigin.CreateProjectManually
      ? "Original Video is not available for Manually created videos"
      : "Original Video is only available for videos uploaded after May 30th, 2024",
});
