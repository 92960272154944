import { useEffect, useState } from "react";
import { SwiperSlide as BaseSwiperSlide } from "swiper/react";
import styled from "styled-components/macro";

import usePrevious from "src/hooks/usePrevious";
import { useSwiperContext } from "src/components/common/Swiper/Swiper";

export function MountIfActive({ children, index }: { children: JSX.Element; index: number }) {
  const { activeIndex, isSliding } = useSwiperContext();
  const prevIsSliding = usePrevious(isSliding);
  const isActive = activeIndex === index;
  const [shouldMount, setShouldMount] = useState(isActive);

  useEffect(() => {
    if (isActive) {
      setShouldMount(true);
    }
  }, [isActive]);

  useEffect(() => {
    if (!isSliding && prevIsSliding) {
      setShouldMount(isActive);
    }
  }, [isActive, isSliding, prevIsSliding]);

  return shouldMount ? children : null;
}

export const SWIPER_SLIDE_PADDING_LEFT = 108;

const SwiperSlide = styled(BaseSwiperSlide)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding-left: ${SWIPER_SLIDE_PADDING_LEFT}px;
`;

export default SwiperSlide;
