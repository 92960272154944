import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import useInitFootageVideoPlayer from "src/components/features/VideoTrimmer/common/hooks/useInitFootageVideoPlayer";
import ClipCreator from "src/components/features/ClipCreator/ClipCreator";

export default function FootageClipCreatorPage() {
  const { footageId } = useParams();

  if (!footageId) {
    throw new Error("footageSid is required");
  }

  const {
    videoSrc,
    wordsHistory,
    chaptersHistory,
    filename,
    videoDuration,
    textDirection,
    languageCode,
    sequenceCount,
    videoAspectRatio,
    videoAvailability,
  } = useInitFootageVideoPlayer(footageId);

  return (
    <>
      <Helmet>
        <title>{filename || ""} | Peech - Automated Talk-Videos Creator</title>
      </Helmet>
      <ClipCreator
        videoSrc={videoSrc}
        videoDuration={videoDuration}
        wordsHistory={wordsHistory}
        chaptersHistory={chaptersHistory}
        languageCode={languageCode}
        textDirection={textDirection}
        footageId={footageId}
        videoAvailability={videoAvailability}
        sequenceCount={sequenceCount}
        videoAspectRatio={videoAspectRatio}
      />
    </>
  );
}
