import { FormStepDescriptor, InferStepPathUnion } from "src/types/stepped-form.types";

import ExampleContentStep from "src/components/features/Onboarding/steps/ExampleContentStep";
import BrandStep from "src/components/features/Onboarding/steps/BrandStep/BrandStep";
import VisualPackageStep from "src/components/features/Onboarding/steps/VisualPackageStep";
import FinishStep from "src/components/features/Onboarding/steps/FinishStep";

const steps = [
  {
    path: "content",
    component: ExampleContentStep,
  },
  {
    path: "brand",
    component: BrandStep,
  },
  {
    path: "visuals",
    component: VisualPackageStep,
  },
  {
    path: "finish",
    component: FinishStep,
  },
] as const satisfies ReadonlyArray<FormStepDescriptor>;

export type StepPath = InferStepPathUnion<typeof steps>;

export default steps;
