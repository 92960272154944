import {
  ExpireBlock,
  ExpireBlockWrapper,
  StyledExpiredTooltip,
} from "src/components/features/SmartLibrary/SmartLibrary.styled";
import FootageExpiredExplanation from "src/components/features/SmartLibrary/footagesLibrary/FootageExpiredExplanation";
import Icon from "src/components/common/Icon";
import { NextPlanData } from "src/components/features/SmartLibrary/smartLibrary.types";

interface ExpiredInfoProps {
  isFootageExpired: boolean;
  expiredText: string | null;
  onUpgradePlanButtonClick: (toNextClosestPlan: boolean) => void;
  nextClosestPlanData: NextPlanData | undefined;
  tooltipsTrigger?: "hover" | "click";
}

export function ExpiredInfo({
  isFootageExpired,
  expiredText,
  onUpgradePlanButtonClick,
  nextClosestPlanData,
  tooltipsTrigger,
}: ExpiredInfoProps) {
  return (
    <ExpireBlockWrapper
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <StyledExpiredTooltip
        text={
          <FootageExpiredExplanation
            nextClosestPlanData={nextClosestPlanData}
            action={isFootageExpired ? undefined : () => onUpgradePlanButtonClick(true)}
          />
        }
        placement="top-start"
        trigger={tooltipsTrigger}
      >
        <ExpireBlock>
          {expiredText && <span>{expiredText}</span>}
          <Icon.InfoCircle />
        </ExpireBlock>
      </StyledExpiredTooltip>
    </ExpireBlockWrapper>
  );
}
