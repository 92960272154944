import { useMemo, useState } from "react";
import styled from "styled-components/macro";

import { themeColor } from "src/utils/styledComponents.utils";

import Icon from "src/components/common/Icon";
import LanguageSelect from "src/components/common/LanguageSelector/LanguageSelect";
import { Button } from "src/components/common/buttons/Button.styled";
import { ModalComponentProps } from "src/components/providers/ModalProvider";
import { useAppConfig } from "../providers/AppConfigProvider";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-width: 371px;
  min-height: 216px;
  background-color: inherit;
  border-radius: 10px;
  padding: 30px 0 0 0;

  @media (max-width: 768px) {
    width: 310px;
    max-width: 90vw;
    min-width: unset;
  }
`;

const CloseDiv = styled.div`
  position: absolute;
  top: 0;
  right: 6px;
  width: 14px;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: ${themeColor("gray.500")};
    }
  }
`;

const Title = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: ${themeColor("gray.900")};
  margin-bottom: 10px;
`;

const InfoDiv = styled(Title)`
  color: ${themeColor("black")};
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
`;

const SelectWrapper = styled.div`
  width: 80%;
  margin-block: 20px;
  z-index: 1000;

  @media (max-width: 425px) {
    width: 90%;
  }
`;

interface EntityTitleModalProps extends ModalComponentProps {
  initialLanguageCode: string;
  onApprove: (languageCode: string) => void;
}

export default function TranslateSequenceModal({ close, onApprove, initialLanguageCode }: EntityTitleModalProps) {
  const { TRANSLATE_LANGUAGES } = useAppConfig();
  const [languageCode, setLanguageCode] = useState(initialLanguageCode);
  const translateLanguagesForUse = useMemo(
    () => TRANSLATE_LANGUAGES.filter((x) => x.languageCode !== initialLanguageCode),
    [TRANSLATE_LANGUAGES, initialLanguageCode],
  );

  const onTranslate = () => {
    onApprove(languageCode);
    close();
  };

  return (
    <Container>
      <CloseDiv onClick={close}>
        <Icon.Close />
      </CloseDiv>

      <Title>Choose language</Title>
      <InfoDiv>You can translate your video</InfoDiv>
      <SelectWrapper>
        <LanguageSelect
          selectedLanguage=""
          onChangeLanguage={(code: string) => setLanguageCode(code)}
          isLoading={false}
          isDisabled={false}
          languagesList={translateLanguagesForUse}
        />
      </SelectWrapper>

      <Button onClick={onTranslate} disabled={!languageCode} variant="primary" size="large">
        Submit
      </Button>
    </Container>
  );
}
