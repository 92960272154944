/* eslint-disable no-confusing-arrow */
/* eslint-disable no-nested-ternary */
import { findIndex, isEqual } from "lodash/fp";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { presetSelectors } from "src/models/Preset.model";
import { Dispatch, RootState } from "src/models/store";
import { absoluteRoutes, applyPathParams } from "src/utils/routes.utils";
import { themeColor, themeZ } from "src/utils/styledComponents.utils";
import { Swiper as SwiperClass } from "swiper/types";
import styled from "styled-components/macro";
import Select from "src/components/common/form/inputs/Select.styled";
import Icon from "src/components/common/Icon";
import { CircularLoader } from "src/components/common/loaders/CircularLoader";
import Swiper from "src/components/common/Swiper/Swiper";
import SwiperNextSlideButton from "src/components/common/Swiper/SwiperNextSlideButton";
import { MountIfActive } from "src/components/common/Swiper/SwiperSlide";
import { areColorsIdentical } from "src/components/features/AutomaticCreateWizard/automaticCreate.utills";
import {
  BackButtonWrapper,
  CancelButtonWrapper,
  CentralButtonsWrapper,
  Footer,
  NextButtonWrapper,
} from "src/components/features/AutomaticCreateWizard/common/automatic-create-wizard-layout.styled";
import CancelButton from "src/components/features/AutomaticCreateWizard/common/CancelButton";
import SwiperPrevSlideButton from "src/components/features/AutomaticCreateWizard/swiper-components/SwiperPrevSlideButton";
import { SwiperSlide } from "src/components/features/AutomaticCreateWizard/swiper-components/SwiperSlide.styled";
import useBeforeUnloadBlocker from "src/hooks/useBeforeUnloadBlocker";
import { useClipForm } from "src/components/features/CreateSequenceWizard/ClipForm";
import createClipSteps, {
  CreateClipStepPath,
} from "src/components/features/CreateSequenceWizard/steps/createClipSteps";
import steps from "src/components/features/AutomaticCreateWizard/steps/steps";
import { footageSelectors } from "src/models/Footage.model";
import { useAppConfig } from "src/components/providers/AppConfigProvider";
import apiClient from "src/network/ApiClient";
import { useBrandkitFormSetters } from "src/hooks/useBrandkitFormSetters";

export enum STEPS {
  ASPECT_RATIO = "size",
  BRAND_KIT = "brandkit",
  FINISH = "finish",
}

export enum SUBSTEPS {
  LOGO = "logo",
  COLORS = "colors",
  VISUALS = "visuals",
  SORS = "SORs",
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const StyledSwiper = styled(Swiper)`
  z-index: ${themeZ("onboardingSwiper")};
  width: 100%;
  height: 100%;
  min-width: 320px;
`;

export const StyledNextSlideButton = styled(SwiperNextSlideButton)`
  width: 119px;
  height: 45px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: ${themeColor("white")};
`;

interface CreateClipWizardProps {
  title: string | null;
  footageId: string | null;
  clips: string | null;
  defaultAspectRatio: string | null;
  boardId?: string | null;
}

export default function CreateClipWizard({
  title,
  footageId,
  clips,
  defaultAspectRatio,
  boardId,
}: CreateClipWizardProps) {
  const { CONTENT_URL } = useAppConfig();
  const [aspectRatioThumbnail, setAspectRatioThumbnail] = useState<string | null>(null);
  const footageThumbnailUrl = useSelector(
    (state: RootState) => footageId && footageSelectors.selectThumbnailUrl(state, footageId ?? ""),
  );
  const thumbnailUrl = footageThumbnailUrl ? `${CONTENT_URL}/${footageThumbnailUrl}` : "";
  const [successData, setSuccessData] = useState<any>(null);

  const navigate = useNavigate();
  const pathParams = useParams<{ step: CreateClipStepPath; subStep: string }>();
  const dispatch = useDispatch<Dispatch>();
  const createClipForm = useClipForm();
  const { formState, getValues: getFormValues, trigger: validateForm, setValue, watch } = createClipForm;
  const formValues = getFormValues();

  const activeStepErrors = pathParams.step && formState.errors[pathParams.step as keyof typeof formState.errors];
  const activeStepHasErrors = !!activeStepErrors && Object.values(activeStepErrors).length > 0;
  const activeStepIndex = useMemo(() => findIndex({ path: pathParams.step }, createClipSteps), [pathParams.step]);
  const isLastStep = activeStepIndex === createClipSteps.length - 1;

  const defaultPreset = useSelector((state: RootState) => presetSelectors.getDefaultPreset(state));
  const usersCurrentPresetSid = watch("initial.usersCurrentPresetSid") ?? "";
  const isSaveChangesLoading = useSelector(({ loading: { models } }: RootState) => models.presets.loading); // prettier-ignore
  const usersCurrentPreset = useSelector((state: RootState) => presetSelectors.selectById(state, usersCurrentPresetSid ?? "")); // prettier-ignore

  const getSuccessData = useCallback(
    (brandKitCreated: boolean) => {
      const brandLogoChanged = watch("brandKit.logo.assetId") !== usersCurrentPreset?.assets?.find((asset) => asset?.isDefault)?.assetSid; // prettier-ignore
      const brandColorChanged = !areColorsIdentical(watch("brandKit.colors"), usersCurrentPreset?.colors);
      const brandVisualPackageChanged = watch("brandKit.visuals.visualPackageId") !== usersCurrentPreset?.defaultVisualPackage; // prettier-ignore
      const editRulesChanged = !isEqual(usersCurrentPreset?.editRules, watch("brandKit.SORs.editRules"));

      setSuccessData({
        brandKitCreated,
        brandLogoChanged,
        brandColorChanged,
        brandVisualPackageChanged,
        editRulesChanged,
      });
    },
    [usersCurrentPreset, watch],
  );

  const thumbnailsUrls = useMemo(
    () => ({
      "16:9": aspectRatioThumbnail || null,
      "1:1": aspectRatioThumbnail || null,
      "9:16": aspectRatioThumbnail || null,
    }),
    [aspectRatioThumbnail],
  );

  const nextButtonText = useMemo(() => (pathParams.subStep ? "Apply" : activeStepIndex === createClipSteps.length - 2 ? "Finish" : "Next"), [activeStepIndex, pathParams]); // prettier-ignore

  const { setTemporaryPresetData, updateCurrentPreset, setInitialSORs } = useBrandkitFormSetters(
    createClipForm,
    usersCurrentPresetSid,
  );

  const createClip = useCallback(() => {
    const aspectRatios = watch("size.selectedAspectRatio");
    if (!aspectRatios || !footageId || !clips?.length) return;
    dispatch.footage.splitFootageByUserTimestamps({
      input: {
        footageId,
        concat: true,
        aspectRatios,
        clips: JSON.parse(clips),
      },
    });
  }, [clips, dispatch.footage, footageId, watch]);

  const onActiveStepIndexChange = useCallback(
    ({ activeIndex }: SwiperClass) => {
      if (pathParams.step !== createClipSteps[activeIndex].path) {
        navigate(
          applyPathParams(absoluteRoutes.createClip.children.step.self, {
            step: createClipSteps[activeIndex].path,
          }),
        );
      }

      if (activeIndex === createClipSteps.length - 1) {
        // last step - finish
        // TODO: add analytics with successData
        // eslint-disable-next-line
        console.log(successData);
      }
    },
    [navigate, pathParams.step, successData],
  );

  const goToBrandPage = useCallback(() => {
    navigate(
      applyPathParams(absoluteRoutes.createClip.children.step.self, {
        step: createClipSteps[1].path,
      }),
    );
  }, [navigate]);

  const onPrevStep = useCallback((back: Function) => {
    // TODO: add analytics
    back();
  }, []);

  const onNextStep = useCallback(
    async (next: Function) => {
      // TODO: add analytics
      if (pathParams.step === STEPS.ASPECT_RATIO) {
        setValue("size.selectedAspectRatio", formValues.size.selectedAspectRatio, { shouldValidate: true });
        createClip();
      }

      if (pathParams.step === STEPS.BRAND_KIT) {
        try {
          await getSuccessData(false);
        } catch (error) {
          // eslint-disable-next-line
          console.log(error);
        }

        await updateCurrentPreset();
      }

      next();
    },
    [pathParams.step, formValues, setValue, createClip, updateCurrentPreset, getSuccessData],
  );

  const substepFunctions = useMemo(
    () => ({
      [SUBSTEPS.LOGO]: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        apply: (next: Function | undefined) => {
          // TODO: add analytics
          setValue("brandKit.temporaryPresetValues.logo.assetId", watch("brandKit.logo.assetId"));
          goToBrandPage();
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        reject: (back: Function | undefined) => {
          // TODO: add analytics
          setValue("brandKit.logo.assetId", watch("brandKit.temporaryPresetValues.logo.assetId") ?? "", {
            shouldValidate: true,
          });
          goToBrandPage();
        },
        allowSlideNext: () => {
          const currentLogoAssetId = watch("brandKit.logo.assetId");
          const prevLogoAssetId = watch("brandKit.temporaryPresetValues.logo.assetId");
          return currentLogoAssetId && currentLogoAssetId !== prevLogoAssetId;
        },
      },
      [SUBSTEPS.COLORS]: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        apply: (next: Function | undefined) => {
          // TODO: add analytics
          setValue("brandKit.temporaryPresetValues.colors", watch("brandKit.colors"));
          goToBrandPage();
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        reject: (back: Function | undefined) => {
          // TODO: add analytics
          // @ts-ignore
          setValue("brandKit.colors", watch("brandKit.temporaryPresetValues.colors"), { shouldValidate: true });
          goToBrandPage();
        },
        allowSlideNext: () =>
          !areColorsIdentical(watch("brandKit.colors"), watch("brandKit.temporaryPresetValues.colors")),
      },
      [SUBSTEPS.VISUALS]: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        apply: async (next: Function | undefined) => {
          // TODO: add analytics
          setValue("brandKit.temporaryPresetValues.visuals.visualPackageId", watch("brandKit.visuals.visualPackageId"));
          goToBrandPage();
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        reject: (back: Function | undefined) => {
          // TODO: add analytics
          setValue(
            "brandKit.visuals.visualPackageId",
            watch("brandKit.temporaryPresetValues.visuals.visualPackageId") ?? "",
            { shouldValidate: true },
          );
          goToBrandPage();
        },
        allowSlideNext: () =>
          watch("brandKit.temporaryPresetValues.visuals.visualPackageId") !== watch("brandKit.visuals.visualPackageId"),
      },
      [SUBSTEPS.SORS]: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        apply: async (next: Function | undefined) => {
          // TODO: add analytics
          setValue("brandKit.temporaryPresetValues.SORs.editRules", watch("brandKit.SORs.editRules"));
          goToBrandPage();
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        reject: (back: Function | undefined) => {
          // TODO: add analytics
          setValue("brandKit.SORs.editRules", watch("brandKit.temporaryPresetValues.SORs.editRules") ?? "", {
            shouldValidate: true,
          });
          goToBrandPage();
        },
        allowSlideNext: () =>
          !isEqual(watch("brandKit.temporaryPresetValues.SORs.editRules"), watch("brandKit.SORs.editRules")),
      },
    }),
    [setValue, watch, goToBrandPage],
  );

  useEffect(() => {
    if (pathParams.step) {
      validateForm();
    }
  }, [pathParams.step, validateForm]);

  useEffect(() => {
    if (!pathParams.step) {
      navigate(
        `${applyPathParams(absoluteRoutes.createClip.children.step.self, {
          step: createClipSteps[0].path,
        })}?title=${title}&footageId=${footageId}&clips=${clips}&defaultAspectRatio=${defaultAspectRatio}&boardId=${boardId}`,
      );
    }
  }, [title, navigate, pathParams.step, pathParams.subStep, watch, footageId, clips, boardId, defaultAspectRatio]);

  useEffect(() => {
    // set form with default aspect ratio
    if (!defaultAspectRatio) return;
    createClipForm.setValue("size.selectedAspectRatio", [defaultAspectRatio], { shouldValidate: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      if (thumbnailUrl) {
        apiClient.httpClient
          .get(thumbnailUrl, { responseType: "blob" })
          .then((res) => setAspectRatioThumbnail(URL.createObjectURL(res.data)));
      }

      return () => {
        setAspectRatioThumbnail(null);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [thumbnailUrl],
  );

  useEffect(() => {
    const temporaryPresetValues = watch("brandKit.temporaryPresetValues");
    defaultPreset && setValue("initial.usersCurrentPresetSid", defaultPreset.sid, { shouldValidate: true });
    defaultPreset && !temporaryPresetValues && setTemporaryPresetData();
  }, [defaultPreset, setTemporaryPresetData, setValue, watch]);

  useEffect(() => {
    setValue("brandKit.SORs.editRulesSid", "", { shouldValidate: true });
    setValue("brandKit.temporaryPresetValues.SORs.editRulesSid", "", { shouldValidate: true });
    setInitialSORs();
  }, [setInitialSORs, setValue, usersCurrentPresetSid]);

  useBeforeUnloadBlocker();

  if (activeStepIndex === -1) {
    return null;
  }

  return (
    <FormProvider {...createClipForm}>
      <Container>
        <StyledSwiper
          activeIndex={activeStepIndex}
          allowSlideNext={
            pathParams.subStep
              ? !!substepFunctions[pathParams.subStep as keyof typeof substepFunctions]?.allowSlideNext()
              : !activeStepHasErrors
          }
          allowSlidePrev={!isLastStep}
          onActiveIndexChange={onActiveStepIndexChange}
          noSwipingSelector={`${Select}`}
          direction="vertical"
          enableMousewheel={false}
        >
          {createClipSteps.map((step, i) => (
            <SwiperSlide key={step.path}>
              <MountIfActive index={i}>
                <>
                  {step.path === STEPS.ASPECT_RATIO && (
                    <step.component
                      headerTitle="Select aspect ratios"
                      formContext={createClipForm}
                      isMultiSelect
                      thumbnailsUrls={thumbnailsUrls}
                    />
                  )}
                  {/* TODO: Implement brandKit step when backend ready */}
                  {/* {step.path === STEPS.BRAND_KIT && ( */}
                  {/*   <step.component */}
                  {/*     formContext={createClipForm} */}
                  {/*     baseRoute={absoluteRoutes.createClip} */}
                  {/*     usersCurrentPresetSidFormPath="initial.usersCurrentPresetSid" */}
                  {/*   /> */}
                  {/* )} */}
                  {step.path === STEPS.FINISH && <step.component footageId={footageId} formContext={createClipForm} />}
                </>
              </MountIfActive>
            </SwiperSlide>
          ))}

          <Footer>
            {activeStepIndex !== steps.length - 2 && (
              <CancelButtonWrapper hide={false}>
                <CancelButton navigateToPath={applyPathParams(absoluteRoutes.platform.children.player, { footageId })}>
                  Cancel
                </CancelButton>
              </CancelButtonWrapper>
            )}

            {activeStepIndex !== steps.length - 2 && (
              <CentralButtonsWrapper>
                <BackButtonWrapper>
                  {activeStepIndex !== 0 && (
                    <SwiperPrevSlideButton
                      data-testid="back"
                      onClick={
                        pathParams.subStep
                          ? substepFunctions[pathParams.subStep as keyof typeof substepFunctions]?.reject
                          : onPrevStep
                      }
                    >
                      <Icon.PrevSlide />
                      Back
                    </SwiperPrevSlideButton>
                  )}
                </BackButtonWrapper>

                <NextButtonWrapper>
                  <StyledNextSlideButton
                    data-testid="next"
                    onClick={
                      pathParams.subStep
                        ? substepFunctions[pathParams.subStep as keyof typeof substepFunctions]?.apply
                        : onNextStep
                    }
                  >
                    {isSaveChangesLoading ? <CircularLoader size={30} /> : nextButtonText}
                  </StyledNextSlideButton>
                </NextButtonWrapper>
              </CentralButtonsWrapper>
            )}
          </Footer>
        </StyledSwiper>
      </Container>
    </FormProvider>
  );
}
