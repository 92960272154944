import { FlattenInterpolation, FlattenSimpleInterpolation, Keyframes, ThemeProps } from "styled-components/macro";
import * as StyledTools from "styled-tools";
import { get } from "lodash/fp";

import { ThemeColor, SpaceValue, Theme, ZIndexValue } from "src/theme";

type StyleValue<Props extends object> =
  | string
  | number
  | FlattenSimpleInterpolation
  | FlattenInterpolation<Props>
  | Keyframes;

type StyleValueGetter<Props extends object> = (props: Props) => StyleValue<Props>;

export const styleProp = StyledTools.prop as <Props extends object>(
  prop: keyof Props,
  defaultValue?: StyleValue<Props> | StyleValueGetter<Props>,
) => StyleValueGetter<Props>;

export const themeProp = StyledTools.theme as <Props extends object>(
  path: Leaves<Theme>,
  defaultValue?: StyleValue<Props>,
) => StyleValueGetter<Props>;

export const ifProp = StyledTools.ifProp as <Props extends object>(
  prop: keyof Props | ((props: Props) => boolean),
  pass: StyleValue<Props> | StyleValueGetter<Props>,
  fail?: StyleValue<Props> | StyleValueGetter<Props>,
) => StyleValueGetter<Props>;

export const ifNotProp = StyledTools.ifNotProp as typeof ifProp;

export const switchProp = StyledTools.switchProp as <Props extends object, Prop extends keyof Props>(
  prop: Prop,
  cases: Props[Prop] extends string | number | symbol
    ? Record<Props[Prop], StyleValue<Props> | StyleValueGetter<Props>>
    : never,
  defaultCase?: StyleValue<Props> | StyleValueGetter<Props>,
) => StyleValueGetter<Props>;

export const themeZ =
  <Props extends ThemeProps<Theme>>(value: ZIndexValue) =>
  ({ theme }: Props) =>
    theme.zIndices[value];

export const themeSpacing =
  (value: SpaceValue) =>
  ({ theme }: ThemeProps<Theme>) =>
    `${theme.spaces[value]}px`;

export const themeColor =
  <Props extends ThemeProps<Theme>>(value: ThemeColor, alpha?: number) =>
  ({ theme }: Props) => {
    const color = get(value, theme.colors);

    if (alpha !== undefined) {
      const restrictedAlpha = Math.max(0, Math.min(1, alpha));
      let hexAlpha = (0xff * restrictedAlpha).toString(16).split(".")[0];

      if (hexAlpha.length === 1) {
        hexAlpha = `0${hexAlpha}`;
      }

      return `${color}${hexAlpha}`;
    }

    return color;
  };
