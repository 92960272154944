import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";

import { userSelectors } from "src/models/User.model";
import { Dispatch, RootState } from "src/models/store";

import { Center } from "src/components/common/layout/Box.styled";
import { CircularLoader } from "src/components/common/loaders/CircularLoader";

import apiClient from "src/network/ApiClient";
import { absoluteRoutes } from "src/utils/routes.utils";

export default function AuthenticatedUsersGate() {
  const dispatch = useDispatch<Dispatch>();
  const location = useLocation();
  const authToken = useSelector((state: RootState) => state.session.authToken);
  const currentUser = useSelector(userSelectors.selectCurrentUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (authToken && !currentUser && location.pathname !== absoluteRoutes.logout) {
      dispatch.users.fetchCurrentUser();
    }
  }, [authToken, currentUser, dispatch, location.pathname]);

  useEffect(() => {
    const onMessage = (event: MessageEvent) => {
      if (event.data === "OLD_APP_IFRAME_COOKIE_EXPIRED") {
        navigate(absoluteRoutes.logout);
      }
    };

    const onError = (statusCode: number) => {
      if (statusCode === 401) {
        navigate(absoluteRoutes.logout);
      }
    };

    window.addEventListener("message", onMessage);
    apiClient.on("error", onError);

    return () => {
      window.removeEventListener("message", onMessage);
      apiClient.off("error", onError);
    };
  }, [dispatch, navigate]);

  useEffect(() => {
    if (currentUser?.sid) {
      dispatch.plans.getPlan();
      dispatch.session.getSubscription();
      dispatch.presets.getPresets({});
    }
  }, [currentUser?.sid, dispatch.plans, dispatch.presets, dispatch.session]);

  if (!authToken) {
    const pathname = window.localStorage.getItem("userHasLoggedBefore")
      ? absoluteRoutes.login
      : absoluteRoutes.signup.self;

    return <Navigate to={`${pathname}${location.search}`} replace />;
  }

  if (!currentUser && location.pathname !== absoluteRoutes.logout) {
    return (
      <Center>
        <CircularLoader size={100} marginTop={20} />
      </Center>
    );
  }

  return <Outlet />;
}
