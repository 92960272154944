import styled from "styled-components/macro";

import { themeColor, themeProp } from "src/utils/styledComponents.utils";
import { boxStyle } from "src/components/common/layout/Box.styled";

/** ****************************************************** */
/** ********************** Headings ********************** */
/** ****************************************************** */

export const H1 = styled.h1`
  font-size: 36px;
  line-height: 46px;
  font-weight: 600;
  color: ${themeProp("colors.gray.900")};
  margin-block: 0;
  ${boxStyle}
`;

export const H1Bold = styled(H1)`
  font-weight: 700;
`;

export const H2 = styled.h2`
  font-size: 30px;
  line-height: 30px;
  font-weight: 700;
  color: ${themeColor("black")};
  margin-block: 6px;
  ${boxStyle}
`;

export const H3 = styled.h3`
  font-size: 26px;
  line-height: 30px;
  font-weight: 400;
  color: ${themeProp("colors.gray.900")};
  margin-block: 0;
  ${boxStyle}
`;

export const H4 = styled.h4`
  font-size: 25px;
  line-height: 36px;
  font-weight: 400;
  margin-block: 0;
  ${boxStyle}
`;

export const H5 = styled.h5`
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  margin-block: 0;
  ${boxStyle}
`;

export const H6 = styled.h6`
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  color: ${themeColor("black")};
  margin-block: 0;
`;

/** ******************************************************* */
/** ************************ Texts ************************ */
/** ******************************************************* */

export const Text1 = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: ${themeProp("colors.gray.500")};
  ${boxStyle}
`;

export const Text1Thin = styled(Text1)`
  font-weight: 300;
`;

export const Text1Bold = styled(Text1)`
  font-weight: 600;
`;

export const Text1Bolder = styled(Text1)`
  font-weight: 700;
`;

export const Text2 = styled.div`
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  line-height: 15px;
  font-weight: 400;
  ${boxStyle}
`;

export const Text2Bold = styled(Text2)`
  font-weight: 700;
`;

export const Text3 = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${themeProp("colors.gray.500")};
  ${boxStyle}
`;

export const Text4 = styled.div`
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  line-height: 17px;
  font-weight: 400;
  color: ${themeColor("black")};
`;

export const Text4Light = styled(Text4)`
  color: ${themeColor("gray.400")};
`;

export const Text5 = styled.div`
  font-size: 12px;
  line-height: 22px;
  font-weight: 300;
  color: ${themeProp("colors.gray.500")};
  ${boxStyle}
`;

export const Text6 = styled.div`
  font-family: "Open Sans", sans-serif;
  font-size: 10px;
  line-height: 15px;
  font-weight: 400;
  color: ${themeColor("gray.400")};
  ${boxStyle}
`;

/** ******************************************************* */
/** ************************ Misc ************************ */
/** ******************************************************* */

export const Strong = styled.strong`
  font-weight: 700;
`;
