import { createModel } from "@rematch/core";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";

import { RootState } from "src/models/store";
import { RootModel } from "src/models/index";
import { selectId } from "src/models/selectId";
import { AssetType, FolderType } from "src/constants/model.constants";

import apiClient from "src/network/ApiClient";
import { Asset, FolderStructure } from "src/network/graphql/generatedGraphqlSDK";
import { assetSelectors } from "src/models/Asset.model";
import { userSelectors } from "./User.model";

const folderStructureAdapter = createEntityAdapter<FolderStructure>({ selectId });
const folderStructureAdapterSelectors = folderStructureAdapter.getSelectors(
  (state: RootState) => state.folderStructures,
);

const selectByType = createSelector(
  [
    folderStructureAdapterSelectors.selectAll,
    (state: RootState, type: FolderType) => type,
  ], // prettier-ignore
  (folders, type) => folders.filter((folder) => folder.type === type),
);

interface VisualPackage {
  folder: FolderStructure;
  asset: Asset;
  // assets: Record<AspectRatio, Asset>; // TODO: wait for backend impl
}

const selectVisualPackage = createSelector(
  [
    (state: RootState, folderStructureId: string) =>
      folderStructureAdapterSelectors.selectById(state, folderStructureId),
    (state: RootState, folderStructureId: string, assetType: AssetType) =>
      assetSelectors.selectByType(state, assetType),
  ],
  (folder, assets) => {
    const asset = assets?.find((ass) => ass?.folderSid === folder?.sid);
    return folder && asset ? ({ folder, asset } satisfies VisualPackage) : null;
  },
);

const selectVisualPackages = createSelector(
  [
    (state: RootState, folderType: FolderType) => selectByType(state, folderType),
    (state: RootState, folderType: FolderType, assetType: AssetType) => assetSelectors.selectByType(state, assetType),
    (state: RootState) => userSelectors.selectCurrentUser(state),
  ],
  (folders, assets, user) =>
    folders
      .map((folder) => ({ folder, asset: assets?.find((ass) => ass?.folderSid === folder?.sid) }))
      .filter((visualPackage) => visualPackage.folder && visualPackage.asset)
      .sort((a, b) => {
        if (a.folder?.userSid === user?.orgSid && b.folder?.userSid !== user?.orgSid) {
          return -1;
        }

        return b.folder.insertTime! - a.folder.insertTime!;
      }) as VisualPackage[],
);
export const folderStructureSelectors = {
  ...folderStructureAdapterSelectors,
  selectByType,
  selectVisualPackages,
  selectVisualPackage,
};

const folderStructures = createModel<RootModel>()({
  state: folderStructureAdapter.getInitialState(),

  reducers: {
    setFolderStructures: (state, payload: FolderStructure[]) => folderStructureAdapter.setMany(state, payload),
  },

  effects: (dispatch) => ({
    async fetchFolderStructuresByType(type: FolderType) {
      const res = await apiClient.getFolderStructures({ type });
      dispatch.folderStructures.setFolderStructures(res);
    },
  }),
});

export default folderStructures;
