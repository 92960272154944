import styled from "styled-components/macro";
import { useCallback, useEffect, useMemo, useState, forwardRef } from "react";
import { TimeFormat, convertMsToTimeFormat, formatTimeCodeToMs } from "src/utils/time.utils";
import { themeColor } from "src/utils/styledComponents.utils";
import { ifProp } from "styled-tools";
import Input from "src/components/common/form/inputs/Input.styled";
import Icon from "src/components/common/Icon";

interface TimeCodeComponentProps {
  time: number;
  minTime: number;
  maxTime: number;
  label?: string;
  timeFormat?: TimeFormat;
  disabled?: boolean;
  autoFocus?: boolean;
  onTimeChange?: (time: number) => void;
  onBlur?: () => void;
}

const Container = styled.div`
  position: relative;
`;

const InputLabel = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  color: ${themeColor("gray.500")};
  left: 8px;
  top: 50%;
  transform: translateY(-50%);

  svg {
    margin-right: 4px;
    path {
      fill: ${themeColor("gray.500")};
    }
  }
`;
const InputStyled = styled(Input)<{ invalid?: boolean }>`
  padding-left: 40px;
  text-align: right;
  border-color: ${ifProp("invalid", themeColor("pink.500"), themeColor("gray.300"))};
  background-color: ${ifProp("invalid", themeColor("pink.500", 0.4), "transparent")};
`;

const TimeCodeInputComponent = forwardRef<HTMLInputElement, TimeCodeComponentProps>(
  (
    {
      time,
      minTime,
      maxTime,
      label = "",
      timeFormat = TimeFormat.HHMMSS,
      disabled = false,
      autoFocus = false,
      onTimeChange = () => {},
      onBlur = () => {},
    },
    ref,
  ) => {
    const [stateTime, setStateTime] = useState(time);
    const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
      const { key, metaKey, ctrlKey } = event;
      const controlKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab", "Enter", "Escape"];

      if (!/[0-9:.]/.test(key) && !controlKeys.includes(key) && !(metaKey || ctrlKey)) {
        event.preventDefault();
      }
    }, []);

    const handlePaste = useCallback(
      (event: React.ClipboardEvent<HTMLInputElement>) => {
        const paste = event.clipboardData.getData("text");
        if (!/^[0-9:.]+$/.test(paste)) {
          event.preventDefault();
        } else if (ref && typeof ref !== "function" && ref.current) {
          ref.current.value = paste;
          setTimeout(() => {
            ref.current?.blur();
          }, 5);
        }
      },
      [ref],
    );

    const isInvalid = useMemo(() => stateTime < minTime || stateTime > maxTime, [stateTime, minTime, maxTime]);

    useEffect(() => {
      if (ref && typeof ref !== "function" && ref.current) {
        ref.current.value = convertMsToTimeFormat(stateTime, timeFormat);
      }
      onTimeChange(stateTime);
    }, [stateTime, timeFormat, onTimeChange, ref]);

    useEffect(() => {
      if (autoFocus && ref && typeof ref !== "function" && ref.current) {
        ref.current.focus();
      }
    }, [autoFocus, ref]);

    return (
      <Container>
        <InputLabel>
          <Icon.Stopwatch size={16} />
          {label}
        </InputLabel>
        <InputStyled
          inputSize="medium"
          type="text"
          invalid={isInvalid}
          ref={ref}
          onKeyDown={handleKeyDown}
          onKeyUp={(event) => {
            if (event.key === "Enter" || event.key === "Escape") {
              if (ref && typeof ref !== "function") ref.current?.blur();
            }
          }}
          onPaste={handlePaste}
          disabled={disabled}
          onBlur={(event) => {
            const input = event.target as HTMLInputElement;
            const ms = formatTimeCodeToMs(input.value, timeFormat);
            setStateTime(ms);
            input.value = convertMsToTimeFormat(ms, timeFormat);
            onBlur();
          }}
          onFocus={() => {
            if (ref && typeof ref !== "function") ref.current?.select();
          }}
        />
      </Container>
    );
  },
);

export default TimeCodeInputComponent;
