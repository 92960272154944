import { PropsWithChildren, RefObject, useCallback, useEffect, useMemo, useState } from "react";
import { usePinch, useWheel } from "@use-gesture/react";
import TWEEN from "@tweenjs/tween.js";
import { flow, pick, update } from "lodash/fp";

import { timeIntervalUtils } from "src/utils/timeInterval.utils";
import { TimeInterval, TimeIntervalEdge } from "src/types/video-trimmer.types";

import useTween from "src/hooks/useTween";
import useShallowMemo from "src/hooks/useShallowMemo";

import TimelineZoomContext, { TimelineZoomContextValue } from "src/components/features/VideoTrimmer/providers/TimelineZoomProvider/TimelineZoomContext"; // prettier-ignore

const MIN_ZOOM_DURATION = 0.3;

interface TimelineZoomProviderProps extends PropsWithChildren {
  videoRef: RefObject<HTMLVideoElement>;
  videoDuration: number;
  timelineWindowElement: HTMLElement | null;
  minZoomDuration?: number;
}

export default function TimelineZoomProvider({
  children,
  videoRef,
  videoDuration,
  timelineWindowElement,
  minZoomDuration = MIN_ZOOM_DURATION,
}: TimelineZoomProviderProps) {
  const fullVideoTimeInterval = useMemo<TimeInterval>(() => ({ start: 0, end: videoDuration ?? 60 }), [videoDuration]);
  const [zoomedTimeInterval, baseSetZoomedTimeInterval] = useState(fullVideoTimeInterval);
  const zoomedIntervalDuration = timeIntervalUtils.duration(zoomedTimeInterval);
  const zoomFactor = zoomedIntervalDuration ? videoDuration / zoomedIntervalDuration : 1;

  const setZoomedTimeInterval = useCallback(
    (value: TimeInterval | ((x: TimeInterval) => TimeInterval)) =>
      baseSetZoomedTimeInterval((prev) => {
        const next = typeof value === "function" ? value(prev) : value;

        return timeIntervalUtils.duration(next) < minZoomDuration ? prev : next;
      }),
    [minZoomDuration],
  );

  const animateTo = useCallback(
    (target: TimeInterval) => {
      const t = pick(["start", "end"], target);
      new TWEEN.Tween({ ...zoomedTimeInterval })
        .to(t, 400)
        .easing(TWEEN.Easing.Quintic.Out)
        .onUpdate(({ start, end }) => setZoomedTimeInterval({ start, end }))
        .start()
        .onComplete(() => setZoomedTimeInterval(target));
    },
    [setZoomedTimeInterval, zoomedTimeInterval],
  );

  const zoomBy = useCallback(
    (relativeZoomFactor: number) => {
      const { currentTime } = videoRef.current!;

      flow(
        (interval) => {
          const zoomPoint = timeIntervalUtils.includesTimePoint(interval, currentTime)
            ? currentTime
            : timeIntervalUtils.middle(interval);

          return timeIntervalUtils.zoom(interval, zoomPoint, relativeZoomFactor);
        },
        (interval) => timeIntervalUtils.applyBorders(interval, fullVideoTimeInterval),
        animateTo,
      )(zoomedTimeInterval);
    },
    [animateTo, fullVideoTimeInterval, videoRef, zoomedTimeInterval],
  );

  const zoomIn = useCallback(() => zoomBy(1.1), [zoomBy]);
  const zoomOut = useCallback(() => zoomBy(0.9), [zoomBy]);
  const zoomReset = useCallback(() => animateTo(fullVideoTimeInterval), [animateTo, fullVideoTimeInterval]);

  const toggleFocusTimeInterval = useCallback(
    (timeInterval: TimeInterval, timeIntervalLength: number, focusZoomFactor = 0.5) => {
      flow(
        (t) => timeIntervalUtils.zoom(t, timeIntervalUtils.middle(timeInterval), focusZoomFactor),
        (t) => (timeIntervalLength === 1 ? { ...t, end: t.end * focusZoomFactor } : t),
        (t) => timeIntervalUtils.applyBorders(t, fullVideoTimeInterval),
        (t) => (timeIntervalUtils.equals(t, zoomedTimeInterval) ? zoomReset() : animateTo(t)),
      )(timeInterval);
    },
    [animateTo, fullVideoTimeInterval, zoomReset, zoomedTimeInterval],
  );

  const scrollToTimeInterval = useCallback(
    (timeInterval: TimeInterval) => {
      if (!timeIntervalUtils.containsTimeInterval(zoomedTimeInterval, timeInterval)) {
        const distance =
          timeInterval.start -
          zoomedTimeInterval.start +
          (timeIntervalUtils.duration(timeInterval) - zoomedIntervalDuration) / 2;

        const targetZoomTimeInterval = timeIntervalUtils.shift(zoomedTimeInterval, distance, fullVideoTimeInterval);

        animateTo(targetZoomTimeInterval);
      }
    },
    [animateTo, zoomedTimeInterval, zoomedIntervalDuration, fullVideoTimeInterval],
  );

  const shiftZoomedTimeInterval = useCallback(
    (timeDelta: number) => {
      videoRef.current?.pause(); // TODO: move to VideoPlaybackProvider
      setZoomedTimeInterval((timeInterval) => timeIntervalUtils.shift(timeInterval, timeDelta, fullVideoTimeInterval));
    },
    [videoRef, fullVideoTimeInterval, setZoomedTimeInterval],
  );

  const resizeZoomedTimeInterval = useCallback(
    (edge: TimeIntervalEdge, timeDelta: number) => {
      videoRef.current?.pause(); // TODO: move to VideoPlaybackProvider
      setZoomedTimeInterval(update([edge], (t) => Math.min(videoDuration, Math.max(0, t + timeDelta))));
    },
    [videoRef, setZoomedTimeInterval, videoDuration],
  );

  useEffect(() => {
    setZoomedTimeInterval(fullVideoTimeInterval);
  }, [fullVideoTimeInterval, setZoomedTimeInterval]);

  useTween();

  usePinch(
    (e) => {
      videoRef.current?.pause(); // TODO: move to VideoPlaybackProvider

      const timelineWindowElementRect = (e.currentTarget as HTMLElement).getBoundingClientRect();
      const zoomX = e.origin[0] - timelineWindowElementRect.x;
      const zoomTimePointInSeconds = zoomedTimeInterval.start + zoomedIntervalDuration * (zoomX / timelineWindowElementRect.width); // prettier-ignore
      const nextZoomFactor = e.offset[0] / zoomFactor;

      // TODO: pinch zoom analytics
      setZoomedTimeInterval(
        flow(
          (interval) => timeIntervalUtils.zoom(interval, zoomTimePointInSeconds, nextZoomFactor),
          (interval) => timeIntervalUtils.applyBorders(interval, fullVideoTimeInterval),
        ),
      );
    },
    {
      target: timelineWindowElement ?? undefined,
      from: [zoomFactor, 0],
      scaleBounds: { min: 1, max: 200 },
    },
  );

  useWheel(
    (e) => {
      videoRef.current?.pause(); // TODO: move to VideoPlaybackProvider
      setZoomedTimeInterval((interval) =>
        timeIntervalUtils.shift(interval, e.delta[0] / (zoomFactor * 4), fullVideoTimeInterval),
      );
    },
    { target: timelineWindowElement ?? undefined },
  );

  const contextValue = useShallowMemo<TimelineZoomContextValue>({
    zoomedTimeInterval,
    zoomFactor,
    zoomBy,
    zoomIn,
    zoomOut,
    zoomReset,
    toggleFocusTimeInterval,
    scrollToTimeInterval,
    shiftZoomedTimeInterval,
    resizeZoomedTimeInterval,
  });

  return <TimelineZoomContext.Provider value={contextValue}>{children}</TimelineZoomContext.Provider>;
}
