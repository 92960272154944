import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { themeColor } from "src/utils/styledComponents.utils";
import theme from "src/theme";

interface NumberInputProps {
  value: any;
  onValueChange: (value: number) => void;
  onBlur?: void | ((value: any) => void);
  disabled?: boolean;
  min?: number;
  max?: number;
  step?: number;
  reverse?: boolean;
}

const Input = styled.input<{ error?: boolean; disabled?: boolean }>`
  margin-left: 10px;
  width: 45px;
  height: 27px;
  top: 2px;
  left: 195px;
  border-radius: 8px;
  background: #f5f5f5;
  text-align: center;
  border: ${(props) => (props.error ? "1px red solid" : "none")};
  caret-color: black;
  font-size: 12px;
  color: ${(props) => (!props.disabled ? themeColor("gray.900") : themeColor("gray.300"))};

  &:hover {
    border: ${(props) => (!props.disabled ? `1px ${theme.colors.gray["900"]} solid` : "none")};
    outline-offset: 1px;
  }

  :focus-visible {
    outline-offset: -2px !important;
    outline: ${(props) => (props.error ? "red" : themeColor("blue.500"))} solid 2px !important;
  }

  &::selection {
    background: lightskyblue;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
  }
`;

export default function NumberInput({
  value,
  onValueChange,
  onBlur,
  disabled = false,
  min = 0,
  max = 100,
  step = 1,
  reverse = false,
}: NumberInputProps) {
  const [displayValue, setDisplayValue] = useState(reverse ? String(max - value) : String(value));
  const inputRef = useRef<HTMLInputElement | null>(null);

  const sanitizeValue = useCallback(
    (dirtyValue: string) => {
      let val = Number(dirtyValue);
      val = Math.min(max, Math.max(min, val));
      return String(val);
    },
    [max, min],
  );

  useEffect(() => {
    let newValue = reverse ? max - value : value;
    newValue = sanitizeValue(String(newValue));
    setDisplayValue(newValue);
  }, [max, min, reverse, sanitizeValue, value]);

  const onInputChange = useCallback(
    (inputValue: string) => {
      // typing any numeric value allowed, even if it's invalid.
      setDisplayValue(inputValue);
      const valueToUpdate = reverse ? max - Number(inputValue) : Number(inputValue);
      if (sanitizeValue(inputValue) === inputValue) {
        onValueChange(valueToUpdate);
      }
    },
    [reverse, max, sanitizeValue, onValueChange],
  );

  const onInputBlur = useCallback(
    (inputValue: string) => {
      // prevent the input from being stuck on an invalid value.
      inputValue = sanitizeValue(inputValue);
      setDisplayValue(inputValue);
      onValueChange(reverse ? max - Number(inputValue) : Number(inputValue));
    },
    [sanitizeValue, onValueChange, reverse, max],
  );

  const onInputKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const intRx = /\d|-/; // only allow digits and minus
    if (e.key.length > 1 || e.ctrlKey || (e.key === "-" && !e.currentTarget.value.length) || intRx.test(e.key)) return;
    e.preventDefault();
  }, []);

  return (
    <Input
      ref={inputRef}
      value={displayValue}
      error={displayValue !== sanitizeValue(displayValue)}
      disabled={disabled}
      min={min}
      max={max}
      step={step}
      type="number"
      onChange={(e) => onInputChange(e.target.value)}
      onBlur={onBlur ? (e) => onBlur(e.target.value) : (e) => onInputBlur(e.target.value)}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        inputRef?.current?.select();
      }}
      onKeyDown={onInputKeyDown}
      onKeyUp={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          e.stopPropagation();
          inputRef?.current?.blur();
        }
      }}
    />
  );
}
