import analyticsService from "src/services/Analytics.service";

export const trackSeekBackward = () =>
  analyticsService.track("content-editor-control", { action: "seek-backward", trigger: "backward-button" });

export const trackSeekForward = () =>
  analyticsService.track("content-editor-control", { action: "seek-forward", trigger: "forward-button" });

export const trackRemove = (
  trigger:
    | "all-filler-words"
    | "all-blanks"
    | "remove-blank"
    | "content-selection"
    | "remove-chapter"
    | "search-results",
  props?: Object,
) => analyticsService.track("content-editor-edit", { action: "remove", trigger, ...props });

export const trackRevert = (
  trigger: "all-filler-words" | "all-blanks" | "revert-blank" | "content-selection" | "search-results",
  props?: Object,
) => analyticsService.track("content-editor-edit", { action: "revert", trigger, ...props });

export const trackSplit = () =>
  analyticsService.track("content-editor-edit", { action: "split", trigger: "split-button" });

export const trackTrim = (trigger: "from-start" | "from-end") =>
  analyticsService.track("content-editor-edit", { action: "trim", trigger });

export const trackKeepOnly = () =>
  analyticsService.track("content-editor-edit", { action: "keep-only", trigger: "content-selection" });

export const trackCancelSelection = () =>
  analyticsService.track("content-editor-edit", { action: "cancel-selection", trigger: "content-selection" });

export const trackReachMaxChapters = (chapterCount: number) =>
  analyticsService.track("content-editor-edit", { action: "reach-max-chapters", chapterCount });

export const trackUndo = () =>
  analyticsService.track("content-editor-control", { action: "undo", trigger: "undo-button" });

export const trackRedo = () =>
  analyticsService.track("content-editor-control", { action: "redo", trigger: "redo-button" });

export const trackHighlight = (trigger: "content-selection") =>
  analyticsService.track("content-editor-edit", { action: "highlight", trigger });

export const trackUnHighlight = (trigger: "content-selection") =>
  analyticsService.track("content-editor-edit", { action: "unhighlight", trigger });

export const trackWordsCorrect = (
  trigger: "content-correct" | "content-correct-all",
  props: { selectedWordsAmount: number; selectionDuration: number; amountDiff: number; selectedBlanksAmount: number },
) => {
  const { selectedWordsAmount, selectionDuration, amountDiff, selectedBlanksAmount } = props;
  analyticsService.track("content-editor-edit", {
    // we calculate selectedWordsAmount + amountDiff to determine if we are deleting all selected words
    // example: 3 + -3 = 0 ("deleteWords") or 3 + -2 = 1 ("updateWords")
    action: selectedWordsAmount + amountDiff === 0 ? "deleteWords" : "updateWords",
    trigger,
    selectedWordsAmount,
    selectionDuration,
    amountDiff,
    selectedBlanksAmount,
  });
};
