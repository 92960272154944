import { HTMLAttributes, ReactNode, useMemo } from "react";
import useResizeObserver from "use-resize-observer";
import styled from "styled-components/macro";

const Container = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: hidden;
`;

const applyAspectRatio = (availableDimensions: { width: number; height: number }, aspectRatio: number) => {
  let { width } = availableDimensions;
  let height = width / aspectRatio;

  if (height > availableDimensions.height) {
    const factor = availableDimensions.height / height;

    height = availableDimensions.height;
    width *= factor;
  }

  return {
    width: Math.floor(width),
    height: Math.floor(height),
  };
};

interface AutoSizerProps extends Omit<HTMLAttributes<HTMLDivElement>, "children"> {
  children(size: { width: number; height: number }): ReactNode;
  aspectRatio?: number;
}

export default function AutoSizer({ children, aspectRatio, ...props }: AutoSizerProps) {
  const { ref, height = 0, width = 0 } = useResizeObserver();

  const memoizedChildren = useMemo(() => {
    const dimensions = { height, width };

    return children(aspectRatio ? applyAspectRatio(dimensions, aspectRatio) : dimensions);
  }, [children, width, height, aspectRatio]);

  return (
    <Container ref={ref} {...props}>
      {memoizedChildren}
    </Container>
  );
}
