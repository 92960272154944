import { ReactNode } from "react";

import { themeColor } from "src/utils/styledComponents.utils";
import styled from "styled-components/macro";
import { ifProp } from "styled-tools";

import Select from "src/components/common/form/inputs/Select.styled";
import SequenceFilterMenu from "src/components/features/SmartLibrary/filtering/filtersSelect/SequenceFilterMenu";
import SequenceFilterMultiValueIcon from "src/components/features/SmartLibrary/filtering/filtersSelect/SequenceFilterMultiValueIcon";
import SequenceFilterMultiValueLabel from "src/components/features/SmartLibrary/filtering/filtersSelect/SequenceFilterMultiValueLabel";
import SequenceFilterOptionComponent from "src/components/features/SmartLibrary/filtering/filtersSelect/SequenceFilterOptionComponent";
import SequenceFilterPlaceholder from "src/components/features/SmartLibrary/filtering/filtersSelect/SequenceFilterPlaceholder";
import SequenceDropdownIndicator from "src/components/features/SmartLibrary/filtering/filtersSelect/SwequenceDropdownIndicator";

export type SequenceFilterOption = {
  value: string;
  label: string;
  status?: string;
  icon: ReactNode;
};

export type FilterPlaceholder = {
  label: string;
  icon: ReactNode;
};

const StyledSelect = styled(Select<SequenceFilterOption, true>)<{ isLoading: boolean; isDisabled: boolean }>`
  flex: 1;
  .select__control {
    border: solid 1px;
    border-color: ${themeColor("blue.600")};
    border-radius: 30px;
    min-height: 30px;
    min-width: 90%;
    width: unset !important;
    width: fit-content;
    background-color: ${themeColor("blue.30")};
    cursor: ${ifProp("isDisabled", "not-allowed", "pointer")} !important;

    opacity: ${ifProp("isLoading", 1, 0.65)};
    transition: opacity 250ms ease-out;
    .select__value-container {
      padding: 0px 8px;
      width: 210px;
    }
    &:hover {
      background-color: ${themeColor("blue.30")} !important;
      border-color: ${themeColor("blue.900")} !important;
    }
  }

  .select__control--menu-is-open,
  .select__control--is-focused {
    background-color: ${themeColor("blue.30")} !important;
    border-color: ${themeColor("blue.900")} !important;
  }

  .select__control--menu-is-open {
    .select__indicators {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .select__indicators {
    max-height: 100%;
    margin-right: 14px;
    transition: transform 0.5s ease;
  }

  .select__dropdown-indicator {
    cursor: ${ifProp("isDisabled", "not-allowed", "pointer")};
  }

  .select__placeholder {
    color: ${themeColor("gray.900")} !important;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    svg {
    }
  }

  .select__clear-indicator {
    padding-inline: 4px;
  }

  .select__multi-value {
    background-color: ${themeColor("white")};
    border-radius: 80px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 2px 3px;
    margin: 2px;
    min-width: 60px;
    height: 26px;
  }

  .select__multi-value__label {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .select__multi-value__remove {
    cursor: ${ifProp("isDisabled", "not-allowed", "pointer")};
    background-color: ${themeColor("blue.100")};
    width: 18px;
    height: 18px;
    border-radius: 50%;
    padding-left: 5px;
    svg {
      fill: ${themeColor("blue.900")};
    }
    &:hover {
      background-color: ${themeColor("blue.600")};
    }
    transition: background-color 250ms ease-out;
  }
`;

export interface SequenceFilterSelectProps {
  supportedOptions: SequenceFilterOption[];
  selectedOptions: string[];
  onOptionsSelect(languageCodes: string[]): void;
  placeholder: FilterPlaceholder;
  isLoading?: boolean;
  isDisabled?: boolean;
  showIcons?: boolean;
}

export default function SequenceFilterSelect({
  supportedOptions,
  selectedOptions,
  onOptionsSelect,
  isLoading = false,
  isDisabled = false,
  placeholder,
  showIcons = false,
}: SequenceFilterSelectProps) {
  const selectedValues = supportedOptions.filter((opt) => selectedOptions.includes(opt.value));

  return (
    <StyledSelect
      isSearchable
      isClearable={false}
      isMulti
      value={selectedValues}
      options={supportedOptions.filter((opt) => opt.status !== "INACTIVE")}
      getOptionLabel={(x) => x.label!}
      getOptionValue={(x) => x.value!}
      onChange={(items) => {
        onOptionsSelect(items.map((opt) => opt.value));
      }}
      isDisabled={isDisabled}
      isLoading={isLoading}
      placeholder={placeholder.label}
      // @ts-ignore
      placeholderIcon={placeholder.icon}
      components={{
        Option: SequenceFilterOptionComponent,
        MultiValue: showIcons ? SequenceFilterMultiValueIcon : SequenceFilterMultiValueLabel,
        Placeholder: SequenceFilterPlaceholder,
        Menu: SequenceFilterMenu,
        DropdownIndicator: SequenceDropdownIndicator,
      }}
    />
  );
}
