import { ComponentType, PropsWithChildren } from "react";
import BaseMasonry, { MasonryPropTypes } from "react-masonry-component";
import { useOutletContext } from "react-router-dom";

import styled from "styled-components/macro";

const Masonry = BaseMasonry as ComponentType<PropsWithChildren<MasonryPropTypes>>;

const Container = styled.div`
  width: 100%;
  padding: 0 20px 0 10px;

  @media (min-width: 1024px) {
    padding: 0 20px 0 34px;
  }
`;

interface ResponsivePeechLibraryProps extends React.HTMLProps<HTMLElement> {
  horizontalGutter?: number;
}

export default function ResponsivePeechLibrary({ children, className, horizontalGutter }: ResponsivePeechLibraryProps) {
  const isSidebarExpanded = useOutletContext();

  return (
    <Container className={className}>
      <Masonry
        key={isSidebarExpanded ? "0" : "1"}
        options={{ horizontalOrder: true, gutter: horizontalGutter ?? 15 }}
        enableResizableChildren
        className="row js-animation"
      >
        {children}
      </Masonry>
    </Container>
  );
}
