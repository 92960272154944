import { useEffect, useRef, useState } from "react";
import styled, { css, keyframes } from "styled-components/macro";
import { range } from "lodash/fp";

import { SpaceValue } from "src/theme";
import { ifProp, styleProp, themeColor, themeProp, themeZ } from "src/utils/styledComponents.utils";

import { Stack } from "src/components/common/layout/Stack.styled";
import { useSwiperContext } from "src/components/common/Swiper/Swiper";
import { SWIPER_SLIDE_PADDING_LEFT } from "src/components/common/Swiper/SwiperSlide";

const Container = styled.div<{ indicatorSize: number }>`
  z-index: ${themeZ("swiperActiveSlideIndicator")};
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${(props) => (SWIPER_SLIDE_PADDING_LEFT - props.indicatorSize) / 2}px;
  display: flex;
  align-items: center;
`;

const IndicatorStack = styled(Stack)`
  position: relative;
`;

const SlideIndicator = styled.div<{ isActive?: boolean; indicatorSize: number }>`
  width: ${styleProp("indicatorSize")}px;
  height: ${styleProp("indicatorSize")}px;
  border-radius: 50%;
  background-color: ${themeProp("colors.gray.300")};
`;

const growShrink = keyframes`
  0% {
    scale: 1;
  }

  20% {
    scale: 1.75;
  }

  80% {
    scale: 1.75;
  }

  100% {
    scale: 1;
  }
`;

interface ActiveSlideIndicatorProps {
  direction: "column" | "row";
  indicatorSpacing: SpaceValue;
  indicatorSize: number;
  activeIndex: number;
  animateIndexChange: boolean;
}

const getOffset = ({ activeIndex, indicatorSpacing, indicatorSize }: ActiveSlideIndicatorProps) =>
  activeIndex * (indicatorSpacing + indicatorSize);

const ActiveSlideIndicator = styled(SlideIndicator)<ActiveSlideIndicatorProps>`
  position: absolute;
  top: ${ifProp(({ direction }) => direction === "column", getOffset, 0)}px;
  left: ${ifProp(({ direction }) => direction === "row", getOffset, 0)}px;
  background-color: ${themeColor("pink.500")};
  transition: top 500ms ease-in-out;

  ${ifProp(
    "animateIndexChange",
    css`
      animation: ${growShrink} 500ms ease-in;
    `,
  )}
`;

interface SwiperActiveSlideIndicatorProps extends Partial<ActiveSlideIndicatorProps> {
  className?: string;
}

export default function SwiperActiveSlideIndicator({
  className,
  direction = "column",
  indicatorSpacing = 40,
  indicatorSize = 8,
}: SwiperActiveSlideIndicatorProps) {
  const { slideCount, activeIndex } = useSwiperContext();
  const [animateIndexChange, setAnimateIndexChange] = useState(false);
  const activeSlideIndicatorRef = useRef<HTMLDivElement>(null);

  useEffect(
    () => () => {
      requestAnimationFrame(() => setAnimateIndexChange(true));
    },
    [activeIndex],
  );

  return (
    <Container className={className} indicatorSize={indicatorSize}>
      <IndicatorStack direction={direction} spacing={indicatorSpacing}>
        {range(0, slideCount).map((slideIndex) => (
          <SlideIndicator key={slideIndex} isActive={slideIndex === activeIndex} indicatorSize={indicatorSize} />
        ))}

        <ActiveSlideIndicator
          ref={activeSlideIndicatorRef}
          direction={direction}
          indicatorSpacing={indicatorSpacing}
          indicatorSize={indicatorSize}
          activeIndex={activeIndex}
          animateIndexChange={animateIndexChange}
          onAnimationEnd={() => setAnimateIndexChange(false)}
        />
      </IndicatorStack>
    </Container>
  );
}
