import { useEffect, useState, RefObject } from "react";

export default function useIsVideoLoading(videoRef: RefObject<HTMLVideoElement>) {
  const [isLoadingVideo, setIsLoadingVideo] = useState(false);
  const [isVideoBuffering, setIsVideoBuffering] = useState(false);

  useEffect(() => {
    const video = videoRef.current;

    const onLoadStart = () => setIsLoadingVideo(true);
    const onCanPlay = () => setIsLoadingVideo(false);

    const onWaiting = () => setIsVideoBuffering(true);
    const onPlaying = () => setIsVideoBuffering(false);

    video?.addEventListener("loadstart", onLoadStart);
    video?.addEventListener("canplay", onCanPlay);

    video?.addEventListener("waiting", onWaiting);
    video?.addEventListener("playing", onPlaying);

    return () => {
      video?.removeEventListener("loadstart", onLoadStart);
      video?.removeEventListener("canplay", onCanPlay);

      video?.removeEventListener("waiting", onWaiting);
      video?.removeEventListener("playing", onPlaying);
    };
  }, [videoRef]);

  return isLoadingVideo || isVideoBuffering;
}
