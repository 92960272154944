import styled, { css } from "styled-components/macro";

import { themeColor, themeZ, ifProp } from "src/utils/styledComponents.utils";

import { Box, FlexBox } from "src/components/common/layout/Box.styled";

export const Footer = styled.div`
  z-index: ${themeZ("createProjectSwiperFooter")};
  position: absolute;
  bottom: 0;
  left: 0;
  height: 73px;
  width: 100%;
  min-width: 310px;
  background-color: ${themeColor("white")};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.3);
`;

export const CentralButtonsWrapper = styled.div<{ hide?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 100%;
  visibility: ${ifProp("hide", "hidden", "visible")};
`;

export const NextButtonWrapper = styled.div`
  width: fit-content;
  height: fit-content;
`;

export const BackButtonWrapper = styled.div`
  @media (min-width: 500px) {
    position: absolute;
    top: 50%;
    left: -100%;
    transform: translateY(-50%);
  }
`;

export const CancelButtonWrapper = styled.div<{ hide?: boolean }>`
  position: absolute;
  top: 25px;
  left: 19px;
  transition: scale 150ms ease-out;

  @media (max-width: 340px) {
    left: 0;
  }

  ${ifProp(
    "hide",
    css`
      scale: 0;
    `,
  )};
`;

export const StepContainer = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const HeaderText = styled.div`
  padding-inline: 10px;
  font-family: "Work Sans", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  color: ${themeColor("gray.900")};
  text-align: center;

  @media (min-width: 425px) {
    font-size: 24px;
    line-height: 24px;
  }
`;

export const SubHeaderText = styled(HeaderText)`
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
  margin-top: 14px;
`;

export const GridScroll = styled(Box)`
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: auto !important;
`;

export const GridScroll2 = styled(FlexBox)`
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto !important;
`;
