import { HTMLProps } from "react";
import { useNavigate } from "react-router-dom";

import { themeColor } from "src/utils/styledComponents.utils";

import styled from "styled-components/macro";

import { Button } from "src/components/common/buttons/Button.styled";

export const StyledButton = styled(Button)`
  width: 63px;
  height: 28px;
  min-height: unset;
  padding: unset;
  border: unset;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  color: ${themeColor("gray.300")};
`;

export default function CancelButton({
  navigateToPath = "/",
  children,
  ...props
}: Pick<HTMLProps<HTMLButtonElement>, "style" | "className" | "children"> & { navigateToPath?: string }) {
  const navigate = useNavigate();
  return (
    <StyledButton
      onClick={() => {
        navigate(navigateToPath);
      }}
      disabled={false}
      variant="link"
      size="large"
      {...props}
    >
      {children}
    </StyledButton>
  );
}
