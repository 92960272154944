import { useSelector } from "react-redux";
import { RootState } from "src/models/store";
import styled from "styled-components/macro";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  max-height: 100%;
  p {
    text-align: center;
    font-size: 24px;
    max-height: 80%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export default function ErrorModal() {
  const recentError = useSelector((state: RootState) => state.session.recentError);

  return (
    <Container>
      <h1>Error</h1>
      <p>{recentError}</p>
    </Container>
  );
}
