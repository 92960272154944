import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useMatch, useNavigate, useSearchParams } from "react-router-dom";

import { sequenceSelectors } from "src/models/Sequence.model";
import { CurrentSequenceSavingStatusType, PlayFromData } from "src/models/Session.model";
import { Dispatch, RootState } from "src/models/store";
import { SequenceInteraction } from "src/network/graphql/generatedGraphqlSDK";

import { absoluteRoutes, applyPathParams, routerRoutes } from "src/utils/routes.utils";
import { themeColor } from "src/utils/styledComponents.utils";

import styled from "styled-components/macro";
import { ifProp, switchProp } from "styled-tools";

import NotificationCenterPopover from "src/components/app/AppRouter/layouts/NotificationsCenter/NotificationCenterPopover";
import UserMenuPopover from "src/components/app/AppRouter/layouts/UserMenuPopover";
import Icon from "src/components/common/Icon";
import Tooltip from "src/components/common/popovers/Tooltip";
import EditableSequenceTitle from "src/components/common/editableSequenceTitle/EditableSequenceTitle";
import { Stack } from "src/components/common/layout/Stack.styled";
import { CircularLoader } from "src/components/common/loaders/CircularLoader";
import useSequenceSavingStatus from "src/hooks/useSequenceSavingStatus";
import {
  BackBtn,
  Header,
  HeaderElementWrapper,
  LeftSide,
  RightSide,
} from "src/components/app/AppRouter/layouts//Header.styled";
import { useAppConfig } from "src/components/providers/AppConfigProvider";
import { getQuery } from "src/utils/urlQuery.utils";
import { useEventSubscriber } from "src/hooks/useEventEmitter";
import { CSSTransitionStyled } from "src/components/common/layout/CSSTranstion.styled";

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  overflow: hidden;
  background-color: ${themeColor("gray.50")};
`;

const StyledStack = styled(Stack)`
  cursor: pointer;
  align-items: "center";
  color: ${themeColor("gray.400")};
`;

const StyledLeftSide = styled(LeftSide)`
  width: calc(50% - 134px);
`;

const Tab = styled.div<{ isActive?: boolean; disabled?: boolean }>`
  padding: 5px 7px;
  border-radius: 6px;
  background-color: ${ifProp("isActive", themeColor("blue.50"), "transparent")};
  color: ${ifProp("isActive", themeColor("blue.500"), themeColor("gray.400"))};
  color: ${switchProp("disabled", {
    true: themeColor("gray.300"),
    false: ifProp("isActive", themeColor("blue.500"), themeColor("gray.400")),
  })};
  cursor: pointer;
  pointer-events: ${switchProp("disabled", {
    true: "none",
    false: ifProp("isActive", "none", "auto"),
  })};
  transition: background-color 300ms ease-out, color 300ms ease-out;

  &:hover {
    background-color: ${themeColor("gray.200")};
    color: ${themeColor("blue.500")};
  }
`;

const Separator = styled.div`
  width: 1px;
  margin-block: 10px;
  background-color: ${themeColor("gray.400")};
`;

const AbsoluteCenter = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;
const StyledBackBtn = styled(BackBtn)`
  svg {
    color: ${themeColor("gray.400")};
  }
`;

const SavingMark = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: ${themeColor("gray.900")};
  svg {
    color: ${themeColor("pink.500")};
    margin-right: 10px;
  }
`;

const FlexibleWrapper = styled(HeaderElementWrapper)`
  display: flex;
  align-items: center;
  flex: 1;
  max-width: calc(100% - 180px);
`;

interface Props {
  showTabs?: boolean;
}

export default function WithEditorHeader({ showTabs = true }: Props) {
  const { EDITOR_HEADER } = useAppConfig();
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const projectEditorPathMatch = useMatch("project/:sequenceSid/*");
  const { sequenceSid } = projectEditorPathMatch?.params || {};
  const sequence = useSelector((state: RootState) => sequenceSelectors.selectById(state, sequenceSid ?? ""));
  const useFootageForCrop = useSelector((state: RootState) =>
    sequenceSelectors.selectUseFootageForCrop(state, sequenceSid ?? ""),
  );
  const {
    offset: playFromOffset,
    chapterSid: playFromChapterSid,
    currentTime: playFromCurrentTime,
  } = useSelector((state: RootState) => state.session?.playFrom);
  const { search } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  // get initial playFromData state from query string
  const [playFromLocal, setPlayFromLocal] = useState({
    offset: Number(getQuery(search, "playbackOffset")) || playFromOffset || 0,
    chapterSid: String(getQuery(search, "playbackChapter")) || playFromChapterSid || "",
    currentTime: playFromCurrentTime || 0,
  });

  const {
    offset: playFromLocalOffset,
    chapterSid: playFromLocalChapterSid,
    currentTime: playFromLocalCurrentTime,
  } = playFromLocal;

  // listen for "currentTimeChanged" event - set local state playFromData
  useEventSubscriber("currentTimeChanged", (playFrom: PlayFromData) => {
    setPlayFromLocal({
      offset: playFrom?.offset || 0,
      chapterSid: playFrom?.chapterSid || "",
      currentTime: playFrom?.currentTime || 0,
    });
  });

  // on mount:
  // 1. set session.playFrom to be same as local state playFromData
  // 2. start listen for postMessage events from reactor
  useEffect(() => {
    dispatch.session.setPlayFrom({
      offset: playFromLocalOffset,
      chapterSid: playFromLocalChapterSid,
      currentTime: playFromLocalCurrentTime,
    });

    const onMessage = (event: MessageEvent) => {
      const { eventName, offset, chapterSid } = event.data;
      if (eventName === "reactorCurrentTimeChanged" && offset > -1 && chapterSid) {
        setPlayFromLocal({
          ...playFromLocal,
          offset,
          chapterSid,
        });
        // delete query params from url
        const params = new URLSearchParams(searchParams);
        params.delete("playbackChapter");
        params.delete("playbackOffset");
        setSearchParams(params);
      }
    };
    window.addEventListener("message", onMessage);
    return () => {
      window.removeEventListener("message", onMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const sequenceSavingStatus = useSequenceSavingStatus(sequenceSid);

  const interactSequenceOpened = useCallback(() => {
    if (sequence && sequence?.footageSid) {
      dispatch.footage?.interactClipSequence({
        footageId: sequence?.footageSid as string,
        externalSequenceId: sequenceSid as string,
        sequenceInteraction: "opened" as SequenceInteraction,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sequenceSid, sequence?.footageSid]);

  const onTabButtonClick = useCallback(
    (action: "visual" | "crop" | "content") => {
      if (sequenceSid) {
        let path;
        switch (action) {
          case "visual":
            path = `${absoluteRoutes.projectSequence.children.visualsEditor}?playbackChapter=${playFromLocalChapterSid}&playbackOffset=${playFromLocalOffset}`;
            break;

          case "crop":
            path = `${absoluteRoutes.projectSequence.children.cropEditor}?playbackChapter=${playFromLocalChapterSid}&playbackOffset=${playFromLocalOffset}`;
            break;

          default:
            path = `${absoluteRoutes.projectSequence.children.contentEditor}?playbackChapter=${playFromLocalChapterSid}&playbackOffset=${playFromLocalOffset}`;
            break;
        }
        // when moving between tabs update session.setPlayFrom from local state
        dispatch.session.setPlayFrom({
          offset: playFromLocalOffset,
          chapterSid: playFromLocalChapterSid,
          currentTime: playFromLocalCurrentTime || 0,
        });
        interactSequenceOpened();
        navigate(applyPathParams(path, { sequenceSid }));
      }
    },
    [
      sequenceSid,
      dispatch.session,
      playFromLocalOffset,
      playFromLocalChapterSid,
      playFromLocalCurrentTime,
      interactSequenceOpened,
      navigate,
    ],
  );

  const onBackBtnClick = useCallback(() => {
    if (sequenceSid && sequence?.footageSid) {
      navigate(applyPathParams(absoluteRoutes.platform.children.footage, { footageId: sequence?.footageSid }));
    } else {
      navigate(absoluteRoutes.platform.self);
    }
  }, [navigate, sequence, sequenceSid]);

  useEffect(() => {
    dispatch.sequences.fetchSequence({ sequenceSid: sequenceSid as string });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const onMessage = (event: MessageEvent) => {
      if (event.data === "DOWNLOAD_MODAL_CLOSED_IN_PROGRESS") {
        onBackBtnClick();
      }
    };
    window.addEventListener("message", onMessage);
    return () => {
      window.removeEventListener("message", onMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    interactSequenceOpened();
  }, [interactSequenceOpened]);

  const nodeRef = useRef(null);
  return (
    <>
      <Header>
        <StyledLeftSide>
          <StyledBackBtn onClick={onBackBtnClick}>
            <Icon.ArrowDown />
          </StyledBackBtn>
          <StyledStack
            style={{ alignItems: "center", cursor: "pointer" }}
            direction="row"
            spacing={10}
            onClick={onBackBtnClick}
          >
            Home
          </StyledStack>
          {showTabs && (
            <FlexibleWrapper>
              <EditableSequenceTitle sequenceSid={sequenceSid} />
              <HeaderElementWrapper style={{ width: "56px" }}>
                {sequenceSavingStatus === CurrentSequenceSavingStatusType.IN_PROGRESS && (
                  <CircularLoader style={{ position: "absolute" }} size={20} thickness={3} />
                )}

                <CSSTransitionStyled
                  timeout={400}
                  nodeRef={nodeRef}
                  classNames="fade"
                  in={sequenceSavingStatus === CurrentSequenceSavingStatusType.SUCCESS}
                >
                  <SavingMark ref={nodeRef}>
                    <Icon.Check />
                    Saved
                  </SavingMark>
                </CSSTransitionStyled>
              </HeaderElementWrapper>
            </FlexibleWrapper>
          )}
        </StyledLeftSide>
        {showTabs && (
          <AbsoluteCenter>
            <Stack direction="row" spacing={4}>
              <Tab
                isActive={location.pathname.endsWith(routerRoutes.projectSequence.children.contentEditor)}
                onClick={() => onTabButtonClick("content")}
              >
                {EDITOR_HEADER.tabs.content?.label || "Content Editor"}
              </Tab>

              <Separator />

              <>
                <Tooltip text={EDITOR_HEADER.tabs.crop?.disabled?.tooltip} disabled={!!useFootageForCrop}>
                  <Tab
                    disabled={!useFootageForCrop}
                    isActive={location.pathname.endsWith(routerRoutes.projectSequence.children.cropEditor)}
                    onClick={() => onTabButtonClick("crop")}
                  >
                    {EDITOR_HEADER.tabs.crop?.label || "Frame Cropper"}
                  </Tab>
                </Tooltip>

                <Separator />
              </>

              <Tab
                isActive={location.pathname.endsWith(routerRoutes.projectSequence.children.visualsEditor)}
                onClick={() => onTabButtonClick("visual")}
              >
                {EDITOR_HEADER.tabs.visual?.label || "Visual Editor"}
              </Tab>
            </Stack>
          </AbsoluteCenter>
        )}
        <RightSide>
          <HeaderElementWrapper>
            <NotificationCenterPopover />
          </HeaderElementWrapper>
          <HeaderElementWrapper>
            <UserMenuPopover position="bottom-end" />
          </HeaderElementWrapper>
        </RightSide>
      </Header>

      <ContentContainer>
        <Outlet />
      </ContentContainer>
    </>
  );
}
