import { SequenceForm } from "src/components/features/CreateSequenceWizard/SequenceForm";
import { VIDEO_EDIT_RULES } from "src/hooks/useEditRules";

export type VideoEditRule = keyof NonNullable<SequenceForm["visuals"]["editRules"]>;
export type ServerConstantRules = "useVideos" | "useImages" | "useTransitions" | "useBulletframe";
export type VideoEditRules = {
  [key in VideoEditRule | ServerConstantRules]: {
    elementName: string;
    disabled: boolean;
    isSelected: boolean;
    name?: string;
  };
};

export const useVideoEditRulesTiles = (): VideoEditRules => VIDEO_EDIT_RULES;
