import { useState, useEffect, useRef, forwardRef, HTMLProps } from "react";
import styled from "styled-components/macro";
import Color from "color";

import { themeProp } from "src/utils/styledComponents.utils";

import { Stack } from "src/components/common/layout/Stack.styled";

import Saturation from "./Saturation";
import HueRange from "./HueRange";
import ColorInput from "./ColorInput";
import SwatchPalette, { Palette } from "./SwatchPalette";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 260px;
  padding: 12px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3);
  border-radius: ${themeProp("borderRadii.small")}px;
  background-color: #fff;
`;

interface ColorPickerProps extends Omit<HTMLProps<HTMLInputElement>, "color" | "onChange"> {
  onChange: (color: Color) => void;
  color: Color;
  defaultColor?: string;
  palettes?: Palette[];
}

const ColorPicker = forwardRef<HTMLDivElement, ColorPickerProps>(
  ({ onChange, color, defaultColor, style, palettes, className }, ref) => {
    const [colorObj, setColorObj] = useState(Color(color || defaultColor));
    const didMount = useRef<boolean>(false);

    useEffect(() => {
      if (didMount.current) {
        onChange(colorObj);
      } else {
        didMount.current = true;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [colorObj]);

    return (
      <Container style={style} className={className} ref={ref}>
        <Saturation color={colorObj} onChange={setColorObj} />
        <HueRange color={colorObj} onChange={setColorObj} />
        <ColorInput color={colorObj} onChange={setColorObj} style={{ marginTop: "16px" }} />

        <Stack direction="column" spacing={12} marginTop={8}>
          {palettes?.map((palette) => (
            <SwatchPalette key={palette.name} palette={palette} onChange={setColorObj} />
          ))}
        </Stack>
      </Container>
    );
  },
);

export default ColorPicker;
