import { EventEmitter } from "events";
import { getImageFromVideo } from "src/utils/image.utils";
import { VideoMetaData, getVideoFileInfo } from "src/utils/uploader.utils";

// TODO: used to save in local storage for resume upload
export interface IFileUploadMetaData {
  totalBytes: number;
  uploadedBytes: number;
  fileName: string;
}

interface IDefaultOptions {
  chunkSize: number;
  maxRetries: number;
  retryDelay: number;
  maxConcurrentChunks: number;
}

export enum FileUploadStatus {
  PENDING = "PENDING",
  UPLOADING = "UPLOADING",
  PAUSED = "PAUSED",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
}

export interface IUploadOptions {
  chunkSize?: IDefaultOptions["chunkSize"];
  maxRetries?: IDefaultOptions["maxRetries"];
  retryDelay?: IDefaultOptions["retryDelay"];
  maxConcurrentChunks?: IDefaultOptions["maxConcurrentChunks"];
}

export const MEGA_BYTE = 1024 * 1024;
export const DEFAULT_THUMBNAIL_TIME = 3;
const DEFAULT_CHUNK_SIZE = MEGA_BYTE * 8; // 8 MB
const DEFAULT_MAX_RETRIES = 3;
const DEFAULT_RETRY_DELAY = 3000; // 3 second
const DEFAULT_MAX_CONCURRENT_CHUNKS = 8;

const defaultOption: IDefaultOptions = {
  chunkSize: DEFAULT_CHUNK_SIZE,
  maxRetries: DEFAULT_MAX_RETRIES,
  retryDelay: DEFAULT_RETRY_DELAY,
  maxConcurrentChunks: DEFAULT_MAX_CONCURRENT_CHUNKS,
};

type Events = {
  start(): void;
  error(error: Error): void;
  statusChange(status: FileUploadStatus): void;
  progress(progress: number): void;
  success(): void;
};

export default class BaseUploadService {
  protected readonly eventEmitter = new EventEmitter<Events>();
  protected file: File;
  protected uploadOptions: IUploadOptions;
  protected statusPrivate: FileUploadStatus;
  protected generatedThumbnail: string | null;
  protected videoFileInfo: VideoMetaData | null;
  protected uploadedBytes: number;

  constructor(file: File, uploadOptions: IUploadOptions) {
    this.file = file;
    this.uploadOptions = { ...defaultOption, ...uploadOptions };
    this.uploadedBytes = 0;
    this.statusPrivate = FileUploadStatus.PENDING;
    this.generatedThumbnail = null;
    this.videoFileInfo = null;
  }

  on: EventEmitter<Events>["on"] = (...args) => this.eventEmitter.on(...args);
  off: EventEmitter<Events>["off"] = (...args) => this.eventEmitter.off(...args);
  once: EventEmitter<Events>["once"] = (...args) => this.eventEmitter.once(...args);

  start() {
    // need to override by extend class
    this.eventEmitter.emit("start");
  }

  sleep(ms: number) {
    // eslint-disable-next-line no-promise-executor-return
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  onError(error: Error) {
    this.status = FileUploadStatus.ERROR;
    this.eventEmitter.emit("error", error);
  }

  onSuccess() {
    this.status = FileUploadStatus.SUCCESS;
    this.eventEmitter.emit("success");
  }

  async getThumbnail(option?: { width?: number; height?: number; time?: number }) {
    if (this.generatedThumbnail) {
      return this.generatedThumbnail;
    }
    try {
      const thumb = await getImageFromVideo(this.file, option?.time || DEFAULT_THUMBNAIL_TIME, {
        width: option?.width,
        height: option?.height,
      });
      this.generatedThumbnail = thumb;
      return this.generatedThumbnail;
    } catch (error) {
      return null;
      // handle error save a place holder thumb
    }
  }

  set status(value: FileUploadStatus) {
    this.statusPrivate = value;
    this.eventEmitter.emit("statusChange", this.status);
  }

  get status() {
    return this.statusPrivate;
  }

  get fileSize() {
    if (this.file) {
      return this.file.size;
    }
    return 0;
  }

  get progress() {
    if (this.fileSize > 0 && this.uploadedBytes > 0) {
      return this.uploadedBytes / this.file.size;
    }
    return 0;
  }

  get videoMetaData() {
    return this.getVideoMetaData();
  }

  private async getVideoMetaData() {
    if (!this.videoFileInfo) {
      const videoMediaInfo = await getVideoFileInfo(this.file);
      this.videoFileInfo = videoMediaInfo;
    }

    return this.videoFileInfo;
  }
}
