import { createModel } from "@rematch/core";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";

import { RootState } from "src/models/store";
import { RootModel } from "src/models/index";
import { selectId } from "src/models/selectId";
import apiClient from "src/network/ApiClient";
import * as gql from "src/network/graphql/generatedGraphqlSDK";
import { BoardSortFields, SortDirection } from "src/network/graphql/generatedGraphqlSDK";

const boardAdapter = createEntityAdapter<gql.Board>({ selectId });
const boardAdapterSelectors = boardAdapter.getSelectors((state: RootState) => state.boards);

const selectAllSortedByName = (state: RootState) => {
  const boards = boardAdapterSelectors.selectAll(state);
  return boards.sort((a, b) => a.name.localeCompare(b.name));
};

const selectBoardByFootageId = createSelector(
  (state: RootState) => state,
  (state: RootState, footageId: string | undefined) => footageId,
  (state, footageId) => {
    if (!footageId) return null;
    const footageBoard = boardAdapterSelectors.selectAll(state).find((board) => board?.footageIds?.includes(footageId));
    return footageBoard;
  },
);

export const boardSelectors = {
  selectAllSortedByName,
  selectBoardByFootageId,
  ...boardAdapterSelectors,
};

interface BoardsModelState {
  ids: string[];
  entities: Record<string, gql.Board>;
  pagination: {
    isInitialized: false;
    footageIds: [];
    hasMore: false;
  };
}

const boards = createModel<RootModel>()({
  state: {
    ...boardAdapter.getInitialState(),
  } as BoardsModelState,

  reducers: {
    setBoard: (state, payload: gql.Board) => boardAdapter.setOne(state, payload),
    setBoards: (state, payload: gql.Board[]) => boardAdapter.setMany(state, payload),
    removeBoard: (state, payload: string) => boardAdapter.removeOne(state, payload),
  },

  effects: (dispatch) => ({
    async fetchBoards(variables: gql.GetBoardsQueryVariables) {
      const fetchedBoards = await apiClient.getBoards(variables);
      dispatch.boards.setBoards(fetchedBoards.nodes);
    },

    async createBoard(name: string) {
      const board = await apiClient.createBoard({ input: { board: { name } } });
      dispatch.boards.setBoard(board);
      // return the id of the created board to navigate to it
      return board?.id;
    },

    async updateBoard(variables: gql.UpdateBoardMutationVariables) {
      const board = await apiClient.updateBoard(variables);
      dispatch.boards.setBoard(board);
    },

    async deleteBoard(variables: gql.DeleteBoardMutationVariables) {
      const board = await apiClient.deleteBoard(variables);
      board?.id && dispatch.boards.removeBoard(board.id);
    },

    async moveFootageToBoard(variables: gql.MoveFootageToBoardMutationVariables, state) {
      await apiClient.moveFootageToBoard(variables);
      await dispatch.boards.fetchBoards({
        sorting: {
          field: BoardSortFields.Name,
          direction: SortDirection.Asc,
        },
        paging: {
          offset: 0,
          limit: 1000,
        },
        filter: {},
      });
      // remove from redux state so that footage is not shown in the board
      state.footage.currentBoardId && dispatch.footage.removeFootage(variables.footageId);
    },

    async removeFootageFromBoard(variables: gql.RemoveFootageFromBoardMutationVariables, state) {
      await apiClient.removeFootageFromBoard(variables);
      await dispatch.boards.fetchBoards({
        sorting: {
          field: BoardSortFields.Name,
          direction: SortDirection.Asc,
        },
        paging: {
          offset: 0,
          limit: 1000,
        },
        filter: {},
      });
      // remove from redux state so that footage is not shown in the board
      state.footage.currentBoardId && dispatch.footage.removeFootage(variables.footageId);
    },
  }),
});

export default boards;
