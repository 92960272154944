import { v4 as uuid } from "uuid";

import { timeIntervalUtils } from "src/utils/timeInterval.utils";
import { TimeInterval, ChapterTimeInterval } from "src/types/video-trimmer.types";

export const chapterTimeIntervalUtils = {
  normalizeChapterIds(chapters: ChapterTimeInterval[]) {
    const chapterIdsDict: Record<string, boolean> = {};

    return chapters.map<ChapterTimeInterval>((chapter) => {
      if (chapterIdsDict[chapter.id]) {
        return { ...chapter, id: uuid() };
      }

      chapterIdsDict[chapter.id] = true;

      return chapter;
    });
  },

  sliceIntervalToChapters(timeInterval: TimeInterval, sourceVideoTimeIntervals: TimeInterval[]): ChapterTimeInterval[] {
    if (!sourceVideoTimeIntervals.length) return [];

    const sourceVideoTimeInterval = sourceVideoTimeIntervals[0];
    const [a, b] = timeIntervalUtils.slice(timeInterval, sourceVideoTimeInterval.end);

    if (!a) return b ? this.sliceIntervalToChapters(b, sourceVideoTimeIntervals.slice(1)) : [];

    const chapter: ChapterTimeInterval = {
      ...a,
      id: uuid(),
      domain: sourceVideoTimeInterval,
      isMuted: false,
      isSource: false,
    };

    return b ? [chapter, ...this.sliceIntervalToChapters(b, sourceVideoTimeIntervals.slice(1))] : [chapter];
  },

  // remove gaps from time interval if words not start at 0 and there is no words or chapters at the start of the video
  // same for the end of the video
  // if chapter time is in the middle of the words interval it will fixed times when add or subtract chapters
  removeGapsTimeInterval(
    interval: TimeInterval,
    chaptersIntervals: ChapterTimeInterval[],
    wordsIntervals: TimeInterval[],
  ) {
    const startChapter = chaptersIntervals.find((chapter) =>
      timeIntervalUtils.includesTimePoint(chapter, interval.start),
    );
    const endChapter = chaptersIntervals.find((chapter) => timeIntervalUtils.includesTimePoint(chapter, interval.end));

    const leftSiblingChapter = timeIntervalUtils.findClosestIntervalBefore(chaptersIntervals, interval.start);
    const rightSiblingChapter = timeIntervalUtils.findClosestIntervalAfter(chaptersIntervals, interval.end);

    if (startChapter || leftSiblingChapter) {
      const leftSpace: TimeInterval = {
        start: startChapter ? startChapter.start : leftSiblingChapter!.end,
        end: interval.start,
      };

      if (!wordsIntervals.some((item) => timeIntervalUtils.containsTimeInterval(leftSpace, item))) {
        interval.start = leftSpace.start;
      }
    }

    if (endChapter || rightSiblingChapter) {
      const rightSpace: TimeInterval = {
        start: interval.end,
        end: endChapter ? endChapter.end : rightSiblingChapter!.start,
      };

      if (!wordsIntervals.some((item) => timeIntervalUtils.containsTimeInterval(rightSpace, item))) {
        interval.end = rightSpace.end;
      }
    }

    interval.end = parseFloat((interval.end).toFixed(3));
    interval.start = parseFloat((interval.start).toFixed(3));

    return interval;
  },
};
