import { useEffect, useRef, useState } from "react";
import { range } from "lodash/fp";

import styled, { css, keyframes } from "styled-components/macro";
import { SpaceValue } from "src/theme";
import { ifProp, styleProp, themeColor, themeZ } from "src/utils/styledComponents.utils";

import { Stack } from "src/components/common/layout/Stack.styled";
import { useSwiperContext } from "src/components/common/Swiper/Swiper";
import steps from "src/components/features/CreateSequenceWizard/steps/steps";

const growShrink = keyframes`
  0% {
    scale: 1;
  }

  20% {
    scale: 1.15;
  }

  80% {
    scale: 1.15;
  }

  100% {
    scale: 1;
  }
`;

const Container = styled.div<{ indicatorSize: number }>`
  z-index: ${themeZ("swiperActiveSlideIndicator")};
  position: absolute;
  top: 20px;
  left: calc(50% - 145px);
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (min-width: 375px) {
    left: calc(50% - 160px);
  }
`;

const IndicatorStack = styled(Stack)`
  position: relative;
`;

const SlideIndicator = styled.div<{ isActive?: boolean; indicatorSize: number }>`
  width: ${styleProp("indicatorSize")}px;
  height: ${styleProp("indicatorSize")}px;
  border-radius: 50%;
  background-color: ${ifProp("isActive", themeColor("blue.500"), themeColor("gray.300"))};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${themeColor("white")};
  position: relative;
`;

const Connector = styled.div<{ isActive: boolean }>`
  position: absolute;
  top: 12px;
  right: 27px;
  height: 2px;
  width: 70px;
  background-color: ${ifProp("isActive", themeColor("blue.500"), themeColor("gray.300"))};
  transition: background-color 500ms ease-in-out;
`;

const IndicatorName = styled.div<{ isActive: boolean }>`
  position: absolute;
  bottom: -16px;
  width: 27px;
  color: ${ifProp("isActive", themeColor("gray.900"), themeColor("gray.300"))};
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  transition: color 500ms ease-in-out;

  @media (min-width: 375px) {
    font-size: 10px;
  }
`;

interface ActiveSlideIndicatorProps {
  direction: "column" | "row";
  indicatorSpacing: SpaceValue;
  indicatorSize: number;
  activeIndex: number;
  animateIndexChange: boolean;
}

const getOffset = ({ activeIndex, indicatorSpacing, indicatorSize }: ActiveSlideIndicatorProps) =>
  activeIndex * (indicatorSpacing + indicatorSize);

const ActiveSlideIndicator = styled(SlideIndicator)<ActiveSlideIndicatorProps>`
  position: absolute;
  top: ${ifProp(({ direction }) => direction === "column", getOffset, 0)}px;
  left: ${ifProp(({ direction }) => direction === "row", getOffset, 0)}px;
  background-color: ${themeColor("blue.500")};
  transition: top 500ms ease-in-out;

  ${ifProp(
    "animateIndexChange",
    css`
      animation: ${growShrink} 500ms ease-in;
    `,
  )}
`;

interface SwiperActiveSlideIndicatorProps extends Partial<ActiveSlideIndicatorProps> {
  className?: string;
}

export default function SwiperActiveSlideIndicator({
  className,
  direction = "column",
  indicatorSpacing = 70,
  indicatorSize = 27,
}: SwiperActiveSlideIndicatorProps) {
  const { slideCount, activeIndex } = useSwiperContext();
  const [animateIndexChange, setAnimateIndexChange] = useState(false);
  const activeSlideIndicatorRef = useRef<HTMLDivElement>(null);

  useEffect(
    () => () => {
      requestAnimationFrame(() => setAnimateIndexChange(true));
    },
    [activeIndex],
  );

  return (
    <Container className={className} indicatorSize={indicatorSize}>
      <IndicatorStack direction={direction} spacing={indicatorSpacing}>
        {range(0, slideCount).map((slideIndex) => (
          <SlideIndicator key={slideIndex} isActive={slideIndex <= activeIndex} indicatorSize={indicatorSize}>
            {slideIndex + 1}
            {slideIndex > 0 && <Connector isActive={slideIndex <= activeIndex} />}
            <IndicatorName isActive={slideIndex <= activeIndex}>{steps[slideIndex].path}</IndicatorName>
          </SlideIndicator>
        ))}

        <ActiveSlideIndicator
          ref={activeSlideIndicatorRef}
          direction={direction}
          indicatorSpacing={indicatorSpacing}
          indicatorSize={indicatorSize}
          activeIndex={activeIndex}
          animateIndexChange={animateIndexChange}
          onAnimationEnd={() => setAnimateIndexChange(false)}
        >
          {activeIndex + 1}
        </ActiveSlideIndicator>
      </IndicatorStack>
    </Container>
  );
}
