import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { createModel } from "@rematch/core";

import { RootModel } from "src/models/index";
import { RootState } from "src/models/store";
import apiClient from "src/network/ApiClient";
import * as gql from "src/network/graphql/generatedGraphqlSDK";

const downloadInfoAdapter = createEntityAdapter<gql.DownloadInfo>({ selectId: (model) => model.id });
const downloadInfoAdapterSelectors = downloadInfoAdapter.getSelectors((state: RootState) => state.downloadInfo);

const selectDownloadInfoBySequenceId = createSelector(
  downloadInfoAdapterSelectors.selectEntities,
  (state: RootState, externalSequenceId: string) => externalSequenceId,
  (downloadInfos, externalSequenceId) =>
    Object.values(downloadInfos).find((downloadInfo) => downloadInfo?.externalSequenceId === externalSequenceId),
);

export const downloadInfoSelectors = {
  ...downloadInfoAdapterSelectors,
  selectDownloadInfoBySequenceId,
};

const downloadInfo = createModel<RootModel>()({
  state: downloadInfoAdapter.getInitialState(),

  reducers: {
    setDownloadInfos: (state, payload: gql.DownloadInfoConnection) => downloadInfoAdapter.setMany(state, payload.nodes),
    setDownloadInfo: (state, payload: gql.DownloadInfo) => downloadInfoAdapter.setOne(state, payload),
    upsertOne: (state, payload: gql.DownloadInfo) => downloadInfoAdapter.upsertOne(state, payload),
  },

  effects: (dispatch) => ({
    async fetchDownloadInfos(variables: gql.GetDownloadInfosQueryVariables) {
      const fetchedDownloadInfos = await apiClient.getDownloadInfos(variables);
      dispatch.downloadInfo.setDownloadInfos(fetchedDownloadInfos);
    },

    async fetchDownloadInfo(variables: gql.GetDownloadInfoQueryVariables) {
      const fetchedDownloadInfo = await apiClient.getDownloadInfo(variables);
      dispatch.downloadInfo.setDownloadInfo(fetchedDownloadInfo);
    },
  }),
});

export default downloadInfo;
