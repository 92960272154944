import { RefObject, useEffect } from "react";
import { Swiper as SwiperType } from "swiper";

/** prevent more than one slide per touchpad swipe (one touchpad swipe triggers multiple wheel events and therefore causing the swiper to slide multiple times) */
export const useTouchpadSwipeRestriction = (swiperRef: RefObject<SwiperType>, maxSlidesPerSwipe: number) => {
  useEffect(() => {
    let prevWheelEvent: WheelEvent | undefined;
    let recentSwipeSlideCount = 0;
    let isWheeling = false;
    let isWheelingResettingTimeout = -1;

    const onActiveIndexChange = () => {
      if (isWheeling) {
        recentSwipeSlideCount += 1;
      }

      if (recentSwipeSlideCount === maxSlidesPerSwipe) {
        /** disable the swiper until recent swipe's wheel events end, or a new swipe has started */
        swiperRef.current?.mousewheel?.disable();
        recentSwipeSlideCount = 0;
      }
    };

    const onWheel = (currWheelEvent: WheelEvent) => {
      const isPrevSwipeInterruptedByNewOne = !currWheelEvent.cancelable && prevWheelEvent?.cancelable;

      /** re-enable the swiper if a new swipe has started before the previous swipe had finished */
      if (isPrevSwipeInterruptedByNewOne) {
        swiperRef.current?.mousewheel?.enable();
      }

      prevWheelEvent = currWheelEvent;

      /** keep track of the wheel status and re-enable the swiper if recent swipe's wheel events have ended */
      isWheeling = true;
      window.clearTimeout(isWheelingResettingTimeout);
      isWheelingResettingTimeout = window.setTimeout(() => {
        isWheeling = false;
        swiperRef.current?.mousewheel?.enable();
      }, 100);
    };

    swiperRef.current?.on("activeIndexChange", onActiveIndexChange);
    window.addEventListener("wheel", onWheel, { capture: true, passive: false });

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      swiperRef.current?.off("activeIndexChange", onActiveIndexChange);
      window.removeEventListener("wheel", onWheel, { capture: true });
      window.clearTimeout(isWheelingResettingTimeout);
    };
  }, [maxSlidesPerSwipe, swiperRef]);
};
