import { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import FootageSequences from "src/components/features/SmartLibrary/oneFootage/FootageSequences";
import { onSequenceDuplicate, onSequenceTranslate } from "src/components/features/SmartLibrary/smartLibrary.utils";
import { AspectRatio } from "src/constants/video.constants";

import { footageSelectors } from "src/models/Footage.model";
import { Dispatch, RootState } from "src/models/store";
import { Clip, ClipSequence } from "src/network/graphql/generatedGraphqlSDK";

import userEngagementService from "src/services/UserEngagement.service";
import { TriggerOrigin } from "src/analytics/sequence.analytics";

const SEQUENCE_ACTION = {
  DUPLICATE: "duplicate",
  TRANSLATE: "translate",
};

export default function FootageSequencesPage() {
  const dispatch = useDispatch<Dispatch>();
  const pathParams = useParams<{ footageId: string }>();
  const footageId = pathParams.footageId!;
  const footage = useSelector(
    (state: RootState) => pathParams?.footageId && footageSelectors.selectById(state, pathParams.footageId!),
  );
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const sequenceAction: string | null = searchParams.get("action");
  const sequenceId: string | null = searchParams.get("sequence");
  const sequenceLangCode: string | null = searchParams.get("languageCode");
  const sequenceRatio: string | null = searchParams.get("ratio");
  const trigger: string | null = searchParams.get("trigger");
  const clipAndSequence: { clip: Clip | null; sequence: ClipSequence | null } | null = useMemo(() => {
    if (!(footage && sequenceId && sequenceAction)) {
      return null;
    }
    let currentClip: Clip | null = null;
    let currentSequence: ClipSequence | null = null;
    footage.clips?.forEach((clItem) => {
      const seq = clItem?.sequences?.find((seqItem) => seqItem.externalSequenceId === sequenceId);
      if (seq) {
        currentClip = clItem;
        currentSequence = seq;
      }
    });
    return {
      clip: currentClip,
      sequence: currentSequence,
    };
  }, [footage, sequenceAction, sequenceId]);

  useEffect(() => {
    const startDuplicating = async () => {
      if ((clipAndSequence?.clip && clipAndSequence?.sequence && sequenceRatio, footage)) {
        await onSequenceDuplicate(
          dispatch,
          footageId,
          clipAndSequence!.clip!,
          clipAndSequence!.sequence!.id,
          sequenceRatio as AspectRatio,
          clipAndSequence!.sequence!.ratio as AspectRatio,
          trigger as TriggerOrigin,
          footage.origin!,
        );

        navigate(location.pathname);
      }
    };

    const startTranslating = async () => {
      if (
        (clipAndSequence?.clip && clipAndSequence?.sequence && clipAndSequence.sequence && sequenceLangCode, footage)
      ) {
        await onSequenceTranslate(
          dispatch,
          footageId,
          clipAndSequence!.clip!,
          clipAndSequence!.sequence!.id!,
          sequenceLangCode!,
          clipAndSequence!.sequence!.languageCode!,
          trigger as TriggerOrigin,
          footage.origin!,
        );

        navigate(location.pathname);
      }
    };

    if (sequenceId) {
      if (sequenceAction === SEQUENCE_ACTION.DUPLICATE && sequenceRatio) {
        startDuplicating();
      }
      if (sequenceAction === SEQUENCE_ACTION.TRANSLATE && sequenceLangCode) {
        startTranslating();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clipAndSequence?.clip,
    clipAndSequence?.sequence,
    dispatch,
    footageId,
    location.pathname,
    navigate,
    sequenceAction,
    sequenceId,
    sequenceLangCode,
    sequenceRatio,
  ]);

  useEffect(() => {
    if (!footage && footageId) {
      dispatch.footage.fetchFootage(footageId);
    }
  }, [dispatch, footage, footageId]);

  useEffect(() => {
    userEngagementService.setLauncherPadding({ verticalPadding: 50 });

    return () => {
      userEngagementService.setLauncherPadding({ verticalPadding: 0 });
    };
  }, []);

  if (!footage) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{footage?.filename} | Peech - Automated Talk-Videos Creator</title>
      </Helmet>
      <FootageSequences footageId={footageId!} />
    </>
  );
}
