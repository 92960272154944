import React, { useMemo, useRef } from "react";
import styled from "styled-components/macro";

import { LINE_HEIGHT } from "src/constants/video-trimmer.constants";

import Icon from "src/components/common/Icon";
import Popper from "src/components/common/popovers/Popper";
import { Stack } from "src/components/common/layout/Stack.styled";
import Input from "src/components/common/form/inputs/Input.styled";
import { Text3 } from "src/components/common/layout/typography.styled";
import { themeColor } from "src/utils/styledComponents.utils";
import DropdownButton from "src/components/common/buttons/DropdownButton";
import { useVideoTranscript } from "src/components/features/VideoTrimmer/providers/VideoTranscriptProvider/VideoTranscriptContext";

const Container = styled.div`
  position: relative;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: white;
  padding: 7px;
  border-radius: 5px;
  &>(Input) {
    font-size: 2px;
  }
`;

export type Props = {
  onCorrect: (string: string) => void;
  onCorrectAll: (string: string) => void;
  recentInteractedSelectionEdge?: "start" | "end";
  isOpen: boolean;
  toggleIsOpen: (isOpen: boolean) => void;
  defaultValue: string;
  children?: React.ReactNode;
};

const CloseButton = styled.div`
  position: relative;
  top: 0;
  right: 6px;
  width: 14px;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: ${themeColor("gray.500")};
    }
  }
`;

export default function TranscriptCorrectionPopover({
  recentInteractedSelectionEdge,
  isOpen,
  toggleIsOpen: setIsOpen,
  onCorrect,
  onCorrectAll,
  defaultValue,
  children,
}: Props) {
  const { onSearchQueryChange } = useVideoTranscript();

  const correctionInputRef = useRef<HTMLInputElement>(null);
  const placement = useMemo(() => {
    if (!recentInteractedSelectionEdge) return "bottom-start";
    return recentInteractedSelectionEdge === "end" ? "bottom" : "top";
  }, [recentInteractedSelectionEdge]);

  const offsetY = useMemo(() => {
    if (!recentInteractedSelectionEdge) return 10;
    return recentInteractedSelectionEdge === "end" ? 5 : LINE_HEIGHT + 5;
  }, [recentInteractedSelectionEdge]);

  return (
    <Popper
      isOpen={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      useClickOutside
      placement={placement}
      offsetY={offsetY}
      content={
        <Container>
          <Stack
            style={{ marginBottom: "5px", width: "100%", justifyContent: "space-between", alignItems: "center" }}
            direction="row"
            spacing={2}
          >
            <Stack direction="row" spacing={2} style={{ alignItems: "center" }}>
              <Icon.Carret size={14} color="black" />
              <Text3>Correct transcription</Text3>
            </Stack>

            <CloseButton onClick={() => setIsOpen(false)}>
              <Icon.Close />
            </CloseButton>
          </Stack>

          <Stack direction="row" spacing={10}>
            <Input
              dir="auto"
              onMouseDown={(e) => e.stopPropagation()}
              onKeyDown={(e) => {
                e.stopPropagation();
                if (e.key === "Enter") {
                  onCorrect(correctionInputRef?.current?.value || "");
                }
              }}
              ref={correctionInputRef}
              defaultValue={defaultValue}
              inputSize="small"
              style={{ minWidth: "300px", fontSize: "12px", marginRight: "5px", borderRadius: "6px" }}
            />
            <DropdownButton
              label="Correct subtitles"
              options={["Correct all"]}
              onClick={() => {
                onCorrect(correctionInputRef?.current?.value || "");
              }}
              onOptionClick={() => {
                onCorrectAll(correctionInputRef?.current?.value || "");
              }}
              onOptionMouseOver={() => onSearchQueryChange(defaultValue, -1)}
              onOptionMouseOut={() => onSearchQueryChange("", -1)}
            />
          </Stack>
        </Container>
      }
      {...{ children }}
    />
  );
}
