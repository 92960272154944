import Icon from "src/components/common/Icon";
import { Text1Bolder } from "src/components/common/layout/typography.styled";
import { themeColor } from "src/utils/styledComponents.utils";
import styled from "styled-components/macro";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 96vw;
  height: 90vh;
  background-color: inherit;
  border-radius: 10px;
  padding: 30px 0 0 0;
`;

const ContentContainer = styled.div`
  max-width: 300px;
  text-align: center;
`;

const StyledVideoIcon = styled(Icon.Video)`
  .color-1 path {
    fill: ${themeColor("blue.1100")};
  }

  .color-2 mask {
    fill: ${themeColor("gray.300")};
  }

  .color-3 path {
    fill: ${themeColor("pink.500")};
  }
`;

export default function NotSupportedModal() {
  return (
    <Container>
      <ContentContainer>
        <StyledVideoIcon />
        <Text1Bolder marginTop={40}>This page is not yet supported on mobile</Text1Bolder>
      </ContentContainer>
    </Container>
  );
}
