import styled, { css } from "styled-components/macro";

import { ifProp, themeColor, themeSpacing } from "src/utils/styledComponents.utils";

import { Box } from "src/components/common/layout/Box.styled";
import { Text1Bold } from "src/components/common/layout/typography.styled";

export const Field = styled(Box)`
  position: relative;
`;

interface FieldLabelProps {
  isMandatory?: boolean;
}

export const FieldLabel = styled(Text1Bold)<FieldLabelProps>`
  display: inline-block;
  margin-bottom: ${themeSpacing(4)};

  ${ifProp(
    "isMandatory",
    css<FieldLabelProps>`
      &:after {
        display: inline-block;
        content: "*";
        color: ${themeColor("status.error")};
        margin-left: 0.5ch;
      }
    `,
  )}
`;
