import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import "rc-slider/assets/index.css";
import NumberInput from "src/components/common/form/inputs/NumberInput";
import { StyledSlider } from "src/components/common/form/inputs/Slider";

interface SliderWithInputProps {
  value: any;
  onValueChange: (value: number) => void;
  onChangeComplete?: any;
  disabled?: boolean;
  minLabel?: string;
  maxLabel?: string;
  min?: number;
  max?: number;
  step?: number;
  reverse?: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SliderInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const RangeLabels = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  width: 100%;
  margin-top: 5px;
  span {
    font-family: "Open Sans", serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }
`;

export const ScrollerWrapper = styled.div<{ marginTop?: string | undefined }>`
  display: flex;
  flex-direction: column;
  align-content: stretch;
  align-items: flex-start;
  justify-content: center;
  overflow: visible;
  flex: 1;
  margin-right: 12px;
  margin-top: ${(props) => props.marginTop || "30px"};
`;

export default function SliderWithInput({
  value,
  onValueChange,
  onChangeComplete = () => {},
  disabled = false,
  minLabel,
  maxLabel,
  min = 0,
  max = 100,
  step = 1,
  reverse = false,
}: SliderWithInputProps) {
  const [displayValue, setDisplayValue] = useState(reverse ? String(max - value) : String(value));
  const timerRef: any = useRef<number | null>(null);

  const sanitizeValue = useCallback(
    (dirtyValue: string) => {
      // this returns closest value within range.
      let val = Number(dirtyValue);
      val = Math.min(max, Math.max(min, val));
      return String(val);
    },
    [max, min],
  );

  useEffect(() => {
    let newValue = reverse ? max - value : value;
    newValue = sanitizeValue(String(newValue));
    setDisplayValue(newValue);
  }, [max, min, value, reverse, sanitizeValue]);

  const onValueChangeDelay = useCallback(
    (sanitizedValue: number) => {
      // clear the previous timer
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      // set a new timer (debounce the input change).
      // check if the value is valid. if so, update the value.
      // if not, do nothing. onInputBlur will sanitize and update the value.
      timerRef.current = setTimeout(() => {
        onValueChange(sanitizedValue);
      }, 400);
    },
    [onValueChange],
  );

  const onInputChange = useCallback(
    (inputValue: string) => {
      // typing any numeric value allowed, even if it's invalid.
      setDisplayValue(inputValue);
      const delayChanges = false;
      const valueToUpdate = reverse ? max - Number(inputValue) : Number(inputValue);
      if (sanitizeValue(inputValue) === inputValue) {
        delayChanges ? onValueChangeDelay(valueToUpdate) : onValueChange(valueToUpdate);
      }
    },
    [reverse, max, sanitizeValue, onValueChangeDelay, onValueChange],
  );

  const onSliderChange = useCallback(
    (sliderValue: number | number[]) => {
      onInputChange(String(sliderValue));
    },
    [onInputChange],
  );

  const sliderRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.blur();
    }
  }, [sliderRef]);

  return (
    <Wrapper>
      <SliderInputWrapper>
        <ScrollerWrapper marginTop="0px">
          <StyledSlider
            ref={sliderRef}
            value={Number(displayValue)}
            min={min}
            max={max}
            step={step}
            onChange={onSliderChange}
            onChangeComplete={(v) => {
              onChangeComplete(v);
              requestAnimationFrame(() => {
                if (document.activeElement) {
                  (document.activeElement as HTMLElement).blur();
                }
              });
            }}
            disabled={disabled}
          />
          {(minLabel || maxLabel) && (
            <RangeLabels>
              {minLabel && <span>{minLabel}</span>}
              {maxLabel && <span>{maxLabel}</span>}
            </RangeLabels>
          )}
        </ScrollerWrapper>
        <NumberInput
          value={Number(displayValue)}
          min={min}
          max={max}
          step={step}
          onValueChange={onSliderChange}
          onBlur={(inputValue) => onChangeComplete(inputValue)}
          disabled={disabled}
        />
      </SliderInputWrapper>
    </Wrapper>
  );
}
