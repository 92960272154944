import {
  BlankLineItem,
  FillerWordLineItem,
  HighlightedWordLineItem,
  TimeInterval,
  Word,
} from "src/types/video-trimmer.types";
import { createContext, useContext } from "react";
import { UseHistoryStateReturnValue } from "src/hooks/useHistoryState";

export interface WordsAllowedActions {
  highlight: { isAllowed: boolean; message?: string };
  correct: { isAllowed: boolean; message?: string };
}

export interface VideoWordsContextValue {
  highlightWords(timeIntervals: TimeInterval[]): void;
  unHighlightWords(timeIntervals: TimeInterval[]): void;
  unHighlightAllWords(): void;
  replaceSelectedWords(wordsString: string): void;
  replaceSearchResultsWords(wordsString: string): void;
  words: Word[];
  wordsHistory: Pick<
    UseHistoryStateReturnValue<Word[]>,
    "undo" | "redo" | "canUndo" | "canRedo" | "resetFuture" | "onSet"
  >;
  blanks: BlankLineItem[];
  highlightedWords: HighlightedWordLineItem[];
  fillerWords: FillerWordLineItem[];
  maxWordsToCorrectAtOnce: number;
  selectedWords: Word[];
  selectedWordsTimeInterval: TimeInterval | null;
  allowedActions: WordsAllowedActions;
}

const VideoWordsContext = createContext<VideoWordsContextValue | null>(null);

export default VideoWordsContext;

export const useVideoWords = () => {
  const contextValue = useContext(VideoWordsContext);

  if (!contextValue) {
    throw new Error("useVideoWords must be used within VideoWordsProvider");
  }

  return contextValue;
};
