import { AnimationItem } from "lottie-web";
import { useCallback, useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components/macro";

import { getImageToAnimation } from "src/utils/lottie.utils";
import { ifNotProp, themeColor, themeZ } from "src/utils/styledComponents.utils";

import apiClient from "src/network/ApiClient";

import { getAspectRatioValue } from "src/constants/video.constants";

import ExtendedLottie, { ExtendedLottieProps } from "src/components/common/media/ExtendedLottie";
import { useSelector } from "react-redux";
import { RootState } from "src/models/store";

const pump = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
`;

const StyledExtendedLottie = styled(ExtendedLottie)<{ isLoaded: boolean }>`
  width: 100%;
  aspect-ratio: ${getAspectRatioValue("16:9")};
  background-color: ${themeColor("gray.300")};
  z-index: ${themeZ("visualPackageLottiePreview")};

  ${ifNotProp(
    "isLoaded",
    css`
      animation: ${pump} 1s infinite ease-in-out;
    `,
  )}
`;

interface AnimationData {
  assets?: Array<{
    id: string;
    w: number;
    h: number;
    [key: string]: string | number;
  }>;

  fonts: {
    list: Array<{
      fFamily: string;
    }>;
  };
}

interface VisualPackageLottiePreviewProps
  extends Omit<ExtendedLottieProps, "width" | "height" | "onLoad" | "animationData"> {
  assetId?: string;
  assetVersion?: number;
  logoURL?: string;
  thumbnailUrl?: string;
}

export default function VisualPackageLottiePreview({
  assetId,
  assetVersion,
  logoURL,
  thumbnailUrl,
  ...props
}: VisualPackageLottiePreviewProps) {
  const [animationData, setAnimationData] = useState<AnimationData>();
  const [isDomLoaded, setIsDomLoaded] = useState(false);
  // TODO: pass logo url with ps to component
  const authToken = useSelector((state: RootState) => state.session.authToken);

  const onLottieLoad = useCallback((animation: AnimationItem) => {
    animation.addEventListener("DOMLoaded", () => setIsDomLoaded(true));
  }, []);

  useEffect(() => {
    if (!assetId || assetVersion === undefined) return;

    apiClient
      .getAssetContent(assetId, assetVersion)
      .then(async (res) => {
        const data = JSON.parse(res.data) as AnimationData;

        data.fonts.list.forEach((f) => {
          f.fFamily = document.body.style.fontFamily;
        });

        const lottieAssetLogo = data.assets?.find((ass) => ass.id === "logo");

        if (lottieAssetLogo && logoURL) {
          try {
            lottieAssetLogo.p = await getImageToAnimation(
              `${logoURL}?ps=${authToken}`,
              lottieAssetLogo.w,
              lottieAssetLogo.h,
            );
            lottieAssetLogo.u = "";
          } catch (error) {
            // eslint-disable-next-line
            console.log("failed to load logo in package preview", error);
          }
        }

        if (thumbnailUrl) {
          const bgAssetLogo = data.assets?.find((a: any) => a.id === "bg-photo");

          if (bgAssetLogo) {
            bgAssetLogo.p = thumbnailUrl;
            bgAssetLogo.u = "";
          }
        }

        setAnimationData(data);
      })
      // eslint-disable-next-line
      .catch(console.log);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId, assetVersion]);

  return (
    <StyledExtendedLottie
      animationData={animationData}
      onLoad={onLottieLoad}
      isLoaded={isDomLoaded}
      width="100%"
      height="100%"
      {...props}
    />
  );
}
