import styled from "styled-components/macro";
import { themeColor } from "src/utils/styledComponents.utils";

export const Panel = styled.div`
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  height: auto;
  justify-content: center;
  color: ${themeColor("gray.500")};
  background: ${themeColor("white")};
  padding-inline: 8px;
  white-space: pre;
  display: inline-flex;
  padding: 7px;
  align-items: flex-start;
  gap: 5px;
  border-radius: 6px;
  box-shadow: 0px 4px 16px 0px rgba(4, 27, 63, 0.2);
`;
