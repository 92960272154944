import { PropsWithChildren, useCallback, useMemo } from "react";
import { FillerWordLineItem, HighlightedWordLineItem, TimeInterval, Word } from "src/types/video-trimmer.types";
import useShallowMemo from "src/hooks/useShallowMemo";

import { UseHistoryStateReturnValue } from "src/hooks/useHistoryState";

import { binarySearch, getBlanks, replaceIntervalWords } from "src/utils/words.utils";
import { useAppConfig } from "src/components/providers/AppConfigProvider";
import { cloneDeep } from "lodash";
import { useVideoTranscript } from "src/components/features/VideoTrimmer/providers/VideoTranscriptProvider/VideoTranscriptContext";
import { timeIntervalUtils } from "src/utils/timeInterval.utils";
import { sortBy } from "lodash/fp";

import VideoWordsContext, { WordsAllowedActions, VideoWordsContextValue } from "./VideoWordsContext";

const MAX_WORDS_TO_CORRECT_AT_ONCE = 15;

interface TimelineWordsProviderProps extends PropsWithChildren {
  wordsHistory: UseHistoryStateReturnValue<Word[]>;
  videoDuration: number;
  languageCode: string | undefined;
  allowedActions: WordsAllowedActions;
  analyticsActions: any;
}
export default function VideoWordsProvider({
  children,
  wordsHistory,
  videoDuration,
  languageCode = "en/US",
  allowedActions,
  analyticsActions,
}: TimelineWordsProviderProps) {
  const { state: words, set: setWords } = wordsHistory;
  const { selectionTimeInterval, searchResults } = useVideoTranscript();
  const { MAX_BLANKS: maxBlanks, FILLER_WORDS } = useAppConfig();
  const maxWordsToCorrectAtOnce = MAX_WORDS_TO_CORRECT_AT_ONCE;
  const blanks = useMemo(() => getBlanks(words, videoDuration, maxBlanks), [maxBlanks, videoDuration, words]);
  const configFillerWords = useMemo(() => FILLER_WORDS[languageCode], [FILLER_WORDS, languageCode]);
  const selectedWords = useMemo(() => {
    if (!selectionTimeInterval) return [];
    const blanksSelected = blanks.filter((blank) =>
      timeIntervalUtils.containsTimeInterval(selectionTimeInterval, blank),
    );
    const wordsSelected = words.filter((word) => timeIntervalUtils.containsTimeInterval(selectionTimeInterval, word));

    return sortBy("start", [...blanksSelected, ...wordsSelected]) as Word[];
  }, [selectionTimeInterval, blanks, words]);

  const selectedWordsTimeInterval = useMemo(() => {
    if (!selectedWords.length) return null;
    return { start: selectedWords[0].start, end: selectedWords[selectedWords.length - 1].end } as TimeInterval;
  }, [selectedWords]);

  const fillerWords = useMemo(
    () => words.filter((word): word is FillerWordLineItem => word.kind === "fillerWord"),
    [words],
  );

  const highlightedWords = useMemo(
    () => words.filter((word): word is HighlightedWordLineItem => word.kind === "highlightedWord"),
    [words],
  );

  const replaceSelectedWords = useCallback(
    (wordsString: string) => {
      if (!selectedWordsTimeInterval) return;
      const newAllWords = cloneDeep(words);
      const replaceIntervalWordsData = replaceIntervalWords(
        newAllWords,
        wordsString,
        selectedWordsTimeInterval,
        configFillerWords,
      );
      const { modifiedWords } = replaceIntervalWordsData;

      analyticsActions?.trackWordsCorrect?.("content-correct", {
        ...replaceIntervalWordsData,
        selectedBlanksAmount: blanks.filter((blank) =>
          timeIntervalUtils.containsTimeInterval(selectedWordsTimeInterval, blank),
        ).length,
      });
      setWords(modifiedWords);
    },
    [blanks, configFillerWords, selectedWordsTimeInterval, setWords, words, analyticsActions],
  );

  const replaceSearchResultsWords = useCallback(
    (wordsString: string) => {
      if (!searchResults?.length) return;
      let newAllWords = cloneDeep(words);
      searchResults.forEach((selectedInterval, index) => {
        const replaceIntervalWordsData = replaceIntervalWords(
          newAllWords,
          wordsString,
          selectedInterval,
          configFillerWords,
        );
        const { modifiedWords } = replaceIntervalWordsData;
        if (index === 0) {
          analyticsActions?.trackWordsCorrect?.("content-correct-all", {
            ...replaceIntervalWordsData,
            selectedBlanksAmount: blanks.filter((blank) =>
              timeIntervalUtils.containsTimeInterval(searchResults[0], blank),
            ).length,
          });
        }
        newAllWords = modifiedWords;
      });
      setWords(newAllWords);
    },
    [searchResults, words, setWords, configFillerWords, blanks, analyticsActions],
  );

  const highlightWords = useCallback(
    (timeIntervals: TimeInterval[]) => {
      const newWords = words.map((word) => ({
        ...word,
        highlight: binarySearch(word, timeIntervals) ? true : word.highlight,
        kind: binarySearch(word, timeIntervals) ? "highlightedWord" : word.kind,
      }));
      setWords(newWords);
    },
    [setWords, words],
  );

  const unHighlightWords = useCallback(
    (timeIntervals: TimeInterval[]) => {
      const newWords = words.map((word) => ({
        ...word,
        highlight: binarySearch(word, timeIntervals) ? false : word.highlight,
        kind: binarySearch(word, timeIntervals) ? "word" : word.kind,
      }));
      setWords(newWords);
    },
    [setWords, words],
  );

  const unHighlightAllWords = useCallback(() => {
    const newWords = words.map((word) => {
      if (!word.highlight) return word;
      return {
        ...word,
        highlight: false,
        kind: word.kind === "highlightedWord" ? "word" : word.kind,
      };
    });
    setWords(newWords);
  }, [setWords, words]);

  const contextValue = useShallowMemo<VideoWordsContextValue>({
    wordsHistory,
    words,
    blanks,
    fillerWords,
    highlightedWords,
    replaceSelectedWords,
    replaceSearchResultsWords,
    highlightWords,
    unHighlightWords,
    unHighlightAllWords,
    maxWordsToCorrectAtOnce,
    selectedWords,
    selectedWordsTimeInterval,
    allowedActions,
  });

  return <VideoWordsContext.Provider value={contextValue}>{children}</VideoWordsContext.Provider>;
}
