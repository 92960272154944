import altis from "src/assets/images/companiesLogos/altis.png";
import altisBig from "src/assets/images/companiesLogos/altisBig.png";
import daversa from "src/assets/images/companiesLogos/daversa.png";
import daversaBig from "src/assets/images/companiesLogos/daversaBig.png";
import faye from "src/assets/images/companiesLogos/faye.png";
import fayeBig from "src/assets/images/companiesLogos/fayeBig.png";
import hyro from "src/assets/images/companiesLogos/hyro.png";
import hyroBig from "src/assets/images/companiesLogos/hyroBig.png";
import linkfire from "src/assets/images/companiesLogos/linkfire.png";
import linkfireBig from "src/assets/images/companiesLogos/linkfireBig.png";
import milenaFarber from "src/assets/images/companiesLogos/milenaFarber.png";
import mywork from "src/assets/images/companiesLogos/mywork.png";
import myworkBig from "src/assets/images/companiesLogos/myworkBig.png";
import namogoo from "src/assets/images/companiesLogos/namogoo.png";
import namogooBig from "src/assets/images/companiesLogos/namogooBig.png";
import peerspot from "src/assets/images/companiesLogos/peerspot.png";
import peerspotBig from "src/assets/images/companiesLogos/peerspotBig.png";
import ucd from "src/assets/images/companiesLogos/ucd.png";
import ucdBig from "src/assets/images/companiesLogos/ucdBig.png";
import cmi from "src/assets/images/companiesLogos/CMI.png";
import cmiBig from "src/assets/images/companiesLogos/cmiBig.png";

export const SPLASH_SCREEN_TESTIMONIALS: Array<{
  company: string;
  title: string;
  name: string;
  text: string;
  fileData: string;
}> = [
  {
    company: "Namogoo",
    title: "Social Media Marketing Manager",
    name: "Milena Farber",
    text: "With Peech, you really don't need to have years of experience in editing videos and graphic design or anything like that. You can get quality videos quickly.",
    fileData: milenaFarber,
  },
];

// TODO: remove small logos and rename all big
export const COMPANIES_LOGOS: Array<{
  logoName: string;
  logoImage: string;
  logoImageBig: string;
}> = [
  {
    logoName: "Altis",
    logoImage: altis,
    logoImageBig: altisBig,
  },
  {
    logoName: "Daversa partners",
    logoImage: daversa,
    logoImageBig: daversaBig,
  },
  {
    logoName: "Faye",
    logoImage: faye,
    logoImageBig: fayeBig,
  },
  {
    logoName: "MyWorkChoice",
    logoImage: mywork,
    logoImageBig: myworkBig,
  },
  {
    logoName: "University College Dublin",
    logoImage: ucd,
    logoImageBig: ucdBig,
  },
  {
    logoName: "Hyro",
    logoImage: hyro,
    logoImageBig: hyroBig,
  },
  {
    logoName: "LinkFire",
    logoImage: linkfire,
    logoImageBig: linkfireBig,
  },
  {
    logoName: "Namogoo",
    logoImage: namogoo,
    logoImageBig: namogooBig,
  },
  {
    logoName: "PeerSpot",
    logoImage: peerspot,
    logoImageBig: peerspotBig,
  },
  {
    logoName: "Content marketing institute",
    logoImage: cmi,
    logoImageBig: cmiBig,
  },
];
