import { useParams } from "react-router-dom";

import useSecuredSearchParam from "src/hooks/useSecuredSearchParam";

import ResetPasswordCompletion from "src/components/auth/ResetPasswordCompletion";

export default function ResetPasswordCompletionPage() {
  const { token } = useParams<{ token: string }>();
  const email = atob(useSecuredSearchParam("email"));

  return <ResetPasswordCompletion token={token} email={email} />;
}
