import analyticsService from "src/services/Analytics.service";

import steps from "src/components/features/Onboarding/steps";
import { OnboardingForm } from "src/components/features/Onboarding/onboardingForm";

export const trackSuccess = () => analyticsService.track("Onboarding_Success");

export const trackStepChange = (activeIndex: number, previousIndex: number, formValues: OnboardingForm) => {
  const previousStep = steps[previousIndex].path;
  const currentStep = steps[activeIndex].path;
  const fields = activeIndex === steps.length - 1 ? formValues : formValues[currentStep];

  return analyticsService.track("OnboardingStep", { previousStep, currentStep, fields });
};
