import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useFormContext } from "react-hook-form";
import * as yup from "yup";

import { StepPath } from "src/components/features/CreateSequenceWizard/steps/steps";
import { AspectRatio } from "src/constants/video.constants";

const sequenceFormSchema = yup
  .object({
    footage: yup
      .object({
        footageSidArray: yup.array(yup.string().required()).required().min(1),
      })
      .required(),

    size: yup
      .object({
        selectedAspectRatio: yup.mixed<AspectRatio>().oneOf(["16:9", "1:1", "9:16"]).required(),
        selectedByUser: yup.boolean().optional().nullable(),
      })
      .required(),

    visuals: yup.object({
      defaultVideoType: yup.string().required(),
      editRulesSid: yup.string().required(),
      editRules: yup
        .object({
          useIntro: yup.boolean().optional().nullable(),
          useOutro: yup.boolean().optional().nullable(),
          useSpeakers: yup.boolean().optional().nullable(),
          useTexts: yup.boolean().optional().nullable(),
          useSlides: yup.boolean().optional().nullable(),
          useMusic: yup.boolean().optional().nullable(),
          useHighlight: yup.boolean().optional().nullable(),
          useFrame: yup.boolean().optional().nullable(),
        })
        .required(),
    }),

    content: yup
      .object({
        sequenceSid: yup.string().required(),
        chapters: yup
          .array(
            yup
              .object({
                id: yup.string().required(),
                start: yup.number().required(),
                end: yup.number().required(),
                domain: yup
                  .object({
                    start: yup.number().required(),
                    end: yup.number().required(),
                  })
                  .required(),
                isSource: yup.boolean().required(),
                isMuted: yup.boolean().required(),
              })
              .required(),
          )
          .required(),
      })
      .required(),
  } satisfies Record<StepPath, yup.ObjectSchema<{}>>)
  .required();

type PartialSteps<Form extends object> = {
  [Step in keyof Form]: {
    [Field in keyof Form[Step]]+?: Form[Step][Field];
  };
};

export type SequenceForm = PartialSteps<yup.InferType<typeof sequenceFormSchema>>;

export const useSequenceForm = () =>
  useForm<SequenceForm>({
    mode: "onChange",
    resolver: yupResolver(sequenceFormSchema),
    defaultValues: {},
  });

export const useSequenceFormContext = useFormContext<SequenceForm>;
