/* eslint-disable no-underscore-dangle */
import { Dictionary } from "@reduxjs/toolkit";
import { RematchDispatch } from "@rematch/core";

import { RootModel } from "src/models";
import { DownloadInfo } from "src/network/graphql/generatedGraphqlSDK";

export type DownloadInfoUpdateEventDataType = {
  entity: DownloadInfo;
  deleted?: boolean;
};

export const onDownloadInfoUpdateEvent = (
  dispatch: RematchDispatch<RootModel>,
  event: any,
  downloadInfos: Dictionary<DownloadInfo>,
) => {
  const info: DownloadInfo = { ...event.data.entity, id: event.data.entity._id };

  const existingItem = downloadInfos[info?.id];
  if (existingItem) {
    dispatch.downloadInfo.setDownloadInfo(info);
  } else {
    dispatch.downloadInfo.upsertOne(info);
  }

  dispatch.session.setHasNewNotifications(!existingItem);
};

export const getDownloadLink = (
  contentUrl: string,
  sequenceSid: string,
  sequenceEndVersion: number,
  authToken: string,
) =>
  `${contentUrl}/v2/downloads/get-download-url/${sequenceSid}/${sequenceEndVersion}?authorization=Bearer ${authToken}`;
