import { useAppConfig } from "src/components/providers/AppConfigProvider";
import styled from "styled-components/macro";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
`;

const TextWrapper = styled.div`
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  width: 150px;
  height: auto;
  text-align: center;
`;

interface FootageExpiredExplanationProps {
  durationUploadLimit: number;
}

export default function FootageDurationUploadLimitExplanation({ durationUploadLimit }: FootageExpiredExplanationProps) {
  const { FOOTAGE_UPLOAD_DURATION } = useAppConfig();

  return (
    <Container>
      <TextWrapper>{FOOTAGE_UPLOAD_DURATION.text.replace("{{minutes}}", durationUploadLimit.toString())}</TextWrapper>
    </Container>
  );
}
