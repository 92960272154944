import { useEffect, useMemo } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { absoluteRoutes, applyPathParams } from "src/utils/routes.utils";

export default function SharedSequenceGate() {
  const navigate = useNavigate();
  const sequenceSid = useMemo(() => window.localStorage.getItem("sequenceSid"), []);

  useEffect(() => {
    if (sequenceSid) {
      window.localStorage.removeItem("sequenceSid");
      navigate(applyPathParams(absoluteRoutes.projectSequence.children.visualsEditor, { sequenceSid }));
    }
  }, [sequenceSid, navigate]);

  if (sequenceSid) {
    return null;
  }

  return <Outlet />;
}
