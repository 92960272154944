import styled from "styled-components/macro";

import { ifProp, themeColor } from "src/utils/styledComponents.utils";

import { UpgradeModelFeature } from "src/components/providers/AppConfigProvider";
import Icon from "src/components/common/Icon";

const ComponentWrapper = styled.div<{ isSelected: boolean }>`
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 20px 56px;
  background-color: ${ifProp("isSelected", themeColor("gray.200"), themeColor("white"))};
  border-radius: 10px;
  border: solid 1px ${themeColor("gray.200")};
  margin-block: 12px;
  cursor: pointer;
  transition: background-color 350ms ease-out;

  font-family: "Work Sans", sans-serif;
  font-weight: ${ifProp("isSelected", 600, 400)};
  font-size: 18px;
  line-height: 24px;
  &:hover {
    background-color: ${themeColor("gray.200")};
  }

  svg {
    color: ${themeColor("gray.400")};
    width: 10px;
    display: block;
    transition: visibility 350ms ease-out, opacity 250ms ease-out;
    margin-left: 42px;
  }
`;

const TextBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: fit-content;
  max-width: calc(100% - 55px);
  span {
    font-size: 14px;
    font-weight: 300;
  }
`;

interface FeatureItemComponentProps {
  item: UpgradeModelFeature;
  onFeatureItemClick: (item: UpgradeModelFeature) => void;
  isSelected: boolean;
}

export default function FeatureItemComponent({ item, onFeatureItemClick, isSelected }: FeatureItemComponentProps) {
  if (!item) {
    return null;
  }
  return (
    <ComponentWrapper onClick={() => onFeatureItemClick(item)} isSelected={isSelected}>
      <TextBlock>{item.title}</TextBlock>

      <Icon.ArrowRight />
    </ComponentWrapper>
  );
}
