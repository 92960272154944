/* eslint-disable no-console */

import scriptjs from "scriptjs";

import { NonUserBaseService } from "src/services/Base.service";

import { APP_ENV } from "src/constants/env.constants";

import { AppConfig } from "src/components/providers/AppConfigProvider";

type PartialType = Partial<{
  product: number;
  passthrough: string;
  successCallback(): void;
  closeCallback(): void;
  override: string;
}>;

interface Paddle {
  Environment: { set(arg0: string): void };
  Setup(arg0: { vendor: string }): void;
  Checkout: {
    open(params: PartialType): void;
  };
}

declare global {
  interface Window {
    Paddle: Paddle;
  }
}

export function assertNever(value: never): never {
  return value;
}

class PaddleService implements NonUserBaseService {
  async init(appConfig: AppConfig) {
    if (window.Paddle) return;

    await new Promise<void>((resolve) => {
      scriptjs("https://cdn.paddle.com/paddle/paddle.js", resolve);
    });

    assertNever(window.Paddle);
    window.Paddle.Environment.set(appConfig.PADDLE_ENV);
    window.Paddle.Setup({ vendor: appConfig.PADDLE_VENDOR_ID });
  }

  openCheckout({
    product,
    userSid,
    planSid,
    chargeType,
    successCallback,
    closeCallback,
  }: {
    userSid: string;
    planSid: string;
    chargeType: string;
  } & Pick<PartialType, "closeCallback" | "successCallback" | "product">) {
    if (!window.Paddle) return;
    const referral = window.Rewardful && window.Rewardful.referral;
    window.Paddle.Checkout.open({
      product,
      passthrough: JSON.stringify({ userSid, planSid, chargeType, env: APP_ENV, rewardful: { referral } }),
      successCallback,
      closeCallback,
    });
  }

  updatePaymentMethod(updateUrl: string) {
    if (!window.Paddle) {
      console.error("Paddle not found");
      return;
    }
    window.Paddle.Checkout.open({
      override: updateUrl,
    });
  }
}

const paddleService = new PaddleService();

export default paddleService;
