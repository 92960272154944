/* eslint-disable react/no-array-index-key */

import { styleProp, themeColor } from "src/utils/styledComponents.utils";

import styled from "styled-components/macro";

import { FormContextInterface } from "src/components/features/AutomaticCreateWizard/FormContext.interface";
import { Color } from "src/network/graphql/generatedGraphqlSDK";

const ColorsContainer = styled.div`
  width: 100%;
  min-width: 100%;
  height: fit-content;
  max-height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
`;

const ColorItem = styled.div<{ color: string }>`
  width: 60px;
  height: 45px;
  border: solid 1px;
  border-color: ${themeColor("blue.50")};
  background-color: ${styleProp("color")};
  margin: 3px;
  border-radius: 6px;
`;

export default function BrandButtonColors({ formContext }: FormContextInterface) {
  const { watch } = formContext;
  const logoColors: Color[] = watch("brandKit.colors");

  if (!logoColors) {
    return null;
  }

  return (
    <ColorsContainer>
      {logoColors?.map((colorItem, index) => (
        <ColorItem color={colorItem?.color ?? ""} key={index} />
      ))}
    </ColorsContainer>
  );
}
