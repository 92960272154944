import { useEffect, useRef } from "react";

import useVariableRef from "src/hooks/useVariableRef";

export default function useTextSelectionEvents({
  onSelectionStart,
  onSelectionChange,
  onSelectionEnd,
}: {
  onSelectionStart?(selection: Selection): void;
  onSelectionChange?(selection: Selection): void;
  onSelectionEnd?(): void;
}) {
  const onSelectionStartRef = useVariableRef(onSelectionStart);
  const onSelectionChangeRef = useVariableRef(onSelectionChange);
  const onSelectionEndRef = useVariableRef(onSelectionEnd);

  const isMouseDownRef = useRef(false);
  useEffect(() => {
    const onMouseDown = () => {
      isMouseDownRef.current = true;
    };

    const onMouseUp = () => {
      isMouseDownRef.current = false;
    };

    document.addEventListener("mousedown", onMouseDown);
    document.addEventListener("mouseup", onMouseUp);

    return () => {
      document.removeEventListener("mousedown", onMouseDown);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, []);

  useEffect(() => {
    let isSelectionStarted = false;

    const handleSelectionEnd = () => {
      onSelectionEndRef.current?.();
      isSelectionStarted = false;
    };

    const handleSelectionChange = () => {
      if (!isMouseDownRef.current) {
        return;
      }

      const selection = window.getSelection();

      if (selection?.type === "Range") {
        if (!isSelectionStarted) {
          isSelectionStarted = true;
          document.addEventListener("mouseup", handleSelectionEnd, { once: true });
          onSelectionStartRef.current?.(selection);
        }

        onSelectionChangeRef.current?.(selection);
      }
    };

    document.addEventListener("selectionchange", handleSelectionChange);

    return () => {
      document.removeEventListener("selectionchange", handleSelectionChange);
    };
  }, [onSelectionStartRef, onSelectionChangeRef, onSelectionEndRef]);
}
