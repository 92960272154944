import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { FormProvider } from "react-hook-form";
import { Swiper as SwiperClass } from "swiper/types";
import styled, { css } from "styled-components/macro";
import { findIndex } from "lodash/fp";

import * as onboardingAnalytics from "src/analytics/onboarding.analytics";

import { FootageOrigin } from "src/network/graphql/generatedGraphqlSDK";

import { absoluteRoutes, applyPathParams } from "src/utils/routes.utils";
import { ifProp, themeProp, themeZ } from "src/utils/styledComponents.utils";

import userEngagementService from "src/services/UserEngagement.service";

import { Dispatch, RootState } from "src/models/store";
import { userSelectors } from "src/models/User.model";
import { presetSelectors } from "src/models/Preset.model";

import Select from "src/components/common/form/inputs/Select.styled";
import Swiper from "src/components/common/Swiper/Swiper";
import SwiperSlide, { MountIfActive } from "src/components/common/Swiper/SwiperSlide";
import SwiperActiveSlideIndicator from "src/components/common/Swiper/SwiperActiveSlideIndicator";
import VideoPreview from "src/components/features/Onboarding/VideoPreview/VideoPreview";
import steps, { StepPath } from "src/components/features/Onboarding/steps";
import { useOnboardingForm } from "src/components/features/Onboarding/onboardingForm";
import { OnboardingNextButton } from "src/components/features/Onboarding/OnboardingNextButton";

const Container = styled.div`
  position: relative;
  display: flex;
  flex: 1;

  * {
    font-family: "Open Sans", sans-serif;
  }
`;

const Panel = styled.div`
  display: flex;
  flex: 0 0 auto;
`;

const LeftPanel = styled(Panel)`
  align-items: center;
  width: 100%;
  background-color: #fff;
`;

const RightPanel = styled(Panel)<{ isHidden: boolean }>`
  z-index: ${ifProp("isHidden", 0, themeZ("onboardingRightPanel"))};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  width: 40%;
  background-color: ${themeProp("colors.blue.30")};
  opacity: ${ifProp("isHidden", 0, 1)};
  transition: opacity 250ms ${ifProp("isHidden", 0, 250)}ms ease-out;
`;

const StyledSwiper = styled(Swiper)`
  z-index: ${themeZ("onboardingSwiper")};
  width: 100%;
  height: 100%;
`;

const StyledSwiperSlide = styled(SwiperSlide)<{ $fullScreen: boolean }>`
  width: 60%;

  ${ifProp(
    "$fullScreen",
    css`
      width: 100%;
      padding-left: 0;
    `,
  )};
`;

const NextSlideButtonWrapper = styled.div<{ hide: boolean }>`
  z-index: 3;
  position: absolute;
  right: 40px;
  bottom: 36px;
  transition: scale 150ms ease-out;

  ${ifProp(
    "hide",
    css`
      scale: 0;
    `,
  )};
`;

// TODO: decouple from useParams
export default function Onboarding() {
  const navigate = useNavigate();
  const pathParams = useParams<{ step: StepPath }>();

  const [initialStepCheckDone, setInitialStepCheckDone] = useState(pathParams.step === steps[0].path);

  const onboardingForm = useOnboardingForm();
  const { getValues: getFormValues, setValue: setFormValue, formState, trigger: validateForm } = onboardingForm;

  const dispatch = useDispatch<Dispatch>();
  const currentUser = useSelector(userSelectors.selectCurrentUser);
  const presetCount = useSelector(presetSelectors.selectTotal);
  const isCreatingBrandKit = useSelector((state: RootState) => state.loading.effects.presets.createPreset.loading);
  const youtubeVideos = useSelector((state: RootState) => state.youtubeSearchResults.entities);

  const selectedYoutubeVideoId = onboardingForm.watch("content.youtubeVideoId");
  const selectedYoutubeVideo = selectedYoutubeVideoId ? youtubeVideos[selectedYoutubeVideoId] : undefined;

  const activeStepErrors = pathParams.step ? formState.errors[pathParams.step] : undefined;
  const activeStepHasErrors = !!activeStepErrors && Object.values(activeStepErrors).length > 0;

  const submitBrandkit = useCallback(async () => {
    if (isCreatingBrandKit) {
      return;
    }

    const formSteps = getFormValues();
    const presetId = await dispatch.presets.createPreset({
      name: currentUser?.orgName,
      exampleAsset: formSteps.brand.logo?.isExample,
      assets: [{ assetSid: formSteps.brand.logo?.assetId, isDefault: true }],
      colors: formSteps.brand.colors,
      defaultVisualPackage: formSteps.visuals.visualPackageId,
      headerFontFamily: "Roboto",
      headerRegularVariant: "400",
      headerBoldVariant: "700",
    });

    if (!presetCount) {
      dispatch.presets.setDefaultPreset({ sid: presetId! });
    }

    if (!currentUser?.onboardingFinished) {
      dispatch.users.updateUser({ userSid: currentUser?.sid!, onboardingFinished: true });
    }

    await dispatch.footage.createFootage({
      footage: {
        youtubeId: formSteps.content.youtubeVideoId,
        filename: selectedYoutubeVideo?.snippet.title ?? "title",
        origin: FootageOrigin.UserUpload, // TODO: wait for backend new enum
        languageCode: formSteps.content.languageCode,
      },
    });

    onboardingAnalytics.trackSuccess();

    setTimeout(() => navigate(absoluteRoutes.platform.self), 10000);
  }, [currentUser, dispatch, getFormValues, isCreatingBrandKit, navigate, presetCount, selectedYoutubeVideo]);

  const onActiveStepIndexChange = useCallback(
    ({ activeIndex, previousIndex }: SwiperClass) => {
      if (steps[activeIndex].path === "finish") {
        submitBrandkit();
      }

      if (previousIndex !== undefined && pathParams.step) {
        const formValues = getFormValues();
        onboardingAnalytics.trackStepChange(previousIndex, activeIndex, formValues);
      }

      if (pathParams.step !== steps[activeIndex].path) {
        const path = applyPathParams(absoluteRoutes.onboarding.children.step, { step: steps[activeIndex].path });
        navigate(path);
      }
    },
    [navigate, pathParams, getFormValues, submitBrandkit],
  );

  useEffect(
    () => {
      setFormValue("content.languageCode", "en-US");
      validateForm();
      dispatch.presets.getPresets({});

      if (!initialStepCheckDone) {
        const path = applyPathParams(absoluteRoutes.onboarding.children.step, { step: steps[0].path });
        navigate(path, { replace: true });
        setInitialStepCheckDone(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    userEngagementService.setLauncherPadding({ verticalPadding: 100 });

    return () => {
      userEngagementService.setLauncherPadding({ verticalPadding: 0 });
    };
  }, []);

  if (!pathParams.step || !initialStepCheckDone) {
    return null;
  }

  const activeStepIndex = findIndex({ path: pathParams.step }, steps);
  const fullScreenStepIndexes = [0, steps.length - 1];

  return (
    <FormProvider {...onboardingForm}>
      <Container>
        <LeftPanel>
          <StyledSwiper
            onActiveIndexChange={onActiveStepIndexChange}
            activeIndex={activeStepIndex}
            allowSlideNext={!activeStepHasErrors}
            allowSlidePrev={activeStepIndex !== steps.length - 1}
            noSwipingSelector={`${Select}`}
          >
            {steps.map((step, i) => (
              <StyledSwiperSlide key={step.path} $fullScreen={fullScreenStepIndexes.includes(i)}>
                <MountIfActive index={i}>
                  <step.component />
                </MountIfActive>
              </StyledSwiperSlide>
            ))}

            <SwiperActiveSlideIndicator />

            <NextSlideButtonWrapper hide={activeStepIndex === steps.length - 1}>
              <OnboardingNextButton data-testid="next">
                {activeStepIndex < steps.length - 2 ? "Next" : "Finish"}
              </OnboardingNextButton>
            </NextSlideButtonWrapper>
          </StyledSwiper>
        </LeftPanel>

        <RightPanel isHidden={fullScreenStepIndexes.includes(activeStepIndex)}>
          <VideoPreview activeStep={pathParams.step} />
        </RightPanel>
      </Container>
    </FormProvider>
  );
}
