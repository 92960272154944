import {
  BluredPlug,
  ProgressContainer,
  RedirectToPlanButton,
} from "src/components/features/SmartLibrary/SmartLibrary.styled";
import { AspectRatio } from "src/constants/video.constants";

interface FootageExpiredProps {
  onUpgradePlanButtonClick: (toNextClosestPlan: boolean) => void;
}
export function FootageExpired({ onUpgradePlanButtonClick }: FootageExpiredProps) {
  return (
    <>
      <BluredPlug />
      <ProgressContainer aspectRatio={"16:9" as AspectRatio}>
        <RedirectToPlanButton onClick={() => onUpgradePlanButtonClick(true)} isBig>
          Upgrade to unlock
        </RedirectToPlanButton>
      </ProgressContainer>
    </>
  );
}
