import styled, { css } from "styled-components/macro";

import { SpaceValue } from "src/theme";
import { styleProp, switchProp } from "src/utils/styledComponents.utils";
import { FlexBox } from "src/components/common/layout/Box.styled";

export interface StackProps {
  direction: "row" | "column";
  spacing: SpaceValue;
}

export const stackStyle = css<StackProps>`
  flex-direction: ${styleProp("direction")};

  > *:not(:last-child) {
    ${switchProp("direction", {
      row: "margin-right",
      column: "margin-bottom",
    })}: ${(props) => props.theme.spaces[props.spacing]}px;
  }
`;

export const Stack = styled(FlexBox)<StackProps>`
  ${stackStyle}
`;
