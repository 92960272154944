import { MouseEvent as ReactMouseEvent, useCallback } from "react";
import useVariableRef from "src/hooks/useVariableRef";

export default function useDrag({
  onDrag,
  onDragStart,
  onDragEnd,
}: {
  onDrag(deltaX: number, deltaY: number, x: number, y: number, halt: () => void): void;
  onDragStart?(): void;
  onDragEnd?(fullDeltaX: number, fullDeltaY: number): void;
}) {
  const onDragRef = useVariableRef(onDrag);
  const onDragStartRef = useVariableRef(onDragStart);
  const onDragEndRef = useVariableRef(onDragEnd);

  const dragTriggerMouseDownHandler = useCallback(
    (mouseDownEvent: ReactMouseEvent) => {
      mouseDownEvent.stopPropagation();
      mouseDownEvent.preventDefault();

      let fullDeltaX = 0;
      let fullDeltaY = 0;
      let prevX = mouseDownEvent.clientX;
      let prevY = mouseDownEvent.clientY;

      const onMouseMove = (mouseMoveEvent: MouseEvent) => {
        const x = mouseMoveEvent.clientX;
        const y = mouseMoveEvent.clientY;
        const deltaX = x - prevX;
        const deltaY = y - prevY;

        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        onDragRef.current(deltaX, deltaY, x, y, halt);

        prevX = x;
        prevY = y;

        fullDeltaX += deltaX;
        fullDeltaY += deltaY;
      };

      const onMouseUp = () => {
        onDragEndRef.current?.(fullDeltaX, fullDeltaY);
        window.removeEventListener("mousemove", onMouseMove);
      };

      const halt = () => {
        onMouseUp();
        window.removeEventListener("mouseup", onMouseUp);
      };

      onDragStartRef.current?.();
      window.addEventListener("mousemove", onMouseMove);
      window.addEventListener("mouseup", onMouseUp, { once: true });
    },
    [onDragRef, onDragEndRef, onDragStartRef],
  );

  return {
    onMouseDown: dragTriggerMouseDownHandler,
  };
}
