import * as gql from "src/network/graphql/generatedGraphqlSDK";
import { CropDataUpdate, CropDetail } from "src/network/graphql/generatedGraphqlSDK";
import { ChapterTimeInterval, TimeInterval } from "src/types/video-trimmer.types";
import { AssetDomain } from "src/models/Crop.model";
import { Dictionary } from "@reduxjs/toolkit";
import { CropTimeInterval } from "src/types/video-cropper.types";
import { timeIntervalUtils } from "src/utils/timeInterval.utils";

const cropTimeIntervalEdgeFraction = 6;
const toFixed = (value: number) => parseFloat(value.toFixed(cropTimeIntervalEdgeFraction));

// takes crop time interval from all assets and converts it to crop time interval for full timeline (not considering chapters)
// used for normalizing crop data from all assets to one timeline
export const assetCropDataToTimeIntervalCropData = (
  cropsDetails?: Dictionary<CropDetail>,
  assetDomains?: AssetDomain[],
) => {
  const result: CropTimeInterval[] = [];

  if (!cropsDetails || !assetDomains?.length) {
    return result;
  }

  assetDomains.forEach((assetDomain) => {
    const assetCropData = cropsDetails[assetDomain.assetSid]?.cropData;
    if (!assetCropData?.length) {
      return;
    }

    assetCropData.forEach((cropData) => {
      result.push({
        ...cropData,
        start: assetDomain.domain.start + cropData.start,
        end: assetDomain.domain.start + cropData.end,
        domain: {
          start: assetDomain.domain.start,
          end: assetDomain.domain.end,
        },
      });
    });
  });

  return result;
};

// NOTE: accepts one asset
// takes crop time interval from chapter timeline and converts it to crop time interval for an asset (fill gaps between chapters)
// used for normalizing crop time interval before sending it to server
export const cropTimeIntervalsToAssetCropData = (
  cropTimeIntervals: CropTimeInterval[],
  assetCropDataDomain: TimeInterval,
) => {
  const result: CropDataUpdate[] = [];

  if (!cropTimeIntervals.length) {
    return result;
  }

  cropTimeIntervals.sort((a, b) => a.start - b.start);

  cropTimeIntervals.forEach((cropTimeInterval, index) => {
    if (timeIntervalUtils.containsTimeInterval(assetCropDataDomain, cropTimeInterval)) {
      const isLastCrop = index === cropTimeIntervals.length - 1;
      const isFirstCrop = index === 0;

      // first crop start is always 0 - eliminates gap at the start.
      // last crop end is always assetCropDataDomain.end - assetCropDataDomain.start which gives us the duration of the asset - eliminates gap at the end.
      // any crop's end in between is the start of the next crop - eliminates gaps between crops.

      // for each cropTimeInterval we need to subtract assetCropDataDomain.start. this converts from global time to asset time.
      result.push({
        draw: cropTimeInterval.draw,
        crop: cropTimeInterval.crop,
        start: isFirstCrop ? 0 : toFixed(cropTimeInterval.start - assetCropDataDomain.start),
        end: isLastCrop
          ? toFixed(assetCropDataDomain.end - assetCropDataDomain.start)
          : toFixed(cropTimeIntervals[index + 1].start - assetCropDataDomain.start),
      });
    }
  });

  return result;
};

// resizes crop time interval to fit into timeline
// this is the data that the user sees and edits
export const castCropTimeIntervalsToChapterTimeIntervals = (
  chapters: ChapterTimeInterval[],
  cropTimeIntervals: CropTimeInterval[],
) => {
  const result: CropTimeInterval[] = [];

  if (!chapters?.length || !cropTimeIntervals?.length) {
    return result;
  }

  cropTimeIntervals.forEach((crop, index) => {
    for (const chapter of chapters) {
      if (crop.start < chapter.end && crop.end > chapter.start) {
        result.push({
          ...crop,
          start: Math.max(crop.start, chapter.start),
          end: Math.min(crop.end, chapter.end),
          index,
        });
      }
    }
  });

  return result;
};

// sort assets by chapter order and calculate crop domain time interval for each asset
export const getSortedAssetCropDomains = (chapters?: gql.Chapter[]) => {
  const result: AssetDomain[] = [];

  if (!chapters?.length) {
    return result;
  }

  let lastChapterAssetSid: string;
  let lastDomainEnd = 0;
  chapters.forEach((chapter) => {
    if (chapter.assetSid && chapter.assetSid !== lastChapterAssetSid) {
      const start = lastDomainEnd;
      const end = lastDomainEnd + chapter.srcDuration!;
      result.push({
        assetSid: chapter.assetSid,
        domain: { start, end },
      });
      lastDomainEnd = end;
      lastChapterAssetSid = chapter.assetSid;
    }
  });

  return result;
};
