import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import faceRecognitionLoader from "src/assets/lottie-animations-data/face-recognition-loader.json";
import firstDraftLoader from "src/assets/lottie-animations-data/first-draft-loader.json";

import { SequenceStatus } from "src/constants/model.constants";

import { sequenceSelectors } from "src/models/Sequence.model";
import { speakerCuePointSelectors } from "src/models/SpeakerQuePoint.model";
import { Dispatch, RootState } from "src/models/store";

import { useNavigate } from "react-router-dom";
import Lottie from "src/components/common/media/Lottie";
import SpeakersModal from "src/components/modals/SpeakersModal/SpeakersModal";
import { useModal } from "src/components/providers/ModalProvider";
import { isMobile } from "src/utils/mobile.utils";
import { absoluteRoutes, applyPathParams } from "src/utils/routes.utils";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

interface SequenceProcessingProps {
  sequenceSid: string;
  skipSpeakerDetection: boolean;
}

export default function SequenceProcessing({ sequenceSid, skipSpeakerDetection }: SequenceProcessingProps) {
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();

  const dispatch = useDispatch<Dispatch>();
  const sequence = useSelector((state: RootState) => sequenceSelectors.selectById(state, sequenceSid));
  const undetectedSpeakerCuePoints = useSelector((state: RootState) => speakerCuePointSelectors.selectSequenceUndetectedSpeakerCuePoints(state, sequenceSid)); // prettier-ignore
  const isFaceDetectionCompleted = useSelector((state: RootState) => sequenceSelectors.selectIsFaceDetectionCompleted(state, sequenceSid)); // prettier-ignore
  const speakerCuePointsLoaded = useSelector((state: RootState) => state.loading.effects.speakerCuePoints.fetchSpeakerCuePoints.success); // prettier-ignore

  const sequenceUsesSpeakers = sequence?.editRules?.useSpeakers;
  const areThereUndetectedSpeakers = undetectedSpeakerCuePoints.length > 0;
  const isSpeakerDetectionCompleted = isFaceDetectionCompleted && speakerCuePointsLoaded && !areThereUndetectedSpeakers;

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch.sequences.fetchSequenceProcessingStatusProps({ sequenceSid });
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [dispatch, sequenceSid]);

  useEffect(() => {
    if (isFaceDetectionCompleted) {
      dispatch.speakerCuePoints.fetchSpeakerCuePoints({ sequenceSid });
    }
  }, [dispatch, sequenceSid, isFaceDetectionCompleted]);

  useEffect(
    () => {
      if (!isFaceDetectionCompleted || !areThereUndetectedSpeakers) {
        return;
      }

      if (skipSpeakerDetection || !sequenceUsesSpeakers) {
        dispatch.sequences.setDefaultSpeakers(sequenceSid);
      } else {
        openModal((props) => <SpeakersModal {...props} sequenceSid={sequenceSid} />, { hideCloseButton: true });
      }
    },
    // prettier-ignore
    [dispatch, openModal, sequenceSid, sequenceUsesSpeakers, isFaceDetectionCompleted, areThereUndetectedSpeakers, skipSpeakerDetection],
  );

  useEffect(() => {
    if (isSpeakerDetectionCompleted && sequence?.status === SequenceStatus.Active) {
      navigate(
        applyPathParams(
          absoluteRoutes.projectSequence.children.visualsEditor,
          { sequenceSid },
          { key: "firstDraft", value: "true" },
        ),
      );
    }
  }, [sequence, isSpeakerDetectionCompleted, navigate, sequenceSid]);

  useEffect(
    () => () => {
      closeModal();
    },
    [closeModal],
  );

  return (
    <Container>
      <Lottie
        animationData={isSpeakerDetectionCompleted ? firstDraftLoader : faceRecognitionLoader}
        autoplay
        width={isMobile() ? 200 : 300}
      />
    </Container>
  );
}
