import { Maybe } from "graphql/jsutils/Maybe";
import { AspectRatio } from "src/constants/video.constants";
import { ClipSequenceStatus, ClipSequenceType } from "src/network/graphql/generatedGraphqlSDK";

export type LeanSequence = {
  sid: Maybe<string>;
  title: string;
  type: ClipSequenceType;
  status: ClipSequenceStatus;
  ratio: AspectRatio;
};

export const ALL_BOARDS_ITEM_ID = "all-boards-item-id";
export const LAST_BOARD_ID = "last_board_id";
