import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { createModel } from "@rematch/core";

import { RootModel } from "src/models/index";
import { selectId } from "src/models/selectId";
import { RootState } from "src/models/store";

import { getPlanPrice } from "src/utils/plans.utils";

import apiClient from "src/network/ApiClient";
import { GetPlansQueryVariables, Plan, SelectPlanMutationVariables } from "src/network/graphql/generatedGraphqlSDK";

const planAdapter = createEntityAdapter<Plan>({ selectId });
const planAdapterSelectors = planAdapter.getSelectors((state: RootState) => state.plans);

const selectActivePlans = (state: RootState) =>
  Object.values(state.plans.entities)
    .sort((a, b) => (a?.tier ?? 0) - (b?.tier ?? 0))
    .filter((p) => !p?.hidden);

const getPlanProperties = (plan: Plan) => {
  const result = {
    monthly: getPlanPrice(plan, "monthly"),
    yearly: getPlanPrice(plan, "yearly"),
    saveAmount: plan.annualDiscountPrice ?? null,
    savePercentage: plan.annualDiscountPercentage ?? null,
    subHeader: plan.subHeader ?? null,
  };
  return result;
};

export const selectPlansProperties = createSelector(selectActivePlans, (activePlans) =>
  activePlans.reduce<
    Record<
      string,
      {
        monthly?: number | null;
        yearly?: number | null;
        saveAmount?: number | null;
        savePercentage?: number | null;
        subHeader?: string | null;
      }
    >
  >((accum, plan) => ({ ...accum, [plan?.sid!]: getPlanProperties(plan!) }), {}),
);

const selectUsersPlan = (state: RootState) => {
  if (state.session && state.session.planSid) {
    return planAdapterSelectors.selectById(state, state.session.planSid);
  }
  return null;
};

const selectIsTierFeatureAllowed = createSelector([selectUsersPlan, (state, feature) => feature], (plan, feature) =>
  plan?.allowedTierFeatures!.includes(feature),
);

const selectIsTierFeatureDisallowed = createSelector([selectUsersPlan, (state, feature) => feature], (plan, feature) =>
  plan?.disallowedTierFeatures!.includes(feature),
);

const selectNextClosestPlan = createSelector(
  [selectUsersPlan, selectActivePlans],
  (plan, activePlans) =>
    activePlans.sort((a, b) => (a?.tier ?? 0) - (b?.tier ?? 0)).find((p) => (p?.tier ?? 0) > (plan?.tier ?? 0)) ?? null,
);

export const planSelectors = {
  selectActivePlans,
  selectUsersPlan,
  selectPlansProperties,
  selectIsTierFeatureDisallowed,
  selectIsTierFeatureAllowed,
  selectNextClosestPlan,
  ...planAdapterSelectors,
};

const plans = createModel<RootModel>()({
  state: planAdapter.getInitialState(),

  reducers: {
    setPlans: (state, payload: Plan[]) => planAdapter.setMany(state, payload),
    setPlan: (state, payload: Plan) => planAdapter.setOne(state, payload),
  },

  effects: (dispatch) => ({
    async getPlans(variables: GetPlansQueryVariables) {
      const fetchedPlans = await apiClient.getPlans(variables);
      dispatch.plans.setPlans(fetchedPlans);
    },

    async getPlan() {
      const fetchedPlan = await apiClient.getPlan();
      dispatch.plans.setPlan(fetchedPlan);
      dispatch.session.setPlanSid(fetchedPlan?.sid!);
    },

    async selectPlan(variables: SelectPlanMutationVariables) {
      const fetchedSubscription = await apiClient.selectPlan(variables);
      dispatch.session.setSubscription(fetchedSubscription);
      await dispatch.plans.getPlan();
    },
  }),
});

export default plans;
