import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

import { Dispatch, RootState } from "src/models/store";
import { useAppConfig } from "src/components/providers/AppConfigProvider";
import { userSelectors } from "src/models/User.model";

import { Center } from "src/components/common/layout/Box.styled";
import { CircularLoader } from "src/components/common/loaders/CircularLoader";
import { absoluteRoutes } from "src/utils/routes.utils";
import * as onboardingAnalytics from "src/analytics/onboarding.analytics";

export default function OnboardingDoneGate() {
  const appConfig = useAppConfig();
  const dispatch = useDispatch<Dispatch>();
  const [isInitialized, setIsInitialized] = useState(false);
  const currentUser = useSelector(userSelectors.selectCurrentUser);
  const presetsNetwork = useSelector((state: RootState) => state.loading.effects.presets.getPresets);

  useEffect(() => {
    dispatch.presets.getPresets({});
  }, [dispatch]);

  useEffect(() => {
    if (presetsNetwork.success) {
      setIsInitialized(true);
    }
  }, [presetsNetwork.success]);

  if (!isInitialized) {
    return (
      <Center>
        <CircularLoader size={100} marginTop={20} />
      </Center>
    );
  }

  if (currentUser?.onboardingFinished) {
    return <Outlet />;
  }

  if (appConfig.EXAMPLE_FOOTAGE_ID && appConfig.EXAMPLE_PRESET_ID) {
    dispatch.users.updateUser({ userSid: currentUser?.sid!, onboardingFinished: true });
    onboardingAnalytics.trackSuccess();
    return <Outlet />;
  }

  return <Navigate to={absoluteRoutes.onboarding.self} replace />;
}
