/* eslint-disable jsx-a11y/media-has-caption */
import { useState, useCallback, useRef, useEffect, useMemo } from "react";
import { Rnd } from "react-rnd";
import styled from "styled-components/macro";
import useResizeObserver from "use-resize-observer";

import { ifProp, styleProp, themeColor } from "src/utils/styledComponents.utils";
import { Box } from "src/components/common/layout/Box.styled";
import Dropzone from "src/components/common/dropzones/Dropzone";
import { Text1Thin } from "src/components/common/layout/typography.styled";
import { Button } from "src/components/common/buttons/Button.styled";
import {
  CropDataDetail,
  CropDataItemSize,
  DragPosition,
  getCropData,
  getDraggablePosition,
  getDrawData,
  getFillCropData,
  getFitCropData,
  getItemScaleRelativeToStage,
} from "src/utils/cropData.utils";
import VideoToCanvasService from "src/services/VideoToCanvas.service";
import { AspectRatio, getAspectRatioValue } from "src/constants/video.constants";
import { cropImage, getImageFromVideo } from "src/utils/image.utils";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 25px;
  overflow: scroll;
`;

const TopSection = styled(Box)``;
const DropzoneWrapper = styled.div`
  width: fit-content;
  height: 30px;
  margin-bottom: 10px;
  margin: auto; ;
`;

const UploadButtonContainer = styled.div`
  width: 200px;
  height: 30px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid ${themeColor("pink.500")};
  cursor: pointer;
  padding: 5px 10px;
  margin-bottom: 10px;
  span {
    text-align: center;

    ${Text1Thin} {
      font-size: 12px;
      color: ${themeColor("gray.900")};
    }
  }
`;

const CropperStage = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  background-color: #625f5f;
  overflow: hidden;
  margin-bottom: 20px;
`;

const FrameOverlay = styled.div<{ isDragging?: boolean; aspectRatio: number }>`
  position: absolute;
  height: 100%;
  aspect-ratio: ${styleProp("aspectRatio")};
  left: 50%;
  transform: translate(-50%);
  background-color: transparent;

  &::after {
    content: "";
    z-index: 1;
    position: absolute;
    height: 100%;
    width: 100%;

    box-shadow: 0 0 0 2000px rgba(255, 255, 255, ${ifProp("isDragging", 0.5, 1)});
    pointer-events: none;
  }
`;

const CropItem = styled.div<{ isActive?: boolean }>`
  padding: 5px;
  margin-bottom: 5px;
  border: 1px solid ${themeColor("pink.500")};
  background-color: ${ifProp("isActive", themeColor("pink.500"), "transparent")};
  border-radius: 5px;
  cursor: pointer;
`;

const DragOverlayBox = styled.div`
  height: 100%;
  width: 100%;
  border: 1px solid #ff000095;
`;

const StyledVideo = styled.video<{ display: boolean }>`
  width: 100%;
  opacity: ${ifProp("display", 1, 0)};
`;

const defaultDraggablePosition: DragPosition = {
  x: 0,
  y: 0,
  w: 0,
  h: 0,
};

const CANVAS_SIZE = {
  "16:9": { w: 1280, h: 720 },
  "1:1": { w: 720, h: 720 },
  "9:16": { w: 720, h: 1280 },
};

export default function CropperPOCPage() {
  const [editRatio, setEditRatio] = useState<AspectRatio>("16:9");
  const [videoFile, setVideoFile] = useState<File | null>(null);
  const [draggablePosition, setDraggablePosition] = useState<DragPosition>(defaultDraggablePosition);
  const [cropMap, setCropMap] = useState<Map<number, CropDataDetail>>(new Map());
  const videoRef = useRef<HTMLVideoElement>(null);
  const viewPortRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const serviceRef = useRef<VideoToCanvasService | null>(null);
  const { height: viewPortHeight, width: viewPortWidth } = useResizeObserver({ ref: viewPortRef });
  const [viewPortLeft, setViewPortLeft] = useState<number>(0);
  const [videoProgress, setVideoProgress] = useState(0); // state of the progress bar

  const [currentCropKey, setCurrentCropKey] = useState<number>(0);
  const [isVideoPaused, setIsVideoPaused] = useState(true);
  const [isDragging, setIsDragging] = useState(false);

  const onDropFile = useCallback(async (acceptedFiles: File[]) => {
    setVideoFile(acceptedFiles[0]);
  }, []);

  const onFill = useCallback(() => {
    const videoOriginSize: CropDataItemSize = {
      width: videoRef.current!.videoWidth,
      height: videoRef.current!.videoHeight,
    };
    const viewPortSize: CropDataItemSize = {
      width: viewPortWidth!,
      height: viewPortHeight!,
    };
    const fillCropData = getFillCropData(videoOriginSize, viewPortSize);
    if (fillCropData) {
      setDraggablePosition(fillCropData);
    }
  }, [viewPortHeight, viewPortWidth]);

  const onFit = useCallback(() => {
    const videoOriginSize: CropDataItemSize = {
      width: videoRef.current!.videoWidth,
      height: videoRef.current!.videoHeight,
    };
    const viewPortSize: CropDataItemSize = {
      width: viewPortWidth!,
      height: viewPortHeight!,
    };
    const fitCropData = getFitCropData(videoOriginSize, viewPortSize);
    if (fitCropData) {
      setDraggablePosition(fitCropData);
    }
  }, [viewPortHeight, viewPortWidth]);

  useEffect(() => {
    if (videoRef.current && canvasRef.current) {
      serviceRef.current = new VideoToCanvasService(videoRef.current, canvasRef.current);
      serviceRef.current.blur = true;
      serviceRef.current.backgroundColor = "#f00";
      serviceRef.current.renderVideoLayer = false;
    }
  }, [videoRef, canvasRef]);

  useEffect(() => {
    if (serviceRef.current) {
      serviceRef.current.renderVideoLayer = !isVideoPaused;
    }
  }, [isVideoPaused]);

  useEffect(() => {
    // eslint-disable-next-line no-console
    const viewPortL = viewPortRef.current?.getBoundingClientRect().left!;
    const videPortParentL = viewPortRef.current?.parentElement?.getBoundingClientRect().left!;
    setViewPortLeft(viewPortL - videPortParentL);
  }, [viewPortWidth]);

  useEffect(() => {
    if (videoFile) {
      videoRef.current!.currentTime = 0;
      videoRef.current!.pause();
      videoRef.current!.src = URL.createObjectURL(videoFile);
    }
  }, [videoFile]);

  const factorScale = useMemo(() => {
    if (!videoRef.current || !draggablePosition.w || !draggablePosition.h) return 1;

    const viewPortSize: CropDataItemSize = {
      width: viewPortWidth!,
      height: viewPortHeight!,
    };
    const scale = getItemScaleRelativeToStage(draggablePosition, viewPortSize);

    return scale;
  }, [viewPortHeight, viewPortWidth, draggablePosition]);

  const onScaleChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newScale = Number(e.target.value) / 100;

      const scaleDiff = newScale / factorScale;
      // new position scale from the center
      const newX = draggablePosition.x + (parseFloat(draggablePosition.w as string) * (1 - scaleDiff)) / 2;
      const newY = draggablePosition.y + (parseFloat(draggablePosition.h as string) * (1 - scaleDiff)) / 2;
      const videoOriginSize: CropDataItemSize = {
        width: videoRef.current!.videoWidth,
        height: videoRef.current!.videoHeight,
      };
      const viewPortSize: CropDataItemSize = {
        width: viewPortWidth!,
        height: viewPortHeight!,
      };
      const newCropData = getFillCropData(videoOriginSize, viewPortSize, newScale);
      if (newCropData) {
        setDraggablePosition({ ...newCropData, x: newX, y: newY });
      }
    },
    [viewPortHeight, viewPortWidth, draggablePosition, factorScale],
  );

  const onCropSnap = () => {
    const videoRect = videoRef.current?.getBoundingClientRect();
    const stageRect = viewPortRef.current?.getBoundingClientRect();

    const cData = getCropData(videoRect!, stageRect!);
    const drawData = getDrawData(videoRect!, stageRect!);
    const data = {
      crop: cData,
      draw: drawData,
    };

    const newCropMap = new Map(cropMap);
    newCropMap.set(videoRef.current?.currentTime!, data);
    setCropMap(newCropMap);

    // eslint-disable-next-line no-console
    console.log("onCropSnap", { draggablePosition, data });
  };

  const onMetaDataLoad = useCallback(() => {
    if (!videoRef.current) return;
    videoRef.current.currentTime = 0;
    onFill();
    onCropSnap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onFill]);

  const getCropKeyByTime = useCallback(
    (time: number) => {
      const cropArray = Array.from(cropMap.keys()); // Convert the Map to an array of keys

      const closestKey = cropArray.reduce((prev, curr) => {
        if (curr <= time && curr > prev) {
          return curr;
        }

        return prev;
      }, 0);

      setCurrentCropKey(closestKey);
    },
    [cropMap],
  );

  const togglePlay = () => {
    if (videoRef.current?.paused) {
      videoRef.current?.play();
    } else {
      videoRef.current?.pause();
    }
  };

  const onTimeUpdate = () => {
    if (videoRef.current) {
      setVideoProgress((videoRef.current.currentTime / videoRef.current.duration) * 100);
    }
  };

  const changeProgressBar = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (videoRef.current && e.target) {
      const newTime = videoRef.current.duration * (Number(e.target.value) / 100);
      videoRef.current.currentTime = newTime;
      setVideoProgress((newTime / videoRef.current.duration) * 100);
    }
  };

  const redrawVideoPosition = useCallback(
    (force: boolean = false) => {
      if (!force && (videoRef.current?.paused || videoRef.current?.ended)) {
        return;
      }

      getCropKeyByTime(videoRef.current?.currentTime!);

      requestAnimationFrame(() => redrawVideoPosition());
    },
    [getCropKeyByTime],
  );

  useEffect(() => {
    const currentCrop = cropMap.get(currentCropKey);
    if (currentCrop) {
      if (serviceRef.current) {
        serviceRef.current.croppingData = currentCrop;
      }

      const videoOriginSize: CropDataItemSize = {
        width: videoRef.current!.videoWidth,
        height: videoRef.current!.videoHeight,
      };
      const viewPortSize: CropDataItemSize = {
        width: viewPortWidth!,
        height: viewPortHeight!,
      };
      const newDragPosition = getDraggablePosition(currentCrop, videoOriginSize, viewPortSize);

      // eslint-disable-next-line no-console
      console.log("newDragPosition", newDragPosition, currentCrop);
      setDraggablePosition(newDragPosition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCropKey]);

  const onVideoPlay = useCallback(() => {
    setIsVideoPaused(false);
    redrawVideoPosition();
  }, [setIsVideoPaused, redrawVideoPosition]);

  const onVideoPause = useCallback(() => {
    setIsVideoPaused(true);
  }, [setIsVideoPaused]);

  const onVideoPlaySeek = useCallback(() => {
    redrawVideoPosition(true);
  }, [redrawVideoPosition]);

  // Attach event listeners to the video when it loads
  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener("loadedmetadata", onMetaDataLoad);
      videoElement.addEventListener("play", onVideoPlay);
      videoElement.addEventListener("seeking", onVideoPlaySeek);
      videoElement.addEventListener("pause", onVideoPause);
      videoElement.addEventListener("timeupdate", onTimeUpdate);
    }

    // Clean up - remove event listeners when the component unmounts
    return () => {
      if (videoElement) {
        videoElement.removeEventListener("loadedmetadata", onMetaDataLoad);
        videoElement.removeEventListener("play", onVideoPlay);
        videoElement.removeEventListener("seeking", onVideoPlaySeek);
        videoElement.removeEventListener("pause", onVideoPause);
        videoElement.removeEventListener("timeupdate", onTimeUpdate);
      }
    };
  }, [onMetaDataLoad, onVideoPlaySeek, onVideoPlay, onVideoPause]);

  const handleCropKeyClick = useCallback((key: number) => {
    videoRef.current!.currentTime = key;
  }, []);

  const handleDeleteCrop = useCallback(
    (key: number) => {
      const newCropMap = new Map(cropMap);
      newCropMap.delete(key);
      setCropMap(newCropMap);
    },
    [cropMap],
  );

  const cropList = useMemo(
    () =>
      Array.from(cropMap.keys())
        .sort((a, b) => a - b)
        .map((key) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <CropItem
            isActive={key === currentCropKey}
            key={key}
            onClick={() => handleCropKeyClick(key)}
            onDoubleClick={() => handleDeleteCrop(key)}
          >
            {key}
          </CropItem>
        )),
    [cropMap, handleCropKeyClick, handleDeleteCrop, currentCropKey],
  );

  const [imgPreview, setImgPreview] = useState<string | null>(null);
  useEffect(() => {
    const fetchImage = async () => {
      if (videoFile && videoRef.current) {
        const img = await getImageFromVideo(videoFile, videoRef.current.currentTime, { width: 300 });
        let croppedImage;
        const currentCrop = cropMap.get(currentCropKey);
        if (img && currentCrop) {
          const aspectRatio = getAspectRatioValue(editRatio);
          const h = 150;
          croppedImage = await cropImage(img, currentCrop, { width: h * aspectRatio, height: h });
        }
        setImgPreview(croppedImage || img);
      }
    };

    fetchImage();
  }, [videoFile, videoRef?.current?.currentTime, currentCropKey, cropMap, editRatio]);

  return (
    <Container>
      <TopSection>
        <select
          onChange={(event) => {
            setEditRatio(event.target.value as AspectRatio);
          }}
        >
          <option value="16:9">Wide 16:9</option>
          <option value="1:1">Square 1:1</option>
          <option value="9:16">Portrait 9:16</option>
        </select>
        <DropzoneWrapper>
          <Dropzone
            onDrop={onDropFile}
            accept={{
              "video/*": [],
            }}
            maxFiles={1}
          >
            <UploadButtonContainer>
              <Text1Thin>click to select your files</Text1Thin>
            </UploadButtonContainer>
          </Dropzone>
        </DropzoneWrapper>
        <CropperStage>
          <FrameOverlay isDragging={isDragging} ref={viewPortRef} aspectRatio={getAspectRatioValue(editRatio)}>
            <canvas
              ref={canvasRef}
              width={CANVAS_SIZE[editRatio].w}
              height={CANVAS_SIZE[editRatio].h}
              style={{ width: "100%", height: "100%", position: "absolute" }}
            />
            <Rnd
              size={{ width: draggablePosition.w, height: draggablePosition.h }}
              position={{ x: draggablePosition.x, y: draggablePosition.y }}
              lockAspectRatio
              disableDragging
              enableResizing={false}
            >
              <StyledVideo display={isVideoPaused} ref={videoRef} />
            </Rnd>
          </FrameOverlay>
          {isVideoPaused && (
            <Rnd
              minHeight="10%"
              size={{ width: draggablePosition.w, height: draggablePosition.h }}
              position={{ x: draggablePosition.x + viewPortLeft, y: draggablePosition.y }}
              lockAspectRatio
              className="drag-overlay"
              resizeHandleWrapperClass="re-cropper-resizable-handle"
              resizeHandleClasses={{
                topLeft: "corner t-l",
                topRight: "corner t-r",
                bottomLeft: "corner b-l",
                bottomRight: "corner b-r",
              }}
              onDragStart={() => {
                setIsDragging(true);
              }}
              onResizeStart={() => {
                setIsDragging(true);
              }}
              onDrag={(e, d) => {
                setDraggablePosition({ ...draggablePosition, x: d.x - viewPortLeft, y: d.y });
              }}
              onDragStop={(e, d) => {
                setIsDragging(false);
                setDraggablePosition({ ...draggablePosition, x: d.x - viewPortLeft, y: d.y });
                onCropSnap();
              }}
              onResize={(e, direction, ref, delta, position) => {
                setIsDragging(false);

                setDraggablePosition({
                  w: ref.style.width,
                  h: ref.style.height,
                  x: position.x - viewPortLeft,
                  y: position.y,
                });
                onCropSnap();
              }}
            >
              <DragOverlayBox />
            </Rnd>
          )}
        </CropperStage>
        <input
          type="range"
          value={videoProgress}
          style={{ width: 700 }}
          step="0.1"
          min="0"
          max="100"
          onChange={changeProgressBar}
        />
        <Box style={{ display: "flex", gap: 10 }}>
          <Button variant="ghost" size="medium" style={{ marginBottom: "20px", minWidth: 120 }} onClick={togglePlay}>
            {isVideoPaused ? "Play" : "Pause"}
          </Button>
          <Button
            disabled={!isVideoPaused}
            variant="primary"
            size="medium"
            style={{ marginBottom: "20px" }}
            onClick={onCropSnap}
          >
            Crop snap
          </Button>
          <Button
            disabled={!isVideoPaused}
            variant="ghost"
            size="medium"
            style={{ marginBottom: "20px", minWidth: 120 }}
            onClick={onFill}
          >
            Fill
          </Button>
          <Button
            disabled={!isVideoPaused}
            variant="ghost"
            size="medium"
            style={{ marginBottom: "20px", minWidth: 120 }}
            onClick={onFit}
          >
            Fit
          </Button>
          <input
            type="range"
            disabled={!isVideoPaused}
            value={factorScale * 100}
            style={{ width: 200 }}
            step="1"
            min="1"
            max="200"
            onMouseDown={() => {
              setIsDragging(true);
            }}
            onMouseUp={() => {
              setIsDragging(false);
            }}
            onChange={onScaleChanged}
          />
        </Box>
      </TopSection>
      <div>{cropList}</div>
      {imgPreview && <img height="100px" style={{ objectFit: "contain" }} src={imgPreview} alt="preview" />}
      {/* <canvas ref={canvasRef} width="720" height="1280" style={{ width: "30%" }} /> */}
    </Container>
  );
}
