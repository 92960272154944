import { createModel } from "@rematch/core";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";

import apiClient from "src/network/ApiClient";
import * as gql from "src/network/graphql/generatedGraphqlSDK";

import { RootState, RootStateWithoutPlugins } from "src/models/store";
import { RootModel } from "src/models";
import { selectId } from "src/models/selectId";
import { CuePointStatus } from "src/constants/model.constants";

const speakerCuePointAdapter = createEntityAdapter<gql.SpeakerCuePoint>({ selectId });
const speakerCuePointAdapterSelectors = speakerCuePointAdapter.getSelectors(
  (state: RootState | RootStateWithoutPlugins) => state.speakerCuePoints,
);

const selectSequenceSpeakerCuePoints = createSelector(
  speakerCuePointAdapterSelectors.selectAll,
  (state: RootState | RootStateWithoutPlugins, sequenceId: string) => sequenceId,
  (speakerCuePoints, sequenceSid) => speakerCuePoints.filter((cuePoint) => cuePoint.sequenceSid === sequenceSid),
);

const selectSequenceDetectedSpeakerCuePoints = createSelector(selectSequenceSpeakerCuePoints, (speakerCuePoints) =>
  speakerCuePoints.filter((cuePoint) => cuePoint.status === CuePointStatus.Active && !!cuePoint.name),
);

const selectSequenceUndetectedSpeakerCuePoints = createSelector(selectSequenceSpeakerCuePoints, (speakerCuePoints) =>
  speakerCuePoints.filter((cuePoint) => cuePoint.status === CuePointStatus.Active && !cuePoint.name),
);

export const speakerCuePointSelectors = {
  ...speakerCuePointAdapterSelectors,
  selectSequenceSpeakerCuePoints,
  selectSequenceDetectedSpeakerCuePoints,
  selectSequenceUndetectedSpeakerCuePoints,
};

const speakerCuePoints = createModel<RootModel>()({
  state: speakerCuePointAdapter.getInitialState(),

  reducers: {
    setSpeakerCuePoints: (state, payload: gql.SpeakerCuePoint[]) => speakerCuePointAdapter.setAll(state, payload),
  },

  effects: (dispatch) => ({
    async fetchSpeakerCuePoints(variables: gql.GetSpeakerCuePointsQueryVariables) {
      const res = await apiClient.getSpeakerCuePoints(variables);
      dispatch.speakerCuePoints.setSpeakerCuePoints(res);
    },

    async updateSpeakerCuePoint(variables: gql.UpdateSpeakerCuePointMutationVariables) {
      await apiClient.updateSpeakerCuePoint(variables);
    },

    async removeSpeakerCuePoint(variables: gql.RemoveSpeakerCuePointMutationVariables) {
      await apiClient.removeSpeakerCuePoint(variables);
    },

    async activateSpeakerCuePoint(variables: gql.ActivateSpeakerCuePointMutationVariables) {
      await apiClient.activateSpeakerCuePoint(variables);
    },

    async deactivateSpeakerCuePoint(variables: gql.DeactivateSpeakerCuePointMutationVariables) {
      await apiClient.deactivateSpeakerCuePoint(variables);
    },
  }),
});

export default speakerCuePoints;
