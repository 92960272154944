import { formatDistance } from "date-fns";
import { sumBy } from "lodash/fp";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { themeColor } from "src/utils/styledComponents.utils";
import styled from "styled-components/macro";
import { ifProp } from "styled-tools";

import { Maybe } from "graphql/jsutils/Maybe";
import { footageSelectors } from "src/models/Footage.model";
import { planSelectors } from "src/models/Plan.model";
import { userSelectors } from "src/models/User.model";
import { Dispatch, RootState } from "src/models/store";
import {
  Board,
  Clip,
  DeleteOneFootageInput,
  Footage,
  FootageOrigin,
  FootageStatus,
} from "src/network/graphql/generatedGraphqlSDK";
import UploadToGcsService from "src/services/UploadToGcs.service";

import { useAppConfig } from "src/components/providers/AppConfigProvider";

import Icon from "src/components/common/Icon";
import ConfirmationModal from "src/components/modals/ConfirmationModal";

import { absoluteRoutes, applyPathParams } from "src/utils/routes.utils";
import { formatSeconds } from "src/utils/time.utils";

import useOverflowCheck from "src/hooks/useOverflowCheck";

import PEECH_THUMB from "src/assets/images/peech_thumbnail.png";
import {
  BluredPlug,
  DarkOverlay,
  DurationBlock,
  GridItem,
  PrevItemContentContainer,
} from "src/components/features/SmartLibrary/SmartLibrary.styled";
import EntityTitleModal from "src/components/modals/EntityTitleModal";
import { useModal } from "src/components/providers/ModalProvider";

import * as footageAnalytics from "src/analytics/footage.analytics";
import { ActionsMenuOptionProps } from "src/components/common/actionsMenu/ActionsMenuPopover";
import MediaSource from "src/components/common/media/MediaSource";
import { STATUS_LABEL_DICT } from "src/components/features/SmartLibrary/smartLibrary.constants";
import { NextPlanData } from "src/components/features/SmartLibrary/smartLibrary.types";
import {
  daysUntilExpiration,
  getIsOriginalVideoAvailable,
} from "src/components/features/SmartLibrary/smartLibrary.utils";
import { isMobile } from "src/utils/mobile.utils";
import CreateClipModal from "src/components/modals/CreateClipModal";
import { useFeatureFlag } from "src/components/providers/FeatureFlagsProvider";
import toast from "react-hot-toast";
import { useFootagePreviewItemLoadingState } from "src/hooks/useFootagePreviewItemLoadingState";
import { FailedInfo } from "src/components/features/SmartLibrary/footagesLibrary/FailedInfo";
import { ExpiredInfo } from "src/components/features/SmartLibrary/footagesLibrary/ExpiredInfo";
import { FootageActions } from "src/components/features/SmartLibrary/footagesLibrary/FootageActions";
import { FootageUploadStatus } from "src/components/features/SmartLibrary/footagesLibrary/FootageUploadStatus";
import { LanguageSelection } from "src/components/features/SmartLibrary/footagesLibrary/LanguageSelection";
import { FootagePrevFooter } from "src/components/features/SmartLibrary/footagesLibrary/FootagePrevFooter";
import { FootageUploadProgressBar } from "src/components/features/SmartLibrary/footagesLibrary/FootageUploadProgressBar";
import { MaxUploadDurationReached } from "src/components/features/SmartLibrary/footagesLibrary/MaxUploadDurationReached";
import { FootageExpired } from "src/components/features/SmartLibrary/footagesLibrary/FootageExpired";
import BoardsModal from "src/components/features/Boards/BoardsModal";
import * as boardsAnalytics from "src/analytics/boards.analytics";
import { boardSelectors } from "src/models/Board.model";
import { downloadFootageTranscript } from "src/utils/words.utils";
import { SupportedRatio } from "src/utils/math.utils";

const Container = styled.div<{ displayFooter: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;

  &:before,
  &:after {
    content: "";
    display: ${ifProp("displayFooter", "block", "none")};
    position: absolute;
    width: auto;
    height: 6px;
    bottom: 52px;
    left: 0;
    right: 0;
    border-radius: 0 0 60px 60px;
  }

  &:after {
    transform: translateY(6px);
    margin-inline: 10px;
    background-color: ${themeColor("blue.550")};
  }

  &:before {
    transform: translateY(12px);
    margin-inline: 20px;
    background-color: ${themeColor("blue.50")};
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    transition: all 0.5s ease;
  }
  &:hover {
    img {
      transform: scale(1.05);
      filter: brightness(0.5);
    }
  }
`;

const PrevItemContentWrapper = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 10px;
  background-color: ${themeColor("gray.900")};
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: url("${PEECH_THUMB}") no-repeat center center;
  background-size: contain;
`;

export const BoardsIconWrapper = styled.div`
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  margin-right: 4px;
`;

const DurationInfo = styled(DurationBlock)``;

export interface FootagePreviewItemProps {
  footageId: string;
  onLanguageSubmit: (footage: Footage, selectedLanguage: string) => void;
  displayFooter?: boolean;
  displayProgress?: boolean;
  allowClick?: boolean;
  boardId?: string | null;
}

export default function FootagePreviewItem({
  footageId,
  onLanguageSubmit,
  displayFooter = true,
  displayProgress = true,
  allowClick = true,
  boardId = null,
}: FootagePreviewItemProps) {
  const { CONTENT_URL, FOOTAGE_EXPIRED } = useAppConfig();
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const { openModal } = useModal();
  const footage = useSelector((state: RootState) => footageSelectors.selectById(state, footageId));
  const authToken = useSelector((state: RootState) => state.session.authToken);

  const {
    uploadStatus,
    progress,
    uploadThumbnail,
    setUploader,
    deleteUploader,
    getUploader,
    reachMaxUploadDuration,
    setReachMaxUploadDuration,
    uploader,
    footageUpdating,
    isFootageReadyToOpen,
    isLanguageSelectEnabled,
    isFootageFailure,
    isFootageExpired,
    shouldShowStatusTime,
    shouldDisplayWarningTag,
    shouldDisplayLoader,
    shouldShowProgress,
    shouldShowUploadingProgress,
    displayContextMenu,
  } = useFootagePreviewItemLoadingState({ footageId });

  const currentUserLangCode =
    useSelector((state: RootState) => userSelectors.selectById(state, state.session.userId)?.languageCode) || "en-US";
  const [selectedLanguage, setSelectedLanguage] = useState<Maybe<string>>(footage?.languageCode ?? currentUserLangCode);
  const [displayActionButtons, setDisplayActionButtons] = useState(false);
  const sequenceCount = useMemo(() => sumBy((clip: Clip) => clip?.sequences?.length ?? 0)(footage?.clips), [footage]);
  const footageThumbnailUrl = useSelector((state: RootState) => footageSelectors.selectThumbnailUrl(state, footageId));
  const thumbnailUrl = footageThumbnailUrl ? `${CONTENT_URL}/${footageThumbnailUrl}` : uploadThumbnail;
  const currentFootageBoard = useSelector((state: RootState) =>
    boardSelectors.selectBoardByFootageId(state, footageId),
  );
  const boards = useSelector((state: RootState) => boardSelectors.selectAll(state));
  const { ref: itemTitleRef, isOverflowing } = useOverflowCheck();

  const tooltipsTrigger = useMemo(() => (isMobile() ? "click" : "hover"), []);
  const userPlan = useSelector(planSelectors.selectUsersPlan);
  const nextClosestPlan = useSelector(planSelectors.selectNextClosestPlan);
  const nextClosestPlanData: NextPlanData | undefined = useMemo(() => {
    if (!nextClosestPlan) {
      return undefined;
    }
    return {
      sid: nextClosestPlan.sid,
      name: nextClosestPlan.name,
    };
  }, [nextClosestPlan]);

  const originalVideoAvailable = getIsOriginalVideoAvailable(footage as Footage);

  const userAllowCreateClipByTimestamps = useFeatureFlag("createClipByTimestamps");
  const notAllowedToCreateClipByTimestampsFootageOrigin = [
    FootageOrigin.ExampleProject,
    FootageOrigin.Archive,
    FootageOrigin.CreateProjectManually,
  ];
  const allowCreateClipByTimestamps = useMemo(
    () =>
      footage &&
      !notAllowedToCreateClipByTimestampsFootageOrigin.includes(footage?.origin) &&
      userAllowCreateClipByTimestamps &&
      (footage?.status === FootageStatus.Completed || footage?.status === FootageStatus.Ready) &&
      footage?.mediainfo?.duration,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userAllowCreateClipByTimestamps, footage],
  );

  const daysRemaining = useMemo(() => {
    if (shouldDisplayWarningTag && !isFootageExpired) {
      return daysUntilExpiration(footage?.createdAt, FOOTAGE_EXPIRED.expiredDays);
    }
    return 0;
  }, [FOOTAGE_EXPIRED.expiredDays, footage?.createdAt, isFootageExpired, shouldDisplayWarningTag]);

  const expiredText = useMemo(() => {
    if (shouldDisplayWarningTag) {
      if (isFootageExpired) {
        return "Expired";
      }
      if (daysRemaining) {
        return `Expires in ${daysRemaining} days`;
      }
      return "Expires today";
    }
    return null;
  }, [daysRemaining, isFootageExpired, shouldDisplayWarningTag]);

  const isClickable = allowClick && isFootageReadyToOpen && !isFootageExpired;

  const onUpgradePlanButtonClick = useCallback(
    (toNextClosestPlan = false) => {
      if (toNextClosestPlan && nextClosestPlanData?.sid) {
        navigate(`${absoluteRoutes.plans}?planId=${nextClosestPlanData.sid}&chargeType=1`);
      } else {
        navigate(absoluteRoutes.plans);
      }
    },
    [navigate, nextClosestPlanData?.sid],
  );

  const onRemoveFromBoardClick = useCallback(
    (originBoard: Board | null | undefined) => {
      const promise = dispatch.boards.removeFootageFromBoard({ footageId });
      toast.promise(promise, {
        loading: "Removing video from board",
        success: "Successfully removed",
        error: "Failed to remove",
      });
      boardsAnalytics.trackRemoveFootageFromBoard(originBoard, footageId, "board-move-modal");
    },
    [dispatch.boards, footageId],
  );

  const onMoveToBoardClick = useCallback(
    (focusedBoardId: string, originBoard: Board | null | undefined, targetBoard: Board | undefined) => {
      const promise = dispatch.boards.moveFootageToBoard({ footageId, boardId: focusedBoardId });
      toast.promise(promise, {
        loading: "Moving to board",
        success: "Successfully moved",
        error: "Failed to move",
      });
      boardsAnalytics.trackMoveFootageToBoard(originBoard, targetBoard, footageId, "board-move-modal");
    },
    [dispatch.boards, footageId],
  );

  const onChangeLanguage = useCallback(async (languageCode: string) => {
    setSelectedLanguage(languageCode);
  }, []);

  const onOptionsMenuClick = useCallback((e: any) => {
    (document.activeElement as HTMLInputElement)?.blur?.();
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const onFootageShowSnippetItemClick = useCallback(() => {
    if (isClickable) {
      navigate(applyPathParams(absoluteRoutes.platform.children.footage, { footageId }));
    }
  }, [footageId, isClickable, navigate]);

  const onFootageRename = useCallback(
    async (filename: string) => {
      try {
        await dispatch.footage.updateFootage({
          id: footageId,
          update: { filename },
        });
        // track rename
        footageAnalytics.trackRenameFootage({
          id: footageId,
          filename,
        } as Footage);
      } catch (error) {
        // eslint-disable-next-line
        console.log("onFootageRename", error);
      }
    },
    [dispatch.footage, footageId],
  );

  const onCreateClip = useCallback(
    (data: { title: string; inTime: number; outTime: number }) => {
      const { inTime, outTime, title } = data;
      const clipFrom = inTime / 1000;
      const duration = (outTime - inTime) / 1000;
      const promise = dispatch.footage.splitFootageByUserTimestamps({
        input: {
          footageId,
          concat: false,
          aspectRatios: [SupportedRatio.Ratio_16_9, SupportedRatio.Ratio_1_1, SupportedRatio.Ratio_9_16],
          clips: [{ title, clipFrom, duration }],
        },
      });
      toast.promise(promise, {
        loading: "Creating chapter",
        success: "Chapter created successfully",
        error: "Creating chapter failed, try again",
      });
    },
    [dispatch.footage, footageId],
  );

  const onFootageDelete = useCallback(async () => {
    try {
      footage && (await dispatch.footage.deleteFootage({ id: footage.id } as DeleteOneFootageInput));
      // track delete
      footageAnalytics.trackDeleteFootage({
        id: footageId,
      } as Footage);
      deleteUploader(footageId);
    } catch (error) {
      // eslint-disable-next-line
      console.log("onFootage", error);
    }
  }, [footage, dispatch.footage, footageId, deleteUploader]);

  const showRenameModal = useCallback(() => {
    openModal(
      ({ close, ...props }) => (
        <EntityTitleModal
          {...props}
          onSubmit={onFootageRename}
          close={close}
          modalTitle="Rename your video"
          titlePlaceholder={footage?.filename}
          submitLabel="Submit"
        />
      ),
      { hideCloseButton: true },
    );
  }, [footage?.filename, onFootageRename, openModal]);

  const showBoardsModal = useCallback(() => {
    openModal(
      ({ close }) => (
        <BoardsModal
          action={boardId ? "move" : "add"}
          close={close}
          footageId={footageId}
          footageName={footage?.filename}
          onMoveToBoardClick={onMoveToBoardClick}
          onRemoveFromBoardClick={onRemoveFromBoardClick}
        />
      ),
      {
        hideCloseButton: true,
      },
    );
  }, [footage?.filename, footageId, onMoveToBoardClick, onRemoveFromBoardClick, openModal, boardId]);

  const showDeleteModal = useCallback(() => {
    openModal(
      ({ close }) => (
        <ConfirmationModal
          close={close}
          onApprove={onFootageDelete}
          title="Delete"
          description="Are you sure you want to delete this Footage?"
          approveLabel="OK"
          declineLabel="Cancel"
        />
      ),
      { hideCloseButton: true },
    );
  }, [onFootageDelete, openModal]);

  const showCreateClipModal = useCallback(() => {
    openModal(
      ({ close }) => (
        <CreateClipModal
          close={close}
          minTime={0}
          maxTime={footage?.mediainfo?.duration ? footage.mediainfo.duration * 1000 : 0}
          onApprove={onCreateClip}
        />
      ),
      {
        hideCloseButton: true,
      },
    );
  }, [openModal, onCreateClip, footage?.mediainfo?.duration]);

  const menuOptions = useMemo(
    (): ActionsMenuOptionProps[] => [
      {
        name: "Rename",
        icon: <Icon.Edit />,
        action: showRenameModal,
        hidden: isFootageFailure,
      },
      {
        name: "Download transcription",
        icon: <Icon.Download />,
        action: () => {
          toast.promise(downloadFootageTranscript(footageId, authToken), {
            loading: "Downloading transcription",
            success: "Success downloading transcription",
            error: "Transcription download failed, try again",
          });
        },
        hidden: isFootageFailure,
      },

      {
        name: boardId ? "Move to board" : "Add to board",
        icon: (
          <BoardsIconWrapper>
            <Icon.Board />
          </BoardsIconWrapper>
        ),
        action: showBoardsModal,
        disabled: isFootageFailure || !boards.length,
      },
      {
        name: "Remove from board",
        icon: <Icon.Close />,
        action: () => onRemoveFromBoardClick(currentFootageBoard),
        hidden: !boardId,
      },
      {
        name: "Create from timestamps",
        icon: <Icon.Watchquarter />,
        action: showCreateClipModal,
        hidden: !allowCreateClipByTimestamps,
      },
      {
        name: "Delete",
        icon: <Icon.Trash />,
        action: showDeleteModal,
        hidden: false,
      },
    ],
    [
      showRenameModal,
      isFootageFailure,
      showDeleteModal,
      showBoardsModal,
      boards.length,
      boardId,
      showCreateClipModal,
      allowCreateClipByTimestamps,
      footageId,
      authToken,
      onRemoveFromBoardClick,
      currentFootageBoard,
    ],
  );

  const fromNowDistance = useMemo(() => {
    if (!displayFooter) {
      return null;
    }
    const dateObject = new Date(footage?.createdAt ?? 0);
    return formatDistance(dateObject, new Date(), { addSuffix: true });
  }, [footage?.createdAt, displayFooter]);

  useEffect(() => {
    if (uploader) {
      setUploader(uploader as UploadToGcsService, false);
    }
  }, [getUploader, setUploader, footageId, uploader]);

  const renderImage = () => {
    if (footageThumbnailUrl) {
      return <MediaSource src={thumbnailUrl}>{(src) => <img src={src} alt={footage?.filename} />}</MediaSource>;
    }
    if (thumbnailUrl) {
      return <img src={thumbnailUrl} alt={footage?.filename} />;
    }
    return null;
  };

  useEffect(() => {
    const checkDuration = async () => {
      const { maxFootageUploadMinutesDuration } = userPlan || {};
      if (
        maxFootageUploadMinutesDuration === null ||
        maxFootageUploadMinutesDuration === undefined ||
        maxFootageUploadMinutesDuration === 0
      ) {
        setReachMaxUploadDuration(false);
        return;
      }

      const uploadVideoMetaData = await uploader?.videoMetaData;

      if (
        uploadVideoMetaData &&
        uploadVideoMetaData.duration &&
        uploadVideoMetaData.duration / 60 > maxFootageUploadMinutesDuration!
      ) {
        setReachMaxUploadDuration(true);
        uploader?.cancel && uploader?.cancel();
      } else {
        setReachMaxUploadDuration(false);
      }
    };

    checkDuration();
  }, [setReachMaxUploadDuration, uploader, userPlan]);

  if (footage?.status === FootageStatus.Uploading && !uploader && !footage.youtubeId) {
    return null;
  }

  return (
    <GridItem>
      <Container
        onMouseEnter={() => isFootageReadyToOpen && !isFootageExpired && setDisplayActionButtons(true)}
        onMouseLeave={() => setDisplayActionButtons(false)}
        displayFooter={displayFooter}
      >
        <PrevItemContentWrapper>
          <ImageContainer>
            {renderImage()}
            <DarkOverlay />
          </ImageContainer>
          <PrevItemContentContainer>
            {(displayProgress || isLanguageSelectEnabled) && shouldShowProgress && (
              <>
                {!displayActionButtons && (shouldDisplayLoader || shouldShowUploadingProgress) && <BluredPlug />}

                {isLanguageSelectEnabled && (
                  <LanguageSelection
                    selectedLanguage={selectedLanguage}
                    onChangeLanguage={onChangeLanguage}
                    onLanguageSubmit={onLanguageSubmit}
                    footage={footage!}
                    footageUpdating={footageUpdating}
                    isLanguageSelectEnabled={isLanguageSelectEnabled}
                  />
                )}

                {shouldShowUploadingProgress && !isLanguageSelectEnabled && !footage?.youtubeId && (
                  <FootageUploadProgressBar
                    footage={footage!}
                    isLanguageSelectEnabled={isLanguageSelectEnabled}
                    uploadStatus={uploadStatus}
                    progress={progress}
                  />
                )}

                {shouldDisplayLoader && !isLanguageSelectEnabled && !displayActionButtons && (
                  <FootageUploadStatus
                    status={STATUS_LABEL_DICT[footage?.status!]}
                    shouldShowStatusTime={shouldShowStatusTime}
                  />
                )}
              </>
            )}

            {/* upload duration limit */}
            {reachMaxUploadDuration && (
              <MaxUploadDurationReached
                userPlan={userPlan}
                onUpgradePlanButtonClick={onUpgradePlanButtonClick}
                tooltipsTrigger={tooltipsTrigger}
              />
            )}

            {isFootageExpired && <FootageExpired onUpgradePlanButtonClick={onUpgradePlanButtonClick} />}

            <FootageActions
              displayActionButtons={isFootageReadyToOpen && displayActionButtons}
              isClickable={isClickable}
              onFootageShowSnippetItemClick={onFootageShowSnippetItemClick}
              shouldDisplayLoader={!!shouldDisplayLoader}
              isLanguageSelectEnabled={isLanguageSelectEnabled}
              sequenceCount={sequenceCount}
              footageId={footageId}
              navigate={navigate}
              originalVideoAvailability={originalVideoAvailable}
            />
          </PrevItemContentContainer>
          {isFootageFailure && <FailedInfo tooltipsTrigger={tooltipsTrigger} />}

          {(shouldDisplayWarningTag || isFootageExpired) && (
            <ExpiredInfo
              isFootageExpired={isFootageExpired}
              expiredText={expiredText}
              onUpgradePlanButtonClick={onUpgradePlanButtonClick}
              nextClosestPlanData={nextClosestPlanData}
              tooltipsTrigger={tooltipsTrigger}
            />
          )}

          {isFootageReadyToOpen && footage?.mediainfo?.duration && (
            <DurationInfo>
              {formatSeconds(footage?.mediainfo?.duration, footage?.mediainfo?.duration >= 3600 ? "HH:mm:ss" : "mm:ss")}
            </DurationInfo>
          )}
        </PrevItemContentWrapper>

        {displayFooter && (
          <FootagePrevFooter
            footage={footage!}
            isOverflowing={isOverflowing}
            itemTitleRef={itemTitleRef}
            displayContextMenu={displayContextMenu}
            onOptionsMenuClick={onOptionsMenuClick}
            menuOptions={menuOptions}
            fromNowDistance={fromNowDistance}
          />
        )}
      </Container>
    </GridItem>
  );
}
