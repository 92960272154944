import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { FixedSizeList, ListOnItemsRenderedProps } from "react-window";
import styled from "styled-components/macro";
import { timeIntervalUtils } from "src/utils/timeInterval.utils";
import { RootState } from "src/models/store";
import { LineItem, TranscriptSelection } from "src/types/video-trimmer.types";
import useShallowMemo from "src/hooks/useShallowMemo";
import { KEY_FRAME_MAX_DURATION, LINE_HEIGHT } from "src/constants/video-trimmer.constants";
import { useCurrentTimeListener } from "src/components/features/VideoTrimmer/providers/VideoCurrentTimeProvider/VideoCurrentTimeListenerContext";
import { useVideoPlayback } from "src/components/features/VideoTrimmer/providers/VideoPlaybackProvider/VideoPlaybackContext";
import { isSelectionInSelection } from "src/components/features/VideoTrimmer/VideoTranscript/utils"; // prettier-ignore
import { useVideoTranscript } from "src/components/features/VideoTrimmer/providers/VideoTranscriptProvider/VideoTranscriptContext";
import { useVideoChapters } from "src/components/features/VideoTrimmer/providers/VideoChaptersProvider/VideoChaptersContext";
import TextBlockLoading from "src/components/common/loaders/TextBlockLoading";
import ClipCreatorLineView, { ClipCreatorLineViewProps } from "src/components/features/ClipCreator/ClipCreatorLineView";

const Container = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const StyledFixedSizeList = styled(FixedSizeList<ClipCreatorLineViewProps>)`
  -webkit-user-drag: none;

  *::selection {
    background: transparent;
  }
`;

interface ClipCreatorVideoTranscriptProps {
  isInitialChapter: boolean;
}

// TODO: anything to do with setHoveredLineItem, hoveredLineItem,
//  hoveredChapter, isFirstInHoveredChapter should be used in phase 2 ->
//  review if actually needed in phase 2
export default function ClipCreatorVideoTranscript({ isInitialChapter }: ClipCreatorVideoTranscriptProps) {
  const isLoading = useSelector(
    (state: RootState) =>
      !!state.loading.effects.sequences.fetchSequence.loading ||
      !!state.loading.effects.footage.fetchFootage.loading ||
      !!state.loading.effects.words.fetchWords.loading ||
      !!state.loading.effects.words.fetchFootageWords.loading ||
      !!state.loading.effects.words.updateWords.loading,
  );
  const listRef = useRef<FixedSizeList>(null);
  const { chapters, subtractTimeIntervals, addTimeIntervals } = useVideoChapters();
  const { seekTimeInterval } = useVideoPlayback();

  const [hoveredLineItem, setHoveredLineItem] = useState<LineItem | null>(null);

  const hoveredChapter = useMemo(
    () =>
      (hoveredLineItem &&
        chapters.find((chapter) => timeIntervalUtils.containsTimeInterval(chapter, hoveredLineItem))) ||
      null,
    [chapters, hoveredLineItem],
  );

  const scrollingContainerRef = useRef<HTMLDivElement>(null);

  const [visibleLineRange, setVisibleLineRange] = useState({ start: 0, end: 0 });
  const {
    textDirection,
    videoRef,
    ref,
    height,
    width,
    selectionTimeInterval,
    hoveredTranscriptActionButton,
    focusedSearchResultIndex,
    searchResults,
    lines,
    transcriptSelection,
    recentInteractedSelectionEdge,
    isSelecting,
    cancelSelection,
  } = useVideoTranscript();
  const selection = transcriptSelection;

  const isFirstInHoveredChapter = useCallback(
    (lineIndex: number, itemIndex: number) => {
      if (!hoveredChapter) return false;
      const lineItem = lines[lineIndex].items[itemIndex];
      const is = hoveredChapter && timeIntervalUtils.includesTimePoint(lineItem, hoveredChapter.start);
      return is;
    },
    [hoveredChapter, lines],
  );

  const onItemsRendered = useCallback(({ visibleStartIndex, visibleStopIndex }: ListOnItemsRenderedProps) => {
    setVisibleLineRange({
      start: visibleStartIndex,
      end: visibleStopIndex,
    });
  }, []);

  const isLineItemSelected = useCallback(
    (lineIndex: number, itemIndex: number) => {
      if (!transcriptSelection) return false;

      const item = lines[lineIndex].items[itemIndex];
      const itemSelection: TranscriptSelection = {
        start: { lineIndex, itemIndex, itemOffset: 0 },
        end: { lineIndex, itemIndex, itemOffset: item.kind === "blank" ? 1 : item.word.length },
      };

      return isSelectionInSelection(itemSelection, transcriptSelection);
    },
    [lines, transcriptSelection],
  );

  const isLineItemPlayable = useCallback(
    (lineIndex: number, itemIndex: number) => {
      const lineItem = lines[lineIndex].items[itemIndex];

      return chapters.some((chapter) => {
        const normalizedItem =
          lineItem.kind === "blank" ? timeIntervalUtils.unpad(lineItem, KEY_FRAME_MAX_DURATION) : lineItem;

        return timeIntervalUtils.intersectsTimeInterval(chapter, normalizedItem);
      });
    },
    [chapters, lines],
  );

  const onLineItemClick = useCallback(
    (lineIndex: number, itemIndex: number) => {
      const lineItem = lines[lineIndex].items[itemIndex];
      const isPlayable = isLineItemPlayable(lineIndex, itemIndex);

      if (lineItem.kind === "blank") {
        if (isInitialChapter) {
          subtractTimeIntervals([{ start: chapters[0].start, end: chapters[0].end }]);
          addTimeIntervals([lineItem]);
        } else if (isPlayable) {
          subtractTimeIntervals([lineItem]);
          // TODO: add analytics
        } else {
          addTimeIntervals([lineItem]);
          // TODO: add analytics
        }

        return;
      }

      if (isPlayable) {
        seekTimeInterval(lineItem);
      }
    },
    [addTimeIntervals, chapters, isInitialChapter, isLineItemPlayable, lines, seekTimeInterval, subtractTimeIntervals],
  );

  useEffect(() => {
    const scrollingContainer = scrollingContainerRef.current;

    const onScrollingContainerWheel = () => {
      videoRef.current?.pause();
    };

    scrollingContainer?.addEventListener("wheel", onScrollingContainerWheel);

    return () => {
      scrollingContainer?.removeEventListener("wheel", onScrollingContainerWheel);
    };
  }, [videoRef]);

  const prevLineIndexRef = useRef(-1);
  useCurrentTimeListener((currentTime: number) => {
    const lineIndex = timeIntervalUtils.findIndexByTimePoint(lines, currentTime, false);

    if (lineIndex !== null && lineIndex !== prevLineIndexRef.current) {
      listRef.current?.scrollToItem(lineIndex, "smart");
      prevLineIndexRef.current = lineIndex;
    }
  });

  useEffect(() => {
    cancelSelection();
  }, [cancelSelection, lines]);

  const highlightedLineItemKinds = useMemo<LineItem["kind"][]>(() => {
    switch (hoveredTranscriptActionButton) {
      case "toggleFillerWords":
        return ["fillerWord"];

      case "toggleBlanks":
        return ["blank"];

      default:
        return [];
    }
  }, [hoveredTranscriptActionButton]);

  useEffect(() => {
    const focusedSearchResult = searchResults[focusedSearchResultIndex];

    if (focusedSearchResult) {
      const focusedSearchResultLineIndex = timeIntervalUtils.findIndexByTimePoint(lines, focusedSearchResult.start);

      if (focusedSearchResultLineIndex !== null) {
        listRef.current?.scrollToItem(focusedSearchResultLineIndex, "smart");
      }
    }
  }, [focusedSearchResultIndex, lines, searchResults]);

  const itemData = useShallowMemo<ClipCreatorLineViewProps>({
    textDirection,
    lines,
    visibleLineRange,
    transcriptSelection: selection,
    recentInteractedSelectionEdge,
    highlightedLineItemKinds,
    isSelecting,
    selectionTimeInterval,
    searchResults,
    focusedSearchResultIndex,
    isLineItemSelected,
    isLineItemPlayable,
    onLineItemClick,
    cancelSelection,
    isInitialChapter,
    setHoveredLineItem,
    hoveredLineItem,
    hoveredChapter,
    isFirstInHoveredChapter,
  });

  return (
    <Container>
      {isLoading ? (
        <TextBlockLoading blocksCount={150} />
      ) : (
        <div
          ref={ref}
          style={{
            width: "100%",
            flex: 1,
            overflow: "hidden",
          }}
        >
          <StyledFixedSizeList
            outerRef={scrollingContainerRef}
            ref={listRef}
            width={width}
            height={height}
            overscanCount={15}
            itemCount={lines.length}
            itemSize={LINE_HEIGHT}
            useIsScrolling
            onItemsRendered={onItemsRendered}
            itemData={itemData}
          >
            {ClipCreatorLineView}
          </StyledFixedSizeList>
        </div>
      )}
    </Container>
  );
}
