import styled from "styled-components/macro";
import { Stack } from "src/components/common/layout/Stack.styled";
import Icon from "src/components/common/Icon";
import theme from "src/theme";
import { Text3 } from "src/components/common/layout/typography.styled";
import React, { PropsWithChildren } from "react";
import { ALL_BOARDS_ITEM_ID } from "src/constants/smartLibrary.constants";
import { ifProp } from "styled-tools";

const StyledSelectStack = styled(Stack)`
  align-items: center;
  font-family: "Open Sans" !important;
  justify-content: space-between;
  width: 100%;
`;

const LeftSide = styled(Stack)`
  align-items: center;
  max-height: 28px;
  flex-wrap: nowrap;
  margin-right: 0px;
  width: 80%;
`;

const RightSide = styled(Stack)`
  align-items: flex-end;
  position: relative;
  max-height: 28px;
  width: 20%;
  justify-content: flex-end;
`;

export const IconWrapper = styled.div<{ isFootageInBoard?: boolean }>`
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color 0.3s ease-out;
  align-content: center;

  svg {
    path {
      fill: ${ifProp("isFootageInBoard", theme.colors.blue["500"], theme.colors.gray["900"])};
    }
  }

  &:hover {
    svg {
      color: ${theme.colors.blue["500"]};
    }
  }
`;

const StyledIconWrapper = styled(IconWrapper)`
  svg {
    path {
      stroke: ${ifProp("isFootageInBoard", theme.colors.blue["500"], theme.colors.gray["900"])};
      fill: none;
    }
  }
`;

const StyledText = styled(Text3)<{ isFootageInBoard?: boolean }>`
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
  color: ${theme.colors.gray["900"]};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  bottom: 1px;
  position: relative;

  color: ${ifProp("isFootageInBoard", theme.colors.blue["500"], theme.colors.gray["900"])};
`;

export interface BoardOptionProps extends PropsWithChildren {
  id: string;
  name: string;
  isFocused: boolean;
  isFootageInBoard?: boolean;
}

export default function BoardsOption({ id, name, isFocused, isFootageInBoard, children }: BoardOptionProps) {
  return (
    <StyledSelectStack direction="row" spacing={12}>
      <LeftSide direction="row" spacing={6}>
        {id === ALL_BOARDS_ITEM_ID ? (
          <StyledIconWrapper isFootageInBoard={isFootageInBoard}>
            <Icon.VideosStack size={18} />
          </StyledIconWrapper>
        ) : (
          <IconWrapper isFootageInBoard={isFootageInBoard}>
            <Icon.Board size={14} />
          </IconWrapper>
        )}

        <StyledText isFootageInBoard={isFootageInBoard}>{name}</StyledText>
      </LeftSide>
      <RightSide direction="row" spacing={6}>
        {isFocused && children}
      </RightSide>
    </StyledSelectStack>
  );
}
