export enum STEPS {
  UPLOADING = "uploading",
  BRAND_KIT = "brandKit",
  FINISH = "finish",
}

export enum SUBSTEPS {
  LOGO = "logo",
  COLORS = "colors",
  VISUALS = "visuals",
  SORS = "SORs",
}
