import Popper from "src/components/common/popovers/Popper";
import Icon from "src/components/common/Icon";
import { useSelector } from "react-redux";
import { RootState } from "src/models/store";
import { sessionSelectors } from "src/models/Session.model";
import styled, { keyframes } from "styled-components/macro";
import { ifProp } from "styled-tools";
import { themeColor } from "src/utils/styledComponents.utils";
import { Panel } from "src/components/common/popovers/panel/Panel";

const TextBlock = styled.div<{ bold?: boolean }>`
  width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Open Sans", sans-serif;
  margin-block: 4px;
  font-size: 10px;
  font-weight: ${ifProp("bold", "600", "400")};
  line-height: 17px;
  color: ${themeColor("gray.900")};
  display: flex;
  justify-content: center;
`;

const pump = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
`;

const TextBlockLoading = styled(TextBlock)`
  color: transparent;
  background-color: ${themeColor("gray.300")};
  animation: ${pump} 1s infinite ease-in-out;
  border-radius: 8px; ;
`;

export default function DownloadsBalanceInfo() {
  const downloadsBalance = useSelector((state: RootState) => sessionSelectors.selectDownloadsBalance(state));
  const nextBillingDate = useSelector((state: RootState) => sessionSelectors.selectFormattedBillingCycleDate(state));

  const isLoadingDownloadsBalance = useSelector(
    (state: RootState) => state.loading.effects.session.getDownloadsBalance.loading,
  );

  return isLoadingDownloadsBalance ? (
    <TextBlockLoading>.</TextBlockLoading>
  ) : (
    <>
      {downloadsBalance?.periodLeftDownloads === null && <TextBlock bold>Unlimited video downloads</TextBlock>}

      {(downloadsBalance?.periodLeftDownloads || downloadsBalance?.periodLeftDownloads === 0) && (
        <TextBlock>
          {`${downloadsBalance.periodMaxDownloads - downloadsBalance.periodLeftDownloads} / ${
            downloadsBalance.periodMaxDownloads
          } Video downloads`}
          <Popper
            style={{ display: "inline", marginLeft: "7px", lineHeight: "20px" }}
            content={<Panel>Video download limit will renew on {nextBillingDate}</Panel>}
            trigger="hover"
            placement="top"
            offsetY={-45}
            offsetX={-120}
          >
            <Icon.CommentInfo width="12px" height="12px" />
          </Popper>
        </TextBlock>
      )}
    </>
  );
}
