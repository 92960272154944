import styled from "styled-components/macro";

import { themeColor } from "src/utils/styledComponents.utils";

import { PLAN_PAGE_TESTIMONIALS } from "src/components/features/Plans/plans.constants";

const Container = styled.div`
  margin: 10px 0 10px 0;
  position: relative;
  border-radius: 12px;
  background-color: ${themeColor("blue.900")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 40px;

  width: 100%;
  min-height: 50%;
  @media only screen and (min-width: 1366px) {
    margin: 10px 0 0 40px;
    width: 30%;
    min-height: 98%;
    padding: 6px 20px;
    padding: 10px 40px;
  }
`;

const TextBlock = styled.div`
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  color: ${themeColor("white")};
  padding-block: 20px;

  @media only screen and (min-width: 1366px) {
    font-size: 23px;
    line-height: 25px;
  }
`;

const BottomBlock = styled.div`
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const NameBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  text-align: center;
  color: ${themeColor("white")};
  @media only screen and (min-width: 600px) {
    font-size: 14px;
    line-height: 15px;
  }
`;

const CompanyBlock = styled(NameBlock)`
  text-transform: uppercase;
`;

const Avatar = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-block: 14px;

  @media only screen and (min-width: 1366px) {
    width: 156px;
    height: 156px;
  }
`;

export default function TestimonialBlock() {
  const randomIndex = Math.floor(Math.random() * PLAN_PAGE_TESTIMONIALS.length);
  const { pictureURL, name, title, text, company } = PLAN_PAGE_TESTIMONIALS[randomIndex];

  return (
    <Container>
      <TextBlock>{`"${text}"`}</TextBlock>
      <BottomBlock>
        <Avatar src={pictureURL} alt={name} />
        <NameBlock>{`${name}, ${title}`}</NameBlock>
        <CompanyBlock>{company}</CompanyBlock>
      </BottomBlock>
    </Container>
  );
}
