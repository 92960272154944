/* eslint-disable no-console */
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { Toaster, toast } from "react-hot-toast";
import { ErrorBoundary } from "@sentry/react";
import { getPersistor } from "@rematch/persist";
import { PersistGate } from "redux-persist/lib/integration/react";
import styled, { ThemeProvider } from "styled-components/macro";

import "src/scripts/mmcq";

import theme from "src/theme";

import store from "src/models/store";

import reportWebVitals from "src/reportWebVitals";

import appMonitoringService from "src/services/AppMonitoring.service";

import FeatureFlagsProvider from "src/components/providers/FeatureFlagsProvider";
import AppGlobalStyles from "src/components/app/AppGlobalStyles";
import ModalProvider from "src/components/providers/ModalProvider";
import AppHelmetPreConfig from "src/components/app/AppHelmetPreConfig";
import AppConfigProvider from "src/components/providers/AppConfigProvider";
import AppHelmetPostConfig from "src/components/app/AppHelmetPostConfig";
import AppGoogleOAuthProvider from "src/components/providers/AppGoogleOAuthProvider";
import AppRouter from "src/components/app/AppRouter/AppRouter";
import UploaderProvider from "src/components/providers/UploaderProvider";
import AppErrorReporter from "src/components/app/AppErrorReporter";
import { UpdateNotification } from "src/components/common/notifications/UpdateNotification";
import * as serviceWorker from "./serviceWorkerRegistration";
import { ServiceWorkerRegistrationConfig } from "./serviceWorkerRegistration";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

appMonitoringService.getSessionURL()?.then(console.log);

const persistor = getPersistor();
const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <FeatureFlagsProvider>
          <ThemeProvider theme={theme}>
            <AppGlobalStyles />
            <AppHelmetPreConfig />

            <AppConfigProvider>
              <AppHelmetPostConfig />

              <AppGoogleOAuthProvider>
                <ModalProvider>
                  <UploaderProvider>
                    <AppErrorReporter />

                    <Container>
                      <AppRouter />
                      <Toaster
                        position="top-center"
                        containerStyle={{
                          top: 5,
                          left: 20,
                          bottom: 20,
                          right: 20,
                        }}
                      />
                    </Container>
                  </UploaderProvider>
                </ModalProvider>
              </AppGoogleOAuthProvider>
            </AppConfigProvider>
          </ThemeProvider>
        </FeatureFlagsProvider>
      </PersistGate>
    </Provider>
  </ErrorBoundary>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

declare global {
  interface Window {
    updateWorker: ServiceWorkerRegistration;
  }
}

function confirmUpdateVersion() {
  toast((t) => <UpdateNotification t={t} />, {
    id: "update-version-toast",
    style: {
      marginBottom: "30px",
      minWidth: "70%",
    },
    duration: 30000,
    position: "bottom-center",
  });
}

const config: ServiceWorkerRegistrationConfig = {
  onRegister: (registration: ServiceWorkerRegistration) => {
    window.updateWorker = registration;
    if (registration.waiting) {
      confirmUpdateVersion();
    }

    // check for update on focus
    window.onfocus = () => {
      if (registration.waiting) {
        if (registration.waiting) {
          console.log("sw new version ready");
          confirmUpdateVersion();
        }
      } else {
        registration.update();
      }
    };
  },
  onUpdate: (registration: ServiceWorkerRegistration) => {
    if (registration.waiting) {
      console.log("sw new version ready");
      confirmUpdateVersion();
    }
  },
};

serviceWorker.register(config);
