/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */

import {
  ReactNode,
  useCallback,
  useMemo,
  useState,
  MouseEvent,
  KeyboardEvent,
  CSSProperties,
  useRef,
  useEffect,
} from "react";
import { createPortal } from "react-dom";
import { usePopper, StrictModifier } from "react-popper";
import { PositioningStrategy } from "@popperjs/core/lib/types";
import { Placement } from "@popperjs/core/lib/enums";
import styled, { keyframes } from "styled-components/macro";

import useOnMouseDownOutside from "src/hooks/useOnMouseDownOutside";
import useVariableRef from "src/hooks/useVariableRef";
import { ifProp } from "styled-tools";

const PopperInAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const Content = styled.div<{ isOpaque: boolean; animationDuration: number }>`
  opacity: ${ifProp("isOpaque", "1", "0")};
  animation: ${PopperInAnimation} ${(props) => props.animationDuration}ms ease-out;
  transition: opacity ${(props) => props.animationDuration}ms ease-out;
  pointer-events: ${ifProp("isOpaque", "all", "none")};
`;

type Trigger = "click" | "hover";

export interface PopperProps {
  content: ReactNode;
  children?: ReactNode;
  isOpen?: boolean;
  trigger?: Trigger;
  placement?: Placement;
  strategy?: PositioningStrategy;
  useClickOutside?: boolean;
  disabled?: boolean;
  offsetX?: number;
  offsetY?: number;
  zIndex?: number;
  className?: string;
  style?: CSSProperties;
  onOpen?(): void;
  onClose?(): void;
  animationDuration?: number;
  hoverDelay?: number;
}

export default function Popper({
  content,
  children,
  isOpen: propsIsOpen,
  trigger = "click",
  placement,
  strategy,
  useClickOutside = true,
  disabled,
  offsetX,
  offsetY,
  zIndex = 99999,
  className,
  style,
  onOpen,
  onClose,
  animationDuration = 250,
  hoverDelay = 500,
}: PopperProps) {
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [localIsOpen, setLocalIsOpen] = useState(false);
  const [isOpaque, setIsOpaque] = useState(propsIsOpen || false);

  const modifiers: StrictModifier[] = [{ name: "offset", options: { offset: [offsetX, offsetY] } }];
  const { styles, attributes } = usePopper(referenceElement, popperElement, { modifiers, placement, strategy });
  const closeTimeoutRef = useRef(-1);
  const hoverOpenTimeoutRef = useRef(-1);
  const hoverCloseTimeoutRef = useRef(-1);

  const popperElementStyle = useMemo<CSSProperties>(
    () => ({
      ...styles.popper,
      zIndex,
      visibility: attributes.popper?.["data-popper-reference-hidden"] && !propsIsOpen ? "hidden" : "visible",
    }),
    [attributes.popper, styles.popper, zIndex, propsIsOpen],
  );

  const isControlled = propsIsOpen !== undefined;

  useEffect(() => {
    if (isControlled) {
      setLocalIsOpen(propsIsOpen!);
      setIsOpaque(propsIsOpen!);
    }
  }, [isControlled, propsIsOpen]);

  const open = useCallback(
    (openTrigger: Trigger) => {
      if (disabled || openTrigger !== trigger) return;
      clearTimeout(closeTimeoutRef.current);
      setIsOpaque(true);
      isControlled ? onOpen?.() : setLocalIsOpen(true);
    },
    [onOpen, trigger, disabled, isControlled],
  );

  const close = useCallback(
    (closeTrigger: Trigger) => {
      if (closeTrigger !== trigger) return;
      setIsOpaque(false);
      closeTimeoutRef.current = window.setTimeout(() => {
        isControlled ? onClose?.() : setLocalIsOpen(false);
      }, animationDuration + 1);
    },
    [animationDuration, isControlled, onClose, trigger],
  );

  const clickTriggerToggle = useCallback(
    (e: MouseEvent | KeyboardEvent) => {
      if (trigger !== "click") return;
      e.preventDefault();
      e.stopPropagation();
      localIsOpen ? close("click") : open("click");
    },
    [trigger, localIsOpen, close, open],
  );

  const hoverTriggerOpen = useCallback(() => {
    clearTimeout(hoverCloseTimeoutRef.current);
    hoverOpenTimeoutRef.current = window.setTimeout(() => {
      open("hover");
    }, hoverDelay);
  }, [hoverDelay, open]);

  const hoverTriggerClose = useCallback(() => {
    clearTimeout(hoverOpenTimeoutRef.current);
    hoverCloseTimeoutRef.current = window.setTimeout(() => {
      close("hover");
    }, hoverDelay);
  }, [close, hoverDelay]);

  const onPopperElementClick = useCallback((e: MouseEvent) => e.stopPropagation(), []);

  useOnMouseDownOutside(useVariableRef(popperElement), (e) => {
    if (localIsOpen && trigger === "click" && useClickOutside) {
      const isInsidePopper = popperElement?.contains(e.target as Node);
      if (!isInsidePopper) {
        e.stopPropagation();
        close("click");
      }
    }
  });

  return (
    <>
      <div
        ref={setReferenceElement}
        role="button"
        tabIndex={-1}
        onMouseDown={clickTriggerToggle}
        onMouseEnter={hoverTriggerOpen}
        onMouseLeave={hoverTriggerClose}
        style={{ ...styles.reference, ...style }}
        className={className}
      >
        {children}
      </div>

      {localIsOpen &&
        createPortal(
          <Content
            isOpaque={isOpaque}
            animationDuration={animationDuration}
            ref={setPopperElement}
            style={popperElementStyle}
            onClick={onPopperElementClick}
            onMouseEnter={hoverTriggerOpen}
            onMouseLeave={hoverTriggerClose}
            {...attributes.popper}
          >
            {content}
          </Content>,
          document.body,
        )}
    </>
  );
}
