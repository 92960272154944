import { createModel } from "@rematch/core";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { keyBy, sortBy, sumBy } from "lodash/fp";

import { RootState } from "src/models/store";
import { RootModel } from "src/models/index";
import { selectId } from "src/models/selectId";
import { sequenceSelectors } from "src/models/Sequence.model";

import { timeIntervalUtils } from "src/utils/timeInterval.utils";

import apiClient from "src/network/ApiClient";
import {
  GetSequenceCaptionsQueryVariables,
  GetV2SequenceCaptionsQueryVariables,
  RegenerateSequenceCaptionsMutationVariables,
  Word as gqlWord,
} from "src/network/graphql/generatedGraphqlSDK";
import { CaptionTimeInterval, ChapterTimeInterval, Word, WordTimeInterval } from "src/types/video-trimmer.types";
import { AppConfig } from "src/components/providers/AppConfigProvider";
import { isFillerWord } from "src/utils/words.utils";

interface SequenceCaptions {
  sid: string;
  words: gqlWord[];
  syncWithTranscript?: boolean;
  contentVersion?: number;
}

const sequenceCaptionsAdapter = createEntityAdapter<SequenceCaptions>({ selectId });

const sequenceCaptionsAdapterSelectors = sequenceCaptionsAdapter.getSelectors((state: RootState) => state.sequenceCaptions); // prettier-ignore

const formatWord = ({ word, startTime, endTime, newLine, highlight }: gqlWord): WordTimeInterval => ({
  word: word!,
  start: startTime! / 1000,
  end: endTime! / 1000,
  newLine: !!newLine,
  highlight,
});

const selectAbsoluteSequenceWords = createSelector(
  sequenceSelectors.selectById,
  sequenceSelectors.selectSourceChapters,
  sequenceCaptionsAdapterSelectors.selectById,
  (state: RootState, sequenceSid: string, appConfig: AppConfig) => appConfig.FILLER_WORDS,
  (sequence, sourceVideoChapters, sequenceCaptions, fillerWords) => {
    if (!sequence || !sourceVideoChapters.length) {
      return [];
    }

    const sequenceSourceVideoChaptersDict = keyBy("sid", sourceVideoChapters);
    const wordTimeIntervals: Array<Word> = [];
    sequenceCaptions?.words.forEach((word) => {
      const relatedSourceChapter = sequenceSourceVideoChaptersDict[word.chapterSid!]!;

      if (!relatedSourceChapter) {
        return;
      }

      const prevSourceVideoChapters = sourceVideoChapters.filter((chapter) => relatedSourceChapter.linkSid !== chapter.sid && chapter.index! < relatedSourceChapter.index!); // prettier-ignore
      const relatedSourceChapterOffset = sumBy("srcDuration", prevSourceVideoChapters);
      const shiftedWordInterval = timeIntervalUtils.shift(formatWord(word), relatedSourceChapterOffset);

      const isFiller = isFillerWord(word.word!, fillerWords[sequence.languageCode!]);
      const isHighlighted = word.highlight;

      wordTimeIntervals.push({
        kind: (() => {
          if (isFiller) return "fillerWord";
          if (isHighlighted) return "highlightedWord";
          return "word";
        })(),
        ...shiftedWordInterval,
        highlight: word.highlight,
        originalStart: word.startTime,
        originalEnd: word.endTime,
        chapterSid: word.chapterSid,
      });
    });

    return sortBy("start", wordTimeIntervals);
  },
);

const selectAbsoluteSequenceCaptions = createSelector(
  selectAbsoluteSequenceWords,
  (state, sequenceSid, appConfig, chapters: ChapterTimeInterval[]) => chapters,
  (absoluteWords, chapters) => {
    let previousWordAdded: WordTimeInterval | undefined;
    let currentWordChapter: ChapterTimeInterval | undefined;

    return (absoluteWords as Array<Word>).reduce<CaptionTimeInterval[]>(
      (accCaptionsList: CaptionTimeInterval[], word: WordTimeInterval, index: number) => {
        // normalize fraction digits to match chapter time format
        word.start = parseFloat(word.start.toFixed(3));
        word.end = parseFloat(word.end.toFixed(3));

        currentWordChapter = (chapters as ChapterTimeInterval[]).find((chapter: ChapterTimeInterval) =>
          timeIntervalUtils.containsTimeInterval(chapter, word),
        );

        if (currentWordChapter) {
          const isPreviousWordInSameChapter =
            index > 0 &&
            previousWordAdded &&
            timeIntervalUtils.containsTimeInterval(currentWordChapter, previousWordAdded);
          if (index === 0 || (absoluteWords as gqlWord[])[index - 1].newLine || !isPreviousWordInSameChapter) {
            // word to new line
            accCaptionsList.push({
              start: word.start,
              end: word.end,
              words: [word],
            });
          } else {
            // word to current line
            accCaptionsList[accCaptionsList.length - 1] = {
              ...accCaptionsList[accCaptionsList.length - 1],
              end: word.end,
              words: [...accCaptionsList[accCaptionsList.length - 1].words, word],
            };
          }
          previousWordAdded = word;
        }
        return accCaptionsList;
      },
      [],
    );
  },
);

const selectContentVersion = createSelector(
  sequenceCaptionsAdapterSelectors.selectById,
  (sequenceCaptions) => sequenceCaptions?.contentVersion,
);

const selectSyncWithTranscript = createSelector(
  sequenceCaptionsAdapterSelectors.selectById,
  (sequenceCaptions) => sequenceCaptions?.syncWithTranscript,
);

export const sequenceCaptionsSelectors = {
  ...sequenceCaptionsAdapterSelectors,
  selectAbsoluteSequenceCaptions,
  selectAbsoluteSequenceWords,
  selectContentVersion,
  selectSyncWithTranscript,
};

const sequenceCaptions = createModel<RootModel>()({
  state: sequenceCaptionsAdapter.getInitialState(),

  reducers: {
    setSequenceCaptions: (state, payload: SequenceCaptions) => sequenceCaptionsAdapter.setOne(state, payload),
  },

  effects: (dispatch) => ({
    async fetchSequenceCaptions(variables: GetSequenceCaptionsQueryVariables) {
      const words = await apiClient.getSequenceCaptions(variables);
      dispatch.sequenceCaptions.setSequenceCaptions({ sid: variables.sequenceSid, words });
    },
    async fetchV2SequenceCaptions(variables: GetV2SequenceCaptionsQueryVariables) {
      const cc = await apiClient.getSequenceCaptionsV2(variables);
      dispatch.sequenceCaptions.setSequenceCaptions({
        sid: variables.sequenceSid,
        syncWithTranscript: cc?.syncWithTranscript,
        words: sequenceCaptions.state.entities[variables.sequenceSid]?.words || [],
        contentVersion: cc?.contentVersion,
      });
      return cc;
    },
    async unsyncWithTranscript(variables: GetV2SequenceCaptionsQueryVariables) {
      const syncStatus = await apiClient.unsyncWithTranscript(variables);
      dispatch.sequenceCaptions.setSequenceCaptions({
        sid: variables.sequenceSid,
        syncWithTranscript: syncStatus,
        words: sequenceCaptions.state.entities[variables.sequenceSid]?.words || [],
        contentVersion: sequenceCaptions.state.entities[variables.sequenceSid]?.contentVersion,
      });
    },
    async regenerateSequenceCaptions(variables: RegenerateSequenceCaptionsMutationVariables) {
      const words = await apiClient.regenerateSequenceCaptions(variables);
      dispatch.sequenceCaptions.setSequenceCaptions({
        sid: variables.sequenceSid,
        syncWithTranscript: true,
        words: (words as gqlWord[]) || [],
        contentVersion: sequenceCaptions.state.entities[variables.sequenceSid]?.contentVersion,
      });
      return words;
    },
  }),
});

export default sequenceCaptions;
