import styled, { css, keyframes } from "styled-components/macro";
import { styleProp, themeColor } from "src/utils/styledComponents.utils";
import { useMemo } from "react";
import { switchProp } from "styled-tools";

const pump = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
`;

const BlockContainer = styled.div`
  display: flex;
  column-gap: 8px;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
`;

const Block = styled.div<{ size: number; align: string }>`
  background-color: ${themeColor("gray.300")};
  animation: ${pump} 1s infinite ease-in-out;
  border-radius: 4px;
  min-width: ${(props) => props.size * 10}px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Open Sans", sans-serif;
  margin-block: 6px;
  font-size: 18px;
  line-height: 12px;
  color: ${themeColor("gray.300")};
  ${switchProp(
    "align",
    {
      center: css`
        flex-grow: ${styleProp("size", "1")};
      `,
      left: css`
        flex-shrink: ${styleProp("size", "1")};
      `,
    },
    `
      flex-grow: ${styleProp("size", "1")};
    `,
  )}
`;

interface TextLoadingProps {
  minBlockSize?: number;
  maxBlockSize?: number;
  blocksCount?: number;
  align?: "center" | "left";
}

const generateRandomBlocks = (count: number, min: number, max: number) =>
  [...Array(count).keys()].map((key) => ({
    key,
    size: Math.floor(Math.random() * (max - min + 1)) + min,
  }));

export default function TextBlockLoading({
  blocksCount = 1,
  minBlockSize = 4,
  maxBlockSize = 12,
  align = "left",
}: TextLoadingProps) {
  const blocks = useMemo(
    () => generateRandomBlocks(blocksCount, minBlockSize, maxBlockSize),
    [blocksCount, minBlockSize, maxBlockSize],
  );

  return (
    <BlockContainer>
      {blocks.map((block) => (
        <Block align={align} size={block.size} key={block.key}>
          .
        </Block>
      ))}
    </BlockContainer>
  );
}
