import {
  BluredPlug,
  ExpireBlock,
  ExpireBlockWrapper,
  ProgressContainer,
  RedirectToPlanButton,
  StyledExpiredTooltip,
} from "src/components/features/SmartLibrary/SmartLibrary.styled";
import { AspectRatio } from "src/constants/video.constants";
import FootageDurationUploadLimitExplanation from "src/components/features/SmartLibrary/footagesLibrary/FootageDurationUploadLimitExplanation";
import Icon from "src/components/common/Icon";

interface MaxUploadDurationReachedProps {
  userPlan: any;
  onUpgradePlanButtonClick: (toNextClosestPlan: boolean) => void;
  tooltipsTrigger: "hover" | "click";
}

export function MaxUploadDurationReached({
  userPlan,
  onUpgradePlanButtonClick,
  tooltipsTrigger,
}: MaxUploadDurationReachedProps) {
  return (
    <>
      <BluredPlug />
      <ProgressContainer aspectRatio={"16:9" as AspectRatio}>
        <span className="label">Video duration is too long</span>
        <RedirectToPlanButton onClick={() => onUpgradePlanButtonClick(false)} isBig>
          Upgrade to unlock
        </RedirectToPlanButton>
      </ProgressContainer>
      <ExpireBlockWrapper
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <StyledExpiredTooltip
          text={
            <FootageDurationUploadLimitExplanation
              durationUploadLimit={userPlan?.maxFootageUploadMinutesDuration || 0}
            />
          }
          placement="top-start"
          trigger={tooltipsTrigger}
        >
          <ExpireBlock>
            <Icon.InfoCircle style={{ marginLeft: 0 }} />
          </ExpireBlock>
        </StyledExpiredTooltip>
      </ExpireBlockWrapper>
    </>
  );
}
