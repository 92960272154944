import * as yup from "yup";

export const password = () =>
  yup
    .string()
    .required("please enter a password")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/,
      "Password must contain minimum 8 characters, at least one letter and one number",
    );

export const passwordVerification = () => yup.string().equals([yup.ref("password")], "passwords are not the same");

export const email = yup
  .string()
  .email("please enter a valid email address")
  .required("please enter your email address");
