import { components, MenuProps } from "react-select";
import styled from "styled-components/macro";
import { BoardOption } from "src/components/features/Boards/BoardSelectOption";
import Icon from "src/components/common/Icon";
import theme from "src/theme";
import { useModal } from "src/components/providers/ModalProvider";
import { useCallback } from "react";
import EntityTitleModal from "src/components/modals/EntityTitleModal";
import { useDispatch } from "react-redux";
import { isMobile } from "src/utils/mobile.utils";
import * as boardsAnalytics from "src/analytics/boards.analytics";
import { useNavigate } from "react-router-dom";
import { absoluteRoutes, applyPathParams } from "src/utils/routes.utils";

const AddButton = styled.div`
  display: flex;
  padding-inline: 6px;
  flex-direction: row;
  justify-content: flex-start;
  border-top: 1px solid ${theme.colors.gray["225"]};
  position: relative;
  bottom: 0;
  width: 100%;
  height: 38px;
  background-color: white;
  align-items: center;
  font-size: 14px;
  cursor: pointer !important;
  font-family: "Open Sans";
  color: ${theme.colors.gray["900"]};

  svg {
    path {
      transition: fill 0.3s ease-in-out;
    }
  }

  &:hover {
    color: ${theme.colors.blue["500"]};
    transition: all 0.3s ease-in-out;
    svg {
      path {
        fill: ${theme.colors.blue["500"]};
      }
    }
  }

  transition: color 0.3s ease-in-out;

  input {
    width: 100%;
    height: 100%;
    border: solid red 1px;
  }
`;

const StyledMenu = styled(components.Menu)`
  inset: auto auto auto ${isMobile() ? "-100px" : "auto"};
  transition: all 0.3s ease-in-out;

  &.select__menu {
    border-radius: 8px;
    border: unset;
    box-shadow: 0px 4px 16px 0px rgba(4, 27, 63, 0.2) !important;
    padding: 0px;
    width: 300px;
  }

  @media (min-height: 600px) {
    .select__menu-list {
      overflow-y: auto;
      max-height: 208px !important;
    }
  }
` as any as typeof components.Menu;

const IconWrapper = styled.div`
  margin-left: 2px;
  margin-right: 4px;
  margin-top: 1px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    path: ${theme.colors.gray["900"]};
  }
  img {
    width: 100%;
    object-fit: contain;
  }
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export default function BoardSelectMenu({ children, ...props }: MenuProps<BoardOption>) {
  const { openModal, closeModal } = useModal();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onConfirm = useCallback(
    async (name: string) => {
      const boardId = await dispatch.boards.createBoard(name);
      navigate(applyPathParams(absoluteRoutes.platform.children.boards, { boardId } as any));
      boardsAnalytics.trackCreateBoard(name, "board-select");
    },
    [dispatch.boards, navigate],
  );

  const showCreateModal = useCallback(() => {
    openModal(
      () => (
        <EntityTitleModal
          {...props}
          onSubmit={onConfirm}
          close={closeModal}
          modalTitle="Create a new board"
          defaultPlaceHolder="Enter board name"
          submitLabel="Create"
        />
      ),
      { hideCloseButton: true },
    );
  }, [closeModal, onConfirm, openModal, props]);

  const onAddBoardClick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    (document.activeElement as HTMLInputElement)?.blur?.();
    showCreateModal();
  };

  return (
    <StyledMenu {...props}>
      <ListWrapper>{children}</ListWrapper>
      <AddButton onClick={onAddBoardClick}>
        <IconWrapper>
          <Icon.Plus color="gray.900" />
        </IconWrapper>
        Create board
      </AddButton>
    </StyledMenu>
  );
}
