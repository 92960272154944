import styled from "styled-components/macro";

import { ifProp, themeColor } from "src/utils/styledComponents.utils";

import { AspectRatio, getAspectRatioValue } from "src/constants/video.constants";

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const FootageName = styled.span`
  font-weight: 600;
  font-size: 24px;
`;

export const LibHeader = styled.div`
  font-family: "Work Sans";
  font-style: normal;
  line-height: 24px;
  color: ${themeColor("gray.500")};
  padding: 26px 34px 16px 34px;
  width: 100%;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  svg {
    width: 30px;
    transform: rotate(270deg);
    fill: ${themeColor("gray.500")};
    margin-bottom: 3px;
  }
`;

export const LibBody = styled.div`
  width: 100%;
  height: calc(100vh - 70px);
  overflow-y: auto;
`;

export const WebItemFooter = styled.div<{ numRows: number }>`
  width: 100%;
  min-height: 30px;
  span {
    min-height: ${(props) => (props.numRows > 1 ? "34px" : "20px")};
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 5px 10px;
    color: ${themeColor("gray.500")};
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    white-space: ${(props) => (props.numRows > 1 ? "initial" : "pre-wrap")};

    @supports (-webkit-line-clamp: 2) {
      -webkit-line-clamp: ${(props) => props.numRows};
      -webkit-box-orient: vertical;
    }
  }
`;

export const ItemContent = styled.div<{ aspectRatio: string; shouldGrayscale?: boolean }>`
  position: relative;
  width: 100%;
  aspect-ratio: ${(props) => getAspectRatioValue(props.aspectRatio as AspectRatio)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px 8px 0 0;
  cursor: pointer;

  img {
    width: 100%;
    aspect-ratio: ${(props) => getAspectRatioValue(props.aspectRatio as AspectRatio)};
    border-radius: 8px 8px 0 0;
    filter: ${ifProp("shouldGrayscale", "grayscale(100%)", "none")};
  }
`;

export const PopMenuButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  width: fit-content;
  height: fit-content;
  padding: 6px;
`;

export const Duration = styled.div`
  position: absolute;
  bottom: 6px;
  left: 6px;
  border-radius: 3px;
  background-color: ${themeColor("gray.500")};
  color: ${themeColor("gray.50")};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  padding-inline: 4px;
`;

export const ItemPlug = styled.div<{ aspectRatio: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  aspect-ratio: ${(props) => getAspectRatioValue(props.aspectRatio as AspectRatio)};
  border-radius: 8px 8px 0 0;
  color: ${themeColor("white")};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
`;

export const ItemBluredPlug = styled(ItemPlug)`
  background-color: ${themeColor("black")};
  opacity: 0.5;
`;

export const ItemHoverPlug = styled(ItemPlug)`
  visibility: hidden;
  background-color: ${themeColor("black")};
  opacity: 0;
`;

export const ItemHoverPlugWithMenu = styled(ItemPlug)`
  visibility: hidden;
  background-color: transparent;
  opacity: 0;
  color: ${themeColor("white")};
`;

export const WebItemContentWrapper = styled.div`
  width: 100%;
  height: fit-content;

  &:hover {
    ${ItemHoverPlug} {
      visibility: visible;
      opacity: 0.5;
      transition: opacity 650ms ease-out;
    }
    ${ItemHoverPlugWithMenu} {
      visibility: visible;
      opacity: 1;
      transition: opacity 650ms ease-out;
    }
  }
`;
