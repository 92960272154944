import { Children, createRef, RefObject, useRef } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import useResizeObserver from "use-resize-observer";
import styled, { css } from "styled-components/macro";
import Color from "color";

import { styleProp, themeColor, themeSpacing } from "src/utils/styledComponents.utils";

import Icon from "src/components/common/Icon";
import Popper from "src/components/common/popovers/Popper";
import ColorPicker from "src/components/common/form/inputs/ColorPicker/ColorPicker";
import { Palette } from "src/components/common/form/inputs/ColorPicker/SwatchPalette";
import { Box } from "src/components/common/layout/Box.styled";
import { StackProps, stackStyle } from "src/components/common/layout/Stack.styled";
import ColorStackItem, { ColorSquare } from "src/components/features/Onboarding/steps/BrandStep/ColorStackItem";
import { StyledTooltip } from "src/components/features/SmartLibrary/SmartLibrary.styled";
import { ifProp } from "styled-tools";
import { AppearAnimatedDiv } from "src/components/features/AutomaticCreateWizard/common/automatic-create-wizard-layout.styled";

const Container = styled(Box)`
  position: relative;
  display: flex;
  width: fit-content;
  overflow: auto;
`;

const StyledTransitionGroup = styled(TransitionGroup)<
  StackProps & { $itemDuration: number; $itemDelay: number; delay: number }
>`
  display: flex;
  ${stackStyle}

  .item-appear {
    scale: 0.6;
    opacity: 0;
  }

  .item-appear-active {
    scale: 1;
    opacity: 1;

    ${({ $itemDuration, $itemDelay, delay, children }) =>
      Children.map(
        children,
        (child, i) => css`
          &:nth-child(${i + 1}) {
            transition: scale ${$itemDuration}ms ${delay + i * $itemDelay}ms ease-out,
              opacity 0ms ${delay + i * $itemDelay}ms;
          }
        `,
      )}
  }

  .item-enter {
    scale: 0.6;
  }

  .item-enter-active {
    scale: 1;
    transition: scale ${styleProp("$itemDuration")}ms ease-out;
  }

  .item-exit {
    scale: 1;
  }

  .item-exit-active {
    scale: 0;
    transition: scale ${styleProp("$itemDuration")}ms ease-out;
  }
`;

const StyledTransitionGroupMobile = styled(StyledTransitionGroup)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 12px;
  column-gap: 10px;
  padding-bottom: 20px;

  @media (max-width: 475px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 900px) {
    grid-template-columns: repeat(7, 1fr);
  }
`;

const BrandColorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 114px;
`;

const AddColorButtonContainer = styled(BrandColorContainer)<{ left?: number }>`
  position: absolute;
  left: ${styleProp("left", 0)}px;
  margin-left: ${themeSpacing(10)};
  transition: left 250ms ease;
`;

const AddColorButton = styled(ColorSquare)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed ${themeColor("blue.200")};
  background-color: ${themeColor("blue.30")};
  cursor: pointer;
`;

const ColorStackItemPlaceholder = styled(ColorStackItem).attrs({ color: "#fff" })`
  visibility: hidden;
`;

const UpdateColorsButtonWrapper = styled(AppearAnimatedDiv)<{ hideIfMobile?: boolean }>`
  width: 124px;
  height: 70px;
  display: ${ifProp("hideIfMobile", "none", "flex")};
  justify-content: center;
  align-items: center;
  opacity: 0;

  @media (min-width: 900px) {
    display: flex;
  }
`;

const DefaultLogoColorsGenerator = styled.button`
  cursor: pointer;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  color: ${themeColor("gray.900")};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: unset;
  border: unset;
  width: 42px;
  height: 42px;
  border-radius: 8px;
  background: ${themeColor("white")};
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);

  &:active {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  }
`;

interface BaseColorItem {
  color: string;
}

interface ColorStackProps<ColorItem extends BaseColorItem> {
  colorItems?: ColorItem[];
  onColorUpdate(colorIndex: number, newValue: Color): void;
  onAddNewColor(): void;
  getColorItemId(item: ColorItem): string | number;
  transitionDelayBase: number;
  colorItemTransitionDuration: number;
  colorItemTransitionDelay: number;
  allowAdding: boolean;
  palettes: Palette[];
  regenerateCallback?: () => void;
}

export default function ColorStack<T extends BaseColorItem>({
  colorItems,
  onColorUpdate,
  onAddNewColor,
  getColorItemId,
  transitionDelayBase,
  colorItemTransitionDuration,
  colorItemTransitionDelay,
  allowAdding,
  palettes,
  regenerateCallback,
}: ColorStackProps<T>) {
  const colorStackContainerRef = useRef<HTMLDivElement>(null);
  const { width: colorStackContainerWidth } = useResizeObserver({ ref: colorStackContainerRef });
  const colorNodeRefs = useRef<Array<RefObject<HTMLDivElement>>>([]);

  if (!colorItems?.length) {
    return <ColorStackItemPlaceholder />;
  }

  return (
    <Container ref={colorStackContainerRef}>
      <StyledTransitionGroupMobile
        appear
        enter
        exit
        direction="row"
        spacing={10}
        $itemDuration={colorItemTransitionDuration}
        $itemDelay={colorItemTransitionDelay}
        delay={transitionDelayBase}
      >
        {regenerateCallback && <UpdateColorsButtonWrapper hideIfMobile />}
        {colorItems.map((colorItem, colorIndex) => {
          if (!colorNodeRefs.current[colorIndex]) {
            colorNodeRefs.current[colorIndex] = createRef<HTMLDivElement>();
          }

          const transitionDuration =
            transitionDelayBase + (colorItems.length - 1) * colorItemTransitionDelay + colorItemTransitionDuration;

          return (
            <CSSTransition
              // eslint-disable-next-line react/no-array-index-key
              key={getColorItemId(colorItem)}
              nodeRef={colorNodeRefs.current[colorIndex]}
              timeout={transitionDuration}
              classNames="item"
            >
              <div ref={colorNodeRefs.current[colorIndex]}>
                <Popper
                  offsetY={10}
                  placement="top"
                  content={
                    <ColorPicker
                      color={Color(colorItem.color)}
                      onChange={(newColor) => onColorUpdate(colorIndex, newColor)}
                      palettes={palettes}
                    />
                  }
                >
                  <ColorStackItem color={colorItem.color} />
                </Popper>
              </div>
            </CSSTransition>
          );
        })}
        {regenerateCallback && (
          <StyledTooltip text="Generate from Logo" placement="bottom">
            <UpdateColorsButtonWrapper>
              <DefaultLogoColorsGenerator onClick={regenerateCallback}>
                <Icon.Reload />
              </DefaultLogoColorsGenerator>
            </UpdateColorsButtonWrapper>
          </StyledTooltip>
        )}
      </StyledTransitionGroupMobile>

      {allowAdding && !!colorStackContainerWidth && (
        <AddColorButtonContainer left={colorStackContainerWidth}>
          <AddColorButton onClick={onAddNewColor}>
            <Icon.PlusSmall color="blue.200" />
          </AddColorButton>
        </AddColorButtonContainer>
      )}
    </Container>
  );
}
