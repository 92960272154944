import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as storageAnalytics from "src/analytics/storage.analytics";
import { useAppConfig } from "src/components/providers/AppConfigProvider";
import { USE_LOCAL_UPLOADER } from "src/constants/env.constants";
import { Dispatch, RootState } from "src/models/store";
import apiClient from "src/network/ApiClient";

import useBeforeUnloadBlocker from "src/hooks/useBeforeUnloadBlocker";
import useShallowMemo from "src/hooks/useShallowMemo";

import BaseUploadService, { FileUploadStatus, MEGA_BYTE } from "src/services/BaseUpload.service";
import UploadToGcsService from "src/services/UploadToGcs.service";
import UploadToLocalService from "src/services/UploadToLocal.service";
import UploadToPeechService from "src/services/UploadToPeech.service";
import { getAdaptiveChunkSize } from "src/utils/uploader.utils";

import * as footageAnalytics from "src/analytics/footage.analytics";
import { Footage, FootageStatus } from "src/network/graphql/generatedGraphqlSDK";
import { planSelectors } from "src/models/Plan.model";
import { footageSelectors } from "src/models/Footage.model";
import UploadToYoutubeService from "src/services/UploadToYoutubeService";
import { YoutubeItem } from "src/types/youtube-api.types";

export enum UploaderTypes {
  FOOTAGE = "footage",
  ASSET = "asset",
  YOUTUBE = "youtube",
}

interface UploadContextData {
  createUploader: (id: string, file: File, type: UploaderTypes, option?: object) => BaseUploadService;
  getUploader: (id: string) => BaseUploadService | undefined;
  deleteUploader: (id: string) => void;
  uploadsLimitReached: boolean;
  amountOfUsedUploading: number;
  amountOfUploadsAvailable: number | null;
  maxFootageUploads: number | null | undefined;
  isUploading: boolean;
  setShouldIgnoreCheckBeforeUnload: (val: boolean) => void;
}

export const UploaderProviderContext = createContext<UploadContextData | null>(null);

export default function UploaderProvider({ children }: PropsWithChildren) {
  const dispatch = useDispatch<Dispatch>();
  const { INGEST_URL } = useAppConfig();
  const authToken = useSelector((state: RootState) => state.session.authToken);
  const uploaderDictionary = useRef(new Map<string, BaseUploadService>());
  const [isUploading, setIsUploading] = useState(false);
  const [shouldIgnoreCheckBeforeUnload, setShouldIgnoreCheckBeforeUnload] = useState(false);
  const usersSubscription = useSelector((state: RootState) => state.session.subscription);
  const usersPlan = useSelector(planSelectors.selectUsersPlan);
  const maxFootageUploads = usersPlan?.maxFootageUploads || 0; // if not set set to 0 no limit
  const footageUploadsCount = usersSubscription?.footageUploadsCount;
  const [uploaderFootagesIds, setUploaderFootagesIds] = useState<string[]>([]);
  const footagesInUploading = useSelector((state: RootState) =>
    footageSelectors.selectFootagesByIds(state, uploaderFootagesIds),
  );

  const activeUploaderAmount = useMemo(() => uploaderFootagesIds.length, [uploaderFootagesIds]);

  const uploadsLimitReached = useMemo(() => {
    if (maxFootageUploads > 0) {
      return (footageUploadsCount || 0) + activeUploaderAmount >= maxFootageUploads;
    }
    return false;
  }, [activeUploaderAmount, footageUploadsCount, maxFootageUploads]);

  const amountOfUsedUploading = useMemo(
    () => (footageUploadsCount as number) + activeUploaderAmount,
    [activeUploaderAmount, footageUploadsCount],
  );

  const amountOfUploadsAvailable = useMemo(() => {
    if (!maxFootageUploads) {
      return null;
    }
    return maxFootageUploads - amountOfUsedUploading;
  }, [amountOfUsedUploading, maxFootageUploads]);

  const onStatusChanged = () => {
    const isInProgress = [...uploaderDictionary.current.values()].some((item) =>
      [FileUploadStatus.PENDING, FileUploadStatus.UPLOADING].includes(item.status),
    );

    setIsUploading(isInProgress);
  };

  const createUploader = useCallback(
    async (id: string, file: File, type: UploaderTypes, option: object) => {
      // create uploader
      let uploader;

      switch (true) {
        case type === UploaderTypes.ASSET:
          uploader = new UploadToPeechService(file, {
            assetId: id,
            authToken,
            uploadUrl: `${INGEST_URL}/asset`,
          });

          break;

        case type === UploaderTypes.YOUTUBE:
          uploader = new UploadToYoutubeService(file, option as YoutubeItem);

          break;

        case USE_LOCAL_UPLOADER:
          uploader = new UploadToLocalService(file, {
            footageId: id,
            authToken,
            uploadUrl: `${INGEST_URL}/local-footage-upload`,
          });
          break;

        default: {
          const signedUrl = await apiClient.generateFootageUploaderUrl({ id });
          const chunkSize = getAdaptiveChunkSize(file.size) || MEGA_BYTE * 5; // 5MB
          uploader = new UploadToGcsService(file, {
            signedUrl,
            chunkSize,
          });
          // track uploader events
          uploader.on("start", () => {
            footageAnalytics.trackFootageUploadStart({
              id,
            } as Footage);
          });

          uploader.on("success", () => {
            footageAnalytics.trackFootageUploadSuccess({
              id,
            } as Footage);
          });

          break;
        }
      }

      uploader.on("statusChange", onStatusChanged);
      uploaderDictionary.current.set(id, uploader);
      // eslint-disable-next-line no-debugger
      if (maxFootageUploads) {
        setUploaderFootagesIds((prevState) => [...prevState, id]);
      }

      storageAnalytics.trackUploadingStarts(file);

      return uploader;
    },
    [INGEST_URL, authToken, maxFootageUploads],
  );

  const getUploader = useCallback((id: string) => uploaderDictionary.current.get(id), []);

  const deleteUploader = useCallback(
    (id: string) => {
      uploaderDictionary.current.delete(id);
      setUploaderFootagesIds(uploaderFootagesIds.filter((x) => x !== id));
    },
    [uploaderFootagesIds],
  );

  // used when we have maxFootageUploads to limit user and we want to delete uploader when it's status is IngestingFootage
  useEffect(() => {
    // if maxFootageUploads === 0 no limit
    if (!maxFootageUploads) {
      return;
    }

    let shouldFetchSubscription: boolean = false;

    footagesInUploading.forEach((footage) => {
      if (footage.status === FootageStatus.IngestingFootage) {
        deleteUploader(footage.id);
        shouldFetchSubscription = true;
      }
    });

    shouldFetchSubscription && dispatch.session.getSubscription();
  }, [deleteUploader, dispatch.session, footagesInUploading, uploaderFootagesIds, maxFootageUploads]);

  const contextValue = useShallowMemo({
    createUploader,
    getUploader,
    deleteUploader,
    uploadsLimitReached,
    amountOfUsedUploading,
    amountOfUploadsAvailable,
    maxFootageUploads,
    isUploading,
    setShouldIgnoreCheckBeforeUnload,
  });

  useBeforeUnloadBlocker(isUploading && !shouldIgnoreCheckBeforeUnload);

  return <UploaderProviderContext.Provider value={contextValue}>{children}</UploaderProviderContext.Provider>;
}

export const useUploaderContext = () => {
  const contextValue = useContext(UploaderProviderContext);

  if (!contextValue) {
    throw new Error("useUploaderContext was used outside of UploaderProvider");
  }

  return contextValue;
};
