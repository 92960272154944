import analyticsService from "src/services/Analytics.service";

export const trackZoomIn = (
  trigger: "zoom-slider" | "pinch-zoom" | "zoom-in-button" | "chapter-double-click" | "crop-double-click",
) => analyticsService.track("timeline-control", { action: "zoom-in", trigger });

export const trackZoomOut = (trigger: "zoom-slider" | "pinch-zoom" | "zoom-out-button") =>
  analyticsService.track("timeline-control", { action: "zoom-out", trigger });

export const trackResetZoom = () =>
  analyticsService.track("timeline-control", { action: "reset-zoom", trigger: "reset-zoom-button" });

export const trackTimelineScroll = () =>
  analyticsService.track("timeline-control", { action: "timeline-scroll", trigger: "zoom-slider" });

export const trackPlay = () => analyticsService.track("timeline-control", { action: "play", trigger: "play-button" });

export const trackPause = () =>
  analyticsService.track("timeline-control", { action: "pause", trigger: "pause-button" });

export const trackPlaybackSpeedChange = () =>
  analyticsService.track("timeline-control", { action: "playback-rate", trigger: "playback-rate-button" });
