import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { css, keyframes } from "styled-components/macro";
import { range } from "lodash/fp";

import { ifNotProp, themeColor, themeProp } from "src/utils/styledComponents.utils";
import { useGetAssetImageURL } from "src/hooks/useGetAssetURL";

import { getAspectRatioValue } from "src/constants/video.constants";
import { FolderType, AssetType } from "src/constants/model.constants";

import { Dispatch, RootState } from "src/models/store";
import { folderStructureSelectors } from "src/models/FolderStructure.model";

import { useSwiperContext } from "src/components/common/Swiper/Swiper";
import { useSwiperInnerScrolling } from "src/components/common/Swiper/hooks/useSwiperInnerScrolling";
import { Text3, Text1Thin } from "src/components/common/layout/typography.styled";
import { Box, InlineBox } from "src/components/common/layout/Box.styled";
import VisualPackageLottiePreview from "src/components/features/Onboarding/VisualPackageLottiePreview";
import { useOnboardingFormContext } from "src/components/features/Onboarding/onboardingForm";
import { RadioButton } from "src/components/common/form/inputs/RadioButton.styled";
import { OnboardingStepTitle } from "src/components/features/Onboarding/onboarding.styled";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100%;
  flex: 1;
  padding-top: 80px;
  padding-right: 50px;
  padding-bottom: 100px;
`;

const GridScroll = styled(Box)`
  overflow-y: auto;
  flex: 1;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 12px;
  column-gap: 15px;
`;

const pump = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
`;

const VisualPackageContainer = styled.label`
  display: block;
  border: 2px solid ${themeColor("gray.200")};
  border-radius: ${themeProp("borderRadii.large")}px;
  overflow: hidden;
  cursor: pointer;
`;

const VisualPackageCover = styled.div<{ isLoaded: boolean }>`
  width: 100%;
  aspect-ratio: ${getAspectRatioValue("16:9")};
  background-color: ${themeColor("gray.300")};

  ${ifNotProp(
    "isLoaded",
    css`
      animation: ${pump} 1s infinite ease-in-out;
    `,
  )}
`;

const VisualPackageFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 8px;
  border-top: 2px solid ${themeColor("gray.200")};
`;

export default function VisualPackageStep() {
  const { isSliding: isSwiperSliding } = useSwiperContext();
  const dispatch = useDispatch<Dispatch>();
  const { setValue, watch, register } = useOnboardingFormContext();
  const getAssetImageURL = useGetAssetImageURL();

  const logoAssetId = watch("brand.logo.assetId");
  const logoURL = logoAssetId && getAssetImageURL(logoAssetId);
  const visualPackageId = watch("visuals.visualPackageId");

  const packages = useSelector((state: RootState) =>
    folderStructureSelectors.selectVisualPackages(state, FolderType.LottiePackage, AssetType.LottiePackagePreview),
  );
  const isLoadingVisualPackages = useSelector(
    ({ loading: { models } }: RootState) => models.assets.loading || models.folderStructures.loading,
  );

  const setVisualPackageId = useCallback(
    (value: string) => {
      setValue("visuals.visualPackageId", value, { shouldValidate: true });
    },
    [setValue],
  );

  useEffect(() => {
    if (!packages.length && !isLoadingVisualPackages) {
      dispatch.folderStructures.fetchFolderStructuresByType(FolderType.LottiePackage);
      dispatch.assets.fetchAssets({ filter: { typeIn: [AssetType.LottiePackagePreview] } });
    }
  }, [dispatch.assets, dispatch.folderStructures, isLoadingVisualPackages, packages.length]);

  useEffect(() => {
    if (packages.length && !visualPackageId) {
      setVisualPackageId(packages[0].folder.sid!);
    }
  }, [packages, visualPackageId, setVisualPackageId]);

  return (
    <Container>
      <OnboardingStepTitle>
        Peech highlights key messages with visuals.
        <br />
        <strong>Pick a style for branded videos.</strong>
      </OnboardingStepTitle>

      <GridScroll marginTop={40} {...useSwiperInnerScrolling()}>
        <Grid>
          {packages?.length && !isSwiperSliding
            ? packages.map((currentVisualPackage) => (
                <VisualPackageContainer key={currentVisualPackage.folder.sid}>
                  <VisualPackageLottiePreview
                    assetId={currentVisualPackage.asset.sid!}
                    assetVersion={currentVisualPackage.asset.version!}
                    // TODO: wait for backend impl
                    // assetId={selectedVisualPackage?.assets[AspectRatio["16:9"]].sid!}
                    // contentId={selectedVisualPackage?.assets[AspectRatio["16:9"]].contentIdentifier?.thumbnailVersion!}
                    logoURL={logoURL!}
                    initialStartTime={2500}
                    playOnHover
                    loop
                  />

                  <VisualPackageFooter>
                    <Text3>{currentVisualPackage.folder.name}</Text3>
                    <RadioButton value={currentVisualPackage.folder.sid!} {...register("visuals.visualPackageId")} />
                  </VisualPackageFooter>
                </VisualPackageContainer>
              ))
            : range(0, 6).map((i) => (
                <VisualPackageContainer key={i}>
                  <VisualPackageCover key={i} isLoaded={false} />

                  <VisualPackageFooter>
                    <Text3>&nbsp;</Text3>
                  </VisualPackageFooter>
                </VisualPackageContainer>
              ))}
        </Grid>
      </GridScroll>

      <Text1Thin marginTop={20}>
        <InlineBox marginInline={10}>•</InlineBox>
        You can always change this later
      </Text1Thin>
    </Container>
  );
}
