import styled from "styled-components/macro";

import { themeColor, themeSpacing } from "src/utils/styledComponents.utils";

import { Text4, Text4Light } from "src/components/common/layout/typography.styled";
import { Center } from "src/components/common/layout/Box.styled";
import { Button } from "src/components/common/buttons/Button.styled";
import { Stack } from "src/components/common/layout/Stack.styled";

export const Container = styled(Center)`
  padding-inline: 20px;
  background-color: ${themeColor("gray.50")};
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 320px;

  @media (max-width: 768px) {
    width: 280px;
  }
`;

export const CenterContentContainer = styled(Center)`
  text-align: center;
`;

export const Divider = styled(Text4).attrs({ children: "Or with your e-mail" })`
  display: flex;
  align-items: center;

  &:before,
  &:after {
    display: block;
    content: "";
    flex: 1;
    height: 1px;
    margin-inline: ${themeSpacing(12)};
    background-color: ${themeColor("gray.300")};
  }

  &:before {
    margin-left: 0;
  }

  &:after {
    margin-right: 0;
  }
`;

export const FormFieldStack = styled(Stack).attrs({
  direction: "column",
  spacing: 16,
})`
  margin-top: ${themeSpacing(55)};
  margin-bottom: ${themeSpacing(30)};
`;

export const SubmitButton = styled(Button).attrs({
  type: "submit",
  variant: "primary",
  size: "medium",
})`
  width: 100%;
  margin-top: ${themeSpacing(40)};
`;

export const AlternativeAuthButton = styled(Button).attrs({ variant: "link2", size: "small" })`
  font-weight: 600;
`;

export const AlternativeAuthLabel = styled(Text4Light)`
  margin-top: ${themeSpacing(14)};
  text-align: center;
`;
