import { forEach } from "lodash";

export const getQuery = (query: string, key: string): string | null => {
  const searchParams = new URLSearchParams(query);
  return searchParams.get(key);
};

export const getQueries = (query: string, keys: string[]): (string | null)[] => {
  const searchParams = new URLSearchParams(query);
  return keys.map((key) => searchParams.get(key));
};

export const setQuery = (query: string, values: object): string => {
  const searchParams = new URLSearchParams(query);
  forEach(values, (value, key) => {
    searchParams.set(key, value);
  });
  return searchParams.toString();
};

export const removeQuery = (query: string, key: string): string => {
  const searchParams = new URLSearchParams(query);
  searchParams.delete(key);
  return searchParams.toString();
};

export const removeQueries = (query: string, keys: string[]): string => {
  const searchParams = new URLSearchParams(query);
  keys.forEach((key) => {
    searchParams.delete(key);
  });
  return searchParams.toString();
};

export const toURL = (url: string): URL => {
  if (/^https?:\/\//g.test(url)) {
    return new URL(url);
  }
  return new URL(`https://${url}`);
};
