import { useCallback, useRef } from "react";
import { AnimationItem } from "lottie-web";

import Lottie, { LottieProps } from "src/components/common/media/Lottie";

export interface ExtendedLottieProps extends LottieProps {
  initialStartTime?: number;
  playOnHover?: boolean;
  loopDelay?: number;
}

export default function ExtendedLottie({
  initialStartTime = 0,
  playOnHover,
  loop,
  loopDelay,
  onLoad,
  autoplay,
  className,
  ...props
}: ExtendedLottieProps) {
  const animationRef = useRef<AnimationItem | null>(null);

  const onLottieLoad = useCallback(
    (animationItem: AnimationItem) => {
      if (autoplay) {
        animationItem.goToAndPlay(initialStartTime);
      } else {
        animationItem.goToAndStop(initialStartTime);
      }

      if (loop) {
        animationItem.addEventListener("complete", () => {
          const replay = () => animationItem.goToAndPlay(initialStartTime);

          if (loopDelay) {
            setTimeout(replay, loopDelay);
          } else {
            replay();
          }
        });
      }

      onLoad?.(animationItem);
      animationRef.current = animationItem;
    },
    [autoplay, initialStartTime, loop, loopDelay, onLoad],
  );

  const onMouseEnter = useCallback(() => playOnHover && animationRef.current?.play(), [playOnHover]);

  const onMouseLeave = useCallback(() => {
    if (playOnHover) {
      animationRef.current?.pause();
      animationRef.current?.goToAndStop(initialStartTime);
    }
  }, [initialStartTime, playOnHover]);

  return (
    <div className={className} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Lottie onLoad={onLottieLoad} autoplay={false} loop={false} {...props} />
    </div>
  );
}
