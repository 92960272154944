import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

/** this hook removes from `location` the @paramName search param,
 *  stores it in `location.state` & returns it's value */
export default function useSecuredSearchParam(paramName: string) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const paramValue = searchParams.get(paramName);

    if (paramValue) {
      searchParams.delete(paramName);

      navigate(
        {
          search: searchParams.toString(),
        },
        {
          replace: true,
          state: {
            ...location.state,
            [paramName]: paramValue,
          },
        },
      );
    }
  }, [navigate, location, paramName]);

  return location.state?.[paramName] ?? new URLSearchParams(location.search).get(paramName);
}
