import { isNil } from "lodash/fp";
import { Plan } from "src/network/graphql/generatedGraphqlSDK";

export const getPlanPrice = (plan: Plan, priceType: "yearly" | "monthly") => {
  if (priceType === "yearly") {
    switch (true) {
      case !isNil(plan.pricePerSeatAnnually):
        return plan.pricePerSeatAnnually;
      case !isNil(plan.annualOverrideRecurringPrice):
        return (plan.annualOverrideRecurringPrice ?? 0) / 12;
      case !isNil(plan.annualRecurringPrice):
        return (plan.annualRecurringPrice ?? 0) / 12;
      default:
        return 0;
    }
  } else {
    switch (true) {
      case !isNil(plan.pricePerSeatMonthly):
        return plan.pricePerSeatMonthly;
      case !isNil(plan.overrideRecurringPrice):
        return plan.overrideRecurringPrice;
      case !isNil(plan.recurringPrice):
        return plan.recurringPrice;
      default:
        return 0;
    }
  }
};
