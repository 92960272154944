import { KeyboardEvent } from "react";

/* eslint-disable */
export const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
  const pattern = /[A-Za-z0-9\ \-\_\/]/gi;
  var key = e.key;

  if (!pattern.test(key)) {
    e.preventDefault();
  }
  if (e.key === "Enter" || e.key === "Escape") {
    e.preventDefault();
    (e.target as HTMLElement).blur();
  }
};
