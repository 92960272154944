import React from "react";
import { v4 as uuidv4 } from "uuid";

const eventListener = new Map();
const eventCallbackCache = new Map();
export const useEventSubscriber = (event: string, callback: unknown) => {
  // state that tracks if this is the first time the component was created.
  // Enforces rules where events are not duplicated.

  // eslint-disable-next-line
  const [componentId, setComponentId] = React.useState(uuidv4());

  // Cleans up component after it unmounts. So hanging callbacks don't get called for
  // components that no longer exist
  React.useEffect(
    () => () => {
      eventListener.get(event).delete(eventCallbackCache.get(componentId));
      eventCallbackCache.delete(componentId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // Check if the function has been called previously
  if (!eventCallbackCache.has(componentId)) {
    if (!eventListener.has(event)) {
      const callbackSet = new Set();
      callbackSet.add(callback);
      eventListener.set(event, callbackSet);
    } else {
      eventListener.get(event).add(callback);
    }
    eventCallbackCache.set(componentId, callback);
  } else {
    eventListener.get(event).delete(eventCallbackCache.get(componentId));
    eventListener.get(event).add(callback);
    eventCallbackCache.set(componentId, callback);
  }

  return {
    unsubscribe: () => {
      // @ts-ignore
      eventListener[event].delete(callback);
      eventCallbackCache.delete(componentId);
    },
  };
};

export const useEventPublisher = () => (event: string, payload?: any) => {
  if (eventListener.get(event) !== undefined) {
    eventListener.get(event).forEach((cb: any) => {
      cb(payload);
    });
  }
};
