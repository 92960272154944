import { initializeApp as initializeFirebaseApp } from "firebase/app";

const firebaseApp = initializeFirebaseApp({
  apiKey: "AIzaSyBf1-21yO16xNBdRC-djVO5LwfiR86-Cuw",
  authDomain: "peech-rnd.firebaseapp.com",
  projectId: "peech-rnd",
  storageBucket: "peech-rnd.appspot.com",
  messagingSenderId: "564841733065",
  appId: "1:564841733065:web:abe6d86dab5982e466a618",
  measurementId: "G-CNGJ98GNEY",
});

export default firebaseApp;
