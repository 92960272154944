import React, { useState, useRef, useEffect, useCallback, HTMLProps } from "react";
import styled from "styled-components/macro";
import Color from "color";

import { themeProp } from "src/utils/styledComponents.utils";

const Range = styled.input`
  display: block;
  appearance: none;
  cursor: pointer;
  background: linear-gradient(
    90deg,
    #f00,
    #ff2b00,
    #f50,
    #ff8000,
    #fa0,
    #ffd500,
    #ff0,
    #d4ff00,
    #af0,
    #80ff00,
    #5f0,
    #2bff00,
    #0f0,
    #00ff2b,
    #0f5,
    #00ff80,
    #0fa,
    #00ffd5,
    #0ff,
    #00d4ff,
    #0af,
    #007fff,
    #05f,
    #002bff,
    #00f,
    #2a00ff,
    #50f,
    #7f00ff,
    #a0f,
    #d400ff,
    #f0f,
    #ff00d4,
    #f0a,
    #ff0080,
    #f05,
    #ff002b
  );

  width: 100%;
  height: 20px;
  box-sizing: border-box;
  border-radius: ${themeProp("borderRadii.medium")}px;
  outline: 0;

  &::-webkit-slider-thumb {
    appearance: none;
    background-color: #fff;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid transparent;
    cursor: pointer;
    transition: 300ms ease-out;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3);
  }

  &::-webkit-slider-thumb:active,
  &::-webkit-slider-thumb:hover {
    transform: scale(1.5);
  }
`;

interface HueRangeProps
  extends Omit<
    HTMLProps<HTMLInputElement>,
    "color" | "onChange" | "value" | "ref" | "as" | "min" | "max" | "step" | "type"
  > {
  onChange(color: Color): void;
  color: Color;
}

export default function HueRange({ onChange, color, ...props }: HueRangeProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [rangeValue, setRangeValue] = useState(color.hue() / 360);

  const hueChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = evt.currentTarget;
      setRangeValue(Number(value));
      const h = Number(value) * 360;
      onChange(color.hue(h));
    },
    [color, onChange],
  );

  useEffect(() => {
    if (document.activeElement !== inputRef.current) {
      setRangeValue(color.hue() / 359);
    }
  }, [color]);

  return (
    <Range
      data-cy="hue-range"
      ref={inputRef}
      type="range"
      min={0}
      max={1}
      step={0.005}
      value={rangeValue}
      onChange={hueChange}
      {...props}
    />
  );
}
