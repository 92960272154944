import { CenterContentContainer, Container } from "src/components/auth/common/layout.styled";
import { H1Bold, Text1 } from "src/components/common/layout/typography.styled";
import Icon from "src/components/common/Icon";

export default function ResetPasswordEmailVerification() {
  return (
    <Container>
      <CenterContentContainer>
        <H1Bold>Forgot My Password</H1Bold>

        <Text1 marginBottom={10}>
          If an existing account with that email, we will send you a reset password email shortly.
        </Text1>

        <Icon.Mail size={165} color="pink.500" />
      </CenterContentContainer>
    </Container>
  );
}
