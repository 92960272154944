/* eslint-disable @typescript-eslint/no-use-before-define */

import React, { useState, useRef, HTMLProps } from "react";
import styled from "styled-components/macro";
import Color from "color";

import { themeProp } from "src/utils/styledComponents.utils";

const Container = styled.div<{
  isDragging: boolean;
  hue: number;
}>`
  width: 100%;
  height: 120px;
  border-radius: ${themeProp("borderRadii.medium")}px;
  margin-bottom: 16px;
  background-color: ${({ hue }): string => `hsl(${hue}, 100%, 50%)`};
  position: relative;
  cursor: ${({ isDragging = false }): string => (isDragging ? "none" : "pointer")};

  &:before,
  &:after {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: ${themeProp("borderRadii.medium")}px;
  }

  &:before {
    background: linear-gradient(270deg, transparent 0, #fff);
  }

  &:after {
    background: linear-gradient(180deg, transparent 0, #000);
  }
`;

const Thumb = styled.div`
  width: 16px;
  height: 16px;
  background-color: transparent;
  border-radius: 50%;
  border: 4px solid #fff;
  position: absolute;
  user-select: none;
  pointer-events: none;
  z-index: 2;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3);
  transform: translate(-50%, -50%);
`;

interface SaturationProps
  extends Omit<HTMLProps<HTMLInputElement>, "color" | "onChange" | "value" | "ref" | "as" | "type"> {
  onChange(color: Color): void;
  color: Color;
}

export default function Saturation({ color, onChange }: SaturationProps) {
  const [isDragging, setIsDragging] = useState(false);
  const boxRef = useRef<HTMLDivElement>(null);

  const onMouseDown = (evt: React.MouseEvent | React.TouchEvent): void => {
    if (evt as React.MouseEvent) {
      const { pageX, pageY } = evt as React.MouseEvent;
      if (pageX && pageY) {
        mouseMove(pageX, pageY);
      }
    } else {
      const { pageX, pageY } = (evt as React.TouchEvent).changedTouches[0];
      mouseMove(pageX, pageY);
    }
    setIsDragging(true);
    addEvents();
  };

  const onTouchMove = (evt: TouchEvent): void => {
    mouseMove(evt.changedTouches[0].pageX, evt.changedTouches[0].pageY);
  };

  const mouseMove = (pageX: number, pageY: number): void => {
    if (boxRef.current) {
      const boundary = boxRef.current.getBoundingClientRect();
      const x = Math.min(boundary.width, Math.max(0, pageX - boundary.x));
      const y = Math.min(boundary.height, Math.max(0, pageY - boundary.y));

      const c = {
        h: color.hue(),
        s: (100 * x) / boundary.width,
        v: 100 - (100 * y) / boundary.height,
      };

      onChange(Color(c));
    }
  };
  const onMouseMove = (evt: MouseEvent): void => {
    mouseMove(evt.pageX, evt.pageY);
  };

  const onMouseUp = (): void => {
    setIsDragging(false);
    removeEvents();
  };

  const addEvents = (): void => {
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
    document.addEventListener("touchmove", onTouchMove);
    document.addEventListener("touchend", onMouseUp);
  };

  const removeEvents = (): void => {
    document.removeEventListener("mousemove", onMouseMove);
    document.removeEventListener("mouseup", onMouseUp);
    document.removeEventListener("touchmove", onTouchMove);
    document.removeEventListener("touchend", onMouseUp);
  };

  return (
    <Container
      isDragging={isDragging}
      ref={boxRef}
      onMouseDown={onMouseDown}
      onTouchStart={onMouseDown}
      hue={color.hue()}
      data-cy="saturation"
    >
      <Thumb
        data-cy="saturation-thumb"
        style={{
          left: `${color.saturationv()}%`,
          top: `${100 - color.value()}%`,
          backgroundColor: color.string(),
        }}
      />
    </Container>
  );
}
