import styled from "styled-components/macro";

import Icon from "src/components/common/Icon";
import { Button } from "src/components/common/buttons/Button.styled";
import toast from "react-hot-toast";

const Wrapper = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

interface UpdateNotificationProps {
  t: { id: string };
}

export function UpdateNotification({ t }: UpdateNotificationProps) {
  return (
    <Wrapper>
      <Icon.Bell size={60} />
      New version available! Click to reload the page.
      <Button
        size="medium"
        variant="primary"
        style={{ margin: "10px auto" }}
        onClick={() => {
          window.updateWorker.waiting?.postMessage({ type: "SKIP_WAITING" });
          toast.dismiss(t.id);
        }}
      >
        Reload application
      </Button>
    </Wrapper>
  );
}
