/* eslint-disable react/no-unescaped-entities */

import styled from "styled-components/macro";

import { Box } from "src/components/common/layout/Box.styled";
import { Button } from "src/components/common/buttons/Button.styled";
import { H3 } from "src/components/common/layout/typography.styled";
import { ModalComponentProps } from "src/components/providers/ModalProvider";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  text-align: center;
`;

export default function NoVerbalContentModal({ close }: ModalComponentProps) {
  return (
    <Container>
      <H3>Oops! We couldn't detect any verbal content, please try again with a different video</H3>

      <Box marginTop={30} marginBottom={20}>
        Please select different footage or add another footage with verbal content to your project.
      </Box>

      <Button variant="primary" size="medium" onClick={close}>
        OK
      </Button>
    </Container>
  );
}
