import { RefObject, useEffect } from "react";
import useVariableRef from "src/hooks/useVariableRef";

export default function useEndScrollHandler(
  containerRef: RefObject<HTMLElement>,
  onEndScrollHandler: () => void,
): void {
  const onEndScrollHandlerRef = useVariableRef(onEndScrollHandler);

  useEffect(() => {
    const container = containerRef.current;

    const handleScroll = () => {
      if (container && Math.ceil(container.scrollTop) + container.clientHeight >= container.scrollHeight) {
        // eslint-disable-next-line no-console
        console.log("onEndScroll");
        onEndScrollHandlerRef.current();
      }
    };

    container?.addEventListener("scroll", handleScroll);

    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, [containerRef, onEndScrollHandlerRef]);
}
