import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { createModel } from "@rematch/core";

import { RootModel } from "src/models/index";
import { RootState } from "src/models/store";
import apiClient from "src/network/ApiClient";
import * as gql from "src/network/graphql/generatedGraphqlSDK";
import { CropDetail } from "src/network/graphql/generatedGraphqlSDK";
import {
  getSortedAssetCropDomains,
  assetCropDataToTimeIntervalCropData,
  castCropTimeIntervalsToChapterTimeIntervals,
} from "src/utils/cropTimeInterval.utils";
import { sequenceSelectors } from "src/models/Sequence.model";
import { TimeInterval } from "src/types/video-trimmer.types";
import { chaptersToChapterTimeIntervals } from "src/components/features/VideoTrimmer/common/converters";
import { CropperHistory } from "src/types/video-cropper.types";

export type AssetDomain = { assetSid: string; domain: TimeInterval };

const cropAdapter = createEntityAdapter<CropDetail>({
  selectId: (model) => model.externalAssetSid,
});
const cropAdapterSelectors = cropAdapter.getSelectors((state: RootState) => state.crop); // prettier-ignore

const selectAssetsDomains = createSelector(
  sequenceSelectors.selectById,
  sequenceSelectors.selectVideoChapters,
  (sequence, chapters) => getSortedAssetCropDomains(chapters),
);

const selectTimelineCropData = createSelector(
  sequenceSelectors.selectById,
  sequenceSelectors.selectVideoChapters,
  cropAdapterSelectors.selectEntities,
  selectAssetsDomains,
  (sequence, chapters, cropDetails, assetDomains): CropperHistory => {
    const chapterTimeIntervals = chaptersToChapterTimeIntervals(chapters);
    const cropTimeIntervals = assetCropDataToTimeIntervalCropData(cropDetails, assetDomains);
    const firstAsset = cropDetails?.[assetDomains[0]?.assetSid];
    return {
      crops: castCropTimeIntervalsToChapterTimeIntervals(chapterTimeIntervals, cropTimeIntervals),
      style: { blur: firstAsset?.blur, color: firstAsset?.color, brandColorIndex: firstAsset?.brandColorIndex },
    };
  },
);

export const cropSelectors = {
  ...cropAdapterSelectors,
  selectTimelineCropData,
  selectAssetsDomains,
};

const crop = createModel<RootModel>()({
  state: cropAdapter.getInitialState(),

  reducers: {
    setCropDetails: (state, payload: CropDetail[]) => cropAdapter.setMany(state, payload),
  },

  effects: (dispatch) => ({
    async fetchCropDetails(variables: gql.GetCropDetailsQueryVariables) {
      const { outputAspectRatio, externalSequenceSid } = variables;
      if (!outputAspectRatio || !externalSequenceSid) {
        return;
      }

      const getCropDataResponse = await apiClient.getCropDetails(variables);
      dispatch.crop.setCropDetails(getCropDataResponse.nodes);
    },
    async updateCropDetails(variables: gql.UpdateAssetCropDataMutationVariables) {
      await apiClient.updateCropDetails(variables);
    },
    async updateCropStyle(variables: gql.MutationUpdateBackgroundDesignArgs) {
      await apiClient.updateCropStyle(variables);
    },
  }),
});

export default crop;
