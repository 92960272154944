import { useCallback, HTMLProps, useState, useEffect } from "react";

import { Button } from "src/components/common/buttons/Button.styled";
import { useSwiperContext } from "src/components/common/Swiper/Swiper";

export interface SwiperNextSlideButtonProps
  extends Omit<HTMLProps<HTMLButtonElement>, "onClick" | "disabled" | "type" | "ref" | "as"> {
  onClick?(slideNext: Function): void;
}

export default function SwiperNextSlideButton({ children, onClick, ...props }: SwiperNextSlideButtonProps) {
  const { allowSlideNext, slideNext } = useSwiperContext();
  const onButtonClick = useCallback(() => (onClick ? onClick(slideNext) : slideNext()), [onClick, slideNext]);

  // forceDisabled is used to prevent the button from being clickable on the first render
  // fix issue with logRocket library that apply disabled css on button in inline style
  const [forceDisabled, setForceDisabled] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setForceDisabled(false);
    }, 100);
  }, []);

  return (
    <Button
      {...props}
      type="button"
      variant="primary"
      size="large"
      onClick={onButtonClick}
      disabled={!allowSlideNext || forceDisabled}
    >
      {children}
    </Button>
  );
}
