import { useCallback, useRef, useState } from "react";
import BaseUploadService, { FileUploadStatus } from "src/services/BaseUpload.service";

export const useChunkedUploader = () => {
  const uploader = useRef<BaseUploadService>();
  const [uploadStatus, setUploadStatus] = useState<FileUploadStatus>(FileUploadStatus.PENDING);
  const [progress, setProgress] = useState(0);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadThumbnail, setUploadThumbnail] = useState<string | null>(null);

  const onUploadStatusChange = (st: FileUploadStatus) => {
    setUploadStatus(st);
  };

  const onProgress = (pro: number) => {
    setProgress(pro || 0);
  };

  const onSuccess = () => {
    setUploadSuccess(true);
    setProgress(uploader.current?.progress || 0);
  };

  const getThumbnail = useCallback(async () => {
    const thumb = await uploader.current?.getThumbnail({ width: 400, height: 225, time: 3 });

    if (thumb) {
      setUploadThumbnail(thumb);
    }
  }, []);

  const setUploader = useCallback(
    async (uploaderService: BaseUploadService, autoStart = true) => {
      uploader.current = uploaderService;
      // add listener
      uploaderService.on("statusChange", onUploadStatusChange);
      uploaderService.on("progress", onProgress);
      uploaderService.on("success", onSuccess);
      // update state
      setUploadStatus(uploaderService.status);
      setProgress(uploaderService.progress || 0);
      setUploadSuccess(uploaderService.status === FileUploadStatus.SUCCESS);
      // call start if needed
      if (uploaderService.status === FileUploadStatus.PENDING && autoStart) {
        uploader.current.start();
      }
      getThumbnail();
    },
    [getThumbnail],
  );

  return {
    setUploader,
    uploadStatus,
    progress,
    uploadSuccess,
    uploadThumbnail,
  };
};
