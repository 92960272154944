import styled, { keyframes } from "styled-components/macro";

import { styleProp, themeColor } from "src/utils/styledComponents.utils";
import { Box } from "src/components/common/layout/Box.styled";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const CircularLoader = styled(Box)<{ size: number; thickness?: number }>`
  width: ${styleProp("size")}px;
  height: ${styleProp("size")}px;
  border: ${styleProp("thickness")}px solid ${themeColor("gray.300")};
  border-top: ${styleProp("thickness")}px solid ${themeColor("pink.500")};
  border-radius: 50%;
  animation: ${spin} 2s linear infinite;
`;

CircularLoader.defaultProps = {
  thickness: 5,
};
