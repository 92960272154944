export type AspectRatio = "1:1" | "16:9" | "9:16";

export const getAspectRatioValue = (aspectRatio: AspectRatio) => {
  const [w, h] = aspectRatio.split(":");

  return Number(w) / Number(h);
};

// TODO: move and organize all search param names in one constants file
export const SKIP_SPEAKER_DETECTION_QUERY_PARAM = "skipSpeakerDetection";

// TODO: move to appConfig
export const MAX_CHAPTER_COUNT = 5;

export const MAX_CLIP_DURATION = 1500000; // max duration 25 minutes in milliseconds
export const MIN_CLIP_DURATION = 1500; // min duration 10 seconds in milliseconds
