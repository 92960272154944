import { useCallback, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useGetOldAppLink } from "src/hooks/useOldAppRedirect";
import { Dispatch } from "src/models/store";
import EntityTitleModal from "src/components/modals/EntityTitleModal";
import analyticsService from "src/services/Analytics.service";
import { useModal } from "src/components/providers/ModalProvider";

export default function BrandPage() {
  const getOldAppLink = useGetOldAppLink();
  const dispatch = useDispatch<Dispatch>();
  const { openModal } = useModal();
  const brandIframe = useRef<HTMLIFrameElement>(null);

  const showRenameBrandPresetModal = useCallback(
    (brandPresetName: string, presetId: string) => {
      openModal(
        ({ close, ...props }) => (
          <EntityTitleModal
            {...props}
            onSubmit={async (name) => {
              await dispatch.presets.updatePreset({
                sid: presetId!,
                values: {
                  name,
                },
              });
              analyticsService.track("brand-kit-rename", { brandSid: presetId, name });
              brandIframe?.current?.contentWindow &&
                brandIframe?.current?.contentWindow?.postMessage("RENAME_BRAND_PRESET_SUBMITTED", "*");
            }}
            close={close}
            modalTitle="Rename your Brand Kit"
            titlePlaceholder={brandPresetName}
            defaultPlaceHolder="Your Brand Kit name"
            submitLabel="Submit"
          />
        ),
        { hideCloseButton: true },
      );
    },
    [dispatch.presets, openModal],
  );

  useEffect(() => {
    const onMessage = (event: MessageEvent) => {
      const { eventName, brandPresetName, presetSid } = event.data;
      if (eventName === "OPEN_RENAME_BRAND_PRESET_MODAL") {
        showRenameBrandPresetModal(brandPresetName, presetSid);
      }
    };
    window.addEventListener("message", onMessage);
    return () => {
      window.removeEventListener("message", onMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    // effect
    return () => {
      // reset preset state
      dispatch.presets.resetPresetState();
      // get presets again
      dispatch.presets.getPresets({});
    };
  });

  return (
    <>
      <Helmet>
        <title>Brand Kit | Peech - Automated Talk-Videos Creator</title>
      </Helmet>
      <iframe
        ref={brandIframe}
        id="brand-iframe"
        title="brand"
        src={`${getOldAppLink("/brand")}#1`}
        width="100%"
        height="100%"
      />
    </>
  );
}
