// TODO: move

import { equals, keyBy, sumBy } from "lodash/fp";

import { ChapterTimeInterval } from "src/types/video-trimmer.types";

import { ChapterStatus, CuePointType } from "src/constants/model.constants";

import { roundBy } from "src/utils/math.utils";
import { timeIntervalUtils } from "src/utils/timeInterval.utils";

import { Chapter, ChapterOrder } from "src/network/graphql/generatedGraphqlSDK";

import AppMonitoringService from "src/services/AppMonitoring.service";

const getChapterOffset = (targetChapter: Chapter, sourceChapters: Chapter[]) => {
  const previousSourceVideoChapters = sourceChapters.filter((chapter) => {
    const isTargetChapterParent = chapter.sid === targetChapter.linkSid;
    const isPreviousChapter = chapter.index! < targetChapter.index!;

    return isPreviousChapter && !isTargetChapterParent;
  });

  return sumBy("srcDuration", previousSourceVideoChapters);
};

export const chaptersToChapterTimeIntervals = (chapters: Chapter[]) => {
  const videoChapters = chapters.filter((c) => !c.cuePointType && [ChapterStatus.Ready, ChapterStatus.Pending].includes(c.status!)); // prettier-ignore
  const sourceChapters = videoChapters.filter((chapter) => !chapter.linkSid);
  const sourceChaptersDuration = sumBy("srcDuration", sourceChapters);

  return videoChapters.map<ChapterTimeInterval>((chapter) => {
    const offset = getChapterOffset(chapter, sourceChapters);

    return {
      id: chapter.sid!,
      isSource: !chapter.linkSid,
      start: roundBy(0.001, offset + (chapter.clipFrom ?? 0)!),
      end: roundBy(0.001, offset + (chapter.clipFrom ?? 0)! + chapter.duration!),
      isMuted: chapter.mute ?? false,
      domain: timeIntervalUtils.applyMaxEnd(
        {
          start: roundBy(0.001, offset),
          end: roundBy(0.001, Math.floor((offset + chapter.srcDuration!) * 100) / 100),
        },
        sourceChaptersDuration,
      ),
    };
  });
};

interface ClassifiedChapterTimeInterval extends ChapterTimeInterval {
  type: "new" | "modifiedSource" | "modified";
}

// TODO: try to simplify formatting logic
export const chapterTimeIntervalsToChapters = (
  chapterTimeIntervals: ChapterTimeInterval[],
  originalChapters: Chapter[],
) => {
  const resolvedChapterTimeIntervals = timeIntervalUtils.resolveOverlaps(
    chapterTimeIntervals,
    (a: ChapterTimeInterval, b: ChapterTimeInterval) => timeIntervalUtils.equals(a.domain, b.domain),
  );
  const originalChapterTimeIntervals = chaptersToChapterTimeIntervals(originalChapters);

  const originalSourceChapters = originalChapterTimeIntervals.filter((c) => c.isSource);
  const originalChapterMap = keyBy("id", originalChapterTimeIntervals);
  const updatedChapterMap = keyBy<ChapterTimeInterval>("id", resolvedChapterTimeIntervals);

  const introChapter = originalChapters.find((c) => c.cuePointType === CuePointType.Chapter.Intro);
  const outroChapter = originalChapters.find((c) => c.cuePointType === CuePointType.Chapter.Outro);

  const newChapters = resolvedChapterTimeIntervals.filter(({ id }) => !originalChapterMap[id]);
  const modifiedChapters = resolvedChapterTimeIntervals.filter(({ id }) => originalChapterMap[id] && !originalChapterMap[id].isSource); // prettier-ignore
  const modifiedSourceChapters = resolvedChapterTimeIntervals.filter(({ id }) => originalChapterMap[id] && originalChapterMap[id].isSource); // prettier-ignore
  const deletedChapterIds = originalChapterTimeIntervals.filter(({ id, isSource }) => !updatedChapterMap[id] && !isSource).map(({ id }) => id); // prettier-ignore
  const deletedSourceChapters = originalChapterTimeIntervals.filter(({ id, isSource }) => !updatedChapterMap[id] && isSource); // prettier-ignore

  while (deletedSourceChapters.length) {
    const sourceChapter = deletedSourceChapters.pop()!;
    const newChapterIndex = newChapters.findIndex((c) => equals(c.domain, sourceChapter.domain));
    const modifiedChapterIndex = modifiedChapters.findIndex((c) => equals(c.domain, sourceChapter.domain));

    if (newChapterIndex !== -1) {
      const [newChapter] = newChapters.splice(newChapterIndex, 1);
      modifiedSourceChapters.push({ ...newChapter, id: sourceChapter.id });
    } else if (modifiedChapterIndex !== -1) {
      const [modifiedChapter] = modifiedChapters.splice(modifiedChapterIndex, 1);
      deletedChapterIds.push(modifiedChapter.id);
      modifiedSourceChapters.push({ ...modifiedChapter, id: sourceChapter.id });
    }
  }

  const mapper = (c: ClassifiedChapterTimeInterval, index: number): ChapterOrder => {
    const resultChapter = {
      sid: c.type === "new" ? null : c.id,
      linkSid: c.type === "modifiedSource" ? null : originalSourceChapters.find((o) => equals(o.domain, c.domain))!.id, // prettier-ignore
      index: index + (introChapter ? 2 : 1), // TODO: add a minIndex parameter instead
      clipFrom: c.start - c.domain.start,
      duration: timeIntervalUtils.duration(c),
      mute: c.isMuted,
    };

    if (resultChapter.clipFrom < 0) {
      resultChapter.clipFrom = 0;
      AppMonitoringService.track("CHAPTER_NEGATIVE_CLIP_FROM_", resultChapter);
    }

    return resultChapter;
  };

  const deletedChapters = deletedChapterIds.map<Chapter>((sid) => ({ sid, duration: 0 }));

  const formattedChapter = [
    ...[
      ...modifiedSourceChapters.map<ClassifiedChapterTimeInterval>((x) => ({ ...x, type: "modifiedSource" })),
      ...modifiedChapters.map<ClassifiedChapterTimeInterval>((x) => ({ ...x, type: "modified" })),
      ...newChapters.map<ClassifiedChapterTimeInterval>((x) => ({ ...x, type: "new" })),
    ]
      .sort((a, b) => a.start - b.start)
      .map(mapper),
  ];

  /** make sure the source chapter in each domain is also the first chapter in that domain */
  const domainFirstChapterIndexes: Record<string, number> = {};
  let i = 0;
  while (i < formattedChapter.length) {
    const currentChapter = formattedChapter[i];
    const previousChapter = formattedChapter[i - 1];

    if ((currentChapter.linkSid ?? currentChapter.sid) !== (previousChapter?.linkSid ?? previousChapter?.sid)) {
      domainFirstChapterIndexes[(currentChapter.linkSid ?? currentChapter.sid)!] = i;
    }

    /** if the previous chapter is linked to the current chapter */
    if (!currentChapter.linkSid && previousChapter && previousChapter.linkSid === currentChapter.sid) {
      /** find the first chapter in the related asset */

      const domainFirstChapterIndex = domainFirstChapterIndexes[currentChapter.sid!];
      const domainFirstChapter = formattedChapter[domainFirstChapterIndex];
      const domainFirstChapterSid = domainFirstChapter.sid;

      /** swap between the first chapter in the related asset and the current chapter */

      domainFirstChapter.sid = currentChapter.sid;
      domainFirstChapter.linkSid = null;

      currentChapter.sid = domainFirstChapterSid;
      currentChapter.linkSid = domainFirstChapter.sid;
    }

    i += 1;
  }

  // TODO: move outside
  if (outroChapter) {
    formattedChapter.push({
      sid: outroChapter.sid,
      linkSid: outroChapter.linkSid,
      index: formattedChapter.length + (introChapter ? 2 : 1),
    });
  }

  formattedChapter.push(...deletedChapters);

  return formattedChapter;
};
