import * as CSS from "csstype";
import styled, { css, ThemeProps } from "styled-components/macro";

import { SpaceValue, Theme } from "src/theme";
import { ifProp, styleProp } from "src/utils/styledComponents.utils";

type MarginProp =
  | "margin"
  | "marginBlock"
  | "marginInline"
  | "marginTop"
  | "marginRight"
  | "marginBottom"
  | "marginLeft";

type PositionProp = "top" | "right" | "bottom" | "left";

type SpacingProp = MarginProp | PositionProp;

type SpacingProps = Record<SpacingProp, SpaceValue>;

export type BoxProps = Partial<SpacingProps & Pick<CSS.Properties, "position">>;

const boxSpaceProp = (spaceProp: SpacingProp) => (props: ThemeProps<Theme> & BoxProps) => {
  const size = props[spaceProp]!;

  return props.theme.spaces[size];
};

export const boxStyle = css<BoxProps>`
  ${ifProp(
    "position",
    css<BoxProps>`
      position: ${styleProp("position")};
    `,
  )}

  ${ifProp(
    "margin",
    css`
      margin: ${boxSpaceProp("margin")}px;
    `,
  )}

  ${ifProp(
    "marginBlock",
    css`
      margin-block: ${boxSpaceProp("marginBlock")}px;
    `,
  )}

  ${ifProp(
    "marginInline",
    css`
      margin-inline: ${boxSpaceProp("marginInline")}px;
    `,
  )}
  
  ${ifProp(
    "marginTop",
    css`
      margin-top: ${boxSpaceProp("marginTop")}px;
    `,
  )}
  
  ${ifProp(
    "marginRight",
    css`
      margin-right: ${boxSpaceProp("marginRight")}px;
    `,
  )}
  
  ${ifProp(
    "marginBottom",
    css`
      margin-bottom: ${boxSpaceProp("marginBottom")}px;
    `,
  )}
  
  ${ifProp(
    "marginLeft",
    css`
      margin-left: ${boxSpaceProp("marginLeft")}px;
    `,
  )}

  ${ifProp(
    "top",
    css`
      top: ${boxSpaceProp("top")}px;
    `,
  )}

  ${ifProp(
    "right",
    css`
      right: ${boxSpaceProp("right")}px;
    `,
  )}

  ${ifProp(
    "bottom",
    css`
      bottom: ${boxSpaceProp("bottom")}px;
    `,
  )}

  ${ifProp(
    "left",
    css`
      left: ${boxSpaceProp("left")}px;
    `,
  )}
`;

export const Box = styled.div<BoxProps>`
  ${boxStyle}
`;

export const InlineBox = styled.span<BoxProps>`
  ${boxStyle}
`;

export const FlexBox = styled(Box)`
  display: flex;
  ${boxStyle}
`;

export const Center = styled(FlexBox)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;
