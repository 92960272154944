/* eslint-disable react/button-has-type,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,no-useless-escape */

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import styled from "styled-components/macro";

import { Dispatch, RootState } from "src/models/store";

import { themeColor, themeZ } from "src/utils/styledComponents.utils";

import { useAppConfig } from "src/components/providers/AppConfigProvider";

import Icon from "src/components/common/Icon";
import { Stack } from "src/components/common/layout/Stack.styled";
import LanguageSelect from "src/components/common/LanguageSelector/LanguageSelect";
import { OnboardingStepTitle, SubmitButton } from "src/components/features/Onboarding/onboarding.styled";
import { useOnboardingFormContext } from "src/components/features/Onboarding/onboardingForm";
import { Box } from "src/components/common/layout/Box.styled";
import { ifProp } from "styled-tools";
import { Text5 } from "src/components/common/layout/typography.styled";

const Container = styled(Stack)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  max-width: 720px;
`;

const ContentInputBox = styled.div<{ isSelected: boolean }>`
  display: inline-flex;
  height: 272px;
  width: 262px;
  padding-bottom: 0px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
  border: ${ifProp("isSelected", 2, 1)}px solid ${ifProp("isSelected", themeColor("pink.500"), themeColor("blue.50"))};
  padding-bottom: 26px;
  transition: border-color 0.2s ease-in-out;
  cursor: pointer;
`;

const ContentInputTitle = styled.div`
  display: flex;
  width: 100%;
  height: 76px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 10px 10px 0px 0px;
  background: ${themeColor("blue.30")};
  font-size: 14px;
  font-weight: 600;
`;

const YoutubeInputBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 0px 26px;
`;

const inlinePadding = 14;

const YoutubeInput = styled.input`
  width: 100%;
  padding: 10px ${inlinePadding}px;
  padding-left: ${inlinePadding}px;
  outline: none;
  border: none;
  border-radius: 20px;
  background-color: ${themeColor("blue.30")};

  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${themeColor("gray.900")};

  &::placeholder {
    color: #979797;
  }
`;

const YoutubeVideoItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
  cursor: pointer;
  width: 100%;
  padding: 26px 26px 0px 26px;
`;

const YoutubeVideoItemThumbnailWrapper = styled.div`
  position: relative;
  &:after {
    content: "";
    border-radius: 10px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background-color: #000;
    opacity: 0.2;
  }
`;

const YoutubeVideoItemThumbnail = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  object-fit: cover; ;
`;

const YoutubeVideoItemTitle = styled(Box)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 10px;
  line-height: 17px;
  font-weight: 400;
  color: #000;
`;

const getYoutubeVideoIdByURL = (url: string) => {
  const youtubeRegex = /[?&]v=([^&#]+)/;
  const shortsRegex = /\/shorts\/([^\?\/]+)/;
  const youtuBeShortRegex = /youtu\.be\/([^\?\/]+)/;

  const youtubeMatch = url.match(youtubeRegex);
  if (youtubeMatch) {
    return youtubeMatch[1];
  }

  const shortsMatch = url.match(shortsRegex);
  if (shortsMatch) {
    return shortsMatch[1];
  }

  const youtuBeShortMatch = url.match(youtuBeShortRegex);
  if (youtuBeShortMatch) {
    return youtuBeShortMatch[1];
  }

  return null;
};

const ClearButton = styled(Icon.CircledX)`
  z-index: ${themeZ("imageDropzoneClearButton")};
  position: absolute;
  top: 0;
  right: 0;
  translate: 50% -50%;
  cursor: pointer;
`;

const ErrorMEssageWrapper = styled(Stack)`
  position: absolute;
  align-items: center;
  display: "flex";
  bottom: -30px;
`;
const ErrorMessage = styled(Text5)`
  color: ${themeColor("status.error")};
`;

export default function ExampleContentStep() {
  const { EXAMPLE_YOUTUBE_VIDEO_IDS } = useAppConfig();

  const dispatch = useDispatch<Dispatch>();
  const youtubeVideos = useSelector((state: RootState) => state.youtubeSearchResults.entities);

  const onboardingForm = useOnboardingFormContext();
  const youtubeVideoURL = onboardingForm.watch("content.youtubeVideoURL") ?? "";
  const selectedYoutubeVideoId = onboardingForm.watch("content.youtubeVideoId");
  const [selectedVideoId, setSelectedVideoId] = useState<string | null>(null);
  const [isYouTubeInputError, setIsYouTubeInputError] = useState<boolean>(false);
  const queryVideo = Object.values(youtubeVideos).find((video) => video && youtubeVideoURL.includes(video.id));

  useEffect(() => {
    EXAMPLE_YOUTUBE_VIDEO_IDS.map(dispatch.youtubeSearchResults.getYoutubeVideo);
  }, [dispatch, EXAMPLE_YOUTUBE_VIDEO_IDS]);

  useEffect(() => {
    if (youtubeVideoURL) {
      const youtubeVideoId = getYoutubeVideoIdByURL(youtubeVideoURL);
      setSelectedVideoId(youtubeVideoId || null);
    } else {
      setSelectedVideoId(null);
    }
  }, [youtubeVideoURL, selectedVideoId]);

  const exampleVideo = youtubeVideos[EXAMPLE_YOUTUBE_VIDEO_IDS[0]];

  useEffect(() => {
    const youtubeVideoId = getYoutubeVideoIdByURL(youtubeVideoURL);
    if (youtubeVideoURL !== "" && youtubeVideoId && queryVideo) {
      onboardingForm.setValue("content.youtubeVideoId", queryVideo.id, { shouldValidate: true });
      onboardingForm.setValue("content.inputMethod", "youtubeVideoURL", { shouldValidate: true });
    } else {
      onboardingForm.setValue("content.youtubeVideoId", exampleVideo?.id, { shouldValidate: true });
      onboardingForm.setValue("content.inputMethod", "youtubeVideoID", { shouldValidate: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [youtubeVideoURL, exampleVideo?.id, queryVideo]);

  useEffect(() => {
    if (youtubeVideoURL === "") {
      setIsYouTubeInputError(false);
    } else {
      const youtubeVideoId = getYoutubeVideoIdByURL(youtubeVideoURL);
      setIsYouTubeInputError(!youtubeVideoId);
    }
  }, [youtubeVideoURL]);

  return (
    <Container direction="column" spacing={40}>
      <OnboardingStepTitle>
        Peech creates numerous branded video snippets from your content.
        <br />
        <strong>Try Peech with a talking video example.</strong>
      </OnboardingStepTitle>

      <Stack direction="row" spacing={30} style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <ContentInputBox
          isSelected={queryVideo ? queryVideo?.id === selectedYoutubeVideoId : false}
          onClick={() => {
            if (queryVideo) {
              onboardingForm.setValue("content.youtubeVideoId", queryVideo.id, { shouldValidate: true });
              onboardingForm.setValue("content.inputMethod", "youtubeVideoURL", { shouldValidate: true });
            }
          }}
        >
          <ContentInputTitle>
            <Icon.YoutubeSmall size="medium" />
            YouTube link
          </ContentInputTitle>
          {!queryVideo && (
            <>
              <YoutubeInputBox>
                <YoutubeInput
                  {...onboardingForm.register("content.youtubeVideoURL")}
                  type="text"
                  placeholder="Paste a Youtube link"
                />
                {isYouTubeInputError && (
                  <ErrorMEssageWrapper direction="row" spacing={4}>
                    <Icon.CircledX size={8} color="status.error" />
                    <ErrorMessage>Enter a valid Youtube link</ErrorMessage>
                  </ErrorMEssageWrapper>
                )}
              </YoutubeInputBox>

              <SubmitButton
                disabled={selectedVideoId === null}
                onClick={async () => {
                  const youtubeVideoId = getYoutubeVideoIdByURL(youtubeVideoURL);

                  if (!youtubeVideoId) {
                    setIsYouTubeInputError(true);
                    return;
                  }

                  try {
                    const res = await dispatch.youtubeSearchResults.getYoutubeVideo(youtubeVideoId);
                    setIsYouTubeInputError(!res);
                  } catch (e) {
                    setIsYouTubeInputError(true);
                  }
                }}
              >
                Get Video Snippets
              </SubmitButton>
            </>
          )}
          {queryVideo && (
            <YoutubeVideoItem key={queryVideo.id}>
              <YoutubeVideoItemThumbnailWrapper>
                <YoutubeVideoItemThumbnail
                  src={queryVideo.snippet.thumbnails.medium.url}
                  alt={queryVideo.snippet.title}
                />
                <ClearButton
                  onClick={async () => {
                    onboardingForm.setValue("content.youtubeVideoURL", "", { shouldValidate: true });
                  }}
                />
              </YoutubeVideoItemThumbnailWrapper>

              <YoutubeVideoItemTitle marginTop={6} dangerouslySetInnerHTML={{ __html: queryVideo.snippet.title }} />
              <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                <Controller
                  name="content.languageCode"
                  control={onboardingForm.control}
                  render={({ field: { ref, value, onChange, ...field } }) => (
                    <LanguageSelect
                      {...field}
                      onChangeLanguage={onChange}
                      selectedLanguage={value ?? ""}
                      isDisabled={false}
                      isLoading={false}
                    />
                  )}
                />
              </div>
            </YoutubeVideoItem>
          )}
        </ContentInputBox>
        <ContentInputBox
          onClick={() => {
            if (exampleVideo) {
              onboardingForm.setValue("content.youtubeVideoId", exampleVideo.id, { shouldValidate: true });
              onboardingForm.setValue("content.inputMethod", "youtubeVideoID", { shouldValidate: true });
            }
          }}
          isSelected={exampleVideo?.id === selectedYoutubeVideoId}
        >
          <ContentInputTitle>
            <Icon.YouTubeStroke size="large" />
            Example Video Content
          </ContentInputTitle>

          {exampleVideo && (
            <YoutubeVideoItem key={exampleVideo.id}>
              <YoutubeVideoItemThumbnail
                src={exampleVideo.snippet.thumbnails.medium.url}
                alt={exampleVideo.snippet.title}
              />

              <YoutubeVideoItemTitle marginTop={6} dangerouslySetInnerHTML={{ __html: exampleVideo.snippet.title }} />
            </YoutubeVideoItem>
          )}
        </ContentInputBox>
      </Stack>
    </Container>
  );
}
