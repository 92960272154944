import { RefObject, useMemo } from "react";
import useResizeObserver from "use-resize-observer";

export default function usePixelPerSecondRatio(
  timelineWindowElementRef: RefObject<HTMLElement>,
  durationInSeconds: number,
) {
  const { width } = useResizeObserver({ ref: timelineWindowElementRef });
  return useMemo(() => (width && durationInSeconds ? width / durationInSeconds : 0), [width, durationInSeconds]);
}
