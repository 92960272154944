import { EntityId } from "@reduxjs/toolkit";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Flag from "react-world-flags";

import { footageSelectors } from "src/models/Footage.model";
import { Dispatch, RootState } from "src/models/store";
import socketClient from "src/network/SocketClient";
import { ClipSequenceStatus, Footage, FootageOrigin } from "src/network/graphql/generatedGraphqlSDK";

import { themeColor } from "src/utils/styledComponents.utils";

import styled from "styled-components/macro";
import { ifProp } from "styled-tools";

import * as footageAnalytics from "src/analytics/footage.analytics";

import Icon from "src/components/common/Icon";
import { Button } from "src/components/common/buttons/Button.styled";
import {
  ScrolableContainer,
  SequencesContainer,
  StyledTooltip,
  VerticalDivider,
} from "src/components/features/SmartLibrary/SmartLibrary.styled";
import DurationPopover from "src/components/features/SmartLibrary/filtering/durationPopover/DurationPopover";
import {
  aspectRatioOptions,
  scoringOptions,
  socialMediaOptions,
} from "src/components/features/SmartLibrary/filtering/filtersSelect/SelectOptions";
import SequenceFilterSelect from "src/components/features/SmartLibrary/filtering/filtersSelect/SequenceFilterSelect";
import FootageClip from "src/components/features/SmartLibrary/oneFootage/FootageClip";
import RecentlyEditedSection from "src/components/features/SmartLibrary/oneFootage/RecentlyEditedSection";
import { CLIPS_ORDER, RECENTLY_EDITED_AMOUNT } from "src/components/features/SmartLibrary/smartLibrary.constants";
import { ClipSequenceFilters } from "src/components/features/SmartLibrary/smartLibrary.types";
import {
  FootageUpdateEventDataType,
  getIsOriginalVideoAvailable,
  onFootageUpdateEvent,
} from "src/components/features/SmartLibrary/smartLibrary.utils";
import { useAppConfig } from "src/components/providers/AppConfigProvider";
import { absoluteRoutes, applyPathParams } from "src/utils/routes.utils";
import { useNavigate } from "react-router-dom";
import { CircularLoader } from "src/components/common/loaders/CircularLoader";

const FiltersWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 6px 32px;
  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    padding: 14px 14px 0 30px;
  }
  @media (max-width: 425px) {
    flex-direction: column-reverse;
    .hide-mobile-size {
      display: none;
    }
  }
`;

const CollapseAllBtn = styled(Button)<{ disabled: boolean }>`
  color: ${themeColor("blue.600")};
  padding: 7px 10px;
  font-size: 12px;
  line-height: 17px;
  font-weight: 500;
  width: 100px;
  min-height: 34px;
  max-width: 90px;

  &:hover {
    color: ${ifProp("disabled", themeColor("blue.600"), themeColor("blue.500"))} !important;
  }
`;

const FiltersGrid = styled.div`
  position: relative;
  max-width: 1300px;
  margin: unset !important;
  padding: 0;
  background-color: ${themeColor("blue.30")};
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, minmax(100px, 1fr));
  column-gap: 10px;
  row-gap: 10px;
  margin-block: 33px;

  @media (max-width: 374px) {
    column-gap: 0;
  }
  @media (min-width: 375px) {
    grid-template-columns: repeat(8, 1fr);
  }
  @media (max-width: 425px) {
    padding: 14px 0px;
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(8, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(8, 1fr) 1fr;
  }
`;

const FiltersGridItem = styled.div<{ disabled?: boolean }>`
  background-color: transparent;
  overflow: hidden;
  height: fit-content;
  opacity: ${ifProp("disabled", "0.5", "1")};
  pointer-events: ${ifProp("disabled", "none", "all")};
  grid-column: span 2;
  @media (min-width: 375px) {
    grid-column: span 8;
  }
  @media (min-width: 768px) {
    grid-column: span 4;
  }
  @media (min-width: 1024px) {
    grid-column: span 2;
  }
`;

const LastFiltersGridItem = styled(FiltersGridItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-column: span 1 / -1;
  @media (min-width: 375px) {
    grid-column: span 2 / -1;
  }
  @media (min-width: 768px) {
    grid-column: span 1 / -1;
  }
`;

const BeforeLastMediaFiltersGridItem = styled(FiltersGridItem)`
  @media (min-width: 375px) {
    grid-column: span 6;
  }

  @media (min-width: 768px) {
    grid-column: span 3;
  }

  @media (min-width: 1024px) {
    grid-column: span 2;
  }
`;

const FavoriteButton = styled.div<{ isSelected: boolean; absoluteOnBigScreen: boolean; disabled?: boolean }>`
  position: relative;
  width: 100%;
  height: 30px;
  border: solid 1px;
  border-color: ${themeColor("blue.600")};
  background-color: ${ifProp("isSelected", themeColor("blue.600"), themeColor("blue.30"))};
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  cursor: ${ifProp("disabled", "not-allowed", "pointer")};
  opacity: ${ifProp("disabled", "0.5", "1")};
  svg {
    color: ${themeColor("blue.600")};
    margin: 2px 1px 0 1px;
  }
  &:active {
    box-shadow: 0 4px 14px 0 rgba(3, 15, 20, 0.15);
  }
  &:hover {
    border-color: ${ifProp("isSelected", themeColor("white"), themeColor("blue.900"))};
  }
  transition: background-color 0.5s ease, border-color 0.5s ease;
  pointer-events: ${ifProp("disabled", "none", "all")};

  @media (min-width: 1440px) {
    position: ${ifProp("absoluteOnBigScreen", "absolute", "realtive")};
    top: ${ifProp("absoluteOnBigScreen", "30px", "none")};
    right: ${ifProp("absoluteOnBigScreen", "30px", "none")};
  }
`;

const StyledScrollableContainer = styled(ScrolableContainer)`
  padding-inline: 0;
`;

const RedirectToFootagePlayer = styled.button`
  border: none;
  outline: none;
  user-select: none;
  display: flex;
  align-self: center;
  align-items: center;
  z-index: 4;
  padding: 4px 12px;
  transition: background-color 0.3s ease-out;
  color: ${themeColor("gray.900")};
  background-color: ${themeColor("blue.1500")};
  border-radius: 8px;
  cursor: pointer;
  font-family: Open Sans;
  min-height: 26px;
  min-width: 132px;
  gap: 8px;
  font-weight: 600;
  font-size: 12px;

  svg {
    path {
      fill: ${themeColor("gray.900")};
    }
  }

  &:hover {
    background-color: ${themeColor("blue.400")};
  }

  &:disabled {
    background-color: ${themeColor("blue.50")};
    cursor: not-allowed;
    color: ${themeColor("gray.400")};
    svg {
      path {
        fill: ${themeColor("gray.400")};
      }
    }
  }

  @media (max-width: 425px) {
    padding: 12px 12px;
  }

  @media (min-width: 1440px) {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    span {
      padding: 30px 14px 30px 34px;
    }
  }
`;

const SideWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  position: relative;
  justify-content: space-between;
  align-items: center;
  .show-mobile-size {
    display: none;
    padding: 0px;
  }
  @media (max-width: 425px) {
    margin-top: 8px;
    width: 100%;
    .show-mobile-size {
      display: block;
    }
  }
`;

const SideButtonsWrapper = styled.div`
  display: flex;
  padding: 0;
  height: 100%;
  flex-direction: row;
  align-items: center;
`;

export default function FootageSequences({ footageId }: { footageId: string }) {
  const { SUPPORTED_LANGUAGES } = useAppConfig();
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();

  const footage = useSelector((state: RootState) => footageId && footageSelectors.selectById(state, footageId as EntityId)); // prettier-ignore
  const durationRange = useSelector((state: RootState) => footage && footageSelectors.selectSequencesDurationRange(state, footage)); // prettier-ignore
  const projectLanguages = useSelector((state: RootState) => footage && footageSelectors.selectSequencesLanguages(state, footageId)); // prettier-ignore
  const hasFootageScoring = useSelector((state: RootState) => footageSelectors.selectHasFootageScoring(state, footageId)); // prettier-ignore

  const [isExpandedAll, setIsExpandedAll] = useState<boolean>(false);
  const [clipsExpanded, setClipsExpanded] = useState<Record<string, boolean>>({});
  const isAllClipsExpandable = useMemo(() => footage && Object.values(clipsExpanded).length === footage?.clips?.length, [clipsExpanded, footage]); // prettier-ignore
  const containerRef = useRef<HTMLDivElement>(null);
  const [currentFilters, setCurrentFilters] = useState<ClipSequenceFilters>({
    scoring: [],
    duration: null,
    language: [],
    socialMedia: [],
    aspectRatio: [],
    onlyFavorites: false,
  });
  const languagesOptions = useMemo(() => {
    const availableLanguages = SUPPORTED_LANGUAGES.filter((opt) => projectLanguages?.includes(opt.languageCode));

    return availableLanguages.map((option) => {
      const obj = {
        value: option.languageCode,
        label: option.label,
        status: option.status,
        icon: <Flag code={option.languageCode.split("-")[1]} />,
      };
      return obj;
    });
  }, [projectLanguages, SUPPORTED_LANGUAGES]);

  const originalVideoAvailable = getIsOriginalVideoAvailable(footage as Footage);

  const shouldEnableFilters = useMemo(() => !!(footage && footage?.clips?.some((clip) => clip.sequences?.some((seq) => seq.status === ClipSequenceStatus.Ready))), [footage]); // prettier-ignore

  const filteredClips = useSelector((state: RootState) => footageSelectors.selectFilteredClips(state, footageId, currentFilters.scoring)); // prettier-ignore

  const recentlyEditedSequences = useSelector((state: RootState) => {
    if (footage && durationRange) {
      return footageSelectors.selectRecentlyEditedSequences(
        state,
        footage,
        currentFilters,
        durationRange,
        RECENTLY_EDITED_AMOUNT,
      );
    }
    return [];
  });

  const trackFilterInFootage = useCallback(
    (trigger: string, filters: ClipSequenceFilters) => {
      // track user uses filter
      footageAnalytics.trackFilterInFootage({
        duration:
          !!filters.duration?.length &&
          !!(durationRange && durationRange.minDuration && durationRange.maxDuration) &&
          (filters.duration[0] !== durationRange.minDuration || filters.duration[1] !== durationRange.maxDuration),
        ratio: filters.aspectRatio.toString(),
        socialMedia: filters.socialMedia.toString(),
        favorite: filters.onlyFavorites,
        language: !!filters.language.length,
        trigger,
      });
    },
    [durationRange],
  );

  const onSequencesFiltering = useCallback(
    (field: keyof ClipSequenceFilters, values: string[]) => {
      setCurrentFilters((filters) => ({
        ...filters,
        [field]: values,
      }));
      trackFilterInFootage(field, { ...currentFilters, [field]: values });
    },
    [currentFilters, trackFilterInFootage],
  );

  const changeSelectedDurationValues = useCallback(
    (range: [number, number]) => {
      setCurrentFilters((filters) => ({
        ...filters,
        duration: range,
      }));
      trackFilterInFootage("duration", { ...currentFilters, duration: range });
    },
    [currentFilters, trackFilterInFootage],
  );

  const onFavoriteClick = useCallback(() => {
    setCurrentFilters((filters) => ({
      ...filters,
      onlyFavorites: !currentFilters.onlyFavorites,
    }));
    trackFilterInFootage("favorite", { ...currentFilters, onlyFavorites: !currentFilters.onlyFavorites });
  }, [currentFilters, trackFilterInFootage]);

  const onExpandCollapseAllClick = useCallback(() => {
    const clipsExpandedCopy = { ...clipsExpanded };
    // eslint-disable-next-line guard-for-in
    for (const key in clipsExpandedCopy) {
      clipsExpandedCopy[key] = !isExpandedAll;
    }

    // track collapse / expand all
    footageAnalytics.trackExpandCollapseAllClips({
      action: isExpandedAll ? "collapse" : "expand",
    });

    setClipsExpanded(clipsExpandedCopy);
    setIsExpandedAll(!isExpandedAll);
  }, [clipsExpanded, isExpandedAll]);

  useEffect(() => {
    if (durationRange) {
      setCurrentFilters((filters) => ({
        ...filters,
        duration: [durationRange.minDuration, durationRange.maxDuration],
      }));
    }
  }, [durationRange]);

  useEffect(() => {
    dispatch.footage.fetchFootage(footageId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [footageId]);

  useEffect(() => {
    if (footage) {
      // fetch sequences for example project
      if (footage.origin === FootageOrigin.ExampleProject) {
        dispatch.sequences.fetchSequencesByFootageId({ footageId: footage.originalFootageId });
      }
      dispatch.sequences.fetchSequencesByFootageId({ footageId: footage.id });
    }
  }, [dispatch, footage]);

  useEffect(() => {
    const allExpandedManually = Object.values(clipsExpanded).every((x) => x === true);
    const allCollapsedManually = Object.values(clipsExpanded).every((x) => x === false);
    if (!Object.keys(clipsExpanded).length) {
      setIsExpandedAll(false);
    } else if (allExpandedManually) {
      setIsExpandedAll(true);
    } else if (allCollapsedManually) {
      setIsExpandedAll(false);
    }
  }, [clipsExpanded, isAllClipsExpandable]);

  useEffect(() => {
    const onFootageUpdate = (event: FootageUpdateEventDataType) => {
      onFootageUpdateEvent(dispatch, event);
    };

    socketClient.on("footages", onFootageUpdate);

    return () => {
      socketClient.off("footages", onFootageUpdate);
    };
  }, [dispatch]);

  if (!footage) {
    return null;
  }

  return (
    <SequencesContainer>
      <FiltersWrapper>
        <div className="hide-mobile-size">
          <StyledTooltip
            disabled={originalVideoAvailable.isAvailable || originalVideoAvailable.isProcessing}
            text={originalVideoAvailable.message}
            placement="bottom"
            maxWidth={180}
            textAlign="center"
          >
            <RedirectToFootagePlayer
              disabled={!originalVideoAvailable.isAvailable}
              onClick={() => {
                navigate(applyPathParams(absoluteRoutes.platform.children.player, { footageId }));
                footageAnalytics.trackFootagePreviewButtonClick(footageId, "snippets-footage-preview-button");
              }}
            >
              {!originalVideoAvailable.isAvailable && originalVideoAvailable.isProcessing ? (
                <CircularLoader size={18} thickness={2} />
              ) : (
                <Icon.VideoCounter size={18} color="black" />
              )}
              Original Video
            </RedirectToFootagePlayer>
          </StyledTooltip>
        </div>
        <VerticalDivider />
        <FiltersGrid>
          {hasFootageScoring && (
            <FiltersGridItem disabled={!shouldEnableFilters}>
              <SequenceFilterSelect
                supportedOptions={scoringOptions}
                selectedOptions={currentFilters.scoring}
                onOptionsSelect={(codes: string[]) => onSequencesFiltering("scoring", codes)}
                placeholder={{
                  label: "Scoring",
                  icon: <Icon.ScoringEmpty />,
                }}
              />
            </FiltersGridItem>
          )}
          <FiltersGridItem
            disabled={!shouldEnableFilters || currentFilters?.duration?.[0] === currentFilters?.duration?.[1]}
          >
            <DurationPopover
              maxDuration={durationRange ? durationRange?.maxDuration : 0}
              minDuration={durationRange ? durationRange?.minDuration : 0}
              selectedDurationValues={currentFilters.duration}
              changeSelectedDurationValues={changeSelectedDurationValues}
            />
          </FiltersGridItem>
          <FiltersGridItem disabled={!shouldEnableFilters}>
            <SequenceFilterSelect
              supportedOptions={aspectRatioOptions}
              selectedOptions={currentFilters.aspectRatio}
              onOptionsSelect={(codes: string[]) => onSequencesFiltering("aspectRatio", codes)}
              placeholder={{
                label: "Ratio",
                icon: <Icon.Ratio />,
              }}
            />
          </FiltersGridItem>
          <FiltersGridItem disabled={!shouldEnableFilters}>
            <SequenceFilterSelect
              supportedOptions={languagesOptions}
              selectedOptions={currentFilters.language}
              onOptionsSelect={(codes: string[]) => onSequencesFiltering("language", codes)}
              placeholder={{
                label: "Languages",
                icon: <Icon.Translate />,
              }}
              showIcons
            />
          </FiltersGridItem>
          <BeforeLastMediaFiltersGridItem disabled={!shouldEnableFilters}>
            <SequenceFilterSelect
              supportedOptions={socialMediaOptions}
              selectedOptions={currentFilters.socialMedia}
              onOptionsSelect={(codes: string[]) => onSequencesFiltering("socialMedia", codes)}
              placeholder={{
                label: "Social",
                icon: <Icon.SocialMedia />,
              }}
              showIcons
            />
          </BeforeLastMediaFiltersGridItem>
          <LastFiltersGridItem disabled={false}>
            <FavoriteButton
              onClick={() => shouldEnableFilters && onFavoriteClick()}
              isSelected={currentFilters.onlyFavorites}
              absoluteOnBigScreen={!!hasFootageScoring}
              disabled={!shouldEnableFilters}
            >
              {currentFilters.onlyFavorites ? <Icon.HeartWhite /> : <Icon.HeartEmpty />}
            </FavoriteButton>
          </LastFiltersGridItem>
        </FiltersGrid>
        <SideWrapper>
          <span className="show-mobile-size">
            <StyledTooltip
              disabled={originalVideoAvailable.isAvailable}
              text={originalVideoAvailable.message}
              placement="bottom"
              maxWidth={180}
              textAlign="center"
            >
              <RedirectToFootagePlayer
                disabled={!originalVideoAvailable.isAvailable}
                onClick={() => {
                  navigate(applyPathParams(absoluteRoutes.platform.children.player, { footageId }));
                  footageAnalytics.trackFootagePreviewButtonClick(footageId, "snippets-footage-preview-button");
                }}
              >
                <Icon.VideoCounter size={18} color="black" />
                Original Video
              </RedirectToFootagePlayer>
            </StyledTooltip>
          </span>
          <SideButtonsWrapper>
            <VerticalDivider />
            <CollapseAllBtn
              variant="link"
              size="medium"
              onClick={() => onExpandCollapseAllClick()}
              disabled={!Object.keys(clipsExpanded).length}
            >
              {isExpandedAll ? "Collapse All" : "Expand All"}
            </CollapseAllBtn>
          </SideButtonsWrapper>
        </SideWrapper>
      </FiltersWrapper>
      <StyledScrollableContainer ref={containerRef}>
        <RecentlyEditedSection footageId={footageId} recentlyEditedSequences={recentlyEditedSequences} />

        {Object.keys(CLIPS_ORDER).map((type) => {
          const clipsBySource = filteredClips?.filter((cl) => cl.type === type);

          if (clipsBySource?.length) {
            return clipsBySource.map((clip) => (
              <FootageClip
                footageId={footageId}
                clip={clip}
                key={`${clip.id}-${clip.type}`}
                currentFilters={currentFilters}
                durationRange={durationRange || null}
                hasFootageScoring={hasFootageScoring}
                clipsExpanded={clipsExpanded}
                setClipsExpanded={setClipsExpanded}
                expendWhenReady={!recentlyEditedSequences?.length}
              />
            ));
          }
          return null;
        })}
      </StyledScrollableContainer>
    </SequencesContainer>
  );
}
