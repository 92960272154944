import {
  BlankLineItem,
  FillerWordLineItem,
  HighlightedWordLineItem,
  TimeInterval,
  WordLineItem,
  Word,
  WordTimeInterval,
} from "src/types/video-trimmer.types";
import { differenceWith, last, partition, values } from "lodash";
import { timeIntervalUtils } from "src/utils/timeInterval.utils";
import { sortBy } from "lodash/fp";
import { V2_Word } from "src/network/graphql/generatedGraphqlSDK";
import apiClient from "src/network/ApiClient";

export const binarySearch = (
  word: HighlightedWordLineItem | WordLineItem | FillerWordLineItem,
  intervals: TimeInterval[],
): boolean => {
  const sortedIntervals = intervals.sort((a, b) => a.start - b.start);
  let left = 0;
  let right = sortedIntervals.length - 1;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);
    if (word.end <= sortedIntervals[mid].start) {
      right = mid - 1;
    } else if (word.start >= sortedIntervals[mid].end) {
      left = mid + 1;
    } else {
      return true;
    }
  }
  return false;
};

export const isFillerWord = (word: string, fillerWords: string[]): boolean => {
  const lowerCasedWord = word!.toLowerCase();
  const punctuationRegex = /[!?.,]*$/;
  return fillerWords?.includes(lowerCasedWord.replace(punctuationRegex, ""));
};

export const getBlanks = (
  absoluteWords: WordTimeInterval[],
  videoDuration: number,
  maxBlanks: number,
  blankMinDuration = 1.5,
): BlankLineItem[] => {
  const inversionBorder = {
    start: 0,
    end: videoDuration,
  };

  const blanks = timeIntervalUtils
    .invertTimeIntervals(absoluteWords, blankMinDuration, inversionBorder)
    .map<BlankLineItem>((t) => {
      const x = timeIntervalUtils.shrink(t, t.start === 0 ? 0 : 0.25, t.end === videoDuration ? 0 : 0.25);

      return {
        ...x,
        kind: "blank",
      };
    });

  // if maxBlanks is -1, return all blanks
  if (maxBlanks === -1) {
    return blanks;
  }

  return blanks.length > maxBlanks
    ? getBlanks(absoluteWords, videoDuration, maxBlanks, blankMinDuration + 0.5)
    : blanks;
};

export const fillDeletedWordsGap = (selectedInterval: TimeInterval, noneSelectedWords: Word[]) => {
  const prev = timeIntervalUtils.findClosestIntervalBefore(noneSelectedWords, selectedInterval.start + 0.01);
  const next = timeIntervalUtils.findClosestIntervalAfter(noneSelectedWords, selectedInterval.end - 0.01);
  const selectionStart = selectedInterval.start;
  const selectionEnd = selectedInterval.end;
  const selectionDuration = selectedInterval.end - selectedInterval.start;
  if (prev) {
    if (next) {
      prev.end += selectionDuration * 0.5;
    } else {
      prev.end = selectionEnd;
    }
  }

  if (next) {
    if (prev) {
      next.start -= selectionDuration * 0.5;
    } else {
      next.start = selectionStart;
    }
  }

  return noneSelectedWords;
};

const getSelectedAndNotSelectedWords = (words: Word[], selectedInterval: TimeInterval): [Word[], Word[]] => {
  const [wordsInSelection, wordsNotInSelection] = partition(words, (word) =>
    timeIntervalUtils.containsTimeInterval(selectedInterval, word),
  );
  return [wordsInSelection, wordsNotInSelection];
};

const formatWordsString = (wordsString: string): string[] =>
  wordsString
    .trim()
    .replace(/\s+/g, " ")
    .replace(/\s*([.,!?])/g, "$1")
    .split(" ");

const correctedWordsIsEmpty = (correctedWords: string[]): boolean =>
  correctedWords.length === 1 && correctedWords[0] === "";

const isSameLength = (correctedWords: string[], wordsInSelection: Word[]): boolean =>
  correctedWords.length === wordsInSelection.length;

const replaceWordForWord = (
  wordsInSelection: Word[],
  correctedWords: string[],
  configFillerWords: string[],
  wordsNotInSelection: Word[],
): Word[] => {
  const correctedWordsInSelection = wordsInSelection.map((word, index) => ({
    ...word,
    word: correctedWords[index],
    kind: isFillerWord(correctedWords[index], configFillerWords) ? "fillerWord" : "word",
  }));

  return [...correctedWordsInSelection, ...wordsNotInSelection] as Word[];
};

const replaceWholeSelection = (
  correctedWords: string[],
  selectedInterval: TimeInterval,
  configFillerWords: string[],
  wordsNotInSelection: Word[],
): Word[] => {
  const wordsToUpdate = correctedWords.map((word) => ({
    word,
    highlight: false,
    kind: isFillerWord(word, configFillerWords) ? "fillerWord" : "word",
    newLine: false,
  }));

  return [
    ...(timeIntervalUtils.equallyDistributeIntervals(wordsToUpdate, selectedInterval) as Word[]),
    ...wordsNotInSelection,
  ];
};

export const replaceIntervalWords = (
  words: Word[],
  wordsString: string,
  selectedInterval: TimeInterval,
  configFillerWords: string[],
) => {
  const [wordsInSelection, wordsNotInSelection] = getSelectedAndNotSelectedWords(words, selectedInterval);
  let newAllWords: Word[] = [];

  const correctedWords = formatWordsString(wordsString);

  if (correctedWordsIsEmpty(correctedWords)) {
    newAllWords = wordsNotInSelection;
  } else if (isSameLength(correctedWords, wordsInSelection)) {
    newAllWords = replaceWordForWord(wordsInSelection, correctedWords, configFillerWords, wordsNotInSelection);
  } else {
    newAllWords = replaceWholeSelection(correctedWords, selectedInterval, configFillerWords, wordsNotInSelection);
  }
  return {
    modifiedWords: sortBy("start", newAllWords),
    selectedWordsAmount: wordsInSelection.length,
    selectionDuration: selectedInterval.end - selectedInterval.start,
    amountDiff: (correctedWordsIsEmpty(correctedWords) ? 0 : correctedWords.length) - wordsInSelection.length,
  };
};

export const getWordsDiff = (currentWordsState: Word[], initialWordsState: Word[]): Word[] => {
  const comparator = (word1: Word, word2: Word) =>
    word1.word === word2.word &&
    word1.start === word2.start &&
    word1.end === word2.end &&
    word1.highlight === word2.highlight;

  const wordsDiff = differenceWith(currentWordsState, initialWordsState, comparator);
  return values(wordsDiff);
};

export const createWordsDiffChunksDto = (currentWordsState: Word[], initialWordsState: Word[], clipFrom: number) => {
  const diffWords = getWordsDiff(currentWordsState, initialWordsState);
  const sortedDiffWords: Word[] = sortBy("startTime", diffWords);
  const groupConsecutiveWords = (): V2_Word[][] =>
    currentWordsState
      .reduce(
        (acc: V2_Word[][], current: Word) => {
          const lastGroup: V2_Word[] | undefined = last(acc);
          if (lastGroup && sortedDiffWords.includes(current)) {
            lastGroup.push({
              word: current.word,
              startTime: +(current.start * 1000 + clipFrom * 1000).toFixed(),
              endTime: +(current.end * 1000 + clipFrom * 1000).toFixed(),
              highlight: current.highlight,
            });
          } else {
            lastGroup?.length && acc.push([]);
          }
          return acc;
        },
        [[]],
      )
      .filter((group) => group.length);
  return groupConsecutiveWords();
};

export const createGapsDiffDto = (
  currentWords: Word[],
  originalWords: Word[],
  duration: number,
  clipFrom: number,
): { startTime: number; endTime: number }[] => {
  const allGaps = timeIntervalUtils.invertTimeIntervals(currentWords, 0, {
    start: 0,
    end: duration,
  });
  const newGaps: { startTime: number; endTime: number }[] = allGaps.flatMap((gap) => {
    const wordsInGap: Word[] = originalWords.filter((word) => timeIntervalUtils.containsTimeInterval(gap, word));
    const gapInterval: TimeInterval = { start: wordsInGap[0]?.start, end: wordsInGap[wordsInGap.length - 1]?.end };
    return gapInterval.start && gapInterval.end
      ? [
          {
            startTime: +(gapInterval.start * 1000 + clipFrom * 1000).toFixed(),
            endTime: +(gapInterval.end * 1000 + clipFrom * 1000).toFixed(),
          },
        ]
      : [];
  });

  return newGaps;
};
export async function downloadFootageTranscript(footageId: string, authToken: string) {
  const response = await apiClient.getFootageTranscriptText(footageId, authToken);
  if (!response?.data) throw new Error(`Failed to fetch transcript. Status: ${response}`);

  let downloadLink: HTMLAnchorElement | null = document.createElement("a");
  downloadLink.href = URL.createObjectURL(response.data);
  downloadLink.download = `transcript_${footageId}.txt`;
  downloadLink.click();
  downloadLink = null;
}
