import styled from "styled-components/macro";
import { ifProp } from "styled-tools";

import LanguageSelectOption from "src/components/common/LanguageSelector/LanguageSelectOption";
import LanguageSelectSingleValue from "src/components/common/LanguageSelector/LanguageSelectSingleValue";
import Select from "src/components/common/form/inputs/Select.styled";
import { LanguageOption, useAppConfig } from "src/components/providers/AppConfigProvider";
import { themeColor } from "src/utils/styledComponents.utils";

const StyledSelect = styled(Select<LanguageOption>)<{ isLoading: boolean; darkBackground: boolean }>`
  flex: 1;
  .select__control {
    background-color: ${ifProp("darkBackground", "transparent", themeColor("white"))};
    opacity: ${ifProp("isLoading", 1, 0.8)};
    transition: opacity 250ms ease-out;
    border: ${ifProp("darkBackground", "unset", "solid 1px")} !important;

    &:hover,
    &:focus,
    &:focus-within {
      background-color: ${ifProp("darkBackground", "transparent", themeColor("white"))} !important;
    }

    .select__value-container {
      padding: 2px 8px;
      color: ${ifProp("darkBackground", themeColor("white"), themeColor("gray.600"))} !important;
    }
  }

  .select__indicator {
    cursor: pointer;
    svg {
      fill: ${ifProp("darkBackground", themeColor("white"), themeColor("gray.600"))} !important;
    }
  }

  .select__control--menu-is-open {
    opacity: 1;
  }

  .select__placeholder {
    color: ${ifProp("darkBackground", themeColor("white"), themeColor("gray.600"))} !important;
  }
`;

const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
  border-radius: 4px;
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  background-color: ${themeColor("white")};
  /* opacity: 0.2; */
`;

export interface LanguageSelectProps {
  selectedLanguage: string;
  onChangeLanguage: (languageCode: string) => void;
  isLoading: boolean;
  isDisabled: boolean;
  darkBackground?: boolean;
  languagesList?: LanguageOption[];
}

export default function LanguageSelect({
  selectedLanguage,
  onChangeLanguage,
  isLoading,
  isDisabled,
  darkBackground,
  languagesList = [],
}: LanguageSelectProps) {
  const { SUPPORTED_LANGUAGES } = useAppConfig();
  if (languagesList.length === 0) {
    languagesList = SUPPORTED_LANGUAGES;
  }
  return (
    <SelectWrapper>
      <Overlay />
      <StyledSelect
        isSearchable
        value={languagesList.find((lang) => lang.languageCode === selectedLanguage)}
        options={languagesList.filter((lang) => lang.status === "ACTIVE")}
        getOptionLabel={(x) => x.label!}
        getOptionValue={(x) => x.languageCode!}
        onChange={(item) => onChangeLanguage(item?.languageCode!)}
        isDisabled={isDisabled}
        isLoading={isLoading}
        darkBackground={!!darkBackground}
        placeholder="Select video language"
        components={{
          Option: LanguageSelectOption,
          SingleValue: LanguageSelectSingleValue,
        }}
      />
    </SelectWrapper>
  );
}
