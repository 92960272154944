export enum SupportedRatio {
  Ratio_16_9 = "16:9",
  Ratio_1_1 = "1:1",
  Ratio_9_16 = "9:16",
}

type AspectRatio = {
  name: SupportedRatio;
  value: number;
};

export const roundBy = (by: number, value: number) => {
  const roundFactor = 1 / by;

  return Math.round(value * roundFactor) / roundFactor;
};

export const calculateAspectRatio = (width: number, height: number): SupportedRatio => {
  const aspectRatios: AspectRatio[] = [
    { name: SupportedRatio.Ratio_16_9, value: 16 / 9 },
    { name: SupportedRatio.Ratio_1_1, value: 1 },
    { name: SupportedRatio.Ratio_9_16, value: 9 / 16 },
  ];

  const videoRatio = width / height;

  let closestRatioName = aspectRatios[0].name;
  let minDifference = Math.abs(aspectRatios[0].value - videoRatio);

  aspectRatios.forEach((ratio) => {
    const difference = Math.abs(ratio.value - videoRatio);
    if (difference < minDifference) {
      closestRatioName = ratio.name;
      minDifference = difference;
    }
  });

  return closestRatioName;
};
