import { createContext, useContext } from "react";

import { UseHistoryStateReturnValue } from "src/hooks/useHistoryState";
import { InitialHistoryState } from "src/components/features/VideoTrimmer/common/hooks/useInitiatedHistory";
import { DragPosition } from "src/utils/cropData.utils";
import { TimeIntervalEdge } from "src/types/video-trimmer.types";
import { CropsStyle, CropTimeInterval } from "src/types/video-cropper.types";
import { AlignType } from "src/components/features/VideoCropper/hooks/useCropData";

export type CropDataResizeLimit = {
  minScale: number;
  maxScale: number;
  limitBy: "width" | "height";
};
export interface VideoCropContextValue {
  crops: CropTimeInterval[];
  cropsHistory: Pick<
    UseHistoryStateReturnValue<InitialHistoryState>,
    "undo" | "redo" | "canUndo" | "canRedo" | "resetFuture" | "onSet"
  >;
  activeCropIndex: number;
  resizedCropIndex: number;
  draggingPosition: DragPosition;
  setDraggingPosition: (position: DragPosition) => void;
  cropScale: number;
  resizeLimit: CropDataResizeLimit;
  getCropFill: () => DragPosition;
  getCropFit: () => DragPosition;
  alignCrop: (align: AlignType) => DragPosition;
  rescaleCrop: (scale: number) => void;
  setCropCords: () => void;
  startCropTimeIntervalResizing(index: number, edge: TimeIntervalEdge): void;
  resizeCropTimeInterval(index: number, edge: TimeIntervalEdge, timeDelta: number): void;
  endCropTimeIntervalResizing(index: number, edge: TimeIntervalEdge): void;
  isCropSplittable: () => boolean;
  isCropDeletable: () => boolean;
  splitCrop: () => void;
  deleteCrop: () => void;
  seekForward: () => void;
  seekBackward: () => void;
  setStyle: (style: Partial<CropsStyle>) => void;
  style: CropsStyle;
  backgroundColor: string;
  copyDragPosition: () => void;
  pasteDragPosition: () => void;
  copiedDragPosition: DragPosition | null;
}

const VideoCropContext = createContext<VideoCropContextValue | null>(null);

export default VideoCropContext;

export const useVideoCrop = () => {
  const contextValue = useContext(VideoCropContext);

  if (!contextValue) {
    throw new Error("useVideoCropContext must be used within VideoCropProvider");
  }

  return contextValue;
};
