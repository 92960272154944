/* eslint-disable no-confusing-arrow */
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

import ResponsivePeechItem from "src/components/common/ResponsiveLibrary/ResponsivePeechItem";
import Tooltip from "src/components/common/popovers/Tooltip";
import { AspectRatio, getAspectRatioValue } from "src/constants/video.constants";
import { assetSelectors } from "src/models/Asset.model";
import { RootState } from "src/models/store";
import { themeColor } from "src/utils/styledComponents.utils";
import styled, { css, keyframes } from "styled-components/macro";
import { ifProp } from "styled-tools";

import { useAssetThumbnailUrl } from "src/hooks/useAssetThumbnailUrl";
import PEECH_THUMB from "src/assets/images/peech_thumbnail.png";
import { formatSeconds } from "src/utils/time.utils";

export const ItemContentWrapper = styled.div<{ isItemSelected?: boolean }>`
  width: 100%;
  height: fit-content;
  transition: border-color 250ms ease-out;
  border: solid 2px;
  border-radius: 11px;
  border-color: ${ifProp("isItemSelected", themeColor("pink.500"), themeColor("white"))};
`;

const scaleIn = keyframes`
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
`;

export const SelectedNumberDiv = styled.div<{ hide: boolean }>`
  position: absolute;
  top: 10px;
  left: 10px;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${themeColor("white")};
  background-color: transparent;
  border-radius: 4px;
  transition: background-color 350ms ease-out;
  span {
    font-family: "Work Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    visibility: ${ifProp("hide", "hidden", "visible")};
    transform: ${ifProp("hide", "scale(0)", "scale(1)")};
    animation: ${(props) =>
      props.hide
        ? "none"
        : css`
            ${scaleIn} 250ms ease-out;
          `};
  }
`;

export const DurationDiv = styled.div<{ hide: boolean }>`
  position: absolute;
  bottom: 9px;
  right: 6px;
  height: 26px;
  width: 50px;
  justify-content: center;
  align-items: center;
  color: ${themeColor("gray.900")};
  background-color: ${themeColor("white")};
  border-radius: 90px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  display: ${ifProp("hide", "none", "flex")};
`;

const BluredDiv = styled.div<{ hide: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  background-color: ${themeColor("gray.900")};
  opacity: 60%;
  width: 100%;
  height: 100%;
  display: none;
  visibility: ${ifProp("hide", "hidden", "visible")};
`;

export const ItemContent = styled.div<{ aspectRatio: string; shouldGrayscale?: boolean; isItemSelected?: boolean }>`
  position: relative;
  width: 100%;
  aspect-ratio: ${(props) => getAspectRatioValue(props.aspectRatio as AspectRatio)};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${themeColor("white")};
  border-radius: 10px;
  cursor: pointer;
  transition: box-shadow 250ms ease-out;

  ${SelectedNumberDiv} {
    background-color: ${ifProp("isItemSelected", themeColor("pink.500"), "transparent")};
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    aspect-ratio: ${(props) => getAspectRatioValue(props.aspectRatio as AspectRatio)};
    border-radius: 8px;
    filter: ${ifProp("shouldGrayscale", "grayscale(100%)", "none")};
  }

  &:hover {
    box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.3);

    ${SelectedNumberDiv} {
      background-color: ${ifProp("isItemSelected", themeColor("pink.500"), themeColor("pink.300"))};
    }

    ${BluredDiv} {
      display: block;
    }
  }
`;

export const ItemFooter = styled.div<{ numRows: number }>`
  width: 100%;
  min-height: 30px;
  background-color: transparent;
  span {
    min-height: ${(props) => (props.numRows > 1 ? "34px" : "20px")};
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 9px 10px 2px 0;
    color: ${themeColor("gray.500")};
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    white-space: ${(props) => (props.numRows > 1 ? "initial" : "pre-wrap")};

    @supports (-webkit-line-clamp: 2) {
      -webkit-line-clamp: ${(props) => props.numRows};
      -webkit-box-orient: vertical;
    }
  }
`;

interface AssetItemContentProps {
  sid: string;
  aspectRatio: string;
  onItemSelected: (assetSid: string) => void;
  isItemSelected: (assetSid: string) => boolean;
  getSelectedIndex: (assetSid: string) => number | undefined;
}

export default function AssetItemContent({
  sid,
  aspectRatio,
  onItemSelected,
  isItemSelected,
  getSelectedIndex,
}: AssetItemContentProps) {
  const asset = useSelector((state: RootState) => assetSelectors.selectById(state, sid));
  const onAssetItemClick = useCallback(() => onItemSelected?.(sid), [onItemSelected, sid]);
  const thumbnailUrl = useAssetThumbnailUrl({ sid: asset?.sid ?? "", aspectRatio, width: 100 });

  const selectedNumber = useMemo(() => {
    const index = getSelectedIndex(sid);

    if (index === undefined || index < 0) {
      return -1;
    }

    return index + 1;
  }, [getSelectedIndex, sid]);

  const duration = useMemo(() => formatSeconds(asset!.duration!, "mm:ss"), [asset]);

  if (!asset) {
    return null;
  }

  return (
    <ResponsivePeechItem onClick={onAssetItemClick} isTransparent verticalGutter={16}>
      <ItemContentWrapper isItemSelected={isItemSelected(sid)}>
        <ItemContent aspectRatio={aspectRatio} isItemSelected={isItemSelected(sid)}>
          <img src={PEECH_THUMB} alt={" "} />
          {thumbnailUrl && (
            <img
              src={thumbnailUrl as string}
              alt={asset?.name!}
              onError={(e) => {
                e.currentTarget.style.display = "none";
              }}
              draggable={false}
            />
          )}
          <BluredDiv hide={!duration} />
          <SelectedNumberDiv hide={selectedNumber === -1}>
            <span>{selectedNumber}</span>
          </SelectedNumberDiv>
          <DurationDiv hide={!duration}>{duration}</DurationDiv>
        </ItemContent>
      </ItemContentWrapper>
      <ItemFooter numRows={1}>
        <Tooltip text={asset?.name ?? ""} placement="top-end">
          <span>{asset?.name}</span>
        </Tooltip>
      </ItemFooter>
    </ResponsivePeechItem>
  );
}
