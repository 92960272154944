import { useCallback } from "react";
import { useSelector } from "react-redux";

import { RootState } from "src/models/store";

import { OLD_APP_URL } from "src/constants/env.constants";

export function useGetOldAppLink() {
  const authToken = useSelector((state: RootState) => state.session.authToken);

  return useCallback(
    (
      url: string,
      search: string = "", // TODO: remove
    ) => {
      const encodedURL = encodeURIComponent(url + search);
      const encodedAuthToken = encodeURIComponent(authToken!);

      return `${OLD_APP_URL}/impersonate-redirect/?redirectUrl=${encodedURL}&authToken=${encodedAuthToken}`;
    },
    [authToken],
  );
}

// TODO: remove
export default function useOldAppRedirect() {
  const getOldAppLink = useGetOldAppLink();

  return useCallback(
    (url: string, search: string = "", replace = false) => {
      const oldAppLink = getOldAppLink(url, search);

      if (replace) {
        window.location.replace(oldAppLink);
      } else {
        window.location.assign(oldAppLink);
      }
    },
    [getOldAppLink],
  );
}
