import { useMemo } from "react";
import {
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  matchPath,
  useLocation,
} from "react-router-dom";

import AuthenticatedUsersGate from "src/components/app/AppRouter/gates/AuthenticatedUsersGate";
import GuestUsersGate from "src/components/app/AppRouter/gates/GuestUsersGate";
import MobileNotAllowedGate from "src/components/app/AppRouter/gates/MobileNotAllowedGate";
import OnboardingDoneGate from "src/components/app/AppRouter/gates/OnboardingDoneGate";
import PlanChosenGate from "src/components/app/AppRouter/gates/PlanChosenGate";
import SharedSequenceGate from "src/components/app/AppRouter/gates/SharedSequenceGate";

import BillingPage from "src/components/pages/Billing.page";
import BrandPage from "src/components/pages/Brand.page";
import CreateSequenceWizardPage from "src/components/pages/CreateSequenceWizard.page";
import FastCreateSequencePage from "src/components/pages/FastCreateSequence.page";
import HomePage from "src/components/pages/Home.page";
import IconsPreviewPage from "src/components/pages/IconsPreview.page";
import LoginPage from "src/components/pages/Login.page";
import LoginSignupLayoutPage from "src/components/pages/LoginSignupLayout.page";
import LogoutPage from "src/components/pages/Logout.page";
import MobileUploaderPage from "src/components/pages/MobileUploader.page";
import OnboardingPage from "src/components/pages/Onboarding.page";
import PlansPage from "src/components/pages/Plans.page";
import ResetPasswordPage from "src/components/pages/ResetPassword.page";
import ResetPasswordCompletionPage from "src/components/pages/ResetPasswordCompletion.page";
import ResetPasswordEmailVerificationPage from "src/components/pages/ResetPasswordEmailVerification.page";
import SequencePage from "src/components/pages/Sequence.page";
import SequenceContentEditorPage from "src/components/pages/SequenceContentEditor.page";
import SequenceCropEditorPage from "src/components/pages/SequenceCropEditor.page";
import SequenceLibraryPage from "src/components/pages/SequenceLibrary.page";
import SequenceProcessingPage from "src/components/pages/SequenceProcessing.page";
import SequenceVisualsEditorPage from "src/components/pages/SequenceVisualsEditor.page";
import SignUpPage from "src/components/pages/SignUp.page";
import SignUpCompletionPage from "src/components/pages/SignUpCompletion.page";
import SignUpEmailVerificationPage from "src/components/pages/SignUpEmailVerification.page";
import UpgradeFeaturePage from "src/components/pages/UpgradeFeature.page";
import { absoluteRoutes, routerRoutes } from "src/utils/routes.utils";

// TODO: organize imports
import FootageNotExpiredGate from "src/components/app/AppRouter/gates/FootageNotExpiredGate";
import WithPlatformHeader from "src/components/app/AppRouter/layouts/WithPlatformHeader";
import AutomaticCreateWizardPage from "src/components/pages/AutomaticCreateWizard.page";
import FootageLibraryPage from "src/components/pages/FootageLibrary.page";
import FootageSequencesPage from "src/components/pages/FootageSequences.page";
import CropperPOCPage from "src/components/pages/CropperPoc.page";
import { isMobile } from "src/utils/mobile.utils";
import FootageContentEditorPage from "src/components/pages/FootageContentEditor.page";
import FootageClipCreatorPage from "src/components/pages/FootageClipCreator.page";
import CreateClipWizardPage from "src/components/pages/CreateClipWizard.page";
import WithEditorHeader from "./layouts/WithEditorHeader";
import WithPlansHeader from "./layouts/WithPlansHeader";

function AppLayout() {
  const location = useLocation();

  return useMemo(() => {
    switch (true) {
      case !!matchPath({ path: absoluteRoutes.projectSequence.children.processing }, location.pathname):
        return <WithEditorHeader showTabs={false} />;

      case !!matchPath({ path: routerRoutes.projectSequence.path, end: false }, location.pathname):
        return <WithEditorHeader showTabs={!isMobile()} />;

      case !!matchPath({ path: routerRoutes.plans, end: false }, location.pathname):
        return <WithPlansHeader />;

      case !!matchPath(
        { path: absoluteRoutes.platform.children.boards.replace("?", ""), end: false },
        location.pathname,
      ):
        return <WithPlatformHeader />;

      case !!matchPath({ path: routerRoutes.platform.path, end: true }, location.pathname):
        return <WithPlatformHeader />;

      case !!matchPath({ path: routerRoutes.mobileUpload, end: false }, location.pathname):
      case !!matchPath({ path: routerRoutes.platform.path, end: false }, location.pathname):
        return <WithPlatformHeader logoType="short" backBtn />;

      case !!matchPath({ path: routerRoutes.brand, end: false }, location.pathname):
      case !!matchPath({ path: routerRoutes.billing, end: false }, location.pathname):
        return <WithPlatformHeader logoType="short" backBtn boardSelect={false} />;

      case !!matchPath({ path: routerRoutes.onboarding.path, end: false }, location.pathname):
        return <WithPlatformHeader brandBookButton={false} upgradeButton={false} boardSelect={false} />;

      case !!matchPath({ path: routerRoutes.automaticCreate.path, end: false }, location.pathname):
        return <WithPlatformHeader brandBookButton={false} upgradeButton={false} boardSelect={false} backBtn />;

      default:
        return <Outlet />;
    }
  }, [location.pathname]);
}

export const appRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AppLayout />}>
      <Route path={routerRoutes.upgradeFeature} element={<UpgradeFeaturePage />} />

      <Route element={<AuthenticatedUsersGate />}>
        <Route path={routerRoutes.plans} element={<PlansPage />} />
        <Route path={routerRoutes.logout} element={<LogoutPage />} />
        <Route path={routerRoutes.mobileUpload} element={<MobileUploaderPage />} />

        <Route element={<SharedSequenceGate />}>
          <Route element={<FootageNotExpiredGate />}>
            <Route element={<PlanChosenGate />}>
              <Route element={<MobileNotAllowedGate />}>
                <Route path={routerRoutes.onboarding.path}>
                  <Route path={routerRoutes.onboarding.children.index} element={<OnboardingPage />} />
                  <Route path={routerRoutes.onboarding.children.step} element={<OnboardingPage />} />
                </Route>
              </Route>

              <Route element={<OnboardingDoneGate />}>
                <Route path={routerRoutes.home} element={<HomePage />} />

                <Route path={routerRoutes.createProjectFast} element={<FastCreateSequencePage />} />
                <Route path={routerRoutes.createProject.path}>
                  <Route path={routerRoutes.createProject.children.index} element={<CreateSequenceWizardPage />} />
                  <Route path={routerRoutes.createProject.children.step} element={<CreateSequenceWizardPage />} />
                </Route>

                <Route path={routerRoutes.automaticCreate.path}>
                  <Route path={routerRoutes.automaticCreate.children.index} element={<AutomaticCreateWizardPage />} />
                  <Route path={routerRoutes.automaticCreate.children.step.path}>
                    <Route path={routerRoutes.automaticCreate.children.index} element={<AutomaticCreateWizardPage />} />
                    <Route
                      path={routerRoutes.automaticCreate.children.step.children.subStep}
                      element={<AutomaticCreateWizardPage />}
                    />
                  </Route>
                </Route>

                <Route path={routerRoutes.createClip.path}>
                  <Route path={routerRoutes.createClip.children.index} element={<CreateClipWizardPage />} />
                  <Route path={routerRoutes.createClip.children.step.path}>
                    <Route path={routerRoutes.createClip.children.index} element={<CreateClipWizardPage />} />
                    <Route
                      path={routerRoutes.createClip.children.step.children.subStep}
                      element={<CreateClipWizardPage />}
                    />
                  </Route>
                </Route>

                <Route path={routerRoutes.platform.path}>
                  <Route path={routerRoutes.platform.children.index} element={<FootageLibraryPage />} />
                  <Route path={routerRoutes.platform.children.footage} element={<FootageSequencesPage />} />
                  <Route path={routerRoutes.platform.children.player} element={<FootageContentEditorPage />} />
                  <Route path={routerRoutes.platform.children.clipCreator} element={<FootageClipCreatorPage />} />
                  <Route path={routerRoutes.platform.children.boards} element={<FootageLibraryPage />} />
                </Route>

                <Route path={routerRoutes.projectSequence.path} element={<SequencePage />}>
                  <Route path={routerRoutes.projectSequence.children.processing} element={<SequenceProcessingPage />} />
                </Route>

                <Route element={<MobileNotAllowedGate />}>
                  <Route path={routerRoutes.projectSequence.path} element={<SequencePage />}>
                    <Route
                      path={routerRoutes.projectSequence.children.contentEditor}
                      element={<SequenceContentEditorPage />}
                    />
                    <Route
                      path={routerRoutes.projectSequence.children.cropEditor}
                      element={<SequenceCropEditorPage />}
                    />
                    <Route
                      path={routerRoutes.projectSequence.children.visualsEditor}
                      element={<SequenceVisualsEditorPage />}
                    />
                  </Route>

                  <Route path={routerRoutes.brand} element={<BrandPage />} />
                  <Route path={routerRoutes.billing} element={<BillingPage />} />
                  {/* for non smart library users */}
                  <Route path={routerRoutes.projects} element={<SequenceLibraryPage />} />
                </Route>
                {/* development use only */}
                <Route path={routerRoutes.iconsPreview} element={<IconsPreviewPage />} />
                <Route path={routerRoutes.cropperPoc} element={<CropperPOCPage />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>

      <Route element={<GuestUsersGate />}>
        <Route path={routerRoutes.signup.path}>
          <Route path={routerRoutes.signup.children.emailVerification} element={<SignUpEmailVerificationPage />} />
          <Route path={routerRoutes.signup.children.token} element={<SignUpCompletionPage />} />
          <Route element={<LoginSignupLayoutPage />}>
            <Route path="" element={<SignUpPage />} />
          </Route>
        </Route>

        <Route path={routerRoutes.forgotPassword.path}>
          <Route path={routerRoutes.forgotPassword.children.index} element={<ResetPasswordPage />} />
          <Route
            path={routerRoutes.forgotPassword.children.emailVerification}
            element={<ResetPasswordEmailVerificationPage />}
          />
          <Route path={routerRoutes.forgotPassword.children.token} element={<ResetPasswordCompletionPage />} />
        </Route>

        <Route path={routerRoutes.login} element={<LoginSignupLayoutPage />}>
          <Route path="" element={<LoginPage />} />
        </Route>
      </Route>
    </Route>,
  ),
);

export default function AppRouter() {
  return <RouterProvider router={appRouter} />;
}
