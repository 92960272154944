import useMousetrap from "src/hooks/useMousetrap";
import { useTimelineZoom } from "src/components/features/VideoTrimmer/providers/TimelineZoomProvider/TimelineZoomContext";

import * as cropAnalytics from "src/analytics/sequenceCropEditor.analytics";
import { useVideoCrop } from "src/components/features/VideoCropper/providers/VideoCropProvider/VideoCropContext";
import { AlignType } from "src/components/features/VideoCropper/hooks/useCropData";
import { ShortcutsKeys } from "src/constants/shortcuts.constants";
import { useState } from "react";
import toast from "react-hot-toast";

export default function VideoCropShortcuts() {
  const [alignActive, setAlignActive] = useState(false);
  const {
    crops,
    cropsHistory,
    activeCropIndex,
    splitCrop,
    deleteCrop,
    seekBackward,
    seekForward,
    setDraggingPosition,
    draggingPosition,
    setCropCords,
    cropScale,
    rescaleCrop,
    getCropFill,
    getCropFit,
    resizeLimit,
    alignCrop,
    startCropTimeIntervalResizing,
    endCropTimeIntervalResizing,
    resizeCropTimeInterval,
    copiedDragPosition,
    copyDragPosition,
    pasteDragPosition,
  } = useVideoCrop();

  const {
    toggleFocusTimeInterval,
  } = useTimelineZoom(); // prettier-ignore

  // undo/redo
  useMousetrap(
    [ShortcutsKeys.undo],
    () => {
      cropsHistory.undo();
      cropAnalytics.trackUndo("keyboard-shortcut");
    },
    { preventDefault: true, allowRepeat: true },
  );
  useMousetrap(
    [ShortcutsKeys.redo],
    () => {
      cropsHistory.redo();
      cropAnalytics.trackRedo("keyboard-shortcut");
    },
    { preventDefault: true, allowRepeat: true },
  );

  // resize timeInterval
  useMousetrap(
    ["alt+left", "alt+right", "alt+shift+left", "alt+shift+right"] as const,
    (e, combo, action) => {
      const edge = combo.includes("shift") ? "end" : "start";
      if (action === "keyup") {
        endCropTimeIntervalResizing(activeCropIndex, edge);
        return;
      }
      const frameDuration = 1 / 30;
      if (!e.repeat) {
        startCropTimeIntervalResizing(activeCropIndex, edge);
        cropAnalytics.trackTimeResize(edge === "start" ? "from-start-keyboard-shortcut" : "from-end-keyboard-shortcut");
      }

      requestAnimationFrame(() => {
        resizeCropTimeInterval(activeCropIndex, edge, combo.includes("right") ? frameDuration + 0.01 : -frameDuration);
      });
    },
    { preventDefault: true, allowRepeat: true, action: ["keypress", "keyup"] },
  );

  // split/delete
  useMousetrap(
    ShortcutsKeys.split,
    () => {
      splitCrop();
      cropAnalytics.trackSplit("keyboard-shortcut");
    },
    { preventDefault: true },
  );
  useMousetrap(
    [ShortcutsKeys.delete],
    () => {
      deleteCrop();
      cropAnalytics.trackRemove("keyboard-shortcut");
    },
    { preventDefault: true },
  );

  useMousetrap(["mod+o"], () => toggleFocusTimeInterval(crops[activeCropIndex], crops.length), { preventDefault: true }); // prettier-ignore

  // seek
  useMousetrap(
    [ShortcutsKeys.forward],
    () => {
      seekForward();
      cropAnalytics.trackSeekForward("keyboard-shortcut");
    },
    { preventDefault: true, allowRepeat: true },
  );
  useMousetrap(
    [ShortcutsKeys.backward],
    () => {
      seekBackward();
      cropAnalytics.trackSeekBackward("keyboard-shortcut");
    },
    { preventDefault: true, allowRepeat: true },
  );

  // set x position
  useMousetrap(
    ["shift+right", "shift+left", "shift+ctrl+right", "shift+ctrl+left"] as const,
    (e, combo, action) => {
      if (action === "keyup") {
        requestAnimationFrame(() => {
          setCropCords();
          cropAnalytics.trackCropResizeAndPosition("keyboard-shortcut");
        });
        return;
      }

      requestAnimationFrame(() => {
        const stepSize = combo.includes("ctrl") ? 10 : 1;
        const nexX = draggingPosition.x + (combo.includes("right") ? stepSize : -stepSize);
        setDraggingPosition({ ...draggingPosition, x: nexX });
      });
    },
    { preventDefault: true, allowRepeat: true, action: ["keypress", "keyup"] },
  );

  // set y position
  useMousetrap(
    ["shift+up", "shift+down", "shift+ctrl+up", "shift+ctrl+down"] as const,
    (e, combo, action) => {
      if (action === "keyup") {
        setCropCords();
        cropAnalytics.trackCropResizeAndPosition("keyboard-shortcut");
        return;
      }

      requestAnimationFrame(() => {
        const stepSize = combo.includes("ctrl") ? 10 : 1;
        const nexY = draggingPosition.y + (combo.includes("down") ? stepSize : -stepSize);
        setDraggingPosition({ ...draggingPosition, y: nexY });
      });
    },
    { preventDefault: true, allowRepeat: true, action: ["keypress", "keyup"] },
  );

  // change scale
  useMousetrap(
    ["shift+=", "shift++", "shift+-", "shift+ctrl+=", "shift+ctrl+-"] as const,
    (e, combo, action) => {
      if (action === "keyup") {
        setCropCords();
        cropAnalytics.trackCropResizeAndPosition("keyboard-shortcut");
        return;
      }

      requestAnimationFrame(() => {
        const stepSize = combo.includes("ctrl") ? 0.1 : 0.01;
        const newScale = cropScale + (combo.includes("-") ? -stepSize : stepSize);
        const s = Math.max(Math.min(newScale, resizeLimit.maxScale), resizeLimit.minScale);
        rescaleCrop(s);
      });
    },
    { preventDefault: true, allowRepeat: true, action: ["keypress", "keyup"] },
  );

  // set crop to fill
  useMousetrap(
    ShortcutsKeys.cropFill,
    () => {
      setDraggingPosition(getCropFill());
      setTimeout(() => {
        setCropCords();
      }, 1);
      cropAnalytics.trackCropFill("keyboard-shortcut");
    },
    { preventDefault: true },
  );

  // set crop to fit
  useMousetrap(
    ShortcutsKeys.cropFit,
    () => {
      setDraggingPosition(getCropFit());
      setTimeout(() => {
        setCropCords();
      }, 1);
      cropAnalytics.trackCropFit("keyboard-shortcut");
    },
    { preventDefault: true },
  );

  // copy drag position
  useMousetrap(
    ShortcutsKeys.copyDragPosition,
    () => {
      copyDragPosition();
      toast.success("Copied to clipboard");
      cropAnalytics.trackCropCopy("keyboard-shortcut");
    },
    { preventDefault: true },
  );

  // paste drag position
  useMousetrap(
    ShortcutsKeys.pasteDragPosition,
    () => {
      if (!copiedDragPosition) return;
      pasteDragPosition();
      toast.success("Pasted successfully");
      cropAnalytics.trackCropCopy("keyboard-shortcut");
    },
    { preventDefault: true },
  );

  // center crop
  useMousetrap(
    "shift+c",
    () => {
      setDraggingPosition(alignCrop("MC"));
      setTimeout(() => {
        setCropCords();
      }, 1);
      cropAnalytics.trackAlignCrop("keyboard-shortcut", "MC");
    },
    { preventDefault: true },
  );
  // align crop
  useMousetrap(
    ["a", "a+c", "a+m", "a+l", "a+r", "a+t", "a+b"] as const,
    (event, combo, action) => {
      if (action === "keydown" && combo === "a") {
        setAlignActive(true);
        return;
      }

      if (action === "keyup" && combo === "a") {
        setAlignActive(false);
        setCropCords();
        cropAnalytics.trackCropResizeAndPosition("keyboard-shortcut");
        return;
      }

      if (!alignActive) {
        return;
      }

      let alignTo: AlignType;
      // eslint-disable-next-line default-case
      switch (combo) {
        case "a+c":
          alignTo = "C";
          break;
        case "a+m":
          alignTo = "M";
          break;
        case "a+l":
          alignTo = "L";
          break;
        case "a+r":
          alignTo = "R";
          break;
        case "a+t":
          alignTo = "T";
          break;
        case "a+b":
          alignTo = "B";
          break;
      }

      requestAnimationFrame(() => {
        setDraggingPosition(alignCrop(alignTo));
      });
    },
    { preventDefault: true, allowRepeat: true, action: ["keydown", "keyup"] },
  );
  return null;
}
