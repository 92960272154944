import { TextDirection } from "src/utils/localization.utils";

import { BLANK_WIDTH, FONT_FAMILIES, FONT_SIZE, FONT_WEIGHT } from "src/constants/video-trimmer.constants";
import { BlankLineItem, Line, TranscriptPoint, TranscriptSelection, Word } from "src/types/video-trimmer.types";

export const getTranscriptCanvasContext = (textDirection: TextDirection) => {
  const canvas = document.createElement("canvas")!;
  const canvasContext = canvas.getContext("2d")!;

  canvasContext.font = `${FONT_WEIGHT} ${FONT_SIZE}px ${FONT_FAMILIES[textDirection]}`;

  return canvasContext;
};

export const measureText = (canvasContext: CanvasRenderingContext2D, text: string) =>
  canvasContext.measureText(text).width;

export const getLines = (
  ctx: CanvasRenderingContext2D,
  words: Array<Word>,
  blanks: BlankLineItem[],
  maxWidth: number,
): Line[] => {
  const SPACE_WIDTH = ctx.measureText(" ").width;
  const items = [...words, ...blanks].sort((a, b) => a.start - b.start);
  const lines: Line[] = [];
  let currentLineWidth = 0;

  items.forEach((item) => {
    const currentItemWidth = item.kind === "blank" ? BLANK_WIDTH : ctx.measureText(item.word).width;

    /** HTML line break happens when the width of the line is >= available width */
    /** Also, because there are no pixel fractions in HTML, we must ceil the current canvas text line width */
    if (currentLineWidth && Math.ceil(currentLineWidth + SPACE_WIDTH + currentItemWidth) < maxWidth) {
      const lastLine = lines.at(-1)!;

      lastLine.items.push(item);
      lastLine.end = item.end;
      currentLineWidth += SPACE_WIDTH + currentItemWidth;
    } else {
      const newLine: Line = {
        start: item.start,
        end: item.end,
        items: [item],
      };

      lines.push(newLine);
      currentLineWidth = currentItemWidth;
    }
  });

  return lines;
};

export const getNodeTranscriptPoint = (node: Node | null): Omit<TranscriptPoint, "itemOffset"> | null => {
  if (!node) {
    return null;
  }

  if (node instanceof HTMLElement && node.dataset.lineindex) {
    return {
      lineIndex: Number(node.dataset.lineindex),
      itemIndex: Number(node.dataset.itemindex),
    };
  }

  return getNodeTranscriptPoint(node.parentNode);
};

export const getSelectionEdgeTranscriptPoint = (
  selection: Selection,
  point: "anchor" | "focus",
): TranscriptPoint | null => {
  const selectionPointNode = selection[`${point}Node`];
  const selectionPointOffset = selection[`${point}Offset`];
  const transcriptPoint = getNodeTranscriptPoint(selectionPointNode);
  let baseOffset = 0;

  if (selectionPointNode?.textContent === " ") {
    const spaceElement = selectionPointNode.parentNode as HTMLElement;
    baseOffset = Number(spaceElement.dataset.baseoffset);
  }

  return (
    transcriptPoint && {
      ...transcriptPoint,
      itemOffset: selectionPointOffset + baseOffset,
    }
  );
};

export const compareTranscriptPoints = (point1: TranscriptPoint, point2: TranscriptPoint): number => {
  if (point1.lineIndex > point2.lineIndex) return 1;
  if (point1.lineIndex < point2.lineIndex) return -1;

  if (point1.itemIndex > point2.itemIndex) return 1;
  if (point1.itemIndex < point2.itemIndex) return -1;

  if (point1.itemOffset > point2.itemOffset) return 1;
  if (point1.itemOffset < point2.itemOffset) return -1;

  return 0;
};

export const isSelectionInSelection = (selection: TranscriptSelection, selectionToCheck: TranscriptSelection) =>
  compareTranscriptPoints(selection.start, selectionToCheck.start) === 1 &&
  compareTranscriptPoints(selection.end, selectionToCheck.end) === -1;
