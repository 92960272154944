import { themeColor } from "src/utils/styledComponents.utils";

import styled from "styled-components/macro";

import BaseSwiperSlide from "src/components/common/Swiper/SwiperSlide";

export const SwiperSlide = styled(BaseSwiperSlide)`
  background-color: ${themeColor("gray.50")};
  width: 100%;
  height: 100%;
  padding: 40px 0 74px 0;

  @media (min-width: 768px) {
    padding: 74px 0;
  }
`;
