/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ClipSequenceType, ClipType, FootageStatus } from "src/network/graphql/generatedGraphqlSDK";
import { FileUploadStatus } from "src/services/BaseUpload.service";

import Icon from "src/components/common/Icon";
import { ReactNode } from "react";
import { AspectRatio } from "src/constants/video.constants";
import { ContactSupportBtn, FailedTooltipInfo } from "src/components/features/SmartLibrary/SmartLibrary.styled";
import userEngagementService from "src/services/UserEngagement.service";

export const STATUS_LABEL_DICT = {
  [FootageStatus.IngestingFootage]: "Preparing Footage",
  [FootageStatus.CreatingAssets]: "Processing",
  [FootageStatus.Completed]: "",
  [FootageStatus.Deleted]: "",
  [FootageStatus.UploadingAndDeleted]: "",
  [FootageStatus.Uploading]: "",
  [FootageStatus.Ready]: "",
  [FootageStatus.Failure]: "",
  [FootageStatus.FailedAndDeleted]: "",
};

export const UPLOADING_STATUS_LABEL_DICT = {
  [FileUploadStatus.PENDING]: "Uploading:",
  [FileUploadStatus.UPLOADING]: "Uploading:",
  [FileUploadStatus.SUCCESS]: "Upload success:",
  [FileUploadStatus.PAUSED]: "Upload paused:",
  [FileUploadStatus.ERROR]: "Upload error:",
};

export const SEQUENCES_TYPES_ICONS: Record<ClipSequenceType, ReactNode> = {
  ORIGINAL: <Icon.Original />,
  HIGHLIGHT: <Icon.Highlight />,
  MANUAL: <Icon.Highlight />,
  CUSTOM_SNIPPETS: <Icon.Highlight />,
};

export type SocialMedia =
  | "facebook"
  | "instagram"
  | "linkedin"
  | "pinterest"
  | "tiktok"
  | "vimeo"
  | "youtube"
  | "shorts";

interface AspectRatioOption {
  text: string;
  icon: JSX.Element;
  supportedSocialMedias: SocialMedia[];
}

export const ASPECT_RATIO_OPTIONS_DICT: Record<AspectRatio, AspectRatioOption> = {
  "16:9": {
    text: "Horizontal",
    icon: <Icon.Horizontal />,
    supportedSocialMedias: ["instagram", "vimeo", "youtube", "linkedin", "facebook"],
  },
  "1:1": {
    text: "Square",
    icon: <Icon.Square />,
    supportedSocialMedias: ["facebook", "instagram", "linkedin", "pinterest"],
  },
  "9:16": {
    text: "Vertical",
    icon: <Icon.Vertical />,
    supportedSocialMedias: ["instagram", "tiktok", "shorts"],
  },
};

export const CLIPS_ORDER: Record<ClipType, string> = {
  SOURCE: "Original",
  TOPIC_BY_TIME: "Custom clip",
  TOPIC: "Chapter",
  TOPIC_BY_TIME_CONCATED: "Custom clip",
};

export const CLIP_SEQUENCES_ORDER_DICT_DEFAULT: Partial<Record<ClipSequenceType, string>> = {
  [ClipSequenceType.Manual]: "Manual",
  [ClipSequenceType.Original]: "Original",
  [ClipSequenceType.Highlight]: "Highlight",
};

export const FailedTooltipExplanation = (
  <FailedTooltipInfo>
    <span>Possible reasons</span>
    <ul>
      <li>Video doesn&apos;t contain verbal content</li>
      <li>Video is too short</li>
      <li>The selected language is incorrect</li>
    </ul>
    <div>
      None of the above?
      <ContactSupportBtn onClick={() => userEngagementService.show()} style={{}}>
        Contact Support
      </ContactSupportBtn>
    </div>
  </FailedTooltipInfo>
);

export const RECENTLY_EDITED_AMOUNT = 10;
