/* eslint-disable no-confusing-arrow */
import { EntityId } from "@reduxjs/toolkit";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { Center } from "src/components/common/layout/Box.styled";
import { CircularLoader } from "src/components/common/loaders/CircularLoader";
import { useAppConfig } from "src/components/providers/AppConfigProvider";
import { footageSelectors } from "src/models/Footage.model";
import { sequenceSelectors } from "src/models/Sequence.model";
import { Dispatch, RootState } from "src/models/store";
import { absoluteRoutes } from "src/utils/routes.utils";

export default function FootageNotExpiredGate() {
  const navigate = useNavigate();
  const { FOOTAGE_EXPIRED } = useAppConfig();
  const dispatch = useDispatch<Dispatch>();
  const pathParams = useParams();

  const sequenceSid = pathParams.sequenceSid!;
  const sequence = useSelector((state: RootState) => sequenceSid ? sequenceSelectors.selectById(state, sequenceSid ?? "") : null); // prettier-ignore
  const footageId = pathParams.footageId! ?? sequence?.footageSid;
  const footage = useSelector((state: RootState) => footageId ? footageSelectors.selectById(state, footageId as EntityId) : null); // prettier-ignore
  const isFootageExpired = useSelector((state: RootState) => footageId ? footageSelectors.selectIsFootageOlderThanDaysAmount(state, footageId, FOOTAGE_EXPIRED.expiredDays) : null); // prettier-ignore

  useEffect(() => {
    if (sequenceSid && !sequence) {
      dispatch.sequences.fetchSequence({ sequenceSid: sequenceSid as string });
    }

    if (footageId && !footage) {
      dispatch.footage.fetchFootage(footageId);
    }
  }, [dispatch.footage, dispatch.sequences, footage, footageId, sequence, sequenceSid]);

  if (!sequenceSid && !footageId) {
    return <Outlet />;
  }

  if (!footage) {
    return (
      <Center>
        <CircularLoader size={100} marginTop={20} />
      </Center>
    );
  }

  if (isFootageExpired) {
    navigate(absoluteRoutes.platform.self);
    toast.error(FOOTAGE_EXPIRED.toastText);
  }

  return <Outlet />;
}
