/* eslint-disable default-case,no-nested-ternary */

import { findIndex, keyBy } from "lodash/fp";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components/macro";
import { Swiper as SwiperClass } from "swiper/types";

import { AssetStatus, AssetType } from "src/constants/model.constants";
import { MAX_CHAPTER_COUNT } from "src/constants/video.constants";

import * as createProjectAnalytics from "src/analytics/creationWizard.analytics";

import { Chapter, EditRules } from "src/network/graphql/generatedGraphqlSDK";

import { getDefaultCaptionsStyle } from "src/utils/closeCaption.utils";
import { themeColor, themeZ } from "src/utils/styledComponents.utils";

import { assetSelectors } from "src/models/Asset.model";
import { presetSelectors } from "src/models/Preset.model";
import { sequenceSelectors } from "src/models/Sequence.model";
import { userSelectors } from "src/models/User.model";
import { Dispatch, RootState } from "src/models/store";

import useBeforeUnloadBlocker from "src/hooks/useBeforeUnloadBlocker";
import usePrevious from "src/hooks/usePrevious";

import Icon from "src/components/common/Icon";
import Swiper from "src/components/common/Swiper/Swiper";
import SwiperNextSlideButton from "src/components/common/Swiper/SwiperNextSlideButton";
import { MountIfActive } from "src/components/common/Swiper/SwiperSlide";
import Select from "src/components/common/form/inputs/Select.styled";
import { CircularLoader } from "src/components/common/loaders/CircularLoader";
import { useSequenceForm } from "src/components/features/CreateSequenceWizard/SequenceForm";
import CancelButton from "src/components/features/CreateSequenceWizard/common/CancelButton";
import {
  BackButtonWrapper,
  CancelButtonWrapper,
  CentralButtonsWrapper,
  Footer,
  NextButtonWrapper,
} from "src/components/features/CreateSequenceWizard/common/create-sequence-wizard-layout.styled";
import steps, { StepPath } from "src/components/features/CreateSequenceWizard/steps/steps";
import SlideIndicator from "src/components/features/CreateSequenceWizard/swiper-components/SwiperActiveSlideIndicator";
import SwiperPrevSlideButton from "src/components/features/CreateSequenceWizard/swiper-components/SwiperPrevSlideButton";
import { SwiperSlide } from "src/components/features/CreateSequenceWizard/swiper-components/SwiperSlide.styled";
import { chapterTimeIntervalsToChapters } from "src/components/features/VideoTrimmer/common/converters";
import NoVerbalContentModal from "src/components/modals/NoVerbalContentModal";
import { useModal } from "src/components/providers/ModalProvider";
import useEditRules from "src/hooks/useEditRules";
import { isMobile } from "src/utils/mobile.utils";
import { absoluteRoutes, applyPathParams } from "src/utils/routes.utils";
import { useAssetThumbnailUrl } from "src/hooks/useAssetThumbnailUrl";

const Container = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const StyledSwiper = styled(Swiper)`
  z-index: ${themeZ("onboardingSwiper")};
  width: 100%;
  height: 100%;
  min-width: 320px;
`;

export const StyledNextSlideButton = styled(SwiperNextSlideButton)`
  width: 119px;
  height: 45px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: ${themeColor("white")};
`;

interface CreateSequenceWizardProps {
  boardId?: string | null;
}

// TODO: decouple from useSearchParams, useParams
export default function CreateSequenceWizard({ boardId = null }: CreateSequenceWizardProps) {
  const { openModal } = useModal();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const pathParams = useParams<{ step: StepPath; sequenceSid: string }>();

  const createProjectForm = useSequenceForm();
  const { formState, trigger: validateForm, setValue, getValues, watch } = createProjectForm;
  const activeStepErrors = pathParams.step && formState.errors[pathParams.step];
  const activeStepHasErrors = !!activeStepErrors && Object.values(activeStepErrors).length > 0;
  const sequenceSid = watch("content.sequenceSid");

  const dispatch = useDispatch<Dispatch>();
  const currentUser = useSelector(userSelectors.selectCurrentUser);
  const defaultPreset = useSelector((state: RootState) => presetSelectors.getDefaultPreset(state));
  const assets = useSelector((state: RootState) => assetSelectors.selectAllByTypeAndStatus(state, AssetType.Source, AssetStatus.Ready)); // prettier-ignore
  const editRulesList = useSelector((state: RootState) => state.session.editRules);
  const isSavingSequence = useSelector((state: RootState) => state.loading.effects.sequences.createSequence.loading);
  const isReorderingSequenceChapters = useSelector((state: RootState) => state.loading.effects.sequences.reorderSequenceChapters.loading); // prettier-ignore
  const isLoading = isSavingSequence || isReorderingSequenceChapters;
  const initialAssetsLoaded = useSelector((state: RootState) => state.loading.effects.assets.fetchAssetsById.success);
  const sequence = useSelector((state: RootState) => sequenceSelectors.selectById(state, sequenceSid!));
  const sequenceCreationError = useSelector(sequenceSelectors.selectSequenceCreationError);

  const activeStepIndex = useMemo(() => findIndex({ path: pathParams.step }, steps), [pathParams.step]);
  const assetDict = useMemo(() => keyBy("sid", assets), [assets]);
  const initialAssetIds = useMemo(() => searchParams.getAll("assetSid").slice(0, MAX_CHAPTER_COUNT), [searchParams]);
  const validInitialAssetIds = useMemo(() => initialAssetIds.filter((sid) => assetDict[sid]), [initialAssetIds, assetDict]); // prettier-ignore

  const initialStepIndexRef = useRef(activeStepIndex);
  const sequenceTitleRef = useRef(searchParams.get("title"));

  const isLastStep = activeStepIndex === steps.length - 1;

  const [indicatorSpacing, setIndicatorSpacing] = useState(window.innerWidth < 375 ? 60 : 70);

  const { setEditRules } = useEditRules({
    currentPreset: defaultPreset,
  });

  const selectedAssetsSidArray = watch("footage.footageSidArray");
  const firstAsset = useSelector((state: RootState) =>
    assetSelectors.selectById(state, selectedAssetsSidArray?.[0] ?? ""),
  );
  const initialAspectRatio = useMemo(() => firstAsset?.aspectRatio || "16:9", [firstAsset]);

  const ASSET_THUMBNAIL_16_9_URL = useAssetThumbnailUrl({
    sid: selectedAssetsSidArray?.[0] ?? "",
    aspectRatio: "16:9",
    width: 100,
  });
  const ASSET_THUMBNAIL_1_1_URL = useAssetThumbnailUrl({
    sid: selectedAssetsSidArray?.[0] ?? "",
    aspectRatio: "1:1",
    width: 100,
  });
  const ASSET_THUMBNAIL_9_16_URL = useAssetThumbnailUrl({
    sid: selectedAssetsSidArray?.[0] ?? "",
    aspectRatio: "9:16",
    width: 100,
  });

  const thumbnailsUrls = useMemo(
    () => ({
      "16:9": ASSET_THUMBNAIL_16_9_URL || "",
      "1:1": ASSET_THUMBNAIL_1_1_URL || "",
      "9:16": ASSET_THUMBNAIL_9_16_URL || "",
    }),
    [ASSET_THUMBNAIL_16_9_URL, ASSET_THUMBNAIL_1_1_URL, ASSET_THUMBNAIL_9_16_URL],
  );

  const createSequence = useCallback(async () => {
    const formValues = getValues();
    const { footageSidArray: assetSids = [] } = formValues.footage; // TODO: rename original
    const { selectedAspectRatio: aspectRatio = "16:9" } = formValues.size; // TODO: rename original
    const { editRulesSid, editRules, defaultVideoType } = formValues.visuals;
    const originalEditRules = editRulesList.find(({ sid }) => sid === editRulesSid);
    const style = getDefaultCaptionsStyle(aspectRatio);
    const title = sequenceTitleRef.current;

    if (currentUser?.sid && defaultVideoType && defaultPreset && editRulesSid && title) {
      const sid = await dispatch.sequences.createSequence({
        userSid: currentUser.sid,
        preset: defaultPreset,
        skipUpdatePreset: true,
        manuallyCreatedFirstBoard: boardId === "null" || boardId === null ? undefined : boardId,
        title,
        style,
        aspectRatio,
        assetSids,
        defaultVideoType,
        editRulesSid,
        editRules: setEditRules({
          fieldsToPick: ["name", "volume", "noContentVolume"],
          currentEditedData: editRules as EditRules,
          fullData: originalEditRules as EditRules,
        }),
      });

      createProjectAnalytics.trackSaveNewProject({ aspectRatio, editRules: editRules! }, sid);
      setValue("content.sequenceSid", sid);
    }
  }, [getValues, editRulesList, currentUser?.sid, defaultPreset, dispatch.sequences, boardId, setEditRules, setValue]);

  const reorderScenes = useCallback(async () => {
    const { chapters: updatedChapterTimeIntervals } = getValues().content;

    if (sequenceSid && updatedChapterTimeIntervals) {
      const originalChapters = (sequence?.chapters ?? []) as Chapter[];

      await dispatch.sequences.reorderSequenceChapters({
        sequenceSid,
        chapters: chapterTimeIntervalsToChapters(updatedChapterTimeIntervals, originalChapters),
      });

      // TODO: move to board by sequence -> talk to backend
      navigate(applyPathParams(absoluteRoutes.projectSequence.children.processing, { sequenceSid }));
    }
  }, [getValues, sequenceSid, sequence?.chapters, dispatch.sequences, navigate]);

  const isEveryAssetMissingVerbalContent = useCallback(() => {
    const { footageSidArray: assetSids = [] } = getValues().footage;

    return assetSids.every((sid) => assetDict[sid].noWords);
  }, [getValues, assetDict]);

  const autoCompleteFootageStep = useCallback(
    (assetIds: string[]) => {
      setValue("footage.footageSidArray", assetIds, { shouldValidate: true });

      if (isEveryAssetMissingVerbalContent()) {
        navigate(
          applyPathParams(absoluteRoutes.createProject.children.step, {
            step: steps[0].path,
          }),
          { replace: true },
        );

        openModal(NoVerbalContentModal); // TODO: NoVerbalContentModalChooseDifferentFootage
      } else {
        // TODO: remove timeout and call navigate after setValue had triggered a rerender instead
        setTimeout(() =>
          navigate(
            applyPathParams(absoluteRoutes.createProject.children.step, {
              step: steps[1].path,
            }),
            { replace: true },
          ),
        );
      }
    },
    [navigate, setValue, isEveryAssetMissingVerbalContent, openModal],
  );

  const onNext = useCallback(
    async (next: Function) => {
      switch (pathParams.step) {
        case "footage": {
          if (isEveryAssetMissingVerbalContent()) {
            openModal(NoVerbalContentModal); // TODO: NoVerbalContentModalChooseDifferentFootage
            return;
          }

          break;
        }

        case "visuals":
          await createSequence();
          break;

        case "content":
          await reorderScenes();
          break;
      }

      next();
    },
    [openModal, createSequence, reorderScenes, isEveryAssetMissingVerbalContent, pathParams.step],
  );

  useEffect(() => {
    createProjectAnalytics.trackStartProjectCreating("regular flow");
  }, []);

  const onActiveStepIndexChange = useCallback(
    ({ activeIndex }: SwiperClass) => {
      if (pathParams.step !== steps[activeIndex].path) {
        navigate(
          applyPathParams(absoluteRoutes.createProject.children.step, {
            step: steps[activeIndex].path,
          }),
        );
      }
    },
    [navigate, pathParams.step],
  );

  useEffect(() => {
    if (initialStepIndexRef.current > 0 || (!sequenceTitleRef.current && !searchParams.has("useDefaultAsset"))) {
      navigate(absoluteRoutes.home);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  useEffect(() => {
    dispatch.session.getVideoTypes();
    dispatch.session.getEditRules();
  }, [dispatch]);

  useEffect(() => {
    if (!pathParams.step) {
      switch (true) {
        case !!initialAssetIds.length:
          dispatch.assets.fetchAssetsById(initialAssetIds);
          break;

        case searchParams.has("useDefaultAsset"):
          dispatch.assets.fetchAssets({
            filter: {
              typeIn: [AssetType.Source],
              statusEqual: AssetStatus.Ready,
            },
            pager: { pageIndex: 1, pageSize: 1 },
          });
          break;

        default:
          navigate(applyPathParams(absoluteRoutes.createProject.children.step, { step: steps[0].path }), {
            replace: true,
          });
      }
    }

    validateForm();
  }, [dispatch, initialAssetIds, navigate, pathParams.step, searchParams, validateForm]);

  useEffect(() => {
    if (initialAssetsLoaded && validInitialAssetIds.length) {
      autoCompleteFootageStep(validInitialAssetIds);
    }
  }, [autoCompleteFootageStep, initialAssetsLoaded, validInitialAssetIds]);

  useEffect(() => {
    if (searchParams.has("useDefaultAsset") && assets[0]) {
      sequenceTitleRef.current = assets[0].name!;
      autoCompleteFootageStep([assets[0].sid!]);
    }
  }, [autoCompleteFootageStep, assets, searchParams]);

  const prevSequenceCreationError = usePrevious(sequenceCreationError);
  useEffect(() => {
    if (sequenceCreationError && !prevSequenceCreationError) {
      const path = applyPathParams(absoluteRoutes.createProject.children.step, { step: steps[0].path });

      navigate(`${path}?title=${sequenceTitleRef.current}?boardId=${boardId}`);
    }
  }, [boardId, navigate, prevSequenceCreationError, sequenceCreationError]);

  useBeforeUnloadBlocker();

  useEffect(() => {
    const handleResize = () => {
      setIndicatorSpacing(window.innerWidth < 375 ? 60 : 70);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // skip content editor if isMobile
  useEffect(() => {
    if (isMobile() && watch("content.chapters")?.length) {
      reorderScenes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reorderScenes, watch("content.chapters")?.length]);

  if (activeStepIndex === -1) {
    return null;
  }

  return (
    <FormProvider {...createProjectForm}>
      <Container>
        <StyledSwiper
          activeIndex={activeStepIndex}
          allowSlideNext={!activeStepHasErrors && !isSavingSequence && !isLoading}
          allowSlidePrev={!isSavingSequence && pathParams.step !== "content"}
          onActiveIndexChange={onActiveStepIndexChange}
          noSwipingSelector={`${Select}`}
          direction="horizontal"
          enableMousewheel={false}
        >
          {steps.map((step, i) => (
            <SwiperSlide key={step.path}>
              <MountIfActive index={i}>
                {step.path !== "size" ? (
                  <step.component formContext={createProjectForm} />
                ) : (
                  <step.component
                    formContext={createProjectForm}
                    initialAspectRatio={initialAspectRatio}
                    thumbnailsUrls={thumbnailsUrls}
                  />
                )}
              </MountIfActive>
            </SwiperSlide>
          ))}

          <SlideIndicator direction="row" indicatorSpacing={indicatorSpacing} />

          <Footer>
            <CancelButtonWrapper hide={isSavingSequence}>
              <CancelButton>Cancel</CancelButton>
            </CancelButtonWrapper>

            <CentralButtonsWrapper>
              <BackButtonWrapper>
                {activeStepIndex > 0 && activeStepIndex < steps.length - 1 && (
                  <SwiperPrevSlideButton
                    data-testid="back"
                    disabled={isSavingSequence || !activeStepIndex || activeStepIndex === steps.length - 1}
                  >
                    <Icon.PrevSlide />
                    Back
                  </SwiperPrevSlideButton>
                )}
              </BackButtonWrapper>

              <NextButtonWrapper>
                {!(activeStepIndex === steps.length - 1 && isMobile()) && (
                  <StyledNextSlideButton data-testid="next" onClick={onNext}>
                    {isLoading ? <CircularLoader size={30} /> : isLastStep ? "Finish" : "Next"}
                  </StyledNextSlideButton>
                )}
              </NextButtonWrapper>
            </CentralButtonsWrapper>
          </Footer>
        </StyledSwiper>
      </Container>
    </FormProvider>
  );
}
