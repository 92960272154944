import { Board as gqlBoard } from "src/network/graphql/generatedGraphqlSDK";
import analyticsService from "src/services/Analytics.service";

type Board = gqlBoard | null | undefined;

export const trackDeleteBoard = (board: Board, trigger: "board-select") =>
  analyticsService.track("board", {
    action: "removed",
    name: board?.name,
    boardFootageCount: board?.footageIds?.length,
    hasFootage: !!board?.footageIds?.length,
    trigger,
  });

export const trackRenameBoard = (newName: string, oldName: string, trigger: "board-select") =>
  analyticsService.track("board", { action: "rename", newName, oldName, trigger });

export const trackCreateBoard = (name: string, trigger: "board-select") =>
  analyticsService.track("board", { action: "create", name, trigger });

export const trackSelectBoard = (board: Partial<Board>, trigger: "board-select") =>
  analyticsService.track("board", { action: "select", id: board?.id, name: board?.name, trigger });

export const trackMoveFootageToBoard = (
  currentBoard: Board,
  targetBoard: Board,
  footageId: string,
  trigger: "board-move-modal" | "automatic-create" | "manual-create",
) =>
  analyticsService.track("board", {
    action: "move-footage-to-board",
    footageId,
    from: currentBoard?.name,
    to: targetBoard?.name,
    trigger,
  });

export const trackRemoveFootageFromBoard = (board: Board, footageId: string, trigger: "board-move-modal") =>
  analyticsService.track("board", {
    action: "remove-footage-from-board",
    from: board?.name,
    footageId,
    trigger,
  });
