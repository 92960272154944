/* eslint-disable default-case */

import { RefObject } from "react";

import useMousetrap from "src/hooks/useMousetrap";
import { useVideoPlayback } from "src/components/features/VideoTrimmer/providers/VideoPlaybackProvider/VideoPlaybackContext";
import { ShortcutsKeys } from "src/constants/shortcuts.constants";

interface PlayerActionButtonsProps {
  videoRef: RefObject<HTMLVideoElement>;
}

export default function VideoPlaybackShortcuts({ videoRef }: PlayerActionButtonsProps) {
  const video = videoRef.current;

  const {
    isPlaying,
    seekForward,
    seekBackward,
    changePlaybackRate,
  } = useVideoPlayback(); // prettier-ignore

  useMousetrap(ShortcutsKeys.togglePlay, () => (isPlaying ? video?.pause() : video?.play()), { preventDefault: true });
  // TODO: move forward/backward to trimmer shortcuts
  useMousetrap([ShortcutsKeys.forward], seekForward, { preventDefault: true, allowRepeat: true });
  useMousetrap([ShortcutsKeys.backward], seekBackward, { preventDefault: true, allowRepeat: true });

  useMousetrap(["mod+shift+p"], changePlaybackRate, { preventDefault: true });

  return null;
}
