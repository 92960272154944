import analyticsService from "src/services/Analytics.service";

export const trackCancelSelection = () =>
  analyticsService.track("footage-preview-player-transcript-edit", {
    action: "cancel-selection",
    trigger: "content-selection",
  });

export const trackUndo = () =>
  analyticsService.track("footage-preview-player-control", { action: "undo", trigger: "undo-button" });

export const trackRedo = () =>
  analyticsService.track("footage-preview-player-control", { action: "redo", trigger: "redo-button" });

export const trackHighlight = (trigger: "content-selection") =>
  analyticsService.track("footage-preview-player-transcript-edit", { action: "highlight", trigger });

export const trackUnHighlight = (trigger: "content-selection") =>
  analyticsService.track("footage-preview-player-transcript-edit", { action: "unhighlight", trigger });

export const trackWordsCorrect = (
  trigger: "content-correct" | "content-correct-all",
  props: { selectedWordsAmount: number; selectionDuration: number; amountDiff: number; selectedBlanksAmount: number },
) => {
  const { selectedWordsAmount, selectionDuration, amountDiff, selectedBlanksAmount } = props;
  analyticsService.track("footage-preview-player-transcript-edit", {
    // we calculate selectedWordsAmount + amountDiff to determine if we are deleting all selected words
    // example: 3 + -3 = 0 ("deleteWords") or 3 + -2 = 1 ("updateWords")
    action: selectedWordsAmount + amountDiff === 0 ? "deleteWords" : "updateWords",
    trigger,
    selectedWordsAmount,
    selectionDuration,
    amountDiff,
    selectedBlanksAmount,
  });
};
