import { Fragment, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { presetSelectors } from "src/models/Preset.model";
import { RootState } from "src/models/store";

import { absoluteRoutes, applyPathParams } from "src/utils/routes.utils";
import { themeColor } from "src/utils/styledComponents.utils";

import styled from "styled-components/macro";

import Icon from "src/components/common/Icon/Icon";
import { SUBSTEPS } from "src/components/features/AutomaticCreateWizard/automaticCreate.constants";
import {
  AppearAnimatedDiv,
  HeaderText,
  StepContainer,
} from "src/components/features/AutomaticCreateWizard/common/automatic-create-wizard-layout.styled";
import BrandButtonColors from "src/components/features/AutomaticCreateWizard/steps/components/BrandButtonColors";
import BrandButtonLogo from "src/components/features/AutomaticCreateWizard/steps/components/BrandButtonLogo";
import BrandVisualPackage from "src/components/features/AutomaticCreateWizard/steps/components/BrandVisualPackage";
import BrandKitsSelector, {
  BrandSelectorOption,
} from "src/components/features/AutomaticCreateWizard/steps/components/BrandKitsSelector";
import steps, { StepPath } from "src/components/features/AutomaticCreateWizard/steps/steps";
import BrandColors from "src/components/features/AutomaticCreateWizard/subSteps/BrandColors";
import BrandLogo from "src/components/features/AutomaticCreateWizard/subSteps/BrandLogo";
import BrandVisuals from "src/components/features/AutomaticCreateWizard/subSteps/BrandVisuals";

import * as createSequenceAnalytics from "src/analytics/creationWizard.analytics";
import BrandSORs from "src/components/features/AutomaticCreateWizard/subSteps/SORStep/BrandSORs";
import BrandButtonSORs from "src/components/features/AutomaticCreateWizard/steps/components/BrandButtonSORs";
import { useFeatureFlag } from "src/components/providers/FeatureFlagsProvider";
import { ifProp } from "styled-tools";
import { FormContextInterface } from "src/components/features/AutomaticCreateWizard/FormContext.interface";
import createClipSteps from "src/components/features/CreateSequenceWizard/steps/createClipSteps";

const SubHeader = styled.div`
  font-size: 16px;
  line-height: 23px;
  font-weight: 600;
  color: ${themeColor("pink.500")};
  margin-block: 20px 10px;
  text-align: center;
`;

const ButtonsGrid = styled(AppearAnimatedDiv)<{ fourButtons: boolean }>`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 12px;
  column-gap: 15px;
  padding-bottom: 20px;

  @media (min-width: 540px) {
    grid-template-columns: ${ifProp("fourButtons", "repeat(2, 1fr)", "repeat(1, 1fr)")};
  }

  @media (min-width: 800px) {
    grid-template-columns: ${ifProp("fourButtons", "repeat(2, 1fr)", "repeat(3, 1fr)")};
  }

  @media (min-width: 1024px) {
    grid-template-columns: ${ifProp("fourButtons", "repeat(4, 1fr)", "repeat(3, 1fr)")};
  }
`;

const BrandButton = styled.div`
  display: flex;
  width: 222px;
  height: 250px;
  padding: 20px 10px 15px 10px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  border: 1px solid;
  border-color: ${themeColor("blue.50")};
  margin: 14px 10px;
  cursor: pointer;

  &:hover {
    border-color: ${themeColor("blue.900")};
  }

  transition: border-color 0.5s ease;
`;

const ButtonName = styled.div`
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  color: ${themeColor("gray.900")};
`;

const ButtonPic = styled.div`
  height: 110px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonIcon = styled.div`
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  color: ${themeColor("pink.500")};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  svg {
    margin-right: 5px;
  }
`;

const SelectorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 224px;
  height: 100%;
  margin-inline: 10px;
`;

export default function BrandKitStep({
  formContext,
  baseRoute = absoluteRoutes.automaticCreate,
  usersCurrentPresetSidFormPath,
}: FormContextInterface & { baseRoute?: any; usersCurrentPresetSidFormPath?: string }) {
  const firstExperienceUser = useSelector((state: RootState) => presetSelectors.isUserFirstExperience(state));
  const navigate = useNavigate();
  const { watch, setValue } = formContext;
  const pathParams = useParams<{ step: StepPath; subStep: string }>();
  const usersPresets = useSelector((state: RootState) => state.presets.entities);
  const usersPesetsLoading = useSelector((state: RootState) => state.loading.effects.presets.getPresets.loading);
  const brandKitSelectorObjects: BrandSelectorOption[] = useMemo(
    () =>
      Object.values(usersPresets)?.map(
        (x) => ({ label: x?.name || "First Brand Kit", value: x?.sid } as BrandSelectorOption),
      ) ?? [],
    [usersPresets],
  );

  const allowChangeEditRules = useFeatureFlag("allowChangeEditRules");
  const allowChangeEditRulesFirstTimeUser = useFeatureFlag("allowChangeEditRulesFirstTimeUser");

  const shouldShowEditRules = useMemo(
    () => (firstExperienceUser ? allowChangeEditRulesFirstTimeUser : allowChangeEditRules),
    [allowChangeEditRules, allowChangeEditRulesFirstTimeUser, firstExperienceUser],
  );

  const onSubstepClick = useCallback(
    (substep: string) => {
      navigate(
        applyPathParams(baseRoute.children.step.children.subStep, {
          step: baseRoute === absoluteRoutes.automaticCreate ? steps[1].path : createClipSteps[1].path,
          subStep: substep,
        }),
      );
    },
    [baseRoute, navigate],
  );

  const brandButtonsInfo = useMemo(
    () => [
      {
        buttonName: "Your Logo",
        buttonPic: <BrandButtonLogo formContext={formContext} />,
        buttonAction: () => onSubstepClick("logo"),
        hidden: false,
      },
      {
        buttonName: "Your Brand Colors",
        buttonPic: <BrandButtonColors formContext={formContext} />,
        buttonAction: () => onSubstepClick("colors"),
        hidden: false,
      },
      {
        buttonName: "Your Visual Style",
        buttonPic: (
          <BrandVisualPackage formContext={formContext} visualPackageId={watch("brandKit.visuals.visualPackageId")} />
        ),
        buttonAction: () => onSubstepClick("visuals"),
        hidden: false,
      },
      {
        buttonName: "Visual Elements",
        buttonPic: <BrandButtonSORs formContext={formContext} />,
        buttonAction: () => onSubstepClick("SORs"),
        hidden: !shouldShowEditRules,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onSubstepClick, watch("brandKit.visuals.visualPackageId")],
  );

  const onBrandKitChange = useCallback(
    (presetSid: string) => {
      if (presetSid && presetSid !== watch(usersCurrentPresetSidFormPath)) {
        setValue(usersCurrentPresetSidFormPath, presetSid, { shouldValidate: true });
        createSequenceAnalytics.trackAutomationWizardControls("brandKit-selector", "brandKit-changed", { presetSid });
      }
    },
    [setValue, usersCurrentPresetSidFormPath, watch],
  );

  return (
    <StepContainer>
      {pathParams.subStep === SUBSTEPS.LOGO && <BrandLogo formContext={formContext} />}
      {pathParams.subStep === SUBSTEPS.COLORS && <BrandColors formContext={formContext} />}
      {pathParams.subStep === SUBSTEPS.VISUALS && <BrandVisuals formContext={formContext} />}
      {shouldShowEditRules && pathParams.subStep === SUBSTEPS.SORS && <BrandSORs formContext={formContext} />}
      {!pathParams.subStep && (
        <Fragment>
          <HeaderText>Your Brand Kit</HeaderText>
          <SubHeader>
            {brandKitSelectorObjects && watch(usersCurrentPresetSidFormPath) && (
              <SelectorWrapper>
                <BrandKitsSelector
                  supportedOptions={brandKitSelectorObjects}
                  selectedOption={watch(usersCurrentPresetSidFormPath) ?? ""}
                  onOptionsSelect={onBrandKitChange}
                  isLoading={!!usersPesetsLoading}
                  isDisabled={!brandKitSelectorObjects.length}
                />
              </SelectorWrapper>
            )}
          </SubHeader>
          <ButtonsGrid fourButtons={!!shouldShowEditRules}>
            {brandButtonsInfo.map(
              (item) =>
                !item.hidden && (
                  <BrandButton onClick={item.buttonAction} key={item.buttonName}>
                    <ButtonName>{item.buttonName}</ButtonName>
                    <ButtonPic>{item.buttonPic}</ButtonPic>
                    <ButtonIcon>
                      <Icon.Edit />
                      Edit
                    </ButtonIcon>
                  </BrandButton>
                ),
            )}
          </ButtonsGrid>
        </Fragment>
      )}
    </StepContainer>
  );
}
