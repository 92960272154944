import { DetailedHTMLProps, HTMLAttributes, useEffect, useRef } from "react";
import lottie, { AnimationConfigWithData, AnimationItem } from "lottie-web";

import useShallowMemo from "src/hooks/useShallowMemo";

export interface LottieProps extends Omit<AnimationConfigWithData, "container" | "renderer"> {
  onLoad?(animation: AnimationItem): void;
  width?: string | number;
  height?: string | number;
  translate?: string;
  elementProps?: Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "className">;
  className?: string;
}

export default function Lottie({
  onLoad,
  width,
  height,
  translate,
  elementProps,
  className,
  ...animationProps
}: LottieProps) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(
    () => {
      const animation = lottie.loadAnimation({ container: ref.current!, renderer: "svg", ...animationProps });

      onLoad?.(animation);

      return () => {
        animation.destroy();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [animationProps.animationData],
  );

  return (
    <div
      {...elementProps}
      ref={ref}
      className={className}
      style={useShallowMemo({
        width,
        height,
        translate,
        ...elementProps?.style,
      })}
    />
  );
}
