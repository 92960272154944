import { themeColor } from "src/utils/styledComponents.utils";
import styled from "styled-components/macro";
import { ifProp } from "styled-tools";

import Select from "src/components/common/form/inputs/Select.styled";
import { SingleValue } from "react-select";

export type BrandSelectorOption = {
  value: string;
  label: string;
};

const StyledSelect = styled(Select<BrandSelectorOption>)<{ isLoading: boolean; isDisabled: boolean }>`
  flex: 1;
  .select__control {
    border: solid 1px;
    border-color: ${themeColor("blue.50")};
    border-radius: 30px;
    height: 40px;
    min-width: 90%;
    width: unset !important;
    width: fit-content;
    background-color: transparent;
    cursor: ${ifProp("isDisabled", "not-allowed", "pointer")} !important;

    opacity: ${ifProp("isLoading", 1, 0.65)};
    transition: opacity 250ms ease-out;
    .select__value-container {
      padding: 2px 8px;
      width: 210px;
    }
    &:hover {
      background-color: transparent !important;
      border-color: ${themeColor("blue.900")} !important;
    }

    transition: border-color 0.5s ease;
  }

  .select__control--menu-is-open,
  .select__control--is-focused {
    background-color: transparent !important;
    border-color: ${themeColor("blue.900")} !important;
  }

  .select__control--menu-is-open {
    .select__indicators {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .select__indicators {
    max-height: 100%;
    margin-right: 14px;
    transition: transform 0.5s ease;
  }

  .select__dropdown-indicator {
    cursor: ${ifProp("isDisabled", "not-allowed", "pointer")};
  }

  .select__placeholder {
    color: ${themeColor("gray.900")} !important;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    svg {
    }
  }

  .select__clear-indicator {
    padding-inline: 4px;
  }

  .select__multi-value {
    background-color: ${themeColor("white")};
    border-radius: 80px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 2px 3px;
    margin: 2px;
    min-width: 60px;
    height: 26px;
  }

  .select__multi-value__label {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .select__multi-value__remove {
    cursor: ${ifProp("isDisabled", "not-allowed", "pointer")};
    background-color: ${themeColor("blue.100")};
    width: 18px;
    height: 18px;
    border-radius: 50%;
    padding-left: 5px;
    svg {
      fill: ${themeColor("blue.900")};
    }
    &:hover {
      background-color: ${themeColor("blue.600")};
    }
    transition: background-color 250ms ease-out;
  }
`;

export interface BrandKitsSelectorProps {
  supportedOptions: BrandSelectorOption[];
  selectedOption: string;
  onOptionsSelect(presetSid: string): void;
  isLoading?: boolean;
  isDisabled?: boolean;
}

export default function BrandKitsSelector({
  supportedOptions,
  selectedOption,
  onOptionsSelect,
  isLoading = false,
  isDisabled = false,
}: BrandKitsSelectorProps) {
  const selectedValue = supportedOptions.find((opt) => opt.value === selectedOption);

  const handleChange = (option: SingleValue<BrandSelectorOption>) => {
    onOptionsSelect(option?.value ?? "");
  };

  return (
    <StyledSelect
      isSearchable
      isClearable={false}
      value={selectedValue}
      options={supportedOptions}
      getOptionLabel={(x) => x.label!}
      getOptionValue={(x) => x.value!}
      onChange={handleChange}
      isDisabled={isDisabled}
      isLoading={isLoading}
      placeholder="My Brandkit"
    />
  );
}
