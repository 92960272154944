export type Size = "xxl" | "xl" | "large" | "medium" | "small" | "xs";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface ThemeStructure {
  zIndices: Record<string, number>;
  breakPoints: Partial<Record<Size, number>>;
  borderRadii: Partial<Record<Size, number>>;
  colors: Record<string, string | Record<string, string>>;
  spaces: Record<number, number>;
}

const theme = {
  zIndices: {
    modal: 4,
    modalBody: 5,

    header: 3,
    clipsSidebar: 3,
    selectMenu: 5,
    expandButton: 3,
    footageDeleteButton: 3,

    onboardingSwiper: 2,
    onboardingRightPanel: 1,
    content: 2,

    videoPreviewParts: 1,
    onboardingNextButton: 1,
    createProjectSwiperFooter: 1,
    imageDropzoneClearButton: 1,
    visualPackageLottiePreview: 1,
    swiperActiveSlideIndicator: 1,
    sidebar: 1,
  },

  breakPoints: {
    xxl: 1920,
    xl: 1366,
    large: 1024,
    medium: 768,
    small: 425,
    xs: 320,
  },

  borderRadii: {
    large: 10,
    medium: 6,
    small: 4,
  },

  colors: {
    white: "#ffffff",
    black: "#000000",

    pink: {
      50: "#FEF6FA",
      100: "#FCE3F0",
      200: "#F9C6E2",
      300: "#FFB1DB",
      400: "#F28EC4",
      500: "#ea429d",
    },

    blue: {
      30: "#F0F5FB",
      50: "#DCE8FB",
      100: "#CBDDFB",
      150: "#C2D5F4",
      200: "#A8C0E9",
      250: "#0073C3",
      300: "#B0DCFB",
      400: "#7EC7FB",
      500: "#1f9ffb",
      550: "#B3CFFC",
      600: "#84BCEF",
      700: "#4094CE",
      800: "#1671B2",
      900: "#1671B2",
      1100: "#132e59",
      1200: "#0E2140",
      1400: "#CBDDFB",
      1500: "#B7D1FC",
    },

    gray: {
      30: "#f9f9f9",
      50: "#fafafa",
      200: "#f2f2f2",
      225: "#eaeaea",
      250: "#d8d8d8",
      275: "#d9d9d9",
      300: "#cccccc",
      350: "#b9b9b9",
      400: "#999999",
      500: "#666666",
      600: "#848199",
      700: "#555555",
      900: "#333333",
    },

    violet: {
      100: "#E2D7FA",
      200: "#A983FC",
      300: "#7D55D2",
      400: "#7B41FA",
    },

    orange: {
      300: "#EE9004",
    },

    yellow: {
      500: "#ffd43d",
    },

    status: {
      success: "#16B242",
      error: "#e44258",
      warning: "#ffd43d",
    },
  },

  spaces: {
    2: 2,
    4: 4,
    6: 6,
    8: 8,
    10: 10,
    12: 12,
    14: 14,
    16: 16,
    18: 18,
    20: 20,
    22: 22,
    24: 24,
    26: 26,
    28: 28,
    30: 30,
    32: 32,
    34: 34,
    36: 36,
    38: 38,

    40: 40,
    45: 45,
    50: 50,
    55: 55,

    60: 60,
    70: 70,
    80: 80,
    90: 90,
    100: 100,

    125: 125,
    150: 150,
    175: 175,
    200: 200,
  },
} satisfies ThemeStructure;

export default theme;

export type Theme = typeof theme;
export type ThemeColor = Leaves<Theme["colors"]>;
export type ZIndexValue = keyof Theme["zIndices"];
export type SpaceValue = keyof Theme["spaces"];
