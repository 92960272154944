import { useEffect } from "react";
import { useAppConfig } from "src/components/providers/AppConfigProvider";

declare global {
  interface Window {
    Calendly: {
      initHubspotForm(config: any): void;
    };
    hbspt: {
      forms: {
        create(config: any): void;
      };
    };
  }
}

interface CalendlyContactFormProps {
  onSubmit(): void;
}

export default function CalendlyContactForm({ onSubmit }: CalendlyContactFormProps) {
  const { CALENDLY_FORM_ID } = useAppConfig();
  const formId = CALENDLY_FORM_ID;

  useEffect(() => {
    window.hbspt.forms.create({
      region: "na1",
      portalId: "19920198",
      formId,
      target: "#hubspotForm",
      onFormSubmitted: onSubmit,
    });

    window.Calendly.initHubspotForm({
      id: formId,
      url: "https://calendly.com/api/form_builder/forms/feb92556-2c3a-4c21-9a73-925a5954ef0c/submissions",
    });
  }, [formId, onSubmit]);

  return <div id="hubspotForm" />;
}
