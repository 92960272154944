import { Outlet } from "react-router-dom";
import styled from "styled-components/macro";

import { isMobile } from "src/utils/mobile.utils";
import { themeColor } from "src/utils/styledComponents.utils";

import Icon from "src/components/common/Icon";
import { Center } from "src/components/common/layout/Box.styled";
import { Text1Bolder } from "src/components/common/layout/typography.styled";

const ContentContainer = styled.div`
  max-width: 300px;
  text-align: center;
`;

const StyledVideoIcon = styled(Icon.Video)`
  .color-1 path {
    fill: ${themeColor("blue.1100")};
  }

  .color-2 mask {
    fill: ${themeColor("gray.300")};
  }

  .color-3 path {
    fill: ${themeColor("pink.500")};
  }
`;

export default function MobileNotAllowedGate() {
  if (isMobile()) {
    return (
      <Center>
        <ContentContainer>
          <StyledVideoIcon />
          <Text1Bolder marginTop={40}>This page is not yet supported on mobile</Text1Bolder>
        </ContentContainer>
      </Center>
    );
  }

  return <Outlet />;
}
