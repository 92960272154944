import { TextDirection } from "src/utils/localization.utils";
import { AspectRatio } from "src/constants/video.constants";

export const ASPECT_RATIO_FRAME_DIMS: Record<AspectRatio, { width: number; height: number }> = {
  "16:9": { width: 80, height: 45 },
  "9:16": { width: 45, height: 80 },
  "1:1": { width: 45, height: 45 },
};

export const KEY_FRAME_MAX_DURATION = 0.05;

const TRANSCRIPT_SCALE = 1;

export const FONT_FAMILIES: Record<TextDirection, string> = {
  ltr: "Work Sans",
  rtl: "Assistant",
};

export const FONT_SIZE = 17 * TRANSCRIPT_SCALE;

export const FONT_WEIGHT = 300;

export const LINE_HEIGHT = 25 * TRANSCRIPT_SCALE;

export const LINE_INLINE_PADDING = 5;

export const BLANK_WIDTH = 120;

export const TRANSCRIPT_SELECTION_HANDLE_WIDTH = 5 * TRANSCRIPT_SCALE;

export const MAX_SAVED_STATES = 50; // RND-2654
