import TimelineActionButtons from "src/components/features/VideoTrimmer/TimelineActionButtons/TimelineActionButtons";
import { useVideoChapters } from "src/components/features/VideoTrimmer/providers/VideoChaptersProvider/VideoChaptersContext";
import { PropsWithChildren, RefObject, useEffect, useMemo, useState } from "react";
import { useVideoPlayback } from "src/components/features/VideoTrimmer/providers/VideoPlaybackProvider/VideoPlaybackContext";
import { useCurrentTimeListener } from "src/components/features/VideoTrimmer/providers/VideoCurrentTimeProvider/VideoCurrentTimeListenerContext";

interface ChapterTimelineActionButtonsProps extends PropsWithChildren {
  videoRef: RefObject<HTMLVideoElement>;
}
export function ChapterTimelineActionButtons({ videoRef }: ChapterTimelineActionButtonsProps) {
  const { splitChapter, deleteChapter } = useVideoChapters(); // prettier-ignore
  const { activeChapterIndex } = useVideoPlayback();
  const {
    isChapterSplittable,
    isChapterDeletable,
  } = useVideoChapters(); // prettier-ignore
  const [isSplitDisabled, setIsSplitDisabled] = useState(false);
  const { seekForward, seekBackward } = useVideoPlayback();
  const isDeleteDisabled = useMemo(
    () => !isChapterDeletable(activeChapterIndex),
    [activeChapterIndex, isChapterDeletable],
  );

  useEffect(() => {
    if (videoRef.current) {
      const { currentTime } = videoRef.current;

      setIsSplitDisabled(!isChapterSplittable(activeChapterIndex, currentTime));
    }
  }, [isChapterSplittable, activeChapterIndex, videoRef]);

  useCurrentTimeListener((currentTime: number) => {
    setIsSplitDisabled(!isChapterSplittable(activeChapterIndex, currentTime));
  });
  return (
    <TimelineActionButtons
      videoRef={videoRef}
      splitAction={splitChapter}
      deleteAction={deleteChapter}
      isSplitDisabled={isSplitDisabled}
      isDeleteDisabled={isDeleteDisabled}
      activeIndex={activeChapterIndex}
      seekForward={seekForward}
      seekBackward={seekBackward}
    />
  );
}
