/* eslint-disable consistent-return */

import { useEffect } from "react";

import useVariableRef from "src/hooks/useVariableRef";

export default function useRequestAnimationFrameLoop(callback: () => void, enabled = true) {
  const callbackRef = useVariableRef(callback);

  return useEffect(() => {
    if (enabled) {
      let recentAnimationFrame = -1;

      const currentTimeUpdater = () => {
        callbackRef.current();
        recentAnimationFrame = requestAnimationFrame(currentTimeUpdater);
      };

      currentTimeUpdater();

      return () => {
        cancelAnimationFrame(recentAnimationFrame);
      };
    }
  }, [callbackRef, enabled]);
}
