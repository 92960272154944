import { useSearchParams } from "react-router-dom";

import UpgradeFeature from "src/components/features/UpgradeFeature/UpgradeFeature";
import { FeatureId } from "src/types/pricing.types";

export default function UpgradeFeaturePage() {
  const [searchParams] = useSearchParams();
  const featureId = searchParams.get("featureId");

  return <UpgradeFeature featureId={featureId as FeatureId} />;
}
