import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { createModel } from "@rematch/core";

import { AssetStatus, AssetType } from "src/constants/model.constants";
import { RootModel } from "src/models/index";
import { selectId } from "src/models/selectId";
import { RootState } from "src/models/store";

import { filter, flow, orderBy } from "lodash/fp";
import apiClient from "src/network/ApiClient";
import {
  Asset,
  CreateAssetMutationVariables,
  GetAssetQueryVariables,
  GetAssetsQueryVariables,
  UpdateAssetMutationVariables,
} from "src/network/graphql/generatedGraphqlSDK"; // TODO: use * as gql

const assetAdapter = createEntityAdapter<Asset>({ selectId });
const assetAdapterSelectors = assetAdapter.getSelectors((state: RootState) => state.assets);

const selectByType = createSelector(
  [
    assetAdapterSelectors.selectAll,
    (state: RootState, type: AssetType) => type,
  ], // prettier-ignore
  (assets, type) => assets.filter((asset) => asset.type === type),
);

// return sorted by insert time and type
const selectAllByType = createSelector(
  [
    assetAdapterSelectors.selectEntities,
    (state: RootState, type: AssetType) => type,
  ], // prettier-ignore
  (assets, type) => flow(filter({ type }), orderBy("insertTime", "desc"))(assets) as Asset[],
);

const selectAllByTypeAndStatus = createSelector(
  [
    selectAllByType,
    (state: RootState, type: AssetType, status: AssetStatus) => status,
  ], // prettier-ignore
  (assets, status) => flow(filter({ status }))(assets) as Asset[],
);

const selectAllByTypeAndStatusAndNotPublished = createSelector(
  [
    selectAllByTypeAndStatus,
  ], // prettier-ignore
  (assets) => flow(filter({ published: false }))(assets) as Asset[],
);

export const assetSelectors = {
  ...assetAdapterSelectors,
  selectByType,
  selectAllByType,
  selectAllByTypeAndStatus,
  selectAllByTypeAndStatusAndNotPublished,
};

const assets = createModel<RootModel>()({
  state: assetAdapter.getInitialState(),

  reducers: {
    setAssets: (state, payload: Asset[]) => assetAdapter.addMany(state, payload),
    setAsset: (state, payload: Asset) => assetAdapter.setOne(state, payload),
    upsertOne: (state, payload: Asset) => assetAdapter.upsertOne(state, payload),
  },

  effects: (dispatch) => ({
    async fetchAssets(variables: GetAssetsQueryVariables) {
      const res = await apiClient.getAssets(variables);
      dispatch.assets.setAssets(res);
      return res;
    },

    async fetchAsset(variables: GetAssetQueryVariables) {
      const res = await apiClient.getAsset(variables);
      dispatch.assets.setAsset(res);
      return res;
    },

    async fetchAssetsById(assetSids: string[]) {
      try {
        const promises = assetSids.map((sid) => apiClient.getAsset({ sid }));
        const results = (await Promise.all(promises)).filter(Boolean);
        if (results.length) dispatch.assets.setAssets(results);
        return results;
      } catch (error) {
        return null;
      }
    },

    async createAsset({
      // logo asset only for now need to change name
      content,
      ...variables
    }: Omit<CreateAssetMutationVariables, "type"> & { type: AssetType; content: File | Blob }) {
      const asset = await apiClient.createAsset(variables);

      await apiClient.setAssetContent(asset.sid!, content);
      dispatch.assets.setAssets([asset]);

      return asset.sid!;
    },

    async updateAsset(variables: UpdateAssetMutationVariables) {
      const asset = await apiClient.updateAsset(variables);
      dispatch.assets.upsertOne(asset);
      return asset;
    },
  }),
});

export default assets;
