import { PropsWithChildren, ReactNode, useState } from "react";

import { switchProp, themeColor, themeProp } from "src/utils/styledComponents.utils";

import styled from "styled-components/macro";

import Icon from "src/components/common/Icon/Icon";
import Popper from "src/components/common/popovers/Popper";
import { ifProp } from "styled-tools";

const ItemMenuContainer = styled.div<{ variant: "light" | "dark" | "pink"; disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  min-width: 20px;
  min-height: 20px;
  svg {
    height: 100%;
    margin-top: 1px;
    color: ${switchProp("variant", {
      dark: themeColor("black"),
      light: themeColor("white"),
      pink: themeColor("pink.500"),
    })};

    color: ${ifProp(
      "disabled",
      themeColor("gray.300"),
      switchProp("variant", { dark: themeColor("black"), light: themeColor("white"), pink: themeColor("pink.500") }),
    )};
    cursor: ${ifProp("disabled", "auto", "pointer")};
  }
`;

const ItemMenu = styled.div`
  border: 1px solid ${themeColor("gray.200")};
  border-radius: ${themeProp("borderRadii.large")}px;
  background-color: ${themeColor("white")};
  padding: 4px 0;
  box-shadow: 0px 4px 24px 0px rgba(3, 15, 20, 0.15);
`;

export interface ActionsMenuOptionProps {
  name: string;
  icon: any;
  action: () => void;
  hidden?: boolean;
  disabled?: boolean;
}

export interface ActionsMenuPopoverProps extends PropsWithChildren {
  variant?: "light" | "dark" | "pink";
  disabled?: boolean;
  triggerIcon?: ReactNode;
}

export default function ActionsMenuPopover({
  children,
  variant,
  disabled,
  triggerIcon = <Icon.Overflow />,
}: ActionsMenuPopoverProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popper
      isOpen={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      offsetY={0}
      disabled={disabled}
      placement="bottom-end"
      content={<ItemMenu onClick={() => setIsOpen(false)}>{children}</ItemMenu>}
    >
      <ItemMenuContainer variant={variant ?? "light"} disabled={disabled}>
        {triggerIcon}
      </ItemMenuContainer>
    </Popper>
  );
}
