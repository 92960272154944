import { Navigate } from "react-router-dom";
import { absoluteRoutes, applyPathParams } from "src/utils/routes.utils";
import useLocalStorage from "src/hooks/useLocalStorage";
import { LAST_BOARD_ID } from "src/constants/smartLibrary.constants";

export default function HomePage() {
  const [localStorageBoardId] = useLocalStorage(LAST_BOARD_ID, "");

  // display last board on login
  const url =
    localStorageBoardId && localStorageBoardId !== "null"
      ? applyPathParams(absoluteRoutes.platform.children.boards, { boardId: localStorageBoardId } as any)
      : absoluteRoutes.platform.self;

  return <Navigate to={url} replace />;
}
