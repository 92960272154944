import { ProgressContainer } from "src/components/features/SmartLibrary/SmartLibrary.styled";
import { AspectRatio } from "src/constants/video.constants";
import LanguageSelect from "src/components/common/LanguageSelector/LanguageSelect";
import styled from "styled-components/macro";
import { Button } from "src/components/common/buttons/Button.styled";
import { themeColor } from "src/utils/styledComponents.utils";
import { Maybe } from "graphql/jsutils/Maybe";
import { Footage } from "src/network/graphql/generatedGraphqlSDK";

const LangSelectorWrapper = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;

  ${Button} {
    height: 32px;
    width: 100%;
    margin-top: 10px;
  }
`;

const LanguageSelectorTitle = styled.span`
  color: ${themeColor("white")};
  margin-bottom: 4px;
  font-size: 14px;
`;

const UploadButton = styled(Button)`
  width: 20%;
  height: 24px;
  padding: 5px;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: 600;
`;

interface LanguageSelectionProps {
  selectedLanguage: Maybe<string>;
  onChangeLanguage: (languageCode: string) => void;
  onLanguageSubmit: (footage: Footage, selectedLanguage: string) => void;
  footage: Footage;
  footageUpdating: boolean;
  isLanguageSelectEnabled: boolean;
}

export function LanguageSelection({
  selectedLanguage,
  onChangeLanguage,
  onLanguageSubmit,
  footage,
  footageUpdating,
  isLanguageSelectEnabled,
}: LanguageSelectionProps) {
  return (
    <ProgressContainer aspectRatio={"16:9" as AspectRatio}>
      {isLanguageSelectEnabled && (
        <LangSelectorWrapper
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <LanguageSelectorTitle>Spoken Language</LanguageSelectorTitle>
          <LanguageSelect
            selectedLanguage={selectedLanguage ?? ""}
            onChangeLanguage={onChangeLanguage}
            isDisabled={footageUpdating}
            isLoading={footageUpdating}
          />

          <UploadButton
            variant="primary"
            size="xs"
            disabled={!selectedLanguage || footageUpdating}
            onClick={() => selectedLanguage && onLanguageSubmit(footage!, selectedLanguage)}
          >
            Select
          </UploadButton>
        </LangSelectorWrapper>
      )}
    </ProgressContainer>
  );
}
