import { components, MenuProps } from "react-select";

import styled from "styled-components/macro";

import { SequenceFilterOption } from "src/components/features/SmartLibrary/filtering/filtersSelect/SequenceFilterSelect";

const StyledMenu = styled(components.Menu)`
  &.select__menu {
    border-radius: 6px;
    border: unset;
    box-shadow: 0px 4px 16px 0px rgba(4, 27, 63, 0.2) !important;
    padding: 5px;
  }

  @media (min-height: 600px) {
    .select__menu-list {
      max-height: none !important;
    }
  }
` as any as typeof components.Menu;

export default function SequenceFilterMenu({ children, ...props }: MenuProps<SequenceFilterOption>) {
  return <StyledMenu {...props}>{children}</StyledMenu>;
}
