import styled from "styled-components/macro";

import { H3 } from "src/components/common/layout/typography.styled";
import { themeColor } from "src/utils/styledComponents.utils";

export const FlexAlignCenter = styled.div`
  display: inline-flex;
  align-items: center;
  flex-direction: column; ;
`;

export const OnboardingStepTitle = styled(H3)`
  font-family: "Work Sans"; // check this
  font-weight: 600;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  strong {
    font-weight: 600;
  }
`;

export const SubmitButton = styled.button`
  padding: 10px 20px;
  outline: none;
  border: none;
  border-radius: 20px;
  background-color: ${themeColor("pink.500")};
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${themeColor("blue.250")};
  }
  &:disabled {
    background-color: ${themeColor("pink.200")};
    pointer-events: none;
  }
`;
