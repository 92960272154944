import { components, MultiValueProps } from "react-select";

import styled from "styled-components/macro";

import { SequenceFilterOption } from "src/components/features/SmartLibrary/filtering/filtersSelect/SequenceFilterSelect";

const TextWrapper = styled.div`
  min-width: 22px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
`;

export default function SequenceFilterMultiValueLabel(props: MultiValueProps<SequenceFilterOption>) {
  const { data } = props;
  return (
    <components.MultiValue {...props}>
      <TextWrapper>{data.label.split(" ")[0]}</TextWrapper>
    </components.MultiValue>
  );
}
