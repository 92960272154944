import { PropsWithChildren, ReactElement } from "react";
import { themeColor } from "src/utils/styledComponents.utils";

import styled from "styled-components/macro";
import { ifProp } from "styled-tools";

const MenuOptionContainer = styled.div<{ disabled: boolean }>`
  position: relative;
  width: 100%;
  min-width: 120px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Open Sans" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${ifProp("disabled", themeColor("gray.300"), themeColor("gray.900"))};
  background-color: ${themeColor("white")};
  cursor: ${ifProp("disabled", "auto", "pointer")};
  padding: 11px 16px;
  pointer-events: ${ifProp("disabled", "none", "all")};

  svg {
    width: 22px;
    height: 20px;
    margin-right: 10px;

    path {
      fill: ${ifProp("disabled", themeColor("gray.300"), themeColor("blue.900"))};
    }
  }
  &:hover {
    background-color: ${themeColor("blue.50")};
  }

  &:not(:last-child)::after {
    content: "";
    display: block;
    width: calc(100% - 32px);
    height: 1px;
    background-color: ${themeColor("gray.200")};
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
`;

interface ActionsMenuOptionProps extends PropsWithChildren {
  icon: ReactElement;
  onSelect: () => void;
  disabled?: boolean;
  hidden?: boolean;
}

export default function ActionsMenuOption({ children, icon, onSelect, disabled, hidden }: ActionsMenuOptionProps) {
  if (hidden) {
    return null;
  }
  return (
    <MenuOptionContainer onClick={onSelect} disabled={!!disabled}>
      {icon}
      {children}
    </MenuOptionContainer>
  );
}
