export enum ShortcutsKeys {
  forward = "mod+right",
  backward = "mod+left",
  togglePlay = "space",
  playBackRate = "mod+shift+p",
  zoomReset = "mod+0",
  zoomIn = "mod++",
  zoomIn2 = "mod+=",
  zoomOut = "mod+-",
  split = "shift+s",
  delete = "mod+backspace",
  undo = "mod+z",
  redo = "mod+shift+z",
  cropFill = "shift+f",
  cropFit = "shift+t",
  copyDragPosition = "mod+c",
  pasteDragPosition = "mod+v",
}

export const getShortcutKeyLabel = (key: ShortcutsKeys) =>
  `(${key
    .replace("mod", /Mac|iPod|iPhone|iPad/.test(navigator.platform) ? "⌘" : "ctrl")
    .replace("right", "→")
    .replace("left", "←")})`;
