import { io, Socket } from "socket.io-client";

import { FootageUpdateEventDataType } from "src/components/features/SmartLibrary/smartLibrary.utils";
import { AppConfig } from "src/components/providers/AppConfigProvider";
import { Chapter, DownloadInfo, Music, Org, Sequence, User, Word } from "src/network/graphql/generatedGraphqlSDK";

interface EntityStatus {
  statusType: string;
  sid: string;
  userSid: string;
  time: number;
}

interface Ackable {
  sid: string;
  itemId: string;
  type: string;
  statusType: string;
  ack: boolean;
}

const isAckable = (data: object): data is Ackable => (data as Ackable).ack;

interface EmitEvents {
  user(sid: string): void;
  registerClient(data: { userSid: string; orgSid?: string | null }): void;
  sequence(sid: string): void;
  ack(data: { sid: string; itemId: string; objectType: string; type: string }): void;
}

interface ListenEvents {
  user(data: { user: User }): void;
  org(data: { org: Org }): void;
  footages(data: FootageUpdateEventDataType): void;
  sequence(data: { sequence: Sequence }): void;
  closedCaption(data: { words: Word[] }): void;
  scene(data: { scene: Chapter }): void;
  music(data: { music: Music }): void;
  audio(data: { progress: number }): void;
  watch(data: EntityStatus): void;
  unwatch(data: EntityStatus): void;
  connect(): void;
  downloadInfos(data: { downloadInfo: DownloadInfo }): void;
}

export type EventUnion = {
  [E in keyof ListenEvents]: {
    name: E;
    args: Parameters<ListenEvents[E]>;
  };
}[keyof ListenEvents];

type StrictAnyEventListener = (event: EventUnion) => void;
type AnyEventListener = (eventName: string, ...eventArgs: any[]) => void;
type SocketType = Socket<ListenEvents, EmitEvents>;

class SocketClient {
  private readonly mSockets: SocketType[] = [];
  private readonly onAnyListeners = new Map<StrictAnyEventListener, AnyEventListener>();
  private url?: string;

  config(appConfig: AppConfig) {
    this.url = appConfig.STATUS_URL;
  }

  init(authToken: string) {
    if (!this.url) throw new Error("SocketClient is not configured");

    this.mSockets.push(
      io(this.url, { auth: { ps: authToken } }),
      io(this.url, { auth: { ps: authToken }, path: "/v2/socket.io" }),
    );

    this.onAny((event) => {
      // eslint-disable-next-line no-console
      console.log("socket client onAny", { event });

      if (event.args[0] && isAckable(event.args[0])) {
        const { statusType: objectType, ...rest } = event.args[0];
        this.emit("ack", { ...rest, objectType });
      }
    });
  }

  emit<E extends keyof EmitEvents>(eventName: E, ...args: Parameters<EmitEvents[E]>) {
    this.mSockets.forEach((socket) => socket.emit(eventName, ...args));
    return this;
  }

  on<E extends keyof ListenEvents>(eventName: E, callback: ListenEvents[E]) {
    this.mSockets.forEach((socket) => socket.on(eventName, callback as any));
    return this;
  }

  onAny(callback: StrictAnyEventListener) {
    const mappedCallback: AnyEventListener = (eventName, ...eventArgs) =>
      callback({ name: eventName, args: eventArgs } as EventUnion);

    this.mSockets.forEach((socket) => socket.onAny(mappedCallback));
    this.onAnyListeners.set(callback, mappedCallback);
    return this;
  }

  offAny(callback: StrictAnyEventListener) {
    this.mSockets.forEach((socket) => socket.offAny(this.onAnyListeners.get(callback)));
    this.onAnyListeners.delete(callback);
    return this;
  }

  off<E extends keyof ListenEvents>(eventName: E, callback: ListenEvents[E]) {
    this.mSockets.forEach((socket) => socket.off(eventName, callback as any));
    return this;
  }
}

const socketClient = new SocketClient();

export default socketClient;
