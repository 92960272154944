import { ProgressContainer, StatusDiv, StatusTime } from "src/components/features/SmartLibrary/SmartLibrary.styled";
import { AspectRatio } from "src/constants/video.constants";
import { CircularLoader } from "src/components/common/loaders/CircularLoader";
import Icon from "src/components/common/Icon";

interface FootageUploadStatusProps {
  status?: string;
  shouldShowStatusTime?: boolean;
}

export function FootageUploadStatus({ status, shouldShowStatusTime }: FootageUploadStatusProps) {
  return (
    <ProgressContainer aspectRatio={"16:9" as AspectRatio}>
      <CircularLoader size={26} thickness={3} />
      {status && <StatusDiv>{status ?? ""}</StatusDiv>}
      {shouldShowStatusTime && (
        <StatusTime>
          <Icon.Duration />3 min left
        </StatusTime>
      )}
    </ProgressContainer>
  );
}
