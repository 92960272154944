import React from "react";
import Color from "color";
import styled from "styled-components/macro";

import { themeProp } from "src/utils/styledComponents.utils";

import { Text3 } from "src/components/common/layout/typography.styled";

export interface Palette {
  name: string;
  colors: string[];
}

const Container = styled.div`
  width: 100%;
`;

const SwatchContainer = styled.div`
  padding-top: 4px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
`;

const Swatch = styled.div<{ color: string }>`
  width: 36px;
  height: 36px;
  margin: 4px;
  background-color: ${({ color }): string => color};
  border-radius: ${themeProp("borderRadii.medium")}px;
  border: solid 1px ${themeProp("colors.gray.50")};
  cursor: pointer;
`;

interface SwatchPaletteProps {
  onChange(color: Color): void;
  palette: Palette;
}

export default function SwatchPalette({ palette, onChange }: SwatchPaletteProps) {
  return (
    <Container>
      <Text3>{palette.name}</Text3>

      <SwatchContainer>
        {palette.colors.map((color) => (
          <Swatch key={color} color={color} onClick={() => onChange(Color(color))} />
        ))}
      </SwatchContainer>
    </Container>
  );
}
