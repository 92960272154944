import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "src/models/store";
import { useAppConfig } from "src/components/providers/AppConfigProvider";
import { wordsSelectors } from "src/models/Words.model";
import useInitiatedHistory from "src/components/features/VideoTrimmer/common/hooks/useInitiatedHistory";
import useBeforeUnloadBlocker from "src/hooks/useBeforeUnloadBlocker";
import { ChapterTimeInterval, Word } from "src/types/video-trimmer.types";
import { UseHistoryStateReturnValue } from "src/hooks/useHistoryState";
import { footageSelectors } from "src/models/Footage.model";
import { EntityId } from "@reduxjs/toolkit";
import { Clip, ClipStatus, Footage, FootageStatus } from "src/network/graphql/generatedGraphqlSDK";
import { TextDirection } from "src/utils/localization.utils";
import { sumBy } from "lodash/fp";
import { AspectRatio } from "src/constants/video.constants";
import { getIsOriginalVideoAvailable } from "src/components/features/SmartLibrary/smartLibrary.utils";
import { SaveStatus } from "src/models/Session.model";

export enum FootageEditReadinessLevel {
  LEVEL1 = "level1",
  LEVEL2 = "level2",
  LEVEL3 = "level3",
}

export interface InitFootageVideoPlayerReturn {
  videoSrc: string;
  videoDuration: number;
  wordsHistory: UseHistoryStateReturnValue<Word[]>;
  chaptersHistory: UseHistoryStateReturnValue<ChapterTimeInterval[]>;
  filename: string;
  languageCode: string;
  textDirection: TextDirection;
  canEditTranscript: boolean;
  sequenceCount: number;
  videoAspectRatio: AspectRatio;
  videoAvailability: { isAvailable: boolean; message: string };
}

export default function useInitFootageVideoPlayer(footageId: string): InitFootageVideoPlayerReturn {
  const { PLAYBACK_URL } = useAppConfig();
  const appConfig = useAppConfig();
  const dispatch = useDispatch<Dispatch>();
  const footage = useSelector((state: RootState) => footageSelectors.selectById(state, footageId as EntityId)) as Footage; // prettier-ignore
  const videoSrc = useSelector((state: RootState) => footageSelectors.selectVideoSrc(state, footageId, PLAYBACK_URL)); // prettier-ignore
  const videoDuration = footage?.mediainfo?.duration ?? 0;
  const videoAspectRatio = (footage?.mediainfo?.aspectRatio as AspectRatio) ?? null;
  const filename = footage?.filename ?? "";
  const languageCode = footage?.languageCode ?? "en/US";
  const footageEditReadinessCheckLevel = FootageEditReadinessLevel.LEVEL2;
  const sequenceCount = useMemo(() => sumBy((clip: Clip) => clip?.sequences?.length ?? 0)(footage?.clips), [footage]);
  const videoAvailability = getIsOriginalVideoAvailable(footage as Footage);
  const saveStatus = useSelector((state: RootState) => state.session?.saveStatus);

  const canEditTranscript = useMemo(() => {
    if (footage.status === FootageStatus.Completed) return true;
    if (
      footageEditReadinessCheckLevel === FootageEditReadinessLevel.LEVEL1 &&
      footage?.status === FootageStatus.Ready
    ) {
      return true;
    }
    if (
      footageEditReadinessCheckLevel === FootageEditReadinessLevel.LEVEL2 &&
      footage?.status === FootageStatus.Ready &&
      footage?.clips?.every((clip) => clip?.status !== ClipStatus.Pending)
    ) {
      return true;
    }
    return false;
  }, [footage?.clips, footage.status, footageEditReadinessCheckLevel]);

  const textDirection = useSelector((state: RootState) => footageSelectors.selectFootageTextDirection(state, footageId)); // prettier-ignore

  const initialWords = useSelector((state: RootState) => wordsSelectors.selectAbsoluteFootageWords(state, footageId, appConfig)); // prettier-ignore
  const initialChapters = useMemo(
    () =>
      [
        {
          start: 0,
          end: videoDuration,
          id: "default-footage-chapter",
          domain: { start: 0, end: videoDuration },
          isMuted: false,
          isSource: true,
        },
      ] as ChapterTimeInterval[],
    [videoDuration],
  );

  const wordsHistory = useInitiatedHistory(initialWords as Word[], []) as UseHistoryStateReturnValue<Word[]>;
  const chaptersHistory = useInitiatedHistory(
    initialChapters as ChapterTimeInterval[],
    [],
  ) as UseHistoryStateReturnValue<ChapterTimeInterval[]>;

  useEffect(
    () => {
      if (saveStatus === SaveStatus.SAVING) return;
      dispatch.words.fetchFootageWords({
        id: footageId,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [footageId, saveStatus],
  );

  useBeforeUnloadBlocker(wordsHistory.canUndo);

  return {
    videoSrc,
    videoDuration,
    filename,
    languageCode,
    wordsHistory,
    chaptersHistory,
    textDirection,
    canEditTranscript,
    videoAvailability,
    sequenceCount,
    videoAspectRatio,
  };
}
