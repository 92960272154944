import { useCallback, useRef, useState } from "react";
import styled, { useTheme } from "styled-components/macro";

import AudioSample from "src/types/media.types";

import * as projectEditorAnalytics from "src/analytics/sequenceContentEditor.analytics";
import * as timelineAnalytics from "src/analytics/timeline.analytics";

import { themeColor } from "src/utils/styledComponents.utils";

import { Box, FlexBox } from "src/components/common/layout/Box.styled";
import SoundWave from "src/components/features/VideoTrimmer/TimelineEditor/SoundWave";
import ChapterView from "src/components/features/VideoTrimmer/TimelineEditor/ChapterView";
import TimeIndicator from "src/components/features/VideoTrimmer/TimelineEditor/TimeIndicator/TimeIndicator";
import HoveredTimeIndicator from "src/components/features/VideoTrimmer/TimelineEditor/HoveredTimeIndicator";
import CurrentTimeIndicator from "src/components/features/VideoTrimmer/TimelineEditor/CurrentTimeIndicator";
import TimeAxis from "src/components/features/VideoTrimmer/TimelineEditor/TimeAxis";
import { useVideoChapters } from "src/components/features/VideoTrimmer/providers/VideoChaptersProvider/VideoChaptersContext";
import { useTimelineZoom } from "src/components/features/VideoTrimmer/providers/TimelineZoomProvider/TimelineZoomContext";
import { useVideoPlayback } from "src/components/features/VideoTrimmer/providers/VideoPlaybackProvider/VideoPlaybackContext";
import { TimeIntervalEdge } from "src/types/video-trimmer.types";

const Container = styled(Box)`
  position: relative;
  width: 100%;
`;

// TODO: get rid of this component
const HideOverflow = styled(FlexBox)`
  padding-top: 4px;
  position: relative;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
`;

const SoundWaveContainer = styled(FlexBox)`
  position: relative;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  background-color: ${themeColor("gray.225")};
`;

const TicksAxis = styled(Box)`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin-bottom: 8px;
  overflow: hidden;

  &:before {
    content: "";
    display: block;
    border-top: 4px solid ${themeColor("gray.275")};
  }
`;

interface FullTimelineProps {
  durationInSeconds: number;
  timeOffset: number;
  pixelPerSecondRatio: number;
}

const getFullTimelineAttrs = ({ durationInSeconds, timeOffset, pixelPerSecondRatio }: FullTimelineProps) => ({
  style: {
    width: durationInSeconds * pixelPerSecondRatio,
    transform: `translateX(${-timeOffset * pixelPerSecondRatio}px)`,
  },
});

// TODO: get rid of this component
const FullTimeline = styled.div.attrs<FullTimelineProps>(getFullTimelineAttrs)<FullTimelineProps>`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
`;

// TODO: get rid of this component
const PopupsTarget = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

interface PlayerTimelineProps {
  videoDurationInSeconds: number;
  audioSamples: AudioSample[];
  pixelPerSecondRatio: number;
}

export default function TimelineEditor({
  videoDurationInSeconds,
  audioSamples,
  pixelPerSecondRatio: originalPixelPerSecondRatio,
}: PlayerTimelineProps) {
  const fullTimelineRef = useRef<HTMLDivElement>(null); // TODO: use timelineWindowElement instead

  const [timelineHoverTarget, setTimelineHoverTarget] = useState<HTMLElement | null>(null);
  const [timelinePopupsTarget, setTimelinePopupsTarget] = useState<HTMLElement | null>(null);
  const [isResizing, setIsResizing] = useState(false);

  const { chapters, startChapterResizing, endChapterResizing, resizeChapter, mergeChapters } = useVideoChapters();
  const { zoomedTimeInterval, zoomFactor, toggleFocusTimeInterval } = useTimelineZoom();
  const { activeChapterIndex, startManualSeeking, isManualSeeking } = useVideoPlayback();
  const [resizeEdge, setResizeEdge] = useState<TimeIntervalEdge | null>(null);
  const pixelPerSecondRatio = originalPixelPerSecondRatio * zoomFactor;

  const onChapterResizeStart = useCallback(
    (index: number, edge: TimeIntervalEdge) => {
      startChapterResizing(index);
      setIsResizing(true);
      setResizeEdge(edge);
      projectEditorAnalytics.trackTrim(edge === "start" ? "from-start" : "from-end");
    },
    [startChapterResizing],
  );

  const onChapterResizeEnd = useCallback(
    (index: number) => {
      endChapterResizing(index);
      setIsResizing(false);
      setResizeEdge(null);
    },
    [endChapterResizing],
  );

  const theme = useTheme();

  return (
    <Container>
      <HideOverflow>
        <TicksAxis>
          <TimeAxis
            zoomedTimeInterval={zoomedTimeInterval}
            pxPerSec={pixelPerSecondRatio}
            width={timelinePopupsTarget?.clientWidth ?? 0}
            ticksSectionHeight={8}
            tickWidth={3}
            tickColor={theme.colors.gray["275"]}
            spacing={2}
            fontSize={12}
            fontColor={theme.colors.gray["700"]}
            fontFamily="Open Sans"
          />
        </TicksAxis>

        <SoundWaveContainer>
          <SoundWave
            height={40}
            width={timelinePopupsTarget?.clientWidth ?? 0}
            sampleRate={10}
            audioSampleBarColor={theme.colors.gray["350"]}
            audioSampleBarWidth={2}
            audioSamples={audioSamples}
            pxPerSec={pixelPerSecondRatio}
            zoomedTimeInterval={zoomedTimeInterval}
          />
        </SoundWaveContainer>

        <FullTimeline
          ref={fullTimelineRef}
          durationInSeconds={videoDurationInSeconds}
          timeOffset={zoomedTimeInterval.start}
          pixelPerSecondRatio={pixelPerSecondRatio}
          onMouseDown={startManualSeeking}
          onDoubleClick={() => {
            toggleFocusTimeInterval(chapters[activeChapterIndex], chapters.length);
            timelineAnalytics.trackZoomIn("chapter-double-click");
          }}
        >
          {chapters.map((chapter, index) => (
            <ChapterView
              key={chapter.id}
              index={index}
              value={chapter}
              isActive={index === activeChapterIndex}
              pixelPerSecond={pixelPerSecondRatio}
              zoomedTimeInterval={zoomedTimeInterval}
              popupTarget={timelinePopupsTarget}
              chapters={chapters}
              mergeChapters={mergeChapters}
              onResize={resizeChapter}
              onResizeStart={onChapterResizeStart}
              onResizeEnd={onChapterResizeEnd}
            />
          ))}

          {isResizing && chapters[activeChapterIndex] && resizeEdge && (
            <TimeIndicator
              timeInSeconds={chapters[activeChapterIndex]?.[resizeEdge]}
              pixelPerSecond={pixelPerSecondRatio}
              popupTarget={timelinePopupsTarget}
            />
          )}

          {!isResizing && (
            <>
              <CurrentTimeIndicator pixelPerSecond={pixelPerSecondRatio} popupTarget={timelinePopupsTarget} />

              {!isManualSeeking && (
                <HoveredTimeIndicator
                  fullTimelineRef={fullTimelineRef}
                  pixelPerSecond={pixelPerSecondRatio}
                  popupTarget={timelineHoverTarget}
                />
              )}
            </>
          )}
        </FullTimeline>
      </HideOverflow>

      <PopupsTarget ref={setTimelineHoverTarget} />
      <PopupsTarget ref={setTimelinePopupsTarget} />
    </Container>
  );
}
