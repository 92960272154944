import { themeColor } from "src/utils/styledComponents.utils";
import styled from "styled-components/macro";
import { ifProp } from "styled-tools";

const ProgressBarContainer = styled.div`
  width: 100%;
  height: 4px;
  border-radius: 5px;
  background-color: ${themeColor("gray.300")};
`;

const Status = styled.div<{ isPaused: boolean }>`
  height: 100%;
  border-radius: 5px;
  background-color: ${ifProp("isPaused", themeColor("blue.500"), themeColor("pink.500"))};
  transition: opacity, width 650ms ease-out;
`;

interface ProgressBarProps {
  progress: string;
  isPaused: boolean;
}

export default function ProgressBar({ progress, isPaused }: ProgressBarProps) {
  return (
    <ProgressBarContainer>
      <Status isPaused={isPaused} style={{ width: progress }} />
    </ProgressBarContainer>
  );
}
