import TimelineActionButtons from "src/components/features/VideoTrimmer/TimelineActionButtons/TimelineActionButtons";
import { PropsWithChildren, RefObject, useEffect, useMemo, useState } from "react";
import { useVideoCrop } from "src/components/features/VideoCropper/providers/VideoCropProvider/VideoCropContext";

interface ChapterTimelineActionButtonsProps extends PropsWithChildren {
  videoRef: RefObject<HTMLVideoElement>;
}
export function CropTimelineActionButtons({ videoRef }: ChapterTimelineActionButtonsProps) {
  const { splitCrop, deleteCrop, activeCropIndex, isCropSplittable, isCropDeletable, seekBackward, seekForward } = useVideoCrop(); // prettier-ignore
  const [isSplitDisabled, setIsSplitDisabled] = useState(false);

  useEffect(() => {
    if (videoRef.current) {
      setIsSplitDisabled(!isCropSplittable());
    }
  }, [isCropSplittable, videoRef]);

  const isDeleteDisabled = useMemo(() => !isCropDeletable(), [isCropDeletable]);

  return (
    <TimelineActionButtons
      videoRef={videoRef}
      splitAction={splitCrop}
      deleteAction={deleteCrop}
      isSplitDisabled={isSplitDisabled}
      isDeleteDisabled={isDeleteDisabled}
      activeIndex={activeCropIndex}
      seekForward={seekForward}
      seekBackward={seekBackward}
    />
  );
}
