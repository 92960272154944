import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Dispatch, RootState } from "src/models/store";
import { Word } from "src/types/video-trimmer.types";
import { createGapsDiffDto, createWordsDiffChunksDto } from "src/utils/words.utils";
import { Helmet } from "react-helmet";
import { wordsSelectors } from "src/models/Words.model";
import { SaveStatus } from "src/models/Session.model";
import { useAppConfig } from "src/components/providers/AppConfigProvider";
import useInitFootageVideoPlayer from "src/components/features/VideoTrimmer/common/hooks/useInitFootageVideoPlayer";
import FootagePlayer from "src/components/features/FootagePlayer/FootagePlayer";
import { useUnmountEffect } from "src/hooks/useUnmountEffect";

export default function FootageContentEditorPage() {
  const { footageId } = useParams();
  const appConfig = useAppConfig();

  if (!footageId) {
    throw new Error("footageSid is required");
  }

  const dispatch = useDispatch<Dispatch>();
  const initialWords = useSelector((state: RootState) => wordsSelectors.selectAbsoluteFootageWords(state, footageId, appConfig)); // prettier-ignore
  const originalWords = useRef<Word[]>([]);
  const saveFootageStatus = useSelector((state: RootState) => state.session?.saveStatus);
  const {
    videoSrc,
    wordsHistory,
    chaptersHistory,
    filename,
    videoDuration,
    textDirection,
    languageCode,
    canEditTranscript,
    sequenceCount,
    videoAspectRatio,
    videoAvailability,
  } = useInitFootageVideoPlayer(footageId);

  const wordsLoading = useSelector((state: RootState) => !!state.loading.effects.words.fetchFootageWords.loading);

  useEffect(() => {
    if (!wordsLoading) {
      originalWords.current = initialWords;
    }
  }, [initialWords, wordsLoading]);

  useEffect(() => {
    if (saveFootageStatus === SaveStatus.SAVED) {
      dispatch.session.setSaveStatus(SaveStatus.PENDING);
    }
  }, [saveFootageStatus, dispatch.session]);

  useUnmountEffect(async () => {
    dispatch.session.setSaveStatus(SaveStatus.SAVING);

    if (wordsHistory.canUndo && originalWords.current.length && wordsHistory.state.length) {
      const gapsDto = createGapsDiffDto(wordsHistory.state, originalWords.current, videoDuration, 0);

      const wordsDto = createWordsDiffChunksDto(wordsHistory.state, originalWords.current, 0);

      if ((gapsDto.length || wordsDto.length) && footageId) {
        await dispatch.words.updateWords({
          footageId: footageId as string,
          input: { arrayOfArrays: wordsDto, removeWords: gapsDto },
        });
        originalWords.current = [];
      }
    }

    originalWords.current = [];
    dispatch.session.setSaveStatus(SaveStatus.SAVED);
  });

  return (
    <>
      <Helmet>
        <title>{filename || ""} | Peech - Automated Talk-Videos Creator</title>
      </Helmet>
      <FootagePlayer
        videoSrc={videoSrc}
        videoDuration={videoDuration}
        wordsHistory={wordsHistory}
        chaptersHistory={chaptersHistory}
        languageCode={languageCode}
        textDirection={textDirection}
        footageId={footageId}
        canEditTranscript={canEditTranscript}
        videoAvailability={videoAvailability}
        sequenceCount={sequenceCount}
        videoAspectRatio={videoAspectRatio}
      />
    </>
  );
}
