import { Fragment } from "react";
import styled from "styled-components/macro";

import { Stack } from "src/components/common/layout/Stack.styled";
import { H4, H5, Text2, Text2Bold } from "src/components/common/layout/typography.styled";

import { COMPANIES_LOGOS, SPLASH_SCREEN_TESTIMONIALS } from "src/constants/customerShowcase.constants";

const Avatar = styled.img`
  width: 156px;
  height: 156px;
  border-radius: 50%;
`;

const CompanyLogo = styled.img`
  margin: 4px 10px;
  height: 45px;
  filter: brightness(100);
`;

const TestimonialText = styled(H5)`
  max-width: 660px;
  white-space: pre-wrap;
`;

const LogosContainer = styled(Stack)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 75%;
  max-width: 800px;
`;

export default function SplashScreen() {
  return (
    <Fragment>
      {SPLASH_SCREEN_TESTIMONIALS.map(({ fileData: pictureURL, name, company, title, text }) => (
        <Fragment key={name}>
          <Stack direction="column" spacing={6} style={{ alignItems: "center" }}>
            <Avatar src={pictureURL} alt={name} />
            <Text2>{`${name}, ${title}`}</Text2>
            <Text2Bold style={{ textTransform: "uppercase" }}>{company}</Text2Bold>
          </Stack>

          <TestimonialText marginTop={50}>{`“${text}”`}</TestimonialText>
        </Fragment>
      ))}

      <H4 marginTop={125}>Trusted by unstoppable companies like:</H4>

      <LogosContainer marginTop={10} direction="row" spacing={20}>
        {COMPANIES_LOGOS.map(({ logoImageBig, logoName }) => (
          <CompanyLogo key={logoName} src={logoImageBig} alt={logoName} title={logoName} />
        ))}
      </LogosContainer>
    </Fragment>
  );
}
