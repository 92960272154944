import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components/macro";
import { compact, flow, groupBy, some } from "lodash/fp";

import * as projectEditorAnalytics from "src/analytics/sequenceContentEditor.analytics";

import { ChapterTimeInterval, TimeInterval } from "src/types/video-trimmer.types";

import { LINE_HEIGHT } from "src/constants/video-trimmer.constants";

import useMousetrap from "src/hooks/useMousetrap";

import { themeColor } from "src/utils/styledComponents.utils";
import { timeIntervalUtils } from "src/utils/timeInterval.utils";

import Icon from "src/components/common/Icon";
import Popper from "src/components/common/popovers/Popper";
import { Stack } from "src/components/common/layout/Stack.styled";
import { useVideoChapters } from "src/components/features/VideoTrimmer/providers/VideoChaptersProvider/VideoChaptersContext";
import { useVideoWords } from "src/components/features/VideoTrimmer/providers/VideoWordsProvider/VideoWordsContext";
import { useFeatureFlag } from "src/components/providers/FeatureFlagsProvider";
import TranscriptCorrectionPopover from "src/components/features/VideoTrimmer/VideoTranscript/TranscriptCorrectionPopover";
import { ifProp } from "styled-tools";
import { useVideoTranscript } from "src/components/features/VideoTrimmer/providers/VideoTranscriptProvider/VideoTranscriptContext";
import { Panel } from "src/components/common/popovers/panel/Panel";

const SelectionActionButtonStack = styled(Stack)`
  display: flex;
  user-select: none;
  padding: 3px;
  background: ${themeColor("white")};
  border-radius: 6px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  white-space: pre;
`;

const SelectionActionButton = styled.button<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 4px 5px;
  border: none;
  outline: none;
  background-color: transparent;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: ${themeColor("gray.500")};
  opacity: ${ifProp("disabled", "0.5", "1")};

  &:not(:disabled):hover {
    background: ${themeColor("gray.200")};
  }

  svg {
    flex: 0 0 auto;
    margin-right: 6px;
  }
`;

const Divider = styled.hr`
  width: 2px;
  border-radius: 2px;
  margin-block: 4px;
  border: none;
  background: ${themeColor("gray.275")};
`;

export type Props = {
  selectionTimeInterval: TimeInterval | null;
  recentInteractedSelectionEdge: "start" | "end";
  cancelSelection(): void;
  isPlayable: boolean;
  isHighlighted: boolean;
};

export default function SelectionActionsPopover({
  selectionTimeInterval,
  recentInteractedSelectionEdge,
  cancelSelection,
  isPlayable,
  isHighlighted,
}: Props) {
  const { chapters, addTimeIntervals, subtractTimeIntervals } = useVideoChapters();
  const {
    highlightWords,
    unHighlightWords,
    replaceSelectedWords,
    replaceSearchResultsWords,
    maxWordsToCorrectAtOnce,
    selectedWords,
    selectedWordsTimeInterval,
  } = useVideoWords();
  const isContentEditorHighlightEnabled = useFeatureFlag("contentEditorHighlight");
  const [transcriptCorrectionIsOpen, setTranscriptCorrectionIsOpen] = useState(false);
  const { setSearchQuery } = useVideoTranscript();
  const { allowedActions: wordsAllowedActions } = useVideoWords();
  const { allowedActions: chaptersAllowedActions } = useVideoChapters();

  const isRemoveHidden = useMemo(() => {
    if (!chaptersAllowedActions?.removeChapter?.isAllowed) return true;
    if (!selectionTimeInterval || !isPlayable) return true;

    if (
      !chapters.some(
        (chapter) =>
          timeIntervalUtils.intersectsTimeInterval(chapter, selectionTimeInterval) ||
          timeIntervalUtils.containsTimeInterval(chapter, selectionTimeInterval) ||
          timeIntervalUtils.containsTimeInterval(selectionTimeInterval, chapter),
      )
    ) {
      return true;
    }

    return flow(
      groupBy<ChapterTimeInterval>(({ domain }) => JSON.stringify(domain)),
      some<ChapterTimeInterval[]>((chapterGroup) =>
        chapterGroup.every((chapter) => timeIntervalUtils.containsTimeInterval(selectionTimeInterval, chapter)),
      ),
    )(chapters);
  }, [selectionTimeInterval, isPlayable, chaptersAllowedActions?.removeChapter?.isAllowed, chapters]);

  const isRevertHidden = useMemo(() => {
    if (!chaptersAllowedActions?.addChapter?.isAllowed) return true;
    if (!selectionTimeInterval) return true;
    return timeIntervalUtils
      .resolveOverlaps(chapters, true)
      .some((chapter) => timeIntervalUtils.containsTimeInterval(chapter, selectionTimeInterval));
  }, [chaptersAllowedActions?.addChapter?.isAllowed, selectionTimeInterval, chapters]);

  const isHighlightHidden = useMemo(() => {
    if (!isContentEditorHighlightEnabled) return true;
    if (!isPlayable) return true;
    if (selectedWords.every((word) => word.kind === "highlightedWord")) return true;
    if (selectedWords.some((word) => word.kind === "highlightedWord")) return false;
    return false;
  }, [isContentEditorHighlightEnabled, isPlayable, selectedWords]);

  const isUnHighlightHidden = useMemo(() => {
    if (!isContentEditorHighlightEnabled) return true;
    if (!isPlayable) return true;
    if (selectedWords.every((word) => word.kind === "highlightedWord")) return false;
    if (selectedWords.some((word) => word.kind === "highlightedWord")) return true;
    return !isHighlighted;
  }, [isContentEditorHighlightEnabled, isHighlighted, isPlayable, selectedWords]);

  const isCorrectDisabled = useMemo(
    () =>
      selectedWords.length > maxWordsToCorrectAtOnce ||
      selectedWords.length === 0 ||
      !wordsAllowedActions.correct?.isAllowed,
    [selectedWords.length, maxWordsToCorrectAtOnce, wordsAllowedActions.correct?.isAllowed],
  );

  const correctSelectedWords = (wordsString: string) => {
    replaceSelectedWords(wordsString);
    cancelSelection();
  };

  const correctSearchResultsWords = (wordsString: string) => {
    replaceSearchResultsWords(wordsString);
    setSearchQuery("");
    cancelSelection();
  };

  const removeSelectedWords = () => {
    if (selectionTimeInterval) {
      subtractTimeIntervals([selectionTimeInterval]);
      cancelSelection();
      projectEditorAnalytics.trackRemove("content-selection");
    }
  };

  const revertSelectedWords = () => {
    if (selectionTimeInterval) {
      addTimeIntervals([selectionTimeInterval]);
      cancelSelection();
      projectEditorAnalytics.trackRevert("content-selection");
    }
  };

  const highlightSelectedWords = () => {
    if (selectedWordsTimeInterval) {
      highlightWords([selectedWordsTimeInterval]);
      cancelSelection();
      projectEditorAnalytics.trackHighlight("content-selection");
    }
  };

  const unHighlightSelectedWords = () => {
    if (selectedWordsTimeInterval) {
      unHighlightWords([selectedWordsTimeInterval]);
      cancelSelection();
      projectEditorAnalytics.trackUnHighlight("content-selection");
    }
  };

  const onCancelSelection = useCallback(() => {
    cancelSelection();
    projectEditorAnalytics.trackCancelSelection();
  }, [cancelSelection]);

  useEffect(() => {
    /** when pointer (mouse/trackpad/etc...) text selection ends, it triggers a click event right away
     *  so to prevent an immediate call to onCancelSelection on component mount, this click must be ignored */
    const timeoutId = setTimeout(() => {
      document.addEventListener("click", onCancelSelection);
    });

    return () => {
      clearTimeout(timeoutId);
      document.removeEventListener("click", onCancelSelection);
    };
  }, [onCancelSelection]);

  useMousetrap("escape", onCancelSelection);

  const actions = compact([
    !isRemoveHidden ? (
      <SelectionActionButton onClickCapture={removeSelectedWords}>
        <Icon.Trash size={14} color="blue.500" />
        Remove
      </SelectionActionButton>
    ) : null,

    !isRevertHidden ? (
      <SelectionActionButton onClickCapture={revertSelectedWords}>
        <Icon.Undo size={14} color="gray.500" />
        Revert
      </SelectionActionButton>
    ) : null,

    !isUnHighlightHidden ? (
      <SelectionActionButton
        disabled={!wordsAllowedActions?.highlight?.isAllowed}
        onClickCapture={unHighlightSelectedWords}
      >
        {!wordsAllowedActions?.highlight?.isAllowed ? (
          <Popper
            style={{ display: "inline", marginLeft: "7px", lineHeight: "20px" }}
            content={<Panel>{wordsAllowedActions?.correct?.message}</Panel>}
            trigger="hover"
            placement="bottom"
            offsetY={0}
            offsetX={0}
          >
            <Icon.Star size={14} color="blue.500" />
            Un-Highlight
          </Popper>
        ) : (
          <>
            <Icon.Star size={14} color="blue.500" />
            Un-Highlight
          </>
        )}
      </SelectionActionButton>
    ) : null,

    !isHighlightHidden ? (
      <SelectionActionButton
        disabled={!wordsAllowedActions?.highlight?.isAllowed}
        onClickCapture={highlightSelectedWords}
      >
        {!wordsAllowedActions?.highlight?.isAllowed ? (
          <Popper
            style={{ display: "inline", marginLeft: "7px", lineHeight: "20px" }}
            content={<Panel>{wordsAllowedActions?.highlight?.message}</Panel>}
            trigger="hover"
            placement="bottom"
            offsetY={0}
            offsetX={0}
          >
            <Icon.Star size={14} color="blue.500" />
            Highlight
          </Popper>
        ) : (
          <>
            <Icon.Star size={14} color="blue.500" />
            Highlight
          </>
        )}
      </SelectionActionButton>
    ) : null,
    <SelectionActionButton disabled={isCorrectDisabled} onClickCapture={() => setTranscriptCorrectionIsOpen(true)}>
      {isCorrectDisabled ? (
        <Popper
          style={{ display: "inline", marginLeft: "7px", lineHeight: "20px" }}
          content={
            <Panel>
              {!wordsAllowedActions?.correct?.isAllowed ? wordsAllowedActions?.correct?.message : "Select fewer words"}
            </Panel>
          }
          trigger="hover"
          placement="bottom"
          offsetY={0}
          offsetX={0}
        >
          <Icon.Carret size={12} color="blue.500" />
          Correct
        </Popper>
      ) : (
        <>
          <Icon.Carret size={12} color="blue.500" />
          Correct
        </>
      )}
    </SelectionActionButton>,
  ]);

  return (
    <>
      <Popper
        isOpen
        useClickOutside={false}
        placement={recentInteractedSelectionEdge === "end" ? "bottom" : "top"}
        offsetY={recentInteractedSelectionEdge === "end" ? 5 : LINE_HEIGHT + 5}
        content={
          <SelectionActionButtonStack direction="row" spacing={4}>
            {actions.map((action, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={index}>
                {action}
                {index < actions.length - 1 && <Divider />}
              </Fragment>
            ))}
          </SelectionActionButtonStack>
        }
      />
      <TranscriptCorrectionPopover
        defaultValue={selectedWords.map((w) => w.word).join(" ")}
        recentInteractedSelectionEdge={recentInteractedSelectionEdge}
        isOpen={transcriptCorrectionIsOpen}
        toggleIsOpen={setTranscriptCorrectionIsOpen}
        onCorrect={correctSelectedWords}
        onCorrectAll={correctSearchResultsWords}
      />
    </>
  );
}
