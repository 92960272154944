import { PropsWithChildren } from "react";
import styled from "styled-components/macro";

import useResizeObserver from "use-resize-observer";

const Container = styled.div<{ isExpanded: boolean; height?: number }>`
  position: relative;
  width: 100%;
  height: ${(props) => (props.isExpanded ? props.height : 0)}px;
  overflow: hidden;
  transition: height 500ms cubic-bezier(0.55, 0.1, 0.41, 0.83);
`;

const ContentHeightMeasurer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

export function Expandable({ children, isExpanded }: PropsWithChildren<{ isExpanded: boolean }>) {
  const { ref, height } = useResizeObserver();

  return (
    <Container height={height} isExpanded={isExpanded}>
      <ContentHeightMeasurer ref={ref}>{children}</ContentHeightMeasurer>
    </Container>
  );
}
