import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { RematchRootState, createModel } from "@rematch/core";
import { sumBy } from "lodash/fp";

import { speakerCuePointSelectors } from "src/models/SpeakerQuePoint.model";
import { RootModel } from "src/models/index";
import { selectId } from "src/models/selectId";
import { RootState } from "src/models/store";

import { ChapterStatus } from "src/constants/model.constants";

import { getLocaleTextDirection } from "src/utils/localization.utils";

import apiClient from "src/network/ApiClient";
import socketClient from "src/network/SocketClient";
import * as gql from "src/network/graphql/generatedGraphqlSDK";

const sequenceAdapter = createEntityAdapter<gql.Sequence>({ selectId });

const sequenceAdapterSelectors = sequenceAdapter.getSelectors((state: RootState) => state.sequences);

const selectVideoChapters = createSelector(sequenceAdapterSelectors.selectById, (sequence) => {
  const chapters = sequence?.chapters ?? [];

  return chapters.filter(
    (chapter) =>
      chapter && !chapter.cuePointType && [ChapterStatus.Ready, ChapterStatus.Pending].includes(chapter.status!),
  ) as gql.Chapter[];
});

const selectSourceChapters = createSelector(sequenceAdapterSelectors.selectById, (sequence) => {
  const chapters = sequence?.chapters ?? [];

  return chapters.filter((chapter) => chapter && !chapter.linkSid && !chapter.cuePointType) as gql.Chapter[];
});

const selectVideoDuration = createSelector(
  selectSourceChapters,
  (sequenceSourceVideoChapters) => Math.floor(sumBy("srcDuration", sequenceSourceVideoChapters) * 100) / 100,
);

const selectUseFootageForCrop = createSelector(
  sequenceAdapterSelectors.selectById,
  (sequence) => sequence?.useFootageForCrop,
);

const selectVideoSrc = createSelector(
  (state: RootState, sequenceSid: string) => sequenceSid,
  (state: RootState, sequenceSid: string, baseURL: string) => baseURL,
  selectSourceChapters,
  selectUseFootageForCrop,
  (sequenceSid, baseURL, sequenceSourceVideoChapters, useFootageForCrop) => {
    const chaptersQuery = sequenceSourceVideoChapters
      .map((chapter) => `${chapter.sid},0,${chapter.srcDuration}`)
      .join(";");

    const type = useFootageForCrop ? "chapters-original" : "chapters";

    return chaptersQuery && `${baseURL}/dash/${sequenceSid}/${sequenceSid}/${type}/${chaptersQuery}/manifest.mpd`;
  },
);

const selectThumbnailUrl = createSelector(
  sequenceAdapterSelectors.selectById,
  (state: RootState, sequenceSid: string, baseURL: string) => baseURL,
  (state: RootState, sequenceSid: string, baseURL: string, size: number) => size,
  (sequence, baseURL, size) => {
    const aspectRatio = sequence?.aspectRatio ?? "16:9";
    const ratioParam = aspectRatio.replace(":", "-");
    const firstVideoChapter = sequence?.chapters?.find(
      (chapter) => chapter && !chapter.cuePointType && chapter.status !== ChapterStatus.Deleted,
    );

    if (!firstVideoChapter) {
      return null;
    }

    if (firstVideoChapter.assetSid) {
      return `${baseURL}/asset/${firstVideoChapter.assetSid}/Content/${size}/${ratioParam}/${
        firstVideoChapter.thumbnailVersion || 1
      }.png?`;
    }

    return `${baseURL}/tc/${sequence?.sid}/Chapter/${firstVideoChapter.sid}/${size}/16-9/${firstVideoChapter.thumbnailVersion}.png?`;
  },
);

const selectSequencesByFootageSid = createSelector(
  sequenceAdapterSelectors.selectAll,
  (state: RootState, footageSid: string) => footageSid,
  (sequences, footageSid) => {
    const seqArr = sequences.filter((seq) => seq.footageSid === footageSid);
    return seqArr;
  },
);

const selectSequenceCreationError = createSelector(
  (state: RootState) => !!state.loading.effects.assets.fetchAssets.error,
  (state: RootState) => !!state.loading.effects.assets.fetchAssetsById.error,
  (state: RootState) => !!state.loading.effects.session.getVideoTypes.error,
  (state: RootState) => !!state.loading.effects.session.getEditRules.error,
  (state: RootState) => !!state.loading.effects.sequences.createSequence.error,
  (state: RootState) => !!state.loading.effects.sequences.fetchSequence.error,
  (state: RootState) => !!state.loading.effects.sequences.reorderSequenceChapters.error,
  (...errors) => errors.some(Boolean),
);

const selectSequenceTextDirection = createSelector(sequenceAdapterSelectors.selectById, (sequence) => {
  if (!sequence?.languageCode) {
    return "ltr";
  }

  return getLocaleTextDirection(sequence.languageCode);
});

const selectIsFaceDetectionCompleted = createSelector(sequenceAdapterSelectors.selectById, (sequence) =>
  sequence?.chapters
    ?.filter((chapter): chapter is gql.Chapter => !!chapter && !chapter.cuePointType)
    .every((chapter) => chapter.faceDetected),
);

export const sequenceSelectors = {
  ...sequenceAdapterSelectors,
  selectVideoChapters,
  selectSourceChapters,
  selectVideoDuration,
  selectVideoSrc,
  selectSequenceCreationError,
  selectThumbnailUrl,
  selectSequencesByFootageSid,
  selectSequenceTextDirection,
  selectIsFaceDetectionCompleted,
  selectUseFootageForCrop,
};

const sequences = createModel<RootModel>()({
  state: sequenceAdapter.getInitialState(),

  reducers: {
    setSequence: (state, payload: gql.Sequence) => sequenceAdapter.setOne(state, payload),

    setSequences: (state, payload: gql.Sequence[]) => sequenceAdapter.setMany(state, payload),

    removeSequence: (state, key: string) => sequenceAdapter.removeOne(state, key),

    updateSequence: (state, payload: { sid: string; update: gql.Sequence }) => ({
      ...state,
      entities: {
        ...state.entities,
        [payload.sid]: {
          ...state.entities[payload.sid],
          ...payload.update,
        },
      },
    }),
  },

  effects: (dispatch) => ({
    async fetchSequence(variables: gql.GetSequenceQueryVariables) {
      const res = await apiClient.getSequence(variables); // should be discussed: maybe we should pass one more property to define full or lean sequence we want to get in response

      if (res) {
        dispatch.sequences.setSequence(res);
      }

      return res;
    },

    async fetchSequencesByFootageId(variables: gql.GetSequencesByFootageIdQueryVariables) {
      const res = await apiClient.getSequencesByFootageId(variables);
      dispatch.sequences.setSequences(res);
    },

    async fetchSequenceProcessingStatusProps(variables: gql.GetSequenceProcessingStatusPropsQueryVariables) {
      const res = await apiClient.getSequenceProcessingStatusProps(variables);
      dispatch.sequences.updateSequence({ sid: variables.sequenceSid, update: res });
    },

    async reorderSequenceChapters(variables: gql.ReorderSequenceChaptersMutationVariables) {
      const res = await apiClient.reorderSequenceChapters(variables);
      dispatch.sequences.setSequence(res);
    },

    async createFromTemplate() {
      const res = await apiClient.createSequenceFromTemplate();
      dispatch.sequences.setSequence(res);
      return res;
    },

    async renameSequence(
      variables: gql.RenameSequenceMutationVariables,
      rootState: RematchRootState<RootModel, Record<string, never>>,
    ) {
      const res = await apiClient.renameSequence(variables);
      if (res?.sid) {
        const existingSequence = rootState.sequences.entities[res?.sid];
        if (existingSequence) {
          sequenceAdapter.updateOne(rootState.entities, {
            id: res.sid,
            changes: { ...existingSequence, title: res.title },
          });
        }
      }
    },

    async deleteSequence(variables: gql.DeleteSequenceMutationVariables) {
      await apiClient.deleteSequence(variables);
      dispatch.sequences.removeSequence(variables.sequenceSid);
    },

    async duplicateSequence(variables: gql.DuplicateSequenceMutationVariables) {
      const res = await apiClient.duplicateSequence(variables);
      dispatch.sequences.fetchSequence({ sequenceSid: res.sid! });
    },

    async createSequence({
      userSid,
      preset,
      title,
      style,
      aspectRatio,
      editRulesSid,
      editRules,
      assetSids,
      defaultVideoType,
      skipUpdatePreset,
      manuallyCreatedFirstBoard,
    }: {
      userSid: string;
      preset: gql.Preset;
      title: string;
      style: gql.StyleAdd;
      aspectRatio: string;
      assetSids: string[];
      defaultVideoType: string;
      editRulesSid: string;
      editRules: gql.EditRulesAdd;
      skipUpdatePreset?: boolean; // TODO: remove
      manuallyCreatedFirstBoard?: string;
    }) {
      const sequence = await apiClient.createSequence({
        values: { style, editRules, editRulesSid, title, manuallyCreatedFirstBoard },
      });
      const sequenceSid = sequence.sid!;
      const presetSid = preset?.sid!;

      socketClient.emit("sequence", sequenceSid);

      for (const assetSid of assetSids) {
        await apiClient.addChapter({ sequenceSid, chapter: { assetSid, aspectRatio } });
      }

      // in fast creation flow we don't need to update users preset
      // TODO: remove, move out
      if (!skipUpdatePreset) {
        await dispatch.presets.updatePreset({ sid: presetSid, values: { defaultVideoType, editRules } });
      }

      await apiClient.updateSequence({ sid: sequenceSid, values: { aspectRatio } });
      await apiClient.applyPresetToSequence({ userSid, sequenceSid, presetSid });
      await apiClient.validateInputs({ sequenceSid });

      return sequenceSid;
    },

    async setDefaultSpeakers(sequenceSid: string, state) {
      const detectedSpeakerCuePoints = speakerCuePointSelectors.selectSequenceDetectedSpeakerCuePoints(state, sequenceSid); // prettier-ignore
      const undetectedSpeakerCuePoints = speakerCuePointSelectors.selectSequenceUndetectedSpeakerCuePoints(state, sequenceSid); // prettier-ignore
      const firstUndetectedSpeakerCuePoint = undetectedSpeakerCuePoints[0];

      const updateFirstSpeakerPromise =
        firstUndetectedSpeakerCuePoint && !detectedSpeakerCuePoints.length
          ? dispatch.speakerCuePoints.updateSpeakerCuePoint({
              sid: firstUndetectedSpeakerCuePoint.sid!,
              sequenceSid,
              values: { name: "speaker name", title: "speaker title", speakerSid: null },
            })
          : Promise.resolve();

      const deactivateSpeakerPromises = undetectedSpeakerCuePoints.map(({ sid }) =>
        dispatch.speakerCuePoints.deactivateSpeakerCuePoint({ sid: sid!, sequenceSid }),
      );

      await Promise.all([updateFirstSpeakerPromise, ...deactivateSpeakerPromises]);
      await dispatch.speakerCuePoints.fetchSpeakerCuePoints({ sequenceSid });
    },
  }),
});

export default sequences;
