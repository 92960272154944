import { useVideoEditRulesTiles } from "src/components/features/AutomaticCreateWizard/subSteps/SORStep/useVideoEditRulesTiles";
import { themeColor } from "src/utils/styledComponents.utils";
import styled from "styled-components/macro";
import { FormContextInterface } from "src/components/features/AutomaticCreateWizard/FormContext.interface";

const SORsContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 120px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
`;

const RuleItem = styled.div<{ isActive: boolean }>`
  padding: 0 7px;
  margin-bottom: 12px;
  margin-inline: 2px;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: ${({ isActive }) => (isActive ? themeColor("black") : themeColor("gray.250"))};
  border: ${({ isActive }) => (isActive ? "solid 1px" : "none")};
  border-color: ${themeColor("gray.300")};
  border-radius: 40px;
`;

export default function BrandButtonSORs({ formContext }: FormContextInterface) {
  const { watch } = formContext;
  const editRules = watch("brandKit.SORs.editRules") as { [key: string]: boolean | null | undefined };
  const videoEditRulesTiles = useVideoEditRulesTiles();

  if (!editRules) {
    return null;
  }

  return (
    <SORsContainer>
      {Object.keys(editRules).map((rule, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <RuleItem key={index} isActive={!!editRules[rule]}>
          {videoEditRulesTiles[rule].elementName}
        </RuleItem>
      ))}
    </SORsContainer>
  );
}
