import { Fragment } from "react";
import styled, { css } from "styled-components/macro";

import { ifNotProp, ifProp, themeProp } from "src/utils/styledComponents.utils";
import { AspectRatio, getAspectRatioValue } from "src/constants/video.constants";

import { Button } from "src/components/common/buttons/Button.styled";
import { Stack } from "src/components/common/layout/Stack.styled";

const AspectRatioOptions = styled(Stack)<{ isActive: boolean }>`
  align-items: center;
  position: absolute;
  top: 100%;
  opacity: ${ifProp("isActive", 1, 0)};
  translate: 0 ${ifProp("isActive", 0, "-60px")};
  transition: translate 550ms 0ms, opacity 400ms 150ms;
  transition-timing-function: ease-out;
`;

interface AspectRatioOptionProps {
  isSelected: boolean;
}

const AspectRatioOption = styled(Button)<AspectRatioOptionProps>`
  padding: 6px 8px;
  transition: background-color, color;
  transition-duration: 250ms;
  transition-timing-function: ease-in;

  ${ifNotProp(
    "isSelected",
    css<AspectRatioOptionProps>`
      background-color: transparent;
      color: ${themeProp("colors.gray.500")};
    `,
  )}
`;

const AspectRatioOptionSeparator = styled.div`
  width: 1px;
  height: 100%;
  padding-block: 10px;
  background-color: ${themeProp("colors.gray.500")};

  &:last-child {
    display: none;
  }
`;

const ASPECT_RATIOS: AspectRatio[] = ["16:9", "1:1", "9:16"];

interface AspectRatioSelectProps {
  onChange(value: AspectRatio): void;
  selectedAspectRatio: AspectRatio;
  isActive: boolean;
}

export default function AspectRatioSelect({ onChange, selectedAspectRatio, isActive }: AspectRatioSelectProps) {
  return (
    <AspectRatioOptions isActive={isActive} direction="row" spacing={20} marginTop={20}>
      {ASPECT_RATIOS.map((currentAspectRatio, aspectRatioIndex) => (
        <Fragment key={currentAspectRatio.toString()}>
          <AspectRatioOption
            variant="primary"
            size="large"
            isSelected={currentAspectRatio === selectedAspectRatio}
            onClick={() => onChange(currentAspectRatio)}
          >
            {getAspectRatioValue(currentAspectRatio)}
          </AspectRatioOption>

          {aspectRatioIndex < ASPECT_RATIOS.length - 1 && <AspectRatioOptionSeparator />}
        </Fragment>
      ))}
    </AspectRatioOptions>
  );
}
