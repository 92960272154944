import { RefObject, useCallback, useEffect, useRef } from "react";
import { useVideoCrop } from "src/components/features/VideoCropper/providers/VideoCropProvider/VideoCropContext";

import VideoToCanvasService from "src/services/VideoToCanvas.service";
import { CropDataDetail } from "src/utils/cropData.utils";
import styled from "styled-components/macro";

interface Props {
  width: number;
  height: number;
  videoRef: RefObject<HTMLVideoElement>;
  renderVideoLayer?: boolean;
}

const CanvasStyled = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export default function CropPlayer({ videoRef, width, height, renderVideoLayer = true }: Props) {
  const { crops, activeCropIndex, style, backgroundColor } = useVideoCrop();
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const video2CanvasRef = useRef<VideoToCanvasService | null>(null);

  const createPlayer = useCallback((video: HTMLVideoElement, canvas: HTMLCanvasElement) => {
    // set service
    // TODO: check if need to clean ups service
    video2CanvasRef.current = new VideoToCanvasService(video, canvas);
    video2CanvasRef.current.backgroundColor = backgroundColor;
    video2CanvasRef.current.blur = !!style.blur;
    video2CanvasRef.current.renderVideoLayer = renderVideoLayer;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!video2CanvasRef.current) return;
    video2CanvasRef.current.backgroundColor = backgroundColor;
    video2CanvasRef.current.blur = !!style.blur;
  }, [style.blur, backgroundColor]);

  useEffect(() => {
    if (!videoRef.current || !canvasRef.current) {
      /* eslint-disable no-console */
      console.error("CropPlayer videoRef.current or canvasRef.current is null or undefined");
      return;
    }
    createPlayer(videoRef.current, canvasRef.current);
  }, [videoRef, canvasRef, createPlayer]);

  useEffect(() => {
    if (!video2CanvasRef.current) {
      return;
    }

    video2CanvasRef.current.croppingData = crops[activeCropIndex] as CropDataDetail;
  }, [crops, activeCropIndex]);

  useEffect(() => {
    if (!video2CanvasRef.current) {
      return;
    }
    video2CanvasRef.current.render();
  }, [width, height]);

  return <CanvasStyled ref={canvasRef} width={width} height={height} />;
}
