import { memo, useEffect, useRef } from "react";
import { timeIntervalUtils } from "src/utils/timeInterval.utils";
import { formatSeconds } from "src/utils/time.utils";
import { TimeInterval } from "src/types/video-trimmer.types";

interface TickSetConfig {
  tickFrequencyInSeconds: number;
  tickSize: "small" | "large";
  drawTime: boolean;
  timeFormat?: string;
}

interface TicksConfig {
  default: TickSetConfig[];
  [pxPerSecBreakPoint: number]: TickSetConfig[];
}

const DEFAULT_TICKS_CONFIG: TicksConfig = {
  256: [
    { tickFrequencyInSeconds: 0.05, tickSize: "small", drawTime: false },
    { tickFrequencyInSeconds: 0.5, tickSize: "large", drawTime: false },
    { tickFrequencyInSeconds: 1, tickSize: "large", drawTime: true },
  ],

  128: [
    { tickFrequencyInSeconds: 1, tickSize: "large", drawTime: true },
    { tickFrequencyInSeconds: 0.5, tickSize: "large", drawTime: false },
    { tickFrequencyInSeconds: 0.1, tickSize: "small", drawTime: false },
  ],

  64: [
    { tickFrequencyInSeconds: 1, tickSize: "large", drawTime: true },
    { tickFrequencyInSeconds: 0.25, tickSize: "small", drawTime: false },
  ],

  32: [
    { tickFrequencyInSeconds: 5, tickSize: "large", drawTime: true },
    { tickFrequencyInSeconds: 1, tickSize: "small", drawTime: false },
  ],

  16: [
    { tickFrequencyInSeconds: 5, tickSize: "large", drawTime: true },
    { tickFrequencyInSeconds: 2.5, tickSize: "small", drawTime: false },
  ],

  8: [
    { tickFrequencyInSeconds: 10, tickSize: "large", drawTime: true },
    { tickFrequencyInSeconds: 5, tickSize: "small", drawTime: false },
  ],

  4: [
    { tickFrequencyInSeconds: 30, tickSize: "large", drawTime: true },
    { tickFrequencyInSeconds: 15, tickSize: "small", drawTime: false },
  ],

  2: [
    { tickFrequencyInSeconds: 60, tickSize: "large", drawTime: true },
    { tickFrequencyInSeconds: 30, tickSize: "small", drawTime: false },
  ],

  1: [
    { tickFrequencyInSeconds: 120, tickSize: "large", drawTime: true },
    { tickFrequencyInSeconds: 60, tickSize: "small", drawTime: false },
  ],

  0.5: [
    { tickFrequencyInSeconds: 240, tickSize: "large", drawTime: true },
    { tickFrequencyInSeconds: 120, tickSize: "small", drawTime: false },
  ],

  0.25: [
    { tickFrequencyInSeconds: 480, tickSize: "large", drawTime: true },
    { tickFrequencyInSeconds: 240, tickSize: "small", drawTime: false },
  ],

  default: [{ tickFrequencyInSeconds: 480, tickSize: "large", drawTime: true }],
};

interface Props {
  zoomedTimeInterval: TimeInterval;
  pxPerSec: number;
  width: number;
  ticksSectionHeight: number;
  config?: TicksConfig;
  tickWidth: number;
  tickColor: string;
  spacing: number;
  fontSize: number;
  fontColor: string;
  fontFamily: string;
}

function TimeAxisBase({
  zoomedTimeInterval,
  pxPerSec,
  width,
  ticksSectionHeight,
  config = DEFAULT_TICKS_CONFIG,
  tickWidth,
  tickColor,
  spacing,
  fontSize,
  fontColor,
  fontFamily,
}: Props) {
  const height = ticksSectionHeight + spacing * 3 + fontSize;
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const canvasContextRef = useRef<CanvasRenderingContext2D | null>(null);
  const offscreenCanvasRef = useRef<OffscreenCanvas | null>(null);
  const offscreenCanvasContextRef = useRef<OffscreenCanvasRenderingContext2D | null>(null);

  useEffect(
    () => {
      if (!canvasRef.current || !width) return;

      if (!canvasContextRef.current || !offscreenCanvasRef.current || !offscreenCanvasContextRef.current) {
        canvasContextRef.current = canvasRef.current.getContext("2d")!;
        offscreenCanvasRef.current = new OffscreenCanvas(width, height);
        offscreenCanvasContextRef.current = offscreenCanvasRef.current.getContext("2d")!;
      }

      offscreenCanvasRef.current.width = width;
      offscreenCanvasContextRef.current.font = `${fontSize}px ${fontFamily}`;

      const drawTickSet = ({ tickFrequencyInSeconds, tickSize, drawTime, timeFormat = "mm:ss" }: TickSetConfig) => {
        const offsetTimeInSeconds = -1 * (zoomedTimeInterval.start % tickFrequencyInSeconds);
        const offsetX = offsetTimeInSeconds * pxPerSec;

        const tickHeight = tickSize === "large" ? ticksSectionHeight : 0.5 * ticksSectionHeight;
        const tickCount = Math.ceil(
          (timeIntervalUtils.duration(zoomedTimeInterval) - offsetTimeInSeconds) / tickFrequencyInSeconds,
        );

        // eslint-disable-next-line no-plusplus
        for (let tickIndex = 0; tickIndex < tickCount; tickIndex++) {
          const tickX = pxPerSec * tickFrequencyInSeconds * tickIndex;

          offscreenCanvasContextRef.current!.fillStyle = tickColor;
          offscreenCanvasContextRef.current!.beginPath();
          offscreenCanvasContextRef.current!.roundRect(offsetX + tickX, 0, tickWidth, tickHeight, tickWidth);
          offscreenCanvasContextRef.current!.closePath();
          offscreenCanvasContextRef.current!.fill();

          if (drawTime) {
            const timeInSeconds = zoomedTimeInterval.start + offsetTimeInSeconds + tickFrequencyInSeconds * tickIndex;
            const formattedTime = formatSeconds(timeInSeconds, timeFormat);
            const timeLabelWidth = offscreenCanvasContextRef.current!.measureText(formattedTime).width;
            const timeLabelMaxX = width - timeLabelWidth;
            const timeLabelX = Math.min(timeLabelMaxX, offsetX + tickX - timeLabelWidth / 2 + 2);

            offscreenCanvasContextRef.current!.fillStyle = fontColor;
            offscreenCanvasContextRef.current!.fillText(formattedTime, timeLabelX, height);
          }
        }
      };

      const { default: defaultTickSet, ...restOfConfig } = config;

      const currentBreakPoint = Object.keys(restOfConfig)
        .sort((breakPoint1, breakPoint2) => Number(breakPoint2) - Number(breakPoint1))
        .find((breakPoint) => pxPerSec > Number(breakPoint));

      const currentBreakPointTickSets = currentBreakPoint ? restOfConfig[Number(currentBreakPoint)] : defaultTickSet;

      currentBreakPointTickSets.forEach(drawTickSet);

      canvasContextRef.current.clearRect(0, 0, width, height);
      canvasContextRef.current.drawImage(offscreenCanvasRef.current, 0, 0);
      offscreenCanvasContextRef.current.clearRect(0, 0, width, height);
    },
    // prettier-ignore
    [config, fontColor, fontFamily, fontSize, height, pxPerSec, spacing, tickColor, tickWidth, ticksSectionHeight, width, zoomedTimeInterval],
  );

  return <canvas ref={canvasRef} width={width} height={height} />;
}

const TimeAxis = memo(TimeAxisBase);

export default TimeAxis;
