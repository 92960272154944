import styled from "styled-components/macro";
import Slider from "rc-slider";
import { themeColor } from "src/utils/styledComponents.utils";

export const StyledSlider = styled(Slider)`
  .rc-slider-rail {
    height: 7px;
    background-color: ${themeColor("gray.50")};
    border-radius: 5px;
  }

  .rc-slider-track {
    background-color: ${themeColor("blue.500")};
    border-radius: 5px;
    height: 7px;
  }

  .rc-slider-handle {
    display: ${(props) => (props.disabled ? "none" : "block")};
    z-index: 1;
    width: 24px;
    height: 24px;
    margin-top: -9px;
    border-radius: 50%;
    background-color: ${themeColor("white")};
    box-shadow: 0px 0.5px 4px rgba(0, 0, 0, 0.12), 0px 6px 13px rgba(0, 0, 0, 0.12);
    border: none;
    opacity: 1;

    &:focus {
      outline: none !important;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12), 0 6px 13px rgba(0, 0, 0, 0.12) !important;
    }
    &:focus::-moz-focus-inner {
      border: 0;
    }
  }

  .rc-slider-tooltip {
    min-width: 30px;
    min-height: 20px;
    width: fit-content;
    background-color: transparent;
  }

  .rc-slider-tooltip-inner {
    color: ${themeColor("blue.300")};
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.12), 0px 6px 13px rgba(0, 0, 0, 0.12);
    background-color: ${themeColor("gray.300")};
  }

  .rc-slider-tooltip-arrow {
    border-top-color: ${themeColor("white")} !important;
  }

  &.rc-slider-disabled {
    background-color: transparent !important;
  }
`;
