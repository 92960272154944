import { HTMLProps, useCallback } from "react";

import styled from "styled-components/macro";

import { ifProp, themeColor } from "src/utils/styledComponents.utils";

import { Button } from "src/components/common/buttons/Button.styled";
import { useSwiperContext } from "src/components/common/Swiper/Swiper";

export const PrevSlideButton = styled(Button)`
  width: 119px;
  height: 45px;
  padding: unset;
  border: unset;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 12px;
  color: #132e59;

  svg {
    fill: #132e59;
    visibility: ${ifProp("disabled", "hidden", "visible")};
    transition: visibility 250ms ease-out, fill 250ms ease-out;
  }

  &:hover {
    svg {
      fill: ${themeColor("blue.300")};
    }
  }
`;

export interface SwiperPrevSlideButtonProps
  extends Omit<HTMLProps<HTMLButtonElement>, "style" | "className" | "children" | "disabled" | "onClick"> {
  children: any;
  onClick?(slideNext: Function): void;
}

export default function SwiperPrevSlideButton({ children, onClick, ...props }: SwiperPrevSlideButtonProps) {
  const { allowSlidePrev, slidePrev } = useSwiperContext();
  const onButtonClick = useCallback(() => (onClick ? onClick(slidePrev) : slidePrev()), [onClick, slidePrev]);

  return (
    <PrevSlideButton onClick={onButtonClick} disabled={!allowSlidePrev} variant="link" {...props}>
      {children}
    </PrevSlideButton>
  );
}
