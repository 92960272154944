/* eslint-disable react/no-unescaped-entities */

import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Dispatch, RootState } from "src/models/store";

import { Text1Bold, H2, H6 } from "src/components/common/layout/typography.styled";
import { Field } from "src/components/common/form/Field.styled";
import { Box } from "src/components/common/layout/Box.styled";
import { Input, FieldError, NetworkError, PasswordInput } from "src/components/common/form/smart-form-components";
import { Container, ContentContainer, FormFieldStack, SubmitButton } from "src/components/auth/common/layout.styled";
import * as commonAuthFieldDefinitions from "src/components/auth/common/fieldDefinitions";

const signUpFormSchema = yup.object({
  name: yup.string().required("please enter your full name"),
  password: commonAuthFieldDefinitions.password(),
  passwordVerification: commonAuthFieldDefinitions.passwordVerification(),
});

type SignUpForm = yup.InferType<typeof signUpFormSchema>;

interface SignUpCompletionProps {
  token: string | undefined;
  email: string | undefined;
}

export default function SignUpCompletion({ token, email }: SignUpCompletionProps) {
  const dispatch = useDispatch<Dispatch>();
  const networkError = useSelector((state: RootState) => state.loading.effects.users.signUpUser.error as Error);
  const signUpForm = useForm<SignUpForm>({ resolver: yupResolver(signUpFormSchema) });
  const { handleSubmit } = signUpForm;

  const onSubmit = useCallback(
    ({ name, password }: SignUpForm) => {
      if (token && email) {
        dispatch.users.signUpUser({ name, email, password, token });
      }
    },
    [dispatch, email, token],
  );

  return (
    <Container>
      <ContentContainer>
        <H2>We're almost there</H2>
        <H6>Confirm your password to help us secure your privacy.</H6>

        <Box marginTop={10}>
          <Text1Bold>{email}</Text1Bold>
        </Box>

        <FormProvider {...signUpForm}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormFieldStack>
              <Field>
                <Input<SignUpForm> name="name" placeholder="Full Name" inputSize="medium" />
                <FieldError<SignUpForm> name="name" />
              </Field>

              <Field>
                <PasswordInput<SignUpForm>
                  name="password"
                  placeholder="Password"
                  data-cy="new-sign-up-password-input"
                  inputSize="medium"
                />
                <FieldError<SignUpForm> name="password" />
              </Field>

              <Field>
                <PasswordInput<SignUpForm>
                  name="passwordVerification"
                  placeholder="Verify Password"
                  data-cy="login-password-verify-input"
                  inputSize="medium"
                />
                {!signUpForm.formState.errors.password && <FieldError<SignUpForm> name="passwordVerification" />}
              </Field>
            </FormFieldStack>

            <SubmitButton data-cy="configure-submit">Next</SubmitButton>
            <NetworkError error={networkError} />
          </form>
        </FormProvider>
      </ContentContainer>
    </Container>
  );
}
