import { appRouter } from "src/components/app/AppRouter/AppRouter";

export const getUrlAuthToken = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const encodedAuthToken = searchParams.get("authToken");

  if (encodedAuthToken) {
    searchParams.delete("authToken");
    appRouter.navigate({ pathname: window.location.pathname, search: searchParams.toString() }, { replace: true });

    return decodeURIComponent(encodedAuthToken);
  }

  return undefined;
};
