import { EntityId } from "@reduxjs/toolkit";
import { formatDistance } from "date-fns";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { footageSelectors } from "src/models/Footage.model";
import { RootState } from "src/models/store";
import { Clip } from "src/network/graphql/generatedGraphqlSDK";

import { themeColor, themeZ } from "src/utils/styledComponents.utils";

import styled from "styled-components/macro";

import Icon from "src/components/common/Icon";
import SequenceItem from "src/components/features/SmartLibrary/oneFootage/SequenceItem";
import useResizeObserver from "use-resize-observer";

type ScrollBtnPlace = "left" | "right";

const Container = styled.div`
  position: relative;
  height: fit-content;
  width: 100vw;
  background-color: ${themeColor("white")};

  opacity: 0;
  animation: appear 0.3s ease 0.3s forwards;

  @keyframes appear {
    to {
      opacity: 1;
    }
  }
`;

const ScrollableContainer = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  min-height: 260px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: ${themeColor("white")};
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`;

const BluredDiv = styled.div<{ place: ScrollBtnPlace }>`
  position: absolute;
  top: 0;
  right: ${(props) => (props.place === "left" ? "unset" : "0")};
  left: ${(props) => (props.place === "left" ? "0" : "unset")};
  height: 100%;
  width: 150px;
  z-index: ${themeZ("modal")};
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);

  @media (max-width: 425px) {
    width: 40px;
  }

  transform: ${(props) => (props.place === "left" ? "rotate(180deg)" : "rotate(0deg)")};
`;

const ScrollBtn = styled.div<{ place: ScrollBtnPlace }>`
  position: absolute;
  top: calc(50% - 19px);
  right: ${(props) => (props.place === "left" ? "unset" : "30px")};
  left: ${(props) => (props.place === "left" ? "30px" : "unset")};
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: ${themeColor("gray.500")};
  z-index: ${themeZ("modal")};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${(props) => (props.place === "left" ? "rotate(180deg)" : "rotate(0deg)")};
  svg {
    height: 16px;
    color: ${themeColor("white")};
  }

  @media (max-width: 425px) {
    top: calc(50% - 15px);
    width: 30px;
    height: 30px;
    right: ${(props) => (props.place === "left" ? "unset" : "14px")};
    left: ${(props) => (props.place === "left" ? "14px" : "unset")};
    svg {
      height: 10px;
    }
  }

  opacity: 0;
  animation: appear 0.3s ease 0.3s forwards;

  @keyframes appear {
    to {
      opacity: 1;
    }
  }
`;

const Header = styled.div`
  padding-top: 24px;
  padding-left: 30px;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
`;

const RecentlyAddedItemsWrapper = styled.div`
  height: fit-content;
  width: fit-content;
  padding: 14px 0 14px 30px;
  display: flex;
`;

const SequenceItemWrapper = styled.div`
  width: 212px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
`;

const RecentInfoFooter = styled.div`
  width: 100%;
  height: 20px;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: ${themeColor("gray.400")};
`;

export interface RecentlyEditedSectionProps {
  footageId: string;
  recentlyEditedSequences: {
    [key: string]: any;
  }[];
}

export default function RecentlyEditedSection({ footageId, recentlyEditedSequences }: RecentlyEditedSectionProps) {
  const footage = useSelector((state: RootState) => footageId && footageSelectors.selectById(state, footageId as EntityId)); // prettier-ignore
  const scrollableRef = useRef<HTMLDivElement>(null);
  const [shouldShowBtn, setShouldShowBtn] = useState({ left: false, right: false });
  const { width } = useResizeObserver({ ref: scrollableRef });

  const determineScrollButtonsVisibility = useCallback(() => {
    if (scrollableRef.current) {
      const container = scrollableRef.current;
      const maxScrollLeft = container.scrollWidth - container.clientWidth;
      const isScrolledToLeft = container.scrollLeft === 0;
      const isScrolledToRight = container.scrollLeft === maxScrollLeft;

      if (maxScrollLeft <= 0) {
        return { left: false, right: false };
      }
      if (isScrolledToLeft) {
        return { left: false, right: true };
      }
      if (isScrolledToRight) {
        return { left: true, right: false };
      }
      return { left: true, right: true };
    }
    return { left: false, right: false };
  }, []);

  const getClip = useCallback(
    (seqId: string) => {
      if (!footage) return null;

      return footage.clips?.find((clip) => clip.sequences?.some((seq) => seq.id === seqId));
    },
    [footage],
  );

  const handleScrollRight = useCallback(() => {
    if (scrollableRef.current) {
      const container = scrollableRef.current;
      container.scrollLeft += window.innerWidth / 2;
    }
  }, []);

  const handleScrollLeft = useCallback(() => {
    if (scrollableRef.current) {
      const container = scrollableRef.current;
      container.scrollLeft -= window.innerWidth / 2;
    }
  }, []);

  const fromNowDistance = useMemo(
    () => (editedAt: string) => {
      const dateObject = new Date(editedAt);
      return formatDistance(dateObject, new Date(), { addSuffix: true });
    },
    [],
  );

  useEffect(() => {
    const container = scrollableRef.current;
    setShouldShowBtn(determineScrollButtonsVisibility());
    const handleScroll = () => {
      setShouldShowBtn(determineScrollButtonsVisibility());
    };

    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollableRef.current]);

  useEffect(() => {
    setShouldShowBtn(determineScrollButtonsVisibility());
  }, [determineScrollButtonsVisibility, recentlyEditedSequences.length, width]);

  if (!recentlyEditedSequences?.length) return null;

  if (Array.isArray(recentlyEditedSequences)) {
    return (
      <Container>
        {shouldShowBtn.left && (
          <>
            <BluredDiv place="left" />
            <ScrollBtn onClick={handleScrollLeft} place="left">
              <Icon.ArrowRight />
            </ScrollBtn>
          </>
        )}
        {shouldShowBtn.right && (
          <>
            <BluredDiv place="right" />
            <ScrollBtn onClick={handleScrollRight} place="right">
              <Icon.ArrowRight />
            </ScrollBtn>
          </>
        )}

        <Header>Recently edited videos</Header>
        <ScrollableContainer ref={scrollableRef}>
          <RecentlyAddedItemsWrapper>
            {recentlyEditedSequences.map((seq) => (
              <SequenceItemWrapper key={seq.sid ?? seq.title + seq.aspectRatio}>
                <SequenceItem
                  footageId={footageId}
                  clip={getClip(seq.id) as Clip}
                  sid={seq.sid}
                  title={seq.title}
                  status={seq.status}
                  ratio={seq.ratio}
                  languageCode={seq.languageCode}
                  isFavorite={seq.favorite}
                  type={seq.type}
                  downloadedAt={seq.downloadedAt}
                  openTrigger="recently-edited"
                />
                <RecentInfoFooter>Last edited {fromNowDistance(seq.editedAt)}</RecentInfoFooter>
              </SequenceItemWrapper>
            ))}
          </RecentlyAddedItemsWrapper>
        </ScrollableContainer>
      </Container>
    );
  }
  return null;
}
