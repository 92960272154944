import { SupportedRatio, calculateAspectRatio } from "./math.utils";

export const getAdaptiveChunkSize = (fileSizeBytes: number) => {
  const bytesInMB = 1048576; // 1024 * 1024
  const minChunkSizeMB = 5;
  const maxChunkSizeMB = 32;
  const thresholdFileSizeMB = 1024; // 1 GB

  const fileSizeMB = fileSizeBytes / bytesInMB;

  // Default chunk size is the minimum chunk size
  let chunkSizeMB = minChunkSizeMB;

  if (fileSizeMB >= thresholdFileSizeMB) {
    // If file size is greater than or equal to 1 GB, use the maximum chunk size
    chunkSizeMB = maxChunkSizeMB;
  } else {
    // As an example, I'm linearly increasing the chunkSizeMB from 5 to 32 MB
    // as the fileSizeMB goes from 0 to 1024 MB.
    chunkSizeMB = minChunkSizeMB + (maxChunkSizeMB - minChunkSizeMB) * (fileSizeMB / thresholdFileSizeMB);

    // Round the chunk size to the nearest integer value
    chunkSizeMB = Math.round(chunkSizeMB);
  }

  // Convert back to bytes and return the chunk size
  return chunkSizeMB * bytesInMB;
};

export interface VideoMetaData {
  videoWidth?: number;
  videoHeight?: number;
  duration?: number;
  ratio?: SupportedRatio;
  fileType: string;
}

export const getVideoFileInfo = async (file: File) =>
  new Promise<VideoMetaData | null>((resolve) => {
    const video = document.createElement("video");
    const fileURL = URL.createObjectURL(file);
    function onLoadedmetadata() {
      const { videoWidth, videoHeight, duration } = video;
      const ratio = calculateAspectRatio(videoWidth, videoHeight);

      URL.revokeObjectURL(fileURL); // release memory
      resolve({ videoWidth, videoHeight, duration, ratio, fileType: file.type });
    }

    function onError() {
      URL.revokeObjectURL(fileURL); // release memory
      resolve({ fileType: file.type });
    }

    video.addEventListener("loadedmetadata", onLoadedmetadata);
    video.addEventListener("error", onError);
    video.src = fileURL;
  });
