import React, { useState } from "react";
import styled from "styled-components/macro";
import Icon from "src/components/common/Icon";
import { ifProp, themeColor, themeProp } from "src/utils/styledComponents.utils";

const StyledArrowDown = styled(Icon.ArrowDown)<{ $isOpen: boolean }>`
  transform: rotate(${ifProp("$isOpen", "180deg", "0deg")});
  transition: transform 0.2s ease-out;
  height: 100%;
`;
// Styled components for the Split button dropdown
const SplitButtonContainer = styled.div`
  display: flex;
  position: relative;
  align-content: center;
`;

const Button = styled.button`
  align-items: center;
  width: max-content;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  background-color: transparent;
  border: none;
  color: ${themeColor("blue.500")};
  height: 100%;
  transition-property: background-color, color, border-color;
  transition-duration: 250ms;
  transition-timing-function: ease-out;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  color: ${themeColor("blue.500")};
  border-radius: ${themeProp("borderRadii.medium")}px;
  &:first-child {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  &:last-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  &:not(:disabled) {
    &:hover {
      border-color: ${themeColor("blue.300")};
      background-color: ${themeColor("blue.300")};
    }

    &:active {
      background-color: ${themeColor("blue.400")};
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: ${themeProp("borderRadii.medium")}px;

  border: 1px solid ${themeColor("blue.500")};

  &:disabled {
    border-color: ${themeColor("gray.300")};
    color: ${themeColor("gray.300")};
    cursor: not-allowed;
  }
`;

const DropdownMenu = styled.div<{ isOpen: boolean }>`
  display: ${ifProp("isOpen", "block", "none")};
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
  border-radius: 5px;
`;

const DropdownItem = styled.a`
  display: block;
  padding: 8px 15px;
  color: ${themeColor("blue.500")};
  font-size: 12px;
  text-decoration: none;
  cursor: pointer;
  color: ${themeColor("gray.500")};
  border-radius: 5px;

  &:hover {
    color: ${themeColor("blue.500")};
    background-color: #f8f9fa;
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${themeColor("blue.500")};
`;

interface SplitButtonDropdownProps {
  label: string;
  options: string[];
  onOptionClick?: () => void;
  onOptionMouseOver?: () => void;
  onOptionMouseOut?: () => void;
  onClick?: () => void;
}

// Split button dropdown component
function DropdownButton({
  label,
  options,
  onOptionClick,
  onClick,
  onOptionMouseOver,
  onOptionMouseOut,
}: SplitButtonDropdownProps) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = () => {
    onOptionClick && onOptionClick();
    setIsOpen(false);
  };

  const handleButtonClick = () => {
    onClick && onClick();
    setIsOpen(false);
  };

  return (
    <SplitButtonContainer onMouseLeave={() => setIsOpen(false)}>
      <ButtonContainer>
        <Button onClick={() => handleButtonClick()}>{label}</Button>
        <Divider />
        <Button
          onClick={() => {
            toggleDropdown();
            if (document.activeElement) {
              (document.activeElement as HTMLElement).blur();
            }
          }}
        >
          <StyledArrowDown size={12} $isOpen={isOpen} />
        </Button>
      </ButtonContainer>

      <DropdownMenu
        onMouseOver={onOptionMouseOver}
        onMouseOut={() => {
          onOptionMouseOut && onOptionMouseOut();
        }}
        isOpen={isOpen}
      >
        {options.map((option) => (
          <DropdownItem key={option} onClick={() => handleOptionClick()}>
            {option}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </SplitButtonContainer>
  );
}

export default DropdownButton;
