import { Models } from "@rematch/core";

import downloadInfo from "src/models/DownloadInfo.model";
import words from "src/models/Words.model";
import session from "./Session.model";
import users from "./User.model";
import plans from "./Plan.model";
import assets from "./Asset.model";
import folderStructures from "./FolderStructure.model";
import footage from "./Footage.model";
import presets from "./Preset.model";
import sequences from "./Sequence.model";
import sequenceAudioSamples from "./SequenceAudioSamples.model";
import sequenceCaptions from "./SequenceCaptions.model";
import speakerCuePoints from "./SpeakerQuePoint.model";
import youtubeSearchResults from "./YoutubeSearchResult.model";
import crop from "./Crop.model";
import boards from "./Board.model";

export interface RootModel extends Models<RootModel> {
  session: typeof session;
  users: typeof users;
  plans: typeof plans;
  assets: typeof assets;
  folderStructures: typeof folderStructures;
  presets: typeof presets;
  sequences: typeof sequences;
  sequenceCaptions: typeof sequenceCaptions;
  sequenceAudioSamples: typeof sequenceAudioSamples;
  footage: typeof footage;
  speakerCuePoints: typeof speakerCuePoints;
  youtubeSearchResults: typeof youtubeSearchResults;
  downloadInfo: typeof downloadInfo;
  words: typeof words;
  crop: typeof crop;
  boards: typeof boards;
}

export const models: RootModel = {
  session,
  users,
  plans,
  assets,
  folderStructures,
  presets,
  sequences,
  sequenceCaptions,
  sequenceAudioSamples,
  footage,
  speakerCuePoints,
  youtubeSearchResults,
  downloadInfo,
  words,
  crop,
  boards,
};
