import { forwardRef, useState } from "react";
import styled, { css } from "styled-components/macro";

import Icon from "src/components/common/Icon";
import Input, { InputProps } from "src/components/common/form/inputs/Input.styled";
import { ifProp } from "src/utils/styledComponents.utils";

const Container = styled.div`
  position: relative;
`;

const StyledInput = styled(Input)`
  padding-right: 36px;
`;

const TogglePasswordVisible = styled(Icon.Eye)<{ $crossed: boolean }>`
  position: absolute;
  top: 0;
  right: 8px;
  bottom: 0;
  width: 20px;
  height: 20px;
  margin-block: auto;
  cursor: pointer;

  .cross {
    transform-origin: center;
    transition: stroke-dashoffset 250ms ease-out;
    stroke-dasharray: 24px 24px;
    stroke-dashoffset: 24px;
  }

  ${ifProp(
    "$crossed",
    css`
      .cross {
        stroke-dashoffset: 0;
      }
    `,
  )}
`;

const PasswordInput = forwardRef<HTMLInputElement, Omit<JSX.IntrinsicElements["input"], "type" | "ref"> & InputProps>(
  (props, ref) => {
    const [isVisible, setIsVisible] = useState(false);

    return (
      <Container>
        <StyledInput ref={ref} {...props} type={isVisible ? "text" : "password"} />
        <TogglePasswordVisible onClick={() => setIsVisible((value) => !value)} color="gray.500" $crossed={!isVisible} />
      </Container>
    );
  },
);

export default PasswordInput;
