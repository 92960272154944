import { createModel } from "@rematch/core";
import { createEntityAdapter } from "@reduxjs/toolkit";

import { YoutubeItem } from "src/types/youtube-api.types";

import apiClient from "src/network/ApiClient";

import store, { RootState } from "src/models/store";
import { RootModel } from "src/models/index";
import { selectId } from "src/models/selectId";

const youtubeSearchResultAdapter = createEntityAdapter<YoutubeItem>({ selectId });

export const youtubeSearchResultSelectors = youtubeSearchResultAdapter.getSelectors(
  (state: RootState) => state.youtubeSearchResults,
);

const youtubeSearchResults = createModel<RootModel>()({
  state: youtubeSearchResultAdapter.getInitialState(),

  reducers: {
    setYoutubeSearchResult: (state, payload: YoutubeItem) => youtubeSearchResultAdapter.setOne(state, payload),
  },

  effects: (dispatch) => ({
    async getYoutubeVideo(videoId: string) {
      // TODO try avoid as YoutubeItem
      const itemFromState: YoutubeItem = youtubeSearchResultSelectors.selectById(
        store.getState(),
        videoId,
      ) as YoutubeItem;
      if (itemFromState) {
        return itemFromState;
      }
      const res = await apiClient.getYoutubeVideo(videoId);
      const firstResult = res.items[0];

      if (!firstResult) {
        return null;
      }

      dispatch.youtubeSearchResults.setYoutubeSearchResult(firstResult);

      return firstResult;
    },
  }),
});

export default youtubeSearchResults;
