import { useCallback, useEffect } from "react";

import styled from "styled-components/macro";

import { AspectRatio } from "src/constants/video.constants";

import { useSwiperInnerScrolling } from "src/components/common/Swiper/hooks/useSwiperInnerScrolling";
import {
  GridScroll2,
  HeaderText,
  StepContainer,
  SubHeaderText,
} from "src/components/features/CreateSequenceWizard/common/create-sequence-wizard-layout.styled";
import AspectRatioExample from "src/components/features/CreateSequenceWizard/steps/ChooseRatioStep/AspectRatioExample";
import { FormContextInterface } from "src/components/features/AutomaticCreateWizard/FormContext.interface";

const RatioBlock = styled.div`
  width: fit-content;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  flex: 1;

  @media (min-width: 940px) {
    flex-direction: row;
    padding: 20px;
  }
`;

const ASPECT_RATIOS: AspectRatio[] = ["16:9", "1:1", "9:16"];

type UrlMapping = {
  [key in AspectRatio]: string | null;
};

interface ChooseRatioStepProps extends FormContextInterface {
  isMultiSelect?: boolean;
  initialAspectRatio?: AspectRatio | string;
  thumbnailsUrls: UrlMapping;
  headerTitle?: string;
}

export default function ChooseRatioStep({
  formContext,
  isMultiSelect = false,
  initialAspectRatio = "16:9",
  thumbnailsUrls,
  headerTitle = "Select video aspect ratio",
}: ChooseRatioStepProps) {
  const { watch, setValue } = formContext;
  const selectedAspectRatio: AspectRatio | AspectRatio[] = watch("size.selectedAspectRatio");
  const selectedByUser = watch("size.selectedByUser");

  const setFormValue = useCallback(
    (value: AspectRatio | AspectRatio[]) => {
      setValue("size.selectedAspectRatio", value, { shouldValidate: true });
      setValue("size.selectedByUser", true, { shouldValidate: true });
    },
    [setValue],
  );

  const onAspectRatioSelected = useCallback(
    (aspectRatio: AspectRatio) => {
      if (!isMultiSelect) {
        setFormValue(aspectRatio);
      } else if (Array.isArray(selectedAspectRatio) && selectedAspectRatio?.includes(aspectRatio)) {
        const arrayToSet = selectedAspectRatio.filter((item) => item !== aspectRatio);
        setFormValue(arrayToSet);
      } else {
        const arrayToSet = [...selectedAspectRatio, aspectRatio];
        setFormValue(arrayToSet as AspectRatio[]);
      }
    },
    [isMultiSelect, selectedAspectRatio, setFormValue],
  );

  const isAspectRatioSelected = useCallback(
    (aspectRatio: AspectRatio) => {
      if (isMultiSelect) {
        return selectedAspectRatio?.includes(aspectRatio);
      }
      return selectedAspectRatio === aspectRatio;
    },
    [isMultiSelect, selectedAspectRatio],
  );

  useEffect(() => {
    if (!isMultiSelect && !selectedByUser) {
      setFormValue(initialAspectRatio as AspectRatio);
    } else if (isMultiSelect && !selectedAspectRatio) {
      setFormValue([]);
    }
  }, [setValue, initialAspectRatio, selectedByUser, isMultiSelect, selectedAspectRatio, setFormValue]);

  return (
    <StepContainer>
      <GridScroll2 {...useSwiperInnerScrolling()}>
        <HeaderText>{headerTitle}</HeaderText>
        <SubHeaderText>
          Peech will automatically resize and crop your video based on the speakers&apos; position{" "}
        </SubHeaderText>
        <RatioBlock>
          {ASPECT_RATIOS.map((item) => (
            <AspectRatioExample
              key={item}
              aspectRatio={item}
              onRatioSelected={onAspectRatioSelected}
              isRatioSelected={isAspectRatioSelected}
              thumbnailUrl={thumbnailsUrls[item]}
            />
          ))}
        </RatioBlock>
      </GridScroll2>
    </StepContainer>
  );
}
