import Icon from "src/components/common/Icon";

interface SelectOption {
  value: string;
  label: string;
  status?: "ACTIVE" | "INACTIVE";
  icon: JSX.Element;
}

export const socialMediaOptions: SelectOption[] = [
  { value: "instagram", label: "Instagram", icon: <Icon.InstagramSmall /> },
  { value: "facebook", label: "Facebook", icon: <Icon.FacebookSmall /> },
  { value: "vimeo", label: "Vimeo", icon: <Icon.VimeoSmall /> },
  { value: "youtube", label: "YouTube", icon: <Icon.YoutubeSmall /> },
  { value: "linkedin", label: "LinkedIn", icon: <Icon.LinkedinSmall /> },
  { value: "pinterest", label: "Pinterest", icon: <Icon.PinterestSmall /> },
  { value: "tiktok", label: "TikTok", icon: <Icon.TiktokSmall /> },
  { value: "shorts", label: "Shorts", icon: <Icon.ShortsSmall /> },
];

export const aspectRatioOptions: SelectOption[] = [
  { value: "16:9", label: "16:9 Landscape", icon: <Icon.Horizontal /> },
  { value: "1:1", label: "1:1 Square", icon: <Icon.Square /> },
  { value: "9:16", label: "9:16 Portrait", icon: <Icon.Vertical /> },
];

export const scoringOptions: SelectOption[] = [
  { value: "80-90", label: "80-90", icon: <Icon.ScoringMin /> },
  { value: "90-95", label: "90-95", icon: <Icon.ScoringMiddle /> },
  { value: "95-100", label: "95-100", icon: <Icon.ScoringFull /> },
];
