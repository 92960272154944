import styled from "styled-components/macro";

import { styleProp, themeColor, themeProp } from "src/utils/styledComponents.utils";
import { Text3 } from "src/components/common/layout/typography.styled";
import Icon from "src/components/common/Icon";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 114px;
  cursor: pointer;
`;

export const ColorSquare = styled.div`
  width: 100px;
  height: 70px;
  border: 1px solid ${themeProp("colors.gray.300")};
  border-radius: 8px;
  background-color: ${styleProp("color")};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${themeColor("white")};
  svg {
    opacity: 50%;
    transition: opacity 250ms ease;
    filter: drop-shadow(0 0 0.5px ${themeProp("colors.gray.900")});
  }

  &:hover {
    svg {
      opacity: 100%;
    }
  }
`;

const ColorLabel = styled(Text3)`
  text-transform: uppercase;
`;

interface ColorStackItemProps {
  color: string;
  className?: string;
}

export default function ColorStackItem({ color, className }: ColorStackItemProps) {
  return (
    <Container className={className}>
      <ColorSquare color={color}>
        <Icon.Edit size="small" />
      </ColorSquare>
      <ColorLabel marginTop={8}>#{color.slice(1)}</ColorLabel>
    </Container>
  );
}
