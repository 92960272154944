import { useEffect, useRef, useState } from "react";

const useOverflowCheck = () => {
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!ref.current) return;

    const isElementOverflowing = () => ref.current!.clientWidth < ref.current!.scrollWidth;

    const handleResize = () => {
      setIsOverflowing(isElementOverflowing() ?? false);
    };

    handleResize();

    const resizeObserver = new ResizeObserver(handleResize);

    const currentRef = ref.current;
    if (currentRef) {
      resizeObserver.observe(currentRef);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      if (currentRef) {
        resizeObserver.unobserve(currentRef);
      }
    };
  }, []);

  return { ref, isOverflowing };
};

export default useOverflowCheck;
