import { createModel } from "@rematch/core";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";

import { appRouter } from "src/components/app/AppRouter/AppRouter";

import * as authAnalytics from "src/analytics/auth.analytics";

import { RootState } from "src/models/store";
import { RootModel } from "src/models/index";
import { selectId } from "src/models/selectId";

import apiClient from "src/network/ApiClient";
import socketClient from "src/network/SocketClient";
import * as gql from "src/network/graphql/generatedGraphqlSDK";

import analyticsService from "src/services/Analytics.service";
import errorMonitoringService from "src/services/ErrorMonitoring.service";
import userEngagementService from "src/services/UserEngagement.service";
import appMonitoringService from "src/services/AppMonitoring.service";
import { absoluteRoutes } from "src/utils/routes.utils";

const userAdapter = createEntityAdapter<gql.User>({ selectId });

const userAdapterSelectors = userAdapter.getSelectors((state: RootState) => state.users);

const selectCurrentUser = createSelector(
  (state: RootState) => state.users.entities,
  (state: RootState) => state.session.userId,
  (users, userId) => users[userId],
);

export const userSelectors = {
  ...userAdapterSelectors,
  selectCurrentUser,
};

const users = createModel<RootModel>()({
  state: userAdapter.getInitialState(),

  reducers: {
    setUser: (state, user: gql.User) => userAdapter.setOne(state, user),
    reset: () => userAdapter.getInitialState(),
  },

  effects: (dispatch) => ({
    logout() {
      dispatch.resetApp();
      authAnalytics.trackLogout();
    },

    async fetchCurrentUser() {
      try {
        const currentUser = await apiClient.getUser();

        dispatch.users.setUser(currentUser);
        dispatch.session.setUserId(currentUser.sid!);
        errorMonitoringService.identify(currentUser);
        userEngagementService.identify(currentUser);
        appMonitoringService.identify(currentUser);
        analyticsService.identify(currentUser);

        socketClient.emit("registerClient", {
          userSid: currentUser.sid!,
          orgSid: currentUser?.orgSid,
        });

        socketClient.on("connect", () => {
          // eslint-disable-next-line no-console
          // console.log("WS message 'registerClient' will be sent - onconnect", { currentUser });
          socketClient.emit("registerClient", {
            userSid: currentUser.sid!,
            orgSid: currentUser?.orgSid,
          });
        });
      } catch (error: any) {
        if (apiClient.isClientError(error) && error.response.data.user === null) {
          dispatch.users.reset();
          dispatch.session.reset();
        }
      }
    },

    async createUser(variables: gql.CreateUserMutationVariables) {
      await apiClient.createUser(variables);

      appRouter.navigate(absoluteRoutes.signup.children.emailVerification, {
        state: {
          email: variables.email,
        },
      });
    },

    async updateUser(variables: gql.UpdateUserMutationVariables) {
      const user = await apiClient.updateUser(variables);
      dispatch.users.setUser(user);
    },

    async loginUser(variables: gql.LoginUserQueryVariables) {
      localStorage.removeItem("planId");
      localStorage.removeItem("chargeType");
      const authToken = await apiClient.loginUser(variables);
      dispatch.session.loadAuthToken(authToken!);
    },

    async loginUserOAuth(variables: gql.LoginUserOAuthQueryVariables & { resetError?: boolean }) {
      if (variables.resetError) return; // this sets the effect's loading plugin's error state to false

      const authToken = await apiClient.loginUserOAuth(variables);
      dispatch.session.loadAuthToken(authToken!);

      const currentUser = await apiClient.getUser();
      dispatch.users.setUser(currentUser);
      dispatch.session.setUserId(currentUser.sid!);
    },

    async updateUserPassword(variables: gql.UpdateUserPasswordQueryVariables) {
      const authToken = await apiClient.updateUserPassword(variables);
      dispatch.session.loadAuthToken(authToken!);
      authAnalytics.trackSetPassword(variables.email);
    },

    async resetUserPassword(variables: gql.ResetUserPasswordMutationVariables) {
      await apiClient.resetUserPassword(variables);
      appRouter.navigate(absoluteRoutes.forgotPassword.children.emailVerification);
      authAnalytics.trackResetPassword(variables.email);
    },

    async signUpUser({ email, password, token, name }: gql.UpdateUserPasswordQueryVariables & { name: string }) {
      const authToken = await apiClient.updateUserPassword({ email, password, token });
      dispatch.session.loadAuthToken(authToken!);
      authAnalytics.trackSetPassword(email);

      const currentUser = await apiClient.getUser();
      dispatch.users.setUser(currentUser);
      dispatch.session.setUserId(currentUser.sid!);

      const [firstName, ...restNames] = name.split(" ");
      const lastName = restNames.join(" ");
      await dispatch.users.updateUser({ userSid: currentUser.sid!, firstName, lastName });
    },
  }),
});

export default users;
