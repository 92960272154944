import { useMemo, useState } from "react";
import styled, { css } from "styled-components/macro";

import { ifProp, themeColor, themeProp, themeZ } from "src/utils/styledComponents.utils";

import Icon from "src/components/common/Icon";
import MediaSource from "src/components/common/media/MediaSource";
import { Box } from "src/components/common/layout/Box.styled";
import Dropzone from "src/components/common/dropzones/Dropzone";
import { CircularLoader } from "src/components/common/loaders/CircularLoader";
import { CheckerboardBg } from "src/components/common/background/Checkerboard.styled";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 210px;
`;

const DropzoneContainer = styled.div`
  position: relative;
`;

interface DropzoneBoxProps {
  isDragging: boolean;
  isEmpty: boolean;
}

const DropZoneActiveStyle = css<DropzoneBoxProps>`
  border-color: ${themeColor("pink.500")};

  ${Icon.Upload} {
    .cloud {
      translate: 0 2px;
    }

    .arrow {
      translate: 0 -7px;

      path {
        fill: ${themeColor("pink.500")};
      }
    }
  }
`;

const DropzoneBox = styled(Box)<DropzoneBoxProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  border: 1px dashed transparent;
  border-radius: ${themeProp("borderRadii.large")}px;
  cursor: pointer;
  overflow: hidden;
  background-color: ${themeColor("blue.30")};

  ${ifProp(
    "isEmpty",
    css<DropzoneBoxProps>`
      border-color: ${themeColor("blue.500")};
      transition: border-color 150ms ease-out;

      ${Icon.Upload} {
        .cloud {
          transition: translate 150ms ease-out;
        }

        .arrow {
          transition: translate 150ms ease-out;

          path {
            transition: fill 150ms ease-out;
          }
        }
      }

      ${ifProp("isDragging", DropZoneActiveStyle)}

      &:hover {
        ${DropZoneActiveStyle}
      }
    `,
  )}
`;

const MediaSourceWrapper = styled.div`
  border: 1px solid ${themeColor("gray.300")};
  padding: 5px;
  ${CheckerboardBg}
`;

const DroppedImage = styled.img`
  width: fit-content;
  max-width: 100%;
  max-height: 80px;
  object-fit: contain;
`;

const EmptyDropzoneContent = styled.div`
  display: flex;
  align-items: center;
  padding-inline: 16px;
`;

// TODO: move to typo
export const DropzoneLabel = styled(Box)`
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  color: ${themeColor("gray.400")};
  text-align: center;
  transition: color 250ms ease-out;

  span {
    color: ${themeColor("blue.500")};
  }
`;

const ClearButton = styled(Icon.CircledX)`
  z-index: ${themeZ("imageDropzoneClearButton")};
  position: absolute;
  top: 0;
  right: 0;
  translate: 50% -50%;
  cursor: pointer;
`;

interface ImageDropzoneProps {
  onDrop(imageFile: File): void;
  onClear(): void;
  onReject?(): void;
  droppedImageURL?: string | null;
  acceptedImageTypes: Array<"png" | "jpg" | "jpeg" | "svg" | "webp" | "ico">;
  maxSizeBytes?: number;
}

export default function ImageDropzone({
  onDrop,
  onClear,
  onReject,
  droppedImageURL,
  acceptedImageTypes,
  maxSizeBytes,
}: ImageDropzoneProps) {
  const [isDragging, setIsDragging] = useState(false);
  const [isLoadingDroppedImage, setIsLoadingDroppedImage] = useState(false);

  const accept = useMemo(
    () =>
      acceptedImageTypes.reduce(
        (acc, imageType) => ({
          ...acc,
          [`image/${imageType}`]: [`.${imageType}`],
        }),
        {},
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...acceptedImageTypes],
  );

  return (
    <Container>
      <DropzoneContainer>
        <Dropzone
          onDrop={([file], rejected) => (rejected.length ? onReject?.() : onDrop(file))}
          onDragStateChange={setIsDragging}
          accept={accept}
          maxSize={maxSizeBytes}
          maxFiles={1}
        >
          <DropzoneBox isDragging={isDragging} isEmpty={!droppedImageURL} tabIndex={-1}>
            {droppedImageURL ? (
              <MediaSourceWrapper>
                <MediaSource
                  src={droppedImageURL}
                  onLoadStart={() => setIsLoadingDroppedImage(true)}
                  onLoadEnd={() => setIsLoadingDroppedImage(false)}
                  placeholder={<CircularLoader size={40} />}
                >
                  {(src) => <DroppedImage src={src} alt="dropped" />}
                </MediaSource>
              </MediaSourceWrapper>
            ) : (
              <EmptyDropzoneContent>
                <Icon.Upload size={42} />

                <DropzoneLabel marginLeft={20}>
                  <div>Drag your files here</div>
                  or <span>browse</span> to upload
                </DropzoneLabel>
              </EmptyDropzoneContent>
            )}
          </DropzoneBox>
        </Dropzone>

        {droppedImageURL && !isLoadingDroppedImage && <ClearButton onClick={onClear} size={26} />}
      </DropzoneContainer>

      <DropzoneLabel marginTop={6}>
        Supported file types: {acceptedImageTypes.map((imgType) => imgType.toUpperCase()).join(", ")}
      </DropzoneLabel>
    </Container>
  );
}
