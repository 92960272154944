import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAppConfig } from "src/components/providers/AppConfigProvider";
import { APP_ENV } from "src/constants/env.constants";

import { RootState } from "src/models/store";
import { absoluteRoutes } from "src/utils/routes.utils";

export default function GuestUsersGate() {
  const navigate = useNavigate();
  const { HOMEPAGE_URL } = useAppConfig();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const authToken = useSelector((state: RootState) => state.session.authToken);

  useEffect(() => {
    const sequenceSid = searchParams.get("sequenceSid");

    if (sequenceSid) {
      window.localStorage.setItem("sequenceSid", sequenceSid);
      searchParams.delete("sequenceSid");
    }

    if (sequenceSid) {
      navigate({ ...location, search: searchParams.toString() }, { replace: true });
    }
  }, [location, navigate, searchParams]);

  if (authToken) {
    return <Navigate to={absoluteRoutes.home} replace />;
  }

  // handle signup gate - block signup page if allowSignIn is false - only for production
  if (APP_ENV === "production" && location.pathname === absoluteRoutes.signup.self) {
    const allowSignInFromStorage = window.localStorage.getItem("YWxsb3dTaWduSW4=") === "true" || false; // base64 encoded string of "allowSignIn"
    const allowSignInParams = searchParams.get("aSIn"); // short for allowSignIn
    const allowSignIn = allowSignInParams === null ? allowSignInFromStorage : allowSignInParams === "true";
    window.localStorage.setItem("YWxsb3dTaWduSW4=", allowSignIn ? "true" : "false");
    if (!allowSignIn) {
      window.location.href = `${HOMEPAGE_URL}/requestademo`;
      return null;
    }
  }

  return <Outlet />;
}
