import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { Mousewheel, Swiper as SwiperType } from "swiper";
import { Swiper as BaseSwiper, SwiperProps as BaseSwiperProps } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

import useVariableRef from "src/hooks/useVariableRef";
import { useTouchpadSwipeRestriction } from "src/components/common/Swiper/hooks/useTouchpadSwipeRestriction";
import useShallowMemo from "src/hooks/useShallowMemo";

interface SwiperContextType {
  slideNext(): void;
  slidePrev(): void;
  slideCount: number;
  activeIndex: number;
  previousActiveIndex?: number;
  allowSlideNext: boolean;
  allowSlidePrev: boolean;
  isSliding: boolean;
}

const SwiperContext = createContext<SwiperContextType | null>(null);

export const useSwiperContext = () => {
  const value = useContext(SwiperContext);

  if (value === null) {
    throw new Error("SwiperContext value is null");
  }

  return value;
};

export interface SwiperProps extends BaseSwiperProps {
  activeIndex: number;
  direction?: "vertical" | "horizontal";
  enableMousewheel?: boolean;
}

export default function Swiper({
  onActiveIndexChange,
  activeIndex,
  direction = "vertical",
  allowSlideNext = true,
  allowSlidePrev = true,
  onTransitionEnd: propsOnTransitionEnd,
  onBeforeTransitionStart: propsOnBeforeTransitionStart,
  enableMousewheel = true,
  children,
  ...props
}: SwiperProps) {
  const [slideCount, setSlideCount] = useState(0);
  const [isSliding, setIsSliding] = useState(false);
  const swiperRef = useRef<SwiperType | null>(null);
  const onActiveIndexChangeRef = useVariableRef(onActiveIndexChange);

  const slideNext = useCallback(() => {
    if (swiperRef.current && swiperRef.current.activeIndex < slideCount - 1) {
      swiperRef.current.slideNext();
    }
  }, [slideCount]);

  const slidePrev = useCallback(() => {
    if (swiperRef.current && swiperRef.current.activeIndex > 0) {
      swiperRef.current.slidePrev();
    }
  }, []);

  const onBeforeTransitionStart = useCallback(
    (swiper: SwiperType, speed: number, internal: any) => {
      setIsSliding(true);
      propsOnBeforeTransitionStart?.(swiper, speed, internal);
    },
    [propsOnBeforeTransitionStart],
  );

  const onTransitionEnd = useCallback(
    (swiper: SwiperType) => {
      setIsSliding(false);
      propsOnTransitionEnd?.(swiper);
    },
    [propsOnTransitionEnd],
  );

  const onSwiperLoad = useCallback((swiperInstance: SwiperType) => {
    swiperRef.current = swiperInstance;
    setSlideCount(swiperInstance.slides.length);
  }, []);

  useEffect(() => {
    if (swiperRef.current && activeIndex !== swiperRef.current.activeIndex) {
      if (
        (activeIndex > swiperRef.current.activeIndex && allowSlideNext) ||
        (activeIndex < swiperRef.current.activeIndex && allowSlidePrev)
      ) {
        requestAnimationFrame(() => swiperRef.current?.slideTo(activeIndex));
      } else {
        onActiveIndexChangeRef.current?.(swiperRef.current);
      }
    }
  }, [activeIndex, allowSlideNext, allowSlidePrev, onActiveIndexChangeRef]);

  useTouchpadSwipeRestriction(swiperRef, 1);

  return (
    <SwiperContext.Provider
      value={useShallowMemo({
        slideNext,
        slidePrev,
        slideCount,
        activeIndex,
        previousActiveIndex: swiperRef.current?.previousIndex,
        allowSlideNext,
        allowSlidePrev,
        isSliding,
      })}
    >
      <BaseSwiper
        initialSlide={activeIndex}
        onSwiper={onSwiperLoad}
        onActiveIndexChange={onActiveIndexChange}
        onBeforeTransitionStart={onBeforeTransitionStart}
        onTransitionEnd={onTransitionEnd}
        allowSlideNext={allowSlideNext}
        allowSlidePrev={allowSlidePrev}
        modules={enableMousewheel ? [Mousewheel] : undefined}
        mousewheel={enableMousewheel}
        speed={750}
        direction={direction}
        allowTouchMove={false}
        {...props}
      >
        {children}
      </BaseSwiper>
    </SwiperContext.Provider>
  );
}
