import { TimeInterval, TimeIntervalEdge } from "src/types/video-trimmer.types";
import useDrag from "src/hooks/useDrag";

interface Options {
  pixelPerSecond: number;
  onDrag?(timeDelta: number): void;
  onDragStart?(): void;
  onDragEnd?(edge: TimeIntervalEdge, fullDeltaX: number, fullDeltaY: number): void;
  onResize?(edge: TimeIntervalEdge, timeDelta: number, halt: () => void): void;
  onResizeStart?(edge: TimeIntervalEdge): void;
  onResizeEnd?(edge: TimeIntervalEdge, fullDeltaX: number, fullDeltaY: number): void;
}

// TODO: remove the word video
// TODO: remove the word time
// TODO: rename
export default function useVideoTimeInterval(
  value: TimeInterval,
  { pixelPerSecond, onDrag, onDragStart, onDragEnd, onResize, onResizeStart, onResizeEnd }: Options,
) {
  const dragTriggerProps = useDrag({
    onDrag(deltaX) {
      const timeDelta = deltaX / pixelPerSecond;

      if (timeDelta) {
        onDrag?.(timeDelta);
      }
    },

    onDragStart() {
      onDragStart?.();
      document.body.style.cursor = "move";
    },

    onDragEnd(fullDeltaX, fullDeltaY) {
      onDragEnd?.("start", fullDeltaX, fullDeltaY);
      document.body.style.cursor = "";
    },
  });

  const leftResizeTriggerProps = useDrag({
    onDrag(deltaX, deltaY, x, y, haltDragging) {
      const timeDelta = deltaX / pixelPerSecond;

      if (timeDelta) {
        onResize?.("start", timeDelta, haltDragging);
      }
    },

    onDragStart() {
      onResizeStart?.("start");
      document.body.style.cursor = "col-resize";
    },

    onDragEnd(fullDeltaX, fullDeltaY) {
      onResizeEnd?.("start", fullDeltaX, fullDeltaY);
      document.body.style.cursor = "";
    },
  });

  const rightResizeTriggerProps = useDrag({
    onDrag(deltaX, deltaY, x, y, haltDragging) {
      const timeDelta = deltaX / pixelPerSecond;

      if (timeDelta) {
        onResize?.("end", timeDelta, haltDragging);
      }
    },

    onDragStart() {
      onResizeStart?.("end");
      document.body.style.cursor = "col-resize";
    },

    onDragEnd(fullDeltaX, fullDeltaY) {
      onResizeEnd?.("end", fullDeltaX, fullDeltaY);
      document.body.style.cursor = "";
    },
  });

  const x1 = Math.floor(value.start * pixelPerSecond);
  const x2 = Math.floor(value.end * pixelPerSecond);

  return {
    x1,
    x2,
    dragTriggerProps,
    leftResizeTriggerProps,
    rightResizeTriggerProps,
  };
}
