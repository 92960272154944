import styled, { css } from "styled-components/macro";
import { Stack } from "src/components/common/layout/Stack.styled";
import { useVideoCrop } from "src/components/features/VideoCropper/providers/VideoCropProvider/VideoCropContext";
import SliderWithInput from "src/components/common/form/inputs/SliderWithInput";
import NumberInput from "src/components/common/form/inputs/NumberInput";
import Icon from "src/components/common/Icon";
import { themeColor, themeProp } from "src/utils/styledComponents.utils";
import { boxStyle, FlexBox } from "src/components/common/layout/Box.styled";
import { useVideoPlayback } from "src/components/features/VideoTrimmer/providers/VideoPlaybackProvider/VideoPlaybackContext";
import * as cropAnalytics from "src/analytics/sequenceCropEditor.analytics";
import CropperColorPicker from "src/components/features/VideoCropper/CropperColorPicker";
import { ShortcutsKeys, getShortcutKeyLabel } from "src/constants/shortcuts.constants";
import { AlignType } from "src/components/features/VideoCropper/hooks/useCropData";
import Popper from "src/components/common/popovers/Popper";
import toast from "react-hot-toast";
import { ifNotProp, ifProp, switchProp } from "styled-tools";
import ActionButton, { ActionButtonPanel } from "../VideoTrimmer/TimelineActionButtons/ActionButton";

const Container = styled(Stack)`
  height: 100%;
  align-items: start;
`;

const TopBar = styled(Stack)`
  justify-content: space-between;
  width: 100%;
  div {
    min-width: 14px;
  }
`;

const PositionInputStack = styled(Stack)`
  justify-content: space-between;
  /* width: 50%; */
`;

const AlignmentInputsStack = styled(Stack)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Title = styled.div`
  font-family: "Open Sans", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  cursor: default;
`;

const ActionButtonStyled = styled(ActionButton)`
  font-family: "Open Sans", serif;
  font-weight: normal;
  font-size: 14px;
  color: ${themeColor("gray.900")};
  margin-left: -6px;
  width: max-content;
  min-height: 24px;
  gap: 0px;
  &:hover {
    color: ${themeColor("blue.500")};
    & svg rect {
      &:first-child {
        fill: ${themeColor("blue.500")};
      }
    }
  }
`;

const MiniActionButton = styled(ActionButton)`
  cursor: pointer;
  ${ifProp("disabled", "cursor: default; opacity: 0.5;")}

  g path {
    transition: fill 350ms ease-out;
    fill: ${themeColor("gray.900")};
  }

  ${ifNotProp(
    "disabled",
    css`
      &:hover {
        & g path {
          fill: ${themeColor("blue.500")};
        }
      }
    `,
  )}
`;

const ALIGNMENT_BUTTON_WIDTH = 24;
const ALIGNMENT_BUTTON_HEIGHT = 18;
// const ALIGNMENT_TOOL_TIP_OFFSET = ALIGNMENT_BUTTON_WIDTH - 4;
const AlignmentKeyPad = styled.div<{ disabled?: boolean }>`
  display: grid;
  grid-template-columns: ${`repeat(3, ${ALIGNMENT_BUTTON_WIDTH}px)`};
  grid-template-rows: ${`repeat(3, ${ALIGNMENT_BUTTON_HEIGHT}px)`};
  gap: 4px;
  opacity: ${ifProp("disabled", 0.5, 1)};
  cursor: ${ifProp("disabled", "auto", "pointer")};
  pointer-events: ${ifProp("disabled", "none", "all")};
  transition: opacity 350ms ease-out;
`;

const AlignmentButton = styled.div<{ alignment?: AlignType }>`
  cursor: pointer;
  background-color: transparent;
  transition: background-color 350ms ease-out;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid ${themeColor("gray.900")};

  height: 100%;
  width: 100%;
  border-radius: ${switchProp(
    "alignment",
    {
      TL: "8px 0 0 0",
      TR: "0 8px 0 0",
      BR: "0 0 8px 0",
      BL: "0 0 0 8px",
    },
    "0",
  )};
  &:hover {
    background-color: ${themeColor("blue.500")};
  }
  &:active {
    background-color: ${themeColor("blue.900")};
  }
`;

const HorizontalDivider = styled(FlexBox)`
  background: ${themeColor("gray.200")};
  height: 1px;
  min-height: 1px;
  width: 134%;
  margin-left: -30px;
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: ${themeProp("colors.gray.900")};
  ${boxStyle}
`;

const LabelAndInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const CropperColorPickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
`;

const alignOptions: { value: AlignType; shortcut: string; offset: number }[] = [
  { value: "TL", shortcut: "Align to top left (a+t+l)", offset: ALIGNMENT_BUTTON_WIDTH * 2 + 13 },
  { value: "TC", shortcut: "Align to top center (a+t+c)", offset: ALIGNMENT_BUTTON_WIDTH + 8 },
  { value: "TR", shortcut: "Align to top right (a+t+r)", offset: 5 },
  { value: "ML", shortcut: "Align to middle left (a+m+l)", offset: ALIGNMENT_BUTTON_WIDTH * 2 + 13 },
  { value: "MC", shortcut: "Align to middle center (a+m+c)", offset: ALIGNMENT_BUTTON_WIDTH + 8 },
  { value: "MR", shortcut: "Align to middle right (a+m+r)", offset: 5 },
  { value: "BL", shortcut: "Align to bottom left (a+b+l)", offset: ALIGNMENT_BUTTON_WIDTH * 2 + 13 },
  { value: "BC", shortcut: "Align to bottom center (a+b+c)", offset: ALIGNMENT_BUTTON_WIDTH + 8 },
  { value: "BR", shortcut: "Align to bottom right (a+b+r)", offset: 5 },
];

export interface VideoCropperSideMenuProps {
  sequenceSid: string;
}
export default function VideoCropperSideMenu({ sequenceSid }: VideoCropperSideMenuProps) {
  const {
    cropScale,
    rescaleCrop,
    draggingPosition,
    setDraggingPosition,
    getCropFill,
    getCropFit,
    setCropCords,
    copyDragPosition,
    pasteDragPosition,
    copiedDragPosition,
    resizeLimit,
    alignCrop,
  } = useVideoCrop();
  const { isPlaying } = useVideoPlayback();

  const onXChange = (x: number) => {
    setDraggingPosition({ ...draggingPosition, x });
  };

  const onYChange = (y: number) => {
    setDraggingPosition({ ...draggingPosition, y });
  };

  const onFillClick = () => {
    setDraggingPosition(getCropFill());
    setTimeout(() => {
      setCropCords();
    }, 1);
    cropAnalytics.trackCropFill("fill-button");
  };

  const onFitClick = () => {
    setDraggingPosition(getCropFit());
    setTimeout(() => {
      setCropCords();
    }, 1);
    cropAnalytics.trackCropFit("fit-button");
  };

  const onAlignClick = (alignTo: AlignType) => {
    setDraggingPosition(alignCrop(alignTo));
    setTimeout(() => {
      setCropCords();
    }, 1);
    cropAnalytics.trackAlignCrop("align-button", alignTo);
  };

  const onCopyClick = () => {
    copyDragPosition();
    toast.success("Copied to clipboard");
    cropAnalytics.trackCropCopy("copy-button");
  };

  const onPasteClick = () => {
    if (!copiedDragPosition) return;
    pasteDragPosition();
    toast.success("Pasted successfully");
    cropAnalytics.trackCropPaste("paste-button");
  };

  return (
    <Container direction="column" spacing={14}>
      <TopBar direction="row" spacing={14}>
        <Title>Position</Title>
        <Stack direction="row" spacing={6}>
          <MiniActionButton
            label={`Copy ${getShortcutKeyLabel(ShortcutsKeys.copyDragPosition)}`}
            disabled={isPlaying}
            onClick={onCopyClick}
          >
            <Icon.Duplicate size={14} />
          </MiniActionButton>
          <MiniActionButton
            label={`Paste ${getShortcutKeyLabel(ShortcutsKeys.pasteDragPosition)}`}
            disabled={!copiedDragPosition || isPlaying}
            onClick={onPasteClick}
          >
            <Icon.Paste size={14} />
          </MiniActionButton>
        </Stack>
      </TopBar>
      <AlignmentInputsStack direction="row" spacing={20}>
        <PositionInputStack direction="column" spacing={10}>
          <LabelAndInputWrapper>
            <Text>X</Text>
            <NumberInput
              value={draggingPosition.x.toFixed(0)}
              onValueChange={onXChange}
              onBlur={() => {
                setCropCords();
                cropAnalytics.trackCropResizeAndPosition("x-input");
              }}
              min={-1000}
              max={1000}
              step={1}
              disabled={isPlaying}
            />
          </LabelAndInputWrapper>
          <LabelAndInputWrapper>
            <Text>Y</Text>
            <NumberInput
              value={draggingPosition.y.toFixed(0)}
              onValueChange={onYChange}
              onBlur={() => {
                setCropCords();
                cropAnalytics.trackCropResizeAndPosition("y-input");
              }}
              min={-1000}
              max={1000}
              step={1}
              disabled={isPlaying}
            />
          </LabelAndInputWrapper>
        </PositionInputStack>
        <Stack direction="column" style={{ alignItems: "center" }} spacing={4}>
          {/* <Text>Alignment</Text> */}
          <AlignmentKeyPad disabled={isPlaying}>
            {alignOptions.map((option) => (
              <Popper
                trigger="hover"
                key={option.shortcut}
                placement="right"
                offsetY={option.offset}
                content={<ActionButtonPanel>{option.shortcut}</ActionButtonPanel>}
              >
                <AlignmentButton
                  alignment={option.value}
                  key={option.value}
                  onClick={() => onAlignClick(option.value)}
                />
              </Popper>
            ))}
          </AlignmentKeyPad>
        </Stack>
      </AlignmentInputsStack>
      <Stack direction="column" style={{ width: "100%" }} spacing={6}>
        <Title>Scale</Title>
        <SliderWithInput
          value={(cropScale * 100).toFixed(0)}
          onValueChange={(value) => {
            rescaleCrop(value / 100);
          }}
          onChangeComplete={() => {
            setCropCords();
            cropAnalytics.trackCropResizeAndPosition("scale-slider");
          }}
          min={resizeLimit.minScale * 100}
          max={resizeLimit.maxScale * 100}
          step={1}
          disabled={isPlaying}
        />
      </Stack>
      <HorizontalDivider />

      <Stack direction="column" spacing={6}>
        <ActionButtonStyled
          label={`Fill ${getShortcutKeyLabel(ShortcutsKeys.cropFill)}`}
          disabled={isPlaying}
          onClick={onFillClick}
        >
          <Icon.Fill />
          Fill Video
        </ActionButtonStyled>
        <ActionButtonStyled
          label={`Fit ${getShortcutKeyLabel(ShortcutsKeys.cropFit)}`}
          popperPlacement="bottom"
          disabled={isPlaying}
          onClick={onFitClick}
        >
          <Icon.Fit />
          Fit Video
        </ActionButtonStyled>
      </Stack>

      <CropperColorPickerContainer>
        <Title>Background</Title>
        <CropperColorPicker sequenceSid={sequenceSid} />
      </CropperColorPickerContainer>
    </Container>
  );
}
