import { components, ControlProps } from "react-select";
import { BoardOption } from "src/components/features/Boards/BoardSelectOption";
import Icon from "src/components/common/Icon";
import styled from "styled-components/macro";
import theme from "src/theme";
import { ALL_BOARDS_ITEM_ID } from "src/constants/smartLibrary.constants";
import { isMobile } from "src/utils/mobile.utils";

const StyledControl = styled(components.Control)`
    &.select__control {
        width: ${isMobile() ? "36px !important" : "auto"};
        height: ${isMobile() ? "36px !important" : "auto"};
        border-radius: ${isMobile() ? "8px !important" : "auto"};
        transition: background-color 0.5s ease;
    }

    &.select__control--is-focused {
        background-color: ${theme.colors.blue["200"]}; !important;
    }
  }
`;

const IconWrapper = styled.div`
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0 !important;
  margin-left: ${isMobile() ? "10px" : "12px"} !important;
  margin-top: ${isMobile() ? "-2px" : "unset"} !important;

  svg {
    path {
      fill: ${theme.colors.gray["900"]};
      stroke: none;
    }
  }
`;

const StyledIconWrapper = styled(IconWrapper)`
  width: 18px;

  svg {
    width: 20px;
    height: 20px;
    path {
      stroke: ${theme.colors.gray["900"]};
      fill: none;
    }
  }
`;
export function BoardsControl(props: ControlProps<BoardOption>) {
  const { selectProps, children } = props;

  return (
    <StyledControl {...props} selectProps={selectProps}>
      {(selectProps?.value as BoardOption)?.id !== ALL_BOARDS_ITEM_ID ? (
        <IconWrapper>
          <Icon.Board size={16} />
        </IconWrapper>
      ) : (
        <StyledIconWrapper>
          <Icon.VideosStack />
        </StyledIconWrapper>
      )}
      {children}
    </StyledControl>
  );
}
