import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "src/models/store";
import { useCallback, useMemo } from "react";

export const PERSONAL_EMAIL_ERROR_TEXT = "Please sign up using your company email";

export default function useOauthNetworkError() {
  const dispatch = useDispatch<Dispatch>();
  const oAuthNetworkError = useSelector(
    (state: RootState) => state.loading.effects.users.loginUserOAuth.error as Error,
  );
  const isPersonalEmailAddress = useMemo(() => {
    const code = oAuthNetworkError && (oAuthNetworkError as any)?.response?.errors?.[0]?.code;
    const message = oAuthNetworkError && (oAuthNetworkError as any)?.response?.errors?.[0]?.message;
    return code === 2 && message?.includes("not a company email");
  }, [oAuthNetworkError]);

  const resetLoginUserOauthError = useCallback(() => {
    dispatch.users.loginUserOAuth({
      provider: "",
      providerToken: "",
      signupCampaign: "",
      resetError: true,
    });
  }, [dispatch.users]);

  return { isPersonalEmailAddress, resetLoginUserOauthError };
}
