import { ReactNode } from "react";
import { themeColor } from "src/utils/styledComponents.utils";

import styled from "styled-components/macro";
import { ifProp } from "styled-tools";

export const Layer = styled.div<{ borderRadius: string }>`
  position: absolute;
  margin: 1px;
  border-radius: ${({ borderRadius }) => borderRadius};
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  transition: opacity 0.5s ease;
  span {
    font-family: "Open Sans";
    font-weight: 700;
    line-height: 20px;
  }
`;

export const WhiteLayer = styled(Layer)`
  background-color: ${themeColor("white")};
  span {
    background-image: linear-gradient(90deg, #ea429d 0%, #7d55d2 54.17%, #0073c3 99.44%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }
  svg {
    fill: transparent;
  }
`;

const RainbowLayer = styled(Layer)`
  opacity: ${ifProp("isFrontal", "1", "0")};
  background-image: linear-gradient(90deg, #ea429d 0%, #7d55d2 54.17%, #0073c3 99.44%);
  span {
    color: ${themeColor("white")};
  }
  svg {
    fill: ${themeColor("white")};
    path {
      fill: ${themeColor("white")};
    }
  }
`;

const ButtonContainer = styled.div<{
  isRainbowFrontal: boolean;
  width: string;
  height: string;
  fontSize: string;
  borderRadius: string;
  fullWidth?: boolean;
  disabled?: boolean;
}>`
  position: relative;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: ${({ borderRadius }) => borderRadius};
  background: linear-gradient(90deg, #ea429d 0%, #7d55d2 54.17%, #0073c3 99.44%);
  cursor: ${ifProp("disabled", "not-allowed", "pointer")};
  opacity: ${ifProp("disabled", "0.5", "1")};
  pointer-events: ${ifProp("disabled", "none", "auto")};

  ${WhiteLayer} {
    opacity: ${ifProp("isRainbowFrontal", "0", "1")};
  }
  ${RainbowLayer} {
    opacity: ${ifProp("isRainbowFrontal", "1", "0")};
  }
  span {
    font-size: ${({ fontSize }) => fontSize};
  }

  &:hover {
    ${WhiteLayer} {
      opacity: ${ifProp("isRainbowFrontal", "1", "0")};
    }
    ${RainbowLayer} {
      opacity: ${ifProp("isRainbowFrontal", "0", "1")};
    }
  }
`;

export interface WhiteRainbowButtonProps {
  onClick: (e: any) => void;
  disabled?: boolean;
  text?: string;
  icon?: ReactNode;
  variant?: "rainbow" | "white";
  width?: string;
  height?: string;
  fontSize?: string;
  borderRadius?: string;
  className?: string;
}

export default function WhiteRainbowButton({
  onClick,
  disabled,
  text,
  icon,
  variant = "rainbow",
  width = "162px",
  height = "42px",
  fontSize = "14px",
  borderRadius = "80px",
  className,
}: WhiteRainbowButtonProps) {
  return (
    <ButtonContainer
      onClick={onClick}
      disabled={disabled}
      isRainbowFrontal={variant === "rainbow"}
      width={width}
      height={height}
      fontSize={fontSize}
      borderRadius={borderRadius}
      className={className}
    >
      <WhiteLayer borderRadius={borderRadius}>
        {icon ?? ""}
        <span>{text}</span>
      </WhiteLayer>
      <RainbowLayer borderRadius={borderRadius}>
        {icon ?? ""}
        <span>{text}</span>
      </RainbowLayer>
    </ButtonContainer>
  );
}
