import { find, flow, keyBy, pick, range, set } from "lodash/fp";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components/macro";

import { assetSelectors } from "src/models/Asset.model";
import { presetSelectors } from "src/models/Preset.model";
import { userSelectors } from "src/models/User.model";
import { Dispatch, RootState } from "src/models/store";

import { getDefaultCaptionsStyle } from "src/utils/closeCaption.utils";

import useEndScrollHandler from "src/hooks/useEndScrollHandler";

import { EditRules } from "src/network/graphql/generatedGraphqlSDK";

import { AssetStatus, AssetType } from "src/constants/model.constants";
import { AspectRatio, MAX_CHAPTER_COUNT, SKIP_SPEAKER_DETECTION_QUERY_PARAM } from "src/constants/video.constants";

import * as createProjectAnalytics from "src/analytics/creationWizard.analytics";

import ResponsivePeechLibrary from "src/components/common/ResponsiveLibrary/ResponsivePeechLibrary";
import { Button } from "src/components/common/buttons/Button.styled";
import { Center } from "src/components/common/layout/Box.styled";
import { CircularLoader } from "src/components/common/loaders/CircularLoader";
import LoadingPlug from "src/components/common/loaders/LoadingPlug";
import CancelButton from "src/components/features/CreateSequenceWizard/common/CancelButton";
import { CancelButtonWrapper, CentralButtonsWrapper, Footer, GridScroll, HeaderText, SubHeaderText } from "src/components/features/CreateSequenceWizard/common/create-sequence-wizard-layout.styled"; // prettier-ignore
import AssetItemContent from "src/components/features/CreateSequenceWizard/steps/FootageStep/AssetItemContent";
import {
  ASPECT_RATIOS,
  DEFAULT_ASPECT_RATIO,
  PAGE_SIZE,
} from "src/components/features/CreateSequenceWizard/steps/FootageStep/FootageStep";
import NoVerbalContentModal from "src/components/modals/NoVerbalContentModal";
import { useModal } from "src/components/providers/ModalProvider";
import { isMobile } from "src/utils/mobile.utils";
import { absoluteRoutes, applyPathParams } from "src/utils/routes.utils";
import { themeColor } from "src/utils/styledComponents.utils";

const Container = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: ${themeColor("gray.50")};
`;

const FootagesContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 80px 0 74px 0;
`;

const ItemsWrapper = styled.div`
  padding: 20px 0 0 20px;
`;

// TODO: decouple from useSearchParams
export default function FastCreateSequence() {
  const { openModal } = useModal();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const containerRef = useRef<HTMLDivElement>(null);
  const sequenceTitleRef = useRef(searchParams.get("title"));

  const initialAssetIds = useMemo(() => searchParams.getAll("assetSid").slice(0, MAX_CHAPTER_COUNT), [searchParams]);

  const [selectedAssetIds, setSelectedAssetIds] = useState(initialAssetIds);
  const [assetsFullyLoaded, setAssetsFullyLoaded] = useState(false);

  const dispatch = useDispatch<Dispatch>();
  const currentUser = useSelector(userSelectors.selectCurrentUser);
  const defaultPreset = useSelector((state: RootState) => presetSelectors.getDefaultPreset(state));
  const assets = useSelector((state: RootState) => assetSelectors.selectAllByTypeAndStatusAndNotPublished(state, AssetType.Source, AssetStatus.Ready)); // prettier-ignore
  const editRulesList = useSelector((state: RootState) => state.session.editRules);
  const isSavingSequence = useSelector((state: RootState) => state.loading.effects.sequences.createSequence.loading);
  const assetsLoading = useSelector((state: RootState) => state.loading.effects.assets.fetchAssets.loading);
  const firstAsset = useSelector((state: RootState) => assetSelectors.selectById(state, selectedAssetIds?.[0] ?? "")); // prettier-ignore
  const defaultVideoTypeFromAdmin = useSelector((state: RootState) => state.session.defaultVideoType);
  const initialAssetsLoaded = useSelector((state: RootState) => state.loading.effects.assets.fetchAssetsById.success);

  const assetDict = useMemo(() => keyBy("sid", assets), [assets]);
  const validInitialAssetIds = useMemo(() => initialAssetIds.filter((sid) => assetDict[sid]), [initialAssetIds, assetDict]); // prettier-ignore

  const useDefaultAsset = searchParams.has("useDefaultAsset");
  const assetsLoaded = assets.length > 0;

  const selectAsset = useCallback(
    async (assetSid: string) => {
      if (isSavingSequence) {
        return;
      }

      const updatedAssets = Array.isArray(selectedAssetIds) ? [...selectedAssetIds] : [];

      if (updatedAssets.includes(assetSid)) {
        const index = updatedAssets.indexOf(assetSid);
        updatedAssets.splice(index, 1);
      } else if (updatedAssets.length < MAX_CHAPTER_COUNT) {
        updatedAssets.push(assetSid);
      }

      setSelectedAssetIds(updatedAssets);
    },
    [selectedAssetIds, isSavingSequence],
  );

  const getAssets = async () => {
    if (assetsLoading || assetsFullyLoaded) {
      return;
    }

    const res = await dispatch.assets.fetchAssets({
      filter: {
        typeIn: [AssetType.Source],
        statusEqual: AssetStatus.Ready,
        footageSidIsNull: false,
      },
      pager: {
        pageSize: PAGE_SIZE,
        pageIndex: Math.floor(assets.length / PAGE_SIZE) + 1,
      },
    });

    if (res.length < PAGE_SIZE) {
      setAssetsFullyLoaded(true);
    }
  };

  const isAssetSelected = useCallback(
    (assetSid: string) => {
      if (selectedAssetIds && selectedAssetIds.length) {
        return [...(selectedAssetIds ?? [])].some((x) => x === assetSid);
      }
      return false;
    },
    [selectedAssetIds],
  );

  const getSelectedIndex = useCallback(
    (assetSid: string) => selectedAssetIds?.findIndex((x) => x === assetSid),
    [selectedAssetIds],
  );

  const createSequence = useCallback(
    async () => {
      const title = useDefaultAsset ? assets[0].name! : sequenceTitleRef.current ?? firstAsset?.name;
      const aspectRatio = firstAsset ? (firstAsset.aspectRatio as AspectRatio) : DEFAULT_ASPECT_RATIO;
      const style = getDefaultCaptionsStyle(aspectRatio);
      const defaultVideoType = defaultVideoTypeFromAdmin?.sid;
      const assetSids = useDefaultAsset ? [assets[0].sid!] : selectedAssetIds;
      const editRulesSid = defaultVideoTypeFromAdmin?.editRulesSid;
      const editRuleKeys = ["name", "volume", "noContentVolume", "useSpeakers", "useIntro", "useOutro", "useTexts", "useSlide", "useMusic", "useHighlight", "useFrame"]; // prettier-ignore
      const editRules = flow(
        find<EditRules>({ sid: editRulesSid }),
        (rules) => pick<EditRules>(editRuleKeys, rules),
        (rules) => set<EditRules>(["useLogo"], true, rules),
      )(editRulesList);

      if (assetSids.every((sid) => assetDict[sid].noWords)) {
        navigate(absoluteRoutes.createProjectFast);
        openModal(NoVerbalContentModal);
        return;
      }

      if (currentUser?.sid && defaultVideoType && defaultPreset && editRulesSid && title) {
        const createSequenceParams = {
          userSid: currentUser.sid,
          preset: defaultPreset,
          skipUpdatePreset: true,
          title,
          style,
          aspectRatio,
          assetSids,
          defaultVideoType,
          editRulesSid,
          editRules,
        };

        const sequenceSid = await dispatch.sequences.createSequence(createSequenceParams);
        await dispatch.sequences.reorderSequenceChapters({ sequenceSid, chapters: [] });

        createProjectAnalytics.trackSaveNewProject({ aspectRatio, editRules }, sequenceSid);
        if (isMobile()) {
          navigate(
            applyPathParams(
              absoluteRoutes.projectSequence.children.visualsEditor,
              { sequenceSid },
              { key: "firstDraft", value: "true" },
            ),
          );
        } else {
          const path = applyPathParams(absoluteRoutes.projectSequence.children.contentEditor, { sequenceSid });
          navigate(`${path}?${SKIP_SPEAKER_DETECTION_QUERY_PARAM}`);
        }
      }
    },
    // prettier-ignore
    [useDefaultAsset, assets, firstAsset, defaultVideoTypeFromAdmin?.sid, defaultVideoTypeFromAdmin?.editRulesSid, selectedAssetIds, editRulesList, currentUser.sid, defaultPreset, assetDict, navigate, openModal, dispatch.sequences],
  );

  useEndScrollHandler(containerRef, async () => {
    if (!assetsFullyLoaded) {
      getAssets();
    }
  });

  useEffect(() => {
    createProjectAnalytics.trackStartProjectCreating("fast flow");
  }, []);

  useEffect(() => {
    getAssets();
    dispatch.session.getVideoTypes();
    dispatch.session.getEditRules();

    if (initialAssetIds.length) {
      dispatch.assets.fetchAssetsById(initialAssetIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      if (initialAssetsLoaded && validInitialAssetIds.length) {
        createSequence();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialAssetsLoaded, validInitialAssetIds.length],
  );

  useEffect(() => {
    if (useDefaultAsset && assetsLoaded) {
      createSequence();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetsLoaded]);

  return (
    <Container>
      {!useDefaultAsset && !initialAssetIds.length && (
        <FootagesContainer>
          <GridScroll ref={containerRef}>
            <HeaderText>Choose the footage for your video</HeaderText>
            <SubHeaderText>The footage will be combined into one video</SubHeaderText>

            <ItemsWrapper>
              <ResponsivePeechLibrary horizontalGutter={20}>
                {assets.map((asset) => (
                  <AssetItemContent
                    key={asset.sid}
                    sid={asset.sid!}
                    aspectRatio={DEFAULT_ASPECT_RATIO}
                    onItemSelected={selectAsset}
                    isItemSelected={isAssetSelected}
                    getSelectedIndex={getSelectedIndex}
                  />
                ))}

                {assetsLoading &&
                  range(0, 10).map((x) =>
                    ASPECT_RATIOS.map((asRat) => (
                      <LoadingPlug key={x + asRat} verticalGutter={16} aspectRatio={DEFAULT_ASPECT_RATIO} />
                    )),
                  )}
              </ResponsivePeechLibrary>
            </ItemsWrapper>
          </GridScroll>
        </FootagesContainer>
      )}

      {useDefaultAsset || initialAssetIds.length ? (
        <Center>
          <CircularLoader size={100} />
        </Center>
      ) : (
        <Footer>
          <CancelButtonWrapper hide={isSavingSequence}>
            <CancelButton>Cancel</CancelButton>
          </CancelButtonWrapper>

          <CentralButtonsWrapper>
            <Button
              data-testid="next"
              size="large"
              variant="primary"
              onClick={createSequence}
              disabled={!(selectedAssetIds.length && !isSavingSequence)}
            >
              {isSavingSequence ? <CircularLoader size={30} /> : "Next"}
            </Button>
          </CentralButtonsWrapper>
        </Footer>
      )}
    </Container>
  );
}
