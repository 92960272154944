import Color from "color";
import { set } from "lodash/fp";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";

import { useGetAssetImageURL } from "src/hooks/useGetAssetURL";

import { ExtractedImageColor, getImagePalette } from "src/utils/image.utils";

import styled from "styled-components/macro";

import { useSelector } from "react-redux";
import { Palette } from "src/components/common/form/inputs/ColorPicker/SwatchPalette";
import {
  HeaderText,
  LogoContainer,
} from "src/components/features/AutomaticCreateWizard/common/automatic-create-wizard-layout.styled";
import ColorStack from "src/components/features/AutomaticCreateWizard/steps/components/ColorStack";
import { presetSelectors } from "src/models/Preset.model";
import { RootState } from "src/models/store";
import { CheckerboardBg } from "src/components/common/background/Checkerboard.styled";
import { FormContextInterface } from "src/components/features/AutomaticCreateWizard/FormContext.interface";

const LogoImageWrapper = styled(LogoContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: unset;
  border: unset;
  width: 220px;
  height: 100px;
  margin-top: 40px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  ${CheckerboardBg}
`;

const ColorStackWrapper = styled.div`
  margin-block: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-block: 40px 0;
  border-radius: 10px;
  width: fit-content;
  min-width: fit-content;
  overflow: hidden;
`;

const LogoImage = styled.img`
  width: fit-content;
  max-width: 100%;
  max-height: 80px;
`;

export default function BrandColors({ formContext }: FormContextInterface) {
  const getAssetImageURL = useGetAssetImageURL();
  const form = formContext;
  const { watch, setValue } = form;
  const { logo, colors } = watch("brandKit");
  const logoAssetSid = watch("brandKit.logo.assetId");
  const [extractedColors, setExtractedColors] = useState<ExtractedImageColor[]>([]);
  const [brandColorStackPalettes, setBrandColorStackPalettes] = useState<Palette[]>([]);
  const logoImageUrl = useMemo(() => (logoAssetSid ? getAssetImageURL(logoAssetSid) : ""), [getAssetImageURL, logoAssetSid]); // prettier-ignore
  const firstExperienceUser = useSelector((state: RootState) => presetSelectors.isUserFirstExperience(state));

  const onColorUpdate = useCallback(
    (colorIndex: number, newValue: Color) => {
      if (!colors) return;

      const updatedBrandColors = set([colorIndex, "color"], newValue.hex(), colors);
      setValue("brandKit.colors", updatedBrandColors, { shouldValidate: true });
    },
    [colors, setValue],
  );

  const generateColorsFromLogo = useCallback(
    () =>
      getImagePalette(getAssetImageURL(logo?.assetId ?? ""), 5).then((extractedLogoColors) => {
        setExtractedColors(extractedLogoColors);
        setBrandColorStackPalettes([
          { name: "Colors from logo", colors: extractedLogoColors.map(({ rgb }) => Color(rgb).hex()) },
        ]);
      }),
    [getAssetImageURL, logo?.assetId],
  );

  const applyColorsFromLogo = useCallback(() => {
    setValue(
      "brandKit.colors",
      extractedColors.map(({ rgb, percent, grayness }, index) => ({
        dominancy: index + 1,
        color: Color(rgb).hex(),
        percent,
        grayness,
      })),
      { shouldValidate: true },
    );
  }, [extractedColors, setValue]);

  useEffect(() => {
    if (!logo?.assetId) return;
    generateColorsFromLogo();
  }, [colors?.length, generateColorsFromLogo, logo?.assetId]);

  useEffect(() => {
    if (!colors?.length) {
      applyColorsFromLogo();
    }
  }, [applyColorsFromLogo, colors?.length]);

  return (
    <Fragment>
      <HeaderText>Ensure Consistent And Professional Video Branding </HeaderText>
      <HeaderText>
        By Using Your <strong>Brand Colors.</strong>
      </HeaderText>
      {firstExperienceUser && (
        <LogoImageWrapper>
          <LogoImage src={logoImageUrl} alt="Logo" />
        </LogoImageWrapper>
      )}
      <ColorStackWrapper>
        <ColorStack
          colorItems={colors}
          onColorUpdate={onColorUpdate}
          onAddNewColor={() => {}}
          getColorItemId={(item) => item.dominancy}
          transitionDelayBase={400}
          colorItemTransitionDuration={100}
          colorItemTransitionDelay={60}
          allowAdding={false}
          palettes={brandColorStackPalettes}
          regenerateCallback={applyColorsFromLogo}
        />
      </ColorStackWrapper>
    </Fragment>
  );
}
