import { useRef } from "react";
import { themeColor } from "src/utils/styledComponents.utils";

import Tooltip from "src/components/common/popovers/Tooltip";
import { onKeyPress } from "src/utils/input.utills";
import styled from "styled-components/macro";

const FootageInputContainer = styled.div`
  input {
    width: 100%;
    height: 30px;
    border: solid 1px;
    border-radius: 0 0 8px 8px;
    border-color: ${themeColor("blue.500")};
    padding-inline: 10px;
    color: ${themeColor("blue.500")};
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
  }
`;

interface WebItemFooterComponentProps {
  renamingInProgress: boolean;
  name: string;
  onNameChange: (val: string) => void;
}

/* eslint-disable */
export default function WebItemFooterComponent({
  renamingInProgress,
  name,
  onNameChange,
}: WebItemFooterComponentProps) {
  const titleInputRef = useRef<HTMLInputElement>(null);

  const onBlur = () => {
    onNameChange(titleInputRef.current?.value || "");
  };

  return !renamingInProgress ? (
    <Tooltip text={name} placement="bottom">
      <span>{name}</span>
    </Tooltip>
  ) : (
    <FootageInputContainer>
      <input ref={titleInputRef} type="text" defaultValue={name} autoFocus onBlur={onBlur} onKeyDown={onKeyPress} />
    </FootageInputContainer>
  );
}
