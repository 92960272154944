import MultiRangeSlider from "multi-range-slider-react";
import React, { useCallback, useRef, useState } from "react";

import { themeColor } from "src/utils/styledComponents.utils";
import { formatSeconds } from "src/utils/time.utils";

import styled from "styled-components/macro";

const SliderWrapper = styled.div`
  width: 100%;
  height: fit-content;
  margin-top: 16px;

  .duration-slider {
    padding: 10px 0;
    box-shadow: none;
    border: none;
    .bar {
      .bar-left,
      .bar-right {
        background-color: ${themeColor("blue.50")};
        padding-block: 2px;
        box-shadow: none;
      }
      .thumb-left,
      .thumb-right {
        position: relative;
        background-color: ${themeColor("blue.900")};
        &::before {
          width: 10px;
          height: 10px;
          margin-top: -4px;
          margin-inline: 0;
          box-shadow: none;
          border-color: ${themeColor("blue.900")};
        }
      }
      .thumb-right {
        &::before {
          margin-inline: -12px;
        }
      }

      .bar-inner {
        background-color: ${themeColor("blue.600")};
        border-color: ${themeColor("blue.600")};
        box-shadow: none;
      }
      .caption {
        bottom: 20px;
        display: flex !important;
      }
      .min-caption,
      .max-caption {
        height: 20px;
        background-color: ${themeColor("white")};
        box-shadow: none;
        color: ${themeColor("gray.900")};
        font-family: "Open Sans";
        font-size: 10px;
        font-weight: 400;
        line-height: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .min-caption {
        right: -6px;
      }
      .max-caption {
        left: -10px;
      }
    }
  }
`;

interface HorizontalRangeSliderProps {
  min: number;
  max: number;
  range: [number, number];
  step: number;
  onChange?: (range: number[]) => void;
}

export default function HorizontalRangeSlider({ min, max, range, step, onChange }: HorizontalRangeSliderProps) {
  const sliderRef = useRef<HTMLDivElement | null>(null);
  const [minValue, setMinValue] = useState(range[0]);
  const [maxValue, setMaxValue] = useState(range[1]);

  const handleSliderChange = useCallback(
    (e: { minValue: React.SetStateAction<number>; maxValue: React.SetStateAction<number> }) => {
      setMinValue(e.minValue);
      setMaxValue(e.maxValue);
    },
    [],
  );

  const handleSliderAfterChange = useCallback(() => {
    onChange?.([minValue, maxValue]);
  }, [maxValue, minValue, onChange]);

  return (
    <SliderWrapper>
      <MultiRangeSlider
        min={min}
        max={max}
        step={step}
        minValue={minValue}
        maxValue={maxValue}
        ruler={false}
        label={false}
        subSteps
        minCaption={formatSeconds(minValue, "mm:ss")}
        maxCaption={formatSeconds(maxValue, "mm:ss")}
        className="duration-slider"
        onInput={(e) => {
          handleSliderChange(e);
        }}
        onChange={handleSliderAfterChange}
        style={{ flexGrow: 1 }}
        ref={sliderRef}
      />
    </SliderWrapper>
  );
}
