import { FormStepDescriptor, InferStepPathUnion } from "common-form-types";
import ContentStep from "src/components/features/CreateSequenceWizard/steps/ContentStep";
import FootageStep from "src/components/features/CreateSequenceWizard/steps/FootageStep/FootageStep";
import ChooseRatioStep from "src/components/features/CreateSequenceWizard/steps/ChooseRatioStep/ChooseRatioStep";
import VisualsStep from "src/components/features/CreateSequenceWizard/steps/VisualsStep/VisualsStep";

const steps = [
  {
    path: "footage",
    component: FootageStep,
  },
  {
    path: "size",
    component: ChooseRatioStep,
  },
  {
    path: "visuals",
    component: VisualsStep,
  },
  {
    path: "content",
    component: ContentStep,
  },
] as const satisfies ReadonlyArray<FormStepDescriptor>;

export type StepPath = InferStepPathUnion<typeof steps>;

export default steps;
