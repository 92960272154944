import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useFormContext } from "react-hook-form";
import * as yup from "yup";

import { StepPath } from "src/components/features/CreateSequenceWizard/steps/steps";
import { AspectRatio } from "src/constants/video.constants";
import { ColorAdd } from "src/network/graphql/generatedGraphqlSDK";

const clipFormSchema = yup
  .object({
    initial: yup
      .object({
        usersCurrentPresetSid: yup.string().nullable(),
      })
      .required(),

    size: yup
      .object({
        selectedAspectRatio: yup.array<AspectRatio[]>().min(1, "Select at least 1 aspect ratio").required().default([]),
        selectedByUser: yup.boolean().optional().nullable(),
      })
      .required(),

    brandKit: yup
      .object({
        temporaryPresetValues: yup
          .object({
            logo: yup.object({
              assetId: yup.string(),
            }),
            colors: yup.array(
              yup.object({
                dominancy: yup.number(),
                color: yup.string(),
                percent: yup.number(),
                grayness: yup.number(),
              } satisfies Record<keyof ColorAdd, yup.BaseSchema>),
            ),
            visuals: yup.object({
              visualPackageId: yup.string(),
            }),
            SORs: yup.object({
              defaultVideoType: yup.string().required(),
              editRulesSid: yup.string().required(),
              editRules: yup
                .object({
                  useIntro: yup.boolean().optional().nullable(),
                  useOutro: yup.boolean().optional().nullable(),
                  useSpeakers: yup.boolean().optional().nullable(),
                  useTexts: yup.boolean().optional().nullable(),
                  useSlides: yup.boolean().optional().nullable(),
                  useMusic: yup.boolean().optional().nullable(),
                  useHighlight: yup.boolean().optional().nullable(),
                  useFrame: yup.boolean().optional().nullable(),
                })
                .required(),
            }),
          })
          .nullable(),

        logo: yup
          .object({
            assetId: yup.string().required(),
          })
          .required(),

        colors: yup
          .array(
            yup.object({
              dominancy: yup.number().required(),
              color: yup.string().required(),
              percent: yup.number().required(),
              grayness: yup.number().required(),
            } satisfies Record<keyof ColorAdd, yup.BaseSchema>),
          )
          .required(),

        visuals: yup
          .object({
            visualPackageId: yup.string().required(),
          })
          .required(),

        SORs: yup.object({
          defaultVideoType: yup.string().required(),
          editRulesSid: yup.string().required(),
          editRules: yup
            .object({
              useIntro: yup.boolean().optional().nullable(),
              useOutro: yup.boolean().optional().nullable(),
              useSpeakers: yup.boolean().optional().nullable(),
              useTexts: yup.boolean().optional().nullable(),
              useSlides: yup.boolean().optional().nullable(),
              useMusic: yup.boolean().optional().nullable(),
              useHighlight: yup.boolean().optional().nullable(),
              useFrame: yup.boolean().optional().nullable(),
            })
            .required(),
        }),
      })
      .required(),
  } satisfies Record<StepPath, yup.ObjectSchema<{}>>)
  .required();

type PartialSteps<Form extends object> = {
  [Step in keyof Form]: {
    [Field in keyof Form[Step]]+?: Form[Step][Field];
  };
};

export type ClipForm = PartialSteps<yup.InferType<typeof clipFormSchema>>;

export const useClipForm = () =>
  useForm<ClipForm>({
    mode: "onChange",
    resolver: yupResolver(clipFormSchema),
    defaultValues: {},
  });

export const useClipFormContext = useFormContext<ClipForm>;
