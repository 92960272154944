import { useMemo } from "react";
import { sumBy } from "lodash/fp";

import { formatSeconds } from "src/utils/time.utils";
import { timeIntervalUtils } from "src/utils/timeInterval.utils";
import {
  useVideoChapters,
} from "src/components/features/VideoTrimmer/providers/VideoChaptersProvider/VideoChaptersContext";

interface PlayerActionButtonsProps {
  formatStr: string;
}

export default function TrimmedVideoTimer({ formatStr }: PlayerActionButtonsProps) {
  const { chapters } = useVideoChapters(); // prettier-ignore

  const trimmedVideoDuration = useMemo(() => sumBy(timeIntervalUtils.duration, chapters) || 0, [chapters]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{`${formatSeconds(trimmedVideoDuration, formatStr)}`}</>
  );
}
