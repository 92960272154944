import axios, { Axios } from "axios";
import { EventEmitter } from "events";
import { ClientError, GraphQLClient } from "graphql-request";
import { isError } from "lodash/fp";
import Papa from "papaparse";

import * as gql from "src/network/graphql/generatedGraphqlSDK";
import AudioSample from "src/types/media.types";

import { AppConfig } from "src/components/providers/AppConfigProvider";
import { API_SERVER_URL, API_V2_SERVER_URL } from "src/constants/env.constants";
import errorMonitoringService from "src/services/ErrorMonitoring.service";
import { YoutubeVideoListResponse } from "src/types/youtube-api.types";
import { V2_Word } from "src/network/graphql/generatedGraphqlSDK";

type Events = {
  error(statusCode: number | undefined, error: Error): void;
};

class ApiClient {
  private readonly eventEmitter = new EventEmitter<Events>();

  private mHttpClient?: Axios;
  private mGraphqlClient?: gql.Sdk;
  private mGraphqlClientV2?: gql.Sdk;

  private graphqlServerURL?: string;
  private graphqlServerURLV2?: string;
  private ingestServerURL?: string;
  private assetsContentServerURL?: string;
  private googleAPIKey?: string;

  isClientError = (error: Error): error is ClientError => !!(error as ClientError).request;

  on: EventEmitter<Events>["on"] = (...args) => this.eventEmitter.on(...args);
  off: EventEmitter<Events>["off"] = (...args) => this.eventEmitter.off(...args);
  once: EventEmitter<Events>["once"] = (...args) => this.eventEmitter.once(...args);

  config(appConfig: AppConfig) {
    this.graphqlServerURL = `${API_SERVER_URL}${appConfig.API_URL}`;
    this.graphqlServerURLV2 = `${API_V2_SERVER_URL}${appConfig.API_URL}`;
    this.ingestServerURL = appConfig.INGEST_URL;
    this.assetsContentServerURL = appConfig.CONTENT_URL;
    this.googleAPIKey = appConfig.GOOGLE_API_KEY;
  }

  init(authToken?: string) {
    const headers = authToken ? { Authorization: `Bearer ${authToken}` } : undefined;

    const httpClient = new Axios({ headers, timeout: 60000 });

    httpClient.interceptors.response.use(
      (response) => response,
      (error) => {
        errorMonitoringService.trackError(error);

        if (axios.isAxiosError(error)) {
          this.eventEmitter.emit("error", error.status, error);
        }

        return Promise.reject(error);
      },
    );

    const graphQLClient = new GraphQLClient(this.graphqlServerURL!, {
      headers,
      mode: "cors",
      responseMiddleware: (responseOrError) => {
        if (isError(responseOrError)) {
          errorMonitoringService.trackError(responseOrError);

          if (this.isClientError(responseOrError)) {
            this.eventEmitter.emit("error", responseOrError.response.status, responseOrError);
          }
        }
      },
    });

    const graphQLClientV2 = new GraphQLClient(this.graphqlServerURLV2!, {
      headers,
      mode: "cors",
      responseMiddleware: (responseOrError) => {
        if (isError(responseOrError)) {
          errorMonitoringService.trackError(responseOrError);

          if (this.isClientError(responseOrError)) {
            this.eventEmitter.emit("error", responseOrError.response.status, responseOrError);
          }
        }
      },
    });

    this.mHttpClient = httpClient;
    this.mGraphqlClient = gql.getSdk(graphQLClient);
    this.mGraphqlClientV2 = gql.getSdk(graphQLClientV2);
  }

  get httpClient() {
    if (!this.mHttpClient) {
      throw new Error("ApiClient used before initialization");
    }

    return this.mHttpClient;
  }

  get graphqlClient() {
    if (!this.mGraphqlClient) {
      throw new Error("ApiClient used before initialization");
    }

    return this.mGraphqlClient;
  }

  get graphqlClientV2() {
    if (!this.mGraphqlClientV2) {
      throw new Error("ApiClient used before initialization");
    }

    return this.mGraphqlClientV2;
  }

  async loginUser(variables: gql.LoginUserQueryVariables) {
    const data = await this.graphqlClient.loginUser(variables);
    return data.user?.login;
  }

  async loginUserOAuth(variables: gql.LoginUserOAuthQueryVariables) {
    const data = await this.graphqlClient.loginUserOAuth(variables);
    return data.user?.verifyLogin;
  }

  async getUser() {
    const data = await this.graphqlClient.getUser();
    return data.user as gql.User;
  }

  async createUser(variables: gql.CreateUserMutationVariables) {
    const data = await this.graphqlClient.createUser(variables);
    return data.user?.add as gql.User;
  }

  async updateUser(variables: gql.UpdateUserMutationVariables) {
    const data = await this.graphqlClient.updateUser(variables);
    return data.user?.update as gql.User;
  }

  async updateUserPassword(variables: gql.UpdateUserPasswordQueryVariables) {
    const data = await this.graphqlClient.updateUserPassword(variables);
    return data.user?.setPassword;
  }

  async resetUserPassword(variables: gql.ResetUserPasswordMutationVariables) {
    const data = await this.graphqlClient.resetUserPassword(variables);
    return data.user?.requestResetPassword;
  }

  async getSequences(variables: gql.GetSequencesQueryVariables) {
    const data = await this.graphqlClient.getSequences(variables);
    return (data.sequence?.list ?? []) as gql.Sequence[];
  }

  async getSequence(variables: gql.GetSequenceQueryVariables) {
    const data = await this.graphqlClient.getSequence(variables);
    return data.sequence as gql.Sequence;
  }

  async getSequencesByFootageId(variables: gql.GetSequencesByFootageIdQueryVariables) {
    const data = await this.graphqlClient.getSequencesByFootageId(variables);
    return (data.sequence?.list ?? []) as gql.Sequence[];
  }

  async getSequenceProcessingStatusProps(variables: gql.GetSequenceProcessingStatusPropsQueryVariables) {
    const data = await this.graphqlClient.getSequenceProcessingStatusProps(variables);
    return data.sequence as gql.Sequence;
  }

  async createSequence(variables: gql.CreateSequenceMutationVariables) {
    const data = await this.graphqlClient.createSequence(variables);
    return data.sequence?.add as gql.Sequence;
  }

  async updateSequence(variables: gql.UpdateSequenceMutationVariables) {
    const data = await this.graphqlClient.updateSequence(variables);
    return data.sequence?.update as gql.Sequence;
  }

  async applyPresetToSequence(variables: gql.ApplyPresetToSequenceMutationVariables) {
    const data = await this.graphqlClient.applyPresetToSequence(variables);
    return data.sequence?.updatePreset as gql.Sequence;
  }

  async validateInputs(variables: gql.ValidateInputsMutationVariables) {
    const data = await this.graphqlClient.validateInputs(variables);
    return data.sequence?.validateInputs as gql.Sequence;
  }

  async addChapter(variables: gql.AddChapterMutationVariables) {
    const data = await this.graphqlClient.addChapter(variables);
    return data.sequence?.addChapter as gql.Chapter;
  }

  async renameSequence(variables: gql.RenameSequenceMutationVariables) {
    const data = await this.graphqlClient.renameSequence(variables);
    return data.sequence?.update?.sid as gql.Sequence;
  }

  async deleteSequence(variables: gql.DeleteSequenceMutationVariables) {
    const data = await this.graphqlClient.deleteSequence(variables);
    return data.sequence?.remove?.valueOf as gql.Sequence;
  }

  async duplicateSequence(variables: gql.DuplicateSequenceMutationVariables) {
    const data = await this.graphqlClient.duplicateSequence(variables);
    return data.sequence?.clone?.sid as gql.Sequence;
  }

  async createSequenceFromTemplate() {
    const data = await this.graphqlClient.createSequenceFromTemplate();
    return data.sequence?.createFromTemplate as gql.Sequence;
  }

  async reorderSequenceChapters(variables: gql.ReorderSequenceChaptersMutationVariables) {
    const data = await this.graphqlClient.reorderSequenceChapters(variables);
    return data.sequence?.reorderScenes as gql.Sequence;
  }

  async getSequenceCaptions(variables: gql.GetSequenceCaptionsQueryVariables) {
    const data = await this.graphqlClient.getSequenceCaptions(variables);
    return data.closedCaption?.listWords as gql.Word[];
  }

  async getSequenceCaptionsV2(variables: gql.GetV2SequenceCaptionsQueryVariables) {
    const data = await this.graphqlClientV2.getV2SequenceCaptions(variables);
    return data.closedCaptions[0];
  }

  async unsyncWithTranscript(variables: gql.UnsyncClosedCaptionsWithTranscriptMutationVariables) {
    const data = await this.graphqlClientV2.unsyncClosedCaptionsWithTranscript({ sequenceSid: variables.sequenceSid });
    return data.unsyncClosedCaptionsWithTranscript.syncWithTranscript;
  }

  async regenerateSequenceCaptions(variables: gql.RegenerateSequenceCaptionsMutationVariables) {
    const data = await this.graphqlClientV2.regenerateSequenceCaptions(variables);
    return data.regenerateClosedCaptions.words as V2_Word[];
  }

  async getWords(variables: gql.GetWordsByExternalAssetIdQueryVariables) {
    const data = await this.graphqlClientV2.getWordsByExternalAssetId(variables);
    return data.getWordsByExternalAssetId;
  }

  async updateWords(variables: gql.UpdateBulkOfWordsInTranscriptMutationVariables) {
    const data = await this.graphqlClientV2.updateBulkOfWordsInTranscript(variables);
    return data.updateBulkOfWordsInTranscript;
  }

  async getPresets(variables: gql.GetPresetsQueryVariables) {
    const data = await this.graphqlClient.getPresets(variables);
    return data.preset?.list as gql.Preset[];
  }

  async createPreset(variables: gql.CreatePresetMutationVariables) {
    const data = await this.graphqlClient.createPreset(variables);
    return data.preset?.add as gql.Preset;
  }

  async updatePreset(variables: gql.UpdatePresetMutationVariables) {
    const data = await this.graphqlClient.updatePreset(variables);
    return data.preset?.update as gql.Preset;
  }

  async deletePreset(variables: gql.DeletePresetMutationVariables) {
    const data = await this.graphqlClient.deletePreset(variables);
    return data.preset?.remove;
  }

  async setDefaultPreset(variables: gql.SetDefaultPresetMutationVariables) {
    const data = await this.graphqlClient.setDefaultPreset(variables);
    return data.preset?.setDefault as gql.Preset;
  }

  async getFolderStructures(variables: gql.GetFolderStructuresQueryVariables) {
    const data = await this.graphqlClient.getFolderStructures(variables);
    return (data.folderStructure?.list ?? []) as gql.FolderStructure[];
  }

  async getAssets(variables: gql.GetAssetsQueryVariables) {
    const data = await this.graphqlClient.getAssets(variables);
    return (data.asset?.list ?? []) as gql.Asset[];
  }

  async getAsset(variables: gql.GetAssetQueryVariables) {
    const data = await this.graphqlClient.getAsset(variables);
    return data.asset as gql.Asset;
  }

  async createAsset(variables: gql.CreateAssetMutationVariables) {
    const data = await this.graphqlClient.createAsset(variables);
    return data.asset?.add as gql.Asset;
  }

  async updateAsset(variables: gql.UpdateAssetMutationVariables) {
    const data = await this.graphqlClient.updateAsset(variables);
    return data.asset?.update as gql.Asset;
  }

  async setAssetContent(assetId: string, content: File | Blob) {
    const formData = new FormData();

    formData.append("sid", assetId);
    formData.append("buffer", content);

    return this.httpClient.post("/asset", formData, { baseURL: this.ingestServerURL });
  }

  async getAssetContent(assetId: string, version = 0, variant = "16-9") {
    return this.httpClient.get<string>(
      `${this.assetsContentServerURL}/c/t/asset/f/${assetId}/${variant}/${version}.json`,
    );
  }

  async getSpeakerCuePoints(variables: gql.GetSpeakerCuePointsQueryVariables) {
    const data = await this.graphqlClient.getSpeakerCuePoints(variables);
    return (data.speakerCuePoint?.list ?? []) as gql.SpeakerCuePoint[];
  }

  async updateSpeakerCuePoint(variables: gql.UpdateSpeakerCuePointMutationVariables) {
    await this.graphqlClient.updateSpeakerCuePoint(variables);
  }

  async removeSpeakerCuePoint(variables: gql.RemoveSpeakerCuePointMutationVariables) {
    await this.graphqlClient.removeSpeakerCuePoint(variables);
  }

  async activateSpeakerCuePoint(variables: gql.ActivateSpeakerCuePointMutationVariables) {
    await this.graphqlClient.activateSpeakerCuePoint(variables);
  }

  async deactivateSpeakerCuePoint(variables: gql.DeactivateSpeakerCuePointMutationVariables) {
    await this.graphqlClient.deactivateSpeakerCuePoint(variables);
  }

  async getPlans(variables: gql.GetPlansQueryVariables) {
    const data = await this.graphqlClient.getPlans(variables);
    return (data.plan?.list ?? []) as gql.Plan[];
  }

  async getPlan() {
    const data = await this.graphqlClient.getPlan();
    return data.plan as gql.Plan;
  }

  async selectPlan(variables: gql.SelectPlanMutationVariables) {
    const data = await this.graphqlClient.selectPlan(variables);
    return data.plan?.select as gql.Subscription;
  }

  async getSubscription() {
    const data = await this.graphqlClient.getSubscription();
    return data.subscription as gql.Subscription;
  }

  async getShopper() {
    const data = await this.graphqlClient.getShopper();
    return data.shopper as gql.Shopper;
  }

  async getChapterAudioSamplesRMS(sequenceSid: string, chapter: gql.Chapter) {
    const res = await this.httpClient.get<string>(
      `/vol/${sequenceSid}/d/${chapter.sid}-${chapter.audio?.sid}/1/volume_map.csv`,
      { baseURL: API_SERVER_URL },
    );

    return Papa.parse<[timestamp: string, rms: string]>(res.data)
      .data.slice(1, -1)
      .map<AudioSample>(([timestamp, rms]) => ({
        timestamp: parseFloat(timestamp),
        rms: parseFloat(rms),
      }));
  }

  async createFootage(variables: gql.MutationCreateOneFootageArgs) {
    const data = await this.graphqlClientV2.createFootage(variables);
    return data.createOneFootage as gql.Footage;
  }

  async updateFootage(variables: gql.UpdateFootageMutationVariables) {
    const data = await this.graphqlClientV2.updateFootage(variables);
    return data.updateOneFootage as gql.Footage;
  }

  async generateFootageUploaderUrl(variables: gql.GenerateFootageUploaderUrlMutationVariables) {
    const data = await this.graphqlClientV2.generateFootageUploaderUrl(variables);
    return data.generateUploadUrl as gql.Scalars["String"];
  }

  async getFootages(variables: gql.GetFootagesQueryVariables) {
    const data = await this.graphqlClientV2.getFootages(variables);
    return data.footages as gql.FootageConnection;
  }

  async getFootagesByBoardId(variables: gql.GetFootagesByBoardIdQueryVariables) {
    const data = await this.graphqlClientV2.getFootagesByBoardId(variables);
    return data.getFootagesByBoardId as gql.FootageConnection;
  }

  async getFootage(variables: gql.GetFootageWithClipSequenceFavoritesQueryVariables) {
    const data = await this.graphqlClientV2.getFootageWithClipSequenceFavorites(variables);
    return data.footageWithClipSequenceFavorites as gql.Footage;
  }

  async getFootageTranscript(variables: gql.GetFootageTranscriptQueryVariables) {
    const data = await this.graphqlClientV2.getFootageTranscript(variables);
    return data?.footageWithClipSequenceFavorites?.transcriptRef as gql.Transcript;
  }

  async deleteFootage(variables: gql.DeleteFootageMutationVariables) {
    const data = await this.graphqlClientV2.deleteFootage(variables);
    return data.deleteOneFootage as gql.Footage;
  }

  async updateClipSequence(variables: gql.UpdateClipSequenceMutationVariables) {
    const data = await this.graphqlClientV2.updateClipSequence(variables);
    return data.updateClipSequence as gql.ClipSequence;
  }

  async DeleteClipSequence(variables: gql.DeleteClipSequenceMutationVariables) {
    const data = await this.graphqlClientV2.deleteClipSequence(variables);
    return data.deleteClipSequence as gql.ClipSequence;
  }

  async cloneClipSequence(variables: gql.CloneClipSequenceMutationVariables) {
    const data = await this.graphqlClientV2.cloneClipSequence(variables);
    return data.cloneClipSequence as gql.ClipSequence;
  }

  async translateClipSequence(variables: gql.TranslateClipSequenceMutationVariables) {
    const data = await this.graphqlClientV2.translateClipSequence(variables);
    return data.translateClipSequence as gql.ClipSequence;
  }

  async replaceExampleClipSequence(variables: gql.ReplaceExampleClipSequenceMutationVariables) {
    const data = await this.graphqlClientV2.replaceExampleClipSequence(variables);
    return data.replaceExampleClipSequence as gql.ClipSequence;
  }

  async renameClipSequence(variables: gql.RenameClipSequenceMutationVariables) {
    const data = await this.graphqlClientV2.renameClipSequence(variables);
    return data.renameClipSequence as gql.ClipSequence;
  }

  async addClipSequenceFavorite(variables: gql.AddClipSequenceFavoriteMutationVariables) {
    const data = await this.graphqlClientV2.addClipSequenceFavorite(variables);
    return data.addClipSequenceFavorite as gql.ClipSequence;
  }

  async deleteClipSequenceFavorite(variables: gql.DeleteClipSequenceFavoriteMutationVariables) {
    const data = await this.graphqlClientV2.deleteClipSequenceFavorite(variables);
    return data.deleteClipSequenceFavorite as gql.ClipSequence;
  }

  async interactClipSequence(variables: gql.InteractClipSequenceMutationVariables) {
    const data = await this.graphqlClientV2.interactClipSequence(variables);
    return data.interactClipSequence as gql.ClipSequence;
  }

  async getVideoTypes() {
    const data = await this.graphqlClient.getVideoTypes();
    return (data.videoType?.list ?? []) as gql.VideoType[];
  }

  async getEditRules() {
    const data = await this.graphqlClient.getEditRules();
    return (data.editRules?.list ?? []) as gql.EditRules[];
  }

  async getYoutubeVideo(videoId: string) {
    const { data } = await axios.get<YoutubeVideoListResponse>(
      `https://www.googleapis.com/youtube/v3/videos/?key=${this.googleAPIKey}&part=snippet,contentDetails&id=${videoId}`,
    );

    return data;
  }

  async getDownloadsBalance() {
    const data = await this.graphqlClientV2.getBalance();
    return data.getDownloadsBalance as gql.DownloadsBalance;
  }

  async getDownloadInfo(variables: gql.GetDownloadInfoQueryVariables) {
    const data = await this.graphqlClientV2.getDownloadInfo(variables);
    return data.downloadInfo as gql.DownloadInfo;
  }

  async getDownloadInfos(variables: gql.GetDownloadInfosQueryVariables) {
    const data = await this.graphqlClientV2.getDownloadInfos(variables);
    return data.downloadInfos as gql.DownloadInfoConnection;
  }

  async getCropDetails(variables: gql.GetCropDetailsQueryVariables) {
    const data = await this.graphqlClientV2.getCropDetails(variables);
    return data.cropDetails as gql.CropDetailConnection;
  }

  async updateCropDetails(variables: gql.UpdateAssetCropDataMutationVariables) {
    const data = await this.graphqlClientV2.updateAssetCropData(variables);
    return data.updateAssetCropData as gql.Asset;
  }

  async updateCropStyle(variables: gql.MutationUpdateBackgroundDesignArgs) {
    const data = await this.graphqlClientV2.updateBackgroundDesign(variables);
    return data;
  }

  async splitFootageByUserTimestamps(variables: gql.SplitFootageByUserTimestampsMutationVariables) {
    const data = await this.graphqlClientV2.splitFootageByUserTimestamps(variables);
    return data;
  }

  async getBoards(variables: gql.GetBoardsQueryVariables) {
    const data = await this.graphqlClientV2.getBoards(variables);
    return data.boards;
  }

  async createBoard(variables: gql.CreateBoardMutationVariables) {
    const data = await this.graphqlClientV2.createBoard(variables);
    return data.createOneBoard;
  }

  async updateBoard(variables: gql.UpdateBoardMutationVariables) {
    const data = await this.graphqlClientV2.updateBoard(variables);
    return data.updateOneBoard;
  }

  async deleteBoard(variables: gql.DeleteBoardMutationVariables) {
    const data = await this.graphqlClientV2.deleteBoard(variables);
    return data.deleteOneBoard;
  }

  async removeFootageFromBoard(variables: gql.RemoveFootageFromBoardMutationVariables) {
    const success = await this.graphqlClientV2.removeFootageFromBoard(variables);
    return success;
  }

  async moveFootageToBoard(variables: gql.MoveFootageToBoardMutationVariables) {
    const success = await this.graphqlClientV2.moveFootageToBoard(variables);
    return success;
  }

  async getFootageTranscriptText(footageId: string, authToken: string) {
    const transcriptBlob = axios.get(`${API_V2_SERVER_URL}/transcripts/footage/${footageId}/text`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
        "x-show-all-users-data": authToken,
      },
      responseType: "blob",
    });
    return transcriptBlob;
  }
}

const apiClient = new ApiClient();

export default apiClient;
