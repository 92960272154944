import LogRocket from "logrocket";

import { APP_ENV } from "src/constants/env.constants";
import { AppConfig } from "src/components/providers/AppConfigProvider";
import { User } from "src/network/graphql/generatedGraphqlSDK";

import blockServiceForEnv from "src/services/blockServiceForEnv";
import BaseService from "src/services/Base.service";

@blockServiceForEnv(["local"])
class AppMonitoringService implements BaseService {
  init(appConfig: AppConfig) {
    LogRocket.init(appConfig.LOGROCKET_APP_ID, {
      shouldParseXHRBlob: true,
      mergeIframes: true,
      childDomains: ["*"],
    });
  }

  identify(user: User) {
    LogRocket.identify(user.sid!, {
      firstName: user.firstName!,
      lastName: user.lastName!,
      email: user.email!,
      created: user.insertTime!,
      environment: APP_ENV,
    });
  }

  getSessionURL() {
    return new Promise<string>((resolve) => {
      LogRocket.getSessionURL(resolve);
    });
  }

  log(...args: any[]) {
    LogRocket.log(...args);
  }

  error(...args: any[]) {
    LogRocket.error(...args);
  }

  track(event: string, data?: Record<string, any>) {
    LogRocket.track(event, data);
  }
}

const appMonitoringService = new AppMonitoringService();

export default appMonitoringService;
