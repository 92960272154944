import { useState } from "react";
import { Link } from "react-router-dom";

import styled from "styled-components/macro";

import { themeColor } from "src/utils/styledComponents.utils";

import Icon from "src/components/common/Icon/Icon";
import Popper from "src/components/common/popovers/Popper";
import { HELP_CENTER, NEWS_FEED } from "src/constants/url.constants";
import userEngagementService from "src/services/UserEngagement.service";
import { absoluteRoutes } from "src/utils/routes.utils";

const ContactMenuContainer = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  background-color: transparent;

  svg {
    color: ${themeColor("blue.500")};
  }

  &:hover {
    background-color: ${themeColor("blue.50")};
  }
  transition: background-color 0.5s ease;
`;

const ContactMenu = styled.div`
  box-shadow: 0px 4px 16px 0px rgba(4, 27, 63, 0.2);
  border-radius: 10px;
  background-color: ${themeColor("white")};
  overflow: hidden;
`;

const TextBlock = styled.div`
  width: 160px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Open Sans", sans-serif;
  margin-block: 4px;
`;

const ThinText = styled(TextBlock)`
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: ${themeColor("gray.900")};
`;

const ContactMenuItemLink = styled(Link)`
  width: 100%;
  max-width: 320px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 30px;
  margin-block: 6px;
  padding-inline: 10px;
  text-decoration: none;
  svg {
    margin-right: 5px;
    color: ${themeColor("blue.600")};
  }
  cursor: pointer;
  &:hover {
    background-color: ${themeColor("blue.50")};
  }
  transition: background-color 0.5s ease;
`;

//  TODO: change
const LINKS = {
  helpCenter: HELP_CENTER,
  newFeed: NEWS_FEED,
};

export default function ContactMenuPopover() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popper
      isOpen={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      offsetY={5}
      placement="bottom-end"
      content={
        <ContactMenu onClick={() => setIsOpen(false)}>
          <ContactMenuItemLink to={LINKS.helpCenter} target="blank">
            <Icon.HelpCenter /> <ThinText>Help Center</ThinText>
          </ContactMenuItemLink>
          <ContactMenuItemLink to={LINKS.newFeed} target="blank">
            <Icon.NewsFeed /> <ThinText>News Feed</ThinText>
          </ContactMenuItemLink>
          <ContactMenuItemLink to="#" onClick={() => userEngagementService.show()}>
            <Icon.ContactCenter /> <ThinText>Contact Support</ThinText>
          </ContactMenuItemLink>
          <ContactMenuItemLink to={absoluteRoutes.plans}>
            <Icon.Pricing /> <ThinText>Pricing</ThinText>
          </ContactMenuItemLink>
        </ContactMenu>
      }
    >
      <ContactMenuContainer>
        <Icon.Sandvich />
      </ContactMenuContainer>
    </Popper>
  );
}
