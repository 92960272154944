/* eslint-disable no-confusing-arrow */

import { ReactNode, useCallback } from "react";
import styled from "styled-components/macro";

import PEECH_THUMB from "src/assets/images/peech_thumbnail.png";
import { ifProp, themeColor } from "src/utils/styledComponents.utils";
import { AspectRatio, getAspectRatioValue } from "src/constants/video.constants";
import Icon from "src/components/common/Icon";

const AspectRatioWrapper = styled.div<{ isSelected: boolean }>`
  position: relative;
  margin: 11px;
  border: solid 1px ${ifProp("isSelected", themeColor("pink.500"), themeColor("gray.300"))};
  box-shadow: ${ifProp("isSelected", "1px 5px 10px rgba(0, 0, 0, 0.3)", "unset")};
  border-radius: 10px;
  height: 240px;
  width: 250px;
  padding: 21px 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: ${themeColor("white")};
  cursor: pointer;
  transition: border-color 250ms ease-out, box-shadow 250ms ease-out;

  &:hover {
    box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.3);
  }

  @media (min-width: 425px) {
    height: 270px;
    width: 280px;
    padding: 21px 15px;
  }
`;

const Title = styled.div`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
  color: ${themeColor("gray.900")};
  text-align: center;
`;

const IconsWrapper = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  svg {
    margin-inline: 8px;
  }
`;

const PictureBlock = styled.div<{ aspectRatio: AspectRatio }>`
  height: 144px;
  aspect-ratio: ${(props) => getAspectRatioValue(props.aspectRatio)};
  border-radius: 10px;
  margin-block: 15px;
  position: relative;

  img {
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    aspect-ratio: ${(props) => getAspectRatioValue(props.aspectRatio)};
    border-radius: 5px;
    object-fit: cover;
  }
`;

export const ImagePlug = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  border: solid 1px ${themeColor("gray.200")};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 99%;
  height: 99%;

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    max-height: 100%;
  }
`;

const DurationLabel = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  height: 26px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${themeColor("white")};
  background-color: ${themeColor("pink.500")};
  border-radius: 40px;
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
`;

export const ASPECT_RATIO_DICTIONARY: Record<AspectRatio, { text: string; icons: ReactNode[] }> = {
  "16:9": {
    text: "Landscape",
    icons: [
      <Icon.InstagramSmall key="InstagramSmall" />,
      <Icon.VimeoSmall key="VimeoSmall" />,
      <Icon.YoutubeSmall key="YoutubeSmall" />,
      <Icon.LinkedinSmall key="LinkedinSmall" />,
      <Icon.FacebookSmall key="FacebookSmall" />,
    ],
  },

  "1:1": {
    text: "Square",
    icons: [
      <Icon.FacebookSmall key="FacebookSmall" />,
      <Icon.InstagramSmall key="InstagramSmall" />,
      <Icon.LinkedinSmall key="LinkedinSmall" />,
      <Icon.PinterestSmall key="PinterestSmall" />,
    ],
  },

  "9:16": {
    text: "Portrait",
    icons: [
      <Icon.InstagramSmall key="InstagramSmall" />,
      <Icon.TiktokSmall key="TiktokSmall" />,
      <Icon.ShortsSmall key="ShortsSmall" />,
      <Icon.InstagramReels key="InstagramReels" />,
    ],
  },
};

interface AspectRatioExampleProps {
  aspectRatio: AspectRatio;
  onRatioSelected: (aspectRatio: AspectRatio) => void;
  isRatioSelected: (aspectRatio: AspectRatio) => boolean;
  thumbnailUrl: string | null;
}

export default function AspectRatioExample({
  aspectRatio,
  onRatioSelected,
  isRatioSelected,
  thumbnailUrl,
}: AspectRatioExampleProps) {
  const onRatioItemClick = useCallback(() => onRatioSelected?.(aspectRatio), [onRatioSelected, aspectRatio]);

  return (
    <AspectRatioWrapper onClick={onRatioItemClick} isSelected={isRatioSelected(aspectRatio)}>
      <Title>{ASPECT_RATIO_DICTIONARY[aspectRatio].text}</Title>

      <PictureBlock aspectRatio={aspectRatio}>
        <ImagePlug>
          <img src={PEECH_THUMB} alt="logo" />
        </ImagePlug>

        {thumbnailUrl && (
          <img
            src={thumbnailUrl}
            alt={aspectRatio}
            draggable={false}
            onError={(e) => {
              e.currentTarget.style.display = "none";
            }}
          />
        )}

        <DurationLabel>{aspectRatio}</DurationLabel>
      </PictureBlock>

      <IconsWrapper>{ASPECT_RATIO_DICTIONARY[aspectRatio].icons.map((icon) => icon)}</IconsWrapper>
    </AspectRatioWrapper>
  );
}
