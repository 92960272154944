import styled from "styled-components/macro";

import { themeColor } from "src/utils/styledComponents.utils";

import Icon from "src/components/common/Icon";
import { Button } from "src/components/common/buttons/Button.styled";

const StyledRainbowButton = styled(Button)`
  span {
    font-size: 16px;
    line-height: 20px;
    font-family: "Open Sans", sans-serif;
    text-transform: capitalize;
    font-weight: 600;
    color: ${themeColor("white")};
  }
  svg {
    flex: 0 0 auto;
    width: 24px;
    height: 20px;
    margin-right: 10px;
  }
`;

export interface RainbowButtonProps {
  label: string;
  showIcon: boolean;
  onClick?: () => void;
}

export function RainbowButton({ label, showIcon, onClick }: RainbowButtonProps) {
  return (
    <StyledRainbowButton onClick={() => onClick?.()} variant="rainbow" size="large">
      {showIcon ? <Icon.Diamond /> : null}
      <span>{label}</span>
    </StyledRainbowButton>
  );
}
