import Tooltip from "src/components/common/popovers/Tooltip";
import { AspectRatio, getAspectRatioValue } from "src/constants/video.constants";
import { themeColor } from "src/utils/styledComponents.utils";
import styled from "styled-components/macro";
import { ifProp } from "styled-tools";

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: ${themeColor("blue.30")};
  overflow: hidden;
`;

export const SequencesContainer = styled(Container)`
  background-color: ${themeColor("blue.30")};
`;

export const TopBlock = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  margin: 36px 0 4px 0;
  padding-inline: 30px;
`;

export const UploadsCounter = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: ${themeColor("gray.400")};
  margin-top: 14px;
`;

export const GridContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, minmax(100px, 1fr));
  column-gap: 18px;
  row-gap: 23px;
  margin-block: 33px;

  @media (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1440px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (min-width: 1800px) {
    grid-template-columns: repeat(6, 1fr);
  }
`;

export const GridItem = styled.div`
  background-color: transparent;
  overflow: hidden;
`;

export const ScrolableContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  overflow: auto;
  padding-inline: 30px;
  padding-bottom: 30px;
`;

export const PrevItemContentContainer = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  top: 0px;
  left: 0px;
`;

export const ProgressContainer = styled.div<{ aspectRatio: AspectRatio }>`
  height: 100%;
  aspect-ratio: ${(props) => getAspectRatioValue(props.aspectRatio)};

  padding: 17px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  position: relative;

  .label {
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: ${themeColor("white")};
    margin-bottom: 4px;
  }
`;

export const BluredPlug = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${themeColor("blue.1200")};
  opacity: 60%;
`;

export const StatusDiv = styled.div`
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
  color: ${themeColor("white")};
  margin-block: 16px 6px;
`;

export const StatusTime = styled.div`
  font-family: "Open Sans";
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  color: ${themeColor("blue.200")};
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-right: 6px;
    margin-bottom: 3px;
  }
`;

export const DarkOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.5;
  background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 30.02%);
`;

export const StyledTooltip = styled(Tooltip)<{ isOverflowing?: boolean; disabled?: boolean }>`
  display: block !important;
  overflow: hidden;
  text-overflow: ${ifProp("isOverflowing", "ellipsis", "unset")};
  white-space: pre;
  cursor: ${ifProp("disabled", "unset", "pointer")};
`;

export const StyledExpiredTooltip = styled(StyledTooltip)`
  width: 160px;
`;

export const ContactSupportBtn = styled.span`
  font-family: "Open Sans";
  color: ${themeColor("blue.500")};
  margin-left: 6px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const FailedTooltipInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  span {
    font-size: 12px;
    font-weight: 600;
  }
  ${ContactSupportBtn} {
    font-size: 9px;
    font-weight: 600;
  }
  ul {
    padding-left: 20px;
  }
`;

export const TitleWrapper = styled.div`
  overflow: hidden;
  text-overflow: inherit;
  white-space: pre;
`;

export const DurationBlock = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  min-height: 24px;
  border-radius: 10px;
  background: ${themeColor("gray.900")};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Open Sans";
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  color: ${themeColor("white")};
  padding: 4px 7px;
`;

export const ExpireBlockWrapper = styled.div`
  position: absolute;
  min-height: 24px;
  bottom: 10px;
  left: 10px;
`;

export const ExpireBlock = styled(DurationBlock)`
  right: unset;
  position: relative;
  bottom: unset;
  span {
    font-family: "Open Sans";
    font-size: 10px;
    font-weight: 600;
    line-height: 14px;
    margin-right: 4px;
  }
`;

export const FailedMark = styled(DurationBlock)`
  top: 10px;
  bottom: unset;
  padding-left: 8px;

  span {
    color: red;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 4px;
  }
  svg {
    width: 14px;
    height: auto;
  }
`;

export const RedirectToPlanButton = styled.div<{ isBig?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${ifProp("isBig", "6px 14px", "4px 10px")};
  background-color: ${themeColor("pink.500")};
  color: ${themeColor("white")};
  border-radius: 50px;
  cursor: pointer;
  margin-top: 8px;
  font-size: ${ifProp("isBig", "12px", "10px")};

  &:active {
    background-color: ${themeColor("pink.400")};
  }
`;

export const VerticalDivider = styled.div`
  display: block;
  position: relative;
  margin: 0 10px;
  width: 1px;
  height: 70%;
  @media (max-width: 1024px) {
    height: 80%;
  }
  background-color: ${themeColor("blue.1500")};
`;
