/* eslint-disable no-console */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-await-in-loop */

import { RefObject, useCallback, useEffect, useRef, VideoHTMLAttributes } from "react";
import { useSelector } from "react-redux";

import { RootState } from "src/models/store";
import ShakaPlayer from "src/components/common/media/ShakaVideoPlayer/shaka-player.utils";
import useVariableRef from "src/hooks/useVariableRef";
import { wait } from "src/utils/promise.utils";
import { FOOTAGE_PREVIEW_PATH } from "src/constants/url.constants";

interface Config {
  streaming: {
    forceHTTPS: boolean;
    bufferBehind: number;
    bufferingGoal: number;
    durationBackoff: number;
    safeSeekOffset: number;
  };
}

interface Props extends VideoHTMLAttributes<HTMLVideoElement> {
  videoRef: RefObject<HTMLVideoElement>;
  cached?: boolean;
  config?: DeepPartial<Config>;
  onShakaError?(error: shaka.extern.Error): void;
}

export default function ShakaVideoPlayer({ src, videoRef, cached, config = {}, onShakaError, ...props }: Props) {
  const playerRef = useRef<shaka.Player | null>(null);
  const storageRef = useRef<shaka.offline.Storage | null>(null);
  const configRef = useRef(config);
  const srcRef = useVariableRef(src);
  const authToken = useSelector((state: RootState) => state.session.authToken);

  const onError = useCallback(
    (error: shaka.extern.Error) => {
      console.error("Error code", error.code, "object", error);
      onShakaError?.(error);
    },
    [onShakaError],
  );

  const createStorage = useCallback(
    (player: shaka.Player) => {
      const storage = new ShakaPlayer.offline.Storage(player);

      const findCachedContent = async (originalManifestUri: string) => {
        const cachedContentList = await storage.list();
        return cachedContentList.find((content) => content.originalManifestUri === originalManifestUri);
      };

      const progressCallback = async (uploadInProgress: shaka.extern.StoredContent, percentage: number) => {
        if (percentage < 1) {
          return;
        }

        let loaded = false;

        while (uploadInProgress.originalManifestUri === srcRef.current && !loaded) {
          const cachedContent = await findCachedContent(uploadInProgress.originalManifestUri);

          if (cachedContent && !cachedContent.isIncomplete && cachedContent.offlineUri) {
            const shouldReplay = videoRef.current && !videoRef.current.paused;

            player
              .load(cachedContent.offlineUri, videoRef.current?.currentTime ?? 0)
              .then(() => {
                if (shouldReplay) {
                  videoRef.current?.play();
                }
              })
              .catch(onError);
            loaded = true;
          } else {
            await wait(1000);
          }
        }
      };

      const trackSelectionCallback = (tracks: Array<{ type: string; bandwidth: number }>) => {
        // This example stores the highest bandwidth variant.
        // Note that this is just an example of an arbitrary algorithm, and not a best
        // practice for storing content offline.  Decide what your app needs, or keep
        // the default (user-pref-matching audio, best SD video, all text).
        const found = tracks
          .filter((track) => track.type === "variant")
          .sort((a, b) => a.bandwidth - b.bandwidth)
          .pop();

        return [found];
      };

      storage.configure({
        offline: {
          progressCallback,
          trackSelectionCallback,
        },
      });

      return storage;
    },
    [onError, srcRef, videoRef],
  );

  const createPlayer = useCallback(
    (video: HTMLVideoElement) => {
      ShakaPlayer.polyfill.installAll();

      const onErrorEvent = (event: any) => onError(event.detail);

      const requestFilter: shaka.extern.RequestFilter = (type, request) => {
        if (request.uris?.[0] && !request.uris[0].includes(FOOTAGE_PREVIEW_PATH)) {
          request.headers.Authorization = `Bearer ${authToken}`;
        }
      };

      const player = new ShakaPlayer.Player();
      player.attach(video);

      player.configure(configRef.current);
      player.getNetworkingEngine()?.registerRequestFilter(requestFilter);
      player.addEventListener("error", onErrorEvent);

      return player;
    },
    [authToken, onError],
  );

  useEffect(() => {
    if (!videoRef.current) {
      console.error("videoRef.current is null or undefined");
      return;
    }

    playerRef.current = createPlayer(videoRef.current);
    storageRef.current = createStorage(playerRef.current);
  }, [authToken, videoRef, onError, createPlayer, createStorage]);

  useEffect(() => {
    const run = async () => {
      if (!playerRef.current || !storageRef.current || !src) return;

      const player = playerRef.current;
      const storage = storageRef.current;
      let playerSrc = src;

      if (cached) {
        const cachedContentList = await storage.list();
        const cachedContent = cachedContentList.find((content) => content.originalManifestUri === playerSrc);

        if (!cachedContent?.offlineUri || cachedContent.isIncomplete) {
          if (cachedContent?.offlineUri) {
            storage?.remove(cachedContent?.offlineUri);
          }

          storage.store(playerSrc).promise.catch(onError);
        } else {
          playerSrc = cachedContent.offlineUri;
        }
      }

      player.load(playerSrc).catch(onError);
    };

    run();
  }, [cached, createStorage, onError, src]);

  return <video ref={videoRef} {...props} />;
}
