import { ReactNode } from "react";
import styled from "styled-components/macro";
import { ifProp } from "styled-tools";

import { Maybe } from "graphql/jsutils/Maybe";

import { styleProp, themeColor } from "src/utils/styledComponents.utils";

import { Box } from "src/components/common/layout/Box.styled";

import Icon from "src/components/common/Icon";
import ShutterstockLabel from "src/components/features/Plans/PlansElements/ShutterstockLabel";
import { SHOULD_SHOW_SEE_MORE_BTN } from "src/components/features/Plans/plans.constants";

const CardWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: fit-content;
`;

const CardContainer = styled.div`
  position: relative;
  width: 320px;
  border-radius: 26px;
  background-color: ${themeColor("gray.30")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 45px;
  height: inherit;
`;

const SeeMoreButton = styled.div<{ isOpened?: boolean }>`
  position: absolute;
  bottom: 10px;
  height: 22px;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: ${themeColor("gray.500")};
  cursor: pointer;
  svg {
    margin: 2px 0 0 7px;
    transform: ${ifProp("isOpened", "rotate(180deg)", "rotate(0deg)")};
    fill: ${themeColor("gray.500")};
    transition: fill 250ms ease-out;
  }
  &:hover {
    color: ${themeColor("blue.500")};
    svg {
      fill: ${themeColor("blue.500")};
    }
  }
  &:active {
    color: ${themeColor("gray.900")};
  }

  transition: color 250ms ease-out;
`;

const PromptTagBlock = styled.div<{ cardColor?: string | null }>`
  position: absolute;
  top: -12px;
  height: 26px;
  width: 122px;
  background-color: ${styleProp("cardColor", themeColor("gray.500"))};
  color: ${themeColor("white")};
  font-weight: 800;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 1px;
  border-radius: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PlanName = styled(Box)<{ cardColor?: string | null }>`
  display: block;
  text-align: center;
  font-weight: 600;
  font-size: 34px;
  width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${styleProp("cardColor", themeColor("gray.500"))};
`;

const PlanPriceContainer = styled(Box)`
  width: 80%;
  height: 70px;
  display: flex;
  flex-direction: row;
  background-color: ${themeColor("gray.30")};
`;

const PlanDescriptionBlock = styled(Box)`
  width: 80%;
  height: 80px;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${themeColor("gray.500")};
  white-space: pre-line;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
`;

const PlanButtonContainer = styled(Box)<{ cardColor?: string | null }>`
  width: 80%;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  span {
    color: ${styleProp("cardColor", themeColor("gray.500"))};
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
  }
`;

const FeaturesList = styled(Box)<{ isOpened?: boolean; isSmall?: boolean }>`
  width: 80%;
  min-height: ${ifProp("isSmall", "0", "166px")};
  height: ${ifProp("isSmall", "fit-content", ifProp("isOpened", "fit-content", "166px"))};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const SubFeaturesList = styled.div`
  width: 100%;
  flex-direction: column;
`;

const SubFeaturesHeader = styled.div`
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: ${themeColor("gray.500")};
  padding: 30px 0 14px 0;
`;

const FeatureItem = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  span {
    margin-left: 10px;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: ${themeColor("gray.500")};

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const CheckIcon = styled(Icon.CheckCircle)<{ $cardColor?: string | null }>`
  color: ${styleProp("$cardColor", themeColor("gray.500"))};
`;

export const CheckIconInverted = styled(Icon.CheckCircleInverted)<{ $cardColor?: string | null }>`
  color: ${styleProp("$cardColor", themeColor("gray.500"))};
`;

interface PlanCardProps {
  planSid: Maybe<string>;
  planName: Maybe<string>;
  planDescription: Maybe<string>;
  planRegularFeaturesList?: Maybe<Maybe<string>[]>;
  planAdditionalFeaturesList?: Maybe<Maybe<string>[]>;
  planColor: Maybe<string>;
  planAdditionalColor: Maybe<string>;
  includesShutterstock: Maybe<boolean>;
  planPriceBlock: Maybe<ReactNode>;
  promptTag: Maybe<string>;
  planButton: Maybe<ReactNode>;
  isCurrent: Maybe<boolean>;
  shouldShowMoreFeatures: Maybe<boolean>;
  setExpandedPlanSid?: (planSid: Maybe<string>) => void;
  openCloseFeaturesList?: (planSid: Maybe<string>) => void;
  planType?: Maybe<string>;
}

export default function PlanCard({
  planSid,
  planName,
  planDescription,
  planRegularFeaturesList,
  planAdditionalFeaturesList,
  planColor,
  planAdditionalColor,
  includesShutterstock,
  planPriceBlock,
  promptTag,
  planButton,
  isCurrent,
  shouldShowMoreFeatures,
  setExpandedPlanSid,
  openCloseFeaturesList,
  planType,
}: PlanCardProps) {
  return (
    <CardWrapper margin={12}>
      <CardContainer data-cy="plan-card" data-cy-current={isCurrent ? "plan-current" : ""} data-cy-plan-type={planType}>
        {promptTag && <PromptTagBlock cardColor={planAdditionalColor}>{promptTag.toUpperCase()}</PromptTagBlock>}
        <PlanName cardColor={planColor} marginBottom={22} data-cy="plan-name">
          {planName}
        </PlanName>
        <PlanPriceContainer marginBottom={24}>{planPriceBlock}</PlanPriceContainer>
        <PlanDescriptionBlock marginBottom={20}>{planDescription}</PlanDescriptionBlock>
        <PlanButtonContainer cardColor={planColor} marginBottom={18}>
          {isCurrent ? <span>Your current plan</span> : planButton || null}
        </PlanButtonContainer>
        <FeaturesList
          isSmall={!SHOULD_SHOW_SEE_MORE_BTN}
          isOpened={!!shouldShowMoreFeatures}
          marginBottom={SHOULD_SHOW_SEE_MORE_BTN ? 45 : 6}
        >
          {planRegularFeaturesList?.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <FeatureItem key={index} marginBottom={10}>
              <div>
                <CheckIcon $cardColor={planColor} />
              </div>
              <span>{item}</span>
            </FeatureItem>
          ))}
          {planAdditionalFeaturesList && !!planAdditionalFeaturesList.length && (
            <SubFeaturesList>
              <SubFeaturesHeader>AI features:</SubFeaturesHeader>
              {planAdditionalFeaturesList?.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <FeatureItem key={index} marginBottom={10}>
                  <div>
                    <CheckIconInverted $cardColor={planColor} />
                  </div>
                  <span>{item}</span>
                </FeatureItem>
              ))}
            </SubFeaturesList>
          )}
        </FeaturesList>

        {SHOULD_SHOW_SEE_MORE_BTN && setExpandedPlanSid && openCloseFeaturesList && (
          <SeeMoreButton
            onClick={() => openCloseFeaturesList(planSid)}
            isOpened={!!shouldShowMoreFeatures}
            data-cy="see-more-button"
          >
            {shouldShowMoreFeatures ? "See less features" : "See all features"}
            <Icon.ArrowDown size={14} />
          </SeeMoreButton>
        )}
      </CardContainer>
      <ShutterstockLabel isVisible={includesShutterstock} />
    </CardWrapper>
  );
}
