import React, { memo, useCallback, useMemo, useState } from "react";
import styled, { css } from "styled-components/macro";

import { TimeInterval, TimeIntervalEdge } from "src/types/video-trimmer.types";

import { ifProp, themeColor } from "src/utils/styledComponents.utils";
import { timeIntervalUtils } from "src/utils/timeInterval.utils";

import useVideoTimeInterval from "src/components/features/VideoTrimmer/common/hooks/useVideoTimeInterval";
import TimePointPopup from "src/components/features/VideoTrimmer/TimelineEditor/TimePointPopup";
import { CropTimeInterval } from "src/types/video-cropper.types";

const RESIZE_THUMB_GRAB_AREA_OUTER_PADDING = 5;
const RESIZE_THUMB_GRAB_AREA_INNER_PADDING = 7;
const RESIZE_THUMB_WIDTH = 2;
const RESIZE_THUMB_GRAB_AREA_WIDTH = RESIZE_THUMB_GRAB_AREA_OUTER_PADDING + RESIZE_THUMB_GRAB_AREA_INNER_PADDING + RESIZE_THUMB_WIDTH; // prettier-ignore

export const ResizeThumbGrabArea = styled.div`
  z-index: 1;
  display: flex;
  padding-inline: ${RESIZE_THUMB_GRAB_AREA_OUTER_PADDING}px;
  transition: opacity 150ms ease-out;
  opacity: 0;
  cursor: col-resize;

  &:first-of-type {
    padding-right: ${RESIZE_THUMB_GRAB_AREA_INNER_PADDING}px;
  }

  &:last-of-type {
    padding-left: ${RESIZE_THUMB_GRAB_AREA_INNER_PADDING}px;
  }
`;

export const ResizeThumb = styled.div`
  width: ${RESIZE_THUMB_WIDTH}px;
  border-radius: 10px;
  margin-block: 10px;
  background-color: ${themeColor("white")};
`;

interface ContainerProps {
  x1: number;
  x2: number;
  isActive: boolean;
  isResizing?: boolean;
  isRightEdge?: boolean;
  isLeftEdge?: boolean;
}

const Container = styled.div.attrs<ContainerProps>(({ x1, x2 }) => ({
  style: {
    transform: `translateX(${x1}px)`,
    width: `${x2 - x1}px`,
  },
}))<ContainerProps>`
  display: flex;
  justify-content: space-between;
  position: absolute;
  height: 38px;
  bottom: 1px;
  left: 0;
  border: 2px solid rgb(255, 0, 0, 0);
  /* outline: ${ifProp("isActive", `1px solid white`, `none`)}; */
  outline-offset: -3px;
  border-radius: 10px;
  border-left-width: ${ifProp("isLeftEdge", `3px`, `2px`)};
  border-right-width: ${ifProp("isRightEdge", `3px`, `2px`)};
  background-color: ${ifProp("isActive", themeColor("violet.400", 0.6), themeColor("violet.100", 0.5))};
  cursor: ${ifProp("isResizing", "col-resize", "pointer")};
  transition: background-color 250ms ease-out;
  background-clip: padding-box;

  &:hover {
    ${ResizeThumbGrabArea} {
      opacity: 1;
    }
  }

  ${ResizeThumbGrabArea} {
    max-height: 100px;
    transition: max-height 150ms ease-out, opacity 150ms ease-out;

    ${ifProp(
      "isLeftEdge",
      css`
        &:first-of-type {
          max-height: 0;
          opacity: 0;
        }
      `,
    )}

    ${ifProp(
      "isRightEdge",
      css`
        &:last-of-type {
          max-height: 0;
          opacity: 0;
        }
      `,
    )}
  }

  ${ResizeThumbGrabArea} {
    opacity: 0;

    ${ifProp(
      "isActive",
      css`
        opacity: 1;
      `,
    )}

    ${ifProp(
      "isLeftEdge",
      css`
        &:first-of-type {
          max-height: 0;
          opacity: 0;
        }
      `,
    )}

        ${ifProp(
      "isRightEdge",
      css`
        &:last-of-type {
          max-height: 0;
          opacity: 0;
        }
      `,
    )}
  }

  ${ifProp(
    "isResizing" && "isActive",
    css`
      ${ResizeThumbGrabArea} {
        opacity: 1;
      }
    `,
  )}
`;

const HiddenResizeThumbsPopup = styled.div`
  background: ${themeColor("white")};
  padding: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
`;

interface CropViewProps {
  index: number;
  value: CropTimeInterval;
  isActive: boolean;
  pixelPerSecond: number;
  zoomedTimeInterval: TimeInterval;
  popupTarget: HTMLElement | null;
  crops: CropTimeInterval[];
  onResize?(index: number, edge: TimeIntervalEdge, timeDelta: number): void;
  onResizeStart?(index: number, edge: TimeIntervalEdge): void;
  onResizeEnd?(index: number, edge: TimeIntervalEdge): void;
  isResizing?: boolean;
}

function CropViewBase({
  index,
  value,
  isActive = false,
  pixelPerSecond,
  zoomedTimeInterval,
  popupTarget,
  crops,
  onResize: outerOnResize,
  onResizeStart: outerOnResizeStart,
  onResizeEnd: outerOnResizeEnd,
  isResizing,
}: CropViewProps) {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const nextCrop = crops[index + 1];
  const previousCrop = crops[index - 1];

  const isRightEdge = useMemo(
    () => value?.end < nextCrop?.start || !nextCrop || value?.domain?.start !== nextCrop?.domain?.start,
    [value, nextCrop],
  );

  const isLeftEdge = useMemo(
    () => value?.start > previousCrop?.end || !previousCrop || value?.domain?.start !== previousCrop?.domain?.start,
    [value, previousCrop],
  );

  const onResizeStart = useCallback(
    (edge: TimeIntervalEdge) => {
      outerOnResizeStart?.(index, edge);
    },
    [index, outerOnResizeStart],
  );

  const onResize = useCallback(
    (edge: TimeIntervalEdge, timeDelta: number) => {
      outerOnResize?.(index, edge, timeDelta);
    },
    [index, outerOnResize],
  );

  const onResizeEnd = useCallback(
    (edge: TimeIntervalEdge) => {
      outerOnResizeEnd?.(index, edge);
    },
    [index, outerOnResizeEnd],
  );
  const { x1, x2, leftResizeTriggerProps, rightResizeTriggerProps } = useVideoTimeInterval(value, {
    pixelPerSecond,
    onResize,
    onResizeStart,
    onResizeEnd,
  });

  const innerWidth = x2 - x1;
  const areResizeThumbsVisible = innerWidth >= RESIZE_THUMB_GRAB_AREA_WIDTH * 2.5;

  return (
    <Container
      x1={x1}
      x2={x2}
      isActive={isActive}
      isResizing={isResizing}
      isLeftEdge={isLeftEdge}
      isRightEdge={isRightEdge}
      onMouseEnter={() => {
        setIsMouseOver(true);
      }}
      onMouseLeave={() => {
        setIsMouseOver(false);
      }}
    >
      {areResizeThumbsVisible ? (
        <>
          <ResizeThumbGrabArea {...leftResizeTriggerProps}>
            <ResizeThumb />
          </ResizeThumbGrabArea>

          <ResizeThumbGrabArea {...rightResizeTriggerProps}>
            <ResizeThumb />
          </ResizeThumbGrabArea>
        </>
      ) : (
        isMouseOver && (
          <TimePointPopup
            relativeTimePositionPx={(timeIntervalUtils.middle(value) - zoomedTimeInterval.start) * pixelPerSecond}
            popupTarget={popupTarget}
          >
            <HiddenResizeThumbsPopup>double click to resize</HiddenResizeThumbsPopup>
          </TimePointPopup>
        )
      )}
    </Container>
  );
}

const CropView = memo(CropViewBase);

export default CropView;
