import { useMemo, useRef, useState } from "react";
import styled from "styled-components/macro";

import { themeColor } from "src/utils/styledComponents.utils";

import Icon from "src/components/common/Icon";
import { Button } from "src/components/common/buttons/Button.styled";
import { ModalComponentProps } from "src/components/providers/ModalProvider";
import Input from "src/components/common/form/inputs/Input.styled";
import TimeCodeInputComponent from "src/components/common/form/inputs/TimeCode/TimeCodeInput";
import FieldError from "src/components/common/form/FieldError";
import { TimeFormat, convertMsToTimeFormat } from "src/utils/time.utils";
import { MAX_CLIP_DURATION, MIN_CLIP_DURATION } from "src/constants/video.constants";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-width: 371px;
  min-height: 216px;
  background-color: inherit;
  border-radius: 10px;
  padding: 30px 0 0 0;

  @media (max-width: 768px) {
    max-width: 90vw;
    min-width: unset;
  }
`;

const CloseDiv = styled.div`
  position: absolute;
  top: 0;
  right: 6px;
  width: 14px;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: ${themeColor("gray.500")};
    }
  }
`;

const Title = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: ${themeColor("gray.900")};
  margin-bottom: 10px;
`;

const InfoDiv = styled(Title)`
  color: ${themeColor("black")};
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
`;

const Wrapper = styled.div`
  width: 75%;
  margin-block: 20px;
  @media (max-width: 425px) {
    width: 90%;
  }
`;

const TimeCodeWrapper = styled.div`
  display: flex;
  margin-block: 10px;
  gap: 6px;
  align-items: center;
  flex-direction: row;
  font-size: 12px;
`;

interface EntityTitleModalProps extends ModalComponentProps {
  minTime: number;
  maxTime: number;
  onApprove: (data: { title: string; inTime: number; outTime: number }) => void;
}

export default function CreateClipModal({ close, onApprove, minTime, maxTime }: EntityTitleModalProps) {
  const titleInputRef = useRef<HTMLInputElement>(null);
  const inTimeInputRef = useRef<HTMLInputElement>(null);
  const outTimeInputRef = useRef<HTMLInputElement>(null);
  const [inTime, setInTime] = useState(0); // in milliseconds
  const [outTime, setOutTime] = useState(0); // in milliseconds
  const maxDuration = MAX_CLIP_DURATION;
  const minDuration = MIN_CLIP_DURATION;

  const onSubmit = () => {
    const title =
      titleInputRef.current?.value.trim() ||
      `${convertMsToTimeFormat(inTime, TimeFormat.HHMMSS)} - ${convertMsToTimeFormat(outTime, TimeFormat.HHMMSS)}`;
    onApprove({ title, inTime, outTime });
    close();
  };

  const inMinTime = minTime;
  // max time is maxTime - minDuration or outTime - minDuration
  const inMaxTime = useMemo(() => {
    if (outTime > 0) {
      return Math.max(outTime - minDuration, 0);
    }
    return Math.max(maxTime - minDuration, 0);
  }, [outTime, maxTime, minDuration]);

  // outMin time is inTime + minDuration
  const outMinTime = useMemo(() => {
    if (outTime > 0) {
      return inTime + minDuration;
    }
    return 0;
  }, [inTime, minDuration, outTime]);
  // outMaxTime is inTime + maxDuration or maxTime
  const outMaxTime = useMemo(() => {
    if (outTime > 0) {
      return Math.min(inTime + maxDuration, maxTime);
    }
    return maxTime;
  }, [outTime, maxTime, inTime, maxDuration]);

  // validate times inTime and outTime with the min and max times
  const isTimeInvalid = useMemo(
    () => inTime < inMinTime || inTime > inMaxTime || outTime < outMinTime || outTime > outMaxTime,
    [inTime, inMinTime, inMaxTime, outTime, outMinTime, outMaxTime],
  );

  const errorMessage = useMemo(() => {
    if (outTime > maxTime) {
      return `'Out' time can't be longer than video's total duration (${convertMsToTimeFormat(
        maxTime,
        TimeFormat.HHMMSS,
      )})`;
    }
    if (inTime > outTime) {
      return "'In' time cannot be bigger than 'Out' time";
    }
    if (outTime < inTime) {
      return "'Out' time cannot be lower than 'In' time";
    }
    if (outTime - inTime < minDuration) {
      return "Chapter can't be shorter than 10 Seconds";
    }
    if (outTime - inTime > maxDuration) {
      return "Chapter can't be longer than 25 Minutes";
    }
    return "";
  }, [inTime, outTime, minDuration, maxDuration, maxTime]);

  // eslint-disable-next-line no-console
  console.log("times", {
    inTime: convertMsToTimeFormat(inTime, TimeFormat.HHMMSS),
    outTime: convertMsToTimeFormat(outTime, TimeFormat.HHMMSS),
    inMinTime: convertMsToTimeFormat(inMinTime, TimeFormat.HHMMSS),
    inMaxTime: convertMsToTimeFormat(inMaxTime, TimeFormat.HHMMSS),
    outMinTime: convertMsToTimeFormat(outMinTime, TimeFormat.HHMMSS),
    outMaxTime: convertMsToTimeFormat(outMaxTime, TimeFormat.HHMMSS),
    maxTime: convertMsToTimeFormat(maxTime, TimeFormat.HHMMSS),
    minTime: convertMsToTimeFormat(minTime, TimeFormat.HHMMSS),
  });

  return (
    <Container>
      <CloseDiv onClick={close}>
        <Icon.Close />
      </CloseDiv>
      <Title>Create chapter from timestamps</Title>
      <InfoDiv>Enter IN and OUT timestamps</InfoDiv>
      <Wrapper>
        <TimeCodeWrapper>
          <TimeCodeInputComponent
            ref={inTimeInputRef}
            label="In"
            autoFocus
            time={inTime}
            minTime={inMinTime}
            maxTime={inMaxTime}
            onTimeChange={(time) => {
              setInTime(time);
            }}
            onBlur={() => {
              setTimeout(() => {
                document.activeElement === document.body && outTimeInputRef?.current?.focus();
              }, 10);
            }}
          />
          <TimeCodeInputComponent
            ref={outTimeInputRef}
            label="Out"
            time={outTime}
            minTime={outMinTime}
            maxTime={outMaxTime}
            onTimeChange={(time) => {
              setOutTime(time);
            }}
            onBlur={() => {
              setTimeout(() => {
                document.activeElement === document.body && titleInputRef?.current?.focus();
              }, 10);
            }}
          />
        </TimeCodeWrapper>
        <Input
          ref={titleInputRef}
          inputSize="medium"
          placeholder={`Enter chapter name (${convertMsToTimeFormat(
            inTime,
            TimeFormat.HHMMSS,
          )} - ${convertMsToTimeFormat(outTime, TimeFormat.HHMMSS)})`}
          onFocus={() => {
            titleInputRef?.current?.select();
          }}
        />
        {isTimeInvalid && <FieldError capitalizeText={false}>{errorMessage}</FieldError>}
      </Wrapper>

      <Button disabled={isTimeInvalid || outTime === 0} onClick={onSubmit} variant="primary" size="large">
        Create chapter
      </Button>
    </Container>
  );
}
