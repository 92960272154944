import { useCallback } from "react";
import { useSelector } from "react-redux";

import { userSelectors } from "src/models/User.model";

import { useAppConfig } from "src/components/providers/AppConfigProvider";

export const useGetAssetImageURL = () => {
  const appConfig = useAppConfig();
  const currentUser = useSelector(userSelectors.selectCurrentUser);

  return useCallback(
    (assetId: string) => `${appConfig.CHUNKS_URL}/asset/${assetId}/type/${currentUser?.cacheVersion}.png`,
    [appConfig.CHUNKS_URL, currentUser?.cacheVersion],
  );
};
