import { FailedMark, StyledTooltip } from "src/components/features/SmartLibrary/SmartLibrary.styled";
import { FailedTooltipExplanation } from "src/components/features/SmartLibrary/smartLibrary.constants";
import { Fragment } from "react";
import Icon from "src/components/common/Icon";

interface FailedInfoProps {
  tooltipsTrigger: "hover" | "click";
}
export function FailedInfo({ tooltipsTrigger }: FailedInfoProps) {
  return (
    <FailedMark
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <StyledTooltip text={FailedTooltipExplanation} placement="right" trigger={tooltipsTrigger}>
        <Fragment>
          <span>Failed</span>
          <Icon.Attention />
        </Fragment>
      </StyledTooltip>
    </FailedMark>
  );
}
