import { useCallback, useEffect } from "react";

export const useSwiperInnerScrolling = () => {
  const onWheel = useCallback((ev: WheelEvent) => {
    ev.stopImmediatePropagation();
  }, []);

  const onMouseEnter = useCallback(() => {
    window.addEventListener("wheel", onWheel, { capture: true, passive: false });
  }, [onWheel]);

  const onMouseLeave = useCallback(() => {
    window.removeEventListener("wheel", onWheel, { capture: true });
  }, [onWheel]);

  useEffect(
    () => () => {
      window.removeEventListener("wheel", onWheel, { capture: true });
    },
    [onWheel],
  );

  return {
    onMouseEnter,
    onMouseLeave,
  };
};
