import styled from "styled-components/macro";

import { Maybe } from "graphql/jsutils/Maybe";

import { styleProp, themeColor } from "src/utils/styledComponents.utils";
import { useAppConfig } from "../../../providers/AppConfigProvider";

const Container = styled.div`
  width: fit-content;
  height: fit-content;
  a {
    text-decoration: none;
  }
`;

const ContactUs = styled.div<{
  buttonColor?: Maybe<string>;
  buttonAdditionalColor?: Maybe<string>;
  buttonWidth?: Maybe<string>;
  fontSize?: Maybe<string>;
}>`
  width: ${styleProp("buttonWidth", "206px")};
  height: 44px;
  border: unset;
  border-radius: 22px;
  background-color: ${styleProp("buttonColor", themeColor("gray.500"))};
  color: ${themeColor("gray.200")};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: ${styleProp("fontSize", "15px")};
  line-height: 22px;
  margin: 10px;
  cursor: pointer;

  &:hover {
    background-color: ${styleProp("buttonAdditionalColor", themeColor("gray.500"))};
  }

  transition: background-color 250ms ease-out;
`;

interface ContactUsButtonProps {
  buttonColor?: Maybe<string>;
  buttonAdditionalColor?: Maybe<string>;
  buttonWidth?: Maybe<string>;
  fontSize?: Maybe<string>;
}

export default function ContactUsButton({
  buttonColor,
  buttonAdditionalColor,
  buttonWidth,
  fontSize,
}: ContactUsButtonProps) {
  const { HOMEPAGE_URL } = useAppConfig();
  return (
    <Container>
      <a href={`${HOMEPAGE_URL}/requestademo`}>
        <ContactUs
          buttonColor={buttonColor}
          buttonAdditionalColor={buttonAdditionalColor}
          buttonWidth={buttonWidth}
          fontSize={fontSize}
          data-cy={buttonAdditionalColor ? "contact-us-inside-plan-card" : "contact-us-in-footer"}
        >
          Book a demo
        </ContactUs>
      </a>
    </Container>
  );
}
