export const getImageToAnimation = (imgUrl: string, maxWidth: number, maxHeight: number) =>
  new Promise<string>((resolve, reject) => {
    const img = new Image();

    const onLoad = () => {
      if (img.width / maxWidth > img.height / maxHeight) {
        const ratio = maxWidth / img.width;

        img.width = maxWidth;
        img.height *= ratio;
      } else {
        const ratio = maxHeight / img.height;

        img.height = maxHeight;
        img.width *= ratio;
      }

      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = maxWidth;
      canvas.height = maxHeight;
      ctx?.drawImage(img, canvas.width / 2 - img.width / 2, canvas.height / 2 - img.height / 2, img.width, img.height);
      resolve(canvas.toDataURL("image/png"));
    };

    img.crossOrigin = "Anonymous"; // to avoid CORS if used with Canvas
    img.style.maxWidth = maxWidth.toString();
    img.style.maxHeight = maxHeight.toString();
    img.onload = onLoad;
    img.onerror = reject;
    img.src = imgUrl;
  });
