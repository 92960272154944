import { format } from "date-fns";

export enum TimeFormat {
  HHMMSS = "HH:MM:SS",
  HHMMSSmmm = "HH:MM:SS:mmm",
  HHMMSSFR = "HH:MM:SS:fr",
  MMSSmmm = "MM:SS:mmm",
  MMSSFR = "MM:SS:fr",
  MMSS = "MM:SS",
}

export interface TimeObj {
  m: number;
  s: number;
  ms: number;
}

export const formatSeconds = (seconds: number, formatString: string) =>
  format(new Date(0, 0, 0, 0, 0, 0, seconds * 1000), formatString);

// This function rounds the frame time. If the decimal part of the frame time is greater than or equal to 0.9 (within a threshold of 0.1),
// the frame time is rounded up to the nearest integer. If the decimal part is less than 0.9, the frame time is rounded down (floored).
export const getFrameNumber = (frameTime: number) => {
  const frame = frameTime * 30;
  const decimalPart = frame % 1;
  let roundedFrame;
  if (decimalPart >= 0.9) {
    roundedFrame = Math.ceil(frame);
  } else {
    roundedFrame = Math.floor(frame);
  }

  return roundedFrame;
};

export const convertTimeToFrameTime = (time: number, timeDifference: number = 0) => {
  const currentTime = time - timeDifference;
  const frameCurrentTime = getFrameNumber(currentTime + timeDifference) / 30;
  return frameCurrentTime;
};

export const formatTimeCodeToMs = (value: string, timeFormat: TimeFormat) => {
  const cleanedValue = value.replace(/[^0-9]/g, "");

  let frame = 0;
  let second = 0;
  let minute = 0;
  let hour = 0;
  let ms = 0;

  switch (timeFormat) {
    case TimeFormat.HHMMSS:
    case TimeFormat.MMSS:
      second = parseInt(cleanedValue.slice(-2), 10) || 0;
      minute = parseInt(cleanedValue.slice(-4, -2), 10) || 0;
      hour = parseInt(cleanedValue.slice(-6, -4), 10) || 0;
      break;
    case TimeFormat.HHMMSSmmm:
    case TimeFormat.MMSSmmm:
      ms = parseInt(cleanedValue.slice(-3), 10) || 0;
      second = parseInt(cleanedValue.slice(-5, -3), 10) || 0;
      minute = parseInt(cleanedValue.slice(-7, -5), 10) || 0;
      hour = parseInt(cleanedValue.slice(-9, -7), 10) || 0;
      break;
    case TimeFormat.HHMMSSFR:
    case TimeFormat.MMSSFR:
      frame = parseInt(cleanedValue.slice(-2), 10) || 0;
      second = parseInt(cleanedValue.slice(-4, -2), 10) || 0;
      minute = parseInt(cleanedValue.slice(-6, -4), 10) || 0;
      hour = parseInt(cleanedValue.slice(-8, -6), 10) || 0;
      break;
    default:
      throw new Error(`Unsupported format: ${format}`);
  }

  // Convert time code to milliseconds
  const milliseconds = (hour * 60 * 60 + minute * 60 + second) * 1000 + Math.round((frame * 1000) / 30) + ms;
  return milliseconds;
};

export const convertMsToTimeFormat = (milliseconds: number, timeFormat: TimeFormat): string => {
  const hours = Math.floor(milliseconds / (60 * 60 * 1000));
  const minutes = Math.floor((milliseconds / (60 * 1000)) % 60);
  const seconds = Math.floor((milliseconds / 1000) % 60);
  const frames = Math.round(((milliseconds % 1000) * 30) / 1000);
  const ms = milliseconds % 1000;

  switch (timeFormat) {
    case TimeFormat.HHMMSS:
      return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    case TimeFormat.HHMMSSmmm:
      return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}:${ms.toString().padStart(3, "0")}`;
    case TimeFormat.HHMMSSFR:
      return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}:${frames.toString().padStart(2, "0")}`;
    case TimeFormat.MMSSmmm:
      return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}:${ms
        .toString()
        .padStart(3, "0")}`;
    case TimeFormat.MMSSFR:
      return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}:${frames
        .toString()
        .padStart(2, "0")}`;
    case TimeFormat.MMSS:
      return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    default:
      throw new Error(`Unsupported format: ${format}`);
  }
};
