import { useRef, useState } from "react";
import styled from "styled-components/macro";

import { ChapterTimeInterval } from "src/types/video-trimmer.types";

import { getAspectRatioValue } from "src/constants/video.constants";

import { styleProp, themeColor } from "src/utils/styledComponents.utils";

import useVariableRef from "src/hooks/useVariableRef";
import useIsVideoLoading from "src/hooks/useIsVideoLoading";
import { UseHistoryStateReturnValue } from "src/hooks/useHistoryState";

import { Stack } from "src/components/common/layout/Stack.styled";
import { Center, FlexBox } from "src/components/common/layout/Box.styled";
import { CircularLoader } from "src/components/common/loaders/CircularLoader";
import ShakaVideoPlayer from "src/components/common/media/ShakaVideoPlayer/ShakaVideoPlayer";
import AutoSizer from "src/components/common/layout/AutoSizer";
import VideoCurrentTimeProvider from "src/components/features/VideoTrimmer/providers/VideoCurrentTimeProvider/VideoCurrentTimeProvider";
import VideoChaptersProvider from "src/components/features/VideoTrimmer/providers/VideoChaptersProvider/VideoChaptersProvider";
import TimelineZoomProvider from "src/components/features/VideoTrimmer/providers/TimelineZoomProvider/TimelineZoomProvider";
import VideoPlaybackProvider from "src/components/features/VideoTrimmer/providers/VideoPlaybackProvider/VideoPlaybackProvider";
import TimelineZoomer from "src/components/features/VideoTrimmer/TimelineZoomer/TimelineZoomer";
import usePixelPerSecondRatio from "src/components/features/VideoTrimmer/common/hooks/usePixelPerSecondRatio";
// import TrimmedVideoTimer from "src/components/features/VideoTrimmer/TimelineActionButtons/TrimmedVideoTimer";
import VideoCropperActionButtons from "src/components/features/VideoCropper/VideoCropperActionButtons";
import VideoCropProvider from "src/components/features/VideoCropper/providers/VideoCropProvider/VideoCropProvider";
import VideoCropperSideMenu from "src/components/features/VideoCropper/VideoCropperSideMenu";
import { VideoTrimmerProps } from "src/components/features/VideoTrimmer/VideoTrimmer";
import { CropTimelineActionButtons } from "src/components/features/VideoCropper/CropTimelineActionButtons";
import CropPlayer from "src/components/common/media/CropPlayer/CropPlayer";
import CropRnd from "src/components/features/VideoCropper/CropRnd";
import CropperTimelineEditor from "src/components/features/VideoCropper/CropperTimelineEditor";
import useIsPlaying from "src/hooks/useIsPlaying";
import TimeLineZoomShortcuts from "src/components/features/VideoTrimmer/shortcuts/TimelineZoomShortcuts";
import VideoPlaybackShortcuts from "src/components/features/VideoTrimmer/shortcuts/VideoPlaybackShortcuts";
import VideoCropShortcuts from "src/components/features/VideoTrimmer/shortcuts/VideoCropShortcuts";
import { ifProp } from "styled-tools";
import VideoWordsProvider from "src/components/features/VideoTrimmer/providers/VideoWordsProvider/VideoWordsProvider";
import VideoTranscriptProvider from "src/components/features/VideoTrimmer/providers/VideoTranscriptProvider/VideoTranscriptProvider";
import { useSelector } from "react-redux";
import { RootState } from "src/models/store";
import { sequenceSelectors } from "src/models/Sequence.model";
import * as sequenceContentEditorAnalytics from "src/analytics/sequenceContentEditor.analytics";
import CropGuide from "./CropGuide";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Body = styled(Stack)`
  flex: 1;
  overflow: hidden;
`;

const MenuColumn = styled.div<{ isBlur?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 16px;
  width: 240px;
  min-width: 240px;
  z-index: 99999;
  background: ${ifProp("isBlur", themeColor("white", 0.6), themeColor("white"))};
  backdrop-filter: ${ifProp("isBlur", "blur(10px)", "none")};
`;

const VideoColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const FlexAutoSizer = styled(AutoSizer)`
  display: flex;
  padding: 30px;
  position: relative;
  overflow: visible;
`;

const VideoPlayerContainer = styled.div`
  container-name: VideoPlayerContainer;
  container-type: inline-size;
  position: relative;
  margin: auto;
  outline: 1px solid ${themeColor("gray.300")};
`;

const StyledShakaVideoPlayer = styled(ShakaVideoPlayer)`
  object-fit: contain;
`;

const Footer = styled(Stack)`
  padding-block: 10px;
  user-select: none;
  background-color: ${themeColor("gray.200")};
`;

const CircularLoaderContainer = styled(Center)`
  position: absolute;
  inset: 0;
`;

const TimelineWindow = styled(Stack)`
  padding-inline: 2px;
`;

// const Progress = styled.div`
//   font-family: Roboto, sans-serif;
//   font-size: 14px;
//   line-height: 25px;
//   font-weight: 300;
//   color: ${themeColor("black")};
//   text-align: right;
// `;

const Header = styled(FlexBox)`
  background-color: ${themeColor("gray.50")};
  padding: 7px 41px 5px 41px;
`;

const HorizontalDivider = styled(FlexBox)`
  background: ${themeColor("gray.200")};
  height: 5px;
  width: 100%;
`;

const CropStage = styled.div<{ height: number; aspectRatio: number; cover: number }>`
  position: absolute;
  aspect-ratio: ${styleProp("aspectRatio")};
  height: ${styleProp("height")}px;
  background-color: transparent;
  outline: 1px solid ${themeColor("gray.300")};

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  &::after {
    content: "";
    z-index: 1;
    position: absolute;
    height: 100%;
    width: 100%;

    box-shadow: 0 0 0 2000px rgba(250, 250, 250, ${styleProp("cover")});
    pointer-events: none;
  }
`;

const DragOverlayBox = styled.div`
  height: 100%;
  width: 100%;
  border: 1px solid ${themeColor("pink.500")};
`;

export default function VideoCropper({
  sequenceSid,
  videoSrc,
  videoDuration,
  videoAspectRatio,
  videoAudioSamples,
  chaptersHistory,
  chapterDomains,
  cropsHistory,
  wordsHistory,
}: VideoTrimmerProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const cropStageRef = useRef<HTMLDivElement>(null);
  const [timelineWindow, setTimelineWindow] = useState<HTMLElement | null>(null);
  const pixelPerSecondRatio = usePixelPerSecondRatio(useVariableRef(timelineWindow), videoDuration);
  const isVideoLoading = useIsVideoLoading(videoRef);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const isPlaying = useIsPlaying(videoRef);
  const textDirection = useSelector((state: RootState) => sequenceSelectors.selectSequenceTextDirection(state, sequenceSid)); // prettier-ignore
  const { languageCode, useV2ClosedCaptions } = useSelector(
    (state: RootState) => sequenceSelectors.selectById(state, sequenceSid)!,
  );

  return (
    <VideoCurrentTimeProvider videoRef={videoRef}>
      <VideoChaptersProvider
        chaptersHistory={chaptersHistory as UseHistoryStateReturnValue<ChapterTimeInterval[]>}
        domains={chapterDomains}
      >
        <TimelineZoomProvider videoRef={videoRef} videoDuration={videoDuration} timelineWindowElement={timelineWindow}>
          <VideoPlaybackProvider
            cropsHistory={cropsHistory}
            wordsHistory={wordsHistory}
            chaptersHistory={chaptersHistory as UseHistoryStateReturnValue<ChapterTimeInterval[]>}
            videoRef={videoRef}
            videoDuration={videoDuration}
          >
            <TimeLineZoomShortcuts />
            <VideoPlaybackShortcuts videoRef={videoRef} />
            <VideoTranscriptProvider
              videoDuration={videoDuration}
              textDirection={textDirection}
              videoRef={videoRef}
              wordsHistory={wordsHistory}
            >
              <VideoWordsProvider
                wordsHistory={wordsHistory}
                videoDuration={videoDuration}
                languageCode={languageCode || "en/US"}
                allowedActions={{
                  correct: {
                    isAllowed: !!useV2ClosedCaptions,
                  },
                  highlight: {
                    isAllowed: !!useV2ClosedCaptions,
                  },
                }}
                analyticsActions={sequenceContentEditorAnalytics}
              >
                <VideoCropProvider
                  cropsHistory={cropsHistory}
                  sequenceSid={sequenceSid}
                  videoRef={videoRef}
                  cropStageRef={cropStageRef}
                >
                  <VideoCropShortcuts />
                  <Container>
                    <Header>
                      <VideoCropperActionButtons />
                    </Header>

                    <HorizontalDivider />

                    <Body direction="row" spacing={2}>
                      <MenuColumn isBlur={isDragging}>
                        <VideoCropperSideMenu sequenceSid={sequenceSid} />
                      </MenuColumn>
                      <VideoColumn>
                        <FlexAutoSizer aspectRatio={getAspectRatioValue(videoAspectRatio)}>
                          {({ width, height }) => (
                            <>
                              <VideoPlayerContainer style={{ width, height }}>
                                <CropPlayer
                                  renderVideoLayer={false}
                                  videoRef={videoRef}
                                  width={width}
                                  height={height}
                                />

                                {/*
                          if we want to show the progress we need to change the component markup
                          <Progress>
                            <TrimmedVideoTimer formatStr="HH:mm:ss" />
                          </Progress> */}

                                {isVideoLoading && (
                                  <CircularLoaderContainer>
                                    <CircularLoader size={100} />
                                  </CircularLoaderContainer>
                                )}
                              </VideoPlayerContainer>
                              <CropStage
                                ref={cropStageRef}
                                cover={isDragging ? 0.5 : 1}
                                height={height}
                                aspectRatio={getAspectRatioValue(videoAspectRatio)}
                              >
                                <CropRnd lockAspectRatio disableDragging enableResizing={false}>
                                  <StyledShakaVideoPlayer
                                    videoRef={videoRef}
                                    src={videoSrc}
                                    cached
                                    width="100%"
                                    height="100%"
                                    config={{ streaming: { forceHTTPS: true } }}
                                  />
                                </CropRnd>
                              </CropStage>
                              {!isPlaying && (
                                <CropStage
                                  cover={0}
                                  height={height}
                                  aspectRatio={getAspectRatioValue(videoAspectRatio)}
                                >
                                  <CropRnd
                                    setIsDragging={setIsDragging}
                                    lockAspectRatio
                                    className="drag-overlay"
                                    resizeHandleWrapperClass="re-cropper-resizable-handle"
                                    resizeHandleClasses={{
                                      topLeft: "corner t-l",
                                      topRight: "corner t-r",
                                      bottomLeft: "corner b-l",
                                      bottomRight: "corner b-r",
                                    }}
                                  >
                                    <DragOverlayBox />
                                  </CropRnd>
                                  <CropGuide isDragging={isDragging} cropStageRef={cropStageRef} />
                                </CropStage>
                              )}
                            </>
                          )}
                        </FlexAutoSizer>
                      </VideoColumn>
                    </Body>

                    <Footer spacing={10} direction="column">
                      <CropTimelineActionButtons videoRef={videoRef} />

                      <TimelineWindow ref={setTimelineWindow} direction="column" spacing={10}>
                        <CropperTimelineEditor
                          sequenceSid={sequenceSid}
                          aspectRatio={videoAspectRatio}
                          audioSamples={videoAudioSamples}
                          videoDurationInSeconds={videoDuration}
                          pixelPerSecondRatio={pixelPerSecondRatio}
                        />

                        <TimelineZoomer pixelPerSecondRatio={pixelPerSecondRatio} />
                      </TimelineWindow>
                    </Footer>
                  </Container>
                </VideoCropProvider>
              </VideoWordsProvider>
            </VideoTranscriptProvider>
          </VideoPlaybackProvider>
        </TimelineZoomProvider>
      </VideoChaptersProvider>
    </VideoCurrentTimeProvider>
  );
}
