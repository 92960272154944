import { useParams } from "react-router-dom";

import useSecuredSearchParam from "src/hooks/useSecuredSearchParam";

import SignUpCompletion from "src/components/auth/SignUpCompletion";

export default function SignUpCompletionPage() {
  const { token } = useParams<{ token: string }>();
  const email = atob(useSecuredSearchParam("email"));

  return <SignUpCompletion token={token} email={email} />;
}
