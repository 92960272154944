import styled from "styled-components/macro";

import { useMemo } from "react";
import Icon from "src/components/common/Icon";
import ProgressBar from "src/components/common/loaders/ProgressBar";
import { FileUploadStatus } from "src/services/BaseUpload.service";

const ProgressContainer = styled.div`
  width: 100%;
  /* max-width: 260px; */
  padding-inline: 14px;
  height: fit-content;
  display: flex;
  flex-direction: column;
`;

const InfoCont = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
`;

const ProgressBarCont = styled.div`
  width: 100%;
  padding-top: 4px;
`;

const PercentCont = styled.div`
  padding-right: 5px;
`;

const StatusCont = styled.div`
  width: 100%;
  padding-inline: 5px;
  border-left: solid white 1px;
`;

const IconCont = styled.div`
  padding-left: 5px;
  padding-top: 4px;
  cursor: pointer;
  svg {
    width: 14px;
    height: 14px;
  }
`;

interface WebItemProgressPlugProps {
  status: FileUploadStatus;
  progress: number;
}

export default function WebItemProgressPlug({ status, progress }: WebItemProgressPlugProps) {
  const showProgress = useMemo(() => `${(progress * 100).toFixed(0)}%`, [progress]);
  const statusLabel = useMemo(() => {
    switch (status) {
      case FileUploadStatus.PAUSED:
        return "Upload paused";
      case FileUploadStatus.SUCCESS:
        return "Upload success";
      default:
        return "Uploading";
    }
  }, [status]);

  return (
    <ProgressContainer>
      <InfoCont>
        <PercentCont>{showProgress}</PercentCont>
        <StatusCont>{statusLabel}</StatusCont>
        {/* TODO: remove false, it's temporary solution */}
        {false && <IconCont>{status === FileUploadStatus.PAUSED ? <Icon.UploadPaused /> : <Icon.Close />}</IconCont>}
      </InfoCont>
      <ProgressBarCont>
        <ProgressBar progress={showProgress} isPaused={status === FileUploadStatus.PAUSED} />
      </ProgressBarCont>
    </ProgressContainer>
  );
}
