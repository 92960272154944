import { init, RematchDispatch, RematchRootState } from "@rematch/core";
import loadingPlugin, { ExtraModelsFromLoading } from "@rematch/loading";
import persistPlugin from "@rematch/persist";
import storage from "redux-persist/lib/storage";

import { models, RootModel } from "src/models/index";
import { getUrlAuthToken } from "src/utils/auth.utils";

const urlAuthToken = getUrlAuthToken();

// clear local storage to prevent redux persist from overriding the authToken
if (urlAuthToken) {
  window.localStorage.clear();
}

type FullModel = ExtraModelsFromLoading<RootModel, { type: "full" }>;

const store = init<RootModel, FullModel>({
  models,
  plugins: [
    loadingPlugin({ type: "full" }),
    persistPlugin(
      {
        storage,
        key: "REDUX_STORE",
        whitelist: [],
      },
      {
        session: {
          storage,
          key: "authToken",
          whitelist: ["authToken"],
        },
      } as any,
    ),
  ],
  redux: {
    rootReducers: {
      RESET_APP: () => undefined,
    },
  },
});

const rootActions = {
  resetApp: () => {
    storage.removeItem("persist:REDUX_STORE");
    storage.removeItem("persist:authToken");
    store.dispatch({ type: "RESET_APP" });
  },
};

store.dispatch = Object.assign(store.dispatch, rootActions);

export default store;

export type Dispatch = RematchDispatch<RootModel> & typeof rootActions;
export type RootState = RematchRootState<RootModel, FullModel>;
export type RootStateWithoutPlugins = RematchRootState<RootModel>;

if (urlAuthToken) {
  store.dispatch.session.setAuthToken(urlAuthToken);
}
