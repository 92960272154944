import { useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";

import { Dispatch } from "src/models/store";
import apiClient from "src/network/ApiClient";

import { useModal } from "src/components/providers/ModalProvider";
import ErrorModal from "src/components/modals/ErrorModal";

export default function AppErrorReporter() {
  const dispatch = useDispatch<Dispatch>();
  const { openModal } = useModal();

  useEffect(() => {
    const onError = (statusCode: number, error: Error) => {
      if (statusCode === 500) {
        if (apiClient.isClientError(error)) {
          dispatch.session.setRecentError(error.response.errors?.[0].message);
        } else if (axios.isAxiosError(error)) {
          dispatch.session.setRecentError(error.message);
        }

        openModal(ErrorModal);
      }
    };

    apiClient.on("error", onError);

    return () => {
      apiClient.off("error", onError);
    };
  }, [dispatch, openModal]);

  return null;
}
