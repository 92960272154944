import { useEffect } from "react";
import TWEEN from "@tweenjs/tween.js";

// TODO: handle multiple components using this hook in parallel
export default function useTween() {
  useEffect(() => {
    let requestId: number;

    const animate = (time: number) => {
      requestId = requestAnimationFrame(animate);
      TWEEN.update(time);
    };

    requestId = requestAnimationFrame(animate);

    return () => {
      cancelAnimationFrame(requestId);
    };
  }, []);
}
