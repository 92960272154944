import { ChangeEvent } from "react";
import styled from "styled-components/macro";

import { themeColor } from "src/utils/styledComponents.utils";

import Icon from "src/components/common/Icon/Icon";

const CheckboxWrapper = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  display: none;
`;

const StyledCheckbox = styled.div<{ checked: boolean }>`
  width: 18px;
  height: 18px;
  border: 1px solid;
  border-color: ${({ checked }) => (checked ? themeColor("gray.300") : themeColor("pink.300"))};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  background-color: ${({ checked }) => (checked ? themeColor("pink.500") : themeColor("pink.300"))};

  svg {
    fill: none;
    stroke: ${themeColor("white")};
    stroke-width: 2px;
    visibility: ${({ checked }) => (checked ? "visible" : "hidden")};
  }

  ${HiddenCheckbox}:checked + & {
    background-color: ${themeColor("pink.500")};
    border-color: ${themeColor("pink.500")};

    svg {
      visibility: visible;
    }
  }
`;

export const Label = styled.span`
  margin-left: 8px;
  font-size: 14px;
`;

interface CheckboxProps {
  onChange(isChecked: boolean): void;
  isChecked: boolean;
  label?: string;
}

export default function Checkbox({ label, isChecked, onChange }: CheckboxProps) {
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    onChange?.(event.target.checked);
  };

  return (
    <CheckboxWrapper>
      <HiddenCheckbox checked={isChecked} onChange={handleCheckboxChange} />

      <StyledCheckbox checked={isChecked}>
        <Icon.Check />
      </StyledCheckbox>

      {label && <Label>{label}</Label>}
    </CheckboxWrapper>
  );
}
