/* eslint-disable no-restricted-syntax,no-continue */

import { flow, update, sortBy } from "lodash/fp";

import { TimeInterval, TimeIntervalEdge } from "src/types/video-trimmer.types";

import { roundBy } from "src/utils/math.utils";

// TODO: remove the word time
export const timeIntervalUtils = {
  equals<T extends TimeInterval>(interval1: T, interval2: T) {
    return interval1.start === interval2.start && interval1.end === interval2.end;
  },

  duration<T extends TimeInterval>(interval: T) {
    return interval.end - interval.start;
  },

  middle<T extends TimeInterval>(interval: T) {
    return interval.start + this.duration(interval) / 2;
  },

  findIndexByTimePoint<T extends TimeInterval>(intervals: T[], point: number, strict = true) {
    let start = 0;
    let end = intervals.length - 1;
    let mid = null;

    while (start <= end) {
      mid = Math.floor((start + end) / 2);

      if (this.includesTimePoint(intervals[mid], point)) {
        return mid;
      }

      if (intervals[mid].end < point) {
        start = mid + 1;
      } else {
        end = mid - 1;
      }
    }

    return strict ? null : mid;
  },

  findIndexByInterval<T extends TimeInterval>(intervals: T[], interval: T, strict = true) {
    let start = 0;
    let end = intervals.length - 1;
    let mid = null;

    while (start <= end) {
      mid = Math.floor((start + end) / 2);

      if (this.containsTimeInterval(intervals[mid], interval)) {
        return mid;
      }

      if (intervals[mid].end < interval.start) {
        start = mid + 1;
      } else {
        end = mid - 1;
      }
    }

    return strict ? null : mid;
  },

  includesTimePoint<T extends TimeInterval>(interval: T, point: number, includeEdges = true) {
    return includeEdges
      ? interval.start <= point && interval.end >= point
      : interval.start < point && interval.end > point;
  },

  containsTimeInterval<T extends TimeInterval, C extends TimeInterval>(interval: T, maybeContained: C) {
    return interval.start <= maybeContained.start && interval.end >= maybeContained.end;
  },

  intersectsTimeInterval<T1 extends TimeInterval, T2 extends TimeInterval>(interval: T1, maybeIntersected: T2) {
    return (
      this.includesTimePoint(interval, maybeIntersected.start, false) ||
      this.includesTimePoint(interval, maybeIntersected.end, false) ||
      this.containsTimeInterval(interval, maybeIntersected)
    );
  },

  applyMinStart<T extends TimeInterval>(interval: T, minStart: number): T {
    return update("start", (currentStart) => Math.max(currentStart, minStart), interval);
  },

  applyMaxEnd<T extends TimeInterval>(interval: T, maxEnd: number): T {
    return update("end", (currentEnd) => Math.min(currentEnd, maxEnd), interval);
  },

  applyBorders<T extends TimeInterval, B extends TimeInterval>(interval: T, borders: B) {
    return flow(
      (currInterval: T) => this.applyMinStart(currInterval, borders.start),
      (currInterval: T) => this.applyMaxEnd(currInterval, borders.end),
    )(interval);
  },

  roundBy<T extends TimeInterval>(interval: T, by: number): T {
    return {
      ...interval,
      start: roundBy(by, interval.start),
      end: roundBy(by, interval.end),
    };
  },

  slice<T extends TimeInterval>(interval: T, point: number): [T | null, T | null] {
    if (!this.includesTimePoint(interval, point, false)) {
      if (point <= interval.start) {
        return [null, interval];
      }

      return [interval, null];
    }

    return [
      { ...interval, start: interval.start, end: point },
      { ...interval, start: point, end: interval.end },
    ];
  },

  merge<T extends TimeInterval>(a: T, b: T): T {
    return {
      ...a,
      start: Math.min(a.start, b.start),
      end: Math.max(a.end, b.end),
    };
  },

  shift<T extends TimeInterval, B extends TimeInterval>(interval: T, delta: number, borders?: B) {
    const shiftedInterval = {
      ...interval,
      start: interval.start + delta,
      end: interval.end + delta,
    };

    if (borders) {
      if (shiftedInterval.start < borders.start) {
        shiftedInterval.start = borders.start;
        shiftedInterval.end = borders.start + this.duration(interval);
      }

      if (shiftedInterval.end > borders.end) {
        shiftedInterval.end = borders.end;
        shiftedInterval.start = borders.end - this.duration(interval);
      }
    }

    return shiftedInterval;
  },

  extend<T extends TimeInterval>(interval: T, deltaStart: number, deltaEnd: number) {
    if (deltaStart < 0 || deltaEnd < 0) {
      return interval;
    }

    return {
      ...interval,
      start: interval.start - deltaStart,
      end: interval.end + deltaEnd,
    };
  },

  shrink<T extends TimeInterval>(interval: T, deltaStart: number, deltaEnd: number) {
    if (deltaStart < 0 || deltaEnd < 0) {
      return interval;
    }

    return {
      ...interval,
      start: interval.start + deltaStart,
      end: interval.end - deltaEnd,
    };
  },

  pad<T extends TimeInterval>(interval: T, padding: number) {
    return this.extend(interval, padding, padding);
  },

  unpad<T extends TimeInterval>(interval: T, padding: number) {
    return this.shrink(interval, padding, padding);
  },

  slicePercentage<T extends TimeInterval>(interval: T, percentage: number, from: TimeIntervalEdge) {
    const duration = this.duration(interval);
    const p2 = interval[from] + duration * percentage * (from === "start" ? 1 : -1);

    return {
      start: from === "start" ? interval.start : p2,
      end: from === "end" ? interval.end : p2,
    };
  },

  zoom<T extends TimeInterval>(interval: T, zoomPoint: number, zoomFactor: number) {
    const left = zoomPoint - interval.start;
    const right = interval.end - zoomPoint;

    return {
      ...interval,
      start: zoomPoint - left / zoomFactor,
      end: zoomPoint + right / zoomFactor,
    };
  },

  resolveOverlaps<T extends TimeInterval>(intervals: T[], mergeAdjacent?: boolean | ((a: T, b: T) => boolean)) {
    if (intervals.length <= 1) return intervals;

    const sortedIntervals = sortBy("start", intervals);
    const mergedIntervals: T[] = [];
    let currentInterval = { ...sortedIntervals[0] };

    for (let i = 1; i < sortedIntervals.length; i += 1) {
      const interval = sortedIntervals[i];
      const shouldMergeAdjacent = typeof mergeAdjacent === "function" ? mergeAdjacent(currentInterval, interval) : mergeAdjacent; // prettier-ignore

      // If intervals overlap, merge them
      if (shouldMergeAdjacent ? interval.start <= currentInterval.end : interval.start < currentInterval.end) {
        currentInterval.end = Math.max(currentInterval.end, interval.end);
      } else {
        // Otherwise, add current interval to merged intervals and start a new interval
        mergedIntervals.push(currentInterval);
        currentInterval = { ...interval };
      }
    }

    // Add the last interval to the merged intervals array
    mergedIntervals.push(currentInterval);

    return mergedIntervals;
  },

  subtractTimeIntervals<M extends TimeInterval, S extends TimeInterval>(minuend: M[], subtrahend: S[]) {
    const result: M[] = [];

    for (const interval1 of minuend) {
      let remainingInterval: M | null = { ...interval1 };

      for (const interval2 of subtrahend) {
        if (interval2.start >= remainingInterval.end || interval2.end <= remainingInterval.start) {
          // No overlap between the two intervals
          continue;
        }

        if (interval2.start <= remainingInterval.start && interval2.end >= remainingInterval.end) {
          // The second interval completely covers the remaining interval
          remainingInterval = null;
          break;
        }

        if (interval2.start > remainingInterval.start && interval2.end < remainingInterval.end) {
          // The second interval is inside the remaining interval
          result.push({ ...remainingInterval, end: interval2.start });
          remainingInterval.start = interval2.end;
        } else if (interval2.start <= remainingInterval.start) {
          // The second interval overlaps the beginning of the remaining interval
          remainingInterval.start = interval2.end;
        } else if (interval2.end >= remainingInterval.end) {
          // The second interval overlaps the end of the remaining interval
          remainingInterval.end = interval2.start;
        }
      }

      if (remainingInterval !== null) {
        result.push(remainingInterval);
      }
    }

    return result;
  },

  invertTimeIntervals<T extends TimeInterval>(intervals: T[], threshold: number, border: TimeInterval) {
    const invertedIntervals: TimeInterval[] = [];

    let lastEnd = border.start;

    for (let i = 0; i < intervals.length; i += 1) {
      const interval = intervals[i];

      if (interval.start - lastEnd > threshold) {
        invertedIntervals.push({
          start: lastEnd,
          end: interval.start,
        });
      }

      lastEnd = interval.end;
    }

    if (border.end - lastEnd > threshold) {
      invertedIntervals.push({
        start: lastEnd,
        end: border.end,
      });
    }

    return invertedIntervals;
  },

  findClosestIntervalBefore<T extends TimeInterval>(intervals: T[], point: number) {
    let closestInterval: T | null = null;

    for (const interval of intervals) {
      if (interval.end >= point) {
        break;
      }

      closestInterval = interval;
    }

    return closestInterval;
  },

  findClosestIntervalAfter<T extends TimeInterval>(intervals: T[], point: number) {
    let closestInterval: T | null = null;

    for (const interval of intervals) {
      if (interval.start > point) {
        closestInterval = interval;
        break;
      }
    }

    return closestInterval;
  },

  equallyDistributeIntervals<T extends TimeInterval>(intervals: T[] | any, timeInterval?: TimeInterval): T[] {
    if (!intervals || !intervals.length || (!intervals[0]?.start && !timeInterval)) {
      return intervals;
    }
    // when no interval passed - use the first and the last intervals to calculate the duration for distribution
    if (!timeInterval) {
      timeInterval = {
        start: intervals?.[0]?.start || 0,
        end: intervals?.[intervals.length - 1]?.end || 1,
      };
    }

    sortBy("start", intervals);
    const fullDuration = timeInterval.end - timeInterval.start;
    const totalIntervals: number = intervals.length;
    const newIntervalDuration = fullDuration / totalIntervals;

    let currentIntervalStart = timeInterval.start;

    intervals.forEach((interval: TimeInterval) => {
      const currentIntervalEnd = currentIntervalStart + newIntervalDuration;
      interval.start = parseFloat(currentIntervalStart.toFixed(3));
      interval.end = parseFloat(currentIntervalEnd.toFixed(3));
      currentIntervalStart = currentIntervalEnd;
    });

    return intervals;
  },

  intervalsIntersectingMultipleSelections(
    all: TimeInterval[],
    selected: TimeInterval[],
    maybeIntersecting: TimeInterval[],
  ): TimeInterval[] {
    return maybeIntersecting.filter((intervalToCheck: TimeInterval) => {
      const intervalBefore = this.findClosestIntervalBefore(all, intervalToCheck.start);
      const intervalAfter = this.findClosestIntervalAfter(all, intervalToCheck.end);
      const isBeforeSelected =
        intervalBefore && selected.some((selection) => this.intersectsTimeInterval(selection, intervalBefore));
      const isAfterSelected =
        intervalAfter && selected.some((selection) => this.intersectsTimeInterval(selection, intervalAfter));
      return isBeforeSelected && isAfterSelected;
    });
  },
};
