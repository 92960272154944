import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { themeColor, themeZ } from "src/utils/styledComponents.utils";
import defaultSpeakerAvatar from "src/assets/images/empty-avatar.png";

import { RootState } from "src/models/store";
import { speakerCuePointSelectors } from "src/models/SpeakerQuePoint.model";

import { useAppConfig } from "src/components/providers/AppConfigProvider";
import { Input } from "src/components/common/form/smart-form-components";
import MediaSource from "src/components/common/media/MediaSource";
import { SpeakersForm } from "src/components/modals/SpeakersModal/speakersForm";

const SpeakerComponent = styled.div`
  z-index: ${themeZ("onboardingSwiper")};
  width: 404px;
  height: 146px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 1px 5px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 660px) {
    height: 100%;
    width: 100%;
    border: solid 1px;
    border-color: ${themeColor("gray.300")};
    box-shadow: none;
    flex-direction: column;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 10px;

  @media (max-width: 660px) {
    height: 60px;
    width: 60px;
  }
`;

const SpeakerImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
`;

const InputsContainer = styled.div`
  margin-left: 10px;
  height: 80px;
  width: 280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 660px) {
    width: 90%;
    margin-left: 0;
  }

  input {
    margin-top: 5px;
    width: 460px;
    max-width: 100%;
    height: 30px;
    border: 1px solid ${themeColor("gray.400")};
    border-radius: 3px;
    background-color: ${themeColor("white")};
    padding: 8.5px 10px;
    color: ${themeColor("gray.900")};
    font-family: "Work Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
  }

  input:focus {
    outline: none !important;
    border: 1px solid ${themeColor("gray.700")};
    box-shadow: 0 0 1px ${themeColor("gray.700")};
  }
`;

interface SpeakerSlideProps {
  index: number;
  sequenceSid: string;
}

export default function SpeakerSlide({ index, sequenceSid }: SpeakerSlideProps) {
  const { CHUNKS_URL } = useAppConfig();
  const undetectedSpeakerCuePoints = useSelector((state: RootState) => speakerCuePointSelectors.selectSequenceUndetectedSpeakerCuePoints(state, sequenceSid)); // prettier-ignore
  const speakerCuePoint = undetectedSpeakerCuePoints[index];
  const speakerAvatarSrc = `${CHUNKS_URL}/c/${sequenceSid}/${speakerCuePoint.sid}/${speakerCuePoint.thumbnailVersion}.png`;

  return (
    <SpeakerComponent>
      <ImageContainer>
        <SpeakerImage src={defaultSpeakerAvatar} alt="default speaker avatar" />

        <MediaSource src={speakerAvatarSrc}>
          {(src) => (
            <SpeakerImage
              src={src}
              alt="speaker avatar"
              onError={(e) => {
                e.currentTarget.style.display = "none";
              }}
            />
          )}
        </MediaSource>
      </ImageContainer>

      <InputsContainer>
        <Input<SpeakersForm>
          name={`speakers.${index}.name`}
          id="speaker-name-input"
          type="text"
          placeholder="Add name here"
          inputSize="medium"
        />

        <Input<SpeakersForm>
          name={`speakers.${index}.title`}
          id="speaker-title-input"
          type="text"
          placeholder="Add title here"
          inputSize="medium"
        />
      </InputsContainer>
    </SpeakerComponent>
  );
}
