/* eslint-disable jsx-a11y/media-has-caption */
import { Maybe } from "graphql/jsutils/Maybe";
import { isNil, range } from "lodash/fp";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";

import paddleService from "src/services/Paddle.service";

import * as plansAnalytics from "src/analytics/plans.analytics";

import { Plan } from "src/network/graphql/generatedGraphqlSDK";

import { COMPANIES_LOGOS } from "src/constants/customerShowcase.constants";
import { ChargeType, PlanStatus } from "src/constants/model.constants";

import { themeColor } from "src/utils/styledComponents.utils";

import { planSelectors } from "src/models/Plan.model";
import { sessionSelectors } from "src/models/Session.model";
import { userSelectors } from "src/models/User.model";
import { Dispatch, RootState } from "src/models/store";

import useLocalStorage from "src/hooks/useLocalStorage";
import { useMediaQuery } from "src/hooks/useMediaQuery";

import { Box } from "src/components/common/layout/Box.styled";
import { Stack } from "src/components/common/layout/Stack.styled";
import FictiveCard from "src/components/features/Plans/FictiveCard";
import PlanCard from "src/components/features/Plans/PlanCard";
import PlansButtonCreator from "src/components/features/Plans/PlansElements/PlansButtonCreator";
import PlansPriceBlockCreator from "src/components/features/Plans/PlansElements/PlansPriceBlockCreator";
import Switcher from "src/components/features/Plans/Switcher";
import TestimonialBlock from "src/components/features/Plans/TestimonialBlock";
import { POSTER_URL, VIDEO_URL } from "src/components/features/Plans/plans.constants";
import { useAppConfig } from "src/components/providers/AppConfigProvider";
import { absoluteRoutes } from "src/utils/routes.utils";
// import PlansIncludeBlock from "src/components/features/Plans/PlansIncludeBlock";

const Container = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: ${themeColor("gray.200")};
  overflow: scroll;
  padding: 50px 20px 20px 20px;

  * {
    font-family: "Poppins";
  }
`;

const MainBlock = styled(Box)`
  position: relative;
  display: flex;
  width: 100%;
`;

const TopBlock = styled(MainBlock)`
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const Header = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 30px;
  line-height: 52px;
  color: ${themeColor("gray.900")};
  span {
    color: ${themeColor("pink.500")};
  }

  @media only screen and (min-width: 654px) {
    font-size: 40px;
  }
`;

const SubHeader = styled(Header)`
  font-size: 20px;
  font-weight: 300;
  margin-block: 10px 20px;

  @media only screen and (min-width: 654px) {
    font-size: 30px;
  }
`;

const CentralBlock = styled(MainBlock)`
  display: grid;
  justify-content: center;
  height: fit-content;
  grid-template-columns: auto;
  row-gap: 10px;

  @media only screen and (min-width: 654px) {
    grid-template-columns: repeat(2, auto);
  }
  @media only screen and (min-width: 1366px) {
    grid-template-columns: repeat(4, auto);
  }
`;

const MediaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-block: 50px;
`;

const MediaHeader = styled.div`
  width: 100%;
  font-size: 16px;
  font-weight: 400;

  span {
    font-weight: 700;
  }

  @media only screen and (min-width: 654px) {
    font-size: 36px;
    margin-bottom: 40px;
  }

  @media only screen and (min-width: 1366px) {
    margin-bottom: 10px;
  }
`;

const MediaBlock = styled(MainBlock)`
  width: 96%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media only screen and (min-width: 420px) {
    width: 320px;
  }

  @media only screen and (min-width: 654px) {
    width: 660px;
  }

  @media only screen and (min-width: 1024px) {
    min-width: 656px;
  }

  @media only screen and (min-width: 1366px) {
    flex-direction: row;
    align-items: space-between;
    min-width: 1360px;
  }

  video {
    margin: 10px 0 10px;
    width: 100%;
    border-radius: 12px;

    @media only screen and (min-width: 1366px) {
      margin: 10px 20px 0 0;
      width: 66%;
    }
  }
`;

const LogosContainer = styled(Stack)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  min-width: 300px;
  margin-top: 30px;

  @media only screen and (min-width: 420px) {
    width: 320px;
  }

  @media only screen and (min-width: 654px) {
    width: 660px;
  }

  @media only screen and (min-width: 1024px) {
    min-width: 656px;
  }

  @media only screen and (min-width: 1366px) {
    min-width: 1000px;
  }
`;

const LogosText = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 25px;
  font-weight: 400;
  line-height: 36px;
  margin: 20px;
`;

const CompanyLogo = styled.img`
  margin: 10px;
`;
interface PlanProps {
  planId?: string | null;
  chargeType?: string | null;
}
export default function Plans({ planId, chargeType }: PlanProps) {
  const dispatch = useDispatch<Dispatch>();
  const currentUser = useSelector(userSelectors.selectCurrentUser);
  const authToken = useSelector((state: RootState) => state.session.authToken);
  const plans = useSelector(planSelectors.selectActivePlans);
  const plansProperties = useSelector(planSelectors.selectPlansProperties);
  const usersPlan = useSelector(planSelectors.selectUsersPlan);
  const shopper = useSelector((state: RootState) => state.session.shopper);
  const shopperFetchedSuccess = useSelector((state: RootState) => state.loading.effects.session.getShopper.success);
  const calculateInitialChargeType = useSelector(sessionSelectors.getInitialChargeType);
  const [chosenChargeType, setChosenChargeType] = useState<ChargeType | null>(null);
  const [planIdFromStorage, setPlanIdFromStorage] = useLocalStorage("planId", "");
  const [chargeTypeFromStorage, setChargeTypeFromStorage] = useLocalStorage("chargeType", "");
  const planSelectedByStorage = useSelector((state: RootState) =>
    planSelectors.selectById(state, planIdFromStorage || ""),
  );
  const [expandedPlanSid, setExpandedPlanSid] = useState<Maybe<string>>(null);
  const isTablet = useMediaQuery("(min-width: 654px)");
  const isDesktop = useMediaQuery("(min-width: 1366px)");
  const currentUserId = currentUser?.sid;
  const currentPlanId = usersPlan?.sid ?? null;
  const shopperSid = shopper?.sid;
  const navigate = useNavigate();
  const appConfig = useAppConfig();
  const isLoadingPlans = useSelector(({ loading: { models } }: RootState) => models.plans.loading);
  const shouldShowLoading = useMemo(() => !plansProperties || isLoadingPlans, [isLoadingPlans, plansProperties]);

  const fictivePlugs = useMemo(() => {
    if (isDesktop) {
      return 4;
    }

    if (isTablet) {
      return 2;
    }

    return 1;
  }, [isTablet, isDesktop]);

  useEffect(() => {
    planId && setPlanIdFromStorage(planId);
    chargeType && setChargeTypeFromStorage(chargeType);
    window.history.replaceState({}, document.title, absoluteRoutes.plans);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch.plans.getPlans({
      filter: {
        statusIn: [PlanStatus.Active, PlanStatus.Default],
      },
    });
  }, [dispatch]);

  useEffect(() => {
    if (authToken) {
      dispatch.users.fetchCurrentUser();
    }
  }, [authToken, dispatch.users]);

  useEffect(() => {
    if (currentUser?.sid) {
      dispatch.plans.getPlan();
      dispatch.session.getSubscription();
      dispatch.session.getShopper();
    }
  }, [currentUser?.sid, dispatch.plans, dispatch.session]);

  useEffect(() => {
    if (calculateInitialChargeType) {
      const ct = Number(chargeType || chargeTypeFromStorage);
      if (ct === ChargeType.Yearly || ct === ChargeType.Monthly) {
        setChosenChargeType(ct);
      } else {
        setChosenChargeType(calculateInitialChargeType);
      }
    }
  }, [calculateInitialChargeType, chargeTypeFromStorage, chargeType]);

  const selectPlanByGuest = useCallback(
    (planSelected: Plan) => {
      if (planSelected.recurringPrice && planSelected.recurringPrice > 0 && chosenChargeType) {
        setPlanIdFromStorage(planSelected.sid ?? "");
        setChargeTypeFromStorage(chosenChargeType.toString());
      }
      navigate(absoluteRoutes.signup.self);
    },
    [chosenChargeType, navigate, setChargeTypeFromStorage, setPlanIdFromStorage],
  );

  const selectPlanByUser = useCallback(
    async (planSelected: Plan) => {
      if (!chosenChargeType) return;

      plansAnalytics.trackPlanRequested(planSelected, currentPlanId, chosenChargeType);

      if (shopperSid || (planSelected.sid && planSelected.recurringPrice === 0)) {
        setChargeTypeFromStorage("");
        setPlanIdFromStorage("");
        await dispatch.plans.selectPlan({ planSid: planSelected.sid!, chargeType: chosenChargeType });
        navigate(absoluteRoutes.home);
      } else {
        const product = chosenChargeType === ChargeType.Yearly ? planSelected.annualPlanId : planSelected.planId;
        if (!product || !currentUserId) return;

        await paddleService.init(appConfig);
        paddleService.openCheckout({
          product,
          userSid: currentUserId!,
          planSid: planSelected.sid!,
          chargeType: chosenChargeType!.toString(),
          successCallback: async () => {
            setTimeout(async () => {
              await dispatch.session.getSubscription();
              await dispatch.plans.getPlan();
              navigate(absoluteRoutes.home);
            }, 1000);
            setChargeTypeFromStorage("");
            setPlanIdFromStorage("");
          },
          closeCallback: () => {
            setChargeTypeFromStorage("");
            setPlanIdFromStorage("");
          },
        });
      }
    },
    [
      appConfig,
      chosenChargeType,
      currentUserId,
      dispatch,
      navigate,
      setChargeTypeFromStorage,
      setPlanIdFromStorage,
      shopperSid,
      currentPlanId,
    ],
  );

  useEffect(() => {
    if (planSelectedByStorage && chosenChargeType && plans.length > 1 && currentUser && shopperFetchedSuccess) {
      selectPlanByUser(planSelectedByStorage);
    }
  }, [planSelectedByStorage, chosenChargeType, plans.length, currentUser, selectPlanByUser, shopperFetchedSuccess]);

  const calcShouldShowMoreFeatures = useCallback(
    (planSid: Maybe<string>) => (expandedPlanSid && (isTablet || isDesktop)) || expandedPlanSid === planSid,
    [isDesktop, isTablet, expandedPlanSid],
  );

  const openCloseFeaturesList = useCallback(
    (planSid: Maybe<string>) => {
      if (!calcShouldShowMoreFeatures(planSid)) {
        setExpandedPlanSid(planSid);
      } else {
        setExpandedPlanSid(null);
      }
    },
    [calcShouldShowMoreFeatures],
  );

  const getPlanType = (plan: Plan) => {
    if (plan.salesOnly) {
      return "custom";
    }
    if (plan.recurringPrice) {
      return "paid";
    }
    return "free";
  };

  const getPlanRegularFeatures = useCallback(
    (plan: Plan) => plan?.features?.filter((x) => x && x[0].trim() !== "*").map((x) => x?.trim()),
    [],
  );
  const getPlanAdditionalFeatures = useCallback(
    (plan: Plan) => plan?.features?.filter((x) => x && x[0].trim() === "*").map((x) => x?.trim().replace("*", "")),
    [],
  );

  const getAmountText = useCallback((plan: Plan) => (isNil(plan.pricePerSeatMonthly) ? "/ month" : "Seat / Month"), []);

  const getAmountAlignment = useCallback((plan: Plan) => (isNil(plan.pricePerSeatMonthly) ? "baseline" : "center"), []);

  useEffect(() => {
    if (isTablet || isDesktop) {
      setExpandedPlanSid(plans[0]?.sid);
    }
  }, [plans, isTablet, isDesktop]);

  return (
    <Container>
      <TopBlock marginTop={20}>
        <Header>
          An AI video post-production platform, <span>designed for scale. </span>
        </Header>
        <SubHeader>Get limitless supply of branded videos from your content</SubHeader>
        {/* <PlansIncludeBlock /> */}
        {plansProperties && plans.length > 1 && (
          <Switcher chosenChargeType={chosenChargeType!} setChosenChargeType={setChosenChargeType} />
        )}
      </TopBlock>
      <CentralBlock marginBlock={30}>
        {!shouldShowLoading
          ? plans?.map(
              (item) =>
                item && (
                  <PlanCard
                    key={item.sid!}
                    planSid={item.sid}
                    planName={item.name}
                    planDescription={item.description}
                    planRegularFeaturesList={getPlanRegularFeatures(item)}
                    planAdditionalFeaturesList={getPlanAdditionalFeatures(item)}
                    planColor={item.buttonColor}
                    planAdditionalColor={item.buttonAditionalColor}
                    includesShutterstock={item.showShutterStockBadge}
                    planPriceBlock={
                      <PlansPriceBlockCreator
                        planOfCard={item}
                        chosenChargeType={chosenChargeType}
                        setChosenChargeType={setChosenChargeType}
                        amountText={getAmountText(item)}
                        amountAlignment={getAmountAlignment(item)}
                      />
                    }
                    promptTag={item.promptTag}
                    planButton={
                      <PlansButtonCreator
                        planOfCard={item}
                        chosenChargeType={chosenChargeType}
                        selectPlanByUser={selectPlanByUser}
                        selectPlanByGuest={selectPlanByGuest}
                      />
                    }
                    isCurrent={usersPlan?.sid === item.sid}
                    shouldShowMoreFeatures={calcShouldShowMoreFeatures(item.sid)}
                    setExpandedPlanSid={setExpandedPlanSid}
                    openCloseFeaturesList={openCloseFeaturesList}
                    planType={getPlanType(item)}
                  />
                ),
            )
          : range(0, fictivePlugs).map((item) => <FictiveCard key={item} />)}
      </CentralBlock>
      <MediaWrapper>
        <MediaHeader>
          Discover Peech’s <span>Different Plans</span>
        </MediaHeader>
        <MediaBlock>
          <video src={VIDEO_URL} controls poster={POSTER_URL} />
          <TestimonialBlock />
        </MediaBlock>
      </MediaWrapper>

      <LogosContainer marginTop={10} direction="row" spacing={20}>
        <LogosText>Trusted by unstoppable companies like:</LogosText>
        {COMPANIES_LOGOS.map(({ logoImageBig, logoName }) => (
          <CompanyLogo key={logoName} src={logoImageBig} alt={logoName} title={logoName} />
        ))}
      </LogosContainer>
    </Container>
  );
}
