import styled from "styled-components/macro";
import BoardsOption from "src/components/features/Boards/BoardsOption";
import theme from "src/theme";
import { useState } from "react";
import { H3, Text3 } from "src/components/common/layout/typography.styled";
import { ModalComponentProps } from "src/components/providers/ModalProvider";
import Icon from "src/components/common/Icon";
import { themeColor } from "src/utils/styledComponents.utils";
import { useSelector } from "react-redux";
import { RootState } from "src/models/store";
import { boardSelectors } from "src/models/Board.model";
import { Button } from "src/components/common/buttons/Button.styled";
import { Stack } from "src/components/common/layout/Stack.styled";
import Popper from "src/components/common/popovers/Popper";
import { Board } from "src/network/graphql/generatedGraphqlSDK";
import { Panel } from "src/components/common/popovers/panel/Panel";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  align-items: stretch;
  text-align: left;
  height: 32.25rem;
  max-width: 38.5rem;
  max-height: 32.25rem;
  width: 38.5rem;
`;

const CloseDiv = styled.div`
  position: absolute;
  top: 14px;
  right: 20px;
  width: 14px;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: ${themeColor("gray.500")};
    }
  }
`;

const BoardsOptionWrapper = styled.div`
  display: flex;
  padding: 6px;
  border: solid 1px ${theme.colors.white};
  cursor: default;

  width: 100%;
  &:hover {
    border: solid 1px ${theme.colors.blue["200"]};
    border-radius: 4px;
    background: ${theme.colors.gray["50"]};
  }
`;

const BoardsContainer = styled.div`
  margin-top: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  height: inherit;
  border: 1px solid ${theme.colors.gray["225"]};
  border-radius: 6px;
  padding: 10px;
`;

const StyledButton = styled(Button)`
  position: relative;
  border-radius: 25px;
`;

const SubHeaderText = styled(Text3)`
  font-family: "Open Sans";
  color: ${theme.colors.gray["500"]};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
  font-size: 12px;
`;

const Title = styled(H3)`
  color: ${theme.colors.gray["900"]};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
  width: 90%;
`;

const MoveButton = styled(Button)`
  border-radius: 25px;
  right: 8px;
  position: relative;
`;

const Footer = styled(Stack)`
  flex-direction: row-reverse;
`;

const Header = styled(Stack)`
  padding: 16px 0px 0px 0px;
`;

interface BoardsModalProps {
  action: "move" | "add";
  footageId: string;
  footageName: string | undefined;
  onMoveToBoardClick: (
    focusedBoardId: string,
    originBoard: Board | undefined | null,
    targetBoard: Board | undefined,
  ) => void;
  onRemoveFromBoardClick: (originBoard: Board) => void;
}

export default function BoardsModal({
  action,
  close,
  footageId,
  footageName,
  onMoveToBoardClick,
  onRemoveFromBoardClick,
}: ModalComponentProps & BoardsModalProps) {
  const [focusedBoardId, setFocusedBoardId] = useState("");
  const boards = useSelector((state: RootState) => boardSelectors.selectAll(state));
  const currentBoard = useSelector((state: RootState) => boardSelectors.selectBoardByFootageId(state, footageId));
  const targetBoard = useSelector((state: RootState) => boardSelectors.selectById(state, focusedBoardId));

  const focusOnBoard = (id: string) => {
    setFocusedBoardId(id);
  };

  return (
    <Container>
      <Header direction="column" spacing={2}>
        <CloseDiv onClick={close}>
          <Icon.Close />
        </CloseDiv>
        <Title>
          {action === "move" ? "Move" : "Add"} &quot;{footageName}&quot;
        </Title>
        {currentBoard?.name && (
          <Stack marginTop={10} spacing={16} direction="row">
            <SubHeaderText>
              Current board: <b>{currentBoard?.name}</b>
            </SubHeaderText>
            <StyledButton
              variant="ghost"
              size="xs"
              onClick={() => {
                onRemoveFromBoardClick(currentBoard);
                close();
              }}
            >
              Remove from current board
            </StyledButton>
          </Stack>
        )}
      </Header>
      <BoardsContainer>
        {boards.map(({ id, name }) => (
          <BoardsOptionWrapper key={id} onMouseOver={() => focusOnBoard(id)} onFocus={() => focusOnBoard(id)}>
            <BoardsOption
              isFootageInBoard={currentBoard?.id === id}
              id={id}
              name={name}
              isFocused={id === focusedBoardId}
            >
              <Popper
                content={
                  id !== focusedBoardId ||
                  (currentBoard?.id === focusedBoardId && <Panel>Video already on this board</Panel>)
                }
                trigger="hover"
                placement="top"
              >
                <MoveButton
                  disabled={id !== focusedBoardId || currentBoard?.id === focusedBoardId}
                  variant="ghost"
                  size="xs"
                  onClick={() => {
                    onMoveToBoardClick(focusedBoardId, currentBoard, targetBoard);
                    close();
                  }}
                >
                  {action === "move" ? "Move" : "Add"}
                </MoveButton>
              </Popper>
            </BoardsOption>
          </BoardsOptionWrapper>
        ))}
      </BoardsContainer>
      <Footer direction="row" spacing={2} />
    </Container>
  );
}
