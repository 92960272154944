import { RefObject, useEffect, useState } from "react";

export default function useIsPlaying(videoRef: RefObject<HTMLMediaElement>) {
  const [isPlaying, setIsPlaying] = useState(!!videoRef.current && !videoRef.current.paused);

  useEffect(() => {
    const onPlay = () => setIsPlaying(true);
    const onPause = () => setIsPlaying(false);
    const video = videoRef.current;

    video?.addEventListener("play", onPlay);
    video?.addEventListener("pause", onPause);

    return () => {
      video?.removeEventListener("play", onPlay);
      video?.removeEventListener("pause", onPause);
    };
  }, [videoRef]);

  return isPlaying;
}
