/* eslint-disable no-bitwise */

import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from "react";
import { Navigate, useOutlet } from "react-router-dom";
import { useSelector } from "react-redux";
import * as FirebaseRemoteConfig from "firebase/remote-config";

import { RootState } from "src/models/store";
import { userSelectors } from "src/models/User.model";

import useShallowMemo from "src/hooks/useShallowMemo";

// 0-31 are available number add in right place
const flagMap = {
  0: "feedbackSent",
  1: "trimmer",
  2: "smartLibrary",
  3: "muteChapters",
  5: "ccManualPosition",
  6: "noUploadLimit",
  7: "experimentalKeepBlankWhenCutWord",
  8: "denoiserEnabled",
  10: "newProjectCreationFlow",
  12: "introEnabled",
  13: "contentEditorHighlight",
  14: "fastProjectCreationFlow",
  17: "dontSendFootageEmail",
  18: "splitTopicByOpenai",
  22: "skipContentEditExampleAsset",
  23: "cloneSequenceAspectRatioChange",
  24: "getContentBySpecificParts",
  25: "combinedVideoAudioFragmentsOnDownload",
  26: "checkVisualConflictsOnlyWithFirstSpeaker",
  27: "enableFoldersFeature",
  28: "generateAiClips",
  29: "processMusicInBackground",
  31: "createClipByTimestamps",
} as const satisfies Record<number, string>;

type FirerBaseFlagMap = "allowChangeEditRules" | "allowChangeEditRulesFirstTimeUser";

export type FlagMap = typeof flagMap;
export type FeatureName = ValueOf<FlagMap> | FirerBaseFlagMap;
export type FeatureFlagDict = Partial<Record<FeatureName, boolean>>;

export const FeatureFlagContext = createContext<{
  featureFlags: FeatureFlagDict;
  featureFlagsLoaded: boolean;
}>({
  featureFlags: {},
  featureFlagsLoaded: false,
});

export default function FeatureFlagsProvider({ children }: PropsWithChildren<{}>) {
  const [firebaseFlags, setFirebaseFlags] = useState<FeatureFlagDict | null>(null);

  useEffect(() => {
    const remoteConfig = FirebaseRemoteConfig.getRemoteConfig();
    FirebaseRemoteConfig.fetchAndActivate(remoteConfig).then(() => {
      const remoteConfigValues = FirebaseRemoteConfig.getAll(remoteConfig);
      const featureFlagDict = Object.entries(remoteConfigValues).reduce((acc, [feature, flagValue]) => {
        // peek only feature parameters that are not config
        if (feature.includes("config_")) {
          return {
            ...acc,
          };
        }
        return {
          ...acc,
          [feature]: flagValue.asBoolean(),
        };
      }, {});
      setFirebaseFlags(featureFlagDict);
    });
  }, []);

  const userFlags = useSelector((state: RootState) => {
    const uiFlags = userSelectors.selectById(state, state.session.userId)?.uiFlags;

    if (!uiFlags) return null;

    return Object.entries(flagMap).reduce(
      (acc, [flagNumber, flagName]) => ({
        ...acc,
        [flagName]: Boolean(uiFlags & (1 << Number(flagNumber))),
      }),
      {},
    );
  });

  const combinedFlags = useMemo<FeatureFlagDict>(
    () => (userFlags && firebaseFlags ? { ...userFlags, ...firebaseFlags } : {}),
    [firebaseFlags, userFlags],
  );

  const featureFlagsLoaded = !!(userFlags && firebaseFlags);

  return (
    <FeatureFlagContext.Provider
      value={useShallowMemo({
        featureFlags: combinedFlags,
        featureFlagsLoaded,
      })}
    >
      {children}
    </FeatureFlagContext.Provider>
  );
}

export const useFeatureFlag = (featureName: FeatureName) => {
  const { featureFlags } = useContext(FeatureFlagContext);

  return featureFlags[featureName];
};

export const useFeatureFlagsLoadingState = () => {
  const { featureFlagsLoaded } = useContext(FeatureFlagContext);

  return featureFlagsLoaded;
};

export function FeatureFlag({ name, children }: PropsWithChildren<{ name: FeatureName }>) {
  const isFeatureEnabled = useFeatureFlag(name);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return isFeatureEnabled ? <>{children}</> : null;
}

export function FeatureFlagRouteGate({ name, fallback }: { name: FeatureName; fallback: string }) {
  const outlet = useOutlet();
  const isFeatureEnabled = useFeatureFlag(name);
  const featureFlagsLoaded = useFeatureFlagsLoadingState();

  if (!featureFlagsLoaded) return null;

  return isFeatureEnabled ? outlet : <Navigate to={fallback} replace />;
}
