import { useVideoChapters } from "src/components/features/VideoTrimmer/providers/VideoChaptersProvider/VideoChaptersContext";
import { useVideoWords } from "src/components/features/VideoTrimmer/providers/VideoWordsProvider/VideoWordsContext";
import { useEffect, useState } from "react";
import useHistoryState from "src/hooks/useHistoryState";
import { ShortcutsKeys } from "src/constants/shortcuts.constants";
import useMousetrap from "./useMousetrap";

// this hook is used to handle global undo and redo for different history states.
// using useHistoryState, we track which non-global history made the last action and pull the correct action accordingly.
export default function useUndoRedo(): any {
  const globalHistory = useHistoryState("");
  const { chaptersHistory } = useVideoChapters();
  const { wordsHistory } = useVideoWords();
  const [lastAction, setLastAction] = useState("");

  /*
  we respectively pass the below functions as callbacks (see useEffect below) to the non-global history states
  when triggered (upon non-global history set), they:
   1. set the global history state with the last non-global history used
   2. clean the other history state's future as well
  */
  const onSetChapters = () => {
    globalHistory.set(`chapters ${new Date().getTime()}`);
    wordsHistory.resetFuture();
  };

  const onSetWords = () => {
    globalHistory.set(`words ${new Date().getTime()}`);
    chaptersHistory.resetFuture();
  };

  /*
  NOTICE: onUndo and onRedo behave differently!
  onUndo:
  1. we directly undo non-global history state based on current global history state
  2. we undo global history immediately after
  */
  const onUndo = () => {
    if (globalHistory.state.includes("words")) wordsHistory.undo();
    if (globalHistory.state.includes("chapters")) chaptersHistory.undo();
    globalHistory.undo();
  };

  /*
  NOTICE: onUndo and onRedo behave differently!
  onRedo:
  because the current global history state points to
  the last non-global history on undo, calling redo() directly will
  break the global and non-global undo/redo sync. to fix this we do the following:

  1. set the last action local state to "redo" so the hook is updated
  2. update the global history state to the next non-global history state (same like on undo)
  2. trigger the actual redo() in the useEffect below
  */
  const onRedo = () => {
    setLastAction("redo");
    globalHistory.redo();
  };

  // trigger actual redo() for non-global history state
  useEffect(() => {
    if (lastAction === "redo") {
      if (globalHistory.state.includes("words")) wordsHistory.redo();
      if (globalHistory.state.includes("chapters")) chaptersHistory.redo();
    }
    setLastAction("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalHistory.state]);

  // set the callbacks on the history state
  useEffect(() => {
    wordsHistory.onSet(onSetWords);
    chaptersHistory.onSet(onSetChapters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // set keyboard shortcuts
  useMousetrap([ShortcutsKeys.undo], onUndo, { preventDefault: true, allowRepeat: true });
  useMousetrap([ShortcutsKeys.redo], onRedo, { preventDefault: true, allowRepeat: true });

  return {
    canUndo: globalHistory.canUndo,
    canRedo: globalHistory.canRedo,
    onUndo: () => onUndo(),
    onRedo: () => onRedo(),
  };
}
