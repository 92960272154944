import { Dispatch, SetStateAction, useMemo } from "react";
import { useSelector } from "react-redux";

import { ifProp, styleProp, themeColor } from "src/utils/styledComponents.utils";
import styled from "styled-components/macro";

import { Maybe } from "graphql/jsutils/Maybe";
import { Plan } from "src/network/graphql/generatedGraphqlSDK";

import { planSelectors } from "src/models/Plan.model";

import { ChargeType } from "src/constants/model.constants";

export const SalesOnlyPriceBlock = styled.div<{ textColor: Maybe<string> | undefined }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-weight: 700;
  font-size: 28px;
  line-height: 46px;
  color: ${styleProp("textColor", themeColor("gray.500"))};
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const Description = styled.div<{ textColor?: string | null; isClickable: boolean }>`
  width: 100%;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: ${styleProp("textColor", themeColor("gray.500"))};
  cursor: ${ifProp("isClickable", "pointer", "unset")};
`;

const SubHeaderTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: ${themeColor("gray.600")};
  width: 100%;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const AmountBlock = styled.div<{ alignment?: string }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: ${styleProp("alignment", "baseline")};
  font-weight: 700;
  font-size: 36px;
  line-height: 46px;
  color: ${themeColor("gray.900")};

  span {
    font-weight: 400;
    font-size: 17px;
    line-height: 16px;
    color: ${themeColor("gray.600")};
    width: 70px;
    margin-left: 10px;
  }
`;

interface PlansPriceBlockCreatorProps {
  planOfCard: Plan;
  chosenChargeType: ChargeType | null;
  setChosenChargeType: Dispatch<SetStateAction<ChargeType | null>>;
  amountText: string;
  amountAlignment: string;
}

export default function PlansPriceBlockCreator({
  planOfCard,
  chosenChargeType,
  setChosenChargeType,
  amountText,
  amountAlignment,
}: PlansPriceBlockCreatorProps) {
  const plansProperties = useSelector(planSelectors.selectPlansProperties);
  const shouldShowPriceDescription = false;

  const priceSubHeader = useMemo(() => plansProperties?.[planOfCard.sid!]?.subHeader, [planOfCard, plansProperties]);

  const whatCanYouSave = useMemo(() => {
    const amount = plansProperties?.[planOfCard.sid!]?.saveAmount;
    const percengtage = plansProperties?.[planOfCard.sid!]?.savePercentage;
    return {
      amount: amount ? Math.round(amount) : amount,
      percengtage: percengtage ? Math.round(percengtage) : percengtage,
    };
  }, [planOfCard, plansProperties]);

  const planPrice = useMemo(() => {
    if (chosenChargeType === ChargeType.Monthly) {
      return Math.round(plansProperties?.[planOfCard.sid!]?.monthly ?? 0);
    }

    if (chosenChargeType === ChargeType.Yearly) {
      return Math.round(plansProperties?.[planOfCard.sid!]?.yearly ?? 0);
    }

    return 0;
  }, [chosenChargeType, planOfCard, plansProperties]);

  return planOfCard.salesOnly ? (
    <SalesOnlyPriceBlock textColor={planOfCard.buttonColor}>Custom</SalesOnlyPriceBlock>
  ) : (
    <Container>
      <AmountBlock data-cy="plan-price" alignment={amountAlignment}>
        ${planPrice} <span>{amountText}</span>
      </AmountBlock>
      {planPrice && shouldShowPriceDescription ? (
        <Description
          textColor={planOfCard.buttonColor}
          data-cy="price-description"
          isClickable={chosenChargeType === ChargeType.Monthly}
          onClick={() => chosenChargeType === ChargeType.Monthly && setChosenChargeType(ChargeType.Yearly)}
        >
          {whatCanYouSave.percengtage && chosenChargeType === ChargeType.Monthly && (
            <span>
              <strong>
                <ins>Switch to annual to save {whatCanYouSave.percengtage}%</ins>
              </strong>
            </span>
          )}
          {whatCanYouSave.amount && chosenChargeType === ChargeType.Yearly && (
            <span>
              Save
              <strong> ${whatCanYouSave.amount} </strong>
              with annual plan
            </span>
          )}
        </Description>
      ) : null}
      <SubHeaderTitle>{priceSubHeader}</SubHeaderTitle>
    </Container>
  );
}
