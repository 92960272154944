import {
  PropsWithChildren,
  RefObject,
  MouseEvent as ReactMouseEvent,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { RootState } from "src/models/store";
import { timeIntervalUtils } from "src/utils/timeInterval.utils";
import { ChapterTimeInterval, TimeInterval, Word } from "src/types/video-trimmer.types";

import useIsPlaying from "src/hooks/useIsPlaying";
import useVariableRef from "src/hooks/useVariableRef";
import useShallowMemo from "src/hooks/useShallowMemo";

import { ResizeThumbGrabArea } from "src/components/features/VideoTrimmer/TimelineEditor/ChapterView";
import { useCurrentTimeListener } from "src/components/features/VideoTrimmer/providers/VideoCurrentTimeProvider/VideoCurrentTimeListenerContext";
import { useVideoChapters } from "src/components/features/VideoTrimmer/providers/VideoChaptersProvider/VideoChaptersContext";
import { useTimelineZoom } from "src/components/features/VideoTrimmer/providers/TimelineZoomProvider/TimelineZoomContext"; // prettier-ignore
import VideoPlaybackContext, {
  VideoPlaybackContextValue,
} from "src/components/features/VideoTrimmer/providers/VideoPlaybackProvider/VideoPlaybackContext";
import * as projectEditorAnalytics from "src/analytics/sequenceContentEditor.analytics"; // prettier-ignore
import { useAppConfig } from "src/components/providers/AppConfigProvider";
import { useSelector } from "react-redux";
import { useEventPublisher } from "src/hooks/useEventEmitter";
import { isEmpty } from "lodash";
import { UseHistoryStateReturnValue } from "src/hooks/useHistoryState";
import { InitialHistoryState } from "src/components/features/VideoTrimmer/common/hooks/useInitiatedHistory";
import { useSearchParams } from "react-router-dom";

const ACTIVE_CHAPTER_CHANGE_THRESHOLD = 0.005;

interface VideoPlaybackProviderProps extends PropsWithChildren {
  videoRef: RefObject<HTMLVideoElement>;
  videoDuration: number;
  activeChapterChangeThreshold?: number;
  wordsHistory: UseHistoryStateReturnValue<Word[]>;
  cropsHistory: UseHistoryStateReturnValue<InitialHistoryState>;
  chaptersHistory: UseHistoryStateReturnValue<ChapterTimeInterval[]>;
}

// TODO: CurrentTimeIndicator should overlap HoveredTimeIndicator when manual seeking
export default function VideoPlaybackProvider({
  children,
  videoRef,
  videoDuration,
  activeChapterChangeThreshold = ACTIVE_CHAPTER_CHANGE_THRESHOLD,
  wordsHistory,
  cropsHistory,
  chaptersHistory,
}: VideoPlaybackProviderProps) {
  const { PLAYBACK_RATES } = useAppConfig();
  const { chapters, resizedChapterIndex } = useVideoChapters();
  const { zoomFactor, scrollToTimeInterval } = useTimelineZoom();
  const [activeChapterIndex, setActiveChapterIndex] = useState(0);
  const [isManualSeeking, setIsManualSeeking] = useState(false);
  const chaptersRef = useVariableRef(chapters);
  const resizedChapterIndexRef = useVariableRef(resizedChapterIndex);
  const endOfZoomedChapterPauseFlagRef = useRef(false);
  const resizedChapter = chapters[resizedChapterIndex];
  const { offset: playFromOffset, chapterSid: playFromChapterSid } = useSelector(
    (state: RootState) => state.session?.playFrom,
  );
  const [playFromLocal, setPlayFromLocal] = useState({
    offset: playFromOffset,
    chapterSid: playFromChapterSid,
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const [playbackRate, setPlaybackRate] = useState(1);
  const publisher = useEventPublisher();

  const isPlaying = useIsPlaying(videoRef);

  const changePlaybackRate = useCallback(() => {
    const currentIndex = PLAYBACK_RATES.indexOf(playbackRate);
    const nextIndex = (currentIndex + 1) % PLAYBACK_RATES.length;
    setPlaybackRate(PLAYBACK_RATES[nextIndex]);
  }, [playbackRate, PLAYBACK_RATES]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current!.playbackRate = playbackRate;
    }
  }, [playbackRate, videoRef]);

  const startManualSeeking = useCallback(
    (mouseDownEvent: ReactMouseEvent) => {
      if (!videoRef.current) throw new Error("videoRef.current is null");

      setIsManualSeeking(true);
      const video = videoRef.current;
      const videoWasPlaying = !video.paused;
      const timelineTrackRect = mouseDownEvent.currentTarget.getBoundingClientRect();

      // TODO: use intersection API instead or elementFromPoint
      const target = mouseDownEvent.target as HTMLElement;
      const targetParent = target.parentNode as HTMLElement;
      const overResizeThumb =
        target.classList.contains(ResizeThumbGrabArea.styledComponentId) ||
        targetParent.classList.contains(ResizeThumbGrabArea.styledComponentId);

      if (overResizeThumb) return;

      const seekTimeByX = (x: number) => {
        if (video) {
          const relativeX = x - timelineTrackRect.x;
          const relativeXPercentage = relativeX / timelineTrackRect.width;
          const time = relativeXPercentage * videoDuration;

          // TODO: handle out of range (fast drag) reach end of interval fully
          if (chapters.some((chapter) => timeIntervalUtils.includesTimePoint(chapter, time))) {
            video.currentTime = time;
          }
        }
      };

      const onMouseMove = (mouseMoveEvent: MouseEvent) => {
        seekTimeByX(mouseMoveEvent.clientX);
      };

      const onMouseUp = () => {
        document.removeEventListener("mousemove", onMouseMove);
        setIsManualSeeking(false);
        if (videoWasPlaying) {
          video.play();
        }
      };

      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp, { once: true });

      video.pause();
      seekTimeByX(mouseDownEvent.clientX);
    },
    [chapters, videoDuration, videoRef],
  );

  const seekTimeInterval = useCallback(
    (timeInterval: TimeInterval) => {
      // TODO: try to decouple from chapters
      // TODO: use timeIntervalUtils.findIndexByInterval instead
      const relatedChapterIndex = chapters.findIndex(
        (chapter) =>
          timeIntervalUtils.includesTimePoint(chapter, timeInterval.start) ||
          timeIntervalUtils.includesTimePoint(chapter, timeInterval.end),
      );

      if (videoRef.current && relatedChapterIndex !== -1) {
        const { start, end } = chapters[relatedChapterIndex];

        // TODO: get rid of 0.001
        videoRef.current.currentTime = Math.max(start, Math.min(end, timeInterval.start + 0.001));
      }
    },
    [chapters, videoRef],
  );

  const seekDirection = useCallback(
    (direction: "backward" | "forward") => {
      const video = videoRef.current;
      const isForward = direction === "forward";

      if (!video) return;

      if (video.paused) {
        let seekTo: number | undefined;

        switch (video.currentTime) {
          case chapters[activeChapterIndex].start:
            if (isForward) {
              seekTo = chapters[activeChapterIndex].end;
            } else {
              seekTo =
                chapters[activeChapterIndex].start === chapters[activeChapterIndex - 1]?.end
                  ? chapters[activeChapterIndex - 1]?.start
                  : chapters[activeChapterIndex - 1]?.end;
            }
            break;

          case chapters[activeChapterIndex].end:
            if (isForward) {
              seekTo =
                chapters[activeChapterIndex].end === chapters[activeChapterIndex + 1]?.start
                  ? chapters[activeChapterIndex + 1]?.end
                  : chapters[activeChapterIndex + 1]?.start;
            } else {
              seekTo = chapters[activeChapterIndex].start;
            }
            break;

          default:
            seekTo = isForward ? chapters[activeChapterIndex].end : chapters[activeChapterIndex].start;
        }

        if (seekTo !== undefined) {
          video.currentTime = seekTo;
        }
      } else {
        video.pause();

        video.currentTime = isForward
          ? chapters[Math.min(chapters.length - 1, activeChapterIndex + 1)].start
          : chapters[Math.max(0, activeChapterIndex - 1)].start;

        requestAnimationFrame(() => video.play());
      }
    },
    [activeChapterIndex, chapters, videoRef],
  );

  const seekForward = useCallback(() => {
    seekDirection("forward");
    projectEditorAnalytics.trackSeekForward();
  }, [seekDirection]);
  const seekBackward = useCallback(() => {
    seekDirection("backward");
    projectEditorAnalytics.trackSeekBackward();
  }, [seekDirection]);

  const isTimePlayable = useCallback(
    (time: number) => chapters.some((chapter) => timeIntervalUtils.includesTimePoint(chapter, time)),
    [chapters],
  );

  /** pause video on chapter resize */
  useEffect(
    () => () => {
      if (resizedChapterIndex === -1) {
        videoRef.current?.pause();
      }
    },
    [resizedChapterIndex, videoRef],
  );

  /** sync currentTime with the resized edge on chapter resize */
  useEffect(() => {
    if (videoRef.current && resizedChapter?.start !== undefined) {
      videoRef.current.currentTime = resizedChapter.start;
    }
  }, [videoRef, resizedChapter?.start]);

  /** sync currentTime with the resized edge on chapter resize */
  useEffect(() => {
    if (videoRef.current && resizedChapter?.end !== undefined) {
      videoRef.current.currentTime = resizedChapter.end;
    }
  }, [videoRef, resizedChapter?.end]);

  /** sync activeChapterIndex with resizedChapterIndex */
  useEffect(() => {
    if (resizedChapterIndex !== -1) {
      setActiveChapterIndex(resizedChapterIndex);
    }
  }, [resizedChapterIndex]);

  /** mute the video on muted active chapter */
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = chapters[activeChapterIndex]?.isMuted ?? false;
    }
  }, [videoRef, chapters, activeChapterIndex]);

  /** handle unsynced currentTime with activeChapterIndex */
  useEffect(() => {
    const video = videoRef.current;
    const activeChapter = chapters[activeChapterIndex];

    if (!video || !chapters.length) {
      return;
    }

    if (!activeChapter) {
      video.currentTime = chapters[0].start;
      return;
    }

    const { currentTime } = video;

    if (!timeIntervalUtils.includesTimePoint(activeChapter, currentTime)) {
      const newActiveChapterIndex = timeIntervalUtils.findIndexByTimePoint(chapters, currentTime);

      if (newActiveChapterIndex === null) {
        video.currentTime = activeChapter.start;
      } else {
        setActiveChapterIndex(newActiveChapterIndex);
      }
    }
  }, [chapters, activeChapterIndex, videoRef]);

  /** sync activeChapterIndex with currentTime on currentTime updates
   *  handle chapter skipping logic
   *  make sure currentTime is always visible on the timeline while zoomed */
  useCurrentTimeListener((currentTime: number) => {
    if (resizedChapterIndexRef.current !== -1) return;
    if (!videoRef.current) throw new Error("videoRef.current is null");

    const video = videoRef.current;

    scrollToTimeInterval({ start: currentTime, end: currentTime });

    // TODO: improve readability
    if (
      !endOfZoomedChapterPauseFlagRef.current &&
      chapters[activeChapterIndex] &&
      zoomFactor > 1.1 &&
      !video.paused &&
      !timeIntervalUtils.includesTimePoint(chapters[activeChapterIndex], currentTime + activeChapterChangeThreshold)
    ) {
      endOfZoomedChapterPauseFlagRef.current = true;

      const onPlay = () => {
        video.currentTime = chapters[(activeChapterIndex + 1) % chapters.length].start;
      };

      const onSeeked = () => {
        endOfZoomedChapterPauseFlagRef.current = false;
        video.removeEventListener("play", onPlay);
      };

      video.addEventListener("play", onPlay, { once: true });
      video.addEventListener("seeked", onSeeked, { once: true });
      video.pause();

      return;
    }

    setActiveChapterIndex((currentActiveChapterIndex) => {
      const currentChapters = chaptersRef.current;
      const activeChapter = currentChapters[currentActiveChapterIndex];

      if (
        !activeChapter ||
        !timeIntervalUtils.includesTimePoint(activeChapter, currentTime + activeChapterChangeThreshold)
      ) {
        if (video.paused) {
          return timeIntervalUtils.findIndexByTimePoint(currentChapters, currentTime) ?? currentActiveChapterIndex;
        }

        const nextActiveChapterIndex = (currentActiveChapterIndex + 1) % currentChapters.length;

        if (nextActiveChapterIndex === 0 && !video.loop) {
          video.pause();
        }

        video.currentTime = currentChapters[nextActiveChapterIndex].start;

        return nextActiveChapterIndex;
      }

      return currentActiveChapterIndex;
    });
  });

  // emit the currentTime to be eventually set by session.playFrom
  useEffect(() => {
    if (videoRef.current?.currentTime) {
      videoRef.current.ontimeupdate = () => {
        const currentTime = videoRef.current?.currentTime;
        if (currentTime) {
          publisher("currentTimeChanged", {
            offset: currentTime - chapters[activeChapterIndex].start || 0,
            chapterSid: chapters[activeChapterIndex].id,
            currentTime,
          });
        }
      };
    }
  }, [activeChapterIndex, chapters, publisher, videoRef]);

  // on wordsHistory change (undo/redo) - set the video time to action time
  useEffect(() => {
    if (!videoRef?.current || !isTimePlayable(wordsHistory.stateCurrentTime)) return;
    videoRef.current.currentTime = wordsHistory.stateCurrentTime;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wordsHistory.state]);

  // on cropsHistory change (undo/redo) - set the video time to action time
  useEffect(() => {
    if (!videoRef?.current || !isTimePlayable(cropsHistory.stateCurrentTime)) return;
    videoRef.current.currentTime = cropsHistory.stateCurrentTime;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropsHistory.state]);

  // on chaptersHistory change (undo/redo) - set the video time to action time
  useEffect(() => {
    if (resizedChapterIndex !== -1) return;
    if (!videoRef?.current || !isTimePlayable(chaptersHistory.stateCurrentTime)) return;
    videoRef.current.currentTime = chaptersHistory.stateCurrentTime;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chaptersHistory.state, resizedChapterIndex]);

  // sets the current video time to the playFromChapterSid and playFromOffset (initiated by url query params);
  useEffect(() => {
    if (videoRef?.current && playFromLocal.offset > -1 && !isEmpty(playFromLocal.chapterSid) && chapters?.length) {
      // get start time from chapterTimeInterval
      const fromTimeIntervalChapter = chapters.find((chapter) => chapter.id === playFromLocal.chapterSid);
      // we set currentTime only if the offset is smaller or equal to chapter duration
      if (fromTimeIntervalChapter && isTimePlayable(fromTimeIntervalChapter.start + playFromLocal.offset)) {
        videoRef.current.currentTime = fromTimeIntervalChapter.start + playFromLocal.offset;
      } else {
        const firstChapter = chapters?.[0];
        videoRef.current.currentTime = firstChapter.start;
      }
      const params = new URLSearchParams(searchParams);
      params.delete("playbackChapter");
      params.delete("playbackOffset");
      setSearchParams(params);
      setPlayFromLocal({ offset: -1, chapterSid: "" });
    }
  }, [chapters, playFromLocal, playFromOffset, videoRef, searchParams, setSearchParams, isTimePlayable]);

  const contextValue = useShallowMemo<VideoPlaybackContextValue>({
    activeChapterIndex,
    playbackRate,
    isPlaying,
    startManualSeeking,
    seekTimeInterval,
    seekForward,
    seekBackward,
    changePlaybackRate,
    isManualSeeking,
    videoDuration,
    currentTime: videoRef.current?.currentTime || 0,
  });

  return <VideoPlaybackContext.Provider value={contextValue}>{children}</VideoPlaybackContext.Provider>;
}
