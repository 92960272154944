import { createContext, MouseEvent, useContext } from "react";

import { TimeInterval } from "src/types/video-trimmer.types";

export interface VideoPlaybackContextValue {
  activeChapterIndex: number;
  playbackRate: number;
  isPlaying: boolean;
  isManualSeeking: boolean;
  startManualSeeking(event: MouseEvent): void;
  seekTimeInterval(timeInterval: TimeInterval): void;
  seekForward(): void;
  seekBackward(): void;
  videoDuration: number;
  changePlaybackRate(): void;
  currentTime: number;
}

const VideoPlaybackContext = createContext<VideoPlaybackContextValue | null>(null);

export default VideoPlaybackContext;

export const useVideoPlayback = () => {
  const contextValue = useContext(VideoPlaybackContext);

  if (!contextValue) {
    throw new Error("useVideoPlayback must be used within a VideoPlaybackProvider");
  }

  return contextValue;
};
