import React, { useCallback, useMemo, useRef, useState } from "react";
import { ChapterTimeInterval, Word } from "src/types/video-trimmer.types";
import { styleProp, themeColor } from "src/utils/styledComponents.utils";
import useIsVideoLoading from "src/hooks/useIsVideoLoading";
import { UseHistoryStateReturnValue } from "src/hooks/useHistoryState";
import { Stack } from "src/components/common/layout/Stack.styled";
import { Center, FlexBox } from "src/components/common/layout/Box.styled";
import { CircularLoader } from "src/components/common/loaders/CircularLoader";
import ShakaVideoPlayer from "src/components/common/media/ShakaVideoPlayer/ShakaVideoPlayer";
import VideoTranscriptProvider from "src/components/features/VideoTrimmer/providers/VideoTranscriptProvider/VideoTranscriptProvider";
import VideoWordsProvider from "src/components/features/VideoTrimmer/providers/VideoWordsProvider/VideoWordsProvider";
import VideoChaptersProvider from "src/components/features/VideoTrimmer/providers/VideoChaptersProvider/VideoChaptersProvider";
import * as footagePreviewPlayerAnalytics from "src/analytics/footagePreviewPlayer.analytics";
import { InitialHistoryState } from "src/components/features/VideoTrimmer/common/hooks/useInitiatedHistory";
import { TextDirection } from "src/utils/localization.utils";
import styled from "styled-components/macro";
import VideoPlaybackShortcuts from "src/components/features/VideoTrimmer/shortcuts/VideoPlaybackShortcuts";
import Icon from "src/components/common/Icon";
import { absoluteRoutes, applyPathParams } from "src/utils/routes.utils";
import { useNavigate } from "react-router-dom";
import { useFootagePreviewItemLoadingState } from "src/hooks/useFootagePreviewItemLoadingState";
import { AspectRatio, getAspectRatioValue, MAX_CLIP_DURATION, MIN_CLIP_DURATION } from "src/constants/video.constants";
import AutoSizer from "src/components/common/layout/AutoSizer";
import { Text2 } from "src/components/common/layout/typography.styled";
import * as footageAnalytics from "src/analytics/footage.analytics";
import VideoCurrentTimeProvider from "src/components/features/VideoTrimmer/providers/VideoCurrentTimeProvider/VideoCurrentTimeProvider";
import VideoPlaybackProvider from "src/components/features/VideoTrimmer/providers/VideoPlaybackProvider/VideoPlaybackProvider";
import TimelineZoomProvider from "src/components/features/VideoTrimmer/providers/TimelineZoomProvider/TimelineZoomProvider";
import { VerticalDivider } from "src/components/features/SmartLibrary/SmartLibrary.styled";
import { ChapterTimelineActionButtons } from "src/components/features/VideoTrimmer/VideoTranscript/ChapterTimelineActionButtons";
import TimelineZoomer from "src/components/features/VideoTrimmer/TimelineZoomer/TimelineZoomer";
import usePixelPerSecondRatio from "src/components/features/VideoTrimmer/common/hooks/usePixelPerSecondRatio";
import useVariableRef from "src/hooks/useVariableRef";
import ClipCreatorTimelineEditor from "src/components/features/ClipCreator/ClipCreatorTimelineEditor";
import ClipCreatorVideoTranscript from "src/components/features/ClipCreator/ClipCreatorVideoTranscript";
import EntityTitleModal from "src/components/modals/EntityTitleModal";
import { useModal } from "src/components/providers/ModalProvider";
import { ClipByTimeInput } from "src/network/graphql/generatedGraphqlSDK";
import { LAST_BOARD_ID } from "src/constants/smartLibrary.constants";
import { sortBy, sumBy } from "lodash/fp";
import { timeIntervalUtils } from "src/utils/timeInterval.utils";
import Popper from "src/components/common/popovers/Popper";
import { Panel } from "src/components/common/popovers/panel/Panel";
import ClipCreateTranscriptActions from "src/components/features/ClipCreator/ClipCreateTranscriptActions";
import { getBlanks } from "src/utils/words.utils";
import { useAppConfig } from "src/components/providers/AppConfigProvider";
import WhiteRainbowButton from "src/components/common/buttons/WhiteRainbowButton";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Body = styled(Stack)`
  flex: 1;
  overflow: hidden;
  padding-inline: 40px;
  width: 100vw;
`;

const TranscriptColumn = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-block: 50px;
  flex: 0.6;
  overflow: hidden;
`;

const VideoColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.4;
  overflow: hidden;
`;

const VideoPlayerContainer = styled.div`
  container-name: VideoPlayerContainer;
  container-type: inline-size;
  position: relative;
  margin: auto;
  outline: 1px solid ${themeColor("gray.300")};
  width: 100%;
`;

const NoPreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 30%;
  padding: 20px;
  border: 1px solid ${themeColor("gray.300")};
  text-align: center;
`;

const StyledShakaVideoPlayer = styled(ShakaVideoPlayer)``;

const CircularLoaderContainer = styled(Center)`
  position: absolute;
  inset: 0;
`;

const Header = styled(FlexBox)`
  background-color: ${themeColor("gray.50")};
  padding: 6px 32px;
  align-items: center;
`;

const HorizontalDivider = styled(FlexBox)`
  background: ${themeColor("gray.200")};
  height: 5px;
  width: 100%;
`;

const FlexAutoSizer = styled(AutoSizer)`
  display: flex;
  flex-direction: column;
  padding: 30px;
  position: relative;
  overflow: visible;
  padding-block-end: 120px;
`;

const RedirectToFootageSnippets = styled.div`
  display: flex;
  align-self: center;
  align-items: center;
  padding: 4px 12px;
  transition: background-color 0.3s ease-out;
  color: ${themeColor("gray.900")};
  background-color: ${themeColor("blue.1500")};
  border-radius: 8px;
  gap: 8px;
  cursor: pointer;
  font-family: Open Sans;
  font-weight: 600;
  font-size: 12px;
  &:hover {
    background-color: ${themeColor("blue.400")};
  }
`;

const Footer = styled(Stack)`
  padding-block: 10px;
  user-select: none;
  background-color: ${themeColor("gray.200")};
`;

const TimelineWindow = styled(Stack)`
  padding-inline: 2px;
`;

const StyledWhiteRainbowButton = styled(WhiteRainbowButton)<{ margin: string }>`
  margin: ${styleProp("margin")};
`;

// TODO: move to config. to be used by CreateClipModal.tsx as well
const maxClipDuration = MAX_CLIP_DURATION / 1000;
const minClipDuration = MIN_CLIP_DURATION / 1000;

export interface ClipCreatorProps {
  videoSrc: string;
  videoDuration: number;
  wordsHistory: UseHistoryStateReturnValue<Word[]>;
  chaptersHistory: UseHistoryStateReturnValue<ChapterTimeInterval[]>;
  languageCode: string;
  textDirection: TextDirection;
  footageId: string;
  videoAvailability: { isAvailable: boolean; message: string };
  sequenceCount: number;
  videoAspectRatio: AspectRatio;
}

export default function ClipCreator({
  videoSrc,
  videoDuration,
  wordsHistory,
  chaptersHistory,
  languageCode,
  textDirection,
  footageId,
  videoAvailability,
  videoAspectRatio,
  sequenceCount,
}: ClipCreatorProps) {
  const { openModal, closeModal } = useModal();
  const videoRef = useRef<HTMLVideoElement>(null);
  const isVideoLoading = useIsVideoLoading(videoRef);
  const navigate = useNavigate();
  const { shouldDisplayLoader } = useFootagePreviewItemLoadingState({ footageId });
  const vW = videoRef.current?.videoWidth;
  const vH = videoRef.current?.videoHeight;
  const [timelineWindow, setTimelineWindow] = useState<HTMLElement | null>(null);
  const pixelPerSecondRatio = usePixelPerSecondRatio(useVariableRef(timelineWindow), videoDuration);
  const { MAX_BLANKS } = useAppConfig();

  const aspectRatio = useMemo(() => {
    if (videoAspectRatio) {
      return videoAspectRatio;
    }
    if (vW && vH) {
      return `${vW} :  ${vH}`;
    }
    return "16:9";
  }, [videoAspectRatio, vW, vH]);

  const onCreateClip = useCallback(
    (name: string) => {
      const clips: ClipByTimeInput[] = [];
      chaptersHistory.state.forEach((chapter) => {
        // get all words/blanks in the chapter and set the first word as the start of the clip and the last word as the end of the clip
        const allBlanks = getBlanks(wordsHistory.state, videoDuration, MAX_BLANKS);
        const selectedWords = wordsHistory.state.filter((word) =>
          timeIntervalUtils.containsTimeInterval(chapter, word),
        );
        const selectedBlanks = allBlanks.filter((blank) => timeIntervalUtils.intersectsTimeInterval(chapter, blank));
        const mergedSelectedWordsAndSelectedBlanks = sortBy("start", [...selectedWords, ...selectedBlanks]);
        const firstWord = mergedSelectedWordsAndSelectedBlanks?.[0];
        const lastWord = mergedSelectedWordsAndSelectedBlanks?.[mergedSelectedWordsAndSelectedBlanks.length - 1];

        // chapter starts at 0 but first word not start at 0 -> set first word start to 0
        if (chapter?.start === 0 && firstWord?.start) {
          firstWord.start = 0;
        }

        firstWord?.start !== undefined &&
          lastWord?.end !== undefined &&
          clips.push({
            title: name,
            clipFrom: firstWord.start,
            duration: Number((lastWord.end - firstWord.start).toFixed(3)),
          });
      });
      navigate(
        `${absoluteRoutes.createClip.self}?title=${name}&footageId=${footageId}&clips=${JSON.stringify(
          clips,
        )}&defaultAspectRatio=${videoAspectRatio}&boardId=${window.localStorage.getItem(LAST_BOARD_ID)}`,
      );
    },
    [MAX_BLANKS, chaptersHistory.state, footageId, navigate, videoAspectRatio, videoDuration, wordsHistory.state],
  );

  const showCreateModal = useCallback(() => {
    openModal(
      ({ close, ...props }) => (
        <EntityTitleModal
          {...props}
          onSubmit={onCreateClip}
          close={closeModal}
          modalTitle="Create a new clip"
          defaultPlaceHolder="Enter clip name"
          submitLabel="Create"
        />
      ),
      { hideCloseButton: true },
    );
  }, [closeModal, onCreateClip, openModal]);

  const trimmedVideoDuration = useMemo(
    () => sumBy(timeIntervalUtils.duration, chaptersHistory.state) || 0,
    [chaptersHistory.state],
  );

  const isFullDurationOutOfAllowedRange = useMemo(
    () => trimmedVideoDuration > maxClipDuration || trimmedVideoDuration < minClipDuration,
    [trimmedVideoDuration],
  );

  const isInitialChapter = useMemo(
    () =>
      !!(
        chaptersHistory.state.length === 1 &&
        !chaptersHistory.canUndo &&
        chaptersHistory.state[0].start === chaptersHistory.state[0].domain.start &&
        chaptersHistory.state[0].end === chaptersHistory.state[0].domain.end
      ),
    [chaptersHistory.canUndo, chaptersHistory.state],
  );

  return (
    <VideoCurrentTimeProvider videoRef={videoRef}>
      <VideoChaptersProvider
        chaptersHistory={chaptersHistory}
        domains={[{ start: 0, end: videoDuration }]}
        allowedActions={{ addChapter: { isAllowed: true }, removeChapter: { isAllowed: true } }}
      >
        <TimelineZoomProvider videoRef={videoRef} videoDuration={videoDuration} timelineWindowElement={timelineWindow}>
          <VideoPlaybackProvider
            cropsHistory={[] as unknown as UseHistoryStateReturnValue<InitialHistoryState>}
            wordsHistory={wordsHistory}
            chaptersHistory={chaptersHistory as UseHistoryStateReturnValue<ChapterTimeInterval[]>}
            videoRef={videoRef}
            videoDuration={videoDuration}
          >
            <VideoPlaybackShortcuts videoRef={videoRef} />

            <VideoTranscriptProvider
              wordsHistory={wordsHistory}
              videoDuration={videoDuration}
              videoRef={videoRef}
              textDirection={textDirection}
              ignoreMaxBlanks
            >
              <VideoWordsProvider
                wordsHistory={wordsHistory}
                videoDuration={videoDuration}
                languageCode={languageCode || "en/US"}
                allowedActions={{
                  correct: {
                    isAllowed: false,
                    message: "",
                  },
                  highlight: {
                    isAllowed: false,
                    message: "",
                  },
                }}
                analyticsActions={footagePreviewPlayerAnalytics}
              >
                <Container>
                  <Header>
                    <StyledWhiteRainbowButton
                      height="26px"
                      width="90px"
                      variant="white"
                      borderRadius="8px"
                      text="Cancel"
                      fontSize="12px"
                      margin="0 10px 0 0"
                      onClick={() => navigate(applyPathParams(absoluteRoutes.platform.children.player, { footageId }))}
                    />
                    <RedirectToFootageSnippets
                      onClick={() => {
                        navigate(applyPathParams(absoluteRoutes.platform.children.footage, { footageId }));
                        footageAnalytics.trackFootageSnippetButtonClick(footageId, "footage-preview-snippet-button");
                      }}
                    >
                      {shouldDisplayLoader ? (
                        <CircularLoader size={18} thickness={3} />
                      ) : (
                        <Icon.Film size={18} color="black" />
                      )}
                      Review {sequenceCount} Snippets
                    </RedirectToFootageSnippets>
                    <VerticalDivider />
                    <ClipCreateTranscriptActions analyticsActions={footagePreviewPlayerAnalytics} />
                    <Popper
                      trigger="hover"
                      content={
                        isInitialChapter ||
                        (isFullDurationOutOfAllowedRange && (
                          <Panel>
                            {isInitialChapter && "You need to select a clip to save it"}
                            {!isInitialChapter &&
                              trimmedVideoDuration > maxClipDuration &&
                              `Clip duration is too long, Your clip has to be shorter than ${maxClipDuration} minutes`}
                            {!isInitialChapter &&
                              trimmedVideoDuration < minClipDuration &&
                              `Clip duration is too short, Your clip has to be longer than ${minClipDuration} seconds`}
                          </Panel>
                        ))
                      }
                      placement="bottom"
                      offsetY={0}
                      offsetX={0}
                    >
                      <StyledWhiteRainbowButton
                        margin="0 0 0 10px"
                        disabled={isInitialChapter || isFullDurationOutOfAllowedRange}
                        height="26px"
                        width="90px"
                        borderRadius="8px"
                        text="Save Clip"
                        fontSize="12px"
                        onClick={showCreateModal}
                      />
                    </Popper>
                  </Header>
                  <HorizontalDivider />

                  <Body direction="row" spacing={60}>
                    <TranscriptColumn>
                      <ClipCreatorVideoTranscript isInitialChapter={isInitialChapter} />
                    </TranscriptColumn>

                    <VideoColumn>
                      {videoAvailability?.isAvailable ? (
                        <FlexAutoSizer aspectRatio={getAspectRatioValue(aspectRatio as AspectRatio)}>
                          {({ width, height }) => (
                            <VideoPlayerContainer style={{ width, height }}>
                              <StyledShakaVideoPlayer
                                onClick={() => {
                                  const video = videoRef?.current;
                                  if (video) {
                                    if (video.paused) {
                                      video.play();
                                    } else {
                                      video.pause();
                                    }
                                  }
                                }}
                                videoRef={videoRef}
                                src={videoSrc}
                                cached={false}
                                width="100%"
                                height="100%"
                                config={{
                                  streaming: {
                                    forceHTTPS: true,
                                    bufferBehind: 30,
                                    bufferingGoal: 30,
                                    durationBackoff: 60,
                                    safeSeekOffset: 2,
                                  },
                                }}
                              />
                              {isVideoLoading && (
                                <CircularLoaderContainer>
                                  <CircularLoader size={100} />
                                </CircularLoaderContainer>
                              )}
                            </VideoPlayerContainer>
                          )}
                        </FlexAutoSizer>
                      ) : (
                        <Center>
                          <NoPreviewWrapper>
                            <Text2>{videoAvailability?.message}</Text2>
                          </NoPreviewWrapper>
                        </Center>
                      )}
                    </VideoColumn>
                  </Body>

                  <Footer spacing={10} direction="column">
                    <ChapterTimelineActionButtons videoRef={videoRef} />
                    <TimelineWindow ref={setTimelineWindow} direction="column" spacing={10}>
                      <ClipCreatorTimelineEditor
                        audioSamples={[]}
                        videoDurationInSeconds={videoDuration}
                        pixelPerSecondRatio={pixelPerSecondRatio}
                      />
                      <TimelineZoomer pixelPerSecondRatio={pixelPerSecondRatio} />
                    </TimelineWindow>
                  </Footer>
                </Container>
              </VideoWordsProvider>
            </VideoTranscriptProvider>
          </VideoPlaybackProvider>
        </TimelineZoomProvider>
      </VideoChaptersProvider>
    </VideoCurrentTimeProvider>
  );
}
