/* eslint-disable no-useless-escape */
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UploaderTypes, useUploaderContext } from "src/components/providers/UploaderProvider";
import { userSelectors } from "src/models/User.model";
import { Dispatch, RootState } from "src/models/store";
import { DeleteOneFootageInput, Footage, FootageOrigin } from "src/network/graphql/generatedGraphqlSDK";

import { Stack } from "src/components/common/layout/Stack.styled";
import { Text5 } from "src/components/common/layout/typography.styled";
import styled from "styled-components/macro";
import { ifProp } from "styled-tools";

import { themeColor } from "src/utils/styledComponents.utils";

import * as footageAnalytics from "src/analytics/footage.analytics";
import {
  AppearAnimatedDiv,
  GridScroll,
  StepContainer,
} from "src/components/features/AutomaticCreateWizard/common/automatic-create-wizard-layout.styled";
import UploadFootageButton, {
  StyledInfoIcon,
} from "src/components/features/SmartLibrary/footagesLibrary/UploadFootageButton";

import Icon from "src/components/common/Icon";
import FootageItem from "src/components/features/AutomaticCreateWizard/steps/components/FootageItem";
import useLocalStorage from "src/hooks/useLocalStorage";
import UploadToGcsService from "src/services/UploadToGcs.service";
import { YoutubeItem } from "src/types/youtube-api.types";
import { FormContextInterface } from "src/components/features/AutomaticCreateWizard/FormContext.interface";
import { RedirectToPlanButton, StyledTooltip } from "src/components/features/SmartLibrary/SmartLibrary.styled";
import { absoluteRoutes } from "src/utils/routes.utils";
import { useNavigate } from "react-router-dom";
import { planSelectors } from "src/models/Plan.model";

const UploadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-block: 20px;
  @media (min-width: 768px) {
    flex-direction: row;
    margin-top: 54px;
  }
`;

const UploadContentBlock = styled(AppearAnimatedDiv)`
  position: relative;
  display: flex;
  height: 446px;
  width: 330px;
  max-width: 90vw;
  padding: 30px 10px 50px 10px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  margin-top: 18px;
  border-radius: 18px;
  background: ${themeColor("white")};
  box-shadow: 1px 5px 16px 0px rgba(0, 0, 0, 0.07);
`;

const UploadedFootagesContainer = styled.div`
  position: relative;
  display: flex;
  height: 470px;
  width: 340px;
  max-width: 90vw;
  padding: 0 10px;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 30px;

  @media (min-width: 768px) {
    padding: 0 10px 0 60px;
    margin-top: 0;
  }
`;

const YoutubeInputBox = styled.div<{ disabled?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 214px;
  border-radius: 20px;
  background-color: ${themeColor("blue.30")};
  opacity: ${ifProp("disabled", "0.5", "1")};

  @media (max-width: 375px) {
    width: 184px;
  }
`;

const YoutubeIconWrapper = styled.div`
  height: 100%;
  border-radius: 20x 0 0 20px;
  width: fit-content;
  margin-inline: 12px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const YoutubeInput = styled.input`
  width: 92%;
  padding: 10px;
  padding-left: 0;
  outline: none;
  border: none;
  border-radius: 0 20px 20px 0;
  background-color: ${themeColor("blue.30")};

  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${themeColor("blue.900")};

  &::placeholder {
    color: ${themeColor("gray.400")};
  }
`;

const ErrorMEssageWrapper = styled(Stack)`
  position: absolute;
  align-items: center;
  display: "flex";
  bottom: -30px;
`;

const ErrorMessage = styled(Text5)`
  color: ${themeColor("status.error")};
`;

export const SubmitButton = styled.button<{ disabled: boolean }>`
  height: 40px;
  width: 80px;
  padding: 7px 10px;
  outline: none;
  border: solid 1px;
  border-radius: 20px;
  border-color: ${themeColor("pink.500")};
  background-color: ${themeColor("white")};
  border-color: ${ifProp("disabled", themeColor("blue.100"), themeColor("pink.500"))};
  font-size: 12px;
  line-height: 20px;
  font-weight: 700;
  color: ${ifProp("disabled", themeColor("blue.100"), themeColor("pink.500"))};
  cursor: ${ifProp("disabled", "not-allowed", "pointer")};
  transition: background-color 0.2s ease-in-out;

  &:disabled {
    pointer-events: none;
  }
`;

const GridContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, minmax(100px, 1fr));
  column-gap: 18px;
  /* row-gap: 23px; */
  margin-block: 0 16px;
  padding-bottom: 20px;

  @media (min-width: 768px) {
    padding-bottom: 0;
  }
`;

const DividerBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  span {
    font-size: 12px;
    color: ${themeColor("blue.900")};
  }
`;

const Divider = styled.div`
  width: 40%;
  height: 0;
  border-top: solid 1px;
  border-color: ${themeColor("blue.150")};
`;

const YoutubeBlock = styled(DividerBlock)`
  position: relative;
  ${RedirectToPlanButton} {
    margin: 0;
    height: 40px;
  }

  ${StyledInfoIcon} {
    bottom: -26px;
  }
`;

export const TooltipInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 10px;
  font-weight: 400;
  a {
    font-weight: 600;
  }
`;

const getYoutubeVideoIdByURL = (url: string) => {
  const youtubeRegex = /[?&]v=([^&#]+)/;
  const shortsRegex = /\/shorts\/([^\?\/]+)/;
  const youtuBeShortRegex = /youtu\.be\/([^\?\/]+)/;

  const youtubeMatch = url.match(youtubeRegex);
  if (youtubeMatch) {
    return youtubeMatch[1];
  }

  const shortsMatch = url.match(shortsRegex);
  if (shortsMatch) {
    return shortsMatch[1];
  }

  const youtuBeShortMatch = url.match(youtuBeShortRegex);
  if (youtuBeShortMatch) {
    return youtuBeShortMatch[1];
  }

  return null;
};

export default function UploadingStep({ formContext }: FormContextInterface) {
  const navigate = useNavigate();
  const currentUser = useSelector((state: RootState) => userSelectors.selectById(state, state.session.userId));
  const { createUploader, getUploader, deleteUploader, uploadsLimitReached, maxFootageUploads, amountOfUsedUploading } =
    useUploaderContext();
  const dispatch = useDispatch<Dispatch>();
  const { watch, setValue } = formContext;
  const [isYouTubeInputError, setIsYouTubeInputError] = useState<boolean>(false);
  const [youtubeVideoURL, setYoutubeVideoURL] = useState<string>("");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const footagesArray: { footageId: string; languageCode: string; thumbnailUrl: string | null | undefined, origin: string }[] = watch("uploading.footagesArray") ?? []; // prettier-ignore
  const [uploadedFootagesIdsFromStorage, setUploadedFootagesIdsFromStorage] = useLocalStorage<string[]>("uploadedFootagesIds", []); // prettier-ignore
  const allowUploadFromYouTube = useSelector((state: RootState) =>
    planSelectors.selectIsTierFeatureAllowed(state, "YOUTUBE_UPLOAD"),
  );

  const isYoutubeLinkInputEnabled = useMemo(() => allowUploadFromYouTube && !(uploadsLimitReached || (maxFootageUploads && maxFootageUploads === amountOfUsedUploading)), [amountOfUsedUploading, maxFootageUploads, uploadsLimitReached, allowUploadFromYouTube]); // prettier-ignore
  const containerRef = useRef<HTMLDivElement>(null);
  const isMobileScreen = window.innerWidth < 786;

  const onDrop = useCallback(
    (files: File[], isDragging: boolean) => {
      // track upload component select files
      footageAnalytics.trackUploadComponentSelectFiles({
        trigger: isDragging ? "dragAndDrop" : "filePicker",
        filesAmount: files.length,
        multiFiles: files.length > 1,
      });
      const promises = files.map(async (file) => {
        const footage = await dispatch.footage.createFootage({
          footage: {
            origin: FootageOrigin.UserUpload,
            filename: file.name.substring(0, file.name.lastIndexOf(".")) || file.name,
            languageCode: currentUser?.languageCode || "en-US",
          },
        });

        // track footage creation
        const uploader = (await createUploader(footage.id, file, UploaderTypes.FOOTAGE)) as UploadToGcsService;
        const videoMediaInfo = await uploader.videoMetaData;
        footageAnalytics.trackCreateFootage(footage, "automation-wizard", videoMediaInfo || {});

        setValue(
          "uploading.footagesArray",
          [
            ...(watch("uploading.footagesArray") ?? []),
            { footageId: footage.id, languageCode: "", thumbnailUrl: null, origin: FootageOrigin.UserUpload },
          ],
          {
            shouldValidate: true,
          },
        );

        dispatch.footage.addNewFootage(footage);
      });

      // eslint-disable-next-line no-console
      return Promise.all(promises).catch(console.error);
    },
    [dispatch.footage, currentUser?.languageCode, createUploader, setValue, watch],
  );

  const handleYoutubeInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setYoutubeVideoURL(inputValue);
    setIsYouTubeInputError(false);
  }, []);

  const onYoutubeSubmit = useCallback(
    async (youtubeVideoId: string, youtubeItem: YoutubeItem) => {
      const createFootage = async () => {
        if (youtubeVideoId) {
          const footage = await dispatch.footage.createFootage({
            footage: {
              youtubeId: youtubeVideoId,
              filename: youtubeItem.snippet.title || youtubeVideoId,
              origin: FootageOrigin.Youtube,
            },
          });
          dispatch.footage.addNewFootage(footage);
          // track footage creation
          footageAnalytics.trackCreateFootage(footage, "automation-wizard", {});
          return footage.id;
        }
        return null;
      };

      const updateFormWithFootageId = async () => {
        const footageId = await createFootage();

        if (footageId) {
          await createUploader(footageId, new File([], youtubeVideoId), UploaderTypes.YOUTUBE, youtubeItem);

          setValue(
            "uploading.footagesArray",
            [
              ...(watch("uploading.footagesArray") ?? []),
              { footageId, languageCode: "", thumbnailUrl: null, origin: FootageOrigin.Youtube },
            ],
            {
              shouldValidate: true,
            },
          );
        }
      };

      updateFormWithFootageId();
    },
    [createUploader, dispatch.footage, setValue, watch],
  );

  const onSubmitButtonClick = useCallback(async () => {
    const youtubeVideoId = getYoutubeVideoIdByURL(youtubeVideoURL);

    if (!youtubeVideoId) {
      setIsYouTubeInputError(true);
      return;
    }

    try {
      const res = await dispatch.youtubeSearchResults.getYoutubeVideo(youtubeVideoId);
      await onYoutubeSubmit(youtubeVideoId ?? "", res || ({} as YoutubeItem));
      setIsYouTubeInputError(!res);
      setYoutubeVideoURL("");
    } catch (e) {
      setIsYouTubeInputError(true);
      setYoutubeVideoURL("");
    }
  }, [dispatch.youtubeSearchResults, onYoutubeSubmit, youtubeVideoURL]);

  const onLanguageSubmit = useCallback(
    async (footage: Footage, selectedLanguage: string) => {
      const footItem = footagesArray.find((x) => x.footageId === footage.id);
      let index = -1;
      if (footItem) {
        index = footagesArray.indexOf(footItem);
      }
      const footagesArrayCopy = [...footagesArray];
      footagesArrayCopy[index].languageCode = selectedLanguage;

      setValue("uploading.footagesArray", footagesArrayCopy, {
        shouldValidate: true,
      });

      // we will update the footage language code at the last wizard step

      // track language change
      footageAnalytics.trackFootageLanguageChange({
        id: footage.id,
        languageCode: selectedLanguage,
      } as Footage);
      const uploader = getUploader(footage.id) as UploadToGcsService;
      if (uploader) {
        uploader.waitForLastChunk = true;
        uploader?.start();
      }
    },
    [footagesArray, getUploader, setValue],
  );

  const onUploadedFootageDelete = useCallback(
    async (id: string) => {
      try {
        await dispatch.footage.deleteFootage({ id } as DeleteOneFootageInput);
        deleteUploader(id);
        setValue(
          "uploading.footagesArray",
          watch().uploading.footagesArray?.filter((footage) => footage.footageId !== id),
          { shouldValidate: true },
        );
        // track delete
        footageAnalytics.trackDeleteFootage(
          {
            id,
          } as Footage,
          "automation-wizard",
        );
      } catch (error) {
        // eslint-disable-next-line
        console.log("onFootageDelete", error);
      }
    },
    [dispatch.footage, setValue, watch, deleteUploader],
  );

  useEffect(() => {
    if (uploadedFootagesIdsFromStorage.length) {
      const footagesFromStorage = uploadedFootagesIdsFromStorage.map((id) => ({
        footageId: id,
        languageCode: "",
        thumbnailUrl: null,
        origin: FootageOrigin.UserUpload,
      }));
      setValue("uploading.footagesArray", footagesFromStorage, { shouldValidate: true });
      setUploadedFootagesIdsFromStorage([]);
    }
  }, [setUploadedFootagesIdsFromStorage, setValue, uploadedFootagesIdsFromStorage]);

  useEffect(() => {
    const container = containerRef.current;
    const targetFootageIndex = footagesArray.findIndex((footage) => !footage.languageCode);

    if (container && footagesArray.length > targetFootageIndex) {
      const targetFootageElement = document.getElementById(`footage-item-${targetFootageIndex}`);
      if (targetFootageElement) {
        const elementOffset = isMobileScreen
          ? targetFootageElement.offsetTop + targetFootageElement.offsetHeight + 300
          : targetFootageElement.offsetTop;
        container.scrollTo({ top: elementOffset, behavior: "smooth" });
      } else {
        container.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
  }, [footagesArray, isMobileScreen]);

  return (
    <StepContainer ref={isMobileScreen ? containerRef : null}>
      <UploadingWrapper>
        <UploadContentBlock>
          Upload Talking Videos
          <UploadFootageButton handleDrop={onDrop} />
          <DividerBlock>
            <Divider />
            <span>or</span>
            <Divider />
          </DividerBlock>
          <YoutubeBlock>
            <YoutubeInputBox disabled={!isYoutubeLinkInputEnabled}>
              <YoutubeIconWrapper>
                <Icon.YoutubeSmall />
              </YoutubeIconWrapper>
              <YoutubeInput
                type="text"
                placeholder="Drop a Youtube link"
                value={youtubeVideoURL}
                onChange={handleYoutubeInputChange}
                disabled={!isYoutubeLinkInputEnabled}
              />
              {isYouTubeInputError && (
                <ErrorMEssageWrapper direction="row" spacing={4}>
                  <Icon.CircledX size={8} color="status.error" />
                  <ErrorMessage>Enter a valid Youtube link</ErrorMessage>
                </ErrorMEssageWrapper>
              )}
            </YoutubeInputBox>
            {allowUploadFromYouTube ? (
              <SubmitButton disabled={!youtubeVideoURL} onClick={onSubmitButtonClick}>
                Get Video
              </SubmitButton>
            ) : (
              <RedirectToPlanButton onClick={() => navigate(absoluteRoutes.plans)}>Upgrade Now!</RedirectToPlanButton>
            )}
            {!allowUploadFromYouTube && (
              <StyledTooltip
                text="Uploading from Youtube is a premium feature. Upgrade to unlock!"
                placement="left-end"
                trigger="hover"
              >
                <StyledInfoIcon />
              </StyledTooltip>
            )}
          </YoutubeBlock>
        </UploadContentBlock>
        {!!footagesArray?.length && (
          <UploadedFootagesContainer>
            <GridScroll ref={!isMobileScreen ? containerRef : null}>
              <GridContainer>
                {footagesArray?.map((footage, index) => (
                  <div id={`footage-item-${index}`} key={footage.footageId}>
                    <FootageItem
                      key={footage.footageId}
                      footage={footage}
                      onUploadedFootageDelete={onUploadedFootageDelete}
                      onLanguageSubmit={onLanguageSubmit}
                    />
                  </div>
                ))}
              </GridContainer>
            </GridScroll>
          </UploadedFootagesContainer>
        )}
      </UploadingWrapper>
    </StepContainer>
  );
}
