import styled from "styled-components/macro";
import Color from "color";
import { ifProp, styleProp, themeColor } from "src/utils/styledComponents.utils";

const ColorOption = styled.div<{ isSelected: boolean; color: string; isTransparent: boolean; isLight: boolean }>`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: 1px solid #fff;
  border-color: ${ifProp("isLight", themeColor("gray.350"), "transparent")};
  scale: ${ifProp("isSelected", 1.25, 1)};
  border-radius: 4px;
  background-color: ${styleProp("color")};
  transition: scale 200ms ease-out;
  cursor: pointer;
  &::after {
    content: "";
    position: absolute;
    background-color: ${ifProp("isTransparent", "#FF3D3D", "none")};
    top: -3px;
    bottom: -3px;
    right: 8px;
    width: 1px;
    transform: rotate(45deg);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 10px;
`;

export type ColorPickerValue = { index: number; color: string };

interface Props {
  options?: ColorOptionProps[];
  onChange(value: ColorPickerValue | null): void;
}

interface ColorOptionProps {
  color: Color<string>;
  isSelected: boolean;
}

export function MiniColorPicker({ options, onChange }: Props) {
  return (
    <Container>
      {options?.map((option, index) => (
        <ColorOption
          key={option.color.toString()}
          color={option.color.toString()}
          isSelected={option.isSelected}
          isTransparent={option.color.alpha() === 0}
          isLight={option.color.isLight()}
          onClick={() => onChange({ index, color: option.color.toString() })}
        />
      ))}
    </Container>
  );
}
