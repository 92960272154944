import { CircularLoader } from "src/components/common/loaders/CircularLoader";
import Icon from "src/components/common/Icon";
import { absoluteRoutes, applyPathParams } from "src/utils/routes.utils";
import styled from "styled-components/macro";
import { ifProp } from "styled-tools";
import { themeColor } from "src/utils/styledComponents.utils";
import * as footageAnalytics from "src/analytics/footage.analytics";
import { StyledTooltip } from "src/components/features/SmartLibrary/SmartLibrary.styled";
import { OriginalVideoAvailability } from "../smartLibrary.utils";

const FootageActionsWrapper = styled.div<{ hide?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: ${ifProp("hide", "absolute", "relative")};
  opacity: ${ifProp("hide", "0", "1")};
  pointer-events: ${ifProp("hide", "none", "auto")};

  transition: all 0.3s ease-out;
`;

const FootageAction = styled.button<{ isClickable?: boolean }>`
  border: none;
  outline: none;
  user-select: none;
  border-radius: 12px;
  cursor: ${ifProp("isClickable", "pointer", "auto")};
  width: 165px;
  background: ${themeColor("white", 0.8)};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${themeColor("black")};
  padding: 8px 9px 8px 12px;
  padding-inline: 18px;
  transition: background-color 0.2s ease-out;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 4px 0 0;
  }

  svg {
    margin-right: 12px;
    color: ${themeColor("black")};
  }
  &:hover {
    background-color: ${themeColor("gray.50")};
  }

  &:disabled {
    background: ${themeColor("white", 0.3)};
    cursor: not-allowed;
  }
`;

interface FootageActionsProps {
  displayActionButtons: boolean;
  isClickable: boolean;
  onFootageShowSnippetItemClick: () => void;
  shouldDisplayLoader?: boolean;
  isLanguageSelectEnabled: boolean;
  sequenceCount: number;
  footageId: string;
  navigate: (path: string) => void;
  originalVideoAvailability: OriginalVideoAvailability;
}

export function FootageActions({
  displayActionButtons,
  isClickable,
  onFootageShowSnippetItemClick,
  shouldDisplayLoader,
  isLanguageSelectEnabled,
  sequenceCount,
  footageId,
  navigate,
  originalVideoAvailability,
}: FootageActionsProps) {
  return (
    <FootageActionsWrapper hide={!displayActionButtons}>
      <FootageAction
        isClickable={isClickable}
        onClick={
          displayActionButtons
            ? () => {
                onFootageShowSnippetItemClick();
                footageAnalytics.trackFootageSnippetButtonClick(footageId, "footage-item-snippet-button");
              }
            : () => {}
        }
      >
        {shouldDisplayLoader && !isLanguageSelectEnabled ? (
          <CircularLoader size={18} thickness={2} marginRight={12} />
        ) : (
          <Icon.Film size={18} color="white" />
        )}
        <span>
          {sequenceCount} {sequenceCount > 1 ? " Snippets" : " Snippet"}
        </span>
      </FootageAction>
      <StyledTooltip
        disabled={originalVideoAvailability.isAvailable || originalVideoAvailability.isProcessing}
        text={originalVideoAvailability.message}
        placement="bottom"
        maxWidth={180}
        textAlign="center"
      >
        <FootageAction
          disabled={!originalVideoAvailability.isAvailable}
          isClickable={isClickable}
          onClick={() => {
            navigate(applyPathParams(absoluteRoutes.platform.children.player, { footageId }));
            footageAnalytics.trackFootagePreviewButtonClick(footageId, "footage-item-preview-button");
          }}
        >
          {!originalVideoAvailability.isAvailable && originalVideoAvailability.isProcessing ? (
            <CircularLoader size={18} thickness={2} marginRight={12} />
          ) : (
            <Icon.VideoCounter size={18} color="black" />
          )}
          <span>Original Video</span>
        </FootageAction>
      </StyledTooltip>
    </FootageActionsWrapper>
  );
}
