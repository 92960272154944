import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Dispatch, RootState } from "src/models/store";

import { Text1Bold, H2, H6 } from "src/components/common/layout/typography.styled";
import { Field } from "src/components/common/form/Field.styled";
import { Box } from "src/components/common/layout/Box.styled";
import { PasswordInput, FieldError, NetworkError } from "src/components/common/form/smart-form-components";
import { Container, ContentContainer, FormFieldStack, SubmitButton } from "src/components/auth/common/layout.styled";
import * as commonAuthFieldDefinitions from "src/components/auth/common/fieldDefinitions";

const resetPasswordFormSchema = yup.object({
  password: commonAuthFieldDefinitions.password(),
  passwordVerification: commonAuthFieldDefinitions.passwordVerification(),
});

type ResetPasswordForm = yup.InferType<typeof resetPasswordFormSchema>;

interface ResetPasswordCompletionProps {
  token: string | undefined;
  email: string | undefined;
}

export default function ResetPasswordCompletion({ token, email }: ResetPasswordCompletionProps) {
  const dispatch = useDispatch<Dispatch>();
  const networkError = useSelector((state: RootState) => state.loading.effects.users.updateUserPassword.error as Error);
  const resetPasswordForm = useForm<ResetPasswordForm>({ resolver: yupResolver(resetPasswordFormSchema) });
  const { handleSubmit } = resetPasswordForm;

  const onSubmit = useCallback(
    ({ password }: ResetPasswordForm) => {
      if (token && email) {
        dispatch.users.updateUserPassword({ email, password, token });
      }
    },
    [token, email, dispatch],
  );

  return (
    <Container>
      <ContentContainer>
        <H2>We&apos;re almost there</H2>
        <H6>Confirm your password to help us secure your privacy.</H6>

        <Box marginTop={10}>
          <Text1Bold>{email}</Text1Bold>
        </Box>

        <FormProvider {...resetPasswordForm}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormFieldStack>
              <Field>
                <PasswordInput<ResetPasswordForm> name="password" placeholder="Password" inputSize="medium" />
                <FieldError<ResetPasswordForm> name="password" />
              </Field>

              <Field>
                <PasswordInput<ResetPasswordForm>
                  name="passwordVerification"
                  placeholder="Verify Password"
                  inputSize="medium"
                />
                {!resetPasswordForm.formState.errors.password && (
                  <FieldError<ResetPasswordForm> name="passwordVerification" />
                )}
              </Field>
            </FormFieldStack>

            <SubmitButton data-cy="configure-submit">Next</SubmitButton>
            <NetworkError error={networkError} />
          </form>
        </FormProvider>
      </ContentContainer>
    </Container>
  );
}
