import { APP_ENV } from "src/constants/env.constants";

export default function blockServiceForEnv(blockedEnvs: Env[]) {
  return <T extends { new (...args: any[]): {} }>(constructor: T) => {
    if (process.env.JEST_WORKER_ID || blockedEnvs.includes(APP_ENV)) {
      /** iterate through the properties of the class */
      Object.getOwnPropertyNames(constructor.prototype).forEach((propName) => {
        if (propName !== "constructor" && typeof constructor.prototype[propName] === "function") {
          constructor.prototype[propName] = () => {
            const className = constructor.name;

            // eslint-disable-next-line no-console
            console.log(`${className}.${propName} blocked on env ${APP_ENV}`);
          };
        }
      });
    }
  };
}
