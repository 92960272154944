import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { set } from "lodash/fp";
import Color from "color";

import peechLogo from "src/assets/images/logo.png";

import { Dispatch, RootState } from "src/models/store";

import { AssetType } from "src/constants/model.constants";

import { useGetAssetImageURL } from "src/hooks/useGetAssetURL";

import { getImagePalette, toPng } from "src/utils/image.utils";
import { ifProp, themeColor, themeProp } from "src/utils/styledComponents.utils";

import Icon from "src/components/common/Icon";
import ImageDropzone, { DropzoneLabel } from "src/components/common/dropzones/ImageDropzone";
import { CircularLoader } from "src/components/common/loaders/CircularLoader";
import { Box, FlexBox } from "src/components/common/layout/Box.styled";
import { Stack } from "src/components/common/layout/Stack.styled";
import { Text1Bold } from "src/components/common/layout/typography.styled";
import { Palette } from "src/components/common/form/inputs/ColorPicker/SwatchPalette";

import { useOnboardingFormContext } from "src/components/features/Onboarding/onboardingForm";
import ColorStack from "src/components/features/Onboarding/steps/BrandStep/ColorStack";
import { OnboardingStepTitle } from "src/components/features/Onboarding/onboarding.styled";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LogoBox = styled(Box)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  border-width: 1px;
  border-radius: ${themeProp("borderRadii.large")}px;
  cursor: pointer;
  overflow: hidden;
`;

const LoaderOverlay = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffffaa;
`;

const CenteredLabel = styled(Text1Bold)`
  text-align: center;
`;

const PeechLogoBox = styled(LogoBox)<{ isSelected?: boolean }>`
  width: 210px;
  border-style: solid;
  border-color: ${themeColor("gray.250")};

  &:after {
    content: "";
    display: block;
    position: absolute;
    inset: 0;
    background-color: ${ifProp("isSelected", "transparent", themeColor("gray.200", 0.7))};
    transition: background-color 350ms cubic-bezier(0.57, -0.01, 0, 0.77);
  }

  &:hover:after {
    background-color: transparent;
  }
`;

const Label = styled(Box)`
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  color: ${themeColor("gray.900")};
`;

const EXAMPLE_LOGO = fetch(peechLogo).then((res) => res.blob());
const INITIAL_COLORS_COUNT = 5;
const MAX_COLOR_COUNT = 5;

export default function BrandStep() {
  const dispatch = useDispatch<Dispatch>();
  const getAssetImageURL = useGetAssetImageURL();
  const onboardingForm = useOnboardingFormContext();
  const { watch, setValue } = onboardingForm;
  const { logo, visibleColorCount, colors } = watch("brand") || {};
  const isLoadingAssets = useSelector((state: RootState) => state.loading.models.assets.loading);
  const [brandColorStackPalettes, setBrandColorStackPalettes] = useState<Palette[]>([]);

  const updateLogo = useCallback(
    async (content?: File | Blob, name?: string, isExample = false) => {
      setValue("brand.logo.isExample", isExample, { shouldValidate: true });
      setValue("brand.logo.assetId", "", { shouldValidate: true });

      let assetId = "";

      if (name && content) {
        const pngContent = await toPng(content, { maxHeight: 1000, maxWidth: 1500 });
        if (pngContent) {
          assetId = await dispatch.assets.createAsset({
            type: AssetType.Logo,
            name,
            content: pngContent,
          });
        }
      }

      setValue("brand.logo.assetId", assetId, { shouldValidate: true });
      setValue("brand.colors", undefined);
    },
    [dispatch.assets, setValue],
  );

  const onDropzoneFile = useCallback((file: File) => updateLogo(file, file.name), [updateLogo]);
  const onDropzoneClear = useCallback(() => updateLogo(), [updateLogo]);
  const setExampleLogo = useCallback(async () => updateLogo(await EXAMPLE_LOGO, "example-logo", true), [updateLogo]);

  const onAddNewColor = useCallback(
    () => setValue("brand.visibleColorCount", (visibleColorCount ?? 0) + 1),
    [setValue, visibleColorCount],
  );

  const onColorUpdate = useCallback(
    (colorIndex: number, newValue: Color) => {
      if (!colors) return;

      const updatedBrandColors = set([colorIndex, "color"], newValue.hex(), colors);
      setValue("brand.colors", updatedBrandColors, { shouldValidate: true });
    },
    [colors, setValue],
  );

  useEffect(() => {
    if (!logo?.assetId) return;

    getImagePalette(getAssetImageURL(logo.assetId), MAX_COLOR_COUNT).then((extractedLogoColors) => {
      setBrandColorStackPalettes([
        { name: "Colors from logo", colors: extractedLogoColors.map(({ rgb }) => Color(rgb).hex()) },
      ]);
      if (!colors?.length) {
        setValue("brand.visibleColorCount", INITIAL_COLORS_COUNT, { shouldValidate: true });
        setValue(
          "brand.colors",
          extractedLogoColors.map(({ rgb, percent, grayness }, index) => ({
            dominancy: index + 1,
            color: Color(rgb).hex(),
            percent,
            grayness,
          })),
          { shouldValidate: true },
        );
      }
    });
  }, [logo?.assetId, setValue, colors, getAssetImageURL]);

  return (
    <Container>
      <OnboardingStepTitle>
        Ensure consistent and professional <br />
        video branding by <strong>uploading your logo.</strong>
      </OnboardingStepTitle>

      <FlexBox style={{ flexDirection: "column", alignItems: "center", position: "relative" }} marginTop={50}>
        <Stack direction="row" spacing={20} style={{ alignItems: "center" }}>
          <div style={{ position: "relative" }}>
            <ImageDropzone
              // TODO: "webp", "ico"
              acceptedImageTypes={["png", "jpg", "jpeg"]}
              onDrop={onDropzoneFile}
              onClear={onDropzoneClear}
              // eslint-disable-next-line no-alert
              onReject={() => alert("rejected")}
              droppedImageURL={!logo?.assetId || logo.isExample ? null : getAssetImageURL(logo.assetId)}
              maxSizeBytes={1024 * 1024}
            />

            {isLoadingAssets && !logo?.isExample && (
              <LoaderOverlay>
                <CircularLoader size={50} />
              </LoaderOverlay>
            )}
          </div>

          <CenteredLabel>Or</CenteredLabel>

          <div>
            <PeechLogoBox
              data-testid="example-logo-option"
              onClick={logo?.isExample ? undefined : setExampleLogo}
              isSelected={logo?.isExample}
            >
              <Icon.Logo />

              {isLoadingAssets && logo?.isExample && (
                <LoaderOverlay>
                  <CircularLoader size={50} />
                </LoaderOverlay>
              )}
            </PeechLogoBox>

            <DropzoneLabel marginTop={6}>Example Logo</DropzoneLabel>
          </div>
        </Stack>

        <Label marginTop={60} marginBottom={20} style={{ opacity: colors?.length ? 1 : 0 }}>
          Your brand colors
        </Label>

        <div
          style={{ opacity: colors?.length ? 1 : 0, marginLeft: (visibleColorCount ?? 0) < MAX_COLOR_COUNT ? -114 : 0 }}
        >
          <ColorStack
            colorItems={colors?.slice(0, visibleColorCount)}
            onColorUpdate={onColorUpdate}
            onAddNewColor={onAddNewColor}
            getColorItemId={(item) => item.dominancy}
            transitionDelayBase={400}
            colorItemTransitionDuration={100}
            colorItemTransitionDelay={60}
            allowAdding={(visibleColorCount ?? 0) < MAX_COLOR_COUNT}
            palettes={brandColorStackPalettes}
          />
        </div>
      </FlexBox>
    </Container>
  );
}
