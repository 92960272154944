import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import FootageLibrary from "src/components/features/SmartLibrary/footagesLibrary/FootageLibrary";
import { Dispatch } from "src/models/store";
import { useParams } from "react-router-dom";
import { LAST_BOARD_ID } from "src/constants/smartLibrary.constants";

export default function FootageLibraryPage() {
  const dispatch = useDispatch<Dispatch>();
  const { boardId } = useParams<{ boardId: string }>();

  useEffect(() => {
    // set or remove last boardId on localStorage for last board to be displayed later on login (by homePage.tsx)
    if (typeof boardId === "string") {
      window.localStorage.setItem(LAST_BOARD_ID, boardId);
    } else {
      window.localStorage.removeItem(LAST_BOARD_ID);
    }
  }, [boardId]);

  useEffect(() => {
    dispatch.session.getSubscription();
  }, [dispatch.session]);

  return (
    <>
      <Helmet>
        <title>Peech - Automated Talk-Videos Creator</title>
      </Helmet>
      <FootageLibrary boardId={boardId} />
    </>
  );
}
