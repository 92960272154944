/* eslint-disable @typescript-eslint/no-unused-vars */
import { useSelector } from "react-redux";

import { RootState } from "src/models/store";

import { useGetAssetImageURL } from "src/hooks/useGetAssetURL";

import { AssetType } from "src/constants/model.constants";

import { Text3 } from "src/components/common/layout/typography.styled";
import {
  VisualPackageContainer,
  VisualPackageFooter,
} from "src/components/features/AutomaticCreateWizard/common/automatic-create-wizard-layout.styled";
import VisualPackageLottiePreview from "src/components/features/AutomaticCreateWizard/steps/components/VisualPackageLottiePreview";
import { useAppConfig } from "src/components/providers/AppConfigProvider";
import { folderStructureSelectors } from "src/models/FolderStructure.model";
import { footageSelectors } from "src/models/Footage.model";
import { RadioButton } from "src/components/common/form/inputs/RadioButton.styled";
import { FormContextInterface } from "src/components/features/AutomaticCreateWizard/FormContext.interface";

export default function BrandVisualPackage({
  visualPackageId,
  radioButtonEnabled = false,
  formContext,
}: {
  visualPackageId: string;
  radioButtonEnabled?: boolean;
} & FormContextInterface) {
  const { CONTENT_URL } = useAppConfig();
  const { watch, register } = formContext;

  const currentVisualPackage = useSelector((state: RootState) => (visualPackageId ? folderStructureSelectors.selectVisualPackage(state, visualPackageId, AssetType.LottiePackagePreview) : null)); // prettier-ignore
  const logoAssetSid = watch("brandKit.logo.assetId");
  const getAssetImageURL = useGetAssetImageURL();
  const logoURL = getAssetImageURL(logoAssetSid ?? "");
  const footagesArray = watch("uploading.footagesArray");
  const footageThumbnailUrl = useSelector(
    (state: RootState) =>
      footagesArray && footageSelectors.selectThumbnailUrl(state, footagesArray?.[0].footageId ?? ""),
  );
  const thumbnailUrl = footageThumbnailUrl ? `${CONTENT_URL}/${footageThumbnailUrl}` : "";

  const externalThumbnailUrl = watch("uploading.footagesArray")?.[0]?.thumbnailUrl;

  if (!visualPackageId) {
    return null;
  }

  return (
    <VisualPackageContainer>
      <VisualPackageLottiePreview
        assetId={currentVisualPackage?.asset.sid!}
        assetVersion={currentVisualPackage?.asset.version!}
        thumbnailUrl={externalThumbnailUrl ?? thumbnailUrl}
        logoURL={logoURL!}
        initialStartTime={2500}
        playOnHover
        loop
      />
      <VisualPackageFooter>
        <Text3>{currentVisualPackage?.folder.name}</Text3>
        {radioButtonEnabled && (
          <RadioButton value={visualPackageId} {...register("brandKit.visuals.visualPackageId")} />
        )}
      </VisualPackageFooter>
    </VisualPackageContainer>
  );
}
