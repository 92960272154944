import { RefObject, useEffect, useState } from "react";
import { useTheme } from "styled-components/macro";

import TimeIndicator from "src/components/features/VideoTrimmer/TimelineEditor/TimeIndicator/TimeIndicator";
import { ResizeThumbGrabArea } from "src/components/features/VideoTrimmer/TimelineEditor/ChapterView";

interface HoveredTimeIndicatorProps {
  fullTimelineRef: RefObject<HTMLDivElement>;
  pixelPerSecond: number;
  popupTarget: HTMLElement | null;
}

export default function HoveredTimeIndicator({
  fullTimelineRef,
  pixelPerSecond,
  popupTarget,
}: HoveredTimeIndicatorProps) {
  const theme = useTheme();
  const [hoveredTime, setHoveredTime] = useState<number | null>(null);

  // TODO: move to functionality hook and make it part of a context to reduce renders, then we can also remove start
  useEffect(() => {
    const fullTimelineElement = fullTimelineRef.current;

    const onMouseMove = (e: MouseEvent) => {
      // TODO: use intersection API instead
      const target = e.target as HTMLElement;
      const targetParent = target.parentNode as HTMLElement;
      const overResizeThumb =
        target.classList.contains(ResizeThumbGrabArea.styledComponentId) ||
        targetParent.classList.contains(ResizeThumbGrabArea.styledComponentId);

      if (overResizeThumb) {
        setHoveredTime(null);
        return;
      }

      // TODO: too expensive because it gets called on mousemove, calc using ResizeObserver
      const timelineTrackRect = (e.currentTarget as HTMLElement).getBoundingClientRect();
      // TODO: handle out of range values (account for zoom as well)
      const xRelativeToTimelineTrack = e.clientX - timelineTrackRect.x;

      setHoveredTime(xRelativeToTimelineTrack / pixelPerSecond);
    };

    const onMouseLeave = () => setHoveredTime(null);

    fullTimelineElement?.addEventListener("mousemove", onMouseMove);
    fullTimelineElement?.addEventListener("mouseleave", onMouseLeave);

    return () => {
      fullTimelineElement?.removeEventListener("mousemove", onMouseMove);
      fullTimelineElement?.removeEventListener("mouseleave", onMouseLeave);
    };
  }, [pixelPerSecond, fullTimelineRef]);

  if (hoveredTime === null) {
    return null;
  }

  return (
    <TimeIndicator
      timeInSeconds={hoveredTime}
      pixelPerSecond={pixelPerSecond}
      color={theme.colors.gray["400"]}
      popupTarget={popupTarget}
      // withThumbnail
    />
  );
}
