import { useEffect } from "react";

export default function useBeforeUnloadBlocker(condition = true) {
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (condition) {
      const handler = (event: BeforeUnloadEvent) => {
        // Recommended
        event.preventDefault();

        // Included for legacy support, e.g. Chrome/Edge < 119
        event.returnValue = true;
      };

      window.addEventListener("beforeunload", handler);

      return () => {
        window.removeEventListener("beforeunload", handler);
      };
    }
  }, [condition]);
}
