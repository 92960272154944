import { useEffect } from "react";

import useOldAppRedirect from "src/hooks/useOldAppRedirect";

import { Center } from "src/components/common/layout/Box.styled";
import { CircularLoader } from "src/components/common/loaders/CircularLoader";

export default function SequenceLibraryPage() {
  const reactorRedirect = useOldAppRedirect();

  useEffect(() => {
    reactorRedirect("/", undefined, true);
  }, [reactorRedirect]);

  return (
    <Center>
      <CircularLoader size={100} marginTop={20} />
    </Center>
  );
}
