import { Dispatch, Fragment, SetStateAction, useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { footageSelectors } from "src/models/Footage.model";
import { RootState } from "src/models/store";
import {
  Clip,
  ClipSequenceStatus,
  ClipSequenceType,
  ClipStatus,
  ClipType,
} from "src/network/graphql/generatedGraphqlSDK";

import { AspectRatio } from "src/constants/video.constants";

import styled from "styled-components/macro";
import { ifProp } from "styled-tools";

import { themeColor } from "src/utils/styledComponents.utils";

import Icon from "src/components/common/Icon";
import { Expandable } from "src/components/common/layout/Expandable";
import Tooltip from "src/components/common/popovers/Tooltip";

import * as footageAnalytics from "src/analytics/footage.analytics";

import { CircularLoader } from "src/components/common/loaders/CircularLoader";
import { FailedMark, GridContainer } from "src/components/features/SmartLibrary/SmartLibrary.styled";
import SequenceItem from "src/components/features/SmartLibrary/oneFootage/SequenceItem";
import { ASPECT_RATIO_OPTIONS_DICT, CLIPS_ORDER } from "src/components/features/SmartLibrary/smartLibrary.constants";
import { ClipSequenceFilters } from "src/components/features/SmartLibrary/smartLibrary.types";
import { useAppConfig } from "src/components/providers/AppConfigProvider";

const Container = styled.div<{ isClipOriginal: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: ${ifProp("isClipOriginal", themeColor("blue.30"), themeColor("white"))};

  opacity: 0;
  animation: appear 0.3s ease 0.3s forwards;

  @keyframes appear {
    to {
      opacity: 1;
    }
  }
`;

const SequencesContainer = styled(GridContainer)`
  width: 100%;
  height: fit-content;
  margin-block: unset;
  padding-block: 14px 14px;
  padding-inline: 30px;
  column-gap: 30px;
`;

const ScoreLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  margin-right: 8px;
  height: 33px;
  padding: 5px 9px 4px 7px;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  background-color: ${themeColor("white")};
  color: ${themeColor("blue.600")};
  svg {
    margin-right: 5px;
    margin-bottom: 2px;
  }
`;

const Header = styled.div<{ isExpanded: boolean; isClipOriginal: boolean; isClipExpandable: boolean }>`
  width: 100%;
  height: 46px;
  background-color: ${ifProp("isClipOriginal", themeColor("blue.1500"), themeColor("blue.50"))};
  display: flex;
  justify-content: flex-start;
  align-items: center;

  position: relative;
  padding-left: 30px;
  margin-bottom: 14px;
  cursor: ${ifProp("isClipExpandable", "pointer", "auto")};
  transition: margin-bottom 0.5s ease;

  ${CircularLoader} {
    margin-left: 12px;
  }
`;

const ClipTitleWrapper = styled.div`
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${themeColor("gray.900")};
  max-width: calc(100% - 190px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ExpandBtn = styled.div<{ isExpanded: boolean }>`
  width: 30px;
  height: 30px;
  position: absolute;
  right: 16px;
  top: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    fill: ${themeColor("blue.900")};
    transform: ${ifProp("isExpanded", "rotateZ(180deg)", "rotateZ(0deg)")};
    transition: transform 0.5s ease;
  }
`;

const Divider = styled.div`
  min-width: 100%;
  padding-inline: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  span {
    display: flex;
    flex: 1;
    border-top: solid 1px;
    border-color: ${themeColor("gray.300")};
  }
`;

const SeqTypeLabel = styled.div`
  border-radius: 70px;
  background-color: ${themeColor("blue.50")};
  padding: 5px 10px;
  font-family: "Open Sans";
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  margin-right: 6px;
`;

const ClipTypeBlock = styled.div`
  height: 100%;
  width: fit-content;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-left: 12px;
  }
`;

const OriginalTag = styled.div`
  width: fit-content;
  height: fit-content;
  background-color: ${themeColor("white")};
  border-radius: 20px;
  padding: 4px 12px;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  color: ${themeColor("gray.900")};
  margin-left: 3px;
`;

const ClipContentWrapper = styled.div`
  margin-bottom: 24px;
`;

const StyledFailedMark = styled(FailedMark)`
  position: relative;
  top: unset;
  margin-left: 20px;
`;

export interface FootageClipProps {
  footageId: string;
  clip: Clip;
  currentFilters: ClipSequenceFilters;
  durationRange: { minDuration: number; maxDuration: number } | null;
  clipsExpanded: Record<string, boolean>;
  setClipsExpanded: Dispatch<SetStateAction<Record<string, boolean>>>;
  expendWhenReady?: boolean;
  hasFootageScoring?: boolean;
}

export default function FootageClip({
  footageId,
  clip,
  currentFilters,
  durationRange,
  expendWhenReady = true,
  hasFootageScoring,
  clipsExpanded,
  setClipsExpanded,
}: FootageClipProps) {
  const { CLIP_SEQUENCES_ORDER_DICT } = useAppConfig();
  const filteredSequencesByTypeAndRatio = useSelector((state: RootState) => footageSelectors.selectFilteredSequencesByTypeAndRatio(state, clip, currentFilters, durationRange)); // prettier-ignore

  const isClipFailure = useMemo(() => clip.status === ClipStatus.Failure, [clip.status]);

  const isClipExpandable = useMemo(
    () =>
      !isClipFailure &&
      (clip.sequences?.some((seq) => seq.status === ClipSequenceStatus.Ready) ||
        clip.sequences?.every((seq) => seq.status === ClipSequenceStatus.Failure)),
    [clip.sequences, isClipFailure],
  );

  const shouldExpandWhenReady = useMemo(
    () =>
      expendWhenReady && isClipExpandable && !clip.sequences?.every((seq) => seq.status === ClipSequenceStatus.Failure),
    [clip.sequences, isClipExpandable, expendWhenReady],
  );

  //  TODO: change
  const tooltipText =
    "The score is calculated based on the quality of the video and the accuracy of the transcription.";

  const onClipHeaderClick = useCallback(() => {
    // track collapse / expand clip
    footageAnalytics.trackExpandCollapseClip({
      action: clipsExpanded[clip.id] ? "collapse" : "expand",
    });

    isClipExpandable && setClipsExpanded({ ...clipsExpanded, [clip.id]: !clipsExpanded[clip.id] });
  }, [clip.id, clipsExpanded, isClipExpandable, setClipsExpanded]);

  useEffect(() => {
    const delay = 1000;

    const timeout = setTimeout(() => {
      if (shouldExpandWhenReady) {
        setClipsExpanded((prevState) => ({ ...prevState, [clip.id]: true }));
      } else {
        setClipsExpanded((prevState) => ({ ...prevState, [clip.id]: false }));
      }
    }, delay);

    return () => clearTimeout(timeout);
  }, [clip.id, isClipExpandable, setClipsExpanded, shouldExpandWhenReady]);

  if (!filteredSequencesByTypeAndRatio || !Object.keys(filteredSequencesByTypeAndRatio).length) {
    return null;
  }

  return (
    <Container isClipOriginal={clip.type === ClipType.Source}>
      <Header
        onClick={onClipHeaderClick}
        isExpanded={clipsExpanded[clip.id]}
        isClipOriginal={clip.type === ClipType.Source}
        isClipExpandable={!!isClipExpandable}
      >
        {isClipExpandable && (
          <ExpandBtn isExpanded={clipsExpanded[clip.id]}>
            <Icon.ArrowDownCircle />
          </ExpandBtn>
        )}
        {hasFootageScoring && (
          <Tooltip text={tooltipText} placement="right-start" infoIcon>
            <ScoreLabel>
              <Icon.Star />
              {/* TODO: change to clip.score when back is ready */}
              85
            </ScoreLabel>
          </Tooltip>
        )}
        <ClipTypeBlock>
          <OriginalTag>{CLIPS_ORDER[clip.type]}</OriginalTag>
        </ClipTypeBlock>

        <ClipTitleWrapper>{clip.title}</ClipTitleWrapper>

        {isClipFailure && (
          <StyledFailedMark>
            <Fragment>
              <span>Failed</span>
              <Icon.Attention />
            </Fragment>
          </StyledFailedMark>
        )}

        {!isClipExpandable && !isClipFailure && <CircularLoader size={20} thickness={3} />}
      </Header>
      <Expandable isExpanded={clipsExpanded[clip.id]}>
        <ClipContentWrapper>
          {Object.keys(CLIP_SEQUENCES_ORDER_DICT).map((type) => {
            if (filteredSequencesByTypeAndRatio[type as ClipSequenceType]) {
              return (
                <Fragment key={type}>
                  {type !== ClipSequenceType.Manual && type !== ClipSequenceType.Original && (
                    <Divider>
                      <SeqTypeLabel>{CLIP_SEQUENCES_ORDER_DICT[type as ClipSequenceType]}</SeqTypeLabel>
                      <span />
                    </Divider>
                  )}

                  <SequencesContainer>
                    {Object.keys(ASPECT_RATIO_OPTIONS_DICT).map((rat) =>
                      filteredSequencesByTypeAndRatio[type as ClipSequenceType]?.[rat as AspectRatio]?.map((seq) => (
                        <SequenceItem
                          footageId={footageId}
                          clip={clip}
                          key={seq.sid ?? seq.title + seq.aspectRatio}
                          sid={seq.sid}
                          title={seq.title}
                          status={seq.status}
                          ratio={seq.aspectRatio}
                          languageCode={seq.languageCode}
                          isFavorite={seq.favorite}
                          type={seq.type}
                          downloadedAt={seq.downloadedAt}
                        />
                      )),
                    )}
                  </SequencesContainer>
                </Fragment>
              );
            }
            return null;
          })}
        </ClipContentWrapper>
      </Expandable>
    </Container>
  );
}
