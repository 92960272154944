import analyticsService from "src/services/Analytics.service";
import { Plan } from "src/network/graphql/generatedGraphqlSDK";
import { ChargeType } from "src/constants/model.constants";

export const trackPlanRequested = (newPlan: Plan, currentPlanSid: string | null, recurringType: ChargeType) =>
  analyticsService.track("PlanRequested", {
    planSid: newPlan.sid,
    planPrice: newPlan.overrideRecurringPrice ?? newPlan.recurringPrice,
    currentPlanSid,
    recurringType,
  });

export const trackOpenUpgradeModal = () => analyticsService.track("Upgrade Modal Displayed", { trigger: "topbar" });

export const trackFeatureSelected = (featureTitle: string) =>
  analyticsService.track("upgrade-feature-modal", {
    action: "select-feature",
    trigger: "feature-list",
    featureName: featureTitle,
  });

export const trackFeatureCTAButtonClick = (featureTitle: string) =>
  analyticsService.track("upgrade-feature-modal", {
    action: "cta",
    trigger: "feature-cta-button",
    featureName: featureTitle,
  });
