/* eslint-disable default-case */

import { RefObject } from "react";

import useMousetrap from "src/hooks/useMousetrap";

import { useVideoChapters } from "src/components/features/VideoTrimmer/providers/VideoChaptersProvider/VideoChaptersContext";
import { useTimelineZoom } from "src/components/features/VideoTrimmer/providers/TimelineZoomProvider/TimelineZoomContext";
import { useVideoPlayback } from "src/components/features/VideoTrimmer/providers/VideoPlaybackProvider/VideoPlaybackContext";
import { ShortcutsKeys } from "src/constants/shortcuts.constants";

interface PlayerActionButtonsProps {
  videoRef: RefObject<HTMLVideoElement>;
}

export default function TrimmerShortcuts({ videoRef }: PlayerActionButtonsProps) {
  const video = videoRef.current;

  const {
    chapters,
    startChapterResizing,
    endChapterResizing,
    resizeChapter,
    splitChapter,
    mergeChapters,
    deleteChapter,
  } = useVideoChapters();

  const {
    toggleFocusTimeInterval,
  } = useTimelineZoom(); // prettier-ignore

  const {
    activeChapterIndex,
  } = useVideoPlayback(); // prettier-ignore

  useMousetrap(ShortcutsKeys.split, () => splitChapter(activeChapterIndex, video?.currentTime ?? 0), { preventDefault: true }); // prettier-ignore
  useMousetrap(ShortcutsKeys.delete, () => deleteChapter(activeChapterIndex), { preventDefault: true }); // prettier-ignore
  useMousetrap(["mod+m"], () => mergeChapters(activeChapterIndex, activeChapterIndex + 1), { preventDefault: true }); // prettier-ignore
  useMousetrap(["mod+o"], () => toggleFocusTimeInterval(chapters[activeChapterIndex], chapters.length), { preventDefault: true }); // prettier-ignore

  useMousetrap(
    ["alt+left", "alt+right", "alt+shift+left", "alt+shift+right"] as const,
    (e, combo, action) => {
      if (action === "keyup") {
        endChapterResizing(activeChapterIndex);
        return;
      }

      if (!e.repeat) {
        startChapterResizing(activeChapterIndex);
      }

      requestAnimationFrame(() =>
        resizeChapter(
          activeChapterIndex,
          combo.includes("shift") ? "end" : "start",
          combo.includes("right") ? 0.1 : -0.1,
        ),
      );
    },
    { preventDefault: true, allowRepeat: true, action: ["keypress", "keyup"] },
  );

  return null;
}
