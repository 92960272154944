import { useCallback, useRef, useState } from "react";

import useTextSelectionEvents from "src/hooks/useTextSelectionEvents";

import {
  compareTranscriptPoints,
  getSelectionEdgeTranscriptPoint,
} from "src/components/features/VideoTrimmer/VideoTranscript/utils";
import { Line, TranscriptSelection } from "src/types/video-trimmer.types";

export const useTranscriptSelection = (lines: Line[]) => {
  const [transcriptSelection, setTranscriptSelection] = useState<TranscriptSelection | null>(null);
  const [isSelecting, setIsSelecting] = useState(false);
  const [recentInteractedSelectionEdge, setRecentInteractedSelectionEdge] = useState<"start" | "end" | null>(null);
  const selectionResizeStartPointRef = useRef<"start" | "end" | null>(null);

  const cancelSelection = useCallback(() => setTranscriptSelection(null), []);

  useTextSelectionEvents({
    onSelectionStart(selection) {
      setIsSelecting(true);

      const anchorPoint = getSelectionEdgeTranscriptPoint(selection, "anchor");

      if (!anchorPoint || !transcriptSelection) {
        return;
      }

      if (
        anchorPoint.lineIndex === transcriptSelection.start.lineIndex &&
        anchorPoint.itemIndex >= transcriptSelection.start.itemIndex - 1 &&
        anchorPoint.itemIndex <= transcriptSelection.start.itemIndex + 1
      ) {
        selectionResizeStartPointRef.current = "start";
      }

      if (
        anchorPoint.lineIndex === transcriptSelection.end.lineIndex &&
        anchorPoint.itemIndex >= transcriptSelection.end.itemIndex - 1 &&
        anchorPoint.itemIndex <= transcriptSelection.end.itemIndex + 1
      ) {
        selectionResizeStartPointRef.current = "end";
      }

      if (!selectionResizeStartPointRef.current) {
        setTranscriptSelection(null);
      }
    },

    onSelectionEnd() {
      setTranscriptSelection((currentTranscriptSelection) => {
        if (!currentTranscriptSelection) {
          return null;
        }

        const normalizedSelection = {
          start: {
            ...currentTranscriptSelection.start,
            itemOffset: 0,
          },
          end: {
            ...currentTranscriptSelection.end,
          },
        };

        if (normalizedSelection.end.itemOffset === 0) {
          normalizedSelection.end.itemIndex -= 1;

          if (normalizedSelection.end.itemIndex < 0) {
            normalizedSelection.end.lineIndex -= 1;

            if (normalizedSelection.end.lineIndex < 0) {
              return currentTranscriptSelection;
            }

            normalizedSelection.end.itemIndex = lines[normalizedSelection.end.lineIndex].items.length - 1;
          }
        }

        const selectionEndItem = lines[normalizedSelection.end.lineIndex].items[normalizedSelection.end.itemIndex];

        normalizedSelection.end.itemOffset = selectionEndItem.kind === "blank" ? 1 : selectionEndItem.word.length;

        return normalizedSelection;
      });

      if (selectionResizeStartPointRef.current) {
        setRecentInteractedSelectionEdge(selectionResizeStartPointRef.current);
        selectionResizeStartPointRef.current = null;
      }

      setIsSelecting(false);
    },

    onSelectionChange(selection) {
      // TODO: create getTranscriptSelection(selection: Selection): TranscriptSelection | null
      const anchorPoint = getSelectionEdgeTranscriptPoint(selection, "anchor");
      const focusPoint = getSelectionEdgeTranscriptPoint(selection, "focus");

      if (!anchorPoint || !focusPoint) return;

      setTranscriptSelection((currentTranscriptSelection) => {
        /** if this is a new selection */
        if (!selectionResizeStartPointRef.current) {
          /** handle selection direction (regular or inverted) */
          const isEndGreaterThanStart = compareTranscriptPoints(anchorPoint, focusPoint) === -1;
          setRecentInteractedSelectionEdge(isEndGreaterThanStart ? "end" : "start");
          return isEndGreaterThanStart // TODO: create a function to invert transcript selection
            ? {
                start: anchorPoint,
                end: focusPoint,
              }
            : {
                start: focusPoint,
                end: anchorPoint,
              };
        }

        if (!currentTranscriptSelection) {
          return null;
        }

        /** this is an existing selection being resized */
        let nextSelection = {
          ...currentTranscriptSelection,
          [selectionResizeStartPointRef.current]: focusPoint,
        };

        /** if while resizing the selection got inverted */
        if (compareTranscriptPoints(nextSelection.start, nextSelection.end) === 1) {
          selectionResizeStartPointRef.current = selectionResizeStartPointRef.current === "end" ? "start" : "end";
          // TODO: create a function to invert transcript selection
          const { start, end } = nextSelection;

          nextSelection = {
            start: end,
            end: start,
          };
        }

        return nextSelection;
      });
    },
  });

  return {
    transcriptSelection,
    recentInteractedSelectionEdge,
    isSelecting,
    cancelSelection,
  };
};
