import { useCallback, useMemo } from "react";
import styled, { css } from "styled-components/macro";

import { getStringTextDirection, TextDirection } from "src/utils/localization.utils";
import { ifProp, styleProp, switchProp, themeColor } from "src/utils/styledComponents.utils";

import { BLANK_WIDTH, TRANSCRIPT_SELECTION_HANDLE_WIDTH } from "src/constants/video-trimmer.constants";
import { getTranscriptCanvasContext, measureText } from "src/components/features/VideoTrimmer/VideoTranscript/utils";

import { LineItem, TranscriptSelection } from "src/types/video-trimmer.types";

interface SelectionEdgesProps {
  selectionStart: boolean;
  selectionStartOffset: number;
  selectionEnd: boolean;
  selectionEndOffset: number;
  isRTL: boolean;
  isSelecting: boolean;
}

const SelectionEdges = styled.div<SelectionEdgesProps>`
  position: absolute;
  z-index: -3;
  top: 0;
  height: 100%;
  background-color: ${ifProp("isSelecting", themeColor("blue.50"), "unset")};

  ${(props) => {
    if (props.selectionStart && props.selectionEnd) {
      return css`
        ${ifProp("isRTL", "right", "left")}: ${props.selectionStartOffset}px;
        ${ifProp("isRTL", "left", "right")}: calc(100% - ${props.selectionEndOffset}px);
        width: auto;
      `;
    }

    if (props.selectionStart) {
      return css<SelectionEdgesProps>`
        ${ifProp("isRTL", "right", "left")}: ${styleProp("selectionStartOffset")}px;
        ${ifProp("isRTL", "left", "right")}: 0;
        width: auto;
      `;
    }

    if (props.selectionEnd) {
      return css<SelectionEdgesProps>`
        ${ifProp("isRTL", "right", "left")}: 0;
        width: ${styleProp("selectionEndOffset")}px;
      `;
    }

    return false;
  }}
`;

interface SelectionHandleProps {
  edge: "start" | "end";
  offset: number;
  isSelecting: boolean;
  isRTL: boolean;
}

const SelectionHandle = styled.div<SelectionHandleProps>`
  position: absolute;
  top: 0;
  ${ifProp("isRTL", "right", "left")}: ${styleProp("offset")}px;
  width: ${TRANSCRIPT_SELECTION_HANDLE_WIDTH}px;
  height: 100%;
  background-color: ${themeColor("blue.500")};
  cursor: col-resize;
  pointer-events: ${ifProp("isSelecting", "none", "unset")};
  transition: all 0.1s ease-out;

  ${switchProp("edge", {
    start: css<SelectionHandleProps>`
      translate: ${ifProp("isRTL", 100, -100)}%;
      border-radius: ${ifProp("isRTL", "0 6px 6px 0", "6px 0 0 6px")};
    `,

    end: css<SelectionHandleProps>`
      border-radius: ${ifProp("isRTL", "6px 0 0 6px", "0 6px 6px 0")};
    `,
  })};
`;

interface EndOfSelectionProps {
  textDirection: TextDirection;
  isSelectionStart: boolean;
  isSelectionEnd: boolean;
  isSelecting: boolean;
  transcriptSelection: TranscriptSelection;
  lineItem: LineItem;
}

export default function EndOfSelection({
  textDirection,
  isSelectionStart,
  isSelectionEnd,
  isSelecting,
  transcriptSelection,
  lineItem,
}: EndOfSelectionProps) {
  const canvasContext = useMemo(() => getTranscriptCanvasContext(textDirection), [textDirection]);

  const getOffset = useCallback(
    (edge: "start" | "end") => {
      if (lineItem.kind === "blank") {
        return transcriptSelection[edge].itemOffset === 0 ? 0 : BLANK_WIDTH;
      }

      const selectedPart = lineItem.word.slice(0, transcriptSelection[edge].itemOffset);

      if (
        textDirection === "rtl" &&
        getStringTextDirection(lineItem.word) === "ltr" &&
        transcriptSelection.end.itemOffset < lineItem.word.length
      ) {
        return measureText(canvasContext, lineItem.word) - measureText(canvasContext, selectedPart);
      }

      return measureText(canvasContext, selectedPart);
    },
    [textDirection, lineItem, transcriptSelection, canvasContext],
  );

  const startOffset = useMemo(() => getOffset("start"), [getOffset]);
  const endOffset = useMemo(() => getOffset("end"), [getOffset]);

  return (
    <>
      <SelectionEdges
        // TODO: try to render as pseudo element of <SelectionHandle />
        selectionStart={isSelectionStart}
        selectionEnd={isSelectionEnd}
        selectionStartOffset={startOffset}
        selectionEndOffset={endOffset}
        isRTL={textDirection === "rtl"}
        isSelecting={isSelecting}
      />

      {isSelectionStart && (
        <SelectionHandle isRTL={textDirection === "rtl"} edge="start" offset={startOffset} isSelecting={isSelecting} />
      )}

      {isSelectionEnd && (
        <SelectionHandle isRTL={textDirection === "rtl"} edge="end" offset={endOffset} isSelecting={isSelecting} />
      )}
    </>
  );
}
