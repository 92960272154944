import CreateClipWizard from "src/components/features/CreateSequenceWizard/CreateClipWizard";
import { useRef } from "react";
import { useSearchParams } from "react-router-dom";

export default function CreateClipWizardPage() {
  const [searchParams] = useSearchParams();
  const clipTitleRef = useRef(searchParams.get("title"));
  const footageId = useRef(searchParams.get("footageId"));
  const clips = useRef(searchParams.get("clips"));
  const defaultAspectRatio = useRef(searchParams.get("defaultAspectRatio"));
  const boardId = useRef(searchParams.get("boardId"));

  return (
    <CreateClipWizard
      title={clipTitleRef.current}
      footageId={footageId.current}
      clips={clips.current}
      defaultAspectRatio={defaultAspectRatio.current}
      boardId={boardId.current}
    />
  );
}
