import { useState } from "react";

import styled from "styled-components/macro";
import { ifProp } from "styled-tools";

import { useNavigate } from "react-router-dom";
import Icon from "src/components/common/Icon";
import Dropzone from "src/components/common/dropzones/Dropzone";
import {
  RedirectToPlanButton,
  StyledExpiredTooltip,
  UploadsCounter,
} from "src/components/features/SmartLibrary/SmartLibrary.styled";
import { useUploaderContext } from "src/components/providers/UploaderProvider";
import { absoluteRoutes } from "src/utils/routes.utils";
import { themeColor } from "src/utils/styledComponents.utils";
import { useSelector } from "react-redux";
import { RootState } from "src/models/store";
import { sessionSelectors } from "src/models/Session.model";

const ButtonContainer = styled.div<{ showDragging: boolean; showBorder: boolean }>`
  width: 100%;
  aspect-ratio: 16/9;
  border: ${ifProp("showDragging", `dotted 2px`, `none`)};
  border-radius: 10px;
  border-color: ${themeColor("blue.150")};
  background: ${ifProp(
    "showBorder",
    "linear-gradient(90deg, #ea429d 0%, #7d55d2 54.17%, #0073c3 99.44%)",
    themeColor("blue.150"),
  )};
  cursor: ${ifProp("showBorder", "auto", "pointer")};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const ButtonText = styled.div`
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;

  color: ${themeColor("gray.400")};
  text-align: center;

  @media (min-width: 768px) {
    font-size: 10px;
    line-height: 10px;
  }
  @media (min-width: 900px) {
    font-size: 12px;
    line-height: 12px;
  }
`;

const ButtonTextBold = styled(ButtonText)`
  font-weight: 600;
`;

const ContentWrapper = styled.div`
  padding: 10px;
  text-align: center;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${themeColor("white")};
  border-radius: 9px;
`;

const IconWrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;

export const StyledInfoIcon = styled(Icon.InfoCircle)`
  position: absolute;
  bottom: 10px;
  left: 10px;
  min-height: 24px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Open Sans";
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;

  .fill {
    fill: ${themeColor("gray.500")};
  }

  .stroke {
    stroke: ${themeColor("gray.500")};
  }
`;

const StyledUploadVideoClosedIcon = styled(Icon.UploadVideoClosed)`
  @media (max-width: 600px) {
    height: 100%;
  }
  @media (max-width: 1200px) {
    height: 70%;
  }
`;

const InfoList = styled.ul`
  margin-block-start: 0px;
  margin-block-end: 0px;
  padding-left: 12px;
  text-indent: -3px;
`;

const InfoItem = styled.li``;

export interface UploadNewAssetButtonProps {
  handleDrop?: (acceptedFiles: File[], isDragging: boolean) => void;
  onClick?: () => void;
}

export default function UploadFootageButton({ handleDrop, onClick }: UploadNewAssetButtonProps) {
  const navigate = useNavigate();
  const nextBillingDate = useSelector((state: RootState) => sessionSelectors.selectFormattedBillingCycleDate(state));
  const [isDragging, setIsDragging] = useState(false);
  const { maxFootageUploads, uploadsLimitReached, amountOfUploadsAvailable, amountOfUsedUploading } =
    useUploaderContext();

  const onDrop = (acceptedFiles: File[]) => {
    let availableFiles: File[] = [];
    if (amountOfUploadsAvailable === null) {
      availableFiles = acceptedFiles;
    } else {
      availableFiles = acceptedFiles.slice(0, amountOfUploadsAvailable);
    }

    handleDrop?.(availableFiles, isDragging);
  };

  return (
    <ButtonContainer showDragging={isDragging} showBorder={uploadsLimitReached}>
      {!uploadsLimitReached ? (
        <Dropzone
          onDrop={onDrop}
          onClick={onClick}
          onDragStateChange={setIsDragging}
          accept={{
            "video/*": [],
          }}
        >
          <ContentWrapper>
            <IconWrapper>
              <Icon.UploadVideo />
            </IconWrapper>
            <TextWrapper>
              <ButtonText>To generate branded snippets</ButtonText>
              <ButtonText>Upload your talking videos</ButtonText>
              {!!maxFootageUploads && (
                <UploadsCounter>{`${amountOfUsedUploading} / ${maxFootageUploads} uploads a month`}</UploadsCounter>
              )}
            </TextWrapper>
          </ContentWrapper>
        </Dropzone>
      ) : (
        <ContentWrapper>
          <IconWrapper>
            <StyledUploadVideoClosedIcon />
          </IconWrapper>
          <TextWrapper>
            <ButtonTextBold style={{ marginBottom: "8px" }}>You’ve reached your upload limit!</ButtonTextBold>
            <ButtonText>Unlock more uploads with our premium plans</ButtonText>
            <RedirectToPlanButton onClick={() => navigate(absoluteRoutes.plans)}>Upgrade Now!</RedirectToPlanButton>
          </TextWrapper>
          <StyledExpiredTooltip
            text={
              <InfoList>
                <InfoItem>{`${amountOfUsedUploading} / ${maxFootageUploads} uploads a month`}</InfoItem>
                <InfoItem>{`Video upload limit will renew on ${nextBillingDate}`}</InfoItem>
              </InfoList>
            }
            placement="bottom"
            trigger="hover"
          >
            <StyledInfoIcon />
          </StyledExpiredTooltip>
        </ContentWrapper>
      )}
    </ButtonContainer>
  );
}
