import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Placement } from "@popperjs/core/lib/enums";

import styled from "styled-components/macro";
import { ifProp } from "styled-tools";

import { themeColor } from "src/utils/styledComponents.utils";

import { Dispatch, RootState } from "src/models/store";
import { userSelectors } from "src/models/User.model";

import Icon from "src/components/common/Icon";
import Popper from "src/components/common/popovers/Popper";
import { absoluteRoutes } from "src/utils/routes.utils";
import DownloadsBalanceInfo from "src/components/common/downloadsBalanceInfo/DownloadsBalanceInfo";
import { planSelectors } from "src/models/Plan.model";
import { isMobile } from "src/utils/mobile.utils";
import appMonitoringService from "src/services/AppMonitoring.service";

const UserAvatar = styled.div<{ isInner?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${ifProp("isInner", "46px", "26px")};
  height: ${ifProp("isInner", "46px", "26px")};
  border-radius: 50%;
  outline: solid;
  outline-width: 1px;
  outline-color: ${themeColor("blue.500")};
  outline-offset: 1px;
  background-color: #ccc;
  color: ${themeColor("white")};
  font-size: 16px;
  text-transform: uppercase;
  transition: outline-width 100ms ease;
`;

const GoogleUserAvatar = styled.img<{ isInner?: boolean }>`
  width: ${ifProp("isInner", "46px", "26px")};
  height: ${ifProp("isInner", "46px", "26px")};
  border-radius: 50%;
  border: solid;
  border-color: ${themeColor("blue.500")};
  border-width: 1px;
  transition: border-width 100ms ease;
`;

const UserMenuContainer = styled.div`
  padding: 7px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px;
  border-color: ${themeColor("blue.100")};
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.5s ease;

  &:hover {
    background-color: ${themeColor("blue.50")};
  }
`;

const TextBlock = styled.div`
  width: 160px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Open Sans", sans-serif;
  margin-block: 4px;
`;

const BoldText = styled(TextBlock)`
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
`;

const ThinText = styled(TextBlock)`
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: ${themeColor("gray.900")};
`;

const Organization = styled.div`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px 11px;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 70px;
  background-color: ${themeColor("blue.30")};
  color: ${themeColor("blue.900")};
  width: fit-content;
  max-width: 100%;
  min-width: 94px;
  margin-block: 4px;
  margin-bottom: 20px;
`;

const Avatar = styled.div`
  margin-bottom: 15px;
  width: fit-content;
  height: fit-content;
`;

const UserMenu = styled.div`
  box-shadow: 0px 4px 16px 0px rgba(4, 27, 63, 0.2);
  border-radius: 10px;
  background-color: ${themeColor("white")};
  overflow: hidden;
  margin-bottom: 15px;
`;

const InfoWrapper = styled.div`
  width: 100%;
  max-width: 320px;
  padding: 15px 10px 12px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const UpgradeButton = styled.div`
  color: ${themeColor("pink.500")};
  text-align: center;
  font-family: Open Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  display: flex;
  padding: 3px 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  transition: all 0.5s ease;
  cursor: pointer;
  &:hover {
    border-radius: 30px;
    background-color: ${themeColor("pink.50")};
  }
`;

const UserMenuItem = styled.div`
  width: 100%;
  max-width: 320px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  height: 30px;
  margin-block: 6px;
  padding-inline: 10px;
  svg {
    margin-right: 5px;
  }
  cursor: pointer;
  &:hover {
    background-color: ${themeColor("blue.50")};
  }
  transition: background-color 0.5s ease;
`;

const Separator = styled.div`
  margin-block: 10px;
  border-bottom: 1px solid ${themeColor("gray.200")};
`;

interface UserMenuPopoverNewProps {
  position: Placement;
}

export default function UserMenuPopover({ position }: UserMenuPopoverNewProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const [isOpen, setIsOpen] = useState(false);
  const currentUser = useSelector((state: RootState) => userSelectors.selectById(state, state.session.userId));
  const onLogout = useCallback(() => navigate(absoluteRoutes.logout), [navigate]);
  const hideUpgradeButton = useSelector((state: RootState) =>
    planSelectors.selectIsTierFeatureAllowed(state, "HIDE_UPGRADE_BUTTON"),
  );

  /* this useEffect is a temporary fix to update the view */
  /* should be replaced when peech-web handles downloads and triggers the update */
  useEffect(() => {
    if (!isOpen) return;
    dispatch.session.getDownloadsBalance();
    // get the session URL and copy it to the clipboard - logRocket
    appMonitoringService.getSessionURL()?.then((url) => {
      // eslint-disable-next-line no-console
      console.log("url", url);
      // copy to clipboard
      navigator.clipboard.writeText(url);
    });
  }, [isOpen, dispatch.session]);

  if (!currentUser) {
    return null;
  }

  return (
    <Popper
      isOpen={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      offsetY={5}
      placement={position}
      content={
        <UserMenu>
          <InfoWrapper>
            <Avatar>
              {currentUser.thumbUrl ? (
                <GoogleUserAvatar isInner src={currentUser.thumbUrl} alt="current-user" />
              ) : (
                <UserAvatar isInner>{currentUser.firstName?.[0]}</UserAvatar>
              )}
            </Avatar>

            <BoldText>{`${currentUser.firstName} ${currentUser.lastName}`}</BoldText>
            <ThinText>{currentUser.email}</ThinText>
            {currentUser.orgName && <Organization>{currentUser.orgName}</Organization>}

            <DownloadsBalanceInfo />
            {!hideUpgradeButton && (
              <UpgradeButton onClick={() => navigate(absoluteRoutes.plans)}> Upgrade Plan </UpgradeButton>
            )}
          </InfoWrapper>

          {!isMobile() && (
            <UserMenuItem onClick={() => navigate(absoluteRoutes.billing)}>
              <Icon.Billing />
              <ThinText>Billing</ThinText>
            </UserMenuItem>
          )}

          <Separator />

          <UserMenuItem onClick={onLogout}>
            <Icon.Logout />
            <ThinText>Log Out</ThinText>
          </UserMenuItem>
        </UserMenu>
      }
    >
      <UserMenuContainer>
        {currentUser.thumbUrl ? (
          <GoogleUserAvatar src={currentUser.thumbUrl} alt="current-user" />
        ) : (
          <UserAvatar>{currentUser.firstName?.[0]}</UserAvatar>
        )}
      </UserMenuContainer>
    </Popper>
  );
}
