import { PropsWithChildren } from "react";
import styled from "styled-components/macro";

import { themeColor } from "src/utils/styledComponents.utils";
import Popper from "src/components/common/popovers/Popper";
import { Placement } from "@popperjs/core/lib/enums";

const StyledPopper = styled(Popper)`
  min-width: 45px;
  /* height: 100%; */
`;

const Button = styled.button`
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  color: ${themeColor("gray.500")};
  cursor: pointer;
  transition: color 350ms ease-out;
  gap: 8px;
  svg {
    color: ${themeColor("gray.500")};
    transition: color 350ms ease-out, transform 250ms ease-out;
  }

  .path-fill {
    path {
      fill: ${themeColor("gray.500")};
      transition: fill 350ms ease-out;
    }
  }

  &:hover,
  &:active {
    color: ${themeColor("blue.500")};
    svg {
      color: ${themeColor("blue.500")};
    }
    .path-fill {
      path {
        fill: ${themeColor("blue.500")};
      }
    }
  }

  &:disabled {
    color: ${themeColor("gray.300")};
    svg {
      color: ${themeColor("gray.300")};
    }
    .path-fill {
      path {
        fill: ${themeColor("gray.300")};
      }
    }
  }
`;

export const ActionButtonPanel = styled.div`
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${themeColor("gray.500")};
  background: ${themeColor("white")};
  box-shadow: 0px 4px 16px 0px rgba(4, 27, 63, 0.2);
  border-radius: 6px;
  padding-inline: 10px;
  white-space: pre;
  text-transform: capitalize;
`;

interface ActionButtonProps extends PropsWithChildren {
  label?: string;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  popperPlacement?: Placement;
}

export default function ActionButton({
  className,
  children,
  label,
  disabled,
  onClick = () => {},
  popperPlacement = "top",
}: ActionButtonProps) {
  return (
    <StyledPopper
      disabled={!label}
      content={<ActionButtonPanel>{label}</ActionButtonPanel>}
      trigger="hover"
      placement={popperPlacement}
      offsetY={5}
    >
      <Button onClick={onClick} disabled={disabled} className={className}>
        {children}
      </Button>
    </StyledPopper>
  );
}
