// Define types for props
import React, { RefObject, useMemo } from "react";
import styled from "styled-components/macro";
import Icon from "src/components/common/Icon";
import { themeColor } from "src/utils/styledComponents.utils";
import { useVideoPlayback } from "src/components/features/VideoTrimmer/providers/VideoPlaybackProvider/VideoPlaybackContext";
import { useCurrentTime } from "src/components/features/VideoTrimmer/providers/VideoCurrentTimeProvider/VideoCurrentTimeContext";
import { formatSeconds } from "src/utils/time.utils";
import { Text6 } from "src/components/common/layout/typography.styled";

interface PositionProps {
  value: number;
}

const getPositionAttrs = ({ value }: PositionProps) => ({
  style: {
    transform: `translateX(${-100 + value}%)`,
    transformOrigin: "10%",
  },
});

const PlayPauseButton = styled.div`
  display: flex;
  border: none;
  width: 30px;
  margin-left: -10px;
  cursor: pointer;
  svg {
    color: ${themeColor("gray.500")};
    transition: color 350ms ease-out, transform 250ms ease-out;
  }

  .path-fill {
    path {
      fill: ${themeColor("gray.500")};
      transition: fill 350ms ease-out;
    }
  }

  &:hover,
  &:active {
    color: ${themeColor("blue.500")};
    svg {
      color: ${themeColor("blue.500")};
    }
    .path-fill {
      path {
        fill: ${themeColor("blue.500")};
      }
    }
  }

  &:disabled {
    color: ${themeColor("gray.300")};
    svg {
      color: ${themeColor("gray.300")};
    }
    .path-fill {
      path {
        fill: ${themeColor("gray.300")};
      }
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  justify-items: flex-start;
  align-items: center;
  align-self: center;
  align-content: center;
  width: 100%;
  height: 30px;
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 8px;
  background-color: #e5f4ff;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
`;

const Progress = styled.div.attrs(getPositionAttrs)<PositionProps>`
  height: 100%;
  background-color: ${themeColor("blue.500")};
  border-radius: 10px;
`;

const TimeWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const TimeContainer = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  background-color: ${themeColor("gray.200")};
  padding: 2px 6px;
  border-radius: 4px;
  cursor: default;
  user-select: none;
`;

const LowResText = styled(Text6)`
  align-self: flex-end;
  user-select: none;
`;

interface FootageVideoControlsProps {
  videoRef: RefObject<HTMLVideoElement>;
}

export default function FootageVideoControls({ videoRef }: FootageVideoControlsProps) {
  const videoElementRef = videoRef?.current;
  const { isPlaying, startManualSeeking } = useVideoPlayback();
  const currentTime = useCurrentTime();
  const duration = useMemo(() => videoElementRef?.duration || 0, [videoElementRef?.duration]);
  const progress = useMemo(() => {
    const percentage = !videoElementRef?.ended ? (currentTime / duration) * 100 : 0;
    return Number.isNaN(percentage) ? 0 : percentage;
  }, [currentTime, duration, videoElementRef?.ended]);
  const formattedCurrentTime = useMemo(() => formatSeconds(currentTime, "HH:mm:ss"), [currentTime]);
  const formattedDuration = useMemo(() => formatSeconds(duration, "HH:mm:ss"), [duration]);

  const togglePlayPause = () => {
    if (videoElementRef) {
      !isPlaying ? videoElementRef.play() : videoElementRef.pause();
    }
  };

  return (
    <Wrapper>
      <LowResText>Low resolution preview</LowResText>
      <ControlsContainer>
        <PlayPauseButton onClick={togglePlayPause}>
          {isPlaying ? <Icon.Pause size={30} color="black" /> : <Icon.Play2 size={30} color="black" />}
        </PlayPauseButton>
        <ProgressBar onMouseDown={startManualSeeking}>
          <Progress value={progress} />
        </ProgressBar>
      </ControlsContainer>
      <TimeWrapper>
        <TimeContainer>{`${formattedCurrentTime} / ${formattedDuration}`}</TimeContainer>
      </TimeWrapper>
    </Wrapper>
  );
}
