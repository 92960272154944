import { RefObject, useCallback } from "react";
import styled from "styled-components/macro";

// TODO: decouple of sequenceContentEditor.analytics - find a better solution to track trimmer events
import * as timelineAnalytics from "src/analytics/timeline.analytics";

import { themeColor } from "src/utils/styledComponents.utils";

import Icon from "src/components/common/Icon";
import { FlexBox } from "src/components/common/layout/Box.styled";
import { Stack, StackProps } from "src/components/common/layout/Stack.styled";
import { useTimelineZoom } from "src/components/features/VideoTrimmer/providers/TimelineZoomProvider/TimelineZoomContext";
import { useVideoPlayback } from "src/components/features/VideoTrimmer/providers/VideoPlaybackProvider/VideoPlaybackContext";
import ActionButton from "src/components/features/VideoTrimmer/TimelineActionButtons/ActionButton";
import { getShortcutKeyLabel, ShortcutsKeys } from "src/constants/shortcuts.constants";

const Container = styled(FlexBox)`
  height: 45px;
  align-items: center;
  background-color: ${themeColor("gray.50")};
  margin-bottom: 35px !important;
  padding-right: unset;
  padding-left: unset;

  > * {
    display: flex;
    justify-content: space-between;

    &:first-child {
      justify-content: center;
      margin-left: auto;
    }

    &:last-child {
      display: flex;
      margin-left: auto;
    }
  }

  @media (max-width: 1280px) {
    align-self: center;
    padding-right: 100%;
    padding-left: 100%;
    > *,
    > *:first-child {
    }

    > *:last-child {
      margin-left: unset;
    }
  }
`;

const ButtonStack = styled(Stack).attrs({
  direction: "row",
  spacing: 12,
} satisfies StackProps)`
  height: 100%;
  align-items: stretch;
`;

const Separator = styled.div`
  flex: 0 0 auto;
  width: 2px;
  border-radius: 2px;
  background: ${themeColor("gray.200")};
  @media (max-width: 1280px) {
    &:last-child {
      display: none;
    }
  }
`;

interface PlayerActionButtonsProps {
  videoRef: RefObject<HTMLVideoElement>;
  splitAction: (index: number, currentTime: number) => void;
  deleteAction: (index: number) => void;
  isSplitDisabled?: boolean;
  isDeleteDisabled?: boolean;
  activeIndex: number;
  seekForward: () => void;
  seekBackward: () => void;
}

export default function TimelineActionButtons({
  videoRef,
  splitAction,
  deleteAction,
  isSplitDisabled,
  isDeleteDisabled,
  activeIndex,
  seekForward,
  seekBackward,
}: PlayerActionButtonsProps) {
  const { isPlaying, changePlaybackRate, playbackRate } = useVideoPlayback();
  const { zoomIn, zoomOut, zoomReset } = useTimelineZoom();

  const onTogglePlay = useCallback(() => {
    if (isPlaying) {
      videoRef.current?.pause();
      timelineAnalytics.trackPause();
    } else {
      videoRef.current?.play();
      timelineAnalytics.trackPlay();
    }
  }, [isPlaying, videoRef]);

  return (
    <Container>
      <ButtonStack>
        <Separator />

        <ActionButton
          label={`Backward ${getShortcutKeyLabel(ShortcutsKeys.backward)}`}
          onClick={() => {
            seekBackward();
          }}
        >
          <Icon.PlayBackward />
        </ActionButton>

        <Separator />

        <ActionButton
          label={`${isPlaying ? "Pause" : "Play"} ${getShortcutKeyLabel(ShortcutsKeys.togglePlay)}`}
          onClick={onTogglePlay}
        >
          {isPlaying ? <Icon.Pause /> : <Icon.Play2 />}
        </ActionButton>

        <Separator />

        <ActionButton
          label={`Forward ${getShortcutKeyLabel(ShortcutsKeys.forward)}`}
          onClick={() => {
            seekForward();
          }}
        >
          <Icon.PlayForward />
        </ActionButton>

        <Separator />
      </ButtonStack>

      <ButtonStack>
        <Separator />

        <ActionButton
          label={`Split ${getShortcutKeyLabel(ShortcutsKeys.split)}`}
          disabled={isSplitDisabled}
          onClick={() => {
            splitAction(activeIndex, videoRef.current!.currentTime);
          }}
        >
          <Icon.Scissors />
        </ActionButton>

        <ActionButton
          label={`Remove ${getShortcutKeyLabel(ShortcutsKeys.delete)}`}
          disabled={isDeleteDisabled}
          onClick={() => {
            deleteAction(activeIndex);
          }}
        >
          <Icon.Trash width={20} height={20} />
        </ActionButton>

        <Separator />

        <ActionButton
          label={`Zoom In ${getShortcutKeyLabel(ShortcutsKeys.zoomIn)}`}
          onClick={() => {
            zoomIn();
            timelineAnalytics.trackZoomIn("zoom-in-button");
          }}
        >
          <Icon.Plus />
        </ActionButton>

        <ActionButton
          label={`Zoom Out ${getShortcutKeyLabel(ShortcutsKeys.zoomOut)}`}
          onClick={() => {
            zoomOut();
            timelineAnalytics.trackZoomOut("zoom-out-button");
          }}
        >
          <Icon.Minus />
        </ActionButton>

        <ActionButton
          label={`Reset Zoom ${getShortcutKeyLabel(ShortcutsKeys.zoomReset)}`}
          onClick={() => {
            zoomReset();
            timelineAnalytics.trackResetZoom();
          }}
        >
          <Icon.ZoomReset />
        </ActionButton>

        <ActionButton
          label={`Playback Speed ${getShortcutKeyLabel(ShortcutsKeys.playBackRate)}`}
          onClick={() => {
            changePlaybackRate();
            timelineAnalytics.trackPlaybackSpeedChange();
          }}
        >
          {playbackRate}x
        </ActionButton>
      </ButtonStack>
    </Container>
  );
}
