import { RefObject, useEffect, useRef } from "react";
import { castArray } from "lodash";
import Mousetrap from "mousetrap";
import "mousetrap/plugins/global-bind/mousetrap-global-bind";

export type Action = "keypress" | "keydown" | "keyup";

export type Shortcuts = string | readonly string[];

export type ShortcutUnion<S extends Shortcuts> = S extends string ? S : S[number];

export type MousetrapCallback<S extends Shortcuts> = (
  e: KeyboardEvent,
  shortcut: ShortcutUnion<S>,
  action: Action,
) => void;

export interface UseMouseTrapOptions {
  target: RefObject<Mousetrap.MousetrapInstance>;
  action: Action | Action[];
  preventDefault: boolean;
  stopPropagation: boolean;
  stopImmediatePropagation: boolean;
  allowRepeat: boolean;
  condition: boolean | (() => boolean);
}

export const isKeyboardEvent = (e: KeyboardEvent | object): e is KeyboardEvent => !!(e as KeyboardEvent).key;

export default function useMousetrap<S extends string | readonly string[]>(
  shortcuts: S,
  callback: MousetrapCallback<S>,
  {
    target: targetRef,
    action,
    preventDefault,
    stopPropagation,
    stopImmediatePropagation,
    allowRepeat = false,
    condition = true,
  }: Partial<UseMouseTrapOptions> = {},
) {
  const actions = castArray(action);
  const callbackRef = useRef<MousetrapCallback<S>>(() => {});

  callbackRef.current = (e, shortcut, currentAction) => {
    if (typeof condition === "function") {
      if (!condition()) return;
    } else if (!condition) {
      return;
    }

    if (isKeyboardEvent(e)) {
      preventDefault && e.preventDefault();
      stopPropagation && e.stopPropagation();
      stopImmediatePropagation && e.stopImmediatePropagation();
    }

    if (allowRepeat || !isKeyboardEvent(e) || !e.repeat) {
      callback(e, shortcut, currentAction);
    }
  };

  useEffect(
    () => {
      const target = targetRef?.current;

      actions.forEach((currentAction) => {
        if (target) {
          target.bind(
            shortcuts as string | string[],
            (e, shortcut) => callbackRef.current(e, shortcut as ShortcutUnion<S>, currentAction),
            currentAction,
          );
        } else {
          Mousetrap.bindGlobal(
            shortcuts as string | string[],
            (e, shortcut) => callbackRef.current(e, shortcut as ShortcutUnion<S>, currentAction),
            currentAction,
          );
        }
      });

      return () => {
        actions.forEach((currentAction) => {
          if (target) {
            target.unbind(shortcuts as string | string[], currentAction);
          } else {
            Mousetrap.unbind(shortcuts as string | string[], currentAction);
          }
        });
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [action, shortcuts.toString()],
  );
}

export function useMousetrapTarget<E extends Element>(ref: RefObject<E>) {
  const mousetrapTargetRef = useRef<Mousetrap.MousetrapInstance | null>(null);

  useEffect(() => {
    if (ref.current) {
      mousetrapTargetRef.current = new Mousetrap(ref.current);
    }

    return () => {
      mousetrapTargetRef.current?.reset();
    };
  }, [ref]);

  return mousetrapTargetRef;
}

Mousetrap.prototype.stopCallback = (e: Event, element: Element) =>
  element.classList.contains("mousetrap") && e.currentTarget !== e.target;
