/* eslint-disable no-confusing-arrow */

import { useMemo } from "react";
import styled from "styled-components/macro";

import { ifProp, themeColor } from "src/utils/styledComponents.utils";

import Icon from "src/components/common/Icon";
import Checkbox from "src/components/common/form/inputs/Checkbox";

const NameDiv = styled.div`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  user-select: none;
  padding-inline: 20px;
  text-align: center;
`;

const ButtonWrapper = styled.label<{ isSelected?: boolean; disabledFeature?: boolean; enableButtonClick: boolean }>`
  position: relative;
  margin: 8px;
  width: 138px;
  height: 107px;
  background-color: ${themeColor("white")};
  border: 1px solid;
  border-color: ${ifProp("isSelected", themeColor("pink.500"), themeColor("gray.300"))};
  border-radius: 8px;
  box-shadow: ${ifProp("isSelected", "0px 4px 4px rgba(0, 0, 0, 0.25)", "none")};
  cursor: ${ifProp("enableButtonClick", ifProp("disabledFeature", "not-allowed", "pointer"), "not-allowed")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: ${ifProp("enableButtonClick", "all")};

  &:hover {
    box-shadow: ${ifProp("disabledFeature", "none", "0px 4px 4px rgba(0, 0, 0, 0.25)")};
  }

  ${NameDiv} {
    color: ${ifProp("disabledFeature", themeColor("gray.400"), themeColor("gray.900"))};
  }

  @media (min-width: 375px) {
    width: 157px;
  }
`;

const ComingSoonBlock = styled.div`
  width: 94px;
  height: 26px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }

  span {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: ${themeColor("gray.500")};
    z-index: 4;
  }
`;

const DisWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;

  ${ComingSoonBlock} {
    margin: 16px 0 10px;
  }
`;

const CheckboxWrapper = styled.div`
  position: absolute;
  top: 7px;
  left: 7px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

interface EditRulesButtonProps {
  disabledFeature: boolean;
  isSelected: boolean;
  onSelect: (rule: string) => void;
  rule: string;
  name: string;
  isLoading?: boolean;
}

export default function EditRulesButton({
  disabledFeature,
  isSelected,
  isLoading,
  onSelect,
  rule,
  name,
}: EditRulesButtonProps) {
  const enableButtonClick = useMemo(() => !disabledFeature && !isLoading, [disabledFeature, isLoading]);

  return (
    <ButtonWrapper isSelected={isSelected} disabledFeature={disabledFeature} enableButtonClick={enableButtonClick}>
      {!disabledFeature && (
        <CheckboxWrapper>
          <Checkbox isChecked={isSelected} onChange={() => enableButtonClick && onSelect(rule)} />
        </CheckboxWrapper>
      )}

      {!disabledFeature && <NameDiv>{name}</NameDiv>}

      {disabledFeature && (
        <DisWrapper>
          <ComingSoonBlock>
            <Icon.ComingSoon />
            <span>Coming Soon</span>
          </ComingSoonBlock>

          <NameDiv>{name}</NameDiv>
        </DisWrapper>
      )}
    </ButtonWrapper>
  );
}
