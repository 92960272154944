import React, { PropsWithChildren, useMemo } from "react";
import styled from "styled-components/macro";

import { LineItem, TimeInterval } from "src/types/video-trimmer.types";

import Popper, { PopperProps } from "src/components/common/popovers/Popper";
import { useVideoChapters } from "src/components/features/VideoTrimmer/providers/VideoChaptersProvider/VideoChaptersContext";
import Icon from "src/components/common/Icon";
import { themeColor } from "src/utils/styledComponents.utils";
import { FlexBox } from "src/components/common/layout/Box.styled";
import { useVideoWords } from "src/components/features/VideoTrimmer/providers/VideoWordsProvider/VideoWordsContext";
import { chapterTimeIntervalUtils } from "../common/chapterTimeInterval.utils";

const Button = styled.button`
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  padding: 6px 10px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 4px 16px 0 rgba(4, 27, 63, 0.2);
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: ${themeColor("gray.500")};
  font-family: "Open Sans", sans-serif;
  cursor: pointer;
`;

interface LineItemMenuProps extends PropsWithChildren, Pick<PopperProps, "isOpen" | "onOpen" | "onClose" | "disabled"> {
  isPlayable: boolean;
  item: LineItem;
  revertLabel?: string;
  removeLabel?: string;
  revertIcon?: React.ReactNode;
  removeIcon?: React.ReactNode;
}

export default function LineItemMenu({
  children,
  isPlayable,
  item,
  revertLabel = "Revert",
  removeLabel = "Remove",
  revertIcon = <Icon.Undo size={16} color="blue.500" />,
  removeIcon = <Icon.Trash size={16} color="blue.500" />,
  ...props
}: LineItemMenuProps) {
  const { chapters, subtractTimeIntervals, addTimeIntervals } = useVideoChapters();
  const { words, blanks } = useVideoWords();

  const transcriptItems = useMemo(() => (words as TimeInterval[]).concat(blanks), [words, blanks]);

  return (
    <Popper
      {...props}
      offsetY={12}
      placement="bottom-start"
      style={{ display: "inline" }}
      trigger="hover"
      disabled={props.disabled}
      content={
        <Button
          onClick={() => {
            const ItemsWithoutGaps = chapterTimeIntervalUtils.removeGapsTimeInterval(item, chapters, transcriptItems);
            isPlayable ? subtractTimeIntervals([ItemsWithoutGaps]) : addTimeIntervals([ItemsWithoutGaps]);
          }}
        >
          <FlexBox marginRight={10}>{isPlayable ? removeIcon : revertIcon}</FlexBox>
          {isPlayable ? removeLabel : revertLabel}
        </Button>
      }
    >
      {children}
    </Popper>
  );
}
