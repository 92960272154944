import TimeIndicator from "src/components/features/VideoTrimmer/TimelineEditor/TimeIndicator/TimeIndicator";
import { useCurrentTime } from "src/components/features/VideoTrimmer/providers/VideoCurrentTimeProvider/VideoCurrentTimeContext";

interface CurrentTimeIndicatorProps {
  pixelPerSecond: number;
  popupTarget: HTMLElement | null;
}

export default function CurrentTimeIndicator({ pixelPerSecond, popupTarget }: CurrentTimeIndicatorProps) {
  const currentTime = useCurrentTime();

  return <TimeIndicator timeInSeconds={currentTime} pixelPerSecond={pixelPerSecond} popupTarget={popupTarget} />;
}
