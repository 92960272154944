import { useEffect } from "react";
import styled from "styled-components/macro";

import VideoTrimmer from "src/components/features/VideoTrimmer/VideoTrimmer";
import useSequenceVideoEditor from "src/components/features/VideoTrimmer/common/hooks/useInitSequenceVideoEditor";
import { isMobile } from "src/utils/mobile.utils";
import { CircularLoader } from "src/components/common/loaders/CircularLoader";
import { useSelector } from "react-redux";
import { RootState } from "src/models/store";
import { sequenceSelectors } from "src/models/Sequence.model";
import { FormContextInterface } from "src/components/features/AutomaticCreateWizard/FormContext.interface";

const Container = styled.div`
  flex: 1;
  overflow: hidden;
`;

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function ContentStep({ formContext }: FormContextInterface) {
  const { watch, setValue } = formContext;
  const sequenceSid = watch("content.sequenceSid") ?? "";

  const sequenceVideoTrimmerProps = useSequenceVideoEditor(sequenceSid, true);
  const { state: chapterTimeIntervals } = sequenceVideoTrimmerProps.chaptersHistory;
  const sequence = useSelector((state: RootState) => sequenceSelectors.selectById(state, sequenceSid)); // prettier-ignore

  useEffect(() => {
    setValue("content.chapters", chapterTimeIntervals, { shouldValidate: true });
  }, [setValue, chapterTimeIntervals]);

  return (
    <Container>
      {isMobile() ? (
        <LoaderContainer>
          <CircularLoader size={30} thickness={3} />
        </LoaderContainer>
      ) : (
        sequence && <VideoTrimmer {...sequenceVideoTrimmerProps} inManualCreate />
      )}
    </Container>
  );
}
