import { components, OptionProps } from "react-select";
import Flag from "react-world-flags";

import styled from "styled-components/macro";

import { Stack } from "src/components/common/layout/Stack.styled";
import { LanguageOption } from "src/components/providers/AppConfigProvider";

export const StyledOption = styled(components.Option)`
  height: 42px !important;
  align-items: center !important;
  display: flex !important;
` as any as typeof components.Option;

export const StyledSelectStack = styled(Stack)`
  align-items: center;
  height: 26px;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: 400;
`;

export const FlagWrapper = styled.div`
  width: 24px;
  height: 16px;
`;

export default function LanguageSelectOption(props: OptionProps<LanguageOption>) {
  const { data: languageOption } = props;

  return (
    <StyledOption {...props}>
      <StyledSelectStack direction="row" spacing={12}>
        <FlagWrapper>
          <Flag code={languageOption.languageCode.split("-")[1]} />
        </FlagWrapper>
        <span>{languageOption.label}</span>
      </StyledSelectStack>
    </StyledOption>
  );
}
