import styled from "styled-components/macro";

import { themeColor } from "src/utils/styledComponents.utils";

import { Box } from "src/components/common/layout/Box.styled";

import Icon from "src/components/common/Icon";
import { ifProp } from "styled-tools";
import { Maybe } from "graphql/jsutils/Maybe";

const Container = styled(Box)<{ isVisible?: Maybe<boolean> }>`
  width: 130px;
  height: 28px;
  border-radius: 13px;
  background-color: ${ifProp("isVisible", themeColor("gray.30"), "none")};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const TextBlock = styled(Box)`
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: ${themeColor("gray.400")};
`;

interface ShutterstockLabelProps {
  isVisible?: Maybe<boolean>;
}

export default function ShutterstockLabel({ isVisible }: ShutterstockLabelProps) {
  return (
    <Container marginBlock={12} isVisible={isVisible}>
      {isVisible && (
        <>
          <TextBlock marginRight={4} marginTop={2}>
            Includes
          </TextBlock>
          <Icon.Shutterstock />
        </>
      )}
    </Container>
  );
}
