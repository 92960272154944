import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { compact } from "lodash/fp";

import { useModal } from "src/components/providers/ModalProvider";
import { useUploaderContext } from "src/components/providers/UploaderProvider";
import RedirectConfirmationModal from "src/components/modals/RedirectConfirmationModal";
import { useFeatureFlag } from "src/components/providers/FeatureFlagsProvider";

// TODO: refactor, decouple from UploaderContext, RedirectConfirmationModal and /webinars
export default function useSafeNavigate() {
  const navigate = useNavigate();
  const isNewProjectCreationFlowEnabled = useFeatureFlag("newProjectCreationFlow");
  const isFastProjectCreationFlowEnabled = useFeatureFlag("fastProjectCreationFlow");
  const { isUploading, setShouldIgnoreCheckBeforeUnload } = useUploaderContext();
  const { openModal } = useModal();

  const reactorRoutes = useMemo(
    () =>
      compact([
        isNewProjectCreationFlowEnabled || isFastProjectCreationFlowEnabled ? null : "/create-project",
        "/library",
        "/projects",
        "/brand",
      ]),
    [isNewProjectCreationFlowEnabled, isFastProjectCreationFlowEnabled],
  );

  return useCallback(
    (linkTo: string) => {
      if (isUploading && reactorRoutes.some((route) => linkTo.startsWith(route))) {
        const onConfirm = () => {
          setShouldIgnoreCheckBeforeUnload(true);
          navigate(linkTo);
        };

        openModal((props) => <RedirectConfirmationModal {...props} confirmCallback={onConfirm} />, {
          hideCloseButton: true,
        });
        return;
      }

      navigate(linkTo);
    },
    [isUploading, navigate, openModal, reactorRoutes, setShouldIgnoreCheckBeforeUnload],
  );
}
