import axios from "axios";
import * as FirebaseAnalytics from "firebase/analytics";
import * as FirebaseRemoteConfig from "firebase/remote-config";
import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "src/models/store";

import { API_SERVER_URL, APP_ENV, GIT_COMMIT_HASH } from "src/constants/env.constants";

import apiClient from "src/network/ApiClient";
import socketClient from "src/network/SocketClient";

import analyticsService from "src/services/Analytics.service";
import appMonitoringService from "src/services/AppMonitoring.service";
import errorMonitoringService from "src/services/ErrorMonitoring.service";
import userEngagementService from "src/services/UserEngagement.service";

import firebaseApp from "src/firebaseApp";
import { PricingData, UpgradeModelFeature } from "src/types/pricing.types";
import { CLIP_SEQUENCES_ORDER_DICT_DEFAULT } from "src/components/features/SmartLibrary/smartLibrary.constants";

/** temporary until backend will support these */
const formatServerAppConfig = (config: AppConfig): AppConfig => ({
  ...config,
  CLIP_SEQUENCES_ORDER_DICT: {
    ...CLIP_SEQUENCES_ORDER_DICT_DEFAULT,
    ...config.CLIP_SEQUENCES_ORDER_DICT,
  },
  GOOGLE_CLIENT_ID: "564841733065-l2fdeat6c7ou44709bt0qltrrsejsk25.apps.googleusercontent.com",
  HOMEPAGE_URL: "https://www.peech-ai.com",
  MAX_BLANKS: 45,
  CALENDLY_FORM_ID: "ea039bc2-5062-4b91-b109-f93dbec645c4",
});

// TODO: move to entities type js
export interface LanguageOption {
  languageCode: string;
  label: string;
  status: "ACTIVE" | "INACTIVE";
}

export type FootageExpiredType = {
  expiredDays: number;
  warningDays: number;
  text: string;
  toastText: string;
};

export interface EditorHeader {
  tabs: {
    [key in "content" | "crop" | "visual"]?: {
      label: string;
      disabled?: {
        tooltip: string;
      };
    };
  };
}

type FillerWordsByLocale = Record<string, string[]>;

export interface AppConfig {
  APP_VERSION: string;
  ENV_NAME: string;
  API_URL: string;
  CHUNKS_URL: string;
  STATUS_URL: string;
  CONTENT_URL: string;
  INGEST_URL: string;
  PLAYBACK_URL: string;
  BLUESNAP_URL: string;
  BLUE_SNAP_SCRIPT_URL: string;
  BLUESNAP_MERCHANT_ID: string;
  MIXPANEL_TOKEN: string;
  SEGMENT_TOKEN: string;
  LOGROCKET_APP_ID: string;
  GOOGLE_CLIENT_ID: string;
  GOOGLE_API_KEY: string;
  TRENDS_PLAYLIST_ID: string;
  LEARNING_CENTER_PLAYLIST_ID: string;
  SENTRY_TOKEN: string;
  SENTRY_TRACES_SAMPLE_RATE: number;
  GTM_ID: string;
  SUPPORTED_LANGUAGES: Array<LanguageOption>;
  TRANSLATE_LANGUAGES: Array<LanguageOption>;
  PADDLE_ENV: string;
  PADDLE_VENDOR_ID: string;
  HOMEPAGE_URL: string;
  UPGRADE_MODAL_FEATURES: UpgradeModelFeature[];
  MAX_CHAPTERS_AMOUNT: number;
  CALENDLY_FORM_ID: string;
  FILLER_WORDS: FillerWordsByLocale;
  MAX_BLANKS: number;
  EXAMPLE_YOUTUBE_VIDEO_IDS: string[];
  PRICING_PAGE: PricingData;
  EXAMPLE_FOOTAGE_ID: string;
  EXAMPLE_PRESET_ID: string;
  FOOTAGE_EXPIRED: FootageExpiredType;
  EDITOR_HEADER: EditorHeader;
  CLIP_SEQUENCES_ORDER_DICT: Record<string, string>;
  FOOTAGE_UPLOAD_DURATION: { text: string };
  PLAYBACK_RATES: number[];
}

export const AppConfigContext = createContext<AppConfig | null>(null);

// TODO: move to redux
export default function AppConfigProvider({ children }: PropsWithChildren<{}>) {
  const [appConfig, setAppConfig] = useState<AppConfig | null>(null);
  const authToken = useSelector((state: RootState) => state.session.authToken);

  const fetchServerAppConfig = useCallback(
    () =>
      axios(`${API_SERVER_URL}/config/${GIT_COMMIT_HASH}/config.json`, {
        timeout: 3000,
      }).then((res) => formatServerAppConfig(res.data)),
    [],
  );

  const fetchFirebaseAppConfig = useCallback(() => {
    const remoteConfig = FirebaseRemoteConfig.getRemoteConfig();

    // disabled cache need to handle it
    remoteConfig.settings.minimumFetchIntervalMillis = 0;

    // TODO: need to move to init analytics with anonymous user
    const firebaseClient = FirebaseAnalytics.getAnalytics(firebaseApp);
    FirebaseAnalytics.setUserId(firebaseClient!, null);
    FirebaseAnalytics.setUserProperties(firebaseClient!, { env: APP_ENV });

    return FirebaseRemoteConfig.fetchAndActivate(remoteConfig).then(() => {
      const remoteConfigValues = FirebaseRemoteConfig.getAll(remoteConfig);

      return Object.entries(remoteConfigValues).reduce((acc, [feature, flagValue]) => {
        // pick only config parameters
        if (!feature.includes("config_")) {
          return acc;
        }

        const featureName = feature.replace("config_", "").toUpperCase();
        let featureValue = flagValue.asString();

        try {
          featureValue = JSON.parse(featureValue);
        } catch {
          /* empty */
        }

        return { ...acc, [featureName]: featureValue };
      }, {});
    });
  }, []);

  useEffect(() => {
    const loadAppConfig = async () => {
      try {
        const [serverAppConfig, firebaseAppConfig] = await Promise.all([
          fetchServerAppConfig(),
          fetchFirebaseAppConfig(),
        ]);

        const fetchedAppConfig = {
          ...serverAppConfig,
          ...firebaseAppConfig,
        };

        apiClient.config(fetchedAppConfig);
        socketClient.config(fetchedAppConfig);
        apiClient.init(authToken);
        socketClient.init(authToken);

        errorMonitoringService.init(fetchedAppConfig);
        userEngagementService.init(fetchedAppConfig);
        appMonitoringService.init(fetchedAppConfig);
        analyticsService.init(fetchedAppConfig);

        setAppConfig(fetchedAppConfig);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log("Failed to load app config, retrying...", { e });
        setTimeout(loadAppConfig, 1000);
      }
    };

    loadAppConfig();
  }, [authToken, fetchServerAppConfig, fetchFirebaseAppConfig]);

  if (!appConfig) {
    return null;
  }

  return <AppConfigContext.Provider value={appConfig}>{children}</AppConfigContext.Provider>;
}

export const useAppConfig = () => {
  const contextValue = useContext(AppConfigContext);

  if (!contextValue) {
    throw new Error("useAppConfig was used outside of AppConfigProvider");
  }

  return contextValue;
};
