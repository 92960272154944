import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, matchPath, useLocation, useNavigate, useParams } from "react-router-dom";

import styled from "styled-components/macro";

import { footageSelectors } from "src/models/Footage.model";
import { RootState } from "src/models/store";

import { themeColor } from "src/utils/styledComponents.utils";

import ContactMenuPopover from "src/components/app/AppRouter/layouts/ContactMenuPopover";
import NotificationCenterPopover from "src/components/app/AppRouter/layouts/NotificationsCenter/NotificationCenterPopover";
import UserMenuPopover from "src/components/app/AppRouter/layouts/UserMenuPopover";
import Icon from "src/components/common/Icon";
import { Button } from "src/components/common/buttons/Button.styled";
import WhiteRainbowButton, { Layer } from "src/components/common/buttons/WhiteRainbowButton";
import { Stack } from "src/components/common/layout/Stack.styled";
import { useFeatureFlag } from "src/components/providers/FeatureFlagsProvider";
import { userSelectors } from "src/models/User.model";
import { isMobile } from "src/utils/mobile.utils";
import { absoluteRoutes, applyPathParams, routerRoutes } from "src/utils/routes.utils";
import { planSelectors } from "src/models/Plan.model";
import { BoardSelect } from "src/components/features/Boards/BoardSelect";
import { BoardSortFields, SortDirection } from "src/network/graphql/generatedGraphqlSDK";
import { boardSelectors } from "src/models/Board.model";
import { LAST_BOARD_ID } from "src/constants/smartLibrary.constants";
import { BackBtn, Header, HeaderElementWrapper, LeftSide, RightSide, TextWrapper } from "./Header.styled";

const UpgradeButton = styled.div`
  width: 188px;
  height: 36px;
  padding: 6px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: ${themeColor("white")};

  span {
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 14px;
    color: ${themeColor("gray.900")};
    background-image: linear-gradient(90deg, #ea429d 0%, #7d55d2 54.17%, #0073c3 99.44%);
    background-clip: text;
    -webkit-background-clip: text;
    transition: all 350ms ease-out;
  }

  &:hover {
    span {
      color: transparent;
    }
  }

  @media (max-width: 768px) {
    width: 144px;
    span {
      margin-left: 10px;
      font-size: 10px;
    }
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  overflow: hidden;
  background-color: ${themeColor("gray.50")};
`;

const TitleStyled = styled(TextWrapper)`
  min-width: unset;
`;

const StyledWhiteRainbowButton = styled(WhiteRainbowButton)`
  ${Layer} {
    padding: 0px 7px 0px 0px;
  }
`;

interface Props {
  brandBookButton?: boolean;
  upgradeButton?: boolean;
  boardSelect?: boolean;
  logoType?: "short" | "long";
  backBtn?: boolean;
}

export default function WithPlatformHeader({
  brandBookButton = true,
  upgradeButton = true,
  boardSelect = true,
  logoType = "long",
  backBtn = false,
}: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = useSelector(userSelectors.selectCurrentUser);
  const smartLibEnabled = useFeatureFlag("smartLibrary");
  const userHasLoggedBefore = window.localStorage.getItem("userHasLoggedBefore");
  const localStorageBoardId = window.localStorage.getItem(LAST_BOARD_ID);
  const hideUpgradeButton = useSelector((state: RootState) =>
    planSelectors.selectIsTierFeatureAllowed(state, "HIDE_UPGRADE_BUTTON"),
  );
  // TODO: remove this and create header for footage
  const pathParams = useParams<{ footageId: string; boardId: string }>();
  const footage = useSelector(
    (state: RootState) => pathParams?.footageId && footageSelectors.selectById(state, pathParams.footageId),
  );
  const footageBoard = useSelector((state: RootState) =>
    boardSelectors.selectBoardByFootageId(state, pathParams?.footageId),
  );

  // persist the last board while navigating / logging in
  const selectedBoardId = useMemo(() => {
    if (pathParams?.boardId) {
      return pathParams.boardId;
    }
    if (localStorageBoardId === footageBoard?.id) {
      return localStorageBoardId;
    }
    return null;
  }, [pathParams.boardId, localStorageBoardId, footageBoard?.id]);

  const onUpgradeBtnClick = useCallback(() => {
    navigate(absoluteRoutes.plans);
  }, [navigate]);

  const displayBranBookButton = useMemo(
    () => brandBookButton && currentUser && smartLibEnabled,
    [brandBookButton, currentUser, smartLibEnabled],
  );

  const displayUpgradeButton = useMemo(
    () => upgradeButton && currentUser && !hideUpgradeButton,
    [upgradeButton, currentUser, hideUpgradeButton],
  );

  const onBrandBookClick = useCallback(() => {
    // Temp until  brand page will be in peech-web
    if (location.pathname === absoluteRoutes.brand) {
      const iframe = document.getElementById("brand-iframe") as HTMLIFrameElement;
      if (iframe) {
        iframe.src = iframe.src.replace(/#.+$/, `#${new Date().getTime()}`);
      }
    } else {
      navigate(absoluteRoutes.brand);
    }
  }, [location.pathname, navigate]);

  // TODO: move this logic
  const title = useMemo(() => {
    if (location.pathname.includes(absoluteRoutes.billing)) {
      return "Billing";
    }
    if (matchPath({ path: routerRoutes.brand, end: false }, location.pathname)) {
      return "Brand Kit";
    }
    if (currentUser && location.pathname.includes(absoluteRoutes.plans)) {
      return "Plans";
    }
    if (location.pathname.includes(absoluteRoutes.platform.self) && footage) {
      return footage.filename;
    }
    return "";
  }, [footage, location.pathname, currentUser]);

  useEffect(() => {
    dispatch.boards.fetchBoards({
      sorting: {
        field: BoardSortFields.Name,
        direction: SortDirection.Asc,
      },
      paging: {
        offset: 0,
        limit: 1000,
      },
      filter: {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header>
        <LeftSide>
          {backBtn && (
            <BackBtn
              onClick={() => {
                selectedBoardId
                  ? navigate(
                      applyPathParams(absoluteRoutes.platform.children.boards, { boardId: selectedBoardId } as any),
                    )
                  : navigate(absoluteRoutes.platform.self);
              }}
            >
              <Icon.ArrowDown />
            </BackBtn>
          )}
          <Stack
            style={{ alignItems: "center", cursor: "pointer", marginRight: "16px" }}
            direction="row"
            spacing={10}
            onClick={() => navigate(absoluteRoutes.home)}
          >
            {logoType === "short" ? <Icon.PeechLogoShort /> : <Icon.PeechLogo />}
          </Stack>
          {!isMobile() && boardSelect && (
            <Stack direction="row" spacing={10}>
              <BoardSelect selectedBoardId={selectedBoardId} />
            </Stack>
          )}

          {/* add title props to pass to header */}
          <TitleStyled>{title}</TitleStyled>
        </LeftSide>
        <RightSide>
          {isMobile() && boardSelect && (
            <Stack direction="row" spacing={10}>
              <BoardSelect selectedBoardId={selectedBoardId} />
            </Stack>
          )}
          {displayUpgradeButton && (
            <HeaderElementWrapper>
              <UpgradeButton onClick={onUpgradeBtnClick}>
                <Icon.DiamondColored />
                <span>Get more from Peech</span>
              </UpgradeButton>
            </HeaderElementWrapper>
          )}
          {displayBranBookButton && !isMobile() && (
            <HeaderElementWrapper>
              <StyledWhiteRainbowButton
                onClick={onBrandBookClick}
                text="Brand Kit"
                icon={<Icon.BrushRainbow />}
                variant="white"
                width="114px"
                height="34px"
                fontSize="12px"
              />
            </HeaderElementWrapper>
          )}
          <HeaderElementWrapper>
            <NotificationCenterPopover />
          </HeaderElementWrapper>
          <HeaderElementWrapper>
            {currentUser ? (
              <UserMenuPopover position="bottom-end" />
            ) : (
              <Button
                variant="ghost"
                size="small"
                onClick={() => navigate(userHasLoggedBefore ? absoluteRoutes.login : absoluteRoutes.signup.self)}
              >
                {userHasLoggedBefore ? "Log in" : "Sign up"}
              </Button>
            )}
          </HeaderElementWrapper>
          <HeaderElementWrapper>
            <ContactMenuPopover />
          </HeaderElementWrapper>
        </RightSide>
      </Header>

      <ContentContainer>
        <Outlet />
      </ContentContainer>
    </>
  );
}
