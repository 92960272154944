import { memo, useEffect, useMemo, useRef } from "react";
import { maxBy } from "lodash/fp";

import AudioSample from "src/types/media.types";
import { TimeInterval } from "src/types/video-trimmer.types";

import { timeIntervalUtils } from "src/utils/timeInterval.utils";

type PxPerSec = number;

interface AudioSamplesContext {
  audioSamples: AudioSample[];
  audioSampleGroupSize: number;
}

let cache: Record<PxPerSec, AudioSamplesContext> = {};

interface Props {
  zoomedTimeInterval: TimeInterval;
  pxPerSec: number;
  width: number;
  height: number;
  audioSampleBarWidth: number;
  audioSampleBarColor: string;
  sampleRate: number;
  audioSamples: AudioSample[] | undefined;
}

function SoundWaveBase({
  zoomedTimeInterval,
  pxPerSec,
  width,
  height,
  audioSampleBarWidth,
  audioSampleBarColor,
  sampleRate,
  audioSamples: originalAudioSamples,
}: Props) {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const canvasContextRef = useRef<CanvasRenderingContext2D | null>(null);
  const offscreenCanvasRef = useRef<OffscreenCanvas | null>(null);
  const offscreenCanvasContextRef = useRef<OffscreenCanvasRenderingContext2D | null>(null);
  const maxRMS = useMemo(() => maxBy("rms", originalAudioSamples)?.rms ?? 0, [originalAudioSamples]);

  useEffect(() => {
    // clean cache
    cache = {};
  }, [originalAudioSamples]);

  useEffect(
    () => {
      if (!canvasRef.current || !width || !originalAudioSamples) return;

      if (!canvasContextRef.current || !offscreenCanvasRef.current || !offscreenCanvasContextRef.current) {
        canvasContextRef.current = canvasRef.current.getContext("2d")!;
        offscreenCanvasRef.current = new OffscreenCanvas(width, height);
        offscreenCanvasContextRef.current = offscreenCanvasRef.current.getContext("2d")!;
      }

      offscreenCanvasRef.current.width = width;

      if (!cache[pxPerSec]) {
        const visibleSampleCount = timeIntervalUtils.duration(zoomedTimeInterval) * sampleRate;
        const maxVisibleSampleCount = Math.ceil(width / audioSampleBarWidth);
        const audioSampleGroupSize = Math.max(1, Math.floor(visibleSampleCount / maxVisibleSampleCount));

        if (audioSampleGroupSize === 1) {
          cache[pxPerSec] = {
            audioSamples: originalAudioSamples,
            audioSampleGroupSize,
          };
        } else {
          cache[pxPerSec] = {
            audioSamples: [],
            audioSampleGroupSize,
          };

          for (let i = 0; i < originalAudioSamples.length - (audioSampleGroupSize - 1); i += audioSampleGroupSize) {
            const audioSampleGroup = originalAudioSamples.slice(i, i + audioSampleGroupSize);
            const rms = maxBy("rms", audioSampleGroup)?.rms ?? 0;
            const { timestamp } = audioSampleGroup[0];
            const sample: AudioSample = { timestamp, rms };

            cache[pxPerSec].audioSamples.push(sample);
          }
        }
      }

      const { audioSamples, audioSampleGroupSize } = cache[pxPerSec];

      offscreenCanvasContextRef.current.fillStyle = audioSampleBarColor;
      offscreenCanvasContextRef.current.beginPath();

      const sampleDurationInSeconds = audioSampleGroupSize / sampleRate;
      const startIndex = Math.floor(zoomedTimeInterval.start / sampleDurationInSeconds);
      const endIndex = Math.floor(zoomedTimeInterval.end / sampleDurationInSeconds);
      const offsetX = -zoomedTimeInterval.start * pxPerSec;

      // eslint-disable-next-line no-plusplus
      for (let sampleIndex = startIndex; sampleIndex < endIndex; sampleIndex++) {
        const sample = audioSamples[sampleIndex];

        // TODO: check this
        // eslint-disable-next-line no-continue
        if (!sample) continue;

        const sampleTimeInSeconds = sample.timestamp / 1000;
        const barHeight = (height - 6 * 2) * (maxRMS / sample.rms);
        const barX = offsetX + pxPerSec * sampleTimeInSeconds;
        const barY = (height - barHeight) / 2;

        offscreenCanvasContextRef.current.roundRect(barX, barY, audioSampleBarWidth, barHeight, 10);
      }

      offscreenCanvasContextRef.current.closePath();
      offscreenCanvasContextRef.current.fill();

      canvasContextRef.current.clearRect(0, 0, width, height);
      canvasContextRef.current.drawImage(offscreenCanvasRef.current, 0, 0);
      offscreenCanvasContextRef.current.clearRect(0, 0, width, height);
    },
    // prettier-ignore
    [audioSampleBarColor, audioSampleBarWidth, height, maxRMS, originalAudioSamples, sampleRate, pxPerSec, width, zoomedTimeInterval],
  );

  return <canvas ref={canvasRef} width={width} height={height} />;
}

const SoundWave = memo(SoundWaveBase);

export default SoundWave;
