/* eslint-disable default-case */

import { useRef } from "react";

import useMousetrap from "src/hooks/useMousetrap";

import { useTimelineZoom } from "src/components/features/VideoTrimmer/providers/TimelineZoomProvider/TimelineZoomContext";
import { ShortcutsKeys } from "src/constants/shortcuts.constants";

export default function TimeLineZoomShortcuts() {
  const zoomFactorRef = useRef(1);

  const {
    zoomFactor,
    zoomBy,
    zoomReset,
    shiftZoomedTimeInterval,
  } = useTimelineZoom(); // prettier-ignore

  useMousetrap([ShortcutsKeys.zoomReset], zoomReset, { preventDefault: true });

  useMousetrap(
    [ShortcutsKeys.zoomIn, ShortcutsKeys.zoomIn2, ShortcutsKeys.zoomOut] as const,
    ({ repeat }, combo) => {
      switch (combo) {
        case ShortcutsKeys.zoomIn:
        case ShortcutsKeys.zoomIn2:
          zoomFactorRef.current = repeat ? zoomFactorRef.current * 1.02 : 1.1;
          break;
        case ShortcutsKeys.zoomOut:
          zoomFactorRef.current = repeat ? zoomFactorRef.current / 1.02 : 0.9;
          break;
      }

      zoomBy(zoomFactorRef.current);
    },
    { preventDefault: true, allowRepeat: true },
  );

  useMousetrap(
    ["left", "right"] as const,
    (e, combo) => {
      const step = 10;
      const direction = combo === "left" ? -1 : 1;
      const slowDownFactor = zoomFactor * 4;

      shiftZoomedTimeInterval((step * direction) / slowDownFactor);
    },
    { preventDefault: true, allowRepeat: true },
  );

  return null;
}
