import { memo, useCallback, useRef, useState } from "react";
import styled, { css } from "styled-components/macro";

import { TimeInterval, ChapterTimeInterval, TimeIntervalEdge } from "src/types/video-trimmer.types";

import { ifProp, themeColor } from "src/utils/styledComponents.utils";
import { timeIntervalUtils } from "src/utils/timeInterval.utils";

import useVideoTimeInterval from "src/components/features/VideoTrimmer/common/hooks/useVideoTimeInterval";
import TimePointPopup from "src/components/features/VideoTrimmer/TimelineEditor/TimePointPopup";

const MIN_TANGENT_CHAPTERS_EVENT_COUNT_FOR_MERGE = 15;
const RESIZE_THUMB_GRAB_AREA_OUTER_PADDING = 5;
const RESIZE_THUMB_GRAB_AREA_INNER_PADDING = 7;
const RESIZE_THUMB_WIDTH = 2;
const RESIZE_THUMB_GRAB_AREA_WIDTH = RESIZE_THUMB_GRAB_AREA_OUTER_PADDING + RESIZE_THUMB_GRAB_AREA_INNER_PADDING + RESIZE_THUMB_WIDTH; // prettier-ignore

export const ResizeThumbGrabArea = styled.div`
  z-index: 1;
  display: flex;
  padding-inline: ${RESIZE_THUMB_GRAB_AREA_OUTER_PADDING}px;
  cursor: col-resize;

  &:first-of-type {
    padding-right: ${RESIZE_THUMB_GRAB_AREA_INNER_PADDING}px;
  }

  &:last-of-type {
    padding-left: ${RESIZE_THUMB_GRAB_AREA_INNER_PADDING}px;
  }
`;

export const ResizeThumb = styled.div`
  width: ${RESIZE_THUMB_WIDTH}px;
  border-radius: 10px;
  margin-block: 10px;
  background-color: ${themeColor("white")};
`;

interface ContainerProps {
  x1: number;
  x2: number;
  isActive: boolean;
  isInteracting: boolean;
  backgroundColor: any;
  activeBackgroundColor: any;
  hideThumbs: boolean;
  displayBoxShadow: boolean;
}

const Container = styled.div.attrs<ContainerProps>(({ x1, x2 }) => ({
  style: {
    transform: `translateX(${x1}px)`,
    width: `${x2 - x1}px`,
  },
}))<ContainerProps>`
  display: flex;
  box-shadow: ${ifProp("displayBoxShadow", "0 2px 4px rgba(0, 0, 0, 0.25)", "none")};
  justify-content: space-between;
  position: absolute;
  height: 40px;
  bottom: 0;
  left: 0;
  border-radius: 10px;
  background-color: ${(props) => ifProp("isActive", props.activeBackgroundColor, props.backgroundColor)};
  cursor: pointer;
  transition: background-color 250ms ease-out;

  ${ResizeThumbGrabArea} {
    opacity: 0;
    transition: opacity 150ms ease-out;
  }

  &:hover {
    ${ResizeThumbGrabArea} {
      opacity: 1;
      visibility: ${ifProp("hideThumbs", "hidden", "visible")};
    }
  }

  ${ifProp(
    "isInteracting",
    css`
      cursor: inherit;

      ${ResizeThumbGrabArea} {
        opacity: 1;
      }
    `,
  )}
  ${ifProp(
    "hideThumbs",
    css`
      cursor: inherit;

      ${ResizeThumbGrabArea} {
        visibility: hidden;
      }
    `,
  )}
`;

const HiddenResizeThumbsPopup = styled.div`
  background: ${themeColor("white")};
  padding: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
`;

interface ChapterViewProps {
  index: number;
  value: ChapterTimeInterval;
  isActive: boolean;
  pixelPerSecond: number;
  zoomedTimeInterval: TimeInterval;
  popupTarget: HTMLElement | null;
  chapters: ChapterTimeInterval[];
  mergeChapters(index: number, interactedSiblingChapterIndex: number): void;
  onResize?(index: number, edge: TimeIntervalEdge, timeDelta: number): void;
  onResizeStart?(index: number, edge: TimeIntervalEdge): void;
  onResizeEnd?(index: number, edge: TimeIntervalEdge): void;
  backgroundColor?: any;
  activeBackgroundColor?: any;
  hideThumbs?: boolean;
  displayBoxShadow?: boolean;
}

function ChapterViewBase({
  index,
  value,
  isActive = false,
  pixelPerSecond,
  zoomedTimeInterval,
  popupTarget,
  chapters,
  mergeChapters,
  onResize: outerOnResize,
  onResizeStart: outerOnResizeStart,
  onResizeEnd: outerOnResizeEnd,
  backgroundColor = themeColor("blue.500", 0.2),
  activeBackgroundColor = themeColor("blue.500", 0.5),
  hideThumbs = false,
  displayBoxShadow = false,
}: ChapterViewProps) {
  const [isInteracting, setIsInteracting] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const tangentChaptersEventCounterRef = useRef(0);

  const onResizeStart = useCallback(
    (edge: TimeIntervalEdge) => {
      setIsInteracting(true);
      outerOnResizeStart?.(index, edge);
    },
    [index, outerOnResizeStart],
  );

  const onResize = useCallback(
    (edge: TimeIntervalEdge, timeDelta: number, haltResizing: () => void) => {
      const interactedSiblingChapterIndex = edge === "start" ? index - 1 : index + 1;
      const interactedSiblingChapter = chapters[interactedSiblingChapterIndex];
      const interactedEdge = edge === "start" ? "end" : "start";
      const isInteractedChapterTangent = interactedSiblingChapter?.[interactedEdge] === value[edge];

      tangentChaptersEventCounterRef.current = isInteractedChapterTangent
        ? tangentChaptersEventCounterRef.current + 1
        : 0;

      if (tangentChaptersEventCounterRef.current >= MIN_TANGENT_CHAPTERS_EVENT_COUNT_FOR_MERGE) {
        mergeChapters(index, interactedSiblingChapterIndex);
        haltResizing();
        return;
      }

      outerOnResize?.(index, edge, timeDelta);
    },
    [chapters, index, mergeChapters, outerOnResize, value],
  );

  const onResizeEnd = useCallback(
    (edge: TimeIntervalEdge) => {
      setIsInteracting(false);
      outerOnResizeEnd?.(index, edge);
      tangentChaptersEventCounterRef.current = 0;
    },
    [index, outerOnResizeEnd],
  );

  const { x1, x2, leftResizeTriggerProps, rightResizeTriggerProps } = useVideoTimeInterval(value, {
    pixelPerSecond,
    onResize,
    onResizeStart,
    onResizeEnd,
  });

  const innerWidth = x2 - x1;
  const areResizeThumbsVisible = innerWidth >= RESIZE_THUMB_GRAB_AREA_WIDTH * 2;

  return (
    <Container
      x1={x1}
      x2={x2}
      isActive={isActive}
      isInteracting={isInteracting}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
      backgroundColor={backgroundColor}
      activeBackgroundColor={activeBackgroundColor}
      hideThumbs={hideThumbs}
      displayBoxShadow={displayBoxShadow}
    >
      {areResizeThumbsVisible ? (
        <>
          <ResizeThumbGrabArea {...leftResizeTriggerProps}>
            <ResizeThumb />
          </ResizeThumbGrabArea>

          <ResizeThumbGrabArea {...rightResizeTriggerProps}>
            <ResizeThumb />
          </ResizeThumbGrabArea>
        </>
      ) : (
        isMouseOver && (
          <TimePointPopup
            relativeTimePositionPx={(timeIntervalUtils.middle(value) - zoomedTimeInterval.start) * pixelPerSecond}
            popupTarget={popupTarget}
          >
            <HiddenResizeThumbsPopup>double click to resize</HiddenResizeThumbsPopup>
          </TimePointPopup>
        )
      )}
    </Container>
  );
}

const ChapterView = memo(ChapterViewBase);

export default ChapterView;
