import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { RootState, Dispatch } from "src/models/store";
import { assetSelectors } from "src/models/Asset.model";
import { useUploaderContext } from "src/components/providers/UploaderProvider";
import { useChunkedUploader } from "src/hooks/useChunkedUploader";
import { ItemBluredPlug, ItemContent, ItemPlug, WebItemFooter } from "src/components/common/refactor/Webinars.styled";
import WebItemProgressPlug from "src/components/common/refactor/WebItemProgressPlug";
import WebItemFooterComponent from "src/components/common/refactor/WebItemFooterComponent";
import ResponsivePeechItem from "src/components/common/ResponsiveLibrary/ResponsivePeechItem";
import { FlexBox } from "src/components/common/layout/Box.styled";
import { ifProp } from "styled-tools";
import Select from "src/components/common/form/inputs/Select.styled";
import { LanguageOption, useAppConfig } from "src/components/providers/AppConfigProvider";

interface UploadItemPocProps {
  id: string;
}

// TODO: move?
export const StyledItemPlug = styled(ItemPlug)`
  flex-direction: column;
`;

// TODO: move?
export const LanguageSelectWrapper = styled(FlexBox)`
  width: 100%;
  padding: 0 14px;
  align-items: center;
  margin-bottom: 10px;
  font-weight: 400;
`;

// TODO: move?
export const Label = styled.span`
  margin-right: 8px;
`;

const StyledSelect = styled(Select<LanguageOption>)<{ isLoading: boolean }>`
  flex: 1;
  .select__control {
    opacity: ${ifProp("isLoading", 1, 0.65)};
    transition: opacity 250ms ease-out;
    .select__value-container {
      padding: 2px 8px;
    }
  }

  .select__control--menu-is-open {
    opacity: 1;
  }
`;

export const DEFAULT_ASPECT_RATIO = "16:9";

export default function UploadItemMobile({ id }: UploadItemPocProps) {
  const dispatch = useDispatch<Dispatch>();
  const { SUPPORTED_LANGUAGES } = useAppConfig();
  const { getUploader } = useUploaderContext();
  const asset = useSelector((state: RootState) => assetSelectors.selectById(state, id));
  const { uploadStatus, progress, uploadThumbnail, setUploader } = useChunkedUploader();
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [updatingAsset, setUpdatingAsset] = useState(false);

  useEffect(() => {
    const uploader = getUploader(id);
    if (uploader) {
      setUploader(uploader);
    }
  }, [id, getUploader, setUploader]);

  const thumbnailUrl = useMemo(() => {
    if (uploadThumbnail) {
      return uploadThumbnail;
    }
    return null;
  }, [uploadThumbnail]);

  const onChangeLanguage = useCallback(
    async (languageCode: string) => {
      if (asset?.sid) {
        setSelectedLanguage(languageCode);
        setUpdatingAsset(true);
        try {
          await dispatch.assets.updateAsset({ sid: asset.sid, languageCode });
          setUpdatingAsset(false);
        } catch (error) {
          setUpdatingAsset(false);
        }
      }
    },
    [dispatch.assets, asset?.sid],
  );

  useEffect(() => {
    if (asset) {
      setSelectedLanguage(asset.languageCode!);
    }
  }, [asset]);

  return (
    <ResponsivePeechItem>
      <ItemContent aspectRatio={DEFAULT_ASPECT_RATIO} shouldGrayscale={false}>
        {thumbnailUrl && <img src={thumbnailUrl as string} alt={asset?.name || ""} />}
        <ItemBluredPlug aspectRatio={DEFAULT_ASPECT_RATIO} />
        <StyledItemPlug aspectRatio={DEFAULT_ASPECT_RATIO}>
          <LanguageSelectWrapper>
            <Label>Video’s language</Label>
            <StyledSelect
              isSearchable={false}
              value={SUPPORTED_LANGUAGES.find((lang) => lang.languageCode === selectedLanguage)}
              options={SUPPORTED_LANGUAGES.filter((lang) => lang.status === "ACTIVE")}
              getOptionLabel={(x) => x.label!}
              getOptionValue={(x) => x.languageCode!}
              onChange={(item) => onChangeLanguage(item?.languageCode!)}
              isDisabled={updatingAsset}
              isLoading={updatingAsset}
            />
          </LanguageSelectWrapper>

          <WebItemProgressPlug status={uploadStatus} progress={progress} />
        </StyledItemPlug>
      </ItemContent>
      <WebItemFooter numRows={1}>
        <WebItemFooterComponent
          renamingInProgress={false}
          name={asset?.name || ""}
          onNameChange={() => {
            // noting for now
          }}
        />
      </WebItemFooter>
    </ResponsivePeechItem>
  );
}
