/* eslint-disable no-confusing-arrow */
/* eslint-disable no-nested-ternary */
import { findIndex, isEqual } from "lodash/fp";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { presetSelectors } from "src/models/Preset.model";
import { Dispatch, RootState } from "src/models/store";
import { DeleteOneFootageInput, EditRules, FootageOrigin } from "src/network/graphql/generatedGraphqlSDK";
import socketClient from "src/network/SocketClient";

import { absoluteRoutes, applyPathParams } from "src/utils/routes.utils";
import { themeColor, themeZ } from "src/utils/styledComponents.utils";

import { Swiper as SwiperClass } from "swiper/types";

import styled from "styled-components/macro";

import * as createSequenceAnalytics from "src/analytics/creationWizard.analytics";
import Select from "src/components/common/form/inputs/Select.styled";
import Icon from "src/components/common/Icon";
import { CircularLoader } from "src/components/common/loaders/CircularLoader";
import Swiper from "src/components/common/Swiper/Swiper";
import SwiperNextSlideButton from "src/components/common/Swiper/SwiperNextSlideButton";
import { MountIfActive } from "src/components/common/Swiper/SwiperSlide";
import { STEPS, SUBSTEPS } from "src/components/features/AutomaticCreateWizard/automaticCreate.constants";
import { areColorsIdentical } from "src/components/features/AutomaticCreateWizard/automaticCreate.utills";
import { useAutomaticCreateForm } from "src/components/features/AutomaticCreateWizard/AutomaticCreateForm";
import {
  BackButtonWrapper,
  CancelButtonWrapper,
  CentralButtonsWrapper,
  Footer,
  NextButtonWrapper,
} from "src/components/features/AutomaticCreateWizard/common/automatic-create-wizard-layout.styled";
import CancelButton from "src/components/features/AutomaticCreateWizard/common/CancelButton";
import steps, { StepPath } from "src/components/features/AutomaticCreateWizard/steps/steps";
import SwiperPrevSlideButton from "src/components/features/AutomaticCreateWizard/swiper-components/SwiperPrevSlideButton";
import { SwiperSlide } from "src/components/features/AutomaticCreateWizard/swiper-components/SwiperSlide.styled";
import { useFeatureFlag } from "src/components/providers/FeatureFlagsProvider";
import { useUploaderContext } from "src/components/providers/UploaderProvider";
import useBeforeUnloadBlocker from "src/hooks/useBeforeUnloadBlocker";
import useEditRules from "src/hooks/useEditRules";
import { userSelectors } from "src/models/User.model";
import {
  FootageUpdateEventDataType,
  onFootageUpdateEvent,
} from "src/components/features/SmartLibrary/smartLibrary.utils";

import UploadToGcsService from "src/services/UploadToGcs.service";
import * as boardsAnalytics from "src/analytics/boards.analytics";
import { boardSelectors } from "src/models/Board.model";
import { useBrandkitFormSetters } from "src/hooks/useBrandkitFormSetters";

const Container = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const StyledSwiper = styled(Swiper)`
  z-index: ${themeZ("onboardingSwiper")};
  width: 100%;
  height: 100%;
  min-width: 320px;
`;

export const StyledNextSlideButton = styled(SwiperNextSlideButton)`
  width: 119px;
  height: 45px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: ${themeColor("white")};
`;

interface AutomaticCreateWizardProps {
  boardId: string | null;
}

export default function AutomaticCreateWizard({ boardId = null }: AutomaticCreateWizardProps) {
  const currentUser = useSelector(userSelectors.selectCurrentUser);
  const navigate = useNavigate();
  const pathParams = useParams<{ step: StepPath; subStep: string }>();
  const dispatch = useDispatch<Dispatch>();
  const [successData, setSuccessData] = useState<any>(null);
  const automaticCreateForm = useAutomaticCreateForm();
  const { formState, getValues: getFormValues, trigger: validateForm, setValue, watch } = automaticCreateForm;
  const activeStepErrors = pathParams.step && formState.errors[pathParams.step as keyof typeof formState.errors];
  const activeStepHasErrors = !!activeStepErrors && Object.values(activeStepErrors).length > 0;
  const activeStepIndex = useMemo(() => findIndex({ path: pathParams.step }, steps), [pathParams.step]);
  const isLastStep = activeStepIndex === steps.length - 1;
  const targetBoard = useSelector((state: RootState) => boardSelectors.selectById(state, boardId ?? ""));

  const firstExperienceUser = useSelector((state: RootState) => presetSelectors.isUserFirstExperience(state));
  const examplePreset = useSelector((state: RootState) => presetSelectors.getExamplePreset(state));

  const defaultPreset = useSelector((state: RootState) => presetSelectors.getDefaultPreset(state));
  const usersCurrentPresetSid = watch("uploading.usersCurrentPresetSid") ?? "";
  const usersCurrentPreset = useSelector((state: RootState) => presetSelectors.selectById(state, usersCurrentPresetSid ?? "")); // prettier-ignore

  const isSaveChangesLoading = useSelector(({ loading: { models } }: RootState) => models.presets.loading || models.footage.loading); // prettier-ignore
  const isCreatingBrandKit = useSelector((state: RootState) => state.loading.effects.presets.createPreset.loading);

  const { originalValuesEditRules, setEditRules } = useEditRules({
    currentPreset: usersCurrentPreset,
  });

  const allowChangeEditRules = useFeatureFlag("allowChangeEditRules");
  const allowChangeEditRulesFirstTimeUser = useFeatureFlag("allowChangeEditRulesFirstTimeUser");

  const shouldShowEditRules = useMemo(
    () => (firstExperienceUser ? allowChangeEditRulesFirstTimeUser : allowChangeEditRules),
    [allowChangeEditRules, allowChangeEditRulesFirstTimeUser, firstExperienceUser],
  );

  const { getUploader, deleteUploader } = useUploaderContext();

  const nextButtonText = useMemo(() => (pathParams.subStep ? "Apply" : activeStepIndex === steps.length - 2 ? "Finish" : "Next"), [activeStepIndex, pathParams]); // prettier-ignore

  const { setTemporaryPresetData, updateCurrentPreset, setInitialSORs } = useBrandkitFormSetters(
    automaticCreateForm,
    usersCurrentPresetSid,
  );

  const saveNewPreset = useCallback(async () => {
    if (isCreatingBrandKit) {
      return;
    }

    const formSteps = getFormValues();
    const editRules = setEditRules({
      fieldsToPick: ["name", "volume", "noContentVolume"],
      currentEditedData: watch("brandKit.SORs.editRules") as EditRules,
      fullData: originalValuesEditRules as EditRules,
    });
    const presetId = await dispatch.presets.createPreset({
      name: currentUser?.orgName || "First Brand Kit",
      assets: [{ assetSid: formSteps.brandKit.logo?.assetId, isDefault: true }],
      colors: formSteps.brandKit.colors,
      defaultVisualPackage: formSteps.brandKit.visuals?.visualPackageId,
      headerFontFamily: "Roboto",
      headerRegularVariant: "400",
      headerBoldVariant: "700",
    });

    // update preset with edit rules and default video type
    await dispatch.presets.updatePreset({
      sid: presetId!,
      values: {
        defaultVideoType: watch("brandKit.SORs.defaultVideoType"),
        editRules,
      },
    });

    // eslint-disable-next-line consistent-return
    return presetId;
  }, [
    isCreatingBrandKit,
    getFormValues,
    dispatch.presets,
    currentUser?.orgName,
    watch,
    setEditRules,
    originalValuesEditRules,
  ]);

  const updateDefaultPreset = useCallback(
    async (presetId: string) => {
      await dispatch.presets.setDefaultPreset({ sid: presetId! });
      if (examplePreset) {
        dispatch.presets.deletePreset({ sid: examplePreset?.sid! });
      }
    },
    [dispatch.presets, examplePreset],
  );

  const updateFootagesWithPreset = useCallback(
    async (presetSid: string) =>
      // eslint-disable-next-line no-async-promise-executor
      new Promise<void>(async (resolve, reject) => {
        try {
          const hasFootage = watch("uploading.footagesArray")?.some((x) => x.footageId);
          if (!hasFootage) {
            resolve();
            return;
          }

          const editRules = setEditRules({
            fieldsToPick: ["name"],
            currentEditedData: watch("brandKit.SORs.editRules") as EditRules,
            fullData: originalValuesEditRules as EditRules,
          });

          for (const footage of watch("uploading.footagesArray") || []) {
            const { footageId } = footage;
            try {
              await dispatch.footage.updateFootage({
                id: footageId,
                update: {
                  languageCode: footage.languageCode,
                  automationData: {
                    externalPresetSid: presetSid,
                    editRules,
                  },
                },
              });
              const uploader = getUploader(footageId) as UploadToGcsService;
              if (uploader) {
                uploader.waitForLastChunk = false;
              }
            } catch (error) {
              reject(error);
              return;
            }
          }

          resolve();
        } catch (error) {
          reject(error);
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch.footage, originalValuesEditRules, watch("brandKit.SORs.editRules")],
  );

  const onActiveStepIndexChange = useCallback(
    ({ activeIndex }: SwiperClass) => {
      if (pathParams.step !== steps[activeIndex].path) {
        navigate(
          `${applyPathParams(absoluteRoutes.automaticCreate.children.step.self, {
            step: steps[activeIndex].path,
          })}?boardId=${boardId}`,
        );
      }

      if (activeIndex === steps.length - 1) {
        // last step - finish
        createSequenceAnalytics.trackAutomationWizardSuccess(successData);
      }
    },
    [boardId, navigate, pathParams.step, successData],
  );

  const goToBrandPage = useCallback(() => {
    navigate(
      `${applyPathParams(absoluteRoutes.automaticCreate.children.step.self, {
        step: steps[1].path,
      })}?boardId=${boardId}`,
    );
  }, [boardId, navigate]);

  const goToBrandSubstep = useCallback(
    (substep: string) => {
      navigate(
        `${applyPathParams(absoluteRoutes.automaticCreate.children.step.children.subStep, {
          step: steps[1].path,
          subStep: substep,
        })}?boardId=${boardId}`,
      );
    },
    [boardId, navigate],
  );

  const onPrevStep = useCallback(
    (back: Function) => {
      createSequenceAnalytics.trackAutomationWizardStep(pathParams.step as STEPS, firstExperienceUser, "reject");
      back();
    },
    [firstExperienceUser, pathParams.step],
  );

  const getSuccessData = useCallback(
    (brandKitCreated: boolean) => {
      const brandLogoChanged = watch("brandKit.logo.assetId") !== usersCurrentPreset?.assets?.find((asset) => asset?.isDefault)?.assetSid; // prettier-ignore
      const brandColorChanged = !areColorsIdentical(watch("brandKit.colors"), usersCurrentPreset?.colors);
      const brandVisualPackageChanged = watch("brandKit.visuals.visualPackageId") !== usersCurrentPreset?.defaultVisualPackage; // prettier-ignore
      const editRulesChanged = !isEqual(usersCurrentPreset?.editRules, watch("brandKit.SORs.editRules"));
      const footagesArray = watch("uploading.footagesArray");

      setSuccessData({
        firstExperienceUser,
        brandKitCreated,
        brandLogoChanged,
        brandColorChanged,
        brandVisualPackageChanged,
        editRulesChanged,
        footageCount: footagesArray?.length,
        footageOriginYouTube: footagesArray?.filter((x) => x.origin === FootageOrigin.Youtube).length,
        footageOriginUserUpload: footagesArray?.filter((x) => x.origin === FootageOrigin.UserUpload).length,
      });
    },
    [usersCurrentPreset, watch, firstExperienceUser],
  );

  const onNextStep = useCallback(
    async (next: Function) => {
      createSequenceAnalytics.trackAutomationWizardStep(pathParams.step as STEPS, firstExperienceUser, "apply");
      if (pathParams.step === STEPS.UPLOADING) {
        const firstFootage = watch("uploading.footagesArray")?.[0];
        if (firstFootage && !firstFootage.thumbnailUrl) {
          const firstUploader = getUploader(firstFootage?.footageId || "");
          if (firstUploader) {
            firstUploader?.getThumbnail({ width: 400, height: 225, time: 3 }).then((thumbnail: string | null) => {
              const updatedFootage = {
                ...firstFootage,
                thumbnailUrl: thumbnail,
              };

              const filteredArr = watch("uploading.footagesArray")?.filter(
                (x) => x.footageId !== firstFootage.footageId,
              );
              const updatedArray = [updatedFootage, ...(filteredArr ?? [])];

              setValue("uploading.footagesArray", updatedArray, { shouldValidate: true });
            });
          }
        }
      }

      if (pathParams.step === STEPS.BRAND_KIT) {
        try {
          await getSuccessData(false);
          const footageIds = watch("uploading.footagesArray")?.map((x) => x?.footageId);
          if (boardId && boardId !== "null" && footageIds?.length) {
            footageIds.forEach((id) => {
              dispatch.boards.moveFootageToBoard({ footageId: id, boardId });
              boardsAnalytics.trackMoveFootageToBoard(null, targetBoard, id, "automatic-create");
            });
          }
        } catch (error) {
          // eslint-disable-next-line
          console.log(error);
        }

        await updateCurrentPreset();
        await updateFootagesWithPreset(usersCurrentPresetSid);
      }

      next();
    },
    [
      pathParams.step,
      firstExperienceUser,
      watch,
      getUploader,
      setValue,
      updateCurrentPreset,
      updateFootagesWithPreset,
      usersCurrentPresetSid,
      getSuccessData,
      boardId,
      dispatch.boards,
      targetBoard,
    ],
  );

  const saveFirstData = useCallback(async () => {
    try {
      await getSuccessData(true);
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    }
    const presetId = await saveNewPreset();
    presetId && (await updateDefaultPreset(presetId!));
    presetId && (await updateFootagesWithPreset(presetId));
    setValue("uploading.usersCurrentPresetSid", presetId);

    navigate(
      `${applyPathParams(absoluteRoutes.automaticCreate.children.step.self, {
        step: steps[steps.length - 1].path,
      })}?boardId=${boardId}`,
    );
  }, [boardId, getSuccessData, navigate, saveNewPreset, setValue, updateDefaultPreset, updateFootagesWithPreset]);

  const substepFunctions = useMemo(
    () => ({
      [SUBSTEPS.LOGO]: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        apply: (next: Function | undefined) => {
          createSequenceAnalytics.trackAutomationWizardStep(SUBSTEPS.LOGO, firstExperienceUser, "apply");
          setValue("brandKit.temporaryPresetValues.logo.assetId", watch("brandKit.logo.assetId"));
          if (firstExperienceUser) {
            goToBrandSubstep(SUBSTEPS.COLORS);
          } else {
            goToBrandPage();
          }
        },
        reject: (back: Function | undefined) => {
          createSequenceAnalytics.trackAutomationWizardStep(SUBSTEPS.LOGO, firstExperienceUser, "reject");
          setValue("brandKit.logo.assetId", watch("brandKit.temporaryPresetValues.logo.assetId") ?? "", {
            shouldValidate: true,
          });
          if (firstExperienceUser) {
            back && back();
          } else {
            goToBrandPage();
          }
        },
        allowSlideNext: () => {
          const currentLogoAssetId = watch("brandKit.logo.assetId");
          const prevLogoAssetId = watch("brandKit.temporaryPresetValues.logo.assetId");

          if (firstExperienceUser) {
            return !!currentLogoAssetId;
          }
          return currentLogoAssetId && currentLogoAssetId !== prevLogoAssetId;
        },
      },
      [SUBSTEPS.COLORS]: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        apply: (next: Function | undefined) => {
          createSequenceAnalytics.trackAutomationWizardStep(SUBSTEPS.COLORS, firstExperienceUser, "apply");
          setValue("brandKit.temporaryPresetValues.colors", watch("brandKit.colors"));
          if (firstExperienceUser) {
            goToBrandSubstep(SUBSTEPS.VISUALS);
          } else {
            goToBrandPage();
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        reject: (back: Function | undefined) => {
          createSequenceAnalytics.trackAutomationWizardStep(SUBSTEPS.COLORS, firstExperienceUser, "reject");
          // @ts-ignore
          setValue("brandKit.colors", watch("brandKit.temporaryPresetValues.colors"), { shouldValidate: true });
          if (firstExperienceUser) {
            navigate(
              `${applyPathParams(absoluteRoutes.automaticCreate.children.step.children.subStep, {
                step: steps[1].path,
                subStep: SUBSTEPS.LOGO,
              })}?boardId=${boardId}`,
            );
          } else {
            goToBrandPage();
          }
        },
        allowSlideNext: () => {
          if (firstExperienceUser) {
            return !!watch("brandKit.colors")?.length;
          }
          return !areColorsIdentical(watch("brandKit.colors"), watch("brandKit.temporaryPresetValues.colors"));
        },
      },
      [SUBSTEPS.VISUALS]: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        apply: async (next: Function | undefined) => {
          createSequenceAnalytics.trackAutomationWizardStep(SUBSTEPS.VISUALS, firstExperienceUser, "apply");
          setValue("brandKit.temporaryPresetValues.visuals.visualPackageId", watch("brandKit.visuals.visualPackageId"));
          if (firstExperienceUser) {
            if (shouldShowEditRules) {
              goToBrandSubstep(SUBSTEPS.SORS);
            } else {
              saveFirstData();
            }
          } else {
            goToBrandPage();
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        reject: (back: Function | undefined) => {
          createSequenceAnalytics.trackAutomationWizardStep(SUBSTEPS.VISUALS, firstExperienceUser, "reject");
          setValue(
            "brandKit.visuals.visualPackageId",
            watch("brandKit.temporaryPresetValues.visuals.visualPackageId") ?? "",
            { shouldValidate: true },
          );
          if (firstExperienceUser) {
            navigate(
              `${applyPathParams(absoluteRoutes.automaticCreate.children.step.children.subStep, {
                step: steps[1].path,
                subStep: SUBSTEPS.COLORS,
              })}?boardId=${boardId}`,
            );
          } else {
            goToBrandPage();
          }
        },
        allowSlideNext: () => {
          if (firstExperienceUser) {
            return !!watch("brandKit.visuals.visualPackageId");
          }
          return (
            watch("brandKit.temporaryPresetValues.visuals.visualPackageId") !==
            watch("brandKit.visuals.visualPackageId")
          );
        },
      },
      [SUBSTEPS.SORS]: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        apply: async (next: Function | undefined) => {
          createSequenceAnalytics.trackAutomationWizardStep(SUBSTEPS.SORS, firstExperienceUser, "apply");
          setValue("brandKit.temporaryPresetValues.SORs.editRules", watch("brandKit.SORs.editRules"));
          if (firstExperienceUser) {
            saveFirstData();
          } else {
            goToBrandPage();
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        reject: (back: Function | undefined) => {
          createSequenceAnalytics.trackAutomationWizardStep(SUBSTEPS.SORS, firstExperienceUser, "reject");
          setValue("brandKit.SORs.editRules", watch("brandKit.temporaryPresetValues.SORs.editRules") ?? "", {
            shouldValidate: true,
          });
          if (firstExperienceUser) {
            navigate(
              `${applyPathParams(absoluteRoutes.automaticCreate.children.step.children.subStep, {
                step: steps[1].path,
                subStep: SUBSTEPS.VISUALS,
              })}?boardId=${boardId}`,
            );
          } else {
            goToBrandPage();
          }
        },
        allowSlideNext: () => {
          if (firstExperienceUser) {
            return !!watch("brandKit.temporaryPresetValues.SORs.editRules");
          }
          return !isEqual(watch("brandKit.temporaryPresetValues.SORs.editRules"), watch("brandKit.SORs.editRules"));
        },
      },
    }),
    [
      firstExperienceUser,
      setValue,
      watch,
      goToBrandSubstep,
      goToBrandPage,
      navigate,
      boardId,
      shouldShowEditRules,
      saveFirstData,
    ],
  );

  useEffect(() => {
    if (pathParams.step) {
      validateForm();
    }
  }, [pathParams.step, validateForm]);

  useEffect(() => {
    if (!pathParams.step || !watch("uploading.footagesArray")?.length) {
      navigate(
        `${applyPathParams(absoluteRoutes.automaticCreate.children.step.self, {
          step: steps[0].path,
        })}?boardId=${boardId}`,
      );
    }
  }, [boardId, navigate, pathParams.step, pathParams.subStep, watch]);

  useEffect(() => {
    const temporaryPresetValues = watch("brandKit.temporaryPresetValues");
    if (firstExperienceUser) {
      setValue("uploading.usersCurrentPresetSid", null, { shouldValidate: true });
      setValue("brandKit.temporaryPresetValues", null);
    } else {
      defaultPreset && setValue("uploading.usersCurrentPresetSid", defaultPreset.sid, { shouldValidate: true });
      defaultPreset && !temporaryPresetValues && setTemporaryPresetData();
    }
    setValue("finish.completed", false, { shouldValidate: true });
  }, [defaultPreset, firstExperienceUser, setTemporaryPresetData, setValue, watch]);

  useEffect(() => {
    setValue("brandKit.SORs.editRulesSid", "", { shouldValidate: true });
    setValue("brandKit.temporaryPresetValues.SORs.editRulesSid", "", { shouldValidate: true });
    setInitialSORs();
  }, [setInitialSORs, setValue, usersCurrentPresetSid]);

  useEffect(() => {
    const onFootageUpdate = (event: FootageUpdateEventDataType) => {
      onFootageUpdateEvent(dispatch, event);
    };

    socketClient.on("footages", onFootageUpdate);

    return () => {
      socketClient.off("footages", onFootageUpdate);
    };
  }, [dispatch]);

  // mount
  useEffect(() => {
    if (pathParams.step) {
      createSequenceAnalytics.trackStartAutomationWizard(firstExperienceUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteAllCreatedFootages = useCallback(() => {
    const footagesIds = watch("uploading.footagesArray")?.map((x) => x?.footageId);

    if (footagesIds?.length) {
      footagesIds.forEach((id: string) => {
        dispatch.footage.deleteFootage({ id } as DeleteOneFootageInput);
        deleteUploader(id);
      });
    }
  }, [deleteUploader, dispatch.footage, watch]);

  // on unmount before creation flow completed we delete created footages
  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      if (!watch("finish.completed")) {
        deleteAllCreatedFootages();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useBeforeUnloadBlocker();

  useEffect(() => {
    if (!watch("uploading.footagesArray")?.length) {
      navigate(
        `${applyPathParams(absoluteRoutes.automaticCreate.children.step.self, {
          step: steps[0].path,
        })}?boardId=${boardId}`,
      );
    } else if (firstExperienceUser && pathParams.step === "brandKit") {
      navigate(
        `${applyPathParams(absoluteRoutes.automaticCreate.children.step.children.subStep, {
          step: steps[1].path,
          subStep: SUBSTEPS.LOGO,
        })}?boardId=${boardId}`,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstExperienceUser, navigate, pathParams.step, watch]);

  if (activeStepIndex === -1) {
    return null;
  }

  return (
    <FormProvider {...automaticCreateForm}>
      <Container>
        <StyledSwiper
          activeIndex={activeStepIndex}
          allowSlideNext={
            pathParams.subStep
              ? !!substepFunctions[pathParams.subStep as keyof typeof substepFunctions]?.allowSlideNext()
              : !activeStepHasErrors
          }
          allowSlidePrev={!isLastStep}
          onActiveIndexChange={onActiveStepIndexChange}
          noSwipingSelector={`${Select}`}
          direction="vertical"
          enableMousewheel={false}
        >
          {steps.map((step, i) => (
            <SwiperSlide key={step.path}>
              <MountIfActive index={i}>
                <>
                  {pathParams.step === STEPS.UPLOADING && <step.component formContext={automaticCreateForm} />}
                  {pathParams.step === STEPS.BRAND_KIT && (
                    <step.component
                      formContext={automaticCreateForm}
                      usersCurrentPresetSidFormPath="uploading.usersCurrentPresetSid"
                    />
                  )}
                  {pathParams.step === STEPS.FINISH && (
                    <step.component formContext={automaticCreateForm} boardId={boardId} />
                  )}
                </>
              </MountIfActive>
            </SwiperSlide>
          ))}

          <Footer>
            {activeStepIndex !== steps.length - 1 && (
              <CancelButtonWrapper hide={false}>
                <CancelButton>Cancel</CancelButton>
              </CancelButtonWrapper>
            )}

            {activeStepIndex !== steps.length - 1 && (
              <CentralButtonsWrapper>
                <BackButtonWrapper>
                  {activeStepIndex !== 0 && (
                    <SwiperPrevSlideButton
                      data-testid="back"
                      onClick={
                        pathParams.subStep
                          ? substepFunctions[pathParams.subStep as keyof typeof substepFunctions]?.reject
                          : onPrevStep
                      }
                    >
                      <Icon.PrevSlide />
                      Back
                    </SwiperPrevSlideButton>
                  )}
                </BackButtonWrapper>

                <NextButtonWrapper>
                  <StyledNextSlideButton
                    data-testid="next"
                    onClick={
                      pathParams.subStep
                        ? substepFunctions[pathParams.subStep as keyof typeof substepFunctions]?.apply
                        : onNextStep
                    }
                  >
                    {isSaveChangesLoading ? <CircularLoader size={30} /> : nextButtonText}
                  </StyledNextSlideButton>
                </NextButtonWrapper>
              </CentralButtonsWrapper>
            )}
          </Footer>
        </StyledSwiper>
      </Container>
    </FormProvider>
  );
}
