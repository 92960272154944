import { formatDistance } from "date-fns";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

import { downloadInfoSelectors } from "src/models/DownloadInfo.model";
import { RootState } from "src/models/store";

import theme from "src/theme";
import styled from "styled-components/macro";

import Icon from "src/components/common/Icon";
import { CircularLoader } from "src/components/common/loaders/CircularLoader";

import { getDownloadLink } from "src/components/app/AppRouter/layouts/NotificationsCenter/NotificationCenter.utils";
import { useAppConfig } from "src/components/providers/AppConfigProvider";

import * as sequenceAnalytics from "src/analytics/download.analytics";
import { DownloadStatus } from "src/network/graphql/generatedGraphqlSDK";
import { themeColor } from "src/utils/styledComponents.utils";

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const OptionRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const OptionTitle = styled.div<{ color: any }>`
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: ${({ color }) => color};
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 20px;
  max-width: 80%;
`;

const OptionStatus = styled(OptionTitle)`
  font-weight: 400;
`;

const LastChanged = styled(OptionStatus)`
  font-size: 10px;
  line-height: 14px;
  margin: unset;
`;

const FailedDownload = styled.div`
  padding: 8px;
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: transparent;
  padding: unset;
  border: unset;
  svg {
    margin: 0 !important;
    width: 24px;
    height: auto;
    scale: 1;
    margin-right: 10px;

    path {
      fill: ${themeColor("gray.400")};
    }
  }
`;

const DownloadLink = styled.a<{ tabIndex?: number }>`
  cursor: pointer;
  padding: 8px;
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: transparent;
  padding: unset;
  border: unset;

  svg {
    margin: 0 !important;
    width: 24px;
    height: auto;
    scale: 1;
    margin-right: 10px;

    path {
      fill: ${themeColor("gray.400")};
      transition: fill 0.3s ease;
    }

    &:hover {
      scale: 1.2;
      path {
        fill: ${themeColor("blue.800")};
      }
    }
    transition: scale 0.3s ease;
  }
`;

interface DownloadNotificationOptionProps {
  notificationId: string;
  hidden?: boolean;
}

export default function DownloadNotificationOption({
  notificationId,
  hidden = false,
}: DownloadNotificationOptionProps) {
  const { CONTENT_URL } = useAppConfig();
  const authToken = useSelector((state: RootState) => state.session.authToken);
  const downloadInfo = useSelector((state: RootState) => downloadInfoSelectors.selectById(state, notificationId));

  const notificationStatus = useMemo(() => {
    switch (downloadInfo?.status) {
      case DownloadStatus.Failed:
        return "Download failed";
      case DownloadStatus.Done:
        if (downloadInfo.hitCounter > 0) {
          return "Downloaded";
        }
        return "Download ready";
      default:
        return "Preparing download";
    }
  }, [downloadInfo?.hitCounter, downloadInfo?.status]);

  const timeAfterCreation = useMemo(() => {
    const dateObject = new Date(downloadInfo?.createdAt as string);
    return formatDistance(dateObject, new Date(), { addSuffix: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadInfo?.createdAt, downloadInfo?.status]);

  const downloadLink = getDownloadLink(
    CONTENT_URL,
    downloadInfo?.externalSequenceId as string,
    downloadInfo?.externalSequenceEndVersion as number,
    authToken,
  );

  const titleColor =
    downloadInfo?.status === DownloadStatus.Done && downloadInfo.hitCounter > 0
      ? theme.colors.gray[300]
      : theme.colors.gray[900];

  const statusColor =
    downloadInfo?.status === DownloadStatus.Done && downloadInfo.hitCounter > 0
      ? theme.colors.gray[300]
      : theme.colors.gray[400];

  const onDownloadClick = useCallback(() => {
    sequenceAnalytics.trackDownloadVideo({
      trigger: "notification-center",
      sequenceSid: downloadInfo?.externalSequenceId ?? "",
    });
  }, [downloadInfo?.externalSequenceId]);

  if (hidden || !downloadInfo) {
    return null;
  }
  return (
    <Container>
      <OptionRow>
        <OptionTitle color={titleColor}>{downloadInfo.externalSequenceTitle}</OptionTitle>
        {downloadInfo.status === DownloadStatus.Processing && <CircularLoader size={20} thickness={3} />}
        {downloadInfo.status === DownloadStatus.Failed && (
          <FailedDownload>
            <Icon.DownloadFailed />
          </FailedDownload>
        )}
        {downloadInfo.status === DownloadStatus.Done && (
          <DownloadLink href={downloadLink} target="_blank" onClick={onDownloadClick}>
            <Icon.Download />
          </DownloadLink>
        )}
      </OptionRow>
      <OptionRow>
        <OptionStatus color={statusColor}>{notificationStatus}</OptionStatus>
        <LastChanged color={theme.colors.gray[300]}>{timeAfterCreation}</LastChanged>
      </OptionRow>
    </Container>
  );
}
