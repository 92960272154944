import { useMemo } from "react";
import styled from "styled-components/macro";
import { useVideoWords } from "src/components/features/VideoTrimmer/providers/VideoWordsProvider/VideoWordsContext";
import { themeColor } from "src/utils/styledComponents.utils";
import { formatSeconds } from "src/utils/time.utils";
import { timeIntervalUtils } from "src/utils/timeInterval.utils";
import { useTimelineZoom } from "src/components/features/VideoTrimmer/providers/TimelineZoomProvider/TimelineZoomContext";
import TimePointPopup from "src/components/features/VideoTrimmer/TimelineEditor/TimePointPopup";

interface PositionProps {
  absoluteTimePositionPx: number;
}

const getPositionAttrs = ({ absoluteTimePositionPx }: PositionProps) => ({
  style: {
    transform: `translateX(${absoluteTimePositionPx}px)`,
  },
});

const Indicator = styled.div.attrs(getPositionAttrs)<PositionProps>`
  z-index: 2;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 2px;
  height: calc(100% + 4px);
  background-color: ${themeColor("pink.500")};
  pointer-events: none;
`;

const TimeLabel = styled.div`
  padding: 2px 6px;
  border-radius: 5px;
  background: ${themeColor("pink.500")};
  user-select: none;
  font-size: 10px;
  line-height: 14px;
  font-weight: 600;
  color: ${themeColor("white")};
  font-family: "Open Sans", sans-serif;
  text-align: center;
`;

const Caption = styled.div`
  font-size: 8px;
  line-height: 12px;
  font-weight: 400;
`;

interface TimeIndicatorProps {
  timeInSeconds: number;
  pixelPerSecond: number;
  className?: string;
  color?: string; // TODO: make compound component so TimePointPopup children can be styled easily and remove this prop and the inline styles
  popupTarget: HTMLElement | null;
}

export default function TimeIndicator({
  timeInSeconds,
  pixelPerSecond,
  className,
  color,
  popupTarget,
}: TimeIndicatorProps) {
  const { zoomedTimeInterval } = useTimelineZoom();

  const { words } = useVideoWords();

  const absoluteTimePositionPx = timeInSeconds * pixelPerSecond;
  const relativeTimePositionPx = (timeInSeconds - zoomedTimeInterval.start) * pixelPerSecond;

  const currentWordIndex = useMemo(
    () => timeIntervalUtils.findIndexByTimePoint(words ?? [], timeInSeconds),
    [words, timeInSeconds],
  );

  return (
    // @ts-ignore
    <Indicator absoluteTimePositionPx={absoluteTimePositionPx} className={className} style={{ backgroundColor: color }}>
      <TimePointPopup relativeTimePositionPx={relativeTimePositionPx} popupTarget={popupTarget}>
        <TimeLabel style={{ backgroundColor: color }}>
          {formatSeconds(timeInSeconds, "mm:ss.SSS")}
          <Caption>{words?.[currentWordIndex!]?.word ?? "-"}</Caption>
        </TimeLabel>
      </TimePointPopup>
    </Indicator>
  );
}
