import Popper from "src/components/common/popovers/Popper";
import { Text1Thin, Text2 } from "src/components/common/layout/typography.styled";
import { Stack } from "src/components/common/layout/Stack.styled";
import styled from "styled-components/macro";
import { Button } from "src/components/common/buttons/Button.styled";
import { themeColor, themeSpacing } from "src/utils/styledComponents.utils";
import Icon from "src/components/common/Icon";
import React from "react";

export const ActionButton = styled(Button)`
  margin-top: ${themeSpacing(30)};
  margin-bottom: ${themeSpacing(10)};
`;

const CloseButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
`;

const CloseButton = styled.div`
  position: relative;
  right: 8px;
  width: 14px;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: ${themeColor("gray.500")};
    }
  }
`;

const PopperContent = styled.div`
  position: relative;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-self: center;
  padding: 7px;
  border-radius: 5px;
  pointer-events: auto;
  background: white;
  outline: 0;
  max-width: 400px;
  min-height: 130px;
  text-align: center;
`;

type CaptionsSyncModalProps = { onApprove: () => void; onCancel: () => void; onClose: () => void; isOpen: boolean };

export function CaptionsSyncModal({ onApprove, onCancel, isOpen, onClose = () => {} }: CaptionsSyncModalProps) {
  return (
    <Popper
      isOpen={isOpen}
      useClickOutside
      offsetY={-100}
      content={
        <PopperContent>
          <CloseButtonContainer>
            <CloseButton onClick={() => onClose()}>
              <Icon.Close />
            </CloseButton>
          </CloseButtonContainer>

          <Text2>Transcription Tweaked, Subtitles Need an Update</Text2>
          <Text1Thin margin={10}>
            It looks like you&apos;ve made changes to the transcript. Should we refresh the subtitles to match your
            edits?
          </Text1Thin>
          <Stack spacing={8} direction="row">
            <ActionButton type="button" variant="ghost" size="small" onClick={() => onCancel()}>
              Keep current subtitles
            </ActionButton>
            <ActionButton type="button" variant="primary" size="small" onClick={() => onApprove()}>
              Regenerate subtitles
            </ActionButton>
          </Stack>
        </PopperContent>
      }
    />
  );
}
