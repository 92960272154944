import { components, OptionProps } from "react-select";
import styled from "styled-components/macro";
import Icon from "src/components/common/Icon";
import theme from "src/theme";
import BoardsOption from "src/components/features/Boards/BoardsOption";
import { useCallback } from "react";
import ConfirmationModal from "src/components/modals/ConfirmationModal";
import { useModal } from "src/components/providers/ModalProvider";
import EntityTitleModal from "src/components/modals/EntityTitleModal";
import { useDispatch, useSelector } from "react-redux";
import { Text1Thin } from "src/components/common/layout/typography.styled";
import { ALL_BOARDS_ITEM_ID } from "src/constants/smartLibrary.constants";
import { useNavigate } from "react-router-dom";
import { absoluteRoutes } from "src/utils/routes.utils";
import * as boardsAnalytics from "src/analytics/boards.analytics";
import { RootState } from "src/models/store";
import { boardSelectors } from "src/models/Board.model";
import Popper from "src/components/common/popovers/Popper";
import { Panel } from "src/components/common/popovers/panel/Panel";

const StyledOption = styled(components.Option)`
  padding: 10px !important;
  color: ${theme.colors.gray["900"]}; !important;
  background: ${theme.colors.white} !important;
  transition: background 0.3s ease-out !important;
  cursor: pointer !important;
  &:hover {
    background: ${theme.colors.gray["200"]} !important;
  }

  &.select__option--is-focused {
    background: ${theme.colors.gray["200"]} !important;

    svg {
        color: ${theme.colors.gray["900"]};
    }
  }

  &.select__option--is-selected {
    background: ${theme.colors.blue["50"]} !important;

    &:hover {
      background: ${theme.colors.blue["50"]} !important;
    }
  }
` as any as typeof components.Option;

export const IconWrapper = styled.div<{ isFocused: boolean }>`
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    color: ${theme.colors.gray["900"]};
  }
  &:hover {
    svg {
      color: ${theme.colors.blue["500"]};
      transition: color 0.3s ease-out !important;
    }
  }
`;

export interface BoardOption {
  id: string;
  name: string;
}

export default function BoardsSelectOption(props: OptionProps<BoardOption>) {
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const navigate = useNavigate();
  const { data: selectOption, isFocused } = props;
  const targetBoard = useSelector((state: RootState) => boardSelectors.selectById(state, selectOption.id));

  const onDeleteApprove = useCallback(() => {
    dispatch.boards.deleteBoard({ input: { id: selectOption.id } });
    if (isFocused) navigate(absoluteRoutes.platform.self);
    boardsAnalytics.trackDeleteBoard(targetBoard, "board-select");
  }, [dispatch.boards, isFocused, navigate, selectOption.id, targetBoard]);

  const onEditApprove = useCallback(
    (name: string) => {
      dispatch.boards.updateBoard({ input: { id: selectOption.id, update: { name } } });
      boardsAnalytics.trackRenameBoard(name, selectOption.name, "board-select");
    },
    [dispatch.boards, selectOption.id, selectOption.name],
  );

  const showRenameModal = useCallback(() => {
    openModal(
      ({ close }) => (
        <EntityTitleModal
          {...props}
          onSubmit={onEditApprove}
          close={close}
          modalTitle="Rename board"
          titlePlaceholder={selectOption.name}
          defaultPlaceHolder="Enter board name"
          submitLabel="Submit"
        />
      ),
      { hideCloseButton: true },
    );
  }, [onEditApprove, openModal, props, selectOption.name]);

  const showDeleteModal = useCallback(() => {
    openModal(
      ({ close }) => (
        <ConfirmationModal
          close={close}
          onApprove={onDeleteApprove}
          title="Delete board"
          description={
            <>
              <div>{`Are you sure you want to delete the '${selectOption.name}' board?`}</div>
              <div>
                <Text1Thin>Your videos won’t be deleted</Text1Thin>
              </div>
            </>
          }
          approveLabel="OK"
          declineLabel="Cancel"
        />
      ),
      { hideCloseButton: true },
    );
  }, [onDeleteApprove, openModal, selectOption.name]);

  const onEditClick = useCallback(
    (e: any) => {
      (document.activeElement as HTMLInputElement)?.blur?.();
      e.stopPropagation();
      showRenameModal();
    },
    [showRenameModal],
  );

  const onDeleteClick = useCallback(
    (e: any) => {
      (document.activeElement as HTMLInputElement)?.blur?.();
      e.stopPropagation();
      showDeleteModal();
    },
    [showDeleteModal],
  );

  return (
    <StyledOption {...props}>
      <BoardsOption id={selectOption.id} name={selectOption.name} isFocused={isFocused}>
        {selectOption.id !== ALL_BOARDS_ITEM_ID && isFocused && (
          <>
            <Popper content={<Panel>Rename board</Panel>} trigger="hover" placement="top">
              <IconWrapper onClick={(e) => onEditClick(e)} isFocused={isFocused}>
                <Icon.Edit size={18} />
              </IconWrapper>
            </Popper>
            <Popper content={<Panel>Delete board</Panel>} trigger="hover" placement="top">
              <IconWrapper onClick={onDeleteClick} isFocused={isFocused}>
                <Icon.Trash size={18} />
              </IconWrapper>
            </Popper>
          </>
        )}
      </BoardsOption>
    </StyledOption>
  );
}
