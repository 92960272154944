import { components, OptionProps } from "react-select";

import theme from "src/theme";
import styled from "styled-components/macro";

import { Stack } from "src/components/common/layout/Stack.styled";
import { SequenceFilterOption } from "src/components/features/SmartLibrary/filtering/filtersSelect/SequenceFilterSelect";
import { themeColor } from "src/utils/styledComponents.utils";

const StyledOption = styled(components.Option)`
  padding: 5px !important;
  margin-block: 3px;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: ${theme.colors.gray[900]};
  background: ${theme.colors.white} !important;
  &:hover {
    background-color: ${theme.colors.blue[50]} !important;
  }

  &.select__option--is-focused {
    background-color: ${theme.colors.blue[50]} !important;
  }
  transition: background-color 500ms ease-out !important;
  cursor: pointer !important;
` as any as typeof components.Option;

const StyledSelectStack = styled(Stack)`
  align-items: center;
  max-height: 28px;
`;

const IconWrapper = styled.div`
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    color: ${themeColor("blue.900")};
  }
  img {
    width: 100%;
    object-fit: contain;
  }
`;

export default function SequenceFilterOptionComponent(props: OptionProps<SequenceFilterOption>) {
  const { data: selectOption } = props;

  return (
    <StyledOption {...props}>
      <StyledSelectStack direction="row" spacing={12}>
        <IconWrapper>{selectOption.icon}</IconWrapper>
        <span>{selectOption.label}</span>
      </StyledSelectStack>
    </StyledOption>
  );
}
