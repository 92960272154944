import { Outlet } from "react-router-dom";

import LoginSignupLayout from "src/components/auth/common/LoginSignupLayout/LoginSignupLayout";

export default function LoginSignupLayoutPage() {
  return (
    <LoginSignupLayout>
      <Outlet />
    </LoginSignupLayout>
  );
}
