import React, { useEffect, useState } from "react";
import { ListChildComponentProps } from "react-window";
import styled, { css } from "styled-components/macro";

import { ChapterTimeInterval, Line, LineItem, TimeInterval, TranscriptSelection } from "src/types/video-trimmer.types";

import { ifProp, themeColor } from "src/utils/styledComponents.utils";
import { timeIntervalUtils } from "src/utils/timeInterval.utils";
import { TextDirection } from "src/utils/localization.utils";

import {
  BLANK_WIDTH,
  FONT_FAMILIES,
  FONT_SIZE,
  FONT_WEIGHT,
  LINE_HEIGHT,
  LINE_INLINE_PADDING,
} from "src/constants/video-trimmer.constants";

import Icon from "src/components/common/Icon";
import { Button } from "src/components/common/buttons/Button.styled";
import LineItemMenu from "src/components/features/VideoTrimmer/VideoTranscript/LineItemMenu";
import EndOfSelection from "src/components/features/VideoTrimmer/VideoTranscript/EndOfSelection";
import { useCurrentTimeListener } from "src/components/features/VideoTrimmer/providers/VideoCurrentTimeProvider/VideoCurrentTimeListenerContext";
import { useFeatureFlag } from "src/components/providers/FeatureFlagsProvider";
import { useVideoPlayback } from "src/components/features/VideoTrimmer/providers/VideoPlaybackProvider/VideoPlaybackContext";
import { useVideoChapters } from "src/components/features/VideoTrimmer/providers/VideoChaptersProvider/VideoChaptersContext";
import ClipCreatorSelectionActionsPopover from "src/components/features/ClipCreator/ClipCreatorSelectionActionPopover";
import theme from "src/theme";
import { isSelectionInSelection } from "src/components/features/VideoTrimmer/VideoTranscript/utils";
import { useVideoWords } from "src/components/features/VideoTrimmer/providers/VideoWordsProvider/VideoWordsContext";

const LineContainer = styled.div`
  padding-inline: ${LINE_INLINE_PADDING}px;
`;

interface LineItemViewProps {
  isSelected: boolean;
  isPlayable: boolean;
  isSearchResult: boolean;
  isStartOfSearchResult: boolean;
  isEndOfSearchResult: boolean;
  isCurrentFocusedSearchResult: boolean;
  textDirection: TextDirection;
  disabled?: boolean;
}

const LineItemView = styled.span<LineItemViewProps>`
  display: inline-block;
  position: relative;
  font-weight: ${FONT_WEIGHT};
  font-size: ${FONT_SIZE}px;
  line-height: ${LINE_HEIGHT}px;

  &:before {
    content: "";
    display: block;
    position: absolute;
    z-index: -3;
    inset: 0;
    ${ifProp(
      "isPlayable",
      css`
        background-color: ${theme.colors.yellow["500"]};
      `,
    )}
    ${ifProp(
      "isSelected",
      css`
        background-color: ${theme.colors.blue["50"]};
      `,
    )}
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    z-index: -2;
    inset: 0;

    ${ifProp(
      "isCurrentFocusedSearchResult",
      css<LineItemViewProps>`
        background-color: ${ifProp("isPlayable", themeColor("blue.500"), themeColor("gray.400"))};
      `,
      ifProp(
        "isSearchResult",
        css<LineItemViewProps>`
          background-color: ${ifProp("isPlayable", "#e5f4ff", themeColor("gray.225"))}; // TODO: new color ?? #e5f4ff
        `,
      ),
    )}

    ${(props) => {
      if (props.isStartOfSearchResult && props.isEndOfSearchResult) {
        return css<LineItemViewProps>`
          border-radius: 6px;
          inset: 0 0 0 -5px;

          ${props.textDirection === "rtl" &&
          css`
            inset: 0 -5px 0 0;
          `}
        `;
      }

      if (props.isEndOfSearchResult) {
        return css`
          inset: 0;
          ${props.textDirection !== "rtl" &&
          css`
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
          `}

          ${props.textDirection === "rtl" &&
          css`
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
          `}
        `;
      }

      if (props.isStartOfSearchResult) {
        return css<LineItemViewProps>`
          inset: 0 0 0 -5px;
          ${props.textDirection !== "rtl" &&
          css`
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
          `}

          ${props.textDirection === "rtl" &&
          css`
            inset: 0 -5px 0 0;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
          `}
        `;
      }

      return null;
    }}
  }

  * {
    font-family: ${(props) => FONT_FAMILIES[props.textDirection]};
  }
`;

interface LineItemContentProps {
  isRTL: boolean;
  isSearchHighlighted: boolean;
  isHighlighted?: boolean;
  isPlayable: boolean;
  isActive: boolean;
  isSearchResult: boolean;
  isCurrentFocusedSearchResult: boolean;
}

const Word = styled.span<LineItemContentProps>`
  &:before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    top: 1px;
    right: ${ifProp("isRTL", -3, 2)}px;
    bottom: 1px;
    left: ${ifProp("isRTL", 2, -3)}px;
    border-radius: 6px;
    background-color: ${ifProp("isActive", themeColor("pink.500"), "unset")};
  }

  color: ${themeColor("black")};
  ${ifProp(
    "isHighlighted",
    css<LineItemContentProps>`
      text-decoration: underline;
    `,
  )}
  ${ifProp(
    "isSearchResult",
    css<LineItemContentProps>`
      color: ${themeColor("blue.500")};
    `,
  )}

  ${ifProp(
    "isCurrentFocusedSearchResult",
    css<LineItemContentProps>`
      color: ${themeColor("white")};
    `,
  )};

  ${ifProp(
    "isSearchHighlighted",
    css<LineItemContentProps>`
      color: ${themeColor("white")};

      &:before {
        background-color: ${themeColor("blue.500")};
      }
    `,
  )}

  ${ifProp(
    "isActive",
    css<LineItemContentProps>`
      color: ${themeColor("white")};
    `,
  )}
`;

const Blank = styled(Button)<LineItemContentProps>`
  display: inline-flex;
  align-items: center;
  vertical-align: bottom;
  width: ${BLANK_WIDTH - 10}px;
  height: 24px;
  min-height: unset;
  margin-inline: 5px;
  padding: 0;
  font-weight: 400;
  color: ${themeColor("black")};
  border-color: transparent;
  background-color: ${themeColor("gray.200")};
  pointer-events: ${ifProp("disabled", "none", "auto")};

  /* this overwrites the inner button's disabled */
  &:disabled {
    border-color: transparent;
    color: ${themeColor("black")};
  }

  ${ifProp(
    "isPlayable",
    css<LineItemContentProps>`
      background-color: ${themeColor("yellow.500")};
      border-color: ${themeColor("orange.300")};
      &:hover {
        color: ${themeColor("blue.500")};
        border-color: currentColor !important;
      }
    `,
    css<LineItemContentProps>`
      color: ${themeColor("black")};

      &:hover {
        color: ${themeColor("gray.500")};
        border-color: ${themeColor("gray.300")} !important;
      }
    `,
  )}

  svg {
    display: none;
    flex: 0 0 auto;
    margin-right: 5px;
  }

  &:hover {
    background-color: ${ifProp("isPlayable", themeColor("yellow.500"), themeColor("white"))} !important;

    svg {
      display: inline;
    }
  }

  ${ifProp(
    "isSearchHighlighted",
    css<LineItemContentProps>`
      color: ${themeColor("blue.500")};
      border-color: currentColor;
    `,
  )}

  ${ifProp(
    "isActive",
    css<LineItemContentProps>`
      background-color: ${themeColor("pink.500")} !important;
      border-color: ${themeColor("pink.500")};
      color: ${themeColor("white")};

      &:hover {
        color: ${themeColor("white")} !important;
        border-color: ${themeColor("pink.500")} !important;
        background-color: ${themeColor("pink.500")} !important;
      }
    `,
  )}
`;

const isLineItemSelected = (
  lineIndexArg: number,
  itemIndexArg: number,
  transcriptSelection: TranscriptSelection | null,
  lines: Line[],
) => {
  if (!transcriptSelection) return false;

  const item = lines[lineIndexArg].items[itemIndexArg];
  const itemSelection: TranscriptSelection = {
    start: { lineIndex: lineIndexArg, itemIndex: itemIndexArg, itemOffset: 0 },
    end: {
      lineIndex: lineIndexArg,
      itemIndex: itemIndexArg,
      itemOffset: item.kind === "blank" ? 1 : item.word.length,
    },
  };

  return isSelectionInSelection(itemSelection, transcriptSelection);
};

export type ClipCreatorLineViewProps = {
  textDirection: TextDirection;
  lines: Line[];
  visibleLineRange: { start: number; end: number };
  transcriptSelection: TranscriptSelection | null;
  recentInteractedSelectionEdge: "start" | "end" | null;
  highlightedLineItemKinds: LineItem["kind"][];
  isSelecting: boolean;
  selectionTimeInterval: TimeInterval | null;
  searchResults: TimeInterval[];
  focusedSearchResultIndex: number;
  isLineItemSelected(lineIndex: number, itemIndex: number): boolean;
  isLineItemPlayable(lineIndex: number, itemIndex: number): boolean;
  onLineItemClick(lineIndex: number, itemIndex: number): void;
  cancelSelection(): void;
  isInitialChapter: boolean;
  setHoveredLineItem(item: LineItem | null): void;
  hoveredLineItem: LineItem | null;
  hoveredChapter: ChapterTimeInterval | null;
  isFirstInHoveredChapter(lineIndex: number, itemIndex: number): boolean;
};

// TODO: anything to do with setHoveredLineItem, hoveredLineItem,
//  hoveredChapter, isFirstInHoveredChapter should be used in phase 2 ->
//  review if actually needed in phase 2
export default function ClipCreatorLineView({
  index: lineIndex,
  style,
  isScrolling,
  data: {
    textDirection,
    lines,
    visibleLineRange,
    transcriptSelection,
    recentInteractedSelectionEdge,
    highlightedLineItemKinds,
    isSelecting,
    selectionTimeInterval,
    searchResults,
    focusedSearchResultIndex,
    isLineItemPlayable,
    onLineItemClick,
    cancelSelection,
    isInitialChapter,
    setHoveredLineItem,
  },
}: ListChildComponentProps<ClipCreatorLineViewProps>) {
  const [activeLineItemIndex, setActiveLineItemIndex] = useState<number | null>(null);
  const [activeActionsMenuLineItemIndex, currentLineItemWithMenu] = useState<number>(-1);
  const isContentEditorHighlightEnabled = useFeatureFlag("contentEditorHighlight");
  const { currentTime: videoCurrentTime } = useVideoPlayback();
  const { words } = useVideoWords();

  const { allowedActions: chaptersAllowedActions, chapters } = useVideoChapters();

  useCurrentTimeListener((currentTime: number) => {
    setActiveLineItemIndex(timeIntervalUtils.findIndexByTimePoint(lines[lineIndex].items, currentTime));
  });

  useEffect(() => {
    setActiveLineItemIndex(timeIntervalUtils.findIndexByTimePoint(lines[lineIndex].items, videoCurrentTime));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LineContainer dir={textDirection} style={style}>
      {lines[lineIndex].items.map((lineItem, lineItemIndex) => {
        const space = lineItemIndex < lines[lineIndex].items.length - 1;
        const isActionMenuOpen =
          chaptersAllowedActions?.addChapter?.isAllowed &&
          chaptersAllowedActions?.removeChapter?.isAllowed &&
          activeActionsMenuLineItemIndex === lineItemIndex;
        const isActive = lineItemIndex === activeLineItemIndex;

        const isPlayable = !isInitialChapter && isLineItemPlayable(lineIndex, lineItemIndex);

        const isSearchHighlighted =
          (chaptersAllowedActions?.addChapter?.isAllowed &&
            chaptersAllowedActions?.removeChapter?.isAllowed &&
            isActionMenuOpen) ||
          (highlightedLineItemKinds.includes(lineItem.kind) &&
            (timeIntervalUtils.intervalsIntersectingMultipleSelections(words, chapters, [lineItem]).length > 0 ||
              chapters.some((c) => timeIntervalUtils.containsTimeInterval(c, lineItem))));

        const isSelectionStart =
          lineIndex === transcriptSelection?.start.lineIndex && lineItemIndex === transcriptSelection.start.itemIndex;

        const isSelectionEnd =
          lineIndex === transcriptSelection?.end.lineIndex && lineItemIndex === transcriptSelection.end.itemIndex;

        const isSelected = isSelecting
          ? isLineItemSelected(lineIndex, lineItemIndex, transcriptSelection, lines)
          : isLineItemSelected(lineIndex, lineItemIndex, transcriptSelection, lines) ||
            isSelectionStart ||
            isSelectionEnd;

        const searchResult = searchResults.find((currentSearchResult) =>
          timeIntervalUtils.containsTimeInterval(currentSearchResult, lineItem),
        );

        const currentFocusedSearchResult = searchResults[focusedSearchResultIndex];
        const isCurrentFocusedSearchResult =
          currentFocusedSearchResult && timeIntervalUtils.containsTimeInterval(currentFocusedSearchResult, lineItem);

        const displaySelectionActions =
          !isSelecting &&
          !isScrolling &&
          timeIntervalUtils.includesTimePoint(visibleLineRange, lineIndex) &&
          ((recentInteractedSelectionEdge === "start" && isSelectionStart) ||
            (recentInteractedSelectionEdge === "end" && isSelectionEnd));

        const isLineItemClickDisabled =
          lineItem.kind === "blank" &&
          (!chaptersAllowedActions?.addChapter?.isAllowed || !chaptersAllowedActions?.removeChapter?.isAllowed);

        return (
          <LineItemView
            key={`lv-${lineItem.start}`}
            data-lineindex={lineIndex}
            data-itemindex={lineItemIndex}
            isSelected={isSelected}
            isPlayable={isPlayable}
            isSearchResult={!!searchResult}
            isStartOfSearchResult={lineItem.start === searchResult?.start}
            isEndOfSearchResult={lineItem.end === searchResult?.end}
            isCurrentFocusedSearchResult={isCurrentFocusedSearchResult}
            textDirection={textDirection}
            onClick={isLineItemClickDisabled ? () => {} : () => onLineItemClick(lineIndex, lineItemIndex)}
            onDragStartCapture={(e) => e.preventDefault()}
            onMouseEnter={() => {
              isPlayable && setHoveredLineItem(lineItem);
            }}
            onMouseLeave={() => {
              !isPlayable && setHoveredLineItem(null);
            }}
          >
            {(isSelectionStart || isSelectionEnd) && (
              <EndOfSelection
                textDirection={textDirection}
                isSelectionStart={isSelectionStart}
                isSelectionEnd={isSelectionEnd}
                isSelecting={isSelecting}
                transcriptSelection={transcriptSelection}
                lineItem={lineItem}
              />
            )}
            {(() => {
              switch (lineItem.kind) {
                case "blank":
                  return (
                    <Blank
                      dir="ltr"
                      isRTL={textDirection === "rtl"}
                      isSearchHighlighted={isSearchHighlighted}
                      isPlayable={isPlayable}
                      isActive={isActive}
                      isSearchResult={!!searchResult}
                      isCurrentFocusedSearchResult={isCurrentFocusedSearchResult}
                      size="small"
                      variant="ghost"
                      disabled={
                        !chaptersAllowedActions?.addChapter?.isAllowed ||
                        !chaptersAllowedActions?.removeChapter?.isAllowed
                      }
                    >
                      {isPlayable ? <Icon.Minus size={14} /> : <Icon.Plus size={14} />}Quiet break
                    </Blank>
                  );

                case "fillerWord":
                  return (
                    <LineItemMenu
                      removeLabel="Remove"
                      revertLabel="Add"
                      removeIcon={<Icon.Minus size={16} color="blue.500" />}
                      revertIcon={<Icon.Plus size={16} color="blue.500" />}
                      isPlayable={isPlayable}
                      item={lineItem}
                      isOpen={isActionMenuOpen}
                      onOpen={() => currentLineItemWithMenu(lineItemIndex)}
                      onClose={() => currentLineItemWithMenu(-1)}
                      disabled={isSelected || isSelecting}
                    >
                      <Word
                        isRTL={textDirection === "rtl"}
                        isSearchHighlighted={isSearchHighlighted}
                        isPlayable={isPlayable}
                        isActive={isActive}
                        isSearchResult={!!searchResult}
                        isCurrentFocusedSearchResult={isCurrentFocusedSearchResult}
                      >
                        {lineItem.word}
                      </Word>
                    </LineItemMenu>
                  );
                case "highlightedWord":
                  return (
                    <Word
                      isRTL={textDirection === "rtl"}
                      isSearchHighlighted={isSearchHighlighted}
                      isPlayable={isPlayable}
                      isActive={isActive}
                      isSearchResult={!!searchResult}
                      isCurrentFocusedSearchResult={isCurrentFocusedSearchResult}
                      isHighlighted={!!isContentEditorHighlightEnabled}
                    >
                      {lineItem.word}
                    </Word>
                  );
                case "word":
                  return (
                    <Word
                      isRTL={textDirection === "rtl"}
                      isSearchHighlighted={isSearchHighlighted}
                      isPlayable={isPlayable}
                      isActive={isActive}
                      isSearchResult={!!searchResult}
                      isCurrentFocusedSearchResult={isCurrentFocusedSearchResult}
                    >
                      {lineItem.word}
                    </Word>
                  );

                default:
                  return null;
              }
            })()}
            {space && <span data-baseoffset={lineItem.kind === "blank" ? 0 : lineItem.word.length}>&nbsp;</span>}

            {displaySelectionActions && (
              <ClipCreatorSelectionActionsPopover
                isPlayable={isLineItemPlayable(lineIndex, lineItemIndex)}
                selectionTimeInterval={selectionTimeInterval}
                recentInteractedSelectionEdge={recentInteractedSelectionEdge}
                cancelSelection={cancelSelection}
                isInitialChapter={isInitialChapter}
              />
            )}
          </LineItemView>
        );
      })}
    </LineContainer>
  );
}
