import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sequenceSelectors } from "src/models/Sequence.model";
import { CurrentSequenceSavingStatusType } from "src/models/Session.model";
import { Dispatch, RootState } from "src/models/store";
import { SequenceInteraction } from "src/network/graphql/generatedGraphqlSDK";

const useSequenceSavingStatus = (sequenceSid: string | undefined) => {
  const dispatch = useDispatch<Dispatch>();
  const sequence = useSelector((state: RootState) => sequenceSelectors.selectById(state, sequenceSid ?? ""));
  const [hasSequenceUpdates, setHasSequenceUpdates] = useState(false);

  const isUpdatingSequence = useSelector(
    (state: RootState) =>
      !!state.loading.effects.footage.renameClipSequence.loading ||
      !!state.loading.effects.sequences.reorderSequenceChapters.loading,
  );

  const currentSequenceReactorSavingStatus = useSelector((state: RootState) => state.session.currentSequenceReactorSavingStatus); // prettier-ignore
  const currentSequencePlatformSavingStatus = useSelector((state: RootState) => state.session.currentSequencePlatformSavingStatus); // prettier-ignore

  useEffect(() => {
    if (isUpdatingSequence) {
      dispatch.session.setCurrentSequencePlatformSavingStatus(CurrentSequenceSavingStatusType.IN_PROGRESS);
    } else if (
      isUpdatingSequence === false &&
      currentSequencePlatformSavingStatus === CurrentSequenceSavingStatusType.IN_PROGRESS
    ) {
      dispatch.session.setCurrentSequencePlatformSavingStatus(CurrentSequenceSavingStatusType.SUCCESS);
    }
  }, [dispatch.session, isUpdatingSequence, currentSequencePlatformSavingStatus]);

  const timeoutIdRef = useRef<number | null>(null);
  const timeoutCallbackRef = useRef<(() => void) | null>(null);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      // check if there is a timeout and a callback to call on unmount
      if (timeoutIdRef.current && timeoutCallbackRef.current) {
        window.clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = null;
        timeoutCallbackRef.current();
      }
      // cleanup status on unmount
      dispatch.session?.setCurrentSequenceReactorSavingStatus(CurrentSequenceSavingStatusType.NOT_STARTED);
      dispatch.session?.setCurrentSequencePlatformSavingStatus(CurrentSequenceSavingStatusType.NOT_STARTED);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      (currentSequenceReactorSavingStatus === CurrentSequenceSavingStatusType.SUCCESS ||
        currentSequencePlatformSavingStatus === CurrentSequenceSavingStatusType.SUCCESS) &&
      sequence
    ) {
      timeoutCallbackRef.current = () => {
        timeoutIdRef.current = null;
        dispatch.footage?.interactClipSequence({
          footageId: sequence?.footageSid as string,
          externalSequenceId: sequenceSid as string,
          sequenceInteraction: "edited" as SequenceInteraction,
        });
      };

      timeoutIdRef.current = window.setTimeout(timeoutCallbackRef.current, hasSequenceUpdates ? 180000 : 0);

      if (!hasSequenceUpdates) {
        setHasSequenceUpdates(true);
      }
    }

    return () => {
      if (timeoutIdRef.current) {
        window.clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSequenceReactorSavingStatus, currentSequencePlatformSavingStatus]);

  const sequenceSavingStatus = useMemo(() => {
    if (
      currentSequenceReactorSavingStatus === CurrentSequenceSavingStatusType.IN_PROGRESS ||
      currentSequencePlatformSavingStatus === CurrentSequenceSavingStatusType.IN_PROGRESS
    ) {
      return CurrentSequenceSavingStatusType.IN_PROGRESS;
    }
    if (
      currentSequenceReactorSavingStatus === CurrentSequenceSavingStatusType.SUCCESS ||
      currentSequencePlatformSavingStatus === CurrentSequenceSavingStatusType.SUCCESS
    ) {
      return CurrentSequenceSavingStatusType.SUCCESS;
    }
    return CurrentSequenceSavingStatusType.NOT_STARTED;
  }, [currentSequencePlatformSavingStatus, currentSequenceReactorSavingStatus]);

  return sequenceSavingStatus;
};

export default useSequenceSavingStatus;
