import { createSelector } from "@reduxjs/toolkit";
import { createModel } from "@rematch/core";

import { ChargeType, SequenceStatus, VideoTypeStatus } from "src/constants/model.constants";
import { planSelectors } from "src/models/Plan.model";
import { sequenceSelectors } from "src/models/Sequence.model";
import { RootModel } from "src/models/index";
import { RootState } from "src/models/store";

import apiClient from "src/network/ApiClient";
import socketClient from "src/network/SocketClient";
import { DownloadsBalance, EditRules, Shopper, Subscription, VideoType } from "src/network/graphql/generatedGraphqlSDK";
import { format } from "date-fns";

export enum NotificationType {
  DOWNLOAD = "download",
}

export enum SaveStatus {
  PENDING = "PENDING",
  SAVING = "SAVING",
  SAVED = "SAVED",
}

export enum CurrentSequenceSavingStatusType {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export type Notification = {
  dataId: string;
  type: NotificationType;
  time: string;
};

export type PlayFromData = {
  offset: number;
  chapterSid: string;
  currentTime?: number;
};

const getInitialChargeType = createSelector(
  [
    planSelectors.selectUsersPlan,
    (state: RootState) => state.session.subscription,
  ], // prettier-ignore
  (usersPlan, usersSubscription) => {
    if (
      !usersSubscription ||
      !usersSubscription.chargeType ||
      (usersSubscription?.chargeType && usersPlan?.name === "Freemium")
    ) {
      return ChargeType.Yearly;
    }
    return usersSubscription.chargeType;
  },
);

const selectSequenceLimit = createSelector(
  planSelectors.selectUsersPlan,
  (plan) => (plan ? plan.maxVideos ?? Infinity : null), // prettier-ignore
);

const selectDownloadsBalance = createSelector(
  (state: RootState) => state.session.downloadsBalance,
  (downloadsBalance) => downloadsBalance,
);

const selectFormattedBillingCycleDate = createSelector(
  (state: RootState) => state.session?.subscription?.nextChargeTime,
  (nextChargeTime: any, formatString: string = "dd/MM/yyyy") => formatString,
  (nextChargeTime: any, formatString) => (nextChargeTime && format(new Date(nextChargeTime), formatString)) || "",
);

const selectSequencesSinceLastSubscriptionCharge = createSelector(
  (state: RootState) => state.session.subscription,
  sequenceSelectors.selectAll,
  (subscription, sequences) => {
    const lastSubscriptionCharge = subscription?.lastActivationDate ?? null;

    return lastSubscriptionCharge === null
      ? []
      : sequences.filter(
          (sequence) => sequence.status === SequenceStatus.Active && sequence.insertTime! >= lastSubscriptionCharge,
        );
  },
);

const selectSequenceLimitHasReached = createSelector(
  selectSequencesSinceLastSubscriptionCharge,
  selectSequenceLimit,
  (sequencesSinceLastSubscriptionCharge, sequenceLimit) => {
    if (sequenceLimit === null) {
      return false;
    }

    return sequencesSinceLastSubscriptionCharge.length >= sequenceLimit;
  },
);

const selectNotifications = createSelector(
  (state: RootState) => state,
  (state: RootState) => state.downloadInfo.entities ?? [],
  (state, downloadInfos) => {
    const notifications: Notification[] = [];
    Object.values(downloadInfos).forEach((downloadInfo) => {
      const notification = {
        dataId: downloadInfo!.id! as string,
        type: NotificationType.DOWNLOAD,
        time: downloadInfo?.createdAt as string,
      };
      notifications.push(notification);
    });

    return notifications.sort((a, b) => (new Date(a.time!) > new Date(b.time!) ? -1 : 1));
  },
);

export const sessionSelectors = {
  getInitialChargeType,
  selectSequenceLimit,
  selectSequencesSinceLastSubscriptionCharge,
  selectSequenceLimitHasReached,
  selectDownloadsBalance,
  selectNotifications,
  selectFormattedBillingCycleDate,
};

interface SessionStateType {
  userId: string;
  authToken: string;
  planSid: string;
  subscription?: Subscription;
  shopper?: Shopper;
  recentError?: string;
  videoTypes: VideoType[];
  editRules: EditRules[];
  defaultVideoType?: VideoType;
  downloadsBalance?: DownloadsBalance;
  hasNewNotifications?: boolean;
  currentSequenceReactorSavingStatus?: CurrentSequenceSavingStatusType;
  currentSequencePlatformSavingStatus?: CurrentSequenceSavingStatusType;
  saveStatus: SaveStatus;
  playFrom: PlayFromData;
}

const initialState: SessionStateType = {
  userId: "",
  authToken: "",
  planSid: "",
  videoTypes: [],
  editRules: [],
  hasNewNotifications: false,
  currentSequenceReactorSavingStatus: CurrentSequenceSavingStatusType.NOT_STARTED,
  currentSequencePlatformSavingStatus: CurrentSequenceSavingStatusType.NOT_STARTED,
  saveStatus: SaveStatus.PENDING,
  playFrom: { chapterSid: "", offset: 0, currentTime: 0 },
};

const session = createModel<RootModel>()({
  state: initialState,

  reducers: {
    setUserId: (state, userId: string) => ({ ...state, userId }),
    setAuthToken: (state, authToken: string) => ({ ...state, authToken }),
    setPlanSid: (state, planSid: string) => ({ ...state, planSid }),
    setSubscription: (state, subscription: Subscription) => ({ ...state, subscription }),
    setShopper: (state, shopper: Shopper) => ({ ...state, shopper }),
    setRecentError: (state, recentError?: string) => ({ ...state, recentError }),
    setVideoTypes: (state, videoTypes: VideoType[]) => ({ ...state, videoTypes }),
    setEditRules: (state, editRules: EditRules[]) => ({ ...state, editRules }),
    setDownloadsBalance: (state, downloadsBalance: DownloadsBalance) => ({ ...state, downloadsBalance }),
    setDefaultVideoType: (state, defaultVideoType: VideoType | undefined) => ({ ...state, defaultVideoType }),
    setHasNewNotifications: (state, hasNewNotifications: boolean) => ({ ...state, hasNewNotifications }),
    setCurrentSequenceReactorSavingStatus: (
      state,
      currentSequenceReactorSavingStatus: CurrentSequenceSavingStatusType,
    ) => ({
      ...state,
      currentSequenceReactorSavingStatus,
    }),
    setCurrentSequencePlatformSavingStatus: (
      state,
      currentSequencePlatformSavingStatus: CurrentSequenceSavingStatusType,
    ) => ({
      ...state,
      currentSequencePlatformSavingStatus,
    }),
    setSaveStatus: (state, saveStatus: SaveStatus) => ({
      ...state,
      saveStatus,
    }),
    setPlayFrom: (state, playFrom: PlayFromData) => ({
      ...state,
      playFrom,
    }),

    reset: () => initialState,
  },

  effects: (dispatch) => ({
    loadAuthToken: (authToken: string) => {
      dispatch.session.setAuthToken(authToken);
      apiClient.init(authToken);
      socketClient.init(authToken);
      window.localStorage.setItem("userHasLoggedBefore", "true");
    },

    async getSubscription() {
      const fetchedSubscription = await apiClient.getSubscription();
      dispatch.session.setSubscription(fetchedSubscription);
    },

    async getShopper() {
      const fetchedShopper = await apiClient.getShopper();
      dispatch.session.setShopper(fetchedShopper);
    },

    async getVideoTypes() {
      const fetchVideoTypes = await apiClient.getVideoTypes();
      dispatch.session.setVideoTypes(fetchVideoTypes);
      dispatch.session.setDefaultVideoType(fetchVideoTypes?.find((x) => x.status === VideoTypeStatus.IsDefault));
    },

    async getEditRules() {
      const fetchEditRules = await apiClient.getEditRules();
      dispatch.session.setEditRules(fetchEditRules);
    },

    async getDownloadsBalance() {
      const fetchDownloadsBalance = await apiClient.getDownloadsBalance();
      dispatch.session.setDownloadsBalance(fetchDownloadsBalance);
    },
  }),
});

export default session;
