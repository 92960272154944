import { useCallback, useState } from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "src/components/common/layout/Box.styled";
import { ifProp } from "styled-tools";
import apiClient from "src/network/ApiClient";
import { Dispatch, RootState } from "src/models/store";
import { CreateAssetMutationVariables } from "src/network/graphql/generatedGraphqlSDK";
import { assetSelectors } from "src/models/Asset.model";
import UploadItemMobile from "src/components/features/MobileUploader/UploadItemMobile";
import Dropzone from "src/components/common/dropzones/Dropzone";
import Icon from "src/components/common/Icon";
import { themeColor, themeProp } from "src/utils/styledComponents.utils";
import { Text1Bold, Text1Thin } from "src/components/common/layout/typography.styled";
import { AssetType } from "src/constants/model.constants";
import { UploaderTypes, useUploaderContext } from "src/components/providers/UploaderProvider";
import ResponsivePeechLibrary from "src/components/common/ResponsiveLibrary/ResponsivePeechLibrary";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 25px;
  overflow: hidden;
`;

const Scroll = styled(Box)`
  flex: 1;
  overflow: scroll;
`;

const UploadButtonContainer = styled.div`
  width: 90%;
  height: 100px;
  border-radius: 8px 8px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  span {
    text-align: center;
    padding-inline: 5px;

    ${Text1Bold} {
      margin-top: 10px;
      font-family: "Open Sans";
      color: ${themeColor("gray.900")};
      font-size: 14px;
    }
    ${Text1Thin} {
      font-size: 12px;
      font-family: "Open Sans";
      color: ${themeColor("gray.900")};
    }
  }

  @media only screen and (min-width: 425px) {
    span {
      text-align: center;
      padding-inline: 5px;

      ${Text1Bold} {
        margin-top: 20px;
        font-size: 16px;
      }
      ${Text1Thin} {
        font-size: 12px;
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    span {
      ${Text1Bold} {
        margin-top: 10px;
      }
    }
  }

  @media only screen and (min-width: 1440px) {
    span {
      ${Text1Bold} {
        margin-top: 20px;
      }
    }
  }
`;
const IconContainer = styled.div<{ showDragging: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  svg {
    width: 71px;
    height: 37px;
    margin-top: 10px;
    stroke: ${themeColor("gray.300")};
    animation: ${ifProp("showDragging", "resize 0.3s 0.5s forwards", "unset")};

    .highlight {
      animation: ${ifProp("showDragging", "slide 0.5s forwards", "unset")};
    }

    .upload-color {
      fill: ${themeColor("gray.50")};
      stroke: ${themeColor("gray.300")};
    }

    .highlight-color {
      /* fill: ${themeColor("blue.500")}; */
      fill: ${ifProp("showDragging", themeColor("pink.500"), themeColor("blue.500"))};
      stroke: ${themeColor("gray.50")};
    }

    .hightlight-second-color {
      fill: ${themeColor("gray.50")};
    }

    &:hover {
      animation: resize 0.3s 0.5s forwards;

      .highlight {
        animation: slide 0.5s forwards;
      }
    }

    @keyframes slide {
      0% {
        transform: translate(0);
      }

      50% {
        transform: translate(-30px, 0) scale(1);
      }

      100% {
        transform: translate(-70px, -60px) scale(1.5);
      }
    }

    @keyframes resize {
      from {
        transform: scale(1);
      }

      to {
        transform: scale(1.1);
      }
    }
  }

  @media only screen and (min-width: 375px) {
    svg {
      width: 61px;
      height: 47px;
      margin-top: 10px;
    }
  }
`;

const DropZoneWrapper = styled.div`
  height: 150px;
  border: 2px solid ${themeProp("colors.gray.300")};
  border-radius: ${themeProp("borderRadii.medium")}px;
`;

const StyledResponsivePeechLibrary = styled(ResponsivePeechLibrary)`
  padding: 0;
`;

export default function MobileUploader() {
  const { createUploader } = useUploaderContext();
  const dispatch = useDispatch<Dispatch>();
  const assets = useSelector((state: RootState) => assetSelectors.selectAllByType(state, AssetType.Source));

  const createAssetPromise = useCallback(
    async (file: File) => {
      const input: CreateAssetMutationVariables = { name: file.name, type: AssetType.Source };
      const asset = await apiClient.createAsset(input);
      await createUploader(asset.sid!, file, UploaderTypes.ASSET);
      return dispatch.assets.upsertOne(asset);
    },
    [createUploader, dispatch.assets],
  );
  const handleDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const promises = acceptedFiles.map(createAssetPromise);
      Promise.all(promises)
        .then()
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    [createAssetPromise],
  );
  const [showDragging, setShowDragging] = useState(false);

  return (
    <Container>
      <DropZoneWrapper>
        <Dropzone
          onDrop={handleDrop}
          onDragStateChange={setShowDragging}
          accept={{
            "video/*": [],
          }}
        >
          <UploadButtonContainer>
            <span>
              <Text1Bold>Upload video content</Text1Bold>
            </span>
            <span>
              <Text1Thin>click to select your files</Text1Thin>
            </span>
            <IconContainer showDragging={showDragging}>
              <Icon.UploadContent />
            </IconContainer>
          </UploadButtonContainer>
        </Dropzone>
      </DropZoneWrapper>
      <Scroll marginTop={20}>
        <StyledResponsivePeechLibrary>
          {assets?.map((asset) => (
            <UploadItemMobile key={asset.sid} id={asset.sid!} />
          ))}
        </StyledResponsivePeechLibrary>
      </Scroll>
    </Container>
  );
}
