import { createGlobalStyle } from "styled-components/macro";

import { SelectGlobalStyle } from "src/components/common/form/inputs/Select.styled";
import { themeColor } from "src/utils/styledComponents.utils";

const AppGlobalStyles = createGlobalStyle`
  html,
  body,
  #root {
    margin: 0;
    height: 100%;
    overflow: hidden;
  }

  * {
    box-sizing: border-box;
    font-family: "Work Sans", sans-serif, "Open Sans";
  }

  iframe {
    border: none;
  }
  
  /* external library override start */
  .hbspt-form {
    position: fixed;
    inset: 0;
    z-index: 999999;
  }

  .calendly-overlay .calendly-popup {
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 80%;
    min-width: 900px;
    max-width: 1000px;
    height: 90%;
    max-height: 710px;
    background-color: #fff;
  }
  
  .intercom-launcher {
    transition: transform 167ms cubic-bezier(0.33, 0, 0, 1), bottom 250ms ease-out !important;
  }
  /* external library override end */

  ${SelectGlobalStyle}


   // override react-rnd handle styles
   .re-cropper-resizable-handle {
     .corner {
      background-color: #fff;
      border: 1px solid ${themeColor("pink.500")};
      border-radius: 50%; ;
      width: 10px !important;
      height: 10px !important;
      z-index: 1;

      transition: background-color 250ms ease-out;
      &:hover {
        background-color: ${themeColor("pink.500")};
      }
    }

    .t-l {
      left: -5px !important;
      top: -5px !important;
      cursor: nwse-resize !important;
    }

    .t-r {
      right: -5px !important;
      top: -5px !important;
      cursor: nesw-resize !important;
    }

    .b-l {
      left: -5px !important;
      bottom: -5px !important;
      cursor: nesw-resize !important;
    }
    .b-r {
      right: -5px !important;
      bottom: -5px !important;
      cursor: nwse-resize !important;
    }
  }
`;

export default AppGlobalStyles;
