import BaseUploadService, { FileUploadStatus, IUploadOptions } from "./BaseUpload.service";

export interface ILocalAssetUploadOptions extends IUploadOptions {
  footageId: string;
  authToken: string;
  uploadUrl: string;
}

export default class UploadToLocalService extends BaseUploadService {
  private readonly footageId: string;
  private readonly authToken: string;
  private readonly uploadUrl: string;

  constructor(file: File, uploadOptions: ILocalAssetUploadOptions) {
    super(file, uploadOptions as IUploadOptions);
    this.authToken = uploadOptions.authToken;
    this.footageId = uploadOptions.footageId;
    this.uploadUrl = uploadOptions.uploadUrl;
  }

  async start() {
    super.start();
    await fetch(this.uploadUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        footageId: this.footageId,
        filename: this.file.name!,
      }),
    });

    this.status = FileUploadStatus.UPLOADING;
  }
}
