import styled, { css, FlattenSimpleInterpolation } from "styled-components/macro";

import { Size } from "src/theme";
import { ifProp, themeColor, themeProp } from "src/utils/styledComponents.utils";

const sizeStyles = {
  medium: css`
    padding: 8px 10px;
  `,

  large: css`
    padding: 15px 10px;
  `,
} satisfies Partial<Record<Size, FlattenSimpleInterpolation>>;

export interface InputProps {
  isError?: boolean;
  inputSize: keyof typeof sizeStyles;
}

export const inputStyle = css<InputProps>`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${themeColor("gray.500")};

  border: 1px solid ${themeColor("gray.400")};
  border-radius: ${themeProp("borderRadii.small")}px;
  background-color: #fff;

  transition: color, background-color, border-color;
  transition-duration: 200ms;
  transition-timing-function: ease-out;

  &:hover {
    border-color: ${themeColor("gray.500")};
  }

  &:focus,
  &:focus-within {
    color: #000;
    border-color: ${themeColor("blue.500")};
  }

  &:hover,
  &:focus,
  &:focus-within {
    background-color: ${themeColor("gray.200")};
  }

  ${ifProp(
    "isError",
    css<InputProps>`
      border-color: ${themeColor("status.error")} !important;
    `,
  )}
`;

export const Input = styled.input<InputProps>`
  display: block;
  width: 100%;
  outline: none;

  ${({ inputSize }) => sizeStyles[inputSize]}
  ${inputStyle}
  
  ::placeholder {
    color: ${themeColor("gray.400")};
  }
`;

export default Input;
