import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useGetOldAppLink } from "src/hooks/useOldAppRedirect";
import { absoluteRoutes } from "src/utils/routes.utils";

export default function BillingPage() {
  const navigate = useNavigate();
  const getOldAppLink = useGetOldAppLink();

  useEffect(() => {
    const onMessage = (event: MessageEvent) => {
      if (event.data === "OLD_APP_IFRAME_UPGRADE_BUTTON_CLICK") {
        navigate(absoluteRoutes.plans);
      }
    };
    window.addEventListener("message", onMessage);
    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, [navigate]);

  return (
    <>
      <Helmet>
        <title>Billing | Peech - Automated Talk-Videos Creator</title>
      </Helmet>
      <iframe title="billing" src={getOldAppLink("/billing")} width="100%" height="100%" />
    </>
  );
}
