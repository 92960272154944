import { PropsWithChildren, useEffect } from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import styled from "styled-components/macro";

const DropzoneContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
`;

interface DropzoneProps extends PropsWithChildren, DropzoneOptions {
  onDragStateChange?(isDragging: boolean): void;
  onClick?(): void;
}

export default function Dropzone({ children, onDragStateChange, onClick, ...dropzoneOptions }: DropzoneProps) {
  const { getRootProps, getInputProps, isDragActive: isDragging } = useDropzone(dropzoneOptions);

  useEffect(() => {
    onDragStateChange?.(isDragging);
  }, [isDragging, onDragStateChange]);

  return onClick ? (
    <DropzoneContainer
      {...getRootProps()}
      onClick={(e: MouseEvent) => {
        e.preventDefault();
        onClick();
      }}
    >
      {children}
    </DropzoneContainer>
  ) : (
    <DropzoneContainer {...getRootProps()}>
      <input {...getInputProps()} />
      {children}
    </DropzoneContainer>
  );
}
