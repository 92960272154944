import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import userEngagementService from "src/services/UserEngagement.service";
import useSequenceVideoEditor from "src/components/features/VideoTrimmer/common/hooks/useInitSequenceVideoEditor";
import VideoCropper from "src/components/features/VideoCropper/VideoCropper";
import { Dispatch, RootState } from "src/models/store";
import { sequenceSelectors } from "src/models/Sequence.model";
import { cropSelectors } from "src/models/Crop.model";
import { cropTimeIntervalsToAssetCropData } from "src/utils/cropTimeInterval.utils";
import { timeIntervalUtils } from "src/utils/timeInterval.utils";
import { absoluteRoutes, applyPathParams } from "src/utils/routes.utils";
import { SequenceInteraction } from "src/network/graphql/generatedGraphqlSDK";
import apiClient from "src/network/ApiClient";
import { CropperHistory } from "src/types/video-cropper.types";
import Color from "color";
import { Helmet } from "react-helmet";
import { SaveStatus } from "src/models/Session.model";
import { useUnmountEffect } from "src/hooks/useUnmountEffect";

export default function SequenceCropEditorPage() {
  const navigate = useNavigate();
  const { sequenceSid } = useParams();
  const dispatch = useDispatch<Dispatch>();

  if (!sequenceSid) {
    throw new Error("sequenceSid is required");
  }
  const sequence = useSelector((state: RootState) => sequenceSelectors.selectById(state, sequenceSid));
  const assetsDomains = useSelector((state: RootState) => cropSelectors.selectAssetsDomains(state, sequenceSid));
  const saveStatus = useSelector((state: RootState) => state.session?.saveStatus);
  const sequenceVideoCropperProps = useSequenceVideoEditor(sequenceSid);
  const { cropsHistory } = sequenceVideoCropperProps;
  const useFootageForCrop = useSelector((state: RootState) =>
    sequenceSelectors.selectUseFootageForCrop(state, sequenceSid ?? ""),
  );

  // validate the footage useFootageForCrop if not redirect to content editor
  useEffect(() => {
    if (!useFootageForCrop) {
      navigate(applyPathParams(absoluteRoutes.projectSequence.children.contentEditor, { sequenceSid }));
    }
  }, [useFootageForCrop, navigate, sequenceSid]);

  useEffect(() => {
    userEngagementService.setLauncherPadding({ verticalPadding: 205 });

    return () => {
      userEngagementService.setLauncherPadding({ verticalPadding: 0 });
    };
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log("[cc] SequenceCropEditor page", { saveStatus });

    if (saveStatus === SaveStatus.SAVED) {
      // eslint-disable-next-line no-console
      console.log("[cc] SequenceCropEditor page saveStatus => PENDING");
      dispatch.session.setSaveStatus(SaveStatus.PENDING);
    }
  }, [saveStatus, dispatch.session]);

  useUnmountEffect(async () => {
    dispatch.session.setSaveStatus(SaveStatus.SAVING);
    if (cropsHistory.canUndo && cropsHistory.state) {
      // we update crop details for each asset
      const { crops, style } = cropsHistory.state as CropperHistory;
      await dispatch.crop.updateCropStyle({
        ...style,
        color: Color(style.color).hex() || "#000000",
        blur: !!style.blur,
        externalSequenceSid: sequenceSid,
      });

      for (const assetDomain of assetsDomains) {
        const assetDomainCropIntervals = crops.filter((cropTimeInterval) =>
          timeIntervalUtils.containsTimeInterval(assetDomain.domain, cropTimeInterval),
        );
        await dispatch.crop.updateCropDetails({
          externalSequenceSid: sequenceSid,
          externalAssetSid: assetDomain.assetSid,
          outputAspectRatio: sequence?.aspectRatio!,
          cropData: cropTimeIntervalsToAssetCropData(assetDomainCropIntervals, assetDomain.domain),
        });
      }
    }

    if (cropsHistory.canUndo) {
      await dispatch.footage.interactClipSequence({
        footageId: sequence?.footageSid as string,
        externalSequenceId: sequence?.sid as string,
        sequenceInteraction: "edited" as SequenceInteraction,
      });

      try {
        await apiClient.updateSequence({
          sid: sequence?.sid as string,
          values: {
            updateTimeStamp: Math.floor(Date.now() / 1000),
          },
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }
    dispatch.session.setSaveStatus(SaveStatus.SAVED);
  });

  return (
    <>
      <Helmet>
        <title>{sequence?.title} | Peech - Automated Talk-Videos Creator</title>
      </Helmet>
      <VideoCropper {...sequenceVideoCropperProps} />
    </>
  );
}
