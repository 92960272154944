import { components, SingleValueProps } from "react-select";
import { Stack } from "src/components/common/layout/Stack.styled";
import styled from "styled-components/macro";
import { BoardOption } from "src/components/features/Boards/BoardSelectOption";
import theme from "src/theme";
import { Text3 } from "src/components/common/layout/typography.styled";
import { isMobile } from "src/utils/mobile.utils";

const StyledSelectStack = styled(Stack)`
  align-items: center;
  height: 26px;
  color: ${theme.colors.gray["900"]};
`;

const StyledText = styled(Text3)`
  font-family: "Open Sans";
  font-size: 14px;
  color: ${theme.colors.gray["900"]};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export default function BoardSelectSingleValue({ selectProps, ...props }: SingleValueProps<BoardOption>) {
  const { getValue, hasValue, children } = props;
  const [boardOption] = getValue();

  return (
    <components.SingleValue {...props} selectProps={selectProps}>
      <StyledSelectStack direction="row" spacing={12}>
        {hasValue ? <StyledText>{!isMobile() && boardOption.name}</StyledText> : children}
      </StyledSelectStack>
    </components.SingleValue>
  );
}
