import { FormStepDescriptor, InferStepPathUnion } from "common-form-types";

import BrandKitStep from "src/components/features/AutomaticCreateWizard/steps/BrandKitStep";
import FinishStep from "src/components/features/AutomaticCreateWizard/steps/FinishStep";
import UploadingStep from "src/components/features/AutomaticCreateWizard/steps/UploadingStep";
import { STEPS } from "src/components/features/AutomaticCreateWizard/automaticCreate.constants";

const steps = [
  {
    path: STEPS.UPLOADING,
    component: UploadingStep,
  },
  {
    path: STEPS.BRAND_KIT,
    component: BrandKitStep,
  },
  {
    path: STEPS.FINISH,
    component: FinishStep,
  },
] as const satisfies ReadonlyArray<FormStepDescriptor>;

export type StepPath = InferStepPathUnion<typeof steps>;

export default steps;
