import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { GoogleLogin as ReactGoogleLogin, CredentialResponse, GoogleLoginProps } from "@react-oauth/google";

import { Dispatch } from "src/models/store";

import { getQuery } from "src/utils/urlQuery.utils";

import { useMediaQuery } from "src/hooks/useMediaQuery";

const signupCampaign = getQuery(window.location.search, "signupCampaign");

export default function GoogleLogin(props: Omit<GoogleLoginProps, "onSuccess">) {
  const dispatch = useDispatch<Dispatch>();
  const isMobile = useMediaQuery("(max-width: 768px)");

  const onPeechGoogleLoginSuccess = useCallback(
    ({ credential }: CredentialResponse) => {
      if (credential) {
        dispatch.users.loginUserOAuth({
          provider: "google",
          providerToken: credential,
          signupCampaign,
        });
      }
    },
    [dispatch],
  );

  return (
    <ReactGoogleLogin
      onSuccess={onPeechGoogleLoginSuccess}
      width={isMobile ? "280px" : "320px"}
      size="large"
      type="standard"
      theme="outline"
      shape="rectangular"
      useOneTap
      {...props}
    />
  );
}
