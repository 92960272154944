import { Fragment, Key } from "react";

import styled from "styled-components/macro";

import { useSwiperInnerScrolling } from "src/components/common/Swiper/hooks/useSwiperInnerScrolling";

import {
  AppearAnimatedDiv,
  HeaderText,
  SubHeaderText,
} from "src/components/features/AutomaticCreateWizard/common/automatic-create-wizard-layout.styled";
import {
  VideoEditRule,
  useVideoEditRulesTiles,
} from "src/components/features/AutomaticCreateWizard/subSteps/SORStep/useVideoEditRulesTiles";
import EditRulesButton from "src/components/common/buttons/EditRulesButton";
import { FormContextInterface } from "src/components/features/AutomaticCreateWizard/FormContext.interface";

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  flex: 1;
  margin-block: 20px;
  @media (min-width: 768px) {
    margin-top: 54px;
  }
`;

const RulesContainer = styled(AppearAnimatedDiv)`
  display: grid;
  grid-template-columns: auto auto;
  width: fit-content;
  height: 752px;
  overflow-y: auto;
  margin: 8px;

  @media (min-width: 768px) {
    margin: 20px;
    grid-template-columns: auto auto auto auto;
    height: 376px;
  }
`;

export default function BrandSORs({ formContext }: FormContextInterface) {
  const videoEditRulesTiles = useVideoEditRulesTiles();
  const { setValue, watch } = formContext;

  const currentEditRules = watch("brandKit.SORs.editRules");

  const onRuleTileClick = (rule: keyof NonNullable<typeof currentEditRules>) => {
    if (currentEditRules && !videoEditRulesTiles[rule].disabled) {
      setValue(
        "brandKit.SORs.editRules",
        { ...currentEditRules, [rule]: !currentEditRules[rule] },
        { shouldValidate: true },
      );
    }
  };

  return (
    <Fragment>
      <HeaderText>Which visuals do you want Peech to add to your video?</HeaderText>
      <SubHeaderText>You can easily tweak it later </SubHeaderText>
      <ContentWrapper>
        <RulesContainer {...useSwiperInnerScrolling()}>
          {(Object.keys(videoEditRulesTiles) as VideoEditRule[]).map((rule) => (
            <EditRulesButton
              key={rule as Key}
              disabledFeature={videoEditRulesTiles[rule].disabled}
              isSelected={!!currentEditRules?.[rule as keyof NonNullable<typeof currentEditRules>]}
              isLoading={false}
              onSelect={() => onRuleTileClick(rule as keyof NonNullable<typeof currentEditRules>)}
              rule={rule as keyof NonNullable<typeof currentEditRules>}
              name={videoEditRulesTiles[rule].elementName}
            />
          ))}
        </RulesContainer>
      </ContentWrapper>
    </Fragment>
  );
}
