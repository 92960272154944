import { useEffect, useState } from "react";

import apiClient from "src/network/ApiClient";

interface MediaSourceProps {
  src?: string | null;
  onLoadStart?(): void;
  onLoadEnd?(): void;
  children(resolvedSource: string): JSX.Element;
  placeholder?: JSX.Element;
}

export default function MediaSource({ src, onLoadStart, onLoadEnd, children, placeholder }: MediaSourceProps) {
  const [resolvedSource, setResolvedSource] = useState<string | null>(null);

  useEffect(
    () => {
      if (src) {
        onLoadStart?.();
        apiClient.httpClient
          .get(src, { responseType: "blob" })
          .then((res) => setResolvedSource(URL.createObjectURL(res.data)))
          .then(onLoadEnd);
      }

      return () => {
        setResolvedSource(null);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [src],
  );

  return resolvedSource ? children(resolvedSource) : placeholder ?? null;
}
