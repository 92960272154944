import { createModel } from "@rematch/core";
import { createEntityAdapter } from "@reduxjs/toolkit";

import { RootState } from "src/models/store";
import { RootModel } from "src/models/index";
import { selectId } from "src/models/selectId";

import apiClient from "src/network/ApiClient";
import AudioSample from "src/types/media.types";
import { Chapter, Sequence } from "src/network/graphql/generatedGraphqlSDK";

import { ChapterStatus } from "src/constants/model.constants";

import { roundBy } from "src/utils/math.utils";

interface SequenceAudioSamples {
  sid: string;
  audioSamples: AudioSample[];
}

const sequenceAudioSamplesAdapter = createEntityAdapter<SequenceAudioSamples>({ selectId });

export const sequenceAudioSamplesSelectors = sequenceAudioSamplesAdapter.getSelectors((state: RootState) => state.sequenceAudioSamples); // prettier-ignore

const sequenceAudioSamples = createModel<RootModel>()({
  state: sequenceAudioSamplesAdapter.getInitialState(),

  reducers: {
    setSequenceAudioSamples: (state, payload: SequenceAudioSamples) =>
      sequenceAudioSamplesAdapter.setOne(state, payload),
  },

  effects: (dispatch) => ({
    // TODO: save samples by chapter id instead of sequence id
    // TODO: move all offsetting logic to a selector
    // TODO: we need to find an alternative way to determine if a chapter has no verbal content instead of using the "mute" property
    //  because muted chapters can still have verbal content
    async fetchSequenceAudioSamples(sequence: Sequence) {
      let currentOffsetInSeconds = 0;
      const statusFilter = [ChapterStatus.Ready, ChapterStatus.Pending];
      const chapters = (sequence.chapters ?? []) as Chapter[];
      const sourceChapters = chapters
        .filter((c) => !c.linkSid && !c.cuePointType && statusFilter.includes(c.status!))
        .map((chapter) => {
          const ret = {
            chapter,
            offsetInSeconds: currentOffsetInSeconds,
          };

          currentOffsetInSeconds += chapter.srcDuration!;

          return ret;
        });

      const unmutedSourceChapters = sourceChapters.filter(({ chapter }) => !chapter.mute && chapter.audio?.sid);
      const promises = unmutedSourceChapters.map(({ chapter, offsetInSeconds }) =>
        apiClient.getChapterAudioSamplesRMS(sequence.sid!, chapter).then((chapterSampleList) =>
          chapterSampleList.map((sample) => ({
            ...sample,
            timestamp: roundBy(100, sample.timestamp + offsetInSeconds * 1000),
          })),
        ),
      );

      const chapterAudioSampleLists = await Promise.all(promises);
      const audioSamples = chapterAudioSampleLists.flat();

      dispatch.sequenceAudioSamples.setSequenceAudioSamples({ sid: sequence.sid!, audioSamples });
    },
  }),
});

export default sequenceAudioSamples;
