import { range } from "lodash/fp";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { assetSelectors } from "src/models/Asset.model";
import { Dispatch, RootState } from "src/models/store";

import { AssetStatus, AssetType } from "src/constants/model.constants";
import styled from "styled-components/macro";

import useEndScrollHandler from "src/hooks/useEndScrollHandler";

import LoadingPlug from "src/components/common/loaders/LoadingPlug";
import ResponsivePeechLibrary from "src/components/common/ResponsiveLibrary/ResponsivePeechLibrary";
import { useSwiperInnerScrolling } from "src/components/common/Swiper/hooks/useSwiperInnerScrolling";

import {
  GridScroll,
  HeaderText,
  StepContainer,
  SubHeaderText,
} from "src/components/features/CreateSequenceWizard/common/create-sequence-wizard-layout.styled";
import AssetItemContent from "src/components/features/CreateSequenceWizard/steps/FootageStep/AssetItemContent";
import { AspectRatio } from "src/constants/video.constants";
import { FormContextInterface } from "src/components/features/AutomaticCreateWizard/FormContext.interface";

const LibBody = styled.div`
  padding: 20px 0 0 20px;
`;

export const PAGE_SIZE = 200;
export const ASPECT_RATIOS: AspectRatio[] = ["16:9", "1:1", "9:16"];
export const DEFAULT_ASPECT_RATIO = ASPECT_RATIOS[0];

export default function FootageStep({ formContext }: FormContextInterface) {
  const dispatch = useDispatch<Dispatch>();
  // not filtered by published
  const totalAssetIncludeNotPublished = useSelector((state: RootState) =>
    assetSelectors.selectAllByTypeAndStatus(state, AssetType.Source, AssetStatus.Ready),
  ).length;
  const assets = useSelector((state: RootState) =>
    assetSelectors.selectAllByTypeAndStatusAndNotPublished(state, AssetType.Source, AssetStatus.Ready),
  );

  const { watch, setValue } = formContext;
  const selectedAssetsSidArray = watch("footage.footageSidArray");
  const containerRef = useRef<HTMLDivElement>(null);
  const [shouldFetchMore, setShouldFetchMore] = useState(true);
  const assetsLoading = useSelector((state: RootState) => state.loading.effects.assets.fetchAssets.loading);

  const selectAsset = useCallback(
    async (assetSid: string) => {
      const updatedAssets = Array.isArray(selectedAssetsSidArray) ? [...selectedAssetsSidArray] : [];
      if (updatedAssets.includes(assetSid)) {
        const index = updatedAssets.indexOf(assetSid);
        updatedAssets.splice(index, 1);
      } else if (updatedAssets.length < 5) {
        updatedAssets.push(assetSid);
      }

      setValue("footage.footageSidArray", updatedAssets, { shouldValidate: true });
    },
    [selectedAssetsSidArray, setValue],
  );

  const getAssets = async () => {
    if (assetsLoading || !shouldFetchMore) {
      return;
    }

    const res = await dispatch.assets.fetchAssets({
      filter: {
        typeIn: [AssetType.Source],
        statusEqual: AssetStatus.Ready,
        footageSidIsNull: false,
      },
      pager: {
        pageSize: PAGE_SIZE,
        pageIndex: Math.floor(totalAssetIncludeNotPublished / PAGE_SIZE) + 1,
      },
    });

    if (res.length < PAGE_SIZE) {
      setShouldFetchMore(false);
    }
  };

  useEffect(() => {
    getAssets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEndScrollHandler(containerRef, async () => {
    if (shouldFetchMore) {
      // eslint-disable-next-line no-console
      console.log("fetch more assets");
      getAssets();
    }
  });

  const isAssetSelected = useCallback(
    (assetSid: string) => {
      if (selectedAssetsSidArray && selectedAssetsSidArray.length) {
        return [...(selectedAssetsSidArray ?? [])].some((x) => x === assetSid);
      }
      return false;
    },
    [selectedAssetsSidArray],
  );

  const getSelectedIndex = useCallback(
    (assetSid: string) => selectedAssetsSidArray?.findIndex((x) => x === assetSid),
    [selectedAssetsSidArray],
  );

  const libItems = useMemo(
    () =>
      [...assets].map((asset) => (
        <AssetItemContent
          key={asset.sid}
          sid={asset.sid!}
          aspectRatio={DEFAULT_ASPECT_RATIO}
          onItemSelected={selectAsset}
          isItemSelected={isAssetSelected}
          getSelectedIndex={getSelectedIndex}
        />
      )),
    [assets, isAssetSelected, selectAsset, getSelectedIndex],
  );

  return (
    <StepContainer>
      <GridScroll {...useSwiperInnerScrolling()} ref={containerRef}>
        <HeaderText>Choose the footage for your video</HeaderText>
        <SubHeaderText>The footage will be combined into one video</SubHeaderText>
        <LibBody>
          <ResponsivePeechLibrary horizontalGutter={20}>
            {libItems}
            {assetsLoading &&
              range(0, 10).map((x) =>
                ASPECT_RATIOS.map((asRat) => (
                  <LoadingPlug key={x + asRat} verticalGutter={16} aspectRatio={DEFAULT_ASPECT_RATIO} />
                )),
              )}
          </ResponsivePeechLibrary>
        </LibBody>
      </GridScroll>
    </StepContainer>
  );
}
