/* eslint-disable no-nested-ternary */

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled, { css } from "styled-components/macro";

import { ifProp, themeColor } from "src/utils/styledComponents.utils";
import { timeIntervalUtils } from "src/utils/timeInterval.utils";

import { KEY_FRAME_MAX_DURATION } from "src/constants/video-trimmer.constants";

import { TimeInterval } from "src/types/video-trimmer.types";

import useMousetrap, { useMousetrapTarget } from "src/hooks/useMousetrap";

import Icon from "src/components/common/Icon";
import Popper from "src/components/common/popovers/Popper";
import { Stack } from "src/components/common/layout/Stack.styled";
import { useVideoChapters } from "src/components/features/VideoTrimmer/providers/VideoChaptersProvider/VideoChaptersContext";
import { chapterTimeIntervalUtils } from "src/components/features/VideoTrimmer/common/chapterTimeInterval.utils";
import { useVideoTranscript } from "src/components/features/VideoTrimmer/providers/VideoTranscriptProvider/VideoTranscriptContext";
import useUndoRedo from "src/hooks/useUndoRedo";
import { useVideoWords } from "src/components/features/VideoTrimmer/providers/VideoWordsProvider/VideoWordsContext";
import { ShortcutsKeys, getShortcutKeyLabel } from "src/constants/shortcuts.constants";
import ActionButton from "src/components/features/VideoTrimmer/TimelineActionButtons/ActionButton";

const Container = styled(Stack)`
  align-items: center;
  height: 32px;
  flex: 1;
`;

const RightSideButtonsContainer = styled.div`
  display: flex;
  margin-left: auto;
  flex-direction: row;
  gap: 8px;
`;

const Divider = styled.div`
  width: 1px;
  height: 18px;
  border-radius: 4px;
  background-color: ${themeColor("gray.300")};
`;

const StyledArrowDown = styled(Icon.ArrowDown)<{ $isOpen: boolean }>`
  transform: rotate(${ifProp("$isOpen", "180deg", "0deg")});
  transition: transform 0.2s ease-out;
`;

const Menu = styled.div`
  min-width: 110px;
  padding-block: 5px;
  border-radius: 5px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  background-color: ${themeColor("white")};
`;

interface OptionProps {
  disabled: boolean;
  hide?: boolean;
}

const Option = styled.div<OptionProps>`
  display: ${ifProp("hide", "none", "flex")};
  padding: 5px 8px;
  font-size: 12px;
  line-height: 17px;
  font-weight: 400;
  color: ${themeColor("gray.500")};
  font-family: "Open Sans", sans-serif;
  cursor: pointer;

  &:hover {
    background-color: #f2f2f2ff; // TODO: new color ?? #f2f2f2ff
  }

  ${ifProp(
    "disabled",
    css<OptionProps>`
      color: ${themeColor("gray.300")};
      cursor: not-allowed;
      pointer-events: none;
    `,
  )}
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 3px 5px;
  background-color: #fff;
`;

const SearchInputContainer = styled.div`
  display: flex;
  flex: 1;
  max-width: 150px;
`;

const SearchInput = styled.input`
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: ${themeColor("gray.900")};

  ::placeholder {
    color: ${themeColor("gray.300")};
  }
`;

const SearchDivider = styled.div`
  width: 1px;
  max-height: 20px;
  height: 20px;
  background-color: ${themeColor("gray.300")};
  margin-inline: 5px;
`;

const CurrentResultLabel = styled.div`
  width: 60px;
  color: ${themeColor("gray.300")};
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  text-align: center;
`;

const PrevResultButton = styled(Icon.Up)`
  cursor: pointer;
  margin-right: 5px;
  color: ${themeColor("gray.300")};
  border-radius: 4px;
  transition: all 350ms ease-out;

  &:hover {
    color: ${themeColor("blue.500")};
  }
`;

const NextResultButton = styled(PrevResultButton)`
  rotate: 180deg;
`;

const CancelSearchButton = styled(Icon.Close)`
  cursor: pointer;
  width: 20px;
  height: 20px;
  color: ${themeColor("gray.300")};
  border-radius: 4px;
  transition: all 350ms ease-out;

  path {
    fill: ${themeColor("gray.300")};
  }

  &:hover {
    path {
      fill: ${themeColor("blue.500")};
    }
  }
`;

interface TranscriptActionsProps {
  analyticsActions: any; // Replace 'any' with the actual type if possible
}

export type TranscriptAction = "toggleBlanks" | "toggleFillerWords" | "removeAllHighlightedWords";

export default function ClipCreateTranscriptActions({ analyticsActions }: TranscriptActionsProps) {
  const {
    selectionTimeInterval,
    searchQuery,
    searchResults,
    onSearchQueryChange,
    focusedSearchResultIndex,
    setFocusedSearchResultIndex,
    setHoveredTranscriptActionButton,
    cancelSelection,
  } = useVideoTranscript();
  const {
    chapters,
    subtractTimeIntervals,
    addTimeIntervals,
    allowedActions: chaptersAllowedActions,
  } = useVideoChapters();
  const { words, blanks, fillerWords } = useVideoWords();
  const [isOpen, setIsOpen] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const inputMousetrap = useMousetrapTarget(inputRef);

  const lineItems = useMemo(() => (words as TimeInterval[]).concat(blanks), [words, blanks]);
  const onHoveredOptionChange = setHoveredTranscriptActionButton;
  const { onUndo, onRedo, canUndo, canRedo } = useUndoRedo();

  const remainingBlanks = useMemo(
    () =>
      blanks.filter((blank) =>
        chapters.some((chapter) => {
          const shrunkBlank = timeIntervalUtils.unpad(blank, KEY_FRAME_MAX_DURATION);
          return timeIntervalUtils.intersectsTimeInterval(chapter, shrunkBlank);
        }),
      ),
    [blanks, chapters],
  );

  const remainingFillerWords = useMemo(
    () =>
      fillerWords.filter((fillerWord) =>
        chapters.some((chapter) => {
          const shrunkFillerWord = timeIntervalUtils.unpad(fillerWord, KEY_FRAME_MAX_DURATION);
          return timeIntervalUtils.intersectsTimeInterval(chapter, shrunkFillerWord);
        }),
      ),
    [fillerWords, chapters],
  );

  const fillerWordsIntersectingMultipleChapters = useMemo(
    () => timeIntervalUtils.intervalsIntersectingMultipleSelections(words, chapters, fillerWords),
    [chapters, fillerWords, words],
  );

  const blanksIntersectingMultipleChapters = useMemo(
    () => timeIntervalUtils.intervalsIntersectingMultipleSelections(words, chapters, blanks),
    [blanks, chapters, words],
  );

  const onToggleBlanks = useCallback(() => {
    if (remainingBlanks.length) {
      subtractTimeIntervals(blanks);
      analyticsActions?.trackRemove?.("all-blanks");
    } else {
      addTimeIntervals(blanksIntersectingMultipleChapters);
      analyticsActions?.trackRevert?.("all-blanks");
    }
  }, [
    remainingBlanks.length,
    subtractTimeIntervals,
    blanks,
    analyticsActions,
    addTimeIntervals,
    blanksIntersectingMultipleChapters,
  ]);

  const onToggleFillerWords = useCallback(() => {
    if (remainingFillerWords.length) {
      const fillersNoGaps = fillerWords.map((fw) =>
        chapterTimeIntervalUtils.removeGapsTimeInterval(fw, chapters, lineItems),
      );
      subtractTimeIntervals(fillersNoGaps);
      analyticsActions?.trackRemove?.("all-filler-words");
    } else {
      const fillersNoGaps = fillerWordsIntersectingMultipleChapters.map((fw) =>
        chapterTimeIntervalUtils.removeGapsTimeInterval(fw, chapters, lineItems),
      );
      addTimeIntervals(fillersNoGaps);
      analyticsActions?.trackRevert?.("all-filler-words");
    }
  }, [
    remainingFillerWords.length,
    fillerWords,
    subtractTimeIntervals,
    analyticsActions,
    chapters,
    lineItems,
    fillerWordsIntersectingMultipleChapters,
    addTimeIntervals,
  ]);

  const areAllSearchResultsRemoved = useMemo(
    () => searchResults.every((result) => !chapters.some((c) => timeIntervalUtils.intersectsTimeInterval(c, result))),
    [chapters, searchResults],
  );

  // remove or revert all search results
  const onToggleSearchResult = useCallback(() => {
    const searchResultsWithoutGaps = searchResults.map((result) =>
      chapterTimeIntervalUtils.removeGapsTimeInterval(result, chapters, lineItems),
    );
    if (areAllSearchResultsRemoved) {
      addTimeIntervals(searchResultsWithoutGaps);
      analyticsActions?.trackRevert?.("search-results");
    } else {
      subtractTimeIntervals(searchResultsWithoutGaps);
      analyticsActions?.trackRemove?.("search-results", { searchQuery });
    }
  }, [
    areAllSearchResultsRemoved,
    addTimeIntervals,
    subtractTimeIntervals,
    searchResults,
    chapters,
    lineItems,
    searchQuery,
    analyticsActions,
  ]);

  useEffect(() => {
    if (!isOpen) {
      onHoveredOptionChange(null);
    }
  }, [isOpen, onHoveredOptionChange]);

  const isKeepOnlyEnabled = useMemo(() => {
    if (!selectionTimeInterval) return false;

    const contains = chapters.some((c) => timeIntervalUtils.containsTimeInterval(selectionTimeInterval, c));
    const equals = chapters.some((c) => timeIntervalUtils.equals(selectionTimeInterval, c));
    const currentChapterConnectedToSelection = chapters.find((c) =>
      timeIntervalUtils.includesTimePoint(c.domain, selectionTimeInterval.start),
    );
    const hasAnotherActiveWordsInSameDomain =
      chapters.filter((c) => c.domain.start === currentChapterConnectedToSelection?.domain.start).length > 1;

    return !contains || !equals || hasAnotherActiveWordsInSameDomain;
  }, [chapters, selectionTimeInterval]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const keepOnlySelectedWords = () => {
    if (!selectionTimeInterval) return;

    const startChapter = chapters.find((c) => timeIntervalUtils.includesTimePoint(c.domain, selectionTimeInterval.start)); // prettier-ignore
    const endChapter = chapters.find((c) => timeIntervalUtils.includesTimePoint(c.domain, selectionTimeInterval.end));

    if (!startChapter || !endChapter) return;

    const { start } = startChapter.domain;
    const { end } = endChapter.domain;

    addTimeIntervals([selectionTimeInterval]);
    subtractTimeIntervals(timeIntervalUtils.invertTimeIntervals([selectionTimeInterval], 0, { start, end }));
    cancelSelection();
    analyticsActions?.trackKeepOnly?.();
  };

  const onPrev = useCallback(() => {
    setFocusedSearchResultIndex((focusedSearchResultIndex - 1 + searchResults.length) % searchResults.length); // prettier-ignore
  }, [focusedSearchResultIndex, setFocusedSearchResultIndex, searchResults.length]);

  const onNext = useCallback(() => {
    setFocusedSearchResultIndex((focusedSearchResultIndex + 1) % searchResults.length);
  }, [focusedSearchResultIndex, setFocusedSearchResultIndex, searchResults.length]);

  const onCancel = useCallback(() => {
    onSearchQueryChange("");
    inputRef.current?.blur();
  }, [onSearchQueryChange]);

  useMousetrap("mod+f", () => inputRef.current?.focus(), { preventDefault: true });
  useMousetrap("shift+enter", onPrev, { target: inputMousetrap });
  useMousetrap("enter", onNext, { target: inputMousetrap });
  useMousetrap("escape", onCancel, { target: inputMousetrap });

  return (
    <Container direction="row" spacing={12}>
      {chaptersAllowedActions?.addChapter?.isAllowed && (
        <>
          <ActionButton disabled={!isKeepOnlyEnabled} onClick={() => keepOnlySelectedWords()}>
            <Icon.KeepOnlySelection className="path-fill" size={14} />
            Keep only
          </ActionButton>

          <Divider />
        </>
      )}
      {chaptersAllowedActions?.removeChapter?.isAllowed && (
        <>
          <Popper
            isOpen={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            placement="bottom-start"
            offsetY={10}
            content={
              <Menu onClick={() => setIsOpen(false)}>
                <Option
                  disabled={!blanksIntersectingMultipleChapters.length && !remainingBlanks.length}
                  onClick={onToggleBlanks}
                  onMouseOver={() => onHoveredOptionChange("toggleBlanks")}
                  onMouseOut={() => onHoveredOptionChange(null)}
                >
                  {remainingBlanks.length ? remainingBlanks.length : blanks.length ? "Add" : "Remove"}
                  &nbsp;Quiet Breaks
                </Option>

                <Option
                  disabled={!fillerWordsIntersectingMultipleChapters.length && !remainingFillerWords.length}
                  onClick={onToggleFillerWords}
                  onMouseOver={() => onHoveredOptionChange("toggleFillerWords")}
                  onMouseOut={() => onHoveredOptionChange(null)}
                >
                  {remainingFillerWords.length ? remainingFillerWords.length : fillerWords.length ? "Add" : "Remove"}
                  &nbsp;Filler Words
                </Option>
              </Menu>
            }
          >
            <ActionButton
              onClick={() => {
                if (document.activeElement) {
                  (document.activeElement as HTMLElement).blur();
                }
              }}
            >
              <Icon.Trash size={14} />
              Remove
              <StyledArrowDown size={12} $isOpen={isOpen} />
            </ActionButton>
            {/* <Button isOpen={isOpen}></Button> */}
          </Popper>

          <Divider />
        </>
      )}
      <SearchContainer>
        <Icon.Search size={24} color="black" />

        <SearchInputContainer>
          <SearchInput
            ref={inputRef}
            className="mousetrap"
            placeholder="search"
            value={searchQuery}
            onChange={(e) => onSearchQueryChange(e.target.value)}
          />
        </SearchInputContainer>

        {!!searchQuery && focusedSearchResultIndex >= 0 && (
          <>
            <SearchDivider />

            <CurrentResultLabel>
              {searchResults.length ? focusedSearchResultIndex + 1 : 0} of {searchResults.length}
            </CurrentResultLabel>

            <SearchDivider />

            <PrevResultButton onClick={onPrev} />
            <NextResultButton onClick={onNext} />
          </>
        )}
        {!!searchQuery && focusedSearchResultIndex >= 0 && <CancelSearchButton onClick={onCancel} />}
      </SearchContainer>

      {chaptersAllowedActions?.removeChapter?.isAllowed &&
        chaptersAllowedActions?.addChapter?.isAllowed &&
        !!searchQuery &&
        focusedSearchResultIndex >= 0 && (
          <ActionButton disabled={!searchResults.length} onClick={onToggleSearchResult}>
            <Icon.Trash size={14} className="path-fill" />
            {areAllSearchResultsRemoved ? "Add" : "Remove"} All Search Results
          </ActionButton>
        )}

      <RightSideButtonsContainer>
        <ActionButton
          label={`Undo ${getShortcutKeyLabel(ShortcutsKeys.undo)}`}
          disabled={!canUndo}
          onClick={() => {
            onUndo();
            analyticsActions?.trackUndo?.();
          }}
        >
          <Icon.Undo size={10} color="black" />
          Undo
        </ActionButton>
        <ActionButton
          label={`Redo ${getShortcutKeyLabel(ShortcutsKeys.redo)}`}
          disabled={!canRedo}
          onClick={() => {
            onRedo();
            analyticsActions?.trackRedo?.();
          }}
        >
          <Icon.Redo size={10} color="black" />
          Redo
        </ActionButton>
      </RightSideButtonsContainer>
    </Container>
  );
}
