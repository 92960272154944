import { Stack } from "src/components/common/layout/Stack.styled";
import Select from "src/components/common/form/inputs/Select.styled";
import BoardsSelectOption, { BoardOption } from "src/components/features/Boards/BoardSelectOption";
import BoardSelectSingleValue from "src/components/features/Boards/BoardSelectSingleValue";
import { useCallback, useMemo } from "react";
import styled from "styled-components/macro";
import { ifProp } from "styled-tools";
import BoardSelectMenu from "src/components/features/Boards/BoardSelectMenu";
import theme from "src/theme";
import { useSelector } from "react-redux";
import { RootState } from "src/models/store";
import { boardSelectors } from "src/models/Board.model";
import { useNavigate } from "react-router-dom";
import { BoardsControl } from "src/components/features/Boards/BoardsControl";
import { isMobile } from "src/utils/mobile.utils";
import { ALL_BOARDS_ITEM_ID } from "src/constants/smartLibrary.constants";
import * as boardsAnalytics from "src/analytics/boards.analytics";
import { absoluteRoutes, applyPathParams } from "src/utils/routes.utils";

const StyledSelect = styled(Select<BoardOption, false>)<{ isLoading: boolean; isDisabled: boolean; isMobile: boolean }>`
  flex: 1;

  .select__control {
    border: none;
    border-radius: 8px;
    padding-block: 3px;
    min-height: 30px;
    width: max-content;
    cursor: ${ifProp("isDisabled", "not-allowed", "pointer")} !important;
    font-weight: 400;
    opacity: ${ifProp("isLoading", 1, 0.65)};
    transition: all 250ms ease-out;
    .select__input-container {
      font-family: "Open Sans";
      color: ${theme.colors.gray["900"]};
    }

    .select__value-container {
      padding: ${isMobile() ? "6px 4px" : "0px 8px"};
      width: max-content;
      max-width: 210px;
      align-self: start;
      bottom: 1px;
    }

    &:hover {
      background-color: ${theme.colors.blue["50"]} !important;
    }
  }

  .select__control--menu-is-open {
    .select__indicators {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .select__indicators {
    max-height: 100%;
    transition: transform 0.5s ease;
  }

  .select__dropdown-indicator {
    cursor: ${ifProp("isDisabled", "not-allowed", "pointer")};
    display: ${ifProp("isMobile", "none", "block")};
    padding-top: 3px !important;
  }

  .select__placeholder {
    font-family: "Open Sans";
    color: ${theme.colors.gray["900"]} !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .select__clear-indicator {
    padding-inline: 4px;
  }
`;

interface BoardSelectProps {
  selectedBoardId?: string | null;
}

export function BoardSelect({ selectedBoardId }: BoardSelectProps) {
  const boards = useSelector((state: RootState) => boardSelectors.selectAllSortedByName(state));
  const navigate = useNavigate();
  const boardsWithDefault = useMemo(() => [{ id: ALL_BOARDS_ITEM_ID, name: "All videos" }, ...boards], [boards]);
  const selectedBoard = useMemo(
    () => boards.find((x) => x.id === String(selectedBoardId)) || boardsWithDefault[0] || null,
    [boards, boardsWithDefault, selectedBoardId],
  );

  const onChangeBoard = useCallback(
    (boardIdValue: string | null) => {
      const boardId = boardIdValue === ALL_BOARDS_ITEM_ID ? null : boardIdValue;
      boardId
        ? navigate(applyPathParams(absoluteRoutes.platform.children.boards, { boardId } as any))
        : navigate(absoluteRoutes.platform.self);
      boardsAnalytics.trackSelectBoard(selectedBoard, "board-select");
    },
    [navigate, selectedBoard],
  );

  return (
    <Stack direction="row" spacing={10}>
      <StyledSelect
        isMobile={isMobile()}
        isSearchable={!isMobile()}
        isMulti={false}
        options={boardsWithDefault as BoardOption[]}
        value={selectedBoard as BoardOption}
        getOptionLabel={(x) => x.name!}
        getOptionValue={(x) => x.id!}
        onChange={(item) => onChangeBoard(item?.id!)}
        isDisabled={false}
        isLoading={false}
        placeholder="All videos"
        components={{
          Option: BoardsSelectOption,
          SingleValue: BoardSelectSingleValue,
          Menu: BoardSelectMenu,
          Control: BoardsControl,
        }}
      />
    </Stack>
  );
}
