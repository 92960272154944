import { createContext, Dispatch, RefCallback, RefObject, SetStateAction, useContext } from "react";
import { TimeInterval } from "src/types/video-trimmer.types";
import { TranscriptAction } from "src/components/features/VideoTrimmer/VideoTranscript/TranscriptActions";
import { TextDirection } from "src/utils/localization.utils";

export interface VideoTranscriptContextValue {
  textDirection: TextDirection;
  videoRef: RefObject<HTMLVideoElement>;
  ref: RefCallback<Element>;
  height: number;
  width: number;
  hoveredTranscriptActionButton: TranscriptAction | null;
  setHoveredTranscriptActionButton: Dispatch<SetStateAction<TranscriptAction | null>>;
  selectionTimeInterval: TimeInterval | null;
  setSearchQuery: (query: string) => void;
  focusedSearchResultIndex: number;
  setFocusedSearchResultIndex: Dispatch<SetStateAction<number>>;
  searchResults: TimeInterval[];
  searchQuery: string;
  lines: any;
  recentInteractedSelectionEdge: "start" | "end" | null;
  isSelecting: boolean;
  cancelSelection: () => void;
  transcriptSelection: any;
  onSearchQueryChange: (query: string, resultIndex?: number) => void;
}

const VideoTranscriptContext = createContext<VideoTranscriptContextValue | null>(null);

export default VideoTranscriptContext;

export const useVideoTranscript = () => {
  const contextValue = useContext(VideoTranscriptContext);

  if (!contextValue) {
    throw new Error("useVideoTranscript must be used within a VideoTranscriptProvider");
  }

  return contextValue;
};
