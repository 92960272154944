import { HTMLProps } from "react";
import styled from "styled-components/macro";

import { ifProp, themeColor } from "src/utils/styledComponents.utils";

import { Button } from "src/components/common/buttons/Button.styled";
import { useSwiperContext } from "src/components/common/Swiper/Swiper";

export const PrevSlideButton = styled(Button)`
  width: 119px;
  height: 45px;
  padding: unset;
  border: unset;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 12px;
  color: #132e59;

  svg {
    fill: #132e59;
    visibility: ${ifProp("disabled", "hidden", "visible")};
    transition: visibility 250ms ease-out, fill 250ms ease-out;
  }

  &:hover {
    svg {
      fill: ${themeColor("blue.300")};
    }
  }
`;

export default function SwiperPrevSlideButton({
  children,
  ...props
}: Pick<HTMLProps<HTMLButtonElement>, "style" | "className" | "children" | "disabled">) {
  const { allowSlidePrev, slidePrev } = useSwiperContext();

  return (
    <PrevSlideButton onClick={() => slidePrev()} disabled={!allowSlidePrev} variant="link" size="large" {...props}>
      {children}
    </PrevSlideButton>
  );
}
