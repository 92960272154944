import { FormStepDescriptor, InferStepPathUnion } from "common-form-types";
import ChooseRatioStep from "src/components/features/CreateSequenceWizard/steps/ChooseRatioStep/ChooseRatioStep";
import FinishStep from "src/components/features/AutomaticCreateWizard/steps/FinishStep";
import { STEPS } from "src/components/features/CreateSequenceWizard/CreateClipWizard";

const createClipSteps = [
  {
    path: STEPS.ASPECT_RATIO,
    component: ChooseRatioStep,
  },
  // TODO: Implement brandKit step when backend ready
  // {
  //   path: STEPS.BRAND_KIT,
  //   component: BrandKitStep,
  // },
  {
    path: STEPS.FINISH,
    component: FinishStep,
  },
] as const satisfies ReadonlyArray<FormStepDescriptor>;

export type CreateClipStepPath = InferStepPathUnion<typeof createClipSteps>;

export default createClipSteps;
