import styled from "styled-components/macro";
import { CSSTransition } from "react-transition-group";

export const CSSTransitionStyled = styled(CSSTransition)`
  opacity: 0;
  &.fade-enter {
    opacity: 0;
  }
  &.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }
  &.fade-enter-done {
    opacity: 1;
  }

  &.fade-exit,
  &.fade-exit-done {
    opacity: 0;
  }
  &.fade-exit-active {
    opacity: 0;
    transition: opacity 50ms ease-in;
  }
`;
