import apiClient from "src/network/ApiClient";
import Compressor from "compressorjs";
import { CropDataDetail } from "./cropData.utils";

export interface ExtractedImageColor {
  rgb: [number, number, number];
  grayness: number;
  percent: number;
}

declare global {
  interface Window {
    getPalette(img: HTMLImageElement, colorCount: number): ExtractedImageColor[];
  }
}

export const getImagePalette = async (imageURL: string, colorCount: number) => {
  const resolvedImageURL = await apiClient.httpClient
    .get(imageURL, { responseType: "blob" })
    .then((res) => URL.createObjectURL(res.data));

  return new Promise<ExtractedImageColor[]>((resolve) => {
    const image = new Image();

    image.src = resolvedImageURL;
    image.onload = () => resolve(window.getPalette(image, colorCount));
  });
};

export const toPng = async (content: Blob, compressorOptions?: Compressor.Options) =>
  new Promise<Blob | null>((resolve) => {
    new Compressor(content, {
      ...compressorOptions,
      checkOrientation: true,
      convertSize: Infinity,
      mimeType: "image/png",
      success: async (compressed) => {
        resolve(compressed);
      },
    });
  });

export const getImageFromVideo = async (file: File, time: number, outputSize?: { width?: number; height?: number }) =>
  new Promise<string | null>((resolve) => {
    const video = document.createElement("video");
    const fileURL = URL.createObjectURL(file);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    function onLoadedmetadata() {
      const { videoWidth, videoHeight } = video;
      const ratio = videoWidth / videoHeight;
      if (outputSize?.width || outputSize?.height) {
        // if we have width and height set as canvas size
        // if only 1 of them we will keep src aspect ratio
        canvas.width = outputSize?.width || outputSize.height! * ratio;
        canvas.height = outputSize?.height || outputSize.width! / ratio;
      } else {
        // if no outputSize set src size as outpot size
        canvas.width = videoWidth;
        canvas.height = videoHeight;
      }

      video.currentTime = time;
    }
    video.addEventListener("seeked", () => {
      const { videoWidth, videoHeight } = video;
      // get the scale
      // it is the min of the 2 ratios
      const scaleFactor = Math.min(canvas.width / videoWidth, canvas.height / videoHeight);

      // Lets get the new width and height based on the scale factor
      const newWidth = videoWidth * scaleFactor;
      const newHeight = videoHeight * scaleFactor;

      // get the top left position of the image
      // in order to center the image within the canvas
      const x = canvas.width / 2 - newWidth / 2;
      const y = canvas.height / 2 - newHeight / 2;

      // When drawing the image, we have to scale down the image
      // width and height in order to fit within the canvas
      ctx?.drawImage(video, x, y, newWidth, newHeight);
      const url = canvas.toDataURL("img/png", 0.7);
      URL.revokeObjectURL(fileURL); // release memory
      resolve(url);
    });
    video.addEventListener("loadedmetadata", onLoadedmetadata);
    video.src = fileURL;
  });

export const cropImage = async (
  fileURL: string,
  cropData: CropDataDetail,
  outputSize: { width: number; height: number },
) =>
  new Promise<string | null>((resolve) => {
    const image = new Image();
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = outputSize.width;
    canvas.height = outputSize.height;
    function onLoaded() {
      const { width: canvasW, height: canvasH } = canvas;
      const { width: imageW, height: imageH } = image;

      const { crop, draw } = cropData;
      const cropX = imageW * crop.topx;
      const cropY = imageH * crop.topy;
      const cropW = imageW * crop.width;
      const cropH = imageH * crop.height;
      const drawX = canvasW * draw.topx;
      const drawY = canvasH * draw.topy;
      const drawW = canvasW * draw.width;
      const drawH = canvasH * draw.height;
      ctx!.fillStyle = "#000";
      ctx?.fillRect(0, 0, canvasW, canvasH);
      ctx?.drawImage(image, cropX, cropY, cropW, cropH, drawX, drawY, drawW, drawH);
      const url = canvas.toDataURL("img/png", 0.7);
      URL.revokeObjectURL(fileURL); // release memory
      resolve(url);
    }
    image.onload = onLoaded;
    image.src = fileURL;
  });
