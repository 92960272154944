import { ProgressContainer } from "src/components/features/SmartLibrary/SmartLibrary.styled";
import { AspectRatio } from "src/constants/video.constants";
import FootageProgress from "src/components/features/SmartLibrary/footagesLibrary/FootageProgress";
import { FileUploadStatus } from "src/services/BaseUpload.service";
import { Footage } from "src/network/graphql/generatedGraphqlSDK";

interface FootageUploadProgressBarProps {
  footage: Footage;
  isLanguageSelectEnabled: boolean;
  uploadStatus: FileUploadStatus;
  progress: number;
}

export function FootageUploadProgressBar({
  footage,
  isLanguageSelectEnabled,
  uploadStatus,
  progress,
}: FootageUploadProgressBarProps) {
  return (
    <ProgressContainer aspectRatio={"16:9" as AspectRatio}>
      {!isLanguageSelectEnabled && !footage?.youtubeId && <FootageProgress status={uploadStatus} progress={progress} />}
    </ProgressContainer>
  );
}
