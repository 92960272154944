import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import useLocalStorage from "src/hooks/useLocalStorage";
import { userSelectors } from "src/models/User.model";
import { Dispatch, RootState } from "src/models/store";
import { absoluteRoutes } from "src/utils/routes.utils";
import { appRouter } from "src/components/app/AppRouter/AppRouter";

export default function PlanChosenGate() {
  const [planId] = useLocalStorage("planId", "");
  const [chargeType] = useLocalStorage("chargeType", "");
  const dispatch = useDispatch<Dispatch>();
  const currentUser = useSelector(userSelectors.selectCurrentUser);
  const shopper = useSelector((state: RootState) => state.session.shopper);
  const shopperFetchedSuccess = useSelector((state: RootState) => state.loading.effects.session.getShopper.success);

  useEffect(() => {
    if (currentUser?.sid) {
      dispatch.session.getShopper();
    }
  }, [currentUser?.sid, dispatch.session]);

  useEffect(() => {
    if (shopperFetchedSuccess && !shopper?.sid && planId && chargeType) {
      appRouter.navigate(absoluteRoutes.plans);
    }
  }, [planId, chargeType, shopperFetchedSuccess, shopper]);

  if (planId && chargeType) {
    return null;
  }

  return <Outlet />;
}
