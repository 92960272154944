import useResizeObserver from "use-resize-observer";
import styled from "styled-components/macro";
import { capitalize } from "lodash/fp";

import { ifProp, themeColor } from "src/utils/styledComponents.utils";

import Icon from "src/components/common/Icon";

import { Text5 } from "src/components/common/layout/typography.styled";
import { Box } from "src/components/common/layout/Box.styled";

export const AnimatedContainer = styled(Box)`
  position: relative;
  overflow: hidden;
  text-align: left;
  transition: height 250ms ease-out;
`;

export const Container = styled(Text5)`
  display: flex;
  align-items: baseline;
  position: absolute;
  bottom: 0;
  color: ${ifProp("children", themeColor("status.error"), "transparent")};
`;

export const ErrorIconContainer = styled(Box)`
  @media (max-width: 768px) {
    display: none;
  }
`;

export interface FieldErrorProps {
  children?: string;
  capitalizeText?: boolean;
  "data-cy"?: string;
}

export default function FieldError({ children, capitalizeText = true, ...props }: FieldErrorProps) {
  const { ref, height } = useResizeObserver();

  return (
    <AnimatedContainer style={{ height: height ? height + 2 : 0 }}>
      <Container ref={ref}>
        <ErrorIconContainer marginRight={4}>
          {children && <Icon.CircledX size={8} color="status.error" />}
        </ErrorIconContainer>
        <Box data-cy={props["data-cy"]}>{children && capitalizeText ? capitalize(children) : children}</Box>
      </Container>
    </AnimatedContainer>
  );
}
