/* eslint-disable no-confusing-arrow */

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled, { css, keyframes } from "styled-components/macro";
import Color from "color";

import onboardingColorsStepCaptionsAnim from "src/assets/lottie-animations-data/onboarding-colors-step-captions.json";

import { useGetAssetImageURL } from "src/hooks/useGetAssetURL";

import { ifProp, styleProp, switchProp, themeColor, themeZ } from "src/utils/styledComponents.utils";

import { RootState } from "src/models/store";
import { folderStructureSelectors } from "src/models/FolderStructure.model";

import { AssetType } from "src/constants/model.constants";
import { AspectRatio, getAspectRatioValue } from "src/constants/video.constants";

import MediaSource from "src/components/common/media/MediaSource";
import { Box } from "src/components/common/layout/Box.styled";
import AspectRatioSelect from "src/components/features/Onboarding/VideoPreview/AspectRatioSelect";
import { useOnboardingFormContext } from "src/components/features/Onboarding/onboardingForm";
import ExtendedLottie from "src/components/common/media/ExtendedLottie";
import VisualPackageLottiePreview from "src/components/features/Onboarding/VisualPackageLottiePreview";
import { StepPath } from "src/components/features/Onboarding/steps";

const VideoFrame = styled.div<{ aspectRatio: AspectRatio; borderColor?: string }>`
  position: relative;
  display: flex;
  justify-content: center;
  aspect-ratio: ${(props) => getAspectRatioValue(props.aspectRatio)};
  transition: aspect-ratio, height, border-color;
  transition-duration: 600ms;
  transition-timing-function: cubic-bezier(0.57, -0.01, 0, 0.77);
  border: 4px solid ${styleProp("borderColor", "transparent")};
  border-radius: 16px;
  overflow: hidden;

  ${switchProp("aspectRatio", {
    "1:1": css`
      height: 58%;
    `,

    "16:9": css`
      height: 40%;

      @media (max-width: 1600px) {
        height: 35%;
      }

      @media (max-width: 1440px) {
        height: 30%;
      }
    `,

    "9:16": css`
      height: 78%;
    `,
  })}
`;

const VideoThumbnail = styled.img`
  object-fit: cover;
  z-index: ${themeZ("videoPreviewParts")};
  background-color: ${themeColor("gray.250")};
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const LogoBox = styled(Box)<{ isVisible: boolean }>`
  z-index: ${themeZ("videoPreviewParts")};
  opacity: ${ifProp("isVisible", 1, 0)};
  transition: opacity ${ifProp("isVisible", 500, 200)}ms ease-out;
  animation: ${fadeIn} 500ms ease-out;
`;

const Logo = styled.img`
  max-width: 80px;
  max-height: 30px;
  width: auto;
  height: auto;
`;

const ExampleSpeakerInfoLottie = styled(ExtendedLottie).attrs({
  animationData: onboardingColorsStepCaptionsAnim,
})<{
  color1: string;
  color2: string;
  color3: string;
}>`
  z-index: ${themeZ("videoPreviewParts")};
  position: absolute;
  bottom: 25%;
  left: 30px;

  /* speaker name text */
  .text-1 {
    fill: ${({ color1 }) => (Color(color1).isLight() ? "#000" : "#fff")};
    transition: fill 250ms ease-out;
  }

  /* speaker name bg */
  /* speaker title text */
  .color-1,
  .color-1 path {
    fill: ${styleProp("color1")};
    transition: fill 250ms ease-out;
  }

  /* slide decoration primary */
  .color-2 path {
    fill: ${styleProp("color2")};
    transition: fill 250ms ease-out;
  }

  /* slide decoration secondary */
  .color-3 path {
    fill: ${styleProp("color3")};
    transition: fill 250ms ease-out;
  }
`;

interface VideoPreviewProps {
  activeStep: StepPath;
}

export default function VideoPreview({ activeStep }: VideoPreviewProps) {
  const getAssetImageURL = useGetAssetImageURL();
  const { watch } = useOnboardingFormContext();
  const [selectedAspectRatio, setSelectedAspectRatio] = useState<AspectRatio>("16:9");

  const logoAssetId = watch("brand.logo.assetId");
  const brandColors = watch("brand.colors");
  const visualPackageId = watch("visuals.visualPackageId");
  const logoURL = logoAssetId && getAssetImageURL(logoAssetId);
  const selectedVisualPackage = useSelector((state: RootState) =>
    visualPackageId
      ? folderStructureSelectors.selectVisualPackage(state, visualPackageId, AssetType.LottiePackagePreview)
      : null,
  );

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      if (activeStep === "visuals") {
        setSelectedAspectRatio("16:9");
      }
    };
  }, [activeStep]);

  const youtubeVideos = useSelector((state: RootState) => state.youtubeSearchResults.entities);
  const onboardingForm = useOnboardingFormContext();
  const selectedYoutubeVideoId = onboardingForm.watch("content.youtubeVideoId");
  const selectedYoutubeVideo = selectedYoutubeVideoId ? youtubeVideos[selectedYoutubeVideoId] : undefined;

  return (
    <VideoFrame
      aspectRatio={selectedAspectRatio}
      borderColor={activeStep === "brand" ? brandColors?.[1]?.color : undefined}
    >
      {activeStep === "visuals" || activeStep === "finish" ? (
        <VisualPackageLottiePreview
          assetId={selectedVisualPackage?.asset.sid!}
          assetVersion={selectedVisualPackage?.asset.version!}
          // TODO: wait for backend impl
          // assetId={selectedVisualPackage?.assets[previewAspectRatio].sid!}
          // contentId={selectedVisualPackage?.assets[previewAspectRatio].contentIdentifier?.thumbnailVersion!}
          logoURL={logoURL!}
          autoplay
          loop
        />
      ) : (
        <VideoThumbnail width="100%" height="100%" src={selectedYoutubeVideo?.snippet.thumbnails.high.url} />
      )}

      <MediaSource src={logoURL}>
        {(src) => (
          <LogoBox position="absolute" top={12} left={12} isVisible={activeStep === "brand"}>
            <Logo src={src} alt="logo" />
          </LogoBox>
        )}
      </MediaSource>

      {activeStep === "brand" && brandColors && (
        <ExampleSpeakerInfoLottie
          height={85}
          autoplay
          loop
          loopDelay={1000}
          color1={brandColors[0]?.color}
          color2={brandColors[1]?.color}
          color3={brandColors[2]?.color}
        />
      )}

      {false && (
        <AspectRatioSelect
          onChange={setSelectedAspectRatio}
          selectedAspectRatio={selectedAspectRatio}
          isActive={activeStep === "visuals"}
        />
      )}
    </VideoFrame>
  );
}
