import { useCallback } from "react";
import styled from "styled-components/macro";

import { Button } from "src/components/common/buttons/Button.styled";
import { H3 } from "src/components/common/layout/typography.styled";
import { ModalComponentProps } from "src/components/providers/ModalProvider";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  height: 100%;
  width: 600px;

  * {
    font-family: "Open Sans", sans-serif !important;
  }
`;

const MessageCont = styled.div`
  width: 90%;
  text-align: center;
  margin-block: 20px;
  white-space: pre-line;
`;

const ButtonsWrapper = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${Button} {
    margin-inline: 8px;
  }
`;

interface RedirectConfirmationModalProps extends ModalComponentProps {
  confirmCallback?: () => void;
  cancelCallback?: () => void;
}

// TODO: refactor, rename or make more generic
export default function RedirectConfirmationModal({
  confirmCallback,
  cancelCallback,
  close,
}: RedirectConfirmationModalProps) {
  const onConfirmClick = useCallback(() => {
    confirmCallback?.();
    close();
  }, [close, confirmCallback]);

  const onCancelClick = useCallback(() => {
    cancelCallback?.();
    close();
  }, [close, cancelCallback]);

  return (
    <Container>
      <H3 style={{ textAlign: "center", paddingBottom: 10 }}>Files Still Uploading</H3>

      <MessageCont>
        {`Looks like you're trying to leave the page, but you've still got files that are uploading. If you leave now, those files won't be saved`}
      </MessageCont>
      <ButtonsWrapper>
        <Button variant="ghost" size="medium" onClick={onCancelClick}>
          Stay
        </Button>
        <Button variant="primary" size="medium" onClick={onConfirmClick}>
          Leave
        </Button>
      </ButtonsWrapper>
    </Container>
  );
}
