import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, FormProvider } from "react-hook-form";
import { Swiper as SwiperClass } from "swiper/types";
import styled from "styled-components/macro";

import appMonitoringService from "src/services/AppMonitoring.service";

import { ifProp, themeColor, themeZ } from "src/utils/styledComponents.utils";

import * as createProjectAnalytics from "src/analytics/creationWizard.analytics";

import { Dispatch, RootState } from "src/models/store";
import { speakerCuePointSelectors } from "src/models/SpeakerQuePoint.model";

import { Button } from "src/components/common/buttons/Button.styled";
import Select from "src/components/common/form/inputs/Select.styled";
import Checkbox, { Label } from "src/components/common/form/inputs/Checkbox";
import Swiper from "src/components/common/Swiper/Swiper";
import SwiperActiveSlideIndicator from "src/components/common/Swiper/SwiperActiveSlideIndicator";
import SwiperSlide, { MountIfActive } from "src/components/common/Swiper/SwiperSlide";
import { CircularLoader } from "src/components/common/loaders/CircularLoader";
import { Box } from "src/components/common/layout/Box.styled";
import { ModalComponentProps } from "src/components/providers/ModalProvider";
import SpeakerSlide from "src/components/modals/SpeakersModal/SpeakerSlide";
import { useSpeakersForm } from "src/components/modals/SpeakersModal/speakersForm";
import { isMobile } from "src/utils/mobile.utils";

const MainBlock = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
`;

const StyledSwiper = styled(Swiper)`
  position: relative;
  width: 100%;
  height: 200px;
`;

const SlidesWrapper = styled.div`
  z-index: ${themeZ("onboardingSwiper")};
  width: 100%;
  height: 100%;
`;

const StyledSwiperSlide = styled(SwiperSlide)`
  width: 100%;
  padding-left: 20px;

  @media (max-width: 660px) {
    padding-left: 0;
    padding-inline: 2px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  width: 504px;
  height: 366px;

  * {
    font-family: "Open Sans", sans-serif !important;
  }
`;

const DetectionTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0;
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
  text-align: center;

  @media (min-width: 660px) {
    font-size: 24px;
    line-height: 24px;
    padding: 20px 40px 0 40px;
  }
`;

const SwipeIndicatorWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-height: 20px;
  top: -20px;
`;

const SpeakerModalIndicator = styled(SwiperActiveSlideIndicator)`
  position: relative;
  overflow: hidden;
  height: 30px;
  max-width: 40%;
  left: unset;
  padding-inline: 5px;
`;

const ButtonsWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px 10px;

  @media (max-width: 660px) {
    flex-direction: column;
    margin: 0;
    padding-inline: 0;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 660px) {
    margin-bottom: 20px;
    ${Label} {
      font-size: 10px;
    }
  }
`;

const NavigateButton = styled.button`
  z-index: 100;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: unset;
  outline: 0;
  background-size: 24px 24px;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: inherit;
  font-size: 40px;
  line-height: 64px;
  text-align: center;
  color: ${themeColor("pink.500")};
  cursor: pointer;
`;

const GoToPrevSlide = styled(NavigateButton)<{ isFirst: boolean }>`
  margin-right: 2px;
  background-image: ${ifProp(
    "isFirst",
    `url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='30,50 80,100 80,0' fill='%23CCC'/%3E%3C/svg%3E")`,
    `url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='30,50 80,100 80,0' fill='%23EA429D'/%3E%3C/svg%3E")`,
  )};
`;

const GoToNextSlide = styled(NavigateButton)<{ isLast: boolean }>`
  background-image: ${ifProp(
    "isLast",
    `url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='70,50 20,100 20,0' fill='%23CCC'/%3E%3C/svg%3E")`,
    `url( "data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='70,50 20,100 20,0' fill='%23EA429D'/%3E%3C/svg%3E")`,
  )};
`;

interface SpeakersModalProps extends ModalComponentProps {
  sequenceSid: string;
}

export default function SpeakersModal({ sequenceSid, close }: SpeakersModalProps) {
  const [activeStepIndex, setActiveStepIndex] = useState(0);

  const dispatch = useDispatch<Dispatch>();
  const speakerCuePointDict = useSelector((state: RootState) => speakerCuePointSelectors.selectEntities(state));
  const undetectedSpeakerCuePoints = useSelector((state: RootState) => speakerCuePointSelectors.selectSequenceUndetectedSpeakerCuePoints(state, sequenceSid)); // prettier-ignore
  const isSubmitting = useSelector((state: RootState) => {
    const { updateSpeakerCuePoint, removeSpeakerCuePoint } = state.loading.effects.speakerCuePoints;
    return updateSpeakerCuePoint.loading || removeSpeakerCuePoint.loading;
  });

  const speakersForm = useSpeakersForm();
  const { setValue, getValues } = speakersForm;

  const onBeforeTransitionStart = useCallback(() => {
    if (document.activeElement instanceof HTMLInputElement) {
      document.activeElement.blur();
    }
  }, []);

  const onNextSlideClick = useCallback(() => {
    if (activeStepIndex < undetectedSpeakerCuePoints.length - 1) {
      setActiveStepIndex(activeStepIndex + 1);
    }
  }, [activeStepIndex, undetectedSpeakerCuePoints.length]);

  const onActiveStepIndexChange = useCallback(({ activeIndex }: SwiperClass) => {
    setActiveStepIndex(activeIndex);
  }, []);

  const onSubmit = useCallback(async () => {
    const { speakers = [], saveForFuture } = getValues(); // TODO: use submit instead

    const speakerCuePointMutationPromises = speakers.map(({ sid, name, title }) => {
      const speakerSid = saveForFuture ? speakerCuePointDict[sid]!.speakerSid : null;

      return name
        ? dispatch.speakerCuePoints.updateSpeakerCuePoint({ sid, sequenceSid, values: { name, title, speakerSid } })
        : dispatch.speakerCuePoints.removeSpeakerCuePoint({ sid, sequenceSid });
    });

    try {
      await Promise.all(speakerCuePointMutationPromises);
      await dispatch.speakerCuePoints.fetchSpeakerCuePoints({ sequenceSid });
      createProjectAnalytics.trackSpeakerDetectionDone();
    } catch (e) {
      appMonitoringService.log(e);
    } finally {
      close();
    }
  }, [dispatch, close, getValues, sequenceSid, speakerCuePointDict]);

  useEffect(() => {
    setValue(
      "speakers",
      undetectedSpeakerCuePoints.map(({ sid, name, title }) => ({
        sid: sid!,
        name: name ?? undefined,
        title: title ?? undefined,
      })),
    );
  }, [setValue, undetectedSpeakerCuePoints]);

  if (!undetectedSpeakerCuePoints.length) {
    return null;
  }

  return (
    <FormProvider {...speakersForm}>
      <Container>
        <DetectionTitle>Peech has detected faces of speakers in your project</DetectionTitle>

        <MainBlock marginTop={20}>
          <GoToPrevSlide
            isFirst={activeStepIndex <= 0}
            onClick={() => activeStepIndex > 0 && setActiveStepIndex(activeStepIndex - 1)}
          />

          <StyledSwiper
            onActiveIndexChange={onActiveStepIndexChange}
            activeIndex={activeStepIndex}
            allowSlideNext
            allowSlidePrev
            direction="horizontal"
            noSwipingSelector={`${Select}`}
            onBeforeTransitionStart={onBeforeTransitionStart}
          >
            <SlidesWrapper>
              {undetectedSpeakerCuePoints.map((speaker, speakerIndex) => (
                <StyledSwiperSlide key={speaker.sid}>
                  <MountIfActive index={speakerIndex}>
                    <SpeakerSlide index={speakerIndex} sequenceSid={sequenceSid} />
                  </MountIfActive>
                </StyledSwiperSlide>
              ))}
            </SlidesWrapper>

            {!isMobile() && (
              <SwipeIndicatorWrapper>
                <SpeakerModalIndicator direction="row" indicatorSpacing={10} />
              </SwipeIndicatorWrapper>
            )}
          </StyledSwiper>

          <GoToNextSlide isLast={activeStepIndex >= undetectedSpeakerCuePoints.length - 1} onClick={onNextSlideClick} />
        </MainBlock>

        <ButtonsWrapper marginTop={20}>
          <CheckboxWrapper>
            <Controller
              name="saveForFuture"
              control={speakersForm.control}
              render={({ field }) => (
                <Checkbox
                  label="Save these speakers for future videos"
                  isChecked={field.value ?? false}
                  onChange={field.onChange}
                />
              )}
            />
          </CheckboxWrapper>

          <Button variant="ghost" size="medium" onClick={onSubmit} disabled={isSubmitting}>
            {isSubmitting ? <CircularLoader size={20} thickness={3} /> : "Done"}
          </Button>
        </ButtonsWrapper>
      </Container>
    </FormProvider>
  );
}
