import { AnalyticsBrowser } from "@segment/analytics-next";
import * as FirebaseAnalytics from "firebase/analytics";
import { omit } from "lodash/fp";

import firebaseApp from "src/firebaseApp";
import { APP_ENV } from "src/constants/env.constants";
import { AppConfig } from "src/components/providers/AppConfigProvider";
import { User } from "src/network/graphql/generatedGraphqlSDK";

import blockServiceForEnv from "src/services/blockServiceForEnv";
import BaseService from "src/services/Base.service";

@blockServiceForEnv(["local"])
class AnalyticsService implements BaseService {
  private segmentClient?: AnalyticsBrowser;
  private firebaseClient?: FirebaseAnalytics.Analytics;
  private segmentContext: { screen: { width: number; height: number } } = {
    screen: { width: window.innerWidth, height: window.innerHeight },
  };
  private context: object = {};

  init(appConfig: AppConfig) {
    this.segmentClient = AnalyticsBrowser.load({ writeKey: appConfig.SEGMENT_TOKEN });
    this.firebaseClient = FirebaseAnalytics.getAnalytics(firebaseApp);
    window.addEventListener("resize", () => {
      this.segmentContext.screen = { width: window.innerWidth, height: window.innerHeight };
    });
  }

  identify(user: User) {
    const details = {
      firstName: user.firstName!,
      lastName: user.lastName!,
      email: user.email!,
      created: user.insertTime,
      environment: APP_ENV,
    };

    this.segmentClient?.page();
    this.segmentClient?.identify(user.sid, details);

    FirebaseAnalytics.setUserId(this.firebaseClient!, user.sid!);
    FirebaseAnalytics.setUserProperties(this.firebaseClient!, { ...user, env: APP_ENV });
  }

  async track(eventName: string, properties?: object) {
    const finalProperties = { ...this.context, ...properties };
    this.segmentClient?.track(eventName, finalProperties, {
      context: this.segmentContext,
    });
    FirebaseAnalytics.logEvent(this.firebaseClient!, eventName, finalProperties);
  }

  setContextProps(contextProps: object) {
    this.context = { ...this.context, ...contextProps };
  }

  resetContextProps(contextProps: object) {
    this.context = omit(Object.keys(contextProps), this.context);
  }
}

const analyticsService = new AnalyticsService();

export default analyticsService;
