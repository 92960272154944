import { useCallback } from "react";

import SelectCreationFlowModal from "src/components/modals/SelectCreationFlowModal";
import { useModal } from "src/components/providers/ModalProvider";
import { absoluteRoutes } from "src/utils/routes.utils";
import useSafeNavigate from "src/hooks/useSafeNavigate";
import { useFeatureFlag } from "src/components/providers/FeatureFlagsProvider";
import * as createSequenceAnalytics from "src/analytics/creationWizard.analytics";

export const useCreationCallback = () => {
  const { openModal } = useModal();

  const isFastProjectCreationFlowEnabled = useFeatureFlag("fastProjectCreationFlow");
  const navigate = useSafeNavigate();
  return useCallback(
    (trigger: createSequenceAnalytics.CreateModalTrigger, boardId: null | string = null) => {
      createSequenceAnalytics.trackCreteSelectModalOpen(trigger);
      const manualCreateFastRoute = absoluteRoutes.createProjectFast;
      const manualCreateRoute = absoluteRoutes.createProject.self;
      const automaticCreateRoute = absoluteRoutes.automaticCreate.self;

      const path = isFastProjectCreationFlowEnabled ? manualCreateFastRoute : manualCreateRoute;

      // we pass the current boardId to creation query string so we can add the created footage to this board after footage is created

      return openModal(
        (props) => (
          <SelectCreationFlowModal
            onAutomaticSubmit={() => navigate(`${automaticCreateRoute}?boardId=${boardId}`)}
            onManualSubmit={(projectTitle: string) => navigate(`${path}?title=${projectTitle}&boardId=${boardId}`)}
            {...props}
          />
        ),
        { hideCloseButton: true },
      );
    },
    [isFastProjectCreationFlowEnabled, navigate, openModal],
  );
};
