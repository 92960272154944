import { VideoCropperSideMenuProps } from "src/components/features/VideoCropper/VideoCropperSideMenu";
import { useSelector } from "react-redux";
import { RootState } from "src/models/store";
import { sequenceSelectors } from "src/models/Sequence.model";
import { useMemo } from "react";
import Color from "color";
import { MiniColorPicker } from "src/components/common/form/inputs/ColorPicker/MiniColorPicker";
import { useVideoCrop } from "src/components/features/VideoCropper/providers/VideoCropProvider/VideoCropContext";
import { trackCropStyle } from "src/analytics/sequenceCropEditor.analytics";

export default function CropperColorPicker({ sequenceSid }: VideoCropperSideMenuProps) {
  const sequence = useSelector((state: RootState) => sequenceSelectors.selectById(state, sequenceSid ?? ""));
  const { setStyle, style } = useVideoCrop();

  const brandColors = useMemo(
    () =>
      sequence?.colors?.map((brandColor, index) => ({
        color: Color(brandColor?.color!),
        isSelected: index === style?.brandColorIndex,
      })),
    [sequence?.colors, style],
  );

  const basicColors = useMemo(() => {
    const colors = [Color("white"), Color("black"), Color("white").alpha(0)];
    return colors.map((color) => ({
      color,
      isSelected:
        (style?.blur && color.alpha() === 0) ||
        (!style?.blur && style?.brandColorIndex === null && color.toString() === Color(style?.color).toString()),
    }));
  }, [style]);

  return (
    <>
      <MiniColorPicker
        options={brandColors}
        onChange={(e) => {
          setStyle({ brandColorIndex: e?.index, color: e?.color });
          trackCropStyle("brand-color-picker", { ...style, brandColorIndex: e?.index, color: e?.color });
        }}
      />
      <MiniColorPicker
        options={basicColors}
        onChange={(e) => {
          setStyle({ color: e?.color });
          trackCropStyle("basic-color-picker", { ...style, color: e?.color });
        }}
      />
    </>
  );
}
