import { themeColor } from "src/utils/styledComponents.utils";
import styled from "styled-components/macro";

export const RadioButton = styled.input.attrs({ type: "radio" })`
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  margin: 0;
  border: 1px solid ${themeColor("pink.500")};
  border-radius: 50%;
  cursor: pointer;

  &:before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${themeColor("pink.500")};
    transform: scale(0);
    transition: 120ms transform ease-in-out;
  }

  &:checked:before {
    transform: scale(1);
  }
`;
