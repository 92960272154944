export enum UserRole {
  SystemAdmin = 1,
  Owner = 2,
  User = 3,
}

export enum SequenceRole {
  Creator = 1,
  Editor = 2,
  Reviewer = 3,
  Viewer = 4,
  Owner = 5,
}

export enum SequenceStatus {
  Pending,
  Active,
  Template,
  Deleted,
  Processing,
  Erroneous,
  InputsValidated,
}

export enum FolderType {
  Asset = 1,
  Sequence = 2,
  LottiePackage = 3,
}

export enum AssetType {
  Logo = 1,
  Music = 101,
  MusicSource = 102,
  CuePointImage = 103,
  Source = 201,
  CuePointVideo = 202,
  Help = 203,
  SourceIntro = 204,
  SourceOutro = 205,
  CuePointPresentation = 401,
  LottieText = 501,
  LottieSlide = 502,
  LottieSpeaker = 503,
  LottieIntro = 504,
  LottieOutro = 505,
  LottieTransition = 506,
  LottieFrame = 507,
  LottiePackagePreview = 550,
  FontOtf = 601,
  FontTtf = 602,
  FontWoff = 603,
  FontWoff2 = 604,
  FontZip = 605,
}

export enum AssetStatus {
  Pending = 0,
  Processing = 1,
  Ready = 2,
  Deleted = 3,
  DeleteCandidate = 4,
  Erroneous = 5,
}

export enum PresetStatus {
  Active = 1,
  Default = 2,
  Deleted = 3,
}

export enum PlanStatus {
  Active = 1,
  Inactive = 2,
  Deleted = 3,
  Default = 4,
}

export enum ChargeType {
  Monthly = 1,
  Yearly = 2,
}

export namespace CuePointType {
  export enum Chapter {
    Intro = 32,
    Outro = 33,
  }
}

export enum ChapterStatus {
  Pending = 0,
  Processing = 1,
  Ready = 2,
  Deleted = 3,
  Erroneous = 5,
}

// TODO: check with old app
export enum MusicStatus {
  Pending = 0,
  Processing = 1,
  Ready = 2,
}

export enum VideoTypeStatus {
  Active = 1,
  Inactive = 2,
  Deleted = 3,
  IsDefault = 4,
}

export enum CuePointStatus {
  Pending = 0,
  Active = 1,
  Inactive = 2,
  Deleted = 3,
  Erroneous = 5,
}
