import Plans from "src/components/features/Plans/Plans";
import { useLocation } from "react-router-dom";
import { getQuery } from "src/utils/urlQuery.utils";
import { Helmet } from "react-helmet";

export default function PlansPage() {
  const { search } = useLocation();
  const chargeType = getQuery(search, "chargeType");
  const planId = getQuery(search, "planId");

  return (
    <>
      <Helmet>
        <title>Plans | Peech - Automated Talk-Videos Creator</title>
      </Helmet>
      <Plans planId={planId} chargeType={chargeType} />
    </>
  );
}
