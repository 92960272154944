import { useEffect } from "react";

import useHistoryState from "src/hooks/useHistoryState";
import { ChapterTimeInterval, Word } from "src/types/video-trimmer.types";
import { CropperHistory } from "src/types/video-cropper.types";
import { isArray } from "lodash";

type HistoryTypes = "chapters" | "words" | "crops";
export type InitialHistoryState = ChapterTimeInterval[] | Word[] | CropperHistory | HistoryTypes;

function hasData(initialHistory: InitialHistoryState): boolean {
  // check if initialHistory is a none empty array or an object with none empty arrays
  let result = false;
  if (isArray(initialHistory) && initialHistory.length) {
    result = true;
  } else {
    Object.keys(initialHistory).forEach((key) => {
      const value: any = initialHistory[key as keyof InitialHistoryState];
      if (isArray(value) && value.length) result = true;
    });
  }
  return result;
}

export default function useInitiatedHistory(initialState: InitialHistoryState, initialEmptyState: InitialHistoryState) {
  const historyState = useHistoryState<InitialHistoryState>(initialEmptyState);
  const { init } = historyState;

  useEffect(() => {
    if (hasData(initialState)) {
      init(initialState);
    }
  }, [init, initialState]);

  return historyState;
}
