import { Footage } from "src/network/graphql/generatedGraphqlSDK";
import analyticsService from "src/services/Analytics.service";
import { VideoMetaData } from "src/utils/uploader.utils";

export const trackCreateFootage = (
  footage: Footage,
  trigger: "platform-library" | "automation-wizard",
  videoMetaData: VideoMetaData | {} = {},
) => {
  const { id, filename, origin, languageCode } = footage;
  analyticsService.track("footage", {
    action: "added",
    trigger,
    id,
    fileName: filename,
    origin,
    languageCode,
    ...videoMetaData,
  });
};

export const trackDeleteFootage = (
  footage: Footage,
  trigger: "platform-library" | "automation-wizard" = "platform-library",
) => analyticsService.track("footage", { action: "removed", id: footage.id, fileName: footage.filename, trigger });

export const trackRenameFootage = (footage: Footage) =>
  analyticsService.track("footage", { action: "rename", id: footage.id, fileName: footage.filename });

export const trackFootageLanguageChange = (footage: Footage) =>
  analyticsService.track("footage", { action: "languageChange", id: footage.id, languageCode: footage.languageCode });

export const trackFootageUploadStart = (footage: Footage) =>
  analyticsService.track("footage", { action: "uploadStart", id: footage.id });

export const trackFootageUploadSuccess = (footage: Footage) =>
  analyticsService.track("footage", { action: "uploadSuccess", id: footage.id });

export const trackUploadComponentSelectFiles = (props: {
  trigger: "dragAndDrop" | "filePicker";
  filesAmount: number;
  multiFiles: boolean;
}) => analyticsService.track("upload-footage-component", props);

export const trackExpandCollapseAllClips = (props: { action: "collapse" | "expand" }) =>
  analyticsService.track("footage-view", { action: props.action, trigger: "all" });

export const trackExpandCollapseClip = (props: { action: "collapse" | "expand" }) =>
  analyticsService.track("footage-view", { action: props.action, trigger: "chapter" });

export const trackFilterInFootage = (props: {
  duration: boolean;
  ratio: string;
  socialMedia: string;
  favorite: boolean;
  language: boolean;
  trigger: string;
}) =>
  analyticsService.track("footage-view", {
    action: "filter",
    trigger: props.trigger,
    duration: props.duration,
    ratio: props.ratio,
    socialMedia: props.socialMedia,
    favorite: props.favorite,
    language: props.language,
  });

export const trackFootageSnippetButtonClick = (
  footageId: string,
  trigger: "footage-item-snippet-button" | "footage-preview-snippet-button",
) =>
  analyticsService.track("footage", {
    action: "snippet-button-click",
    id: footageId,
    trigger,
  });

export const trackFootagePreviewButtonClick = (
  footageId: string,
  trigger: "footage-item-preview-button" | "snippets-footage-preview-button",
) =>
  analyticsService.track("footage", {
    action: "preview-button-click",
    id: footageId,
    trigger,
  });
