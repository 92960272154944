import { useForm, useFormContext } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { PartialSteps } from "src/types/stepped-form.types";
import { ColorAdd } from "src/network/graphql/generatedGraphqlSDK";
import { StepPath } from "src/components/features/Onboarding/steps";

const INPUT_METHODS = ["youtubeVideoURL", "youtubeVideoID", "upload"] as const;
type InputMethod = typeof INPUT_METHODS[number];

const onboardingFormSchema = yup
  .object({
    content: yup
      .object({
        inputMethod: yup
          .mixed<InputMethod>()
          .oneOf([...INPUT_METHODS])
          .required(),
        youtubeVideoURL: yup.string(),
        youtubeVideoId: yup.string().required(),
        languageCode: yup.string(),
      })
      .required(),

    brand: yup
      .object({
        logo: yup
          .object({
            assetId: yup.string().required(),
            isExample: yup.boolean().required(),
          })
          .required(),

        colors: yup
          .array(
            yup.object({
              dominancy: yup.number().required(),
              color: yup.string().required(),
              percent: yup.number().required(),
              grayness: yup.number().required(),
            } satisfies Record<keyof ColorAdd, yup.BaseSchema>),
          )
          .required(),

        visibleColorCount: yup.number(),
      })
      .required(),

    visuals: yup
      .object({
        visualPackageId: yup.string().required(),
      })
      .required(),

    finish: yup.object({}).required(),
  } satisfies Record<StepPath, yup.ObjectSchema<{}>>)
  .required();

export type OnboardingForm = PartialSteps<yup.InferType<typeof onboardingFormSchema>>;

export const useOnboardingForm = () =>
  useForm<OnboardingForm>({
    mode: "onChange",
    resolver: yupResolver(onboardingFormSchema),
  });

export const useOnboardingFormContext = useFormContext<OnboardingForm>;
