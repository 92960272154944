import ReactSelect from "react-select";
import styled, { css } from "styled-components/macro";

import { themeColor, themeProp, themeZ } from "src/utils/styledComponents.utils";
import { inputStyle } from "src/components/common/form/inputs/Input.styled";

const Select = styled(ReactSelect).attrs((props) => ({
  classNamePrefix: props.classNamePrefix ?? "select",
  menuPlacement: "auto",
  menuPortalTarget: document.body,
}))`
  .select__control {
    min-height: unset;
    box-shadow: none;
    ${inputStyle}

    .select__placeholder {
      color: ${themeColor("gray.400")};
    }

    .select__value-container {
      padding: 11px 8px;
    }

    .select__dropdown-indicator {
      padding-block: 0;
      padding-inline: 8px;

      > svg {
        width: 12px;
        height: 12px;
        fill: ${themeColor("gray.500")};
        transition: fill, rotate;
        transition-duration: 200ms;
        transition-timing-function: ease-out;
      }
    }

    .select__indicator-separator {
      display: none;
    }

    .select__loading-indicator {
      color: ${themeColor("pink.500")};
    }
  }

  .select__control--menu-is-open {
    .select__dropdown-indicator > svg {
      fill: ${themeColor("blue.500")};
      rotate: 180deg;
    }
  }
`;

export const SelectGlobalStyle = css`
  .select__menu-portal {
    z-index: ${themeZ("selectMenu")} !important;
  }

  .select__menu {
    margin-block: 2px;
    border: 1px solid ${themeColor("gray.400")};
    border-radius: ${themeProp("borderRadii.small")}px;
    box-shadow: none !important;
    overflow: hidden !important;
    background-color: ${themeColor("white")};

    .select__menu-list {
      max-height: 190px;
      padding: 0;

      .select__option {
        padding: 15px 12px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        transition: background-color 200ms ease-out;

        &.select__option--is-focused {
          background-color: ${themeColor("gray.200")};
        }

        &.select__option--is-selected {
          background-color: ${themeColor("blue.500")};
        }
      }
    }
  }
`;

export default Select as typeof ReactSelect;
