import { CenterContentContainer, Container } from "src/components/auth/common/layout.styled";
import { H1Bold, Text1 } from "src/components/common/layout/typography.styled";
import Icon from "src/components/common/Icon";
import { useLocation } from "react-router-dom";

export default function SignUpEmailVerification() {
  const location = useLocation();
  // get email from navigation state
  const email = location?.state?.email;
  return (
    <Container>
      <CenterContentContainer>
        <H1Bold>An email was sent to</H1Bold>
        {email && (
          <Text1 marginBottom={10} style={{ fontSize: "22px" }}>
            {email}
          </Text1>
        )}
        <Text1 marginBottom={10}>Please check your email and click the activation link</Text1>
        <Icon.Mail size={165} color="pink.500" />
      </CenterContentContainer>
    </Container>
  );
}
