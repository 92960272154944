import { PropsWithChildren, RefObject, useCallback, useEffect, useMemo, useState } from "react";
import {
  CropDataDetail,
  CropDataItemSize,
  DragPosition,
  getDraggablePosition,
  getFillCropData,
  getFitCropData,
  getItemScaleRelativeToStage,
  scaleLimitBy,
} from "src/utils/cropData.utils";
import { useSelector } from "react-redux";
import { RootState } from "src/models/store";
import { cropSelectors } from "src/models/Crop.model";
import { CropDataResizeLimit } from "src/components/features/VideoCropper/providers/VideoCropProvider/VideoCropContext";
import { CropTimeInterval } from "src/types/video-cropper.types";

interface CropDataHookProps extends PropsWithChildren {
  crops: CropTimeInterval[];
  activeCropIndex: number;
  sequenceSid: string;
  videoRef?: RefObject<HTMLVideoElement>;
  cropStageRef?: RefObject<HTMLDivElement>;
}

interface CropDataHookReturnValue {
  draggingPosition: DragPosition;
  setDraggingPosition: (position: DragPosition) => void;
  cropScale: number;
  resizeLimit: CropDataResizeLimit;
  getCropFill: () => DragPosition;
  getCropFit: () => DragPosition;
  alignCrop: (align: AlignType) => DragPosition;
  rescaleCrop: (scale: number) => void;
}

export type AlignType = | "M" | "T" | "B" | "R" | "L" | "C" | "MC" | "ML" | "MR" | "TC" | "TL" | "TR" | "BC" | "BL" | "BR"; // prettier-ignore

export default function useCropData({
  sequenceSid,
  videoRef,
  cropStageRef,
  crops,
  activeCropIndex,
}: CropDataHookProps): CropDataHookReturnValue {
  const assetsDomains = useSelector((state: RootState) => cropSelectors.selectAssetsDomains(state, sequenceSid));
  const firstAssetNode = useSelector((state: RootState) => cropSelectors.selectById(state, assetsDomains[0]?.assetSid));
  const currentCrop = crops[activeCropIndex] as CropDataDetail;
  const assetOriginalAspectRatio = firstAssetNode?.originalAspectRatio;
  const [draggingPosition, setDraggingPosition] = useState<DragPosition>({
    x: 0,
    y: 0,
    w: 0,
    h: 0,
  });

  const videoOriginalSize: CropDataItemSize = useMemo(() => {
    if (!videoRef?.current) {
      const ratio = assetOriginalAspectRatio?.split(":");
      if (!ratio) {
        return {
          width: 0,
          height: 0,
        };
      }
      return {
        width: parseFloat(ratio[0]),
        height: parseFloat(ratio[1]),
      };
    }
    return {
      width: videoRef.current.videoWidth,
      height: videoRef.current.videoHeight,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoRef?.current?.videoWidth, videoRef?.current?.videoHeight, assetOriginalAspectRatio]);

  const stageSize: CropDataItemSize = useMemo(
    () => ({
      width: cropStageRef?.current?.offsetWidth || 0,
      height: cropStageRef?.current?.offsetHeight || 0,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cropStageRef?.current?.offsetLeft, cropStageRef?.current?.offsetHeight],
  );

  const cropScale = useMemo(
    () => getItemScaleRelativeToStage(draggingPosition, stageSize),
    [draggingPosition, stageSize],
  );

  const resizeLimit = useMemo(() => {
    const limitBy = scaleLimitBy(videoOriginalSize, stageSize);
    return {
      minScale: 0.1,
      maxScale: 2,
      limitBy,
    } as CropDataResizeLimit;
  }, [videoOriginalSize, stageSize]);

  useEffect(() => {
    if (!currentCrop) {
      setDraggingPosition({
        x: 0,
        y: 0,
        w: 0,
        h: 0,
      });
    } else {
      setDraggingPosition(getDraggablePosition(currentCrop, videoOriginalSize, stageSize));
    }
  }, [currentCrop, videoOriginalSize, stageSize]);

  const getCropFill = useCallback(
    () => getFillCropData(videoOriginalSize, stageSize, 1),
    [videoOriginalSize, stageSize],
  );

  const getCropFit = useCallback(() => getFitCropData(videoOriginalSize, stageSize), [videoOriginalSize, stageSize]);

  const rescaleCrop = useCallback(
    (newScale: number) => {
      const scaleDiff = newScale / cropScale;
      // new position scale from the center
      const newX = draggingPosition.x + (parseFloat(draggingPosition.w as string) * (1 - scaleDiff)) / 2;
      const newY = draggingPosition.y + (parseFloat(draggingPosition.h as string) * (1 - scaleDiff)) / 2;

      const newCropData = getFillCropData(videoOriginalSize, stageSize, newScale);
      if (newCropData) {
        setDraggingPosition({
          ...newCropData,
          x: newX,
          y: newY,
        });
      }
    },
    [
      draggingPosition.h,
      draggingPosition.w,
      draggingPosition.x,
      draggingPosition.y,
      cropScale,
      stageSize,
      videoOriginalSize,
    ],
  );

  const alignCrop = useCallback(
    (align: AlignType) => {
      const newCropData = { ...draggingPosition };

      // set y position
      // eslint-disable-next-line default-case
      switch (align) {
        case "MC":
        case "ML":
        case "MR":
        case "M":
          newCropData.y = (stageSize.height - Number(newCropData.h)) / 2;
          break;
        case "TC":
        case "TL":
        case "TR":
        case "T":
          newCropData.y = 0;
          break;
        case "BC":
        case "BL":
        case "BR":
        case "B":
          newCropData.y = stageSize.height - Number(newCropData.h);
          break;
      }

      // set x position
      // eslint-disable-next-line default-case
      switch (align) {
        case "MC":
        case "TC":
        case "BC":
        case "C":
          newCropData.x = (stageSize.width - Number(newCropData.w)) / 2;
          break;
        case "ML":
        case "TL":
        case "BL":
        case "L":
          newCropData.x = 0;
          break;
        case "MR":
        case "TR":
        case "BR":
        case "R":
          newCropData.x = stageSize.width - Number(newCropData.w);
          break;
      }

      return newCropData;
    },
    [stageSize, draggingPosition],
  );

  return {
    draggingPosition,
    setDraggingPosition,
    cropScale,
    resizeLimit,
    getCropFill,
    getCropFit,
    rescaleCrop,
    alignCrop,
  };
}
