import { omit } from "lodash/fp";
import { AspectRatio } from "src/constants/video.constants";
import { ClipSequenceType, FootageOrigin, Sequence } from "src/network/graphql/generatedGraphqlSDK";
import analyticsService from "src/services/Analytics.service";

export type TriggerOrigin = "context-menu" | "visual-editor" | "project-download-modal";

export type PreviewTrigger = "clip" | "recently-edited";

export const deleteSequence = (
  trigger: "context-menu",
  footageId: string,
  sequenceSid: string,
  footageOrigin: FootageOrigin,
) => analyticsService.track("project-action", { action: "delete", trigger, footageId, sequenceSid, footageOrigin });

export const renameSequence = (
  trigger: "context-menu" | "editor-header",
  footageId: string,
  sequenceSid: string,
  footageOrigin: FootageOrigin,
) => analyticsService.track("project-action", { action: "rename", trigger, footageId, sequenceSid, footageOrigin });

export const downloadSubtitles = (
  trigger: "context-menu",
  footageId: string,
  sequenceSid: string,
  footageOrigin: FootageOrigin,
) =>
  analyticsService.track("project-action", {
    action: "download-subtitles",
    trigger,
    footageId,
    sequenceSid,
    footageOrigin,
  });

export const duplicateSequence = (
  trigger: TriggerOrigin,
  footageId: string,
  sequenceSid: string,
  ratioChange: boolean,
  ratioTarget: AspectRatio,
  ratioSource: AspectRatio,
  footageOrigin: FootageOrigin,
) =>
  analyticsService.track("project-action", {
    action: "duplicate",
    trigger,
    footageId,
    sequenceSid,
    ratioChange,
    ratioTarget,
    ratioSource,
    footageOrigin,
  });

export const translateSequence = (
  trigger: TriggerOrigin,
  footageId: string,
  sequenceSid: string,
  languageTarget: string,
  languageSource: string,
  footageOrigin: FootageOrigin,
) =>
  analyticsService.track("project-action", {
    action: "translate",
    trigger,
    footageId,
    sequenceSid,
    languageTarget,
    languageSource,
    footageOrigin,
  });

// extend Sequence with additional properties
export interface OpenSequence extends Sequence {
  type: ClipSequenceType;
  ratio: AspectRatio;
  isFavorite: boolean;
  footageOrigin: FootageOrigin;
}

export const openSequence = (trigger: "preview-modal", sequence: OpenSequence) =>
  analyticsService.track("project-action", { action: "open", trigger, ...omit(["chapters"], sequence) });

export const previewSequence = (trigger: PreviewTrigger, sequence: OpenSequence) =>
  analyticsService.track("project-action", { action: "preview", trigger, ...omit(["chapters"], sequence) });

export const previewSequenceReady = (externalSequenceId: string) =>
  analyticsService.track("project-action", { action: "preview-ready", externalSequenceId });
