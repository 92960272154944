import { ComponentType, PropsWithChildren, createContext, useContext, useState, useCallback } from "react";
import styled, { css, keyframes } from "styled-components/macro";

import { ifProp, themeColor, themeProp, themeZ } from "src/utils/styledComponents.utils";
import useShallowMemo from "src/hooks/useShallowMemo";

import Icon from "src/components/common/Icon";

export interface ModalComponentProps {
  close(): void;
}

export type ModalComponent = ComponentType<ModalComponentProps>;

interface ModalContextType {
  openModal(
    modal: ModalComponent,
    options?: { hideCloseButton?: boolean; fullScreen?: boolean; hidePaddings?: boolean; transparent?: boolean },
  ): void;
  closeModal(): void;
  isModalOpen: boolean;
}

const ModalContext = createContext<ModalContextType | null>(null);

const modalOpeningAnimation = keyframes`
  from {
    scale: 0.95;
    opacity: 0;
    translate: 0 -100px;
  }

  to {
    scale: 1;
    opacity: 1;
    translate: 0 0;
  }
`;

const modalClosingAnimation = keyframes`
  from {
    scale: 1;
    opacity: 1;
    translate: 0 0;
  }

  to {
    scale: 0.95;
    opacity: 0;
    translate: 0 -100px;
  }
`;

interface ModalContainerProps {
  isModalSet: boolean;
  isClosing: boolean;
  isFullScreen: boolean;
  hidePaddings: boolean;
  isTransparent: boolean;
}

interface ModalOverlayProps {
  isClosing: boolean;
}

const ModalOverlay = styled.div<ModalOverlayProps>`
  z-index: ${themeZ("modal")};
  position: fixed;
  inset: 0;
  animation: ${ifProp("isClosing", "blurOut", "blurIn")} 500ms 250ms ease forwards;

  ${ifProp(
    "isClosing",
    css<ModalOverlayProps>`
      background: ${themeColor("black", 0.2)};
      backdrop-filter: blur(5px);
    `,
  )};

  @keyframes blurIn {
    to {
      background: ${themeColor("black", 0.2)};
      backdrop-filter: blur(5px);
    }
  }

  @keyframes blurOut {
    to {
      background: transparent;
      backdrop-filter: blur(0px);
    }
  }
`;

const ModalContainer = styled.div<ModalContainerProps>`
  display: flex;
  z-index: ${themeZ("modal")};
  position: fixed;
  inset: 0;
  margin: auto;
  width: ${ifProp("isFullScreen", "100%", "fit-content")};
  max-width: ${ifProp("isFullScreen", "100%", "90vw")};
  height: ${ifProp("isFullScreen", "100%", "fit-content")};
  max-height: ${ifProp("isFullScreen", "100%", "96vh")};
  min-height: fit-content;
  padding: ${ifProp("isFullScreen", "0", ifProp("hidePaddings", "0", "20"))}px;
  background-color: ${ifProp("isTransparent", "transparent", "#fff")};
  border-radius: ${ifProp("isFullScreen", "0", themeProp("borderRadii.large"))}px;
  box-shadow: ${ifProp("isTransparent", "unset", "0 1px 5px 0 rgba(0, 0, 0, 0.3)")};
  overflow: hidden;

  ${ifProp(
    "isModalSet",
    css<ModalContainerProps>`
      animation: ${ifProp("isClosing", modalClosingAnimation, modalOpeningAnimation)} 250ms forwards;
      animation-timing-function: ${ifProp("isClosing", "ease-out", "ease-in-out")};
    `,
    css`
      visibility: hidden;
    `,
  )}
`;

const CloseButton = styled(Icon.CircledX)`
  position: absolute;
  top: 0;
  right: 0;
  translate: 50% -50%;
  cursor: pointer;
  border-radius: 50%;
  transition: scale 250ms ease-out;

  &:hover {
    scale: 1.05;
  }
`;

export default function ModalProvider({ children }: PropsWithChildren<{}>) {
  const [Modal, setModal] = useState<ModalComponent | null>(null);
  const [isClosing, setIsClosing] = useState(false);
  const [shouldShowCloseButton, setShouldShowCloseButton] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [shouldHidePaddings, setShouldHidePaddings] = useState(false);
  const [isTransparent, setIsTransparent] = useState(false);

  const openModal = useCallback(
    (
      modal: ModalComponent,
      options?: { hideCloseButton?: boolean; fullScreen?: boolean; hidePaddings?: boolean; transparent?: boolean },
    ) => {
      setModal(() => modal);
      setShouldShowCloseButton(!(options?.hideCloseButton ?? false));
      setIsFullScreen(!!options?.fullScreen);
      setShouldHidePaddings(!!options?.hidePaddings);
      setIsTransparent(!!options?.transparent);
    },
    [],
  );

  const closeModal = useCallback(() => setIsClosing(true), []);

  return (
    <ModalContext.Provider value={useShallowMemo({ openModal, closeModal, isModalOpen: !!Modal })}>
      {children}

      {Modal && (
        <ModalOverlay
          isClosing={isClosing}
          onAnimationEnd={(e) => {
            if (e.animationName === "blurOut" /* modalClosingAnimation.getName() */) {
              setIsClosing(false);
              setModal(null);
            }
          }}
        />
      )}

      <ModalContainer
        isModalSet={!!Modal}
        isClosing={isClosing}
        isFullScreen={isFullScreen}
        hidePaddings={shouldHidePaddings}
        isTransparent={isTransparent}
      >
        {shouldShowCloseButton && <CloseButton onClick={closeModal} size="medium" color="gray.300" />}
        {Modal && <Modal close={closeModal} />}
      </ModalContainer>
    </ModalContext.Provider>
  );
}

export const useModal = () => {
  const contextValue = useContext(ModalContext);

  if (!contextValue) {
    throw new Error("useModal was used outside of ModalProvider");
  }

  return contextValue;
};
