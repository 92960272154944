import { useCallback, useMemo, useState } from "react";

import { themeColor } from "src/utils/styledComponents.utils";
import { formatSeconds } from "src/utils/time.utils";
import styled from "styled-components/macro";
import { ifProp } from "styled-tools";

import Icon from "src/components/common/Icon/Icon";
import { CircularLoader } from "src/components/common/loaders/CircularLoader";
import Popper from "src/components/common/popovers/Popper";
import HorizontalRangeSlider from "src/components/features/SmartLibrary/filtering/durationPopover/HorizontalRangeSlider";
import { isNil } from "lodash/fp";

const PopContainer = styled.div<{ disabled: boolean }>`
  position: relative;
  border: solid 1px;
  border-color: ${themeColor("blue.600")};
  border-radius: 30px;
  min-height: 30px;
  width: 100%;
  background-color: ${themeColor("blue.30")};
  cursor: ${ifProp("disabled", "auto", "pointer")};
  opacity: 0.65;
  transition: opacity 250ms ease-out;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${themeColor("gray.900")};
  padding-left: 8px;
  &:hover {
    border-color: ${ifProp("disabled", themeColor("blue.600"), themeColor("blue.900"))};
  }
  transition: border-color 250ms ease-out;
`;

const ArrowWrapper = styled.div<{ isOpen: boolean }>`
  position: absolute;
  right: 16px;
  top: ${ifProp("isOpen", "2px", "8px")};
  width: 24px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${ifProp("isOpen", "rotateZ(180deg)", "rotateZ(0deg)")};
  svg {
    height: 12px;
    color: ${themeColor("blue.900")};
  }
  transition: transform 200ms ease;
`;

const ContentContainer = styled.div`
  height: fit-content;
  min-width: 250px;
  border-radius: 6px;
  border: 0.5px solid;
  border-color: ${themeColor("blue.50")};
  background: ${themeColor("white")};
  box-shadow: 0px 4px 16px 0px rgba(4, 27, 63, 0.2);
  padding: 10px 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const SelectedValueContainer = styled.div`
  background-color: ${themeColor("white")};
  border-radius: 80px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 2px 3px;
  margin: 2px;
  min-width: 60px;
  height: 26px;
  font-size: 13px;
  font-weight: 600;
`;

const ValueLabel = styled.div`
  padding: 3px 6px 3px 6px;
`;

const RemoveBtn = styled.div`
  cursor: pointer;
  background-color: ${themeColor("blue.100")};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  padding-inline: 4px;
  &:hover {
    background-color: ${themeColor("blue.600")};
  }
  svg {
    width: 10px;
    path {
      fill: ${themeColor("blue.900")};
    }
  }
  transition: background-color 250ms ease-out;
`;

const PlaceholderWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: ${themeColor("blue.900")};
  svg {
    margin-right: 3px;
  }
`;

export interface ActionsMenuOptionProps {
  name: string;
  icon: any;
  action: () => void;
  hidden?: boolean;
}

export interface DurationPopoverProps {
  minDuration?: number | null;
  maxDuration?: number | null;
  selectedDurationValues: [number, number] | null;
  changeSelectedDurationValues: (range: [number, number]) => void;
}

export default function DurationPopover({
  minDuration,
  maxDuration,
  selectedDurationValues,
  changeSelectedDurationValues,
}: DurationPopoverProps) {
  const [isOpen, setIsOpen] = useState(false);

  const onRangeSelect = useCallback(
    (range: number[]) => {
      if (!(range[0] === selectedDurationValues?.[0] && range[1] === selectedDurationValues[1])) {
        changeSelectedDurationValues([range[0], range[1]]);
      }
    },
    [selectedDurationValues, changeSelectedDurationValues],
  );
  const step = useMemo(() => {
    if (!isNil(minDuration) && !isNil(maxDuration)) {
      return ((maxDuration - minDuration) / 100) * 14;
    }
    return 1;
  }, [maxDuration, minDuration]);

  return (
    <Popper
      isOpen={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      offsetY={5}
      placement="bottom-start"
      content={
        <ContentContainer>
          {selectedDurationValues && !isNil(minDuration) && !isNil(maxDuration) && (
            <HorizontalRangeSlider
              min={minDuration}
              max={maxDuration}
              range={[selectedDurationValues[0], selectedDurationValues[1]]}
              step={step}
              onChange={onRangeSelect}
            />
          )}
        </ContentContainer>
      }
    >
      <PopContainer disabled={!selectedDurationValues || selectedDurationValues[0] === selectedDurationValues[1]}>
        {!selectedDurationValues && <CircularLoader size={20} thickness={3} />}
        {selectedDurationValues &&
          !isNil(minDuration) &&
          !isNil(maxDuration) &&
          (selectedDurationValues[0] === minDuration && selectedDurationValues[1] === maxDuration ? (
            <PlaceholderWrapper>
              <Icon.Duration />
              Duration
            </PlaceholderWrapper>
          ) : (
            <SelectedValueContainer>
              <ValueLabel>
                {`${formatSeconds(selectedDurationValues[0], "mm:ss")} - ${formatSeconds(
                  selectedDurationValues[1],
                  "mm:ss",
                )}`}
              </ValueLabel>

              <RemoveBtn
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  changeSelectedDurationValues([minDuration, maxDuration]);
                }}
              >
                <Icon.Close />
              </RemoveBtn>
            </SelectedValueContainer>
          ))}

        <ArrowWrapper isOpen={isOpen}>
          <Icon.ArrowDown viewBox="0 0 13 14" />
        </ArrowWrapper>
      </PopContainer>
    </Popper>
  );
}
