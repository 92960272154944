import { components, DropdownIndicatorProps } from "react-select";

import Icon from "src/components/common/Icon";
import { SequenceFilterOption } from "src/components/features/SmartLibrary/filtering/filtersSelect/SequenceFilterSelect";

export default function SequenceDropdownIndicator({ ...props }: DropdownIndicatorProps<SequenceFilterOption>) {
  return (
    <components.DropdownIndicator {...props}>
      <Icon.ArrowDown size={13} color="blue.900" />
    </components.DropdownIndicator>
  );
}
