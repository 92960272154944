import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useResizeObserver from "use-resize-observer";
import styled from "styled-components/macro";
import { isNil } from "lodash/fp";

import UserEngagementService from "src/services/UserEngagement.service";

import * as plansAnalytics from "src/analytics/plans.analytics";

import { ifProp, themeColor } from "src/utils/styledComponents.utils";

import { Dispatch } from "src/models/store";
import { planSelectors } from "src/models/Plan.model";

import Icon from "src/components/common/Icon";
import { Box } from "src/components/common/layout/Box.styled";
import { RainbowButton } from "src/components/common/buttons/RainbowButton";
import { UpgradeModelFeature } from "src/types/pricing.types";
import { useAppConfig } from "src/components/providers/AppConfigProvider";
import CalendlyContactForm from "src/components/features/UpgradeFeature/CalendlyContactForm";
import FeatureItemComponent from "src/components/features/UpgradeFeature/FeatureItemComponent";

const Container = styled.div`
  display: flex;
  height: 100%;
  min-height: 586px;
  width: 100%;
  min-width: 734px;
  border-radius: 30px;
`;

const LeftSide = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  min-width: 40%;
  background-color: ${themeColor("white")};

  padding-block: 34px 46px;
  border-radius: 30px 0 0 30px;
`;

const ScrollButton = styled.div<{ hide: boolean }>`
  display: ${ifProp("hide", "none", "flex")};
  justify-content: center;
  position: absolute;
  bottom: 14px;
  left: calc(50% - 25px);
  width: 50px;
  height: 20px;
  cursor: pointer;
  display: ${ifProp("hide", "none")};
`;

const RightSide = styled(LeftSide)`
  min-width: 60%;
  background-color: ${themeColor("gray.50")};
  padding-block: 34px 95px;
  position: relative;
  border-radius: 0 30px 30px 0;
`;

const StickyHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 13px 20px 24px 20px;
  background: ${themeColor("white")};
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 80%,
    rgba(255, 255, 255, 0) 100%
  );
  position: sticky;
  top: 0;
  margin: 0;
`;

const StickyHeaderRight = styled(StickyHeader)`
  background-color: ${themeColor("gray.50")};
  background-image: none;
  padding-left: 0;
`;

const ListHeader = styled.div`
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: ${themeColor("black")};
`;

const ListSubHeader = styled(ListHeader)`
  font-weight: 400;
  color: ${themeColor("gray.900")};
`;

const FeatureTitle = styled.div`
  font-family: "Work Sans", sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: ${themeColor("black")};
  animation: 2s show ease;

  @keyframes show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const FeatureDescription = styled(FeatureTitle)`
  font-weight: 400;
  font-size: 16px;
  color: ${themeColor("gray.900")};
`;

const GridScroll = styled(Box)`
  overflow-y: auto;
  flex: 1;
  width: 100%;
  max-height: 100%;
  padding-inline: 35px 26px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const TransparentBottomBlock = styled.div`
  position: absolute;
  bottom: 46px;
  left: 0;
  width: 100%;
  height: 30px;
  background: ${themeColor("white")};
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 39%,
    rgba(255, 255, 255, 1) 100%
  );
`;

const GridScrollRight = styled(GridScroll)`
  padding-inline: 68px;
`;

const FeaturesList = styled.div`
  width: 100%;
  height: fit-content;
  padding-block: 6px 22px;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 23px;
  right: 70px;
  text-decoration: none;
  animation: 2s show ease;
`;

const FeatureIsActive = styled.div`
  color: ${themeColor("black")};
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 15px;
  position: absolute;
  bottom: 23px;
  right: 70px;
`;

const MediaWrapper = styled.div`
  position: relative;
  width: 100%;
  background-color: ${themeColor("gray.200")};
  border-radius: 15px;
  margin-top: 30px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    object-fit: cover;
  }
  animation: 2s show ease;
`;

const UpgradeFeaturePlayer = styled.video`
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 15px;
  object-fit: cover;
  cursor: pointer;
  &::-webkit-media-controls-fullscreen-button {
    display: none;
  }
  &::-webkit-media-controls-play-button {
  }
  &::-webkit-media-controls-timeline {
    display: none;
  }
  &::-webkit-media-controls-current-time-display {
  }
  &::-webkit-media-controls-time-remaining-display {
  }
  &::-webkit-media-controls-mute-button {
  }
  &::-webkit-media-controls-toggle-closed-captions-button {
    display: none;
  }
  &::-webkit-media-controls-volume-slider {
  }
`;

const ContactFormContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${themeColor("white")};
  padding: 40px;
  border-left: solid 1px;
  border-color: ${themeColor("gray.200")};
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
`;

const FormCloseBtn = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
    stroke: ${themeColor("gray.400")};
  }
`;

const CalendlyContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const LIST_HEADER_TEXT = "We’ve got you cover with more amazing features!";
const LIST_SUBHEADER_TEXT = "Upgrade to get more from Peech!";

export default function UpgradeFeature({ featureId }: { featureId: string | null }) {
  const dispatch = useDispatch<Dispatch>();
  const usersPlan = useSelector(planSelectors.selectUsersPlan);
  const { UPGRADE_MODAL_FEATURES } = useAppConfig();
  const featuresToShow = useMemo(() => {
    const filteredFeatures = UPGRADE_MODAL_FEATURES.filter(
      (x) => !x.showOnlyByDemand || (featureId && x.showOnlyByDemand && x.featureIds?.includes(featureId)),
    );

    filteredFeatures.sort((a, b) => {
      const aIncludes = a.featureIds?.includes(featureId!);
      const bIncludes = b.featureIds?.includes(featureId!);

      if (aIncludes && !bIncludes) return -1;
      if (!aIncludes && bIncludes) return 1;
      return 0;
    });

    return filteredFeatures;
  }, [featureId, UPGRADE_MODAL_FEATURES]);
  const [selectedFeature, setSelectedFeature] = useState<UpgradeModelFeature | null>(null);
  const [isContactFormVisible, setIsContactFormVisible] = useState(false);
  const gridScrollRef: RefObject<HTMLDivElement> = useRef(null);
  const { height } = useResizeObserver({ ref: gridScrollRef });
  const [isVideoMuted, setIsVideoMuted] = useState(true);

  const showScrollButton = useMemo(
    () => gridScrollRef.current && gridScrollRef.current.scrollHeight > gridScrollRef.current.clientHeight,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gridScrollRef.current?.scrollHeight, height],
  );

  const isFeatureActive = useMemo(() => {
    if (isNil(usersPlan?.tier)) {
      return false;
    }
    return !!(usersPlan && selectedFeature && selectedFeature?.tier <= usersPlan?.tier);
  }, [usersPlan, selectedFeature]);

  const handleScroll = () => {
    if (gridScrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = gridScrollRef.current;

      if (scrollTop === scrollHeight - clientHeight) {
        gridScrollRef.current.scroll({
          top: 0,
          behavior: "smooth",
        });
      } else if (scrollTop === 0) {
        gridScrollRef.current.scroll({
          top: gridScrollRef.current.scrollHeight,
          behavior: "smooth",
        });
      } else {
        gridScrollRef.current.scroll({
          top: scrollTop + clientHeight,
          behavior: "smooth",
        });
      }
    }
  };

  const onFeatureItemClick = (item: UpgradeModelFeature) => {
    plansAnalytics.trackFeatureSelected(item.title);
    setIsContactFormVisible(false);
    setSelectedFeature(null);
    setSelectedFeature(item);
  };

  const videoRef = useRef<HTMLVideoElement>(null);
  const onVideoVolumeChanged = useCallback(() => {
    if (!videoRef?.current) {
      return;
    }
    setIsVideoMuted(videoRef.current.muted);
  }, []);

  const togglePlayPause = useCallback(() => {
    if (!videoRef?.current) {
      return;
    }
    if (videoRef.current.paused) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, []);

  const onFeatureButtonClick = useCallback(() => {
    if (!selectedFeature) {
      return;
    }
    const { actionType, button, title } = selectedFeature;
    plansAnalytics.trackFeatureCTAButtonClick(title ?? "");

    if (actionType === "link") {
      window.open(button.link, "_blank");
    } else if (actionType === "calendly") {
      videoRef?.current?.pause();
      setIsContactFormVisible(true);
    }
  }, [selectedFeature]);

  useEffect(() => {
    UserEngagementService.hideDefaultLauncher();

    return () => {
      UserEngagementService.showDefaultLauncher();
    };
  }, []);

  useEffect(() => {
    dispatch.plans.getPlan();
    dispatch.users.fetchCurrentUser();
  }, [dispatch]);

  useEffect(() => {
    setSelectedFeature(featuresToShow?.[0]);
  }, [featuresToShow]);

  if (!selectedFeature) {
    return null;
  }

  return (
    <Container>
      <LeftSide>
        <ScrollButton hide={!showScrollButton} onClick={handleScroll}>
          <Icon.MouseScroll />
        </ScrollButton>
        <GridScroll ref={gridScrollRef}>
          <StickyHeader>
            <ListHeader>{LIST_HEADER_TEXT}</ListHeader>
            <ListSubHeader>{LIST_SUBHEADER_TEXT}</ListSubHeader>
          </StickyHeader>

          <FeaturesList>
            {featuresToShow.map((item) => (
              <FeatureItemComponent
                key={item.title}
                item={item}
                onFeatureItemClick={onFeatureItemClick}
                isSelected={item.title === selectedFeature.title}
              />
            ))}
          </FeaturesList>
          <TransparentBottomBlock />
        </GridScroll>
      </LeftSide>
      <RightSide key={selectedFeature.title}>
        {isFeatureActive ? (
          <FeatureIsActive>Included in your current plan</FeatureIsActive>
        ) : (
          <ButtonWrapper>
            <RainbowButton
              label={selectedFeature.button.label}
              showIcon={selectedFeature.button.icon}
              onClick={onFeatureButtonClick}
            />
          </ButtonWrapper>
        )}

        <GridScrollRight>
          <StickyHeaderRight>
            <FeatureTitle>{selectedFeature.title}</FeatureTitle>
          </StickyHeaderRight>
          <FeatureDescription>{selectedFeature.description}</FeatureDescription>
          <MediaWrapper>
            {selectedFeature.videoUrl && (
              <UpgradeFeaturePlayer
                ref={videoRef}
                src={selectedFeature.videoUrl}
                onClick={togglePlayPause}
                onVolumeChange={onVideoVolumeChanged}
                autoPlay
                loop
                muted={isVideoMuted}
                controls
                disablePictureInPicture
                controlsList="nofullscreen nodownload noremoteplayback noplaybackrate disablePictureInPicture"
              />
            )}
            {!selectedFeature.videoUrl && selectedFeature.imageUrl && (
              <img src={selectedFeature.imageUrl} alt={selectedFeature.title} />
            )}
          </MediaWrapper>
        </GridScrollRight>
        {isContactFormVisible && (
          <ContactFormContainer>
            <FormCloseBtn onClick={() => setIsContactFormVisible(false)}>
              <Icon.Close />
            </FormCloseBtn>
            <CalendlyContainer>
              <CalendlyContactForm onSubmit={() => setIsContactFormVisible(false)} />
            </CalendlyContainer>
          </ContactFormContainer>
        )}
      </RightSide>
    </Container>
  );
}
