import { useMemo, useState } from "react";
import styled from "styled-components/macro";
import Fuse from "fuse.js";

import { themeColor, themeProp } from "src/utils/styledComponents.utils";

import Icon, { ICON_SIZES } from "src/components/common/Icon";
import { Box } from "src/components/common/layout/Box.styled";
import { Stack } from "src/components/common/layout/Stack.styled";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 25px;
  overflow: hidden;
`;

const TopSection = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 5px 10px;
  border: 1px solid ${themeColor("pink.500")};
  border-radius: 15px;
  color: rgb(97, 97, 97);
  outline: none;

  &::placeholder {
    color: rgb(177, 177, 177);
  }
`;

const IconSizes = styled(Stack)`
  align-items: baseline;
`;

const Tag = styled(Box)`
  width: fit-content;
  padding: 4px 10px;
  border: 1px solid ${themeColor("pink.500")};
  border-radius: ${themeProp("borderRadii.medium")}px;
  font-size: 14px;
  line-height: 14px;
  color: ${themeColor("pink.500")};
`;

const CodeExamples = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px;
  border: 1px solid ${themeProp("colors.gray.300")};
  border-radius: ${themeProp("borderRadii.large")}px;
  background-color: #f7f7f7;
  font-size: 20px;
  color: crimson;
`;

const Scroll = styled(Box)`
  flex: 1;
  overflow: scroll;
`;

const IconGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 25px;
`;

const IconGridItem = styled.div`
  position: relative;
  padding: 50px;
  border: 1px solid #eaeaea;
  border-radius: ${themeProp("borderRadii.large")}px;
  background-color: #fefefe;
`;

export default function IconsPreviewPage() {
  const sizes = Object.keys(ICON_SIZES) as Array<keyof typeof ICON_SIZES>;
  const [query, setQuery] = useState("");
  const icons = useMemo(() => Object.entries(Icon).map(([name, Component]) => ({ name, Component })), []);
  const fuse = useMemo(() => new Fuse(icons, { keys: ["name"] }), [icons]);
  const searchResults = useMemo(() => fuse.search(query).map((result) => result.item), [fuse, query]);
  const [showcaseIcon] = searchResults.length ? searchResults : icons;

  return (
    <Container>
      <TopSection>
        <div>
          <StyledInput
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            type="text"
            placeholder="Search icons"
          />

          <IconSizes direction="row" spacing={28} marginBlock={28}>
            {sizes.map((size) => (
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <showcaseIcon.Component key={size} size={size} />
                <Tag marginTop={10}>{size}</Tag>
              </div>
            ))}
          </IconSizes>
        </div>

        <CodeExamples>
          <code>{`<Icon.${showcaseIcon.name} size="large" />`}</code>
          <code>{`<Icon.${showcaseIcon.name} size="full" />`}</code>
          <code>{`<Icon.${showcaseIcon.name} size={32} />`}</code>
        </CodeExamples>
      </TopSection>

      <Scroll marginTop={20}>
        <IconGrid>
          {(query ? searchResults : icons).map((icon) => (
            <IconGridItem key={icon.name}>
              <icon.Component size="full" color="pink.500" />
              <Tag position="absolute" bottom={18} left={18}>
                {icon.name}
              </Tag>
            </IconGridItem>
          ))}
        </IconGrid>
      </Scroll>
    </Container>
  );
}
