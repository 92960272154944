import { components, MultiValueProps } from "react-select";

import styled from "styled-components/macro";

import { SequenceFilterOption } from "src/components/features/SmartLibrary/filtering/filtersSelect/SequenceFilterSelect";

const IconWrapper = styled.div`
  width: 22px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: unset !important;
    max-height: unset !important;
    min-width: unset !important;
    min-height: unset !important;
    width: 22px;
    height: 20px;
    object-fit: contain;
  }
`;

export default function SequenceFilterMultiValueIcon(props: MultiValueProps<SequenceFilterOption>) {
  const { data } = props;
  return (
    <components.MultiValue {...props}>
      <IconWrapper>{data.icon}</IconWrapper>
    </components.MultiValue>
  );
}
