import { EntityId } from "@reduxjs/toolkit";
import { ChangeEvent, KeyboardEvent, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import styled from "styled-components/macro";

import { footageSelectors } from "src/models/Footage.model";
import { sequenceSelectors } from "src/models/Sequence.model";
import { Dispatch, RootState } from "src/models/store";
import { Footage, FootageOrigin } from "src/network/graphql/generatedGraphqlSDK";

import { themeColor } from "src/utils/styledComponents.utils";

import * as sequenceAnalytics from "src/analytics/sequence.analytics";
import { StyledTooltip } from "src/components/features/SmartLibrary/SmartLibrary.styled";

const Container = styled.div`
  width: fit-content;
  max-width: 100%;
  overflow: hidden;
`;

const EditableText = styled.input`
  width: 50vw;
  max-width: 100%;
  outline: none;
  color: ${themeColor("gray.900")};
  border: solid 1px;
  border-color: ${themeColor("blue.300")};
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
`;

const VideoTitle = styled.div`
  width: 100%;
  color: ${themeColor("gray.900")};
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  cursor: pointer;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default function EditableSequenceTitle({ sequenceSid }: { sequenceSid: string | undefined }) {
  const dispatch = useDispatch<Dispatch>();
  const sequence = useSelector((state: RootState) => sequenceSelectors.selectById(state, sequenceSid ?? ""));
  const footage = useSelector((state: RootState) => sequence?.footageSid && footageSelectors.selectById(state, sequence.footageSid as EntityId)) as Footage; // prettier-ignore
  const footageOrigin = useSelector((state: RootState) => footageSelectors.selectFootageOrigin(state, footage?.id)) || FootageOrigin.UserUpload; // prettier-ignore
  const clipData = useSelector((state: RootState) => sequence?.sid && footage && footageSelectors.selectClipDataByFootageAndExternalSequenceSid(state, footage, sequence?.sid)); // prettier-ignore
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState("");

  const onVideoTitleClick = useCallback(() => {
    setIsEditing(true);
  }, []);

  const onVideoTitleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEditedText(e.target.value);
  }, []);

  const onVideoTitleBlur = useCallback(async () => {
    setIsEditing(false);

    if (sequence && footage?.id && clipData && sequence.title !== editedText) {
      if (editedText) {
        try {
          await dispatch.footage.renameClipSequence({
            footageId: footage.id,
            clipId: clipData.clipId as string,
            id: clipData.clipSequenceId as string,
            input: {
              title: editedText,
            },
          });

          sequenceAnalytics.renameSequence(
            "editor-header",
            footage.id,
            clipData.clipSequenceId as string,
            footageOrigin,
          );
        } catch (error) {
          // eslint-disable-next-line
          console.log("onSequenceRename", error);
        }
      } else {
        setEditedText(sequence?.title ?? "");
      }
    }
  }, [clipData, dispatch, editedText, footage?.id, footageOrigin, sequence]);

  const onVideoTitleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      e.stopPropagation();
      const pattern = /[A-Za-z0-9 \-_/]/gi;
      const { key } = e;

      if (!pattern.test(key)) {
        e.preventDefault();
      }

      if (e.which === 13) {
        e.preventDefault();
        onVideoTitleBlur();
      }
    },
    [onVideoTitleBlur],
  );

  useEffect(() => {
    if (sequence?.sid) {
      if (!footage) {
        dispatch.footage.fetchFootage(sequence.footageSid);
      }
    }
  }, [dispatch.footage, footage, sequence?.footageSid, sequence?.sid]);

  useEffect(() => {
    setEditedText(sequence?.title ?? "");
  }, [sequence?.title]);

  return (
    <Container>
      {isEditing ? (
        <EditableText
          type="text"
          value={editedText}
          onChange={onVideoTitleChange}
          onBlur={onVideoTitleBlur}
          onKeyDown={onVideoTitleKeyDown}
          autoFocus
        />
      ) : (
        <StyledTooltip key={editedText} text={editedText} placement="bottom">
          <VideoTitle onClick={onVideoTitleClick}>{editedText}</VideoTitle>
        </StyledTooltip>
      )}
    </Container>
  );
}
