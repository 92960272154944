/* eslint-disable no-async-promise-executor */
import { isNil } from "lodash/fp";
import { Fragment, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { footageSelectors } from "src/models/Footage.model";
import { sequenceSelectors } from "src/models/Sequence.model";
import { Dispatch, RootState } from "src/models/store";
import {
  Clip,
  ClipSequenceStatus,
  ClipSequenceType,
  DownloadStatus,
  FootageOrigin,
  SequenceInteraction,
} from "src/network/graphql/generatedGraphqlSDK";

import { AspectRatio, getAspectRatioValue } from "src/constants/video.constants";
import useOverflowCheck from "src/hooks/useOverflowCheck";
import { isIOS } from "src/utils/mobile.utils";
import { absoluteRoutes, applyPathParams } from "src/utils/routes.utils";
import { themeColor } from "src/utils/styledComponents.utils";
import { formatSeconds } from "src/utils/time.utils";

import * as sequenceAnalytics from "src/analytics/sequence.analytics";

import styled from "styled-components/macro";
import { ifProp } from "styled-tools";

import { useLocation, useNavigate } from "react-router-dom";
import PEECH_THUMB from "src/assets/images/peech_thumbnail.png";
import Icon from "src/components/common/Icon";
import ActionsMenuOption from "src/components/common/actionsMenu/ActionsMenuOption";
import ActionsMenuPopover, { ActionsMenuOptionProps } from "src/components/common/actionsMenu/ActionsMenuPopover";
import AutoSizer from "src/components/common/layout/AutoSizer";
import { CircularLoader } from "src/components/common/loaders/CircularLoader";
import MediaSource from "src/components/common/media/MediaSource";
import {
  DarkOverlay,
  FailedMark,
  GridItem,
  ProgressContainer,
  PrevItemContentContainer,
  StyledTooltip,
  TitleWrapper,
} from "src/components/features/SmartLibrary/SmartLibrary.styled";
import { ASPECT_RATIO_OPTIONS_DICT, CLIPS_ORDER } from "src/components/features/SmartLibrary/smartLibrary.constants";
import {
  checkIfNeedToReplaceExampleSequence,
  onSequenceDuplicate,
  onSequenceTranslate,
} from "src/components/features/SmartLibrary/smartLibrary.utils";
import ConfirmationModal from "src/components/modals/ConfirmationModal";
import EntityTitleModal from "src/components/modals/EntityTitleModal";
import NotSupportedModal from "src/components/modals/NotSupportedModal";
import ProjectPreviewModal from "src/components/modals/ProjectPreviewModal";
import TranslateSequenceModal from "src/components/modals/TranslateSequenceModal";
import { useAppConfig } from "src/components/providers/AppConfigProvider";
import { useModal } from "src/components/providers/ModalProvider";
import errorMonitoringService from "src/services/ErrorMonitoring.service";
import { downloadSubtitles } from "src/utils/closeCaption.utils";
import { downloadInfoSelectors } from "src/models/DownloadInfo.model";

const StyledGridItem = styled(GridItem)`
  box-shadow: 0px 4px 24px 0px rgba(3, 15, 20, 0.15);
  border-radius: 15px;

  &:hover {
    box-shadow: 0px 4px 25px 0px rgba(5, 32, 51, 0.7);
  }
  transition: box-shadow 0.5s ease;
`;

const ImgWrapper = styled.div`
  border: solid 1px;
  border-color: ${themeColor("blue.30")};
  position: relative;
  width: fit-content;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  background: url("${PEECH_THUMB}") no-repeat center center;
  background-size: contain;
`;

export const Cropped = styled.div`
  position: absolute;
  top: 11px;
  right: 11px;
  padding: 3px 5px;
  border-radius: 10px;
  border: solid 1px;
  border-color: rgba(255, 255, 255, 0.14);
  background-color: rgba(255, 255, 255, 0.12);
  border-color: ${themeColor("white")};
  color: ${themeColor("white")};
  font-family: "Open Sans";
  font-size: 8px;
  font-weight: 600;
  line-height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s ease;
`;

const FavBtn = styled.div`
  position: absolute;
  top: 4px;
  left: 4px;
  width: 30px;
  height: 30px;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  svg {
    margin: 2px 1px 0 1px;
    path {
      fill: ${themeColor("white")};
      width: 12px;
    }
  }

  transition: opacity 0.5s ease, background-color 0.5s ease;
`;

const SeqTitle = styled.div`
  width: calc(100% - 100px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  svg {
    width: 24px;
    height: auto;
    color: ${themeColor("blue.50")};
    transition: color 0.5s ease;
  }
`;

const Container = styled.div<{ isClickable: boolean; showFavIconByDefault: boolean }>`
  position: relative;
  width: 100%;
  border-radius: 15px;
  cursor: ${ifProp("isClickable", "pointer", "auto")};
  padding-inline: 10px;
  background-color: ${themeColor("white")};

  border: solid 1px;
  border-color: ${themeColor("white")};

  ${FavBtn} {
    opacity: ${ifProp("showFavIconByDefault", "1", "0")};
    background: ${ifProp("showFavIconByDefault", "rgba(0, 0, 0, 0.20)", "transparent")};
    &:hover {
      background-image: rgba(0, 0, 0, 0.2);
    }
    transition: opacity 0.5s ease, background-image 0.5s ease;
  }

  ${DarkOverlay} {
    opacity: 0;
    transition: opacity 0.5s ease;
  }

  &:hover {
    ${FavBtn} {
      opacity: 1;
    }
    ${DarkOverlay} {
      opacity: 0.5;
    }
    ${Cropped} {
      opacity: 0.4;
    }
    ${SeqTitle} {
      svg {
        color: ${themeColor("blue.900")};
      }
    }
  }
`;

const SequenceItemHeader = styled.div`
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  span {
    height: 100%;
    display: flex;
    align-items: center;
    font-family: "Open Sans";
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    svg {
      margin-right: 6px;
    }
  }
`;

const OptionsMenuWrapper = styled.div`
  position: absolute;
  top: 0;
  right: -10px;
  width: 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const SequenceItemContent = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 70px);
  background-color: ${themeColor("white")};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ThumbnailWrapper = styled.div`
  position: relative;
  width: 100%;
  flex: 1;
  overflow: hidden;
  aspect-ratio: 16 / 9;

  img {
    border-radius: 10px;
    object-fit: cover;
  }
`;

const SequenceItemFooter = styled.div`
  position: relative;
  width: 100%;
  height: 20px;
  margin-block: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${themeColor("white")};
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
`;

const SeqNameText = styled.span`
  width: 70%;
  display: inline-block;
  padding: 2px 0 0 2px;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: ${themeColor("gray.900")};
  user-select: none;
`;

const DurationBlock = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  background-color: ${themeColor("blue.30")};
  border-radius: 10px;
  font-size: 14px;
  padding: 4px 7px;
  margin-right: 12px;
`;

const LanguageMarkWrapper = styled.div`
  width: 24px;
  margin-inline: 5px;
  margin-top: 1px;
  border-radius: 4px;
  overflow: hidden;
  text-transform: capitalize;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: ${themeColor("gray.400")};
`;

const StyledAutoSizer = styled(AutoSizer)`
  display: flex;
  justify-content: center;
`;

const StyledFailedMark = styled(FailedMark)`
  top: 7px;
  left: 2px;
  right: unset;
`;

const DownloadMark = styled.div`
  width: fit-content;
  height: fit-content;
  padding: 4px 6px 3px 6px;
  border-radius: 10px;
  background-color: ${themeColor("gray.200")};
  font-size: 10px;
  line-height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export interface SequenceItemProps {
  footageId: string;
  clip: Clip;
  sid: string | null;
  title: string;
  status: ClipSequenceStatus;
  ratio: AspectRatio;
  languageCode: string;
  isFavorite: boolean;
  type: ClipSequenceType;
  openTrigger?: sequenceAnalytics.PreviewTrigger;
  downloadedAt?: string;
}

export default function SequenceItem({
  footageId,
  clip,
  sid,
  title,
  status,
  ratio,
  languageCode,
  isFavorite,
  type,
  openTrigger = "clip",
  downloadedAt,
}: SequenceItemProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { CHUNKS_URL, CONTENT_URL, CLIP_SEQUENCES_ORDER_DICT } = useAppConfig();
  const dispatch = useDispatch<Dispatch>();
  const sequence = useSelector((state: RootState) => sequenceSelectors.selectById(state, sid ?? ""));

  const authToken = useSelector((state: RootState) => state.session.authToken);
  const clipSequenceId = clip?.sequences?.find((seq) => seq.externalSequenceId === sid)?.id ?? "";
  const footageOrigin =
    useSelector((state: RootState) => footageSelectors.selectFootageOrigin(state, footageId)) ||
    FootageOrigin.UserUpload;
  const downloadInfo = useSelector((state: RootState) =>
    downloadInfoSelectors.selectDownloadInfoBySequenceId(state, sequence?.sid || ""),
  );
  // TODO: I think this is not needed anymore
  const sequenceUpdating = useSelector(
    (state: RootState) =>
      !!state.loading.effects.sequences.renameSequence.loading ||
      !!state.loading.effects.sequences.duplicateSequence.loading ||
      !!state.loading.effects.sequences.deleteSequence.loading,
  );

  const isClickable = useMemo(() => status === ClipSequenceStatus.Ready, [status]);
  const isFailure = useMemo(() => status === ClipSequenceStatus.Failure, [status]);

  const sequenceIsDownloading = useMemo(() => downloadInfo?.status === DownloadStatus.Processing, [downloadInfo]);

  const shouldDisplayLoader = useMemo(
    () => (!isClickable && !isFailure) || sequenceUpdating,
    [isClickable, isFailure, sequenceUpdating],
  );

  const { openModal } = useModal();
  const { ref: itemTitleRef, isOverflowing } = useOverflowCheck();

  // eslint-disable-next-line no-confusing-arrow
  const thumbnailUrl = useSelector((state: RootState) => sid ? sequenceSelectors.selectThumbnailUrl(state, sid, CHUNKS_URL, 1) : null); // prettier-ignore

  const sequenceDuration = useMemo(() => {
    if (sequence?.duration) {
      return formatSeconds(sequence?.duration, "mm:ss");
    }
    return null;
  }, [sequence?.duration]);

  const onProjectEditClick = useCallback(
    (externalSequenceId: string) => {
      navigate(
        applyPathParams(absoluteRoutes.projectSequence.children.visualsEditor, { sequenceSid: externalSequenceId }),
      );
      sequenceAnalytics.openSequence(
        "preview-modal",
        { ...sequence, type, isFavorite: !!isFavorite, ratio, footageOrigin } || {},
      );
    },
    [navigate, sequence, type, isFavorite, ratio, footageOrigin],
  );

  const onOptionsMenuClick = useCallback((e: any) => {
    (document.activeElement as HTMLInputElement)?.blur?.();
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const onSequenceRename = useCallback(
    async (newTitle: string) => {
      if (clipSequenceId) {
        try {
          await dispatch.footage.renameClipSequence({
            footageId,
            clipId: clip.id,
            id: clipSequenceId,
            input: {
              title: newTitle,
            },
          });
          dispatch.footage.interactClipSequence({
            footageId: sequence?.footageSid as string,
            externalSequenceId: sequence?.sid as string,
            sequenceInteraction: "edited" as SequenceInteraction,
          });

          sequenceAnalytics.renameSequence("context-menu", footageId, clipSequenceId, footageOrigin);
        } catch (error) {
          // eslint-disable-next-line
          console.log("onSequenceRename", error);
        }
      }
    },
    [clipSequenceId, dispatch.footage, footageId, clip.id, sequence?.footageSid, sequence?.sid, footageOrigin],
  );

  const onSequenceDelete = useCallback(async () => {
    try {
      await dispatch.footage.deleteClipSequence({
        footageId,
        clipId: clip.id,
        id: clipSequenceId,
      });
      sequenceAnalytics.deleteSequence("context-menu", footageId, clipSequenceId, footageOrigin);
    } catch (error) {
      // eslint-disable-next-line
      console.log("onSequenceDelete", error);
    }
  }, [clip.id, clipSequenceId, dispatch.footage, footageId, footageOrigin]);

  const onDuplicate = useCallback(
    async (ratioTarget: AspectRatio) => {
      await onSequenceDuplicate(
        dispatch,
        footageId,
        clip,
        clipSequenceId,
        ratioTarget,
        ratio,
        "context-menu",
        footageOrigin,
      );
    },

    [dispatch, footageId, clip, clipSequenceId, ratio, footageOrigin],
  );

  const onTranslate = useCallback(
    async (languageCodeTarget: string) => {
      await onSequenceTranslate(
        dispatch,
        footageId,
        clip,
        clipSequenceId,
        languageCodeTarget,
        languageCode,
        "context-menu",
        footageOrigin,
      );
    },
    [dispatch, footageId, clip, clipSequenceId, languageCode, footageOrigin],
  );

  const onFavoriteClick = useCallback(async () => {
    try {
      if (!isFavorite) {
        await dispatch.footage.addClipSequenceFavorite({
          footageId,
          clipId: clip.id,
          id: clipSequenceId,
        });
      } else {
        await dispatch.footage.deleteClipSequenceFavorite({
          footageId,
          clipId: clip.id,
          id: clipSequenceId,
        });
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log("onFavoriteClick", error);
    }
  }, [clip.id, clipSequenceId, dispatch.footage, footageId, isFavorite]);

  const showRenameModal = useCallback(() => {
    openModal(
      ({ close, ...props }) => (
        <EntityTitleModal
          {...props}
          onSubmit={onSequenceRename}
          close={close}
          modalTitle="Rename your video"
          titlePlaceholder={title}
          submitLabel="Submit"
        />
      ),
      { hideCloseButton: true },
    );
  }, [onSequenceRename, openModal, title]);

  const showDeleteModal = useCallback(() => {
    openModal(
      ({ close }) => (
        <ConfirmationModal
          close={close}
          onApprove={onSequenceDelete}
          title="Delete"
          description="Are you sure you want to delete this Video Snippet?"
          approveLabel="OK"
          declineLabel="Cancel"
        />
      ),
      { hideCloseButton: true },
    );
  }, [onSequenceDelete, openModal]);

  const showTranslateModal = useCallback(() => {
    openModal(
      ({ close }) => (
        <TranslateSequenceModal close={close} onApprove={onTranslate} initialLanguageCode={languageCode} />
      ),
      { hideCloseButton: true },
    );
  }, [languageCode, onTranslate, openModal]);

  const onDownloadSubtitles = useCallback(async () => {
    try {
      if (sequence?.sid && CONTENT_URL) {
        await downloadSubtitles({ sequenceSid: sequence?.sid, contentUrl: CONTENT_URL }, authToken);
        sequenceAnalytics.downloadSubtitles("context-menu", footageId, clipSequenceId, footageOrigin);
      }
    } catch (error) {
      errorMonitoringService.trackError(error as Error);
    }
  }, [CONTENT_URL, authToken, clipSequenceId, footageId, footageOrigin, sequence?.sid]);

  const onUpgrade = useCallback(
    (planId: string | undefined) => {
      if (planId) {
        navigate(`${absoluteRoutes.plans}?planId=${planId}&chargeType=1`);
      } else {
        navigate(absoluteRoutes.plans);
      }
    },
    [navigate],
  );

  const showProjectPreviewModal = useCallback(
    (externalSequenceId: string, sequenceTag: String, download = false) => {
      if (isIOS()) {
        openModal(() => <NotSupportedModal />);
      } else {
        openModal(
          ({ close }) => (
            <ProjectPreviewModal
              close={() => {
                navigate(`${location.pathname}`, { replace: true });
                close();
              }}
              externalSequenceId={externalSequenceId}
              tag={sequenceTag}
              onProjectEditClick={onProjectEditClick}
              onTranslate={onTranslate}
              onDuplicate={onDuplicate}
              onUpgrade={onUpgrade}
              download={download}
              onOpen={() => {
                navigate(`${location.pathname}#preview`, { replace: true });
              }}
            />
          ),
          { hideCloseButton: true, fullScreen: false, hidePaddings: true, transparent: true },
        );

        // if download is true, set sequence interaction as edit
        if (download) {
          dispatch.footage.interactClipSequence({
            footageId: sequence?.footageSid as string,
            externalSequenceId: sequence?.sid as string,
            sequenceInteraction: "edited" as SequenceInteraction,
          });
        }
      }
    },
    [
      onDuplicate,
      onProjectEditClick,
      onTranslate,
      onUpgrade,
      openModal,
      navigate,
      location.pathname,
      dispatch,
      sequence?.sid,
      sequence?.footageSid,
    ],
  );

  const onSequenceItemClick = useCallback(
    async (download = false) => {
      if (!isClickable) {
        return;
      }
      let tag = "";
      if (type === ClipSequenceType.Manual || type === ClipSequenceType.Original) {
        tag = CLIPS_ORDER[clip.type] || "";
      } else {
        tag = CLIP_SEQUENCES_ORDER_DICT[type] || "";
      }
      // current sequence is from example project need to clone and replace before redirect
      const replaceResponse = await checkIfNeedToReplaceExampleSequence(dispatch, footageId, clip.id, clipSequenceId);
      // redirect to new externalSequenceId
      if (replaceResponse?.externalSequenceId) {
        showProjectPreviewModal(replaceResponse.externalSequenceId, tag, download);
        return;
      }

      // track event open sequence
      sequenceAnalytics.previewSequence(
        openTrigger,
        { ...sequence, type, isFavorite: !!isFavorite, ratio, footageOrigin } || {},
      );
      showProjectPreviewModal(sequence?.sid as string, tag, download);
    },
    [
      isClickable,
      dispatch,
      footageId,
      clip,
      clipSequenceId,
      openTrigger,
      sequence,
      type,
      isFavorite,
      ratio,
      footageOrigin,
      showProjectPreviewModal,
      CLIP_SEQUENCES_ORDER_DICT,
    ],
  );

  const getMenuOptions = useCallback(() => {
    const options: ActionsMenuOptionProps[] = [
      {
        name: sequenceIsDownloading ? "Preparing download" : "Download",
        icon: sequenceIsDownloading ? <CircularLoader size={20} thickness={3} marginRight={10} /> : <Icon.Download />,
        action: sequenceIsDownloading ? () => {} : () => onSequenceItemClick(true),
      },
      {
        name: "Rename",
        icon: <Icon.Edit />,
        action: showRenameModal,
        hidden: isFailure,
      },

      {
        name: "Duplicate",
        icon: <Icon.AlarmClock />,
        action: () => onDuplicate(ratio),
        hidden: isFailure,
      },
    ];
    Object.keys(ASPECT_RATIO_OPTIONS_DICT).forEach((aspRat) => {
      if (aspRat !== ratio) {
        options.push({
          name: `Duplicate - ${aspRat}`,
          icon: <Icon.AlarmClock />,
          action: () => onDuplicate(aspRat as AspectRatio),
          hidden: isFailure,
        });
      }
    });
    options.push({
      name: "Download subtitles",
      icon: <Icon.Download />,
      action: onDownloadSubtitles,
      hidden: isFailure,
    });
    options.push({
      name: "Translate",
      icon: <Icon.Translate />,
      action: showTranslateModal,
      hidden: isFailure,
    });
    options.push({
      name: "Delete",
      icon: <Icon.Trash />,
      action: showDeleteModal,
      hidden: false,
    });
    return options;
  }, [
    onDownloadSubtitles,
    isFailure,
    onDuplicate,
    ratio,
    showDeleteModal,
    showRenameModal,
    showTranslateModal,
    onSequenceItemClick,
    sequenceIsDownloading,
  ]);

  return (
    <StyledGridItem>
      <Container isClickable={isClickable} onClick={() => onSequenceItemClick(false)} showFavIconByDefault={isFavorite}>
        <SequenceItemHeader>
          {downloadedAt && !sequenceIsDownloading && <DownloadMark>Downloaded</DownloadMark>}
          {sequenceIsDownloading && (
            <DownloadMark>
              <CircularLoader size={10} thickness={2} marginRight={2} /> Preparing download
            </DownloadMark>
          )}
          {isFailure && (
            <StyledFailedMark>
              <Fragment>
                <span>Failed</span>
                <Icon.Attention />
              </Fragment>
            </StyledFailedMark>
          )}
        </SequenceItemHeader>

        <SequenceItemContent>
          <ThumbnailWrapper>
            <StyledAutoSizer aspectRatio={getAspectRatioValue(ratio)}>
              {(size) => (
                <ImgWrapper style={{ width: size.width, height: size.height }}>
                  {isClickable && <DarkOverlay />}
                  {isClickable && (
                    <FavBtn
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        onFavoriteClick();
                      }}
                    >
                      {isFavorite ? <Icon.HeartFilled /> : <Icon.HeartEmpty />}
                    </FavBtn>
                  )}

                  <MediaSource src={thumbnailUrl}>
                    {(src) => <img src={src} alt={title} width="100%" height="100%" />}
                  </MediaSource>
                </ImgWrapper>
              )}
            </StyledAutoSizer>

            {shouldDisplayLoader && (
              <PrevItemContentContainer>
                <ProgressContainer aspectRatio={ratio}>
                  <CircularLoader size={50} thickness={3} />
                </ProgressContainer>
              </PrevItemContentContainer>
            )}
          </ThumbnailWrapper>
        </SequenceItemContent>

        <SequenceItemFooter>
          <SeqTitle>
            {languageCode && <LanguageMarkWrapper>{languageCode.split("-")[0]}</LanguageMarkWrapper>}

            <SeqNameText>
              <StyledTooltip text={title} placement="bottom" isOverflowing={isOverflowing} disabled={!isOverflowing}>
                <TitleWrapper ref={itemTitleRef}>{title}</TitleWrapper>
              </StyledTooltip>
            </SeqNameText>
          </SeqTitle>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            {status === ClipSequenceStatus.Ready && !isNil(sequence?.duration) && (
              <DurationBlock>{sequenceDuration}</DurationBlock>
            )}
            <OptionsMenuWrapper onClick={onOptionsMenuClick}>
              <ActionsMenuPopover variant="dark" triggerIcon={<Icon.OverflowVertical />}>
                {getMenuOptions().map((option) => (
                  <ActionsMenuOption
                    key={option.name}
                    icon={option.icon}
                    onSelect={option.action}
                    hidden={option.hidden}
                  >
                    {option.name}
                  </ActionsMenuOption>
                ))}
              </ActionsMenuPopover>
            </OptionsMenuWrapper>
          </div>
        </SequenceItemFooter>
      </Container>
    </StyledGridItem>
  );
}
