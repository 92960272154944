import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Dispatch, RootState } from "src/models/store";
import { sequenceSelectors } from "src/models/Sequence.model";
import userEngagementService from "src/services/UserEngagement.service";
import { Chapter, SequenceInteraction } from "src/network/graphql/generatedGraphqlSDK";
import VideoTrimmer from "src/components/features/VideoTrimmer/VideoTrimmer";
import useSequenceVideoEditor from "src/components/features/VideoTrimmer/common/hooks/useInitSequenceVideoEditor";
import { chapterTimeIntervalsToChapters } from "src/components/features/VideoTrimmer/common/converters";
import { ChapterTimeInterval, Word } from "src/types/video-trimmer.types";
import { createGapsDiffDto, createWordsDiffChunksDto } from "src/utils/words.utils";
import { Helmet } from "react-helmet";
import { footageSelectors } from "src/models/Footage.model";
import { EntityId } from "@reduxjs/toolkit";
import { wordsSelectors } from "src/models/Words.model";
import { useAppConfig } from "src/components/providers/AppConfigProvider";
import { SaveStatus } from "src/models/Session.model";
import { useUnmountEffect } from "src/hooks/useUnmountEffect";

export default function SequenceContentEditorPage() {
  const { sequenceSid } = useParams();
  const appConfig = useAppConfig();

  if (!sequenceSid) {
    throw new Error("sequenceSid is required");
  }

  const dispatch = useDispatch<Dispatch>();
  const sequence = useSelector((state: RootState) => sequenceSelectors.selectById(state, sequenceSid));
  const footage = useSelector((state: RootState) => (sequence?.footageSid ? footageSelectors.selectById(state, sequence?.footageSid as EntityId) : null)); // prettier-ignore
  const clipData = useSelector((state: RootState) => sequence?.sid && footage && footageSelectors.selectClipDataByFootageAndExternalSequenceSid(state, footage, sequence?.sid)); // prettier-ignore
  const initialWords = useSelector((state: RootState) => wordsSelectors.selectAbsoluteSequenceWords(state, sequence?.footageSid!, appConfig, sequenceSid)); // prettier-ignore
  const sequenceVideoTrimmerProps = useSequenceVideoEditor(sequenceSid);
  const { chaptersHistory, wordsHistory } = sequenceVideoTrimmerProps;
  const originalWords = useRef<Word[]>([]);
  const currentWordsLatestVersion = useSelector((state: RootState) => wordsSelectors.selectLastVersion(state, sequence?.footageSid!)); // prettier-ignore
  const saveStatus = useSelector((state: RootState) => state.session?.saveStatus);
  const wordsLoading = useSelector(
    (state: RootState) =>
      !!state.loading.effects.words.fetchWords.loading || !!state.loading.effects.words.updateWords.loading,
  );

  useEffect(() => {
    if (!wordsLoading) {
      originalWords.current = initialWords;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialWords, wordsLoading]);

  useEffect(() => {
    userEngagementService.setLauncherPadding({ verticalPadding: 205 });

    return () => {
      userEngagementService.setLauncherPadding({ verticalPadding: 0 });
    };
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log("[cc] SequenceContentEditor page", { saveStatus });

    if (saveStatus === SaveStatus.SAVED) {
      // eslint-disable-next-line no-console
      console.log("[cc] SequenceContentEditor page saveStatus => PENDING");
      dispatch.session.setSaveStatus(SaveStatus.PENDING);
    }
  }, [saveStatus, dispatch.session]);

  useUnmountEffect(async () => {
    dispatch.session.setSaveStatus(SaveStatus.SAVING);
    if (chaptersHistory.canUndo && sequence?.chapters) {
      await dispatch.sequences.reorderSequenceChapters({
        sequenceSid,
        chapters: chapterTimeIntervalsToChapters(
          chaptersHistory.state as ChapterTimeInterval[],
          sequence.chapters as Chapter[],
        ),
      });
    }

    if (wordsHistory.canUndo && originalWords.current.length && wordsHistory.state.length) {
      const gapsDto = createGapsDiffDto(
        wordsHistory.state,
        originalWords.current,
        sequence?.duration as number,
        (clipData as { clipFrom: number }).clipFrom,
      );

      const wordsDto = createWordsDiffChunksDto(
        wordsHistory.state,
        originalWords.current,
        (clipData as { clipFrom: number }).clipFrom,
      );

      if ((gapsDto.length || wordsDto.length) && sequence?.footageSid) {
        // eslint-disable-next-line no-console
        console.log("[cc] before updateWords", { currentWordsLatestVersion });
        await dispatch.words.updateWords({
          footageId: sequence?.footageSid as string,
          input: { arrayOfArrays: wordsDto, removeWords: gapsDto },
        });
        originalWords.current = [];
      }
    }

    if (wordsHistory.canUndo || chaptersHistory.canUndo) {
      await dispatch.footage.interactClipSequence({
        footageId: sequence?.footageSid as string,
        externalSequenceId: sequence?.sid as string,
        sequenceInteraction: "edited" as SequenceInteraction,
      });
    }
    originalWords.current = [];
    dispatch.session.setSaveStatus(SaveStatus.SAVED);
  });

  return (
    <>
      <Helmet>
        <title>{sequence?.title} | Peech - Automated Talk-Videos Creator</title>
      </Helmet>
      <VideoTrimmer {...sequenceVideoTrimmerProps} />
    </>
  );
}
