import { useMemo, useState } from "react";
import { Rnd, Props as RndProps } from "react-rnd";

import { useVideoCrop } from "src/components/features/VideoCropper/providers/VideoCropProvider/VideoCropContext";
import * as cropAnalytics from "src/analytics/sequenceCropEditor.analytics";
import useMousetrap from "src/hooks/useMousetrap";

interface CropRndProps extends RndProps {
  setIsDragging?: (isDragging: boolean) => void;
}

export default function CropRnd({ setIsDragging, children, ...props }: CropRndProps) {
  const { draggingPosition, setDraggingPosition, setCropCords, resizeLimit } = useVideoCrop();
  const [dragAxis, setDragAxis] = useState<"x" | "y" | "none" | "both" | undefined>("both");
  // store last drag data to determine the drag axis when shift key is pressed
  const [lastDragData, setLastDragData] = useState<{ x: number; y: number } | null>(null);

  const { minWidth, minHeight, maxWidth, maxHeight } = useMemo(() => {
    const minW = resizeLimit.limitBy === "width" ? `${resizeLimit.minScale * 100}%` : 0;
    const minH = resizeLimit.limitBy === "height" ? `${resizeLimit.minScale * 100}%` : 0;
    const maxW = resizeLimit.limitBy === "width" ? `${resizeLimit.maxScale * 100}%` : undefined;
    const maxH = resizeLimit.limitBy === "height" ? `${resizeLimit.maxScale * 100}%` : undefined;
    return { minWidth: minW, minHeight: minH, maxWidth: maxW, maxHeight: maxH };
  }, [resizeLimit]);

  // handle shift key press to change drag axis
  useMousetrap(
    ["shift"] as const,
    (e, combo, action) => {
      if (action === "keyup") {
        // reset drag axis to both
        setDragAxis("both");
        return;
      }

      setLastDragData({ x: draggingPosition.x, y: draggingPosition.y });
    },
    { preventDefault: true, allowRepeat: false, action: ["keypress", "keyup"] },
  );

  return (
    <Rnd
      minWidth={minWidth}
      minHeight={minHeight}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      size={{ width: draggingPosition.w, height: draggingPosition.h }}
      position={{ x: draggingPosition.x, y: draggingPosition.y }}
      dragAxis={dragAxis}
      onDragStart={() => {
        setIsDragging && setIsDragging(true);
      }}
      onResizeStart={() => {
        setIsDragging && setIsDragging(true);
      }}
      onDragStop={() => {
        setIsDragging && setIsDragging(false);
        setCropCords();
        // reset drag axis to both
        setDragAxis("both");
        cropAnalytics.trackCropResizeAndPosition("crop-rnd");
      }}
      onResizeStop={() => {
        setIsDragging && setIsDragging(false);
        setCropCords();
        cropAnalytics.trackCropResizeAndPosition("crop-rnd");
      }}
      onDrag={(e, d) => {
        // determine drag axis when shift key is pressed
        if (e.shiftKey && lastDragData && dragAxis === "both") {
          const deltaX = d.x - lastDragData.x;
          const deltaY = d.y - lastDragData.y;
          const absDeltaX = Math.abs(deltaX);
          const absDeltaY = Math.abs(deltaY);
          if (absDeltaX > absDeltaY) {
            setDragAxis("x");
          } else if (absDeltaX < absDeltaY) {
            setDragAxis("y");
          }
        }
        setDraggingPosition({ ...draggingPosition, ...d });
      }}
      onResize={(e, direction, ref, delta, position) => {
        setDraggingPosition({
          w: ref.style.width,
          h: ref.style.height,
          x: position.x,
          y: position.y,
        });
      }}
      {...props}
    >
      {children}
    </Rnd>
  );
}
