import { AlignType } from "src/components/features/VideoCropper/hooks/useCropData";
import analyticsService from "src/services/Analytics.service";
import { CropsStyle } from "src/types/video-cropper.types";

export const trackSeekBackward = (trigger: "backward-button" | "keyboard-shortcut") =>
  analyticsService.track("crop-editor-control", { action: "seek-backward", trigger });

export const trackSeekForward = (trigger: "forward-button" | "keyboard-shortcut") =>
  analyticsService.track("crop-editor-control", { action: "seek-forward", trigger });

export const trackRemove = (trigger: "delete-button" | "keyboard-shortcut") =>
  analyticsService.track("crop-editor-edit", { action: "remove", trigger });

export const trackSplit = (trigger: "split-button" | "keyboard-shortcut") =>
  analyticsService.track("crop-editor-edit", { action: "split", trigger });

export const trackTimeResize = (
  trigger: "from-start" | "from-end" | "from-start-keyboard-shortcut" | "from-end-keyboard-shortcut",
) => analyticsService.track("crop-editor-edit", { action: "resize-crop-time", trigger });

export const trackCropResizeAndPosition = (
  trigger: "crop-rnd" | "scale-slider" | "x-input" | "y-input" | "keyboard-shortcut",
) => analyticsService.track("crop-editor-edit", { action: "resize-and-position-crop", trigger });

export const trackCropFill = (trigger: "fill-button" | "keyboard-shortcut") =>
  analyticsService.track("crop-editor-edit", { action: "fill-crop", trigger });

export const trackCropFit = (trigger: "fit-button" | "keyboard-shortcut") =>
  analyticsService.track("crop-editor-edit", { action: "fit-crop", trigger });

export const trackUndo = (trigger: "undo-button" | "keyboard-shortcut") =>
  analyticsService.track("crop-editor-control", { action: "undo", trigger });

export const trackRedo = (trigger: "redo-button" | "keyboard-shortcut") =>
  analyticsService.track("crop-editor-control", { action: "redo", trigger });

export const trackAlignCrop = (trigger: "align-button" | "keyboard-shortcut", align: AlignType) =>
  analyticsService.track("crop-editor-edit", { action: "align-crop", align, trigger });

export const trackCropStyle = (trigger: "brand-color-picker" | "basic-color-picker", cropStyle: CropsStyle) =>
  analyticsService.track("crop-editor-edit", { action: "crop-style", ...cropStyle, trigger });

export const trackCropCopy = (trigger: "copy-button" | "keyboard-shortcut") =>
  analyticsService.track("crop-editor-edit", { action: "copy", trigger });
export const trackCropPaste = (trigger: "paste-button" | "keyboard-shortcut") =>
  analyticsService.track("crop-editor-edit", { action: "paste", trigger });
