import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Dispatch, RootState } from "src/models/store";

import GoogleLogin from "src/components/auth/common/GoogleLogin";
import { H6, H2 } from "src/components/common/layout/typography.styled";
import { Field } from "src/components/common/form/Field.styled";
import { Input, FieldError, NetworkError } from "src/components/common/form/smart-form-components";
import {
  AlternativeAuthButton,
  AlternativeAuthLabel,
  Container,
  ContentContainer,
  Divider,
  FormFieldStack,
  SubmitButton,
} from "src/components/auth/common/layout.styled";
import * as commonAuthFieldDefinitions from "src/components/auth/common/fieldDefinitions";
import { absoluteRoutes } from "src/utils/routes.utils";
import { isCompanyEmail } from "company-email-validator";
import NotificationBar from "src/components/auth/common/NotificationBar";
import useOauthNetworkError, { PERSONAL_EMAIL_ERROR_TEXT } from "src/components/auth/common/useOauthNetworkError";

const signUpFormSchema = (allowedAnyEmail: boolean) =>
  yup.object({
    email: commonAuthFieldDefinitions.email.test(
      "is-company-email",
      PERSONAL_EMAIL_ERROR_TEXT,
      (value) => allowedAnyEmail || isCompanyEmail(String(value)),
    ),
  });

type SchemaType = yup.InferType<ReturnType<typeof signUpFormSchema>>;
type SignUpForm = SchemaType;

interface SignUpProps {
  campaign: string | null;
  allowAnyEmail?: boolean;
}

export default function SignUp({ campaign, allowAnyEmail = false }: SignUpProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const networkError = useSelector((state: RootState) => state.loading.effects.users.createUser.error as Error);
  const signUpForm = useForm<SignUpForm>({
    resolver: yupResolver(signUpFormSchema(allowAnyEmail)),
  });
  const { handleSubmit } = signUpForm;
  const { isPersonalEmailAddress, resetLoginUserOauthError } = useOauthNetworkError();

  const onSubmit = useCallback(
    ({ email }: SignUpForm) =>
      dispatch.users.createUser({
        email,
        signupCampaign: campaign,
      }),
    [dispatch, campaign],
  );

  return (
    <Container>
      <ContentContainer>
        <H2>Create your account</H2>
        <H6>Start elevating your video content today</H6>

        <FormProvider {...signUpForm}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormFieldStack>
              <GoogleLogin text="signup_with" />
              <NotificationBar
                isOpen={isPersonalEmailAddress}
                onClose={resetLoginUserOauthError}
                message={PERSONAL_EMAIL_ERROR_TEXT}
                type="error"
              />

              <Divider />

              <Field>
                <Input<SignUpForm>
                  name="email"
                  placeholder="Company email"
                  data-cy="signup-email-input"
                  inputSize="medium"
                />
                <FieldError<SignUpForm> name="email" />
              </Field>
            </FormFieldStack>

            <SubmitButton type="submit" data-cy="signup-email-submit">
              Sign up
            </SubmitButton>
            <NetworkError data-cy="network-error" error={networkError} />
          </form>
        </FormProvider>

        <AlternativeAuthLabel>
          Already have an account?
          <AlternativeAuthButton type="button" onClick={() => navigate(absoluteRoutes.login)} data-cy="login-account">
            Log in
          </AlternativeAuthButton>
        </AlternativeAuthLabel>
      </ContentContainer>
    </Container>
  );
}
