import styled, { css, keyframes } from "styled-components/macro";

import { themeColor, themeZ, ifProp, themeProp, ifNotProp } from "src/utils/styledComponents.utils";

import { Box, FlexBox } from "src/components/common/layout/Box.styled";
import { getAspectRatioValue } from "src/constants/video.constants";

export const AppearAnimatedDiv = styled.div`
  opacity: 0;
  animation: appear 0.5s ease 0.5s forwards;

  @keyframes appear {
    to {
      opacity: 1;
    }
  }
`;

export const Footer = styled.div`
  z-index: ${themeZ("createProjectSwiperFooter")};
  position: absolute;
  bottom: 0;
  left: 0;
  height: 73px;
  width: 100%;
  min-width: 310px;
  background-color: ${themeColor("white")};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.3);
`;

export const CentralButtonsWrapper = styled.div<{ hide?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 100%;
  visibility: ${ifProp("hide", "hidden", "visible")};
`;

export const NextButtonWrapper = styled.div`
  width: fit-content;
  height: fit-content;
`;

export const BackButtonWrapper = styled.div`
  @media (min-width: 500px) {
    position: absolute;
    top: 50%;
    left: -100%;
    transform: translateY(-50%);
  }
`;

export const CancelButtonWrapper = styled.div<{ hide?: boolean }>`
  position: absolute;
  top: 25px;
  left: 19px;
  transition: scale 150ms ease-out;

  ${ifProp(
    "hide",
    css`
      scale: 0;
    `,
  )};
`;

export const StepContainer = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-inline: 10px;
`;

export const HeaderText = styled.div`
  font-family: "Work Sans", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: ${themeColor("gray.900")};
  text-align: center;

  @media (min-width: 540px) {
    font-size: 24px;
    line-height: 29px;
  }
`;

export const SubHeaderText = styled(HeaderText)`
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
  margin-top: 14px;
`;

export const GridScroll = styled(Box)`
  flex: 1;
  width: 100%;
  height: 100%;

  @media (min-width: 768px) {
    overflow: auto !important;
  }
`;

export const GridScroll2 = styled(FlexBox)`
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto !important;
`;

export const LogoContainer = styled(AppearAnimatedDiv)`
  margin-top: 70px;
  width: 220px;

  @media (min-width: 768px) {
    margin-top: 130px;
  }
`;

export const VPGridScroll = styled(Box)`
  overflow-y: auto;
  flex: 1;
  width: 100%;
  max-width: 800px;
`;

export const VPGrid = styled(AppearAnimatedDiv)`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 12px;
  column-gap: 15px;
  padding-bottom: 20px;

  @media (min-width: 540px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const pump = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
`;

export const VisualPackageContainer = styled.label`
  display: block;
  border: 2px solid ${themeColor("gray.200")};
  border-radius: ${themeProp("borderRadii.large")}px;
  overflow: hidden;
  cursor: pointer;
  margin-inline: 24px;

  @media (min-width: 540px) {
    margin: 0;
  }
`;

export const VisualPackageCover = styled.div<{ isLoaded: boolean }>`
  width: 100%;
  aspect-ratio: ${getAspectRatioValue("16:9")};
  background-color: ${themeColor("gray.300")};

  ${ifNotProp(
    "isLoaded",
    css`
      animation: ${pump} 1s infinite ease-in-out;
    `,
  )}
`;

export const VisualPackageFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 8px;
  border-top: 2px solid ${themeColor("gray.200")};
`;
