import { PropsWithChildren, RefObject, useCallback, useEffect, useMemo, useState } from "react";
import { TranscriptAction } from "src/components/features/VideoTrimmer/VideoTranscript/TranscriptActions";
import { useTranscriptSelection } from "src/components/features/VideoTrimmer/VideoTranscript/hooks";
import { getLines, getTranscriptCanvasContext } from "src/components/features/VideoTrimmer/VideoTranscript/utils";
import { chapterTimeIntervalUtils } from "src/components/features/VideoTrimmer/common/chapterTimeInterval.utils";
import { useVideoChapters } from "src/components/features/VideoTrimmer/providers/VideoChaptersProvider/VideoChaptersContext";
import VideoTranscriptContext, {
  VideoTranscriptContextValue,
} from "src/components/features/VideoTrimmer/providers/VideoTranscriptProvider/VideoTranscriptContext";
import { FONT_FAMILIES, LINE_INLINE_PADDING } from "src/constants/video-trimmer.constants";
import useShallowMemo from "src/hooks/useShallowMemo";
import { TimeInterval, Word, WordTimeInterval } from "src/types/video-trimmer.types";
import { SCROLL_BAR_WIDTH } from "src/utils/dom.utils";
import useResizeObserver from "use-resize-observer";
import { getBlanks } from "src/utils/words.utils";
import { useAppConfig } from "src/components/providers/AppConfigProvider";
import { UseHistoryStateReturnValue } from "src/hooks/useHistoryState";
import { TextDirection } from "src/utils/localization.utils";

interface VideoTranscriptProviderProps extends PropsWithChildren {
  textDirection: TextDirection;
  videoRef: RefObject<HTMLVideoElement>;
  wordsHistory: UseHistoryStateReturnValue<Word[]>;
  videoDuration: number;
  ignoreMaxBlanks?: boolean;
}

export default function VideoTranscriptProvider({
  children,
  textDirection,
  videoRef,
  wordsHistory,
  videoDuration,
  ignoreMaxBlanks = false,
}: VideoTranscriptProviderProps) {
  const { ref, height = 0, width = 0 } = useResizeObserver();
  const [fontsReady, setFontsReady] = useState(false);
  const { chapters } = useVideoChapters();
  const { state: words } = wordsHistory;
  const { MAX_BLANKS: maxBlanks } = useAppConfig();
  const blanks = useMemo(
    () => getBlanks(words, videoDuration, ignoreMaxBlanks ? -1 : maxBlanks),
    [ignoreMaxBlanks, maxBlanks, videoDuration, words],
  );

  useEffect(() => {
    const checkFonts = () => {
      const dummyElement = document.createElement("div");
      dummyElement.style.fontFamily = `${FONT_FAMILIES[textDirection]}, sans-serif`;
      document.body.appendChild(dummyElement);

      const computedFontFamily = window.getComputedStyle(dummyElement).fontFamily;

      if (computedFontFamily.includes(FONT_FAMILIES[textDirection])) {
        setFontsReady(true);
      }

      document.body.removeChild(dummyElement);
    };

    checkFonts();
  }, [textDirection]);

  const [hoveredTranscriptActionButton, setHoveredTranscriptActionButton] = useState<TranscriptAction | null>(null);

  const canvasContext = useMemo(() => getTranscriptCanvasContext(textDirection), [textDirection]);

  const lines = useMemo(() => {
    if (!words.length || !fontsReady) return [];

    const availableWidth = width - SCROLL_BAR_WIDTH - LINE_INLINE_PADDING * 2;

    return getLines(canvasContext, words, blanks, availableWidth);
  }, [width, words, blanks, fontsReady, canvasContext]);

  const { transcriptSelection, recentInteractedSelectionEdge, isSelecting, cancelSelection } =
    useTranscriptSelection(lines);
  const [searchQuery, setSearchQuery] = useState("");

  const [focusedSearchResultIndex, setFocusedSearchResultIndex] = useState(0);

  const onSearchQueryChange = useCallback(
    (query: string, resultIndex: number = 0) => {
      setSearchQuery(query);
      setFocusedSearchResultIndex(resultIndex);
    },
    [setSearchQuery, setFocusedSearchResultIndex],
  );

  const searchResults = useMemo(() => {
    const queryWords = searchQuery
      .trim()
      .split(" ")
      .filter((word) => word.length > 0);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return findOccurrences(queryWords, words);
  }, [searchQuery, words]);

  const transcriptItems = useMemo(() => (words as TimeInterval[]).concat(blanks), [words, blanks]);

  const selectionLastWord = transcriptSelection
    ? lines[transcriptSelection.end.lineIndex].items[transcriptSelection.end.itemIndex]
    : null;

  const wordBeforeSelection = useMemo(() => {
    if (!transcriptSelection?.start) return null;

    const { lineIndex, itemIndex } = transcriptSelection.start;

    if (itemIndex === 0) {
      if (lineIndex === 0) {
        return null;
      }

      return lines[lineIndex - 1].items.at(-1);
    }

    return lines[lineIndex].items[itemIndex - 1];
  }, [transcriptSelection?.start, lines]);

  const wordAfterSelection = useMemo(() => {
    if (!transcriptSelection?.end) return null;

    const { lineIndex, itemIndex } = transcriptSelection.end;

    if (itemIndex === lines[lineIndex].items.length - 1) {
      if (lineIndex === lines.length - 1) {
        return null;
      }

      return lines[lineIndex + 1].items[0];
    }

    return lines[lineIndex].items[itemIndex + 1];
  }, [transcriptSelection?.end, lines]);

  const selectionTimeInterval = useMemo(() => {
    if (!selectionLastWord) return null;
    const selection: TimeInterval = {
      start: wordBeforeSelection ? wordBeforeSelection.end : 0,
      end: wordAfterSelection ? wordAfterSelection.start : selectionLastWord.end, // TODO: videoDuration instead
    };

    return chapterTimeIntervalUtils.removeGapsTimeInterval(selection, chapters, transcriptItems);
  }, [chapters, transcriptItems, wordBeforeSelection, wordAfterSelection, selectionLastWord]);

  const contextValue = useShallowMemo<VideoTranscriptContextValue>({
    textDirection,
    videoRef,
    ref,
    height,
    width,
    hoveredTranscriptActionButton,
    selectionTimeInterval,
    setHoveredTranscriptActionButton,
    setSearchQuery,
    searchQuery,
    focusedSearchResultIndex,
    setFocusedSearchResultIndex,
    searchResults,
    lines,
    transcriptSelection,
    recentInteractedSelectionEdge,
    isSelecting,
    cancelSelection,
    onSearchQueryChange,
  });
  return <VideoTranscriptContext.Provider value={contextValue}>{children}</VideoTranscriptContext.Provider>;
}

function cleanWord(word: string): string {
  return word.replace(/[.|!|?|;|,]/g, "").toLowerCase();
}

function findOccurrences(query: string[], transcript: WordTimeInterval[]): TimeInterval[] {
  const occurrences: TimeInterval[] = [];

  if (!query.length) {
    return occurrences;
  }

  /** iterate through transcript words */
  for (let transcriptWordIndex = 0; transcriptWordIndex < transcript.length; transcriptWordIndex += 1) {
    if (cleanWord(transcript[transcriptWordIndex].word) === cleanWord(query[0])) {
      let isMatch = true;

      /** iterate through query words */
      for (let queryWordIndex = 1; queryWordIndex < query.length; queryWordIndex += 1) {
        if (cleanWord(transcript[transcriptWordIndex + queryWordIndex]?.word) !== cleanWord(query[queryWordIndex])) {
          isMatch = false;
          break;
        }
      }

      if (isMatch) {
        const { start } = transcript[transcriptWordIndex];
        const { end } = transcript[transcriptWordIndex + query.length - 1];
        occurrences.push({ start, end });
      }
    }
  }
  return occurrences;
}
