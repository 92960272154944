import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Icon from "src/components/common/Icon";
import { CircularLoader } from "src/components/common/loaders/CircularLoader";
import {
  HeaderText,
  StepContainer,
} from "src/components/features/AutomaticCreateWizard/common/automatic-create-wizard-layout.styled";
import { absoluteRoutes, applyPathParams } from "src/utils/routes.utils";
import styled from "styled-components/macro";
import { FormContextInterface } from "src/components/features/AutomaticCreateWizard/FormContext.interface";

const LoaderWrapper = styled.div`
  margin-block: 30px 24px;
`;

const FinishHeaderText = styled(HeaderText)`
  font-size: 26px;
  line-height: 28px;
  margin-bottom: 20px;
  @media (min-width: 768px) {
    font-size: 36px;
    line-height: 38px;
    margin-bottom: 30px;
  }
`;

const IconWrapper = styled.div`
  margin-top: 20px;
  @media (min-width: 768px) {
    margin-top: 90px;
  }
`;

interface FinishStepProps {
  boardId?: string | null;
  footageId?: string | null;
}

export default function FinishStep({
  boardId = null,
  footageId = undefined,
  formContext,
}: FinishStepProps & FormContextInterface) {
  const navigate = useNavigate();
  const { setValue } = formContext;

  const navigateToPlatform = useCallback(() => {
    setTimeout(() => {
      navigate(absoluteRoutes.platform.self, { replace: true });
    }, 5000);
  }, [navigate]);

  const navigateToBoard = useCallback(() => {
    boardId &&
      setTimeout(() => {
        // TODO: support optional params type in applyPathParams instead of using "as any"
        navigate(applyPathParams(absoluteRoutes.platform.children.boards, { boardId } as any));
      }, 5000);
  }, [boardId, navigate]);

  const navigateToFootageSnippets = useCallback(() => {
    setTimeout(() => {
      footageId && navigate(applyPathParams(absoluteRoutes.platform.children.footage, { footageId }));
    }, 5000);
  }, [footageId, navigate]);

  useEffect(() => {
    setValue("finish.completed", true, { shouldValidate: true });
    setValue("uploading.footagesArray", [], { shouldValidate: false });
    if (boardId && boardId !== "null") {
      navigateToBoard();
    } else if (!boardId && footageId) {
      navigateToFootageSnippets();
    } else {
      navigateToPlatform();
    }
  }, [boardId, footageId, navigateToBoard, navigateToFootageSnippets, navigateToPlatform, setValue]);

  return (
    <StepContainer>
      <LoaderWrapper>
        <CircularLoader size={50} />
      </LoaderWrapper>
      <FinishHeaderText>How exciting!</FinishHeaderText>
      <HeaderText>Peech is generating a lot of amazing video content for you</HeaderText>
      <IconWrapper>
        <Icon.SmileFace />
      </IconWrapper>
    </StepContainer>
  );
}
