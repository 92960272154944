import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { subDays, format } from "date-fns";

import { Dispatch, RootState } from "src/models/store";
import socketClient from "src/network/SocketClient";

import { themeColor } from "src/utils/styledComponents.utils";

import styled from "styled-components/macro";
import { ifProp } from "styled-tools";

import DownloadNotificationOption from "src/components/app/AppRouter/layouts/NotificationsCenter/DownloadNotificationOption";
import { onDownloadInfoUpdateEvent } from "src/components/app/AppRouter/layouts/NotificationsCenter/NotificationCenter.utils";
import Icon from "src/components/common/Icon/Icon";
import Popper from "src/components/common/popovers/Popper";
import {
  DownloadInfo,
  DownloadInfoSortFields,
  DownloadStatus,
  SortDirection,
} from "src/network/graphql/generatedGraphqlSDK";
import { isMobile } from "src/utils/mobile.utils";
import { sessionSelectors } from "src/models/Session.model";

const NotificationsMenuContainer = styled.div<{ isOpen: boolean; disabled?: boolean }>`
  position: relative;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${ifProp("disabled", "auto", "pointer")};
  border-radius: 8px;
  background-color: ${ifProp("isOpen", themeColor("blue.500"), "transparent")};

  svg {
    color: ${ifProp("isOpen", themeColor("white"), themeColor("blue.500"))};
  }

  &:hover {
    background-color: ${themeColor("blue.50")};
  }

  &:active {
    background-color: ${themeColor("blue.500")};
    svg {
      color: ${themeColor("white")};
    }
  }
  transition: background-color 0.5s ease;
`;

const NotificationsMenu = styled.div`
  box-shadow: 0px 4px 16px 0px rgba(4, 27, 63, 0.2);
  border-radius: 10px;
  background-color: ${themeColor("white")};
  overflow: hidden;
  width: 340px;
  @media (max-width: 425px) {
    width: 260px;
  }

  @media (max-width: 768px) {
    width: 280px;
  }
`;

const MenuHeader = styled.div`
  width: 100%;
  height: 46px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${themeColor("blue.500")};
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  background-color: ${themeColor("blue.50")};
  svg {
    color: ${themeColor("gray.600")};
  }
`;

const MenuOptionsScrollWrapper = styled.div`
  height: fit-content;
  max-height: 40vh;
  overflow-y: scroll;
`;

const CloseBtn = styled.div.attrs({ tabIndex: 0 })`
  cursor: pointer;
  width: 40px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  padding: unset;
  border: unset;
  margin-right: -10px;
  svg {
    width: 20px;
    height: 20px;
    scale: 1;
    path {
      fill: ${themeColor("gray.500")};
    }
    transition: scale 0.3s ease;
  }

  &:hover {
    svg {
      scale: 1.2;
      path {
        fill: ${themeColor("blue.500")};
      }
    }
  }
`;

const MenuOptionContainer = styled.div<{ disabled?: boolean }>`
  position: relative;
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Open Sans" !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${ifProp("disabled", themeColor("gray.300"), themeColor("gray.900"))};
  background-color: ${themeColor("white")};
  cursor: ${ifProp("disabled", "auto", "pointer")};
  padding: 11px 24px 11px 16px;
  pointer-events: ${ifProp("disabled", "none", "all")};
  margin-block: 6px;

  &:hover {
    background-color: ${themeColor("blue.50")};
  }

  &:not(:last-child)::after {
    content: "";
    display: block;
    width: calc(100% - 24px);
    height: 1px;
    background-color: ${themeColor("gray.200")};
    position: absolute;
    bottom: 0;
    left: 0;
  }

  transition: background-color 0.5s ease;
`;

const HasNewMark = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: red;
`;

export default function NotificationCenterPopover() {
  const dispatch = useDispatch<Dispatch>();
  const downloadInfos = useSelector((state: RootState) => state.downloadInfo.entities);
  const [isOpen, setIsOpen] = useState(false);
  const notifications = useSelector((state: RootState) => sessionSelectors.selectNotifications(state));
  const hasNewNotifications = useSelector((state: RootState) => state.session.hasNewNotifications);

  const threeDaysBeforeToday: string = useMemo(() => {
    const threeDaysAgo = subDays(new Date(), 3);
    return format(threeDaysAgo, "yyyy-MM-dd");
  }, []);

  const handleCloseClick = () => {
    setIsOpen(false);
  };

  const handleCloseKeyDown: React.KeyboardEventHandler<HTMLButtonElement> = (event) => {
    if (event.key === "Enter" || event.key === " ") {
      handleCloseClick();
    }
  };

  useEffect(() => {
    const fetchDownloadInfos = async () => {
      await dispatch.downloadInfo.fetchDownloadInfos({
        filter: {
          status: { neq: DownloadStatus.Failed },
          createdAt: { gt: threeDaysBeforeToday },
        },
        paging: {
          limit: 100,
        },
        sorting: {
          field: DownloadInfoSortFields.CreatedAt,
          direction: SortDirection.Desc,
        },
      });
    };
    fetchDownloadInfos();
  }, [dispatch.downloadInfo, threeDaysBeforeToday]);

  useEffect(() => {
    const onDownloadInfosUpdate = (event: { downloadInfo: DownloadInfo }) => {
      onDownloadInfoUpdateEvent(dispatch, event, downloadInfos);
    };

    socketClient.on("downloadInfos", onDownloadInfosUpdate);

    return () => {
      socketClient.off("downloadInfos", onDownloadInfosUpdate);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch.downloadInfo, downloadInfos]);

  useEffect(() => {
    const onMessage = (event: MessageEvent) => {
      if (event.data === "DOWNLOAD_MODAL_CLOSED_IN_PROGRESS") {
        setIsOpen(true);
      }
    };
    window.addEventListener("message", onMessage);
    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, []);

  return (
    <Popper
      isOpen={isOpen}
      onOpen={() => {
        dispatch.session.setHasNewNotifications(false);
        setIsOpen(true);
      }}
      onClose={() => {
        dispatch.session.setHasNewNotifications(false);
        setIsOpen(false);
      }}
      offsetY={5}
      placement={isMobile() ? "bottom" : "bottom-end"}
      content={
        <NotificationsMenu>
          <MenuHeader>
            {notifications?.length ? "Notifications" : "No new notifications"}
            <CloseBtn as="button" onClick={handleCloseClick} onKeyDown={handleCloseKeyDown}>
              <Icon.Close />
            </CloseBtn>
          </MenuHeader>
          <MenuOptionsScrollWrapper>
            {notifications?.map((item) => (
              <MenuOptionContainer key={item.dataId}>
                <DownloadNotificationOption notificationId={item.dataId} />
              </MenuOptionContainer>
            ))}
          </MenuOptionsScrollWrapper>
        </NotificationsMenu>
      }
    >
      <NotificationsMenuContainer isOpen={isOpen} disabled={false}>
        {hasNewNotifications && <HasNewMark />}
        <Icon.Bell />
      </NotificationsMenuContainer>
    </Popper>
  );
}
