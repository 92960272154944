import { range } from "lodash/fp";
import styled, { keyframes } from "styled-components/macro";

import { styleProp, themeColor, themeProp } from "src/utils/styledComponents.utils";

import { Box } from "src/components/common/layout/Box.styled";

import { CheckIcon } from "src/components/features/Plans/PlanCard";
import PlanButton from "src/components/features/Plans/PlansElements/PlanButton";
import { SalesOnlyPriceBlock } from "src/components/features/Plans/PlansElements/PlansPriceBlockCreator";

const FictiveCardWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: fit-content;
  position: relative;
`;

export const PlugHover = keyframes`
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.5;
  }
`;

const HoverBlock = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${themeColor("gray.50")};

  border-radius: 26px;
  z-index: 2;
  animation: ${PlugHover} 2s infinite;

  transition: opacity 1500ms cubic-bezier(1, 0.12, 1, 0.12);
`;

const FictiveCardContainer = styled.div`
  position: relative;
  min-height: 518px;
  width: 320px;
  border-radius: 26px;
  background-color: ${themeColor("white")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 50px;
`;

const FictivePlanName = styled(Box)`
  display: block;
  text-align: center;
  font-weight: 600;
  font-size: 34px;
  width: 80%;
  height: 40px;
  background-color: ${themeColor("gray.250")};
  border-radius: ${themeProp("borderRadii.small")}px;
`;

const FictivePlanPriceContainer = styled(Box)`
  width: 80%;
  height: 70px;
  display: flex;
  flex-direction: row;
  background-color: ${themeColor("white")};
`;

const FictivePlanDescriptionBlock = styled(Box)`
  width: 80%;
  height: 44px;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const FicRow = styled.div<{ width: string }>`
  width: ${styleProp("width")};
  height: 20px;
  background-color: ${themeColor("gray.250")};
  border-radius: ${themeProp("borderRadii.small")}px;
`;

const FictivePlanButtonContainer = styled(Box)`
  width: 80%;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const FictiveFeaturesList = styled(Box)`
  width: 80%;
  min-height: 170px;
  height: "170px";
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const FictiveFeatureItem = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;

  span {
    margin-left: 10px;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: ${themeColor("gray.500")};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export default function FictiveCard() {
  return (
    <FictiveCardWrapper margin={12}>
      <HoverBlock />
      <FictiveCardContainer>
        <FictivePlanName marginBottom={22} />
        <FictivePlanPriceContainer marginBottom={22}>
          <SalesOnlyPriceBlock textColor={undefined}> ...... </SalesOnlyPriceBlock>
        </FictivePlanPriceContainer>
        <FictivePlanDescriptionBlock marginBottom={18}>
          <FicRow width="100%" />
          <FicRow width="60%" />
        </FictivePlanDescriptionBlock>
        <FictivePlanButtonContainer marginBottom={18}>
          <PlanButton />
        </FictivePlanButtonContainer>
        <FictiveFeaturesList marginBottom={70}>
          {range(0, 5).map((x) => (
            <FictiveFeatureItem key={x} marginBottom={10}>
              <div>
                <CheckIcon />
              </div>
              <span> .............................. </span>
            </FictiveFeatureItem>
          ))}
        </FictiveFeaturesList>
      </FictiveCardContainer>
    </FictiveCardWrapper>
  );
}
