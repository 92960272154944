import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Dispatch, RootState } from "src/models/store";

import { H2 } from "src/components/common/layout/typography.styled";
import { Field } from "src/components/common/form/Field.styled";
import { Input, FieldError, NetworkError } from "src/components/common/form/smart-form-components";
import {
  AlternativeAuthButton,
  AlternativeAuthLabel,
  Container,
  ContentContainer,
  SubmitButton,
} from "src/components/auth/common/layout.styled";
import * as commonAuthFieldDefinitions from "src/components/auth/common/fieldDefinitions";
import { absoluteRoutes } from "src/utils/routes.utils";

const resetPasswordFormSchema = yup.object({
  email: commonAuthFieldDefinitions.email,
});

type ResetPasswordForm = yup.InferType<typeof resetPasswordFormSchema>;

export default function ResetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const networkError = useSelector((state: RootState) => state.loading.effects.users.resetUserPassword.error as Error);
  const signUpForm = useForm<ResetPasswordForm>({ resolver: yupResolver(resetPasswordFormSchema) });
  const { handleSubmit } = signUpForm;

  const onSubmit = useCallback(
    ({ email }: ResetPasswordForm) => dispatch.users.resetUserPassword({ email }),
    [dispatch],
  );

  return (
    <Container>
      <ContentContainer>
        <H2>Forgot My Password</H2>

        <FormProvider {...signUpForm}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Field marginTop={20}>
              <Input<ResetPasswordForm> name="email" placeholder="Email" inputSize="medium" />
              <FieldError<ResetPasswordForm> name="email" />
            </Field>

            <SubmitButton>Reset Password</SubmitButton>
            <NetworkError error={networkError} />
          </form>
        </FormProvider>

        <AlternativeAuthLabel>
          Back to
          <AlternativeAuthButton onClick={() => navigate(absoluteRoutes.login)}>Log In</AlternativeAuthButton>
        </AlternativeAuthLabel>
      </ContentContainer>
    </Container>
  );
}
