import { useEffect } from "react";
import { useSelector } from "react-redux";

import styled from "styled-components/macro";

import { RootState } from "src/models/store";

import { useSwiperInnerScrolling } from "src/components/common/Swiper/hooks/useSwiperInnerScrolling";
import {
  GridScroll2,
  HeaderText,
  StepContainer,
  SubHeaderText,
} from "src/components/features/CreateSequenceWizard/common/create-sequence-wizard-layout.styled";
import {
  VideoEditRule,
  useVideoEditRulesTiles,
} from "src/components/features/CreateSequenceWizard/steps/VisualsStep/useVideoEditRulesTiles";
import useEditRules from "src/hooks/useEditRules";
import { presetSelectors } from "src/models/Preset.model";
import EditRulesButton from "src/components/common/buttons/EditRulesButton";
import { FormContextInterface } from "src/components/features/AutomaticCreateWizard/FormContext.interface";
import { VideoEditRules } from "src/components/features/AutomaticCreateWizard/subSteps/SORStep/useVideoEditRulesTiles";

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1;
`;

const RulesContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  width: fit-content;
  height: 752px;
  overflow-y: auto;
  margin: 8px;

  @media (min-width: 768px) {
    margin: 20px;
    grid-template-columns: auto auto auto auto;
    height: 376px;
  }
`;

export default function VisualsStep({ formContext }: FormContextInterface) {
  const videoEditRulesTiles = useVideoEditRulesTiles();
  const { setValue, watch } = formContext;

  const defaultVideoTypeSid = watch("visuals.defaultVideoType");
  const selectedEditRulesSid = watch("visuals.editRulesSid");
  const currentEditRules = watch("visuals.editRules");
  const defaultPreset = useSelector((state: RootState) => presetSelectors.getDefaultPreset(state));

  const { videoTypeSid, originalValuesEditRules, updatedEditRules } = useEditRules({ currentPreset: defaultPreset });
  const saveSequenceLoading = useSelector((state: RootState) => state.loading.effects.sequences.createSequence.loading);

  useEffect(() => {
    if (videoTypeSid && !defaultVideoTypeSid) {
      setValue("visuals.defaultVideoType", videoTypeSid, { shouldValidate: true });
    }
  }, [defaultVideoTypeSid, setValue, videoTypeSid]);

  useEffect(() => {
    if (!defaultVideoTypeSid) {
      return;
    }
    if (
      originalValuesEditRules &&
      selectedEditRulesSid !== originalValuesEditRules.sid
    ) {
      setValue("visuals.editRulesSid", originalValuesEditRules.sid!, { shouldValidate: true });
      setValue("visuals.editRules", { ...currentEditRules!, ...updatedEditRules }, { shouldValidate: true });
    }
  }, [currentEditRules, defaultVideoTypeSid, originalValuesEditRules, selectedEditRulesSid, setValue, updatedEditRules]); // prettier-ignore

  const onRuleTileClick = (rule: keyof NonNullable<typeof currentEditRules>) => {
    if (currentEditRules && !(videoEditRulesTiles as VideoEditRules)[rule].disabled) {
      setValue("visuals.editRules", { ...currentEditRules, [rule]: !currentEditRules[rule] }, { shouldValidate: true });
    }
  };

  return (
    <StepContainer>
      <GridScroll2 {...useSwiperInnerScrolling()}>
        <HeaderText>Which visuals do you want Peech to add to your video?</HeaderText>
        <SubHeaderText>You can easily tweak it later </SubHeaderText>
        <ContentWrapper>
          <RulesContainer {...useSwiperInnerScrolling()}>
            {(Object.keys(videoEditRulesTiles) as VideoEditRule[]).map((rule) => (
              <EditRulesButton
                key={rule}
                disabledFeature={videoEditRulesTiles[rule].disabled}
                isSelected={!!currentEditRules?.[rule]}
                isLoading={saveSequenceLoading}
                onSelect={() => onRuleTileClick(rule)}
                rule={rule}
                name={videoEditRulesTiles[rule].elementName}
              />
            ))}
          </RulesContainer>
        </ContentWrapper>
      </GridScroll2>
    </StepContainer>
  );
}
