import { useSelector } from "react-redux";
import { userSelectors } from "src/models/User.model";
import WithPlatformHeader from "./WithPlatformHeader";

export default function WithPlansHeader() {
  const currentUser = useSelector(userSelectors.selectCurrentUser);

  return (
    <WithPlatformHeader
      boardSelect={false}
      upgradeButton={false}
      backBtn={!!currentUser}
      logoType={currentUser ? "short" : "long"}
    />
  );
}
