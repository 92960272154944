import { Dispatch, SetStateAction } from "react";
import styled from "styled-components/macro";
import { ifProp } from "styled-tools";

import { switchProp, themeColor } from "src/utils/styledComponents.utils";

import { ChargeType } from "src/constants/model.constants";

const Container = styled.div<{ chargeType: ChargeType }>`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  background-color: ${themeColor("white")};
  border-radius: 22px;
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    width: 113px;
    height: 44px;
    border-radius: 22px;
    background-color: ${themeColor("gray.400")};
    transition: translate 0.3s;
    translate: ${switchProp("chargeType", { [ChargeType.Yearly]: "113px", [ChargeType.Monthly]: "0" })};
  }
`;

const SwitcherOption = styled.div<{ active: boolean }>`
  width: 113px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 22px;
  background-color: transparent;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 1px;
  color: ${ifProp("active", themeColor("gray.200"), themeColor("gray.900"))};
  z-index: 1;
`;

interface SwitcherProps {
  chosenChargeType: ChargeType;
  setChosenChargeType: Dispatch<SetStateAction<ChargeType | null>>;
}

export default function Switcher({ chosenChargeType, setChosenChargeType }: SwitcherProps) {
  return (
    <Container chargeType={chosenChargeType!}>
      <SwitcherOption
        active={chosenChargeType === ChargeType.Monthly}
        data-cy-active={chosenChargeType === ChargeType.Monthly}
        data-cy="switch-monthly"
        onClick={() => setChosenChargeType(ChargeType.Monthly)}
      >
        MONTHLY
      </SwitcherOption>
      <SwitcherOption
        active={chosenChargeType === ChargeType.Yearly}
        data-cy-active={chosenChargeType === ChargeType.Yearly}
        data-cy="switch-yearly"
        onClick={() => setChosenChargeType(ChargeType.Yearly)}
      >
        YEARLY
      </SwitcherOption>
    </Container>
  );
}
