import { User } from "src/network/graphql/generatedGraphqlSDK";

import blockServiceForEnv from "src/services/blockServiceForEnv";
import BaseService from "src/services/Base.service";

declare global {
  type IntercomSettings = Partial<{
    app_id: string;
    api_base: string;
    user_id: string;
    email: string;
    name: string;
    created_at: number;
    hide_default_launcher: boolean;
    vertical_padding: number;
    horizontal_padding: number;
  }>;

  interface Window {
    Intercom(method: "boot" | "update", params: IntercomSettings): void;
    Intercom(method: "startSurvey", params: number): void;
    Intercom(method: "shutdown"): void;
    Intercom(method: "show"): void;
  }
}

@blockServiceForEnv([])
class UserEngagementService implements BaseService {
  async init() {
    // set by segment.io
    // window.Intercom("boot", {
    //   app_id: appConfig.INTERCOM_APP_ID,
    //   api_base: appConfig.INTERCOM_API_BASE_URL,
    // });
  }

  identify(user: User): void {
    if (window.Intercom === undefined) {
      setTimeout(() => this.identify(user), 1000);
      return;
    }
    window.Intercom("boot", {
      user_id: user.sid!,
      email: user.email!,
      name: `${user.firstName} ${user.lastName}`,
      created_at: user.insertTime ? user.insertTime / 1000 : undefined,
    });
  }

  showDefaultLauncher() {
    if (window.Intercom === undefined) {
      setTimeout(() => this.showDefaultLauncher(), 1000);
      return;
    }
    window.Intercom("update", {
      hide_default_launcher: false,
    });
  }

  hideDefaultLauncher() {
    if (window.Intercom === undefined) {
      setTimeout(() => this.hideDefaultLauncher(), 1000);
      return;
    }
    window.Intercom("update", {
      hide_default_launcher: true,
    });
  }

  startSurvey(surveyId: number) {
    if (window.Intercom === undefined) {
      setTimeout(() => this.startSurvey(surveyId), 1000);
      return;
    }
    window.Intercom("startSurvey", surveyId);
  }

  setLauncherPadding(padding: Partial<{ verticalPadding: number; horizontalPadding: number }>) {
    if (window.Intercom === undefined) {
      setTimeout(() => this.setLauncherPadding(padding), 1000);
      return;
    }
    window.Intercom("update", {
      vertical_padding: padding.verticalPadding,
      horizontal_padding: padding.horizontalPadding,
    });
  }

  show() {
    if (window.Intercom === undefined) {
      return;
    }
    window.Intercom("show");
  }

  shutdown() {
    if (window.Intercom === undefined) {
      return;
    }
    window.Intercom("shutdown");
  }
}

const userEngagementService = new UserEngagementService();

export default userEngagementService;
