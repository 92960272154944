import { components, PlaceholderProps } from "react-select";

import styled from "styled-components/macro";

import { SequenceFilterOption } from "src/components/features/SmartLibrary/filtering/filtersSelect/SequenceFilterSelect";
import { themeColor } from "src/utils/styledComponents.utils";

const TextWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: ${themeColor("blue.900")};
`;

const IconWrapper = styled(TextWrapper)`
  width: 22px;
  height: 20px;
  justify-content: center;
  margin-right: 5px;
  svg {
    width: 22px;
    height: 20px;
    color: ${themeColor("blue.900")};
    path {
      fill: ${themeColor("blue.900")};
    }
  }
`;

export default function SequenceFilterPlaceholder({ selectProps, ...props }: PlaceholderProps<SequenceFilterOption>) {
  // @ts-ignore
  const { placeholderIcon, placeholder: placeholderLabel } = selectProps;

  return (
    <components.Placeholder {...props} selectProps={selectProps}>
      <IconWrapper>{placeholderIcon}</IconWrapper>
      <TextWrapper>{placeholderLabel}</TextWrapper>
    </components.Placeholder>
  );
}
