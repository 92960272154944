import { useRef, useState } from "react";
import styled from "styled-components/macro";

import AudioSample from "src/types/media.types";
import { ChapterTimeInterval, TimeInterval, Word } from "src/types/video-trimmer.types";

import { AspectRatio, getAspectRatioValue } from "src/constants/video.constants";

import { themeColor } from "src/utils/styledComponents.utils";

import useVariableRef from "src/hooks/useVariableRef";
import useIsVideoLoading from "src/hooks/useIsVideoLoading";
import { UseHistoryStateReturnValue } from "src/hooks/useHistoryState";

import { Stack } from "src/components/common/layout/Stack.styled";
import { Center, FlexBox } from "src/components/common/layout/Box.styled";
import { CircularLoader } from "src/components/common/loaders/CircularLoader";
import ShakaVideoPlayer from "src/components/common/media/ShakaVideoPlayer/ShakaVideoPlayer";
import AutoSizer from "src/components/common/layout/AutoSizer";
import VideoCurrentTimeProvider from "src/components/features/VideoTrimmer/providers/VideoCurrentTimeProvider/VideoCurrentTimeProvider";
import VideoChaptersProvider from "src/components/features/VideoTrimmer/providers/VideoChaptersProvider/VideoChaptersProvider";
import TimelineZoomProvider from "src/components/features/VideoTrimmer/providers/TimelineZoomProvider/TimelineZoomProvider";
import VideoPlaybackProvider from "src/components/features/VideoTrimmer/providers/VideoPlaybackProvider/VideoPlaybackProvider";
import VideoTranscript from "src/components/features/VideoTrimmer/VideoTranscript/VideoTranscript";
import TimelineEditor from "src/components/features/VideoTrimmer/TimelineEditor/TimelineEditor";
import TimelineZoomer from "src/components/features/VideoTrimmer/TimelineZoomer/TimelineZoomer";
import usePixelPerSecondRatio from "src/components/features/VideoTrimmer/common/hooks/usePixelPerSecondRatio";
import TrimmerShortcuts from "src/components/features/VideoTrimmer/shortcuts/TrimmerShortcuts";
import TrimmedVideoTimer from "src/components/features/VideoTrimmer/TimelineActionButtons/TrimmedVideoTimer";
import VideoTranscriptProvider from "src/components/features/VideoTrimmer/providers/VideoTranscriptProvider/VideoTranscriptProvider";
import TranscriptActions from "src/components/features/VideoTrimmer/VideoTranscript/TranscriptActions";
import VideoWordsProvider from "src/components/features/VideoTrimmer/providers/VideoWordsProvider/VideoWordsProvider";
import { InitialHistoryState } from "src/components/features/VideoTrimmer/common/hooks/useInitiatedHistory";
import VideoCropProvider from "src/components/features/VideoCropper/providers/VideoCropProvider/VideoCropProvider";
import { ChapterTimelineActionButtons } from "src/components/features/VideoTrimmer/VideoTranscript/ChapterTimelineActionButtons";
import CropPlayer from "src/components/common/media/CropPlayer/CropPlayer";
import TimeLineZoomShortcuts from "src/components/features/VideoTrimmer/shortcuts/TimelineZoomShortcuts";
import VideoPlaybackShortcuts from "src/components/features/VideoTrimmer/shortcuts/VideoPlaybackShortcuts";
import { useSelector } from "react-redux";
import { RootState } from "src/models/store";
import { sequenceSelectors } from "src/models/Sequence.model";
import * as sequenceContentEditorAnalytics from "src/analytics/sequenceContentEditor.analytics";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Body = styled(Stack)`
  flex: 1;
  overflow: hidden;
  padding-inline: 40px;
`;

const TranscriptColumn = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-block: 50px;
  flex: 0.6;
  overflow: hidden;
`;

const VideoColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.4;
  overflow: hidden;
`;

const FlexAutoSizer = styled(AutoSizer)`
  display: flex;
  padding: 30px;
`;

const VideoPlayerContainer = styled.div`
  container-name: VideoPlayerContainer;
  container-type: inline-size;
  position: relative;
  margin: auto;
  outline: 1px solid ${themeColor("gray.300")};
`;

const StyledShakaVideoPlayer = styled(ShakaVideoPlayer)`
  opacity: 0;
  object-fit: fill;
`;

const Footer = styled(Stack)`
  padding-block: 10px;
  user-select: none;
  background-color: ${themeColor("gray.200")};
`;

const CircularLoaderContainer = styled(Center)`
  position: absolute;
  inset: 0;
`;

const TimelineWindow = styled(Stack)`
  padding-inline: 2px;
`;

const Progress = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 25px;
  font-weight: 300;
  color: ${themeColor("black")};
  text-align: right;
`;

const Header = styled(FlexBox)`
  background-color: ${themeColor("gray.50")};
  padding: 7px 41px 5px 41px;
`;

const HorizontalDivider = styled(FlexBox)`
  background: ${themeColor("gray.200")};
  height: 5px;
  width: 100%;
`;

export interface VideoTrimmerProps {
  // TODO: remove, to make the VideoTrimmer completely independent of sequence,
  //  the major problem is prop drilling of: words, blanks & captions
  sequenceSid: string;

  videoSrc: string;
  videoDuration: number;
  videoAspectRatio: AspectRatio;
  videoAudioSamples: AudioSample[];
  chaptersHistory: UseHistoryStateReturnValue<InitialHistoryState>;
  wordsHistory: UseHistoryStateReturnValue<Word[]>;
  chapterDomains: TimeInterval[];
  cropsHistory: UseHistoryStateReturnValue<InitialHistoryState>;
  inManualCreate?: boolean;
  isTopicByTimeConcated?: boolean;
}

export default function VideoTrimmer({
  sequenceSid,
  videoSrc,
  videoDuration,
  videoAspectRatio,
  videoAudioSamples,
  chaptersHistory,
  wordsHistory,
  chapterDomains,
  cropsHistory,
  inManualCreate = false,
  isTopicByTimeConcated = false,
}: VideoTrimmerProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [timelineWindow, setTimelineWindow] = useState<HTMLElement | null>(null);
  const pixelPerSecondRatio = usePixelPerSecondRatio(useVariableRef(timelineWindow), videoDuration);
  const isVideoLoading = useIsVideoLoading(videoRef);
  const sequence = useSelector((state: RootState) => sequenceSelectors.selectById(state, sequenceSid)); // prettier-ignore
  const languageCode = sequence?.languageCode || "en/US";
  const useV2ClosedCaptions = sequence?.useV2ClosedCaptions || true;
  const textDirection = useSelector((state: RootState) => sequenceSelectors.selectSequenceTextDirection(state, sequenceSid)); // prettier-ignore

  return (
    <VideoCurrentTimeProvider videoRef={videoRef}>
      <VideoChaptersProvider
        chaptersHistory={chaptersHistory as UseHistoryStateReturnValue<ChapterTimeInterval[]>}
        domains={chapterDomains}
      >
        <TimelineZoomProvider videoRef={videoRef} videoDuration={videoDuration} timelineWindowElement={timelineWindow}>
          <VideoPlaybackProvider
            cropsHistory={cropsHistory}
            wordsHistory={wordsHistory}
            chaptersHistory={chaptersHistory as UseHistoryStateReturnValue<ChapterTimeInterval[]>}
            videoRef={videoRef}
            videoDuration={videoDuration}
          >
            <TimeLineZoomShortcuts />
            <VideoPlaybackShortcuts videoRef={videoRef} />
            <TrimmerShortcuts videoRef={videoRef} />
            <VideoTranscriptProvider
              videoDuration={videoDuration}
              wordsHistory={wordsHistory}
              videoRef={videoRef}
              textDirection={textDirection}
            >
              <VideoWordsProvider
                videoDuration={videoDuration}
                languageCode={languageCode || "en/US"}
                wordsHistory={wordsHistory}
                allowedActions={{
                  correct: {
                    isAllowed: !inManualCreate && !!useV2ClosedCaptions && !isTopicByTimeConcated,
                    message: !inManualCreate
                      ? "Applies to automatically created newer projects only"
                      : "You can Correct the Transcription once the video is created",
                  },
                  highlight: {
                    isAllowed: !inManualCreate && !!useV2ClosedCaptions,
                    message: !inManualCreate
                      ? "Applies to newer projects only"
                      : "You can highligh a Word once the video is created",
                  },
                }}
                analyticsActions={sequenceContentEditorAnalytics}
              >
                <VideoCropProvider videoRef={videoRef} sequenceSid={sequenceSid} cropsHistory={cropsHistory}>
                  <Container>
                    <Header>
                      <TranscriptActions analyticsActions={sequenceContentEditorAnalytics} />
                    </Header>
                    <HorizontalDivider />

                    <Body direction="row" spacing={60}>
                      <TranscriptColumn>
                        <VideoTranscript />
                      </TranscriptColumn>

                      <VideoColumn>
                        <FlexAutoSizer aspectRatio={getAspectRatioValue(videoAspectRatio)}>
                          {({ width, height }) => (
                            <VideoPlayerContainer style={{ width, height }}>
                              <CropPlayer videoRef={videoRef} width={width} height={height} />
                              <StyledShakaVideoPlayer
                                videoRef={videoRef}
                                src={videoSrc}
                                cached
                                width="100%"
                                height="100%"
                                config={{ streaming: { forceHTTPS: true } }}
                              />
                              <Progress>
                                <TrimmedVideoTimer formatStr="HH:mm:ss" />
                              </Progress>

                              {isVideoLoading && (
                                <CircularLoaderContainer>
                                  <CircularLoader size={100} />
                                </CircularLoaderContainer>
                              )}
                            </VideoPlayerContainer>
                          )}
                        </FlexAutoSizer>
                      </VideoColumn>
                    </Body>

                    <Footer spacing={10} direction="column">
                      <ChapterTimelineActionButtons videoRef={videoRef} />

                      <TimelineWindow ref={setTimelineWindow} direction="column" spacing={10}>
                        <TimelineEditor
                          audioSamples={videoAudioSamples}
                          videoDurationInSeconds={videoDuration}
                          pixelPerSecondRatio={pixelPerSecondRatio}
                        />

                        <TimelineZoomer pixelPerSecondRatio={pixelPerSecondRatio} />
                      </TimelineWindow>
                    </Footer>
                  </Container>
                </VideoCropProvider>
              </VideoWordsProvider>
            </VideoTranscriptProvider>
          </VideoPlaybackProvider>
        </TimelineZoomProvider>
      </VideoChaptersProvider>
    </VideoCurrentTimeProvider>
  );
}
