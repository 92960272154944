import { createContext, useContext, useEffect } from "react";

import useVariableRef from "src/hooks/useVariableRef";

export type CurrentTimeListener = (currentTime: number) => void;

export interface VideoCurrentTimeListenerContextValue {
  addCurrentTimeListener(listener: CurrentTimeListener): void;
  removeCurrentTimeListener(listener: CurrentTimeListener): void;
}

const VideoCurrentTimeListenerContext = createContext<VideoCurrentTimeListenerContextValue | null>(null);

export default VideoCurrentTimeListenerContext;

export const useCurrentTimeListener = (listener: CurrentTimeListener) => {
  const contextValue = useContext(VideoCurrentTimeListenerContext);

  if (!contextValue) {
    throw new Error("useCurrentTimeListener must be used within VideoCurrentTimeProvider");
  }

  const { addCurrentTimeListener, removeCurrentTimeListener } = contextValue;
  const listenerRef = useVariableRef(listener);

  useEffect(() => {
    const onTimeUpdate = (time: number) => {
      listenerRef.current(time);
    };

    addCurrentTimeListener(onTimeUpdate);

    return () => {
      removeCurrentTimeListener(onTimeUpdate);
    };
  }, [addCurrentTimeListener, removeCurrentTimeListener, listenerRef]);
};
