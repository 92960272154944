import { AspectRatio } from "src/constants/video.constants";
import { StyleAdd } from "src/network/graphql/generatedGraphqlSDK";

export enum StyleVerticalAlign {
  TOP = 1,
  MIDDLE = 2,
  BOTTOM = 3,
}

export enum StyleStyleType {
  BOX = 1,
  STROKE = 2,
  TRANSPARENT_BOX = 3,
  TRANSPARENT = 4,
}

const DEFAULT_COLORS = {
  FONT: "#FFFFFF",
  STYLE: "#000000",
};

const STYLE_TYPE_DEFAULTS: Record<AspectRatio, StyleStyleType> = {
  "16:9": StyleStyleType.TRANSPARENT_BOX,
  "9:16": StyleStyleType.STROKE,
  "1:1": StyleStyleType.STROKE,
};

export enum VerticalAlign {
  TOP = 1,
  MIDDLE = 2,
  BOTTOM = 3,
}

const defaultPosition = {
  [`9:16-${VerticalAlign.TOP}`]: 10,
  [`9:16-${VerticalAlign.MIDDLE}`]: 50,
  [`9:16-${VerticalAlign.BOTTOM}`]: 86,
  [`1:1-${VerticalAlign.TOP}`]: 10,
  [`1:1-${VerticalAlign.MIDDLE}`]: 50,
  [`1:1-${VerticalAlign.BOTTOM}`]: 84,
  [`16:9-${VerticalAlign.TOP}`]: 10,
  [`16:9-${VerticalAlign.MIDDLE}`]: 50,
  [`16:9-${VerticalAlign.BOTTOM}`]: 84,
};

const ALIGNMENT_DEFAULTS: Record<AspectRatio, StyleVerticalAlign> = {
  "16:9": StyleVerticalAlign.BOTTOM,
  "9:16": StyleVerticalAlign.TOP,
  "1:1": StyleVerticalAlign.BOTTOM,
};

export const getCloseCaptionDefaultPosition = (ratio: string, verticalAlign: StyleVerticalAlign): number => {
  const position = defaultPosition[`${ratio}-${verticalAlign}`];
  return position || 84;
};

export const getDefaultCaptionsStyle = (ratio: AspectRatio): StyleAdd => ({
  captionsVerticalAlign: ALIGNMENT_DEFAULTS[ratio],
  captionsPosition: getCloseCaptionDefaultPosition(ratio, ALIGNMENT_DEFAULTS[ratio]),
  captionsFontColor: DEFAULT_COLORS.FONT,
  captionsStyleType: STYLE_TYPE_DEFAULTS[ratio],
  captionsStyleColor: DEFAULT_COLORS.STYLE,
});

export const downloadSubtitles = async (variables: { sequenceSid: string; contentUrl: string }, authToken: string) => {
  const version = new Date().getTime();
  let link: HTMLAnchorElement | null = document.createElement("a");
  link.onclick = (e) => e.stopPropagation();
  link.href = `${variables.contentUrl}/c/s/s/${variables.sequenceSid}/${version}.srt?ps=${authToken}`;
  link.target = "_blank";
  link.click();

  link.onclick = null;
  link = null;
};
