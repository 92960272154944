import { ReactComponent as YouTubeStroke } from "./svg/YouTube-stroke.svg";
import { ReactComponent as AlarmClock } from "./svg/alarmClock.svg";
import { ReactComponent as ArrowDown } from "./svg/arrow-down.svg";
import { ReactComponent as ArrowRight } from "./svg/arrow-right.svg";
import { ReactComponent as ArrowDownCircle } from "./svg/arrowDownCircle.svg";
import { ReactComponent as Attention } from "./svg/attention.svg";
import { ReactComponent as Bell } from "./svg/bell.svg";
import { ReactComponent as Billing } from "./svg/billing.svg";
import { ReactComponent as Board } from "./svg/board.svg";
import { ReactComponent as BrushRainbow } from "./svg/brushRainbow.svg";
import { ReactComponent as Carret } from "./svg/carret.svg";
import { ReactComponent as CheckCircleInverted } from "./svg/check-circle-inverted.svg";
import { ReactComponent as CheckCircle } from "./svg/check-circle.svg";
import { ReactComponent as Check } from "./svg/check.svg";
import { ReactComponent as ChooseAutomatic } from "./svg/chooseAutomatic.svg";
import { ReactComponent as ChooseManually } from "./svg/chooseManually.svg";
import { ReactComponent as CircledX } from "./svg/circled-x.svg";
import { ReactComponent as Close } from "./svg/close.svg";
import { ReactComponent as ComingSoon } from "./svg/coming-soon.svg";
import { ReactComponent as CommentInfo } from "./svg/comment-info.svg";
import { ReactComponent as ContactCenter } from "./svg/contactCenter.svg";
import { ReactComponent as Diamond } from "./svg/diamond.svg";
import { ReactComponent as DiamondColored } from "./svg/diamondColored.svg";
import { ReactComponent as DownloadFailed } from "./svg/download-failed.svg";
import { ReactComponent as Download } from "./svg/download.svg";
import { ReactComponent as Duplicate } from "./svg/duplicate.svg";
import { ReactComponent as Duration } from "./svg/duration.svg";
import { ReactComponent as Edit } from "./svg/edit.svg";
import { ReactComponent as Eye } from "./svg/eye.svg";
import { ReactComponent as FacebookSmall } from "./svg/facebook-small.svg";
import { ReactComponent as Fill } from "./svg/fill.svg";
import { ReactComponent as Film } from "./svg/film.svg";
import { ReactComponent as Fit } from "./svg/fit.svg";
import { ReactComponent as HeartEmpty } from "./svg/heart-empty.svg";
import { ReactComponent as HeartFilled } from "./svg/heart-filled.svg";
import { ReactComponent as HeartWhite } from "./svg/heart-white.svg";
import { ReactComponent as HelpCenter } from "./svg/helpCenter.svg";
import { ReactComponent as Highlight } from "./svg/highlight.svg";
import { ReactComponent as Horizontal } from "./svg/horizontal.svg";
import { ReactComponent as Info } from "./svg/info.svg";
import { ReactComponent as InfoCircle } from "./svg/infoCircle.svg";
import { ReactComponent as InstagramReels } from "./svg/instagram-reels.svg";
import { ReactComponent as InstagramSmall } from "./svg/instagram-small.svg";
import { ReactComponent as KeepOnlySelection } from "./svg/keep-only-selection.svg";
import { ReactComponent as LinkedinSmall } from "./svg/linkedin-small.svg";
import { ReactComponent as Locked } from "./svg/locked.svg";
import { ReactComponent as Logo } from "./svg/logo.svg";
import { ReactComponent as Logout } from "./svg/logout.svg";
import { ReactComponent as Mail } from "./svg/mail.svg";
import { ReactComponent as Minus } from "./svg/minus.svg";
import { ReactComponent as MouseScroll } from "./svg/mouse-scroll.svg";
import { ReactComponent as NewsFeed } from "./svg/news-feed.svg";
import { ReactComponent as Original } from "./svg/original.svg";
import { ReactComponent as OverflowVertical } from "./svg/overflow-vertical.svg";
import { ReactComponent as Overflow } from "./svg/overflow.svg";
import { ReactComponent as Paste } from "./svg/paste.svg";
import { ReactComponent as Pause } from "./svg/pause.svg";
import { ReactComponent as PeechLogo } from "./svg/peechLogo.svg";
import { ReactComponent as PeechLogoShort } from "./svg/peechLogoShort.svg";
import { ReactComponent as PinterestSmall } from "./svg/pinterest-small.svg";
import { ReactComponent as PlayBackward } from "./svg/play-backward.svg";
import { ReactComponent as PlayForward } from "./svg/play-forward.svg";
import { ReactComponent as Play } from "./svg/play.svg";
import { ReactComponent as Play2 } from "./svg/play2.svg";
import { ReactComponent as PlusSmall } from "./svg/plus-small.svg";
import { ReactComponent as Plus } from "./svg/plus.svg";
import { ReactComponent as PrevSlide } from "./svg/prev-slide.svg";
import { ReactComponent as Pricing } from "./svg/pricing.svg";
import { ReactComponent as Ratio } from "./svg/ratio.svg";
import { ReactComponent as Redo } from "./svg/redo.svg";
import { ReactComponent as Reload } from "./svg/reload.svg";
import { ReactComponent as Sandvich } from "./svg/sandvich.svg";
import { ReactComponent as Scissors } from "./svg/scissors.svg";
import { ReactComponent as ScoringEmpty } from "./svg/scoringEmpty.svg";
import { ReactComponent as ScoringFull } from "./svg/scoringFull.svg";
import { ReactComponent as ScoringMiddle } from "./svg/scoringMiddle.svg";
import { ReactComponent as ScoringMin } from "./svg/scoringMin.svg";
import { ReactComponent as Search } from "./svg/search.svg";
import { ReactComponent as ShortsSmall } from "./svg/shorts-small.svg";
import { ReactComponent as Shutterstock } from "./svg/shutterstock.svg";
import { ReactComponent as SmileFace } from "./svg/smile-face.svg";
import { ReactComponent as SocialMedia } from "./svg/socialMedia.svg";
import { ReactComponent as Square } from "./svg/square.svg";
import { ReactComponent as Star } from "./svg/star.svg";
import { ReactComponent as Stopwatch } from "./svg/stopwatch.svg";
import { ReactComponent as ThreeDots } from "./svg/three-dots.svg";
import { ReactComponent as TiktokSmall } from "./svg/tiktok-small.svg";
import { ReactComponent as Translate } from "./svg/translate.svg";
import { ReactComponent as Trash } from "./svg/trash.svg";
import { ReactComponent as Undo } from "./svg/undo.svg";
import { ReactComponent as Up } from "./svg/up.svg";
import { ReactComponent as UploadContent } from "./svg/upload-content.svg";
import { ReactComponent as UploadPaused } from "./svg/upload-paused.svg";
import { ReactComponent as Upload } from "./svg/upload.svg";
import { ReactComponent as UploadVideo } from "./svg/uploadVideo.svg";
import { ReactComponent as UploadVideoClosed } from "./svg/uploadVideoClosed.svg";
import { ReactComponent as Vertical } from "./svg/vertical.svg";
import { ReactComponent as VideoCounter } from "./svg/video-counter.svg";
import { ReactComponent as Video } from "./svg/video.svg";
import { ReactComponent as VideosStack } from "./svg/videos-stack.svg";
import { ReactComponent as VimeoSmall } from "./svg/vimeo-small.svg";
import { ReactComponent as Watchquarter } from "./svg/watchquarter.svg";
import { ReactComponent as YoutubeSmall } from "./svg/youtube-small.svg";
import { ReactComponent as ZoomReset } from "./svg/zoom-reset.svg";

const Icon = {
  YouTubeStroke,
  AlarmClock,
  ArrowDown,
  ArrowRight,
  ArrowDownCircle,
  Attention,
  Bell,
  Billing,
  Board,
  BrushRainbow,
  Carret,
  CheckCircleInverted,
  CheckCircle,
  Check,
  ChooseAutomatic,
  ChooseManually,
  CircledX,
  Close,
  ComingSoon,
  CommentInfo,
  ContactCenter,
  Diamond,
  DiamondColored,
  DownloadFailed,
  Download,
  Duplicate,
  Duration,
  Edit,
  Eye,
  FacebookSmall,
  Fill,
  Film,
  Fit,
  HeartEmpty,
  HeartFilled,
  HeartWhite,
  HelpCenter,
  Highlight,
  Horizontal,
  Info,
  InfoCircle,
  InstagramReels,
  InstagramSmall,
  KeepOnlySelection,
  LinkedinSmall,
  Locked,
  Logo,
  Logout,
  Mail,
  Minus,
  MouseScroll,
  NewsFeed,
  Original,
  OverflowVertical,
  Overflow,
  Paste,
  Pause,
  PeechLogo,
  PeechLogoShort,
  PinterestSmall,
  PlayBackward,
  PlayForward,
  Play,
  Play2,
  PlusSmall,
  Plus,
  PrevSlide,
  Pricing,
  Ratio,
  Redo,
  Reload,
  Sandvich,
  Scissors,
  ScoringEmpty,
  ScoringFull,
  ScoringMiddle,
  ScoringMin,
  Search,
  ShortsSmall,
  Shutterstock,
  SmileFace,
  SocialMedia,
  Square,
  Star,
  Stopwatch,
  ThreeDots,
  TiktokSmall,
  Translate,
  Trash,
  Undo,
  Up,
  UploadContent,
  UploadPaused,
  Upload,
  UploadVideo,
  UploadVideoClosed,
  Vertical,
  VideoCounter,
  Video,
  VideosStack,
  VimeoSmall,
  Watchquarter,
  YoutubeSmall,
  ZoomReset,
};

export default Icon;
