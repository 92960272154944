/* eslint-disable no-confusing-arrow */

import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { equals, flow, set, update } from "lodash/fp";
import { v4 as uuid } from "uuid";

import { TimeInterval, TimeIntervalEdge, ChapterTimeInterval } from "src/types/video-trimmer.types";

import { roundBy } from "src/utils/math.utils";
import { timeIntervalUtils } from "src/utils/timeInterval.utils";

import useShallowMemo from "src/hooks/useShallowMemo";
import { UseHistoryStateReturnValue } from "src/hooks/useHistoryState";

import { useModal } from "src/components/providers/ModalProvider";
import { useAppConfig } from "src/components/providers/AppConfigProvider";
import MaxChaptersLimitReachedModal from "src/components/modals/MaxChaptersLimitReachedModal"; // prettier-ignore
import { chapterTimeIntervalUtils } from "src/components/features/VideoTrimmer/common/chapterTimeInterval.utils";
import VideoChaptersContext, {
  ChaptersAllowedActions,
  VideoChaptersContextValue,
} from "src/components/features/VideoTrimmer/providers/VideoChaptersProvider/VideoChaptersContext";
import * as projectEditorAnalytics from "src/analytics/sequenceContentEditor.analytics";

const MIN_INTERVAL_DURATION = 0.1;

interface TimelineChaptersProviderProps extends PropsWithChildren {
  chaptersHistory: UseHistoryStateReturnValue<ChapterTimeInterval[]>;
  domains: TimeInterval[];
  minIntervalDuration?: number;
  allowedActions?: ChaptersAllowedActions;
}

export default function VideoChaptersProvider({
  children,
  chaptersHistory,
  domains,
  minIntervalDuration = MIN_INTERVAL_DURATION,
  allowedActions = { addChapter: { isAllowed: true }, removeChapter: { isAllowed: true } },
}: TimelineChaptersProviderProps) {
  const { openModal } = useModal();
  const { MAX_CHAPTERS_AMOUNT } = useAppConfig();

  const {
    state: chapters,
    set: setChapters,
    startBatching: startChaptersBatchUpdate,
    endBatching: endChaptersBatchUpdate,
  } = chaptersHistory;

  const [resizedChapterIndex, setResizedChapterIndex] = useState(-1);

  const isChapterSplittable = useCallback(
    (index: number, point: number) => {
      const targetChapter = chapters[index];

      if (!targetChapter) return false;

      return point - targetChapter.start >= minIntervalDuration && targetChapter.end - point >= minIntervalDuration;
    },
    [chapters, minIntervalDuration],
  );

  const isChapterDeletable = useCallback(
    (index: number) => {
      const targetChapter = chapters[index];

      return (
        targetChapter &&
        chapters.some((chapter) => equals(chapter.domain, targetChapter.domain) && chapter.id !== targetChapter.id)
      );
    },
    [chapters],
  );

  const startChapterResizing = useCallback(
    (index: number) => {
      startChaptersBatchUpdate();
      setResizedChapterIndex(index);
    },
    [startChaptersBatchUpdate],
  );

  const endChapterResizing = useCallback(() => {
    endChaptersBatchUpdate();
    setResizedChapterIndex(-1);
  }, [endChaptersBatchUpdate]);

  const resizeChapter = useCallback(
    (index: number, edge: TimeIntervalEdge, timeDelta: number) => {
      setChapters((prevState: ChapterTimeInterval[]) => {
        const chapterToResize = prevState[index];
        const leftSibling = prevState[index - 1];
        const rightSibling = prevState[index + 1];

        const applyMinDuration = (c: ChapterTimeInterval) =>
          set([edge], edge === "start" ? c.end - minIntervalDuration : c.start + minIntervalDuration, c);

        const resizedChapter = flow(
          (c: ChapterTimeInterval) => set([edge], roundBy(0.001, c[edge] + timeDelta), c),
          (c: ChapterTimeInterval) => (leftSibling ? timeIntervalUtils.applyMinStart(c, leftSibling.end) : c),
          (c: ChapterTimeInterval) => (rightSibling ? timeIntervalUtils.applyMaxEnd(c, rightSibling.start) : c),
          (c: ChapterTimeInterval) => timeIntervalUtils.applyBorders(c, c.domain),
          (c: ChapterTimeInterval) => (timeIntervalUtils.duration(c) < minIntervalDuration ? applyMinDuration(c) : c),
        )(chapterToResize);

        return set([index], resizedChapter, prevState);
      });
    },
    [minIntervalDuration, setChapters],
  );

  const splitChapter = useCallback(
    (index: number, timePoint: number) => {
      setChapters((prevState: ChapterTimeInterval[]) => {
        if (!isChapterSplittable(index, timePoint)) return prevState;

        const chapter = prevState[index];
        const [a, b] = timeIntervalUtils.slice(chapter, timePoint);

        if (!a || !b) return prevState;

        b.id = uuid();

        return [...prevState.slice(0, index), a, b, ...prevState.slice(index + 1)];
      });
      projectEditorAnalytics.trackSplit();
    },
    [isChapterSplittable, setChapters],
  );

  const mergeChapters = useCallback(
    (aIndex: number, bIndex: number) => {
      setChapters((prevState) => {
        const a = prevState[aIndex];
        const b = prevState[bIndex];

        if (!a || !b || !timeIntervalUtils.equals(a.domain, b.domain)) {
          return prevState;
        }

        return [
          ...prevState.slice(0, Math.min(aIndex, bIndex)),
          timeIntervalUtils.merge(a, b),
          ...prevState.slice(Math.max(aIndex, bIndex) + 1),
        ];
      });
    },
    [setChapters],
  );

  const deleteChapter = useCallback(
    (deletedIndex: number) => {
      setChapters((prevState) =>
        isChapterDeletable(deletedIndex)
          ? prevState.filter((chapter, chapterIndex) => chapterIndex !== deletedIndex)
          : prevState,
      );
      projectEditorAnalytics.trackRemove("remove-chapter");
    },
    [isChapterDeletable, setChapters],
  );

  const toggleChapterMuteState = useCallback(
    (index: number) => setChapters(update([index, "isMuted"], (isMuted) => !isMuted)),
    [setChapters],
  );

  const addTimeIntervals = useCallback(
    (timeIntervals: TimeInterval[]) =>
      setChapters((prevState) => {
        const newChapters = timeIntervals
          .map((timeInterval) => chapterTimeIntervalUtils.sliceIntervalToChapters(timeInterval, domains))
          .flat();

        return timeIntervalUtils
          .resolveOverlaps([...prevState, ...newChapters], (a, b) => equals(a.domain, b.domain))
          .map((c) => timeIntervalUtils.roundBy(c, 0.001));
      }),
    [setChapters, domains],
  );

  const subtractTimeIntervals = useCallback(
    (timeIntervals: TimeInterval[]) =>
      setChapters(
        flow(
          (nextState) => timeIntervalUtils.subtractTimeIntervals(nextState, timeIntervals),
          (nextState) => chapterTimeIntervalUtils.normalizeChapterIds(nextState),
          (nextState) => nextState.map((c) => timeIntervalUtils.roundBy(c, 0.001)),
        ),
      ),
    [setChapters],
  );

  useEffect(() => {
    if (chapters.length > MAX_CHAPTERS_AMOUNT && chaptersHistory.canUndo) {
      chaptersHistory.undo();
      openModal(MaxChaptersLimitReachedModal, { hideCloseButton: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal, chapters.length, chaptersHistory.canUndo, chaptersHistory.undo, MAX_CHAPTERS_AMOUNT]);

  const contextValue = useShallowMemo<VideoChaptersContextValue>({
    chapters,
    chaptersHistory,
    resizedChapterIndex,

    isChapterSplittable,
    isChapterDeletable,

    startChapterResizing,
    endChapterResizing,
    resizeChapter,
    splitChapter,
    mergeChapters,
    deleteChapter,
    toggleChapterMuteState,
    addTimeIntervals,
    subtractTimeIntervals,
    allowedActions,
  });

  return <VideoChaptersContext.Provider value={contextValue}>{children}</VideoChaptersContext.Provider>;
}
