import { createContext, useContext } from "react";

import { TimeInterval, TimeIntervalEdge } from "src/types/video-trimmer.types";

export interface TimelineZoomContextValue {
  zoomedTimeInterval: TimeInterval;
  zoomFactor: number;
  zoomBy(factor: number): void;
  zoomIn(): void;
  zoomOut(): void;
  zoomReset(): void;
  toggleFocusTimeInterval(timeInterval: TimeInterval, timeIntervalsLength: number, zoomFactor?: number): void;
  scrollToTimeInterval(timeInterval: TimeInterval): void;
  shiftZoomedTimeInterval(timeDelta: number): void;
  resizeZoomedTimeInterval(edge: TimeIntervalEdge, timeDelta: number): void;
}

const TimelineZoomContext = createContext<TimelineZoomContextValue | null>(null);

export default TimelineZoomContext;

export const useTimelineZoom = () => {
  const contextValue = useContext(TimelineZoomContext);

  if (!contextValue) {
    throw new Error("useTimelineZoom must be used within TimelineZoomProvider");
  }

  return contextValue;
};
