import { range } from "lodash/fp";
import { Fragment, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useSwiperContext } from "src/components/common/Swiper/Swiper";
import { useSwiperInnerScrolling } from "src/components/common/Swiper/hooks/useSwiperInnerScrolling";
import { Text3 } from "src/components/common/layout/typography.styled";
import {
  HeaderText,
  VPGrid,
  VPGridScroll,
  VisualPackageContainer,
  VisualPackageCover,
  VisualPackageFooter,
} from "src/components/features/AutomaticCreateWizard/common/automatic-create-wizard-layout.styled";
import BrandVisualPackage from "src/components/features/AutomaticCreateWizard/steps/components/BrandVisualPackage";
import { AssetType, FolderType } from "src/constants/model.constants";
import { folderStructureSelectors } from "src/models/FolderStructure.model";
import { RootState } from "src/models/store";
import { FormContextInterface } from "src/components/features/AutomaticCreateWizard/FormContext.interface";

export default function BrandVisuals({ formContext }: FormContextInterface) {
  const { isSliding: isSwiperSliding } = useSwiperContext();
  const form = formContext;
  const { watch, setValue } = form;
  const visualPackageId = watch("brandKit.visuals.visualPackageId");
  const packages = useSelector((state: RootState) => folderStructureSelectors.selectVisualPackages(state, FolderType.LottiePackage, AssetType.LottiePackagePreview)); // prettier-ignore

  const setVisualPackageId = useCallback(
    (value: string) => {
      setValue("brandKit.visuals.visualPackageId", value, { shouldValidate: true });
    },
    [setValue],
  );

  useEffect(() => {
    if (packages.length && !visualPackageId) {
      setVisualPackageId(packages[0].folder.sid!);
    }
  }, [packages, visualPackageId, setVisualPackageId]);

  return (
    <Fragment>
      <HeaderText>
        <strong>Pick a style for your video.</strong>
      </HeaderText>
      <HeaderText>Peech will highlight key messages with visuals.</HeaderText>
      <VPGridScroll marginTop={40} {...useSwiperInnerScrolling()}>
        <VPGrid>
          {packages?.length && !isSwiperSliding
            ? packages.map((vpItem) => (
                <BrandVisualPackage
                  formContext={formContext}
                  key={vpItem.folder.sid!}
                  visualPackageId={vpItem.folder.sid!}
                  radioButtonEnabled
                />
              ))
            : range(0, 6).map((i) => (
                <VisualPackageContainer key={i}>
                  <VisualPackageCover key={i} isLoaded={false} />

                  <VisualPackageFooter>
                    <Text3>&nbsp;</Text3>
                  </VisualPackageFooter>
                </VisualPackageContainer>
              ))}
        </VPGrid>
      </VPGridScroll>
    </Fragment>
  );
}
