import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";

import { themeColor } from "src/utils/styledComponents.utils";

import Icon from "src/components/common/Icon";
import { Button } from "src/components/common/buttons/Button.styled";
import { ModalComponentProps } from "src/components/providers/ModalProvider";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-width: 371px;
  min-height: 216px;
  background-color: inherit;
  border-radius: 10px;
  padding: 30px 0 0 0;

  @media (max-width: 768px) {
    width: 310px;
    max-width: 90vw;
    min-width: unset;
  }
`;

const CloseDiv = styled.div`
  position: absolute;
  top: 0;
  right: 6px;
  width: 14px;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: ${themeColor("gray.500")};
    }
  }
`;

const Title = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: ${themeColor("gray.900")};
  margin-bottom: 10px;
`;

const InfoDiv = styled(Title)`
  color: ${themeColor("black")};
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
`;

const StyledInput = styled.input`
  width: 100%;
  max-width: 100%;
  margin-block: 22px;
  height: 37px;
  border: 1px solid ${themeColor("gray.400")};
  border-radius: 3px;
  background-color: ${themeColor("white")};
  padding: 10px 12px 10px 10px;
  color: ${themeColor("gray.900")};
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: ${themeColor("gray.200")};
  }

  &:focus {
    outline: none !important;
    border: 1px solid ${themeColor("blue.500")};
    background-color: ${themeColor("gray.200")};
    box-shadow: 0 0 1px ${themeColor("gray.700")};
  }
`;

interface EntityTitleModalProps extends ModalComponentProps {
  onSubmit(title: string): void;
  modalTitle: string;
  description?: string;
  titlePlaceholder?: string;
  defaultPlaceHolder?: string;
  submitLabel: string;
}

export default function EntityTitleModal({
  close,
  onSubmit,
  modalTitle,
  description,
  titlePlaceholder,
  defaultPlaceHolder = "Your video title",
  submitLabel,
}: EntityTitleModalProps) {
  const titleInput = useRef<HTMLInputElement>(null);
  const [title, setTitle] = useState(titlePlaceholder ?? "");

  const onCreateClick = () => {
    onSubmit(title);
    close();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const onProjectTitleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();

    const pattern = /[A-Za-z0-9 \-_/]/gi;
    const { key } = e;

    if (!pattern.test(key)) {
      e.preventDefault();
    }

    if (e.which === 13) {
      e.preventDefault();
      e.currentTarget.blur();
    }
  };

  useEffect(() => {
    titleInput?.current?.select();
  }, []);

  return (
    <Container>
      <CloseDiv onClick={close}>
        <Icon.Close />
      </CloseDiv>

      <Title>{modalTitle}</Title>
      <InfoDiv>{description}</InfoDiv>

      <StyledInput
        ref={titleInput}
        autoFocus
        value={title}
        type="text"
        placeholder={defaultPlaceHolder}
        id="project-title"
        onChange={handleChange}
        onKeyDown={onProjectTitleKeyPress}
        maxLength={60}
        autoComplete="off"
      />

      <Button onClick={onCreateClick} disabled={!title} variant="primary" size="large">
        {submitLabel}
      </Button>
    </Container>
  );
}
