/* eslint-disable @typescript-eslint/no-unused-vars */
type RouteName = string;
type RoutePath = string;
type PathParamValue = string | number;

type JoinPaths<A extends RoutePath, B extends RoutePath> = "/" extends B ? A : `${A}/${B}`;

type RoutesPredicate = {
  [key in RouteName]: RoutePath | ChildRoutes;
};

type ChildRoutes = {
  path: RoutePath;
  children: RoutesPredicate;
};

type AbsoluteRoutesPredicate = {
  [key: RouteName]: RoutePath | AbsoluteRoutesPredicate;
};

type PathParamNameUnion<Path extends RoutePath> = Path extends `${infer _Start}:${infer Param}/${infer Rest}`
  ? Param | PathParamNameUnion<Rest>
  : Path extends `${infer _Start}:${infer Param}`
  ? Param
  : never;

type ExtractPathParams<Path extends RoutePath> = Record<PathParamNameUnion<Path>, PathParamValue>;

const isParentRoute = (route: RoutePath | ChildRoutes): route is ChildRoutes => typeof route === "object";

type AbsoluteRoutes<Routes extends RoutesPredicate, ParentRoutePath extends RoutePath = ""> = {
  [RouteNameK in keyof Routes]: Routes[RouteNameK] extends ChildRoutes
    ? {
        self: JoinPaths<ParentRoutePath, Routes[RouteNameK]["path"]>;
        children: AbsoluteRoutes<
          Routes[RouteNameK]["children"],
          JoinPaths<ParentRoutePath, Routes[RouteNameK]["path"]>
        >;
      }
    : JoinPaths<ParentRoutePath, Routes[RouteNameK]>;
};

export const makeAbsolute = <Routes extends RoutesPredicate>(
  routes: Routes,
  parentRoutePath: RoutePath = "",
): AbsoluteRoutes<Routes> => {
  const absoluteRoutes = Object.entries(routes).reduce<AbsoluteRoutesPredicate>(
    (acc, [routeName, route]) => ({
      ...acc,
      [routeName]: isParentRoute(route)
        ? {
            self: `${parentRoutePath}/${route.path}`,
            children: makeAbsolute(route.children, `${parentRoutePath}/${route.path}`),
          }
        : `${parentRoutePath}/${route}`,
    }),
    {},
  ) as AbsoluteRoutes<Routes>;

  return absoluteRoutes;
};

export const applyPathParams = <Path extends RoutePath>(
  path: Path,
  params: ExtractPathParams<Path>,
  dynamicParam?: { key: string; value: string | number },
) => {
  let resultPath: RoutePath = path;

  Object.entries(params).forEach(([pathParamName, pathParamValue]) => {
    resultPath = resultPath.replace(`:${pathParamName}`, `${pathParamValue}`);
  });

  if (dynamicParam) {
    const separator = resultPath.includes("?") ? "&" : "?";
    resultPath += `${separator}${dynamicParam.key}=${dynamicParam.value}`;
  }

  return resultPath;
};

// Router Routes
export const routerRoutes = {
  home: "",

  // Authentication
  logout: "logout",
  login: "login",
  signup: {
    path: "sign-up",
    children: {
      index: "",
      token: ":token",
      emailVerification: "email-verification",
    },
  },
  forgotPassword: {
    path: "forgot-password",
    children: {
      index: "",
      token: ":token",
      emailVerification: "email-verification",
    },
  },

  // Onboarding
  onboarding: {
    path: "onboarding",
    children: {
      index: "",
      step: ":step",
    },
  },

  // Billing
  billing: "billing",
  plans: "plans",
  upgradeFeature: "upgrade-feature",

  // Misc
  mobileUpload: "mobile-upload",
  projects: "projects", // old user projects page
  brand: "brand",

  // Project Creation
  createProjectFast: "create-project-fast",
  createProject: {
    path: "create-project",
    children: {
      index: "",
      step: ":step",
    },
  },

  // Automation Creation
  automaticCreate: {
    path: "automation-create",
    children: {
      index: "",
      step: {
        path: ":step",
        children: {
          subStep: ":subStep",
        },
      },
    },
  },

  createClip: {
    path: "create-clip",
    children: {
      index: "",
      step: {
        path: ":step",
        children: {
          subStep: ":subStep",
        },
      },
    },
  },

  // Editor
  projectSequence: {
    path: "project/:sequenceSid",
    children: {
      contentEditor: "content-editor",
      cropEditor: "crop-editor",
      visualsEditor: "visuals-editor",
      footagePlayer: "player",
      processing: "processing",
    },
  },

  // platform
  platform: {
    path: "platform",
    children: {
      index: "",
      footage: "p/:footageId",
      player: "player/:footageId",
      clipCreator: "clip-creator/:footageId",
      boards: "boards/:boardId?",
    },
  },

  // other - development use only
  iconsPreview: "icons-preview",
  cropperPoc: "cropper-poc",
} as const satisfies RoutesPredicate;

export const absoluteRoutes = makeAbsolute(routerRoutes);
