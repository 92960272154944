import { useCallback, useMemo, useState } from "react";
import { MAX_SAVED_STATES } from "src/constants/video-trimmer.constants";
import { isEqual } from "lodash/fp";
import { useEventSubscriber } from "src/hooks/useEventEmitter";
import { PlayFromData } from "src/models/Session.model";
import { useSelector } from "react-redux";
import { RootState } from "src/models/store";

type StateGetter<T> = (currentPresent: T) => T;

function isStateGetter<T>(state: T | StateGetter<T>): state is StateGetter<T> {
  return !!(state as StateGetter<T>).call;
}

interface HistoryState<T> {
  past: T[];
  present: T;
  future: T[];
  pastVideoTime: number[];
  presentVideoTime: number;
  futureVideoTime: number[];
  isBatching?: boolean;
}

interface UseHistoryStateReturn<T> {
  state: T;
  stateCurrentTime: number;
  init: (nextState: T) => void;
  set: (newState: T) => void;
  replace: (nextState: T | StateGetter<T>) => void;
  startBatching: () => void;
  endBatching: () => void;
  undo: () => void;
  redo: () => void;
  canUndo: boolean;
  canRedo: boolean;
  resetFuture: () => void;
  onSet: (callback: () => void) => void;
}

export default function useHistoryState<T>(initialState: T): UseHistoryStateReturn<T> {
  const { currentTime } = useSelector((state: RootState) => state.session?.playFrom);
  const [nextVideoTime, setNextVideoTime] = useState<number>(currentTime || -1);
  const [lastAction, setLastAction] = useState<"undo" | "redo" | null>(null);
  const [state, setState] = useState<HistoryState<T>>({
    past: [],
    present: initialState,
    future: [],
    pastVideoTime: [],
    presentVideoTime: nextVideoTime || 0,
    futureVideoTime: [],
    isBatching: false,
  });
  const [callback, setCallback] = useState<() => void>(() => {});
  const canUndo = state.past.length !== 0;
  const canRedo = state.future.length !== 0;
  const init = useCallback((nextState: T) => setState((currentState) => ({ ...currentState, present: nextState })), []);

  useEventSubscriber("currentTimeChanged", (playFrom: PlayFromData) => {
    setNextVideoTime(playFrom?.currentTime || 0);
  });

  const set = useCallback(
    (nextState: T | StateGetter<T>) => {
      setState((currentState) => {
        const nextPresent = isStateGetter(nextState) ? nextState(currentState.present) : nextState;

        return isEqual(nextPresent, currentState.present)
          ? currentState
          : {
              ...currentState,
              past: currentState.isBatching ? currentState.past : [...currentState.past, currentState.present],
              present: nextPresent,
              future: [],
              pastVideoTime: currentState.isBatching
                ? currentState.pastVideoTime
                : [...currentState.pastVideoTime, nextVideoTime],
              presentVideoTime: nextVideoTime,
              futureVideoTime: [],
            };
      });
      callback && callback();
    },
    [callback, nextVideoTime],
  );

  const onSet = useCallback((cb: () => void) => {
    setCallback(() => cb);
  }, []);

  const resetFuture = useCallback(() => {
    setState((currentState) => ({
      ...currentState,
      future: [],
      futureVideoTime: [],
    }));
  }, []);

  const replace = useCallback(
    (nextState: T | StateGetter<T>) =>
      setState((currentState) => {
        const nextPresent = isStateGetter(nextState) ? nextState(currentState.present) : nextState;

        return isEqual(nextPresent, currentState.present)
          ? currentState
          : {
              ...currentState,
              present: nextPresent,
              presentVideoTime: nextVideoTime,
              future: [],
              futureVideoTime: [],
            };
      }),
    [nextVideoTime],
  );

  const startBatching = useCallback(
    () =>
      setState((currentState) => ({
        ...currentState,
        past: [...currentState.past, currentState.present],
        pastVideoTime: [...currentState.pastVideoTime, currentState.presentVideoTime],
        isBatching: true,
      })),
    [],
  );

  const endBatching = useCallback(
    () =>
      setState((currentState) => ({
        ...currentState,
        isBatching: false,
      })),
    [],
  );

  const undo = useCallback(() => {
    if (state.past.length > MAX_SAVED_STATES) {
      setState((currentState) => ({
        ...currentState,
        past: currentState.past.slice(currentState.past.length - MAX_SAVED_STATES),
        pastVideoTime: currentState.pastVideoTime.slice(currentState.pastVideoTime.length - MAX_SAVED_STATES),
      }));
    }
    if (canUndo) {
      setState((currentState) => {
        if (currentState.past.length === 0) return currentState;

        const past = [...currentState.past];
        const present = past.pop() as T;

        const pastVideoTime = [...currentState.pastVideoTime];
        const presentVideoTime = pastVideoTime.pop() as number;

        setLastAction("undo");

        return {
          past,
          present,
          future: [currentState.present, ...currentState.future],
          pastVideoTime,
          presentVideoTime,
          futureVideoTime: [currentState.presentVideoTime, ...currentState.futureVideoTime],
        };
      });
    }
  }, [canUndo, state.past.length]);

  const redo = useCallback(() => {
    if (canRedo) {
      setState((currentState) => {
        if (currentState.future.length === 0) return currentState;

        const future = [...currentState.future];
        const present = future.shift() as T;

        const futureVideoTime = [...currentState.futureVideoTime];
        const presentVideoTime = futureVideoTime.shift() as number;

        setLastAction("redo");

        return {
          past: [...currentState.past, currentState.present],
          present,
          future,
          pastVideoTime: [...currentState.pastVideoTime, currentState.presentVideoTime],
          presentVideoTime,
          futureVideoTime,
        };
      });
    }
  }, [canRedo]);

  const stateCurrentTime = useMemo(() => {
    // on redo: stateCurrentTime is last pastVideoTime
    // on undo: stateCurrentTime is state.presentVideoTime
    const redoCurrentTime = state.pastVideoTime[state.pastVideoTime.length - 1];
    return lastAction === "redo" ? redoCurrentTime : state.presentVideoTime;
  }, [state, lastAction]);

  return {
    state: state.present,
    stateCurrentTime,
    init,
    set,
    replace,
    startBatching,
    endBatching,
    undo,
    redo,
    canUndo,
    canRedo,
    resetFuture,
    onSet,
  };
}

export type UseHistoryStateReturnValue<T> = ReturnType<typeof useHistoryState<T>>;
