import styled, { keyframes } from "styled-components/macro";

import { themeColor } from "src/utils/styledComponents.utils";

import { AspectRatio } from "src/constants/video.constants";

import ResponsivePeechItem from "src/components/common/ResponsiveLibrary/ResponsivePeechItem";
import { ItemContent, WebItemFooter } from "src/components/common/refactor/Webinars.styled";

const PlugHover = keyframes`
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.5;
  }
`;

const HoverBlock = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 31px);
  background-color: ${themeColor("gray.400")};
  border-radius: 8px;
  z-index: 2;
  animation: ${PlugHover} 2s infinite;
  transition: opacity 1500ms cubic-bezier(1, 0.12, 1, 0.12);
`;

export default function LoadingPlug({
  aspectRatio,
  verticalGutter,
}: {
  aspectRatio: AspectRatio;
  verticalGutter?: number;
}) {
  return (
    <ResponsivePeechItem isTransparent verticalGutter={verticalGutter}>
      <HoverBlock />
      <ItemContent aspectRatio={aspectRatio} />
      <WebItemFooter numRows={1} />
    </ResponsivePeechItem>
  );
}
