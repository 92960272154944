import { PropsWithChildren, ReactNode } from "react";
import styled, { css } from "styled-components/macro";
import { Placement } from "@popperjs/core/lib/enums";

import { themeColor } from "src/utils/styledComponents.utils";

import Popper from "src/components/common/popovers/Popper";
import Icon from "src/components/common/Icon";

const Container = styled.div`
  height: fit-content;
`;

const ContentWrapper = styled.div<{ placement?: Placement }>`
  position: relative;
  min-width: 100%;
  width: fit-content;
  height: fit-content;
  padding: 10px;
  border-radius: 10px;
  background-color: ${themeColor("white")};
  box-shadow: 0px 4px 16px 0px rgba(4, 27, 63, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
  }

  ${(props) =>
    props.placement === "top" &&
    css`
      &::before {
        top: 100%;
        left: 50%;
        transform: translateX(-50%) rotateX(180deg);
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid ${themeColor("white")};
      }
    `}
  ${(props) =>
    props.placement === "bottom" &&
    css`
      &::before {
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%) rotateX(180deg);
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid ${themeColor("white")};
      }
    `}
  ${(props) =>
    props.placement === "left" &&
    css`
      &::before {
        left: 100%;
        top: 50%;
        transform: translateY(-50%) rotateY(180deg);
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-right: 6px solid ${themeColor("white")};
      }
    `}
  ${(props) =>
    props.placement === "right" &&
    css`
      &::before {
        right: 100%;
        top: 50%;
        transform: translateY(-50%) rotateY(180deg);
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 6px solid ${themeColor("white")};
      }
    `}
    ${(props) =>
    props.placement === "right-start" &&
    css`
      &::before {
        right: 100%;
        top: 20px;
        transform: translateY(-50%) rotateY(180deg);
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 6px solid ${themeColor("white")};
      }
    `}
`;

const IconWrapper = styled.div`
  height: 100%;
  width: 24px;
  margin-right: 5px;
`;

const TextWrapper = styled.div<{ maxWidth?: number; textAlign: "left" | "center" | "right" }>`
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : "320px")};
  text-align: ${(props) => props.textAlign};
  font-family: "Open Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  color: ${themeColor("gray.900")};

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
`;

const ChildrenCont = styled.div`
  max-width: 100%;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

interface TooltipProps extends PropsWithChildren {
  text: string | ReactNode;
  placement?: Placement;
  disabled?: boolean;
  offsetX?: number;
  offsetY?: number;
  infoIcon?: boolean;
  trigger?: "hover" | "click";
  maxWidth?: number;
  textAlign?: "left" | "center" | "right";
}

export default function Tooltip({
  children,
  text,
  placement,
  disabled,
  offsetX,
  offsetY,
  infoIcon = false,
  trigger = "hover",
  maxWidth,
  textAlign = "left",
}: TooltipProps) {
  return (
    <Container>
      <Popper
        trigger={trigger}
        disabled={disabled}
        offsetY={offsetX ?? 10}
        offsetX={offsetY ?? 10}
        placement={placement}
        content={
          <ContentWrapper placement={placement}>
            {infoIcon && (
              <IconWrapper>
                <Icon.Info />
              </IconWrapper>
            )}
            <TextWrapper textAlign={textAlign} maxWidth={maxWidth}>
              {text}
            </TextWrapper>
          </ContentWrapper>
        }
      >
        <ChildrenCont>{children}</ChildrenCont>
      </Popper>
    </Container>
  );
}
