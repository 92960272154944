import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";

import styled from "styled-components/macro";

import { useGetOldAppLink } from "src/hooks/useOldAppRedirect";

import { AspectRatio } from "src/constants/video.constants";

import { CircularLoader } from "src/components/common/loaders/CircularLoader";
import { ModalComponentProps } from "src/components/providers/ModalProvider";
import { ifProp } from "styled-tools";
import { themeColor } from "src/utils/styledComponents.utils";

import * as sequenceAnalytics from "src/analytics/sequence.analytics";
import { CaptionsSyncModal } from "src/components/modals/CaptionsSyncModal";
import useCaptionsSync from "src/hooks/useCaptionsSync";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 96vw;
  height: 90vh;
  background-color: transparent;
`;

const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${themeColor("white")};
  padding: 30px;
  aspect-ratio: 1/1;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IframeStyled = styled.iframe<{ loaded?: boolean }>`
  width: 100%;
  height: 100%;
  border: none;
  opacity: ${ifProp("loaded", 1, 0)};
`;
interface ProjectPreviewModalProps extends ModalComponentProps {
  externalSequenceId: string;
  tag: String;
  onProjectEditClick: (externalSequenceId: string) => void;
  onTranslate: (languageCodeTarget: string) => void;
  onDuplicate: (ratioTarget: AspectRatio) => void;
  onUpgrade: (planId: string | undefined) => void;
  onOpen: () => void;
  download?: boolean;
}

export default function ProjectPreviewModal({
  close,
  externalSequenceId,
  onProjectEditClick,
  onTranslate,
  onDuplicate,
  onUpgrade,
  onOpen,
  tag = "",
  download = false,
}: ProjectPreviewModalProps) {
  const {
    isOpenSyncCaptionsModal,
    regenerateCaptions,
    denyRegenerateCaptions,
    closeSyncCaptionsModal,
    syncCcWithTranscript,
    allowRegenerateCC,
  } = useCaptionsSync(externalSequenceId);

  const getOldAppLink = useGetOldAppLink();
  const iframeSrc = `${getOldAppLink(
    `/project?allowRegenerateCC=${allowRegenerateCC}&autoSyncCC=${syncCcWithTranscript}&&new=true&isInPreview=true&tag=${tag}&download=${download}#${externalSequenceId}`,
  )}#1`;
  const [iframeLoaded, setIframeLoaded] = useState(false);

  const loadTimeoutRef = useRef<number>();

  useEffect(() => {
    const onMessage = (event: MessageEvent) => {
      if (typeof event.data === "string") {
        if (
          event.data.includes("DOWNLOAD_MODAL_CLOSED") ||
          event.data.includes("DRAFT_MODAL_CLOSED") ||
          event.data.includes("WATERMARK_MODAL_CLOSED") ||
          event.data.includes("UPGRADE_MODAL_CLOSED")
        ) {
          close();
        }
        if (event.data === "DRAFT_MODAL_READY") {
          window.clearTimeout(loadTimeoutRef.current);
          sequenceAnalytics.previewSequenceReady(externalSequenceId);
          setIframeLoaded(true);
        }
        if (event.data === "DRAFT_MODAL_EDIT_CLICK") {
          close();
          onProjectEditClick(externalSequenceId);
        }
        if (event.data.includes("CLONE")) {
          const data = event.data.split("_");
          const aspectRatio = data[1];
          close();
          onDuplicate(aspectRatio as AspectRatio);
        }
        if (event.data.includes("TRANSLATE")) {
          const data = event.data.split("_");
          const languageCode = data[1];
          close();
          onTranslate(languageCode);
        }
        if (event.data.includes("UPGRADE_PLAN")) {
          const data = event.data.split("_");
          const planId = data[2];
          close();
          onUpgrade(planId);
        }
      }
    };
    window.addEventListener("message", onMessage);
    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, [close, externalSequenceId, onDuplicate, onProjectEditClick, onTranslate, onUpgrade]);

  useEffect(() => {
    onOpen();
    loadTimeoutRef.current = window.setTimeout(() => {
      close();
      toast.error("Project preview failed to load. Please try again.");
    }, 90000);
    return () => {
      window.clearTimeout(loadTimeoutRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      {(!iframeLoaded || isOpenSyncCaptionsModal) && (
        <LoaderWrapper>
          <CircularLoader size={70} />
        </LoaderWrapper>
      )}

      {!isOpenSyncCaptionsModal && (
        <IframeStyled
          loaded={iframeLoaded}
          id="project-preview-iframe"
          title="project-preview"
          src={iframeSrc}
          width="100%"
          height="100%"
        />
      )}
      <CaptionsSyncModal
        onApprove={regenerateCaptions}
        onCancel={denyRegenerateCaptions}
        isOpen={isOpenSyncCaptionsModal}
        onClose={() => {
          closeSyncCaptionsModal();
          close();
        }}
      />
    </Container>
  );
}
