import styled from "styled-components/macro";
import { themeColor } from "src/utils/styledComponents.utils";
import { switchProp } from "styled-tools";

import Icon from "src/components/common/Icon";
import { Expandable } from "src/components/common/layout/Expandable";
import React, { useEffect, useRef, useState } from "react";
import useOnMouseDownOutside from "src/hooks/useOnMouseDownOutside";

export const Bar = styled.div<{ type?: string }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  width: 100%;
  padding-right: 5px;
  padding-left: 10px;
  color: ${switchProp(
    "type",
    {
      success: themeColor("status.success"),
      error: themeColor("status.error"),
      warning: themeColor("status.warning"),
    },
    themeColor("blue.500"),
  )};

  border: 1px solid
    ${switchProp(
      "type",
      {
        success: themeColor("status.success"),
        error: themeColor("status.error"),
        warning: themeColor("status.warning"),
      },
      themeColor("blue.500"),
    )};
  border-radius: 4px;
  font-size: 12px;
`;

export const CloseButton = styled(Icon.CircledX)<{ type?: string }>`
  position: relative;
  top: 0;
  right: 0;
  cursor: pointer;
  border-radius: 50%;
  height: 15px;
  color: ${switchProp(
    "type",
    {
      success: themeColor("status.success"),
      error: themeColor("status.error"),
      warning: themeColor("status.warning"),
    },
    themeColor("blue.500"),
  )};
`;

interface ErrorSnackBarProps {
  isOpen: boolean;
  message?: string;
  type?: string;
  onClose?: () => void;
}

export default function NotificationBar({
  isOpen,
  message = "Something went wrong. Please try again.",
  type,
  onClose = () => {},
}: ErrorSnackBarProps) {
  const [isSnackBarExpanded, setIsSnackBarExpanded] = useState(false);
  const snackbarRef = useRef(null);

  const handleCloseSnackbar = () => {
    setIsSnackBarExpanded(false);
    onClose();
  };

  useOnMouseDownOutside(snackbarRef, handleCloseSnackbar);

  useEffect(() => {
    if (isOpen) {
      setIsSnackBarExpanded(true);
    }
  }, [isOpen]);

  return (
    <Expandable isExpanded={isSnackBarExpanded}>
      <Bar type={type} ref={snackbarRef}>
        {message}
        <CloseButton type={type} onClick={handleCloseSnackbar} />
      </Bar>
    </Expandable>
  );
}
