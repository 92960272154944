import styled from "styled-components/macro";

import { themeColor } from "src/utils/styledComponents.utils";

import Icon from "src/components/common/Icon";
import { Button } from "src/components/common/buttons/Button.styled";
import { ModalComponentProps } from "src/components/providers/ModalProvider";
import { useSelector } from "react-redux";
import { RootState } from "src/models/store";
import { presetSelectors } from "src/models/Preset.model";
import { ifProp } from "styled-tools";
import { ChangeEvent, Fragment, KeyboardEvent, useRef, useState } from "react";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 320px;
  min-height: 460px;
  max-width: 90vw;
  max-height: 100%;
  background-color: inherit;
  border-radius: 10px;

  @media (min-width: 768px) {
    padding: 30px 0 20px 0;
    flex-direction: row;
    width: 664px;
    height: 270px;
    min-height: 270px;
  }
`;

const CloseDiv = styled.div`
  position: absolute;
  top: 0;
  right: 6px;
  width: 14px;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: ${themeColor("gray.500")};
    }
  }
`;

const MainText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
  font-family: "Open Sans", sans-serif;

  @media (max-width: 768px) {
    font-size: 12px;
    line-break: 14px;
  }
`;

const SecondaryText = styled(MainText)`
  font-weight: 400;
  color: ${themeColor("gray.900")};
`;

const Devider = styled.div`
  border: solid 1px;
  border-color: ${themeColor("blue.600")};
  height: 0;
  width: 130px;
  margin: 16px;

  @media (min-width: 768px) {
    height: 130px;
    width: 0;
    margin: 46px 36px;
  }
`;

const StyledButton = styled(Button)`
  border-radius: 70px;
  border-color: ${themeColor("pink.500")};
  color: ${themeColor("pink.500")};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  width: 160px;
  height: 40px;
  padding: 10px;
  margin-top: 18px;
`;

const ModalBlock = styled.div<{ disabled?: boolean }>`
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 768px) {
    width: 266px;
    height: 224px;
  }

  ${MainText} {
    opacity: ${ifProp("disabled", "0.5", "1")};
  }
`;

const BlockIcon = styled.div`
  margin-bottom: 10px;
  height: 95px;

  @media (max-width: 768px) {
    height: 60px;
  }
`;

const InfoBlock = styled(MainText)`
  height: 40px;
  margin: 18px 0 0 0;
  color: ${themeColor("pink.500")};
`;

const Title = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: ${themeColor("gray.900")};
  margin-bottom: 10px;
  text-align: center;
`;

const InfoDiv = styled(Title)`
  color: ${themeColor("black")};
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  text-align: center;
`;

const StyledInput = styled.input`
  width: 100%;
  max-width: 100%;
  margin-block: 40px;
  height: 37px;
  border: 1px solid ${themeColor("gray.400")};
  border-radius: 3px;
  background-color: ${themeColor("white")};
  padding: 10px 12px 10px 10px;
  color: ${themeColor("gray.900")};
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;

  &:focus {
    outline: none !important;
    border: 1px solid ${themeColor("gray.700")};
    box-shadow: 0 0 1px ${themeColor("gray.700")};
  }
`;

const ProjectTitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

interface SelectCreationFlowModalProps extends ModalComponentProps {
  close: () => void;
  onAutomaticSubmit: () => void;
  onManualSubmit(title: string): void;
}

export default function SelectCreationFlowModal({
  close,
  onAutomaticSubmit,
  onManualSubmit,
}: SelectCreationFlowModalProps) {
  const firstExperienceUser = useSelector((state: RootState) => presetSelectors.isUserFirstExperience(state));
  const [manualFlowIsChosen, setManualFlowIsChosen] = useState(false);

  const titleInput = useRef<HTMLInputElement>(null);
  const [title, setTitle] = useState("");

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const onProjectTitleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    const pattern = /[A-Za-z0-9 \-_/]/gi;
    const { key } = e;

    if (!pattern.test(key)) {
      e.preventDefault();
    }

    if (e.which === 13) {
      e.preventDefault();
      e.currentTarget.blur();
    }
  };

  const createAutomatically = () => {
    onAutomaticSubmit();
    close();
  };

  const createManually = () => {
    onManualSubmit(title);
    close();
  };

  return (
    <Container>
      <CloseDiv onClick={close}>
        <Icon.Close />
      </CloseDiv>
      {!manualFlowIsChosen ? (
        <Fragment>
          <ModalBlock>
            <BlockIcon>
              <Icon.ChooseAutomatic size="full" />
            </BlockIcon>
            <MainText>Let Peech generate clips for you</MainText>
            <SecondaryText>Utilize Peech&apos;s automation process to get branded video clips instantly</SecondaryText>
            <StyledButton onClick={createAutomatically} variant="ghost" size="medium">
              Upload New Video
            </StyledButton>
          </ModalBlock>

          <Devider />

          <ModalBlock disabled={!!firstExperienceUser}>
            <BlockIcon>
              <Icon.ChooseManually size="full" />
            </BlockIcon>

            <MainText>Choose the content you want</MainText>
            <SecondaryText>Manually select content to create a new clip that Peech will brand for you</SecondaryText>
            {!firstExperienceUser ? (
              <StyledButton onClick={() => setManualFlowIsChosen(true)} variant="ghost" size="medium">
                Select From Library
              </StyledButton>
            ) : (
              <InfoBlock>
                <Icon.Locked />
                Upload Your First Video to Use
              </InfoBlock>
            )}
          </ModalBlock>
        </Fragment>
      ) : (
        <ProjectTitleBlock>
          <Title>Create a new video</Title>
          <InfoDiv>Give your video a title so you can find it later</InfoDiv>

          <StyledInput
            ref={titleInput}
            value={title}
            type="text"
            placeholder="Your video title"
            id="project-title"
            onChange={handleChange}
            onKeyDown={onProjectTitleKeyPress}
            maxLength={60}
            autoComplete="off"
          />

          <Button onClick={createManually} disabled={!title} variant="primary" size="large">
            Create
          </Button>
        </ProjectTitleBlock>
      )}
    </Container>
  );
}
