import { PropsWithChildren, RefObject, useCallback, useMemo, useRef, useState } from "react";
import { EventEmitter } from "events";

import useShallowMemo from "src/hooks/useShallowMemo";
import useRequestAnimationFrameLoop from "src/hooks/useRequestAnimationFrameLoop";

import CurrentTimeContext from "src/components/features/VideoTrimmer/providers/VideoCurrentTimeProvider/VideoCurrentTimeContext";
import VideoCurrentTimeListenerContext, { CurrentTimeListener, VideoCurrentTimeListenerContextValue } from "src/components/features/VideoTrimmer/providers/VideoCurrentTimeProvider/VideoCurrentTimeListenerContext"; // prettier-ignore

type InternalEvents = {
  timeUpdate(currentTime: number): void;
};

interface CurrentTimeProviderProps extends PropsWithChildren {
  videoRef: RefObject<HTMLMediaElement>;
}

export default function VideoCurrentTimeProvider({ children, videoRef }: CurrentTimeProviderProps) {
  const [currentTime, setCurrentTime] = useState(videoRef.current?.currentTime ?? 0);

  const eventEmitter = useMemo(() => {
    const emitter = new EventEmitter<InternalEvents>();

    emitter.setMaxListeners(1000);

    return emitter;
  }, []);

  const addCurrentTimeListener = useCallback(
    (listener: CurrentTimeListener) => {
      eventEmitter.on("timeUpdate", listener);
    },
    [eventEmitter],
  );

  const removeCurrentTimeListener = useCallback(
    (listener: CurrentTimeListener) => {
      eventEmitter.off("timeUpdate", listener);
    },
    [eventEmitter],
  );

  const previousTimeRef = useRef(currentTime);
  useRequestAnimationFrameLoop(() => {
    const frameCurrentTime = videoRef.current?.currentTime;

    if (frameCurrentTime !== undefined && frameCurrentTime !== previousTimeRef.current) {
      setCurrentTime(frameCurrentTime);
      eventEmitter.emit("timeUpdate", frameCurrentTime);
      previousTimeRef.current = frameCurrentTime;
    }
  });

  return (
    <CurrentTimeContext.Provider value={currentTime}>
      <VideoCurrentTimeListenerContext.Provider
        value={useShallowMemo<VideoCurrentTimeListenerContextValue>({
          addCurrentTimeListener,
          removeCurrentTimeListener,
        })}
      >
        {children}
      </VideoCurrentTimeListenerContext.Provider>
    </CurrentTimeContext.Provider>
  );
}
