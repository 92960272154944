import { MouseEvent, PropsWithChildren, useMemo } from "react";

import { themeColor } from "src/utils/styledComponents.utils";
import styled from "styled-components/macro";
import { ifProp } from "styled-tools";

const GridItem = styled.div<{ isTransparent?: boolean; marginBlock?: string }>`
  background-color: ${ifProp("isTransparent", "transparent", themeColor("gray.50"))};
  border-radius: 8px;
  border-color: ${themeColor("gray.200")};
  box-shadow: ${ifProp("isTransparent", "none", "0 2px 5px rgba(0, 0, 0, 0.1)")};
  margin-block: ${ifProp("marginBlock", (props) => props.marginBlock || "8px", "8px")};
  width: 98%;
  user-select: none;

  height: fit-content;
  display: flex;
  flex-direction: column;

  position: relative;

  @media only screen and (min-width: 768px) {
    width: 48%;
  }

  @media only screen and (min-width: 1024px) {
    width: 31%;
  }

  @media only screen and (min-width: 1440px) {
    width: 23%;
  }

  @media only screen and (min-width: 1800px) {
    width: 19%;
  }
`;

export interface ResponsivePeechItemProps extends PropsWithChildren {
  onClick?: (event: MouseEvent) => void;
  isTransparent?: boolean;
  verticalGutter?: number;
}

export default function ResponsivePeechItem({
  onClick,
  children,
  isTransparent,
  verticalGutter,
}: ResponsivePeechItemProps) {
  const marginBlock = useMemo(() => (verticalGutter ? `${verticalGutter}px` : "8px"), [verticalGutter]);

  return (
    <GridItem onClick={(e) => onClick && onClick(e)} isTransparent={isTransparent} marginBlock={marginBlock}>
      {children}
    </GridItem>
  );
}
