import { YoutubeItem } from "src/types/youtube-api.types";
import { VideoMetaData } from "src/utils/uploader.utils";
import { parse, toSeconds } from "iso8601-duration";

import BaseUploadService, { FileUploadStatus } from "./BaseUpload.service";

export default class UploadToYoutubeService extends BaseUploadService {
  private youtubeId: string;
  private item: YoutubeItem;
  protected videoFileInfo: VideoMetaData | null;

  constructor(file: File, youtubeItem: YoutubeItem) {
    super(file, {});
    this.item = youtubeItem;
    this.youtubeId = file.name;
    this.generatedThumbnail = youtubeItem.snippet.thumbnails.medium.url;
    // Create VideoMetaData from YoutubeItem
    const durationInSeconds = toSeconds(parse(youtubeItem.contentDetails.duration));
    this.videoFileInfo = {
      fileType: "youTube",
      duration: durationInSeconds,
    };
  }

  async start() {
    super.start();

    this.status = FileUploadStatus.SUCCESS;
  }
}
