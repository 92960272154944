import { createContext, useContext } from "react";

import { UseHistoryStateReturnValue } from "src/hooks/useHistoryState";
import { ChapterTimeInterval, TimeInterval, TimeIntervalEdge } from "src/types/video-trimmer.types";

export interface ChaptersAllowedActions {
  addChapter: { isAllowed: boolean; message?: string };
  removeChapter: { isAllowed: boolean; message?: string };
}

export interface VideoChaptersContextValue {
  chapters: ChapterTimeInterval[];
  chaptersHistory: Pick<
    UseHistoryStateReturnValue<ChapterTimeInterval[]>,
    "undo" | "redo" | "canUndo" | "canRedo" | "resetFuture" | "onSet"
  >;
  resizedChapterIndex: number;

  isChapterSplittable(index: number, point: number): boolean;
  isChapterDeletable(index: number): boolean;

  startChapterResizing(index: number): void;
  endChapterResizing(index: number): void;
  resizeChapter(index: number, edge: TimeIntervalEdge, timeDelta: number): void;
  splitChapter(index: number, timePoint: number): void;
  mergeChapters(aIndex: number, bIndex: number): void;
  deleteChapter(index: number): void;
  toggleChapterMuteState(index: number): void;
  addTimeIntervals(timeIntervals: TimeInterval[]): void;
  subtractTimeIntervals(timeIntervals: TimeInterval[]): void;
  allowedActions: ChaptersAllowedActions;
}

const VideoChaptersContext = createContext<VideoChaptersContextValue | null>(null);

export default VideoChaptersContext;

export const useVideoChapters = () => {
  const contextValue = useContext(VideoChaptersContext);

  if (!contextValue) {
    throw new Error("useVideoChaptersContext must be used within VideoChaptersProvider");
  }

  return contextValue;
};
