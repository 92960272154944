import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { sequenceSelectors } from "src/models/Sequence.model";
import { CurrentSequenceSavingStatusType, SaveStatus } from "src/models/Session.model";
import { Dispatch, RootState } from "src/models/store";
import { useGetOldAppLink } from "src/hooks/useOldAppRedirect";
import { CircularLoader } from "src/components/common/loaders/CircularLoader";
import { absoluteRoutes, applyPathParams } from "src/utils/routes.utils";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet";
import useCaptionsSync from "src/hooks/useCaptionsSync";
import { CaptionsSyncModal } from "src/components/modals/CaptionsSyncModal";
import { debounce } from "lodash/fp";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100vw;
  height: calc(100vh - 55px);
`;

const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default function SequenceVisualsEditorPage() {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();
  const getOldAppLink = useGetOldAppLink();
  const { sequenceSid } = useParams();
  const sequence = useSelector((state: RootState) => sequenceSelectors.selectById(state, sequenceSid ?? ""));
  const location = useLocation();
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const currentSequenceReactorSavingStatus = useSelector((state: RootState) => state.session.currentSequenceReactorSavingStatus); // prettier-ignore
  const saveStatus = useSelector((state: RootState) => state.session.saveStatus);
  const { offset, chapterSid } = useSelector((state: RootState) => state.session?.playFrom);

  // need to use ref to keep the value of saveStatus for the timeout
  const saveStatusRef = useRef(saveStatus);

  const {
    isOpenSyncCaptionsModal,
    regenerateCaptions,
    denyRegenerateCaptions,
    closeSyncCaptionsModal,
    syncCcWithTranscript,
    checkedOnce,
    allowRegenerateCC,
  } = useCaptionsSync(sequenceSid!);

  const iframeSrc = useMemo(() => {
    if (!checkedOnce && isOpenSyncCaptionsModal) return undefined;
    if (location.search.includes("firstDraft=true")) {
      return `${getOldAppLink(
        `/project?playbackChapter=${chapterSid}&playbackOffset=${offset}&allowRegenerateCC=${allowRegenerateCC}&autoSyncCC=${syncCcWithTranscript}&new=true#${sequenceSid}`,
      )}#1`;
    }
    return `${getOldAppLink(
      `/project?playbackChapter=${chapterSid}&playbackOffset=${offset}&allowRegenerateCC=${allowRegenerateCC}&autoSyncCC=${syncCcWithTranscript}#${sequenceSid}`,
    )}#1`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOldAppLink, location.search, sequenceSid, checkedOnce, isOpenSyncCaptionsModal]);

  const processCloneMessaging = useCallback(
    (message: string) => {
      if (message.includes("CLONE")) {
        const data = message.split("_");
        const aspectRatio = data[1];
        const trigger = data[2];
        navigate(
          `${applyPathParams(absoluteRoutes.platform.children.footage, {
            footageId: sequence?.footageSid,
          })}?action=duplicate&ratio=${aspectRatio}&sequence=${sequenceSid}&trigger=${trigger}`,
        );
      } else if (message.includes("TRANSLATE")) {
        const data = message.split("_");
        const languageCode = data[1];
        const trigger = data[2];
        navigate(
          `${applyPathParams(absoluteRoutes.platform.children.footage, {
            footageId: sequence?.footageSid,
          })}?action=translate&languageCode=${languageCode}&sequence=${sequenceSid}&trigger=${trigger}`,
        );
      }
    },
    [navigate, sequence?.footageSid, sequenceSid],
  );

  const handleIframeLoad = useCallback(() => {
    setIframeLoaded(true);
  }, []);

  const processSaveMessaging = useCallback(
    (message: string) => {
      let savingStatus = CurrentSequenceSavingStatusType.NOT_STARTED;
      switch (message) {
        case "SEQUENCE_SAVING_LOADING":
          savingStatus = CurrentSequenceSavingStatusType.IN_PROGRESS;
          break;
        case "SEQUENCE_SAVING_SUCCESS":
          savingStatus = CurrentSequenceSavingStatusType.SUCCESS;
          break;
        case "SEQUENCE_SAVING_ERROR":
          savingStatus = CurrentSequenceSavingStatusType.ERROR;
          break;
        default:
          break;
      }
      if (
        currentSequenceReactorSavingStatus !== savingStatus &&
        savingStatus !== CurrentSequenceSavingStatusType.NOT_STARTED
      ) {
        dispatch.session.setCurrentSequenceReactorSavingStatus(savingStatus);
      }
    },
    [currentSequenceReactorSavingStatus, dispatch.session],
  );

  const onUnsyncCcDebounced = debounce(2000)(() => {
    sequenceSid && dispatch.sequenceCaptions.unsyncWithTranscript({ sequenceSid });
  });

  const processClosedCaptionsMessaging = useCallback(
    (message: string) => {
      switch (message) {
        case "UPDATE_WORDS":
          // eslint-disable-next-line no-console
          console.log("[CC] [processClosedCaptionsMessaging]", `onMessage: ${message}`);
          onUnsyncCcDebounced();
          break;
        case "REGENERATE_CC":
          // eslint-disable-next-line no-console
          console.log("[CC] [processClosedCaptionsMessaging]", `onMessage: ${message}`);
          regenerateCaptions();
          break;
        default:
          break;
      }
    },
    [regenerateCaptions, onUnsyncCcDebounced],
  );

  useEffect(() => {
    const onMessage = (event: MessageEvent) => {
      if (typeof event.data === "string") {
        processCloneMessaging(event.data);
        processSaveMessaging(event.data);
        processClosedCaptionsMessaging(event.data);
      }
    };
    window.addEventListener("message", onMessage);
    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, [processCloneMessaging, processSaveMessaging, processClosedCaptionsMessaging]);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log("[cc] SequenceVisualsEditor page saveStatus", { saveStatus });
    // save the value of saveStatus to the ref don't remove this line
    saveStatusRef.current = saveStatus;
  }, [saveStatus]);

  useEffect(() => {
    if (saveStatus === SaveStatus.PENDING) {
      // eslint-disable-next-line no-console
      console.log("[cc] SequenceVisualEditor page seTimeout start");
      setTimeout(() => {
        // eslint-disable-next-line no-console
        console.log("[cc] SequenceVisualEditor page seTimeout end", {
          checkedOnce,
          saveStatusRef: saveStatusRef.current,
        });
        if (saveStatusRef.current === SaveStatus.PENDING) {
          dispatch.session.setSaveStatus(SaveStatus.SAVED);
        }
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>{sequence?.title} | Peech - Automated Talk-Videos Creator</title>
      </Helmet>
      <Container>
        {iframeLoaded || isOpenSyncCaptionsModal ? null : (
          <LoaderWrapper>
            <CircularLoader size={70} marginTop={20} />
          </LoaderWrapper>
        )}

        {saveStatus === SaveStatus.SAVED && checkedOnce && !isOpenSyncCaptionsModal && (
          <iframe
            id="visuals-editor-iframe"
            title="visuals-editor"
            src={iframeSrc}
            width="100%"
            height="100%"
            onLoad={handleIframeLoad}
          />
        )}
        <CaptionsSyncModal
          onApprove={regenerateCaptions}
          onCancel={denyRegenerateCaptions}
          isOpen={isOpenSyncCaptionsModal}
          onClose={() => {
            navigate(-1);
            closeSyncCaptionsModal();
          }}
        />
      </Container>
    </>
  );
}
