import { useCallback } from "react";
import styled from "styled-components/macro";

import { Button } from "src/components/common/buttons/Button.styled";
import { H3 } from "src/components/common/layout/typography.styled";
import { ModalComponentProps } from "src/components/providers/ModalProvider";
import Icon from "src/components/common/Icon";
import { themeColor } from "src/utils/styledComponents.utils";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  height: 100%;
  min-height: 190px;
  width: 600px;

  * {
    font-family: "Open Sans", sans-serif !important;
  }
`;

const CloseDiv = styled.div`
  position: absolute;
  top: 0;
  right: 6px;
  width: 14px;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: ${themeColor("gray.500")};
    }
  }
`;

const MessageCont = styled.div`
  width: 90%;
  text-align: center;
  margin-block: 20px;
  white-space: pre-line;
`;

const ButtonsWrapper = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  ${Button} {
    margin-inline: 8px;
    min-width: 112px;
  }
`;

interface ConfirmationModalProps extends ModalComponentProps {
  onApprove?: () => void;
  onDecline?: () => void;
  title: string;
  description: string | JSX.Element;
  question?: string;
  approveLabel: string;
  declineLabel: string;
}
// TODO: share design with Redirect confirm modal
export default function ConfirmationModal({
  onApprove,
  onDecline,
  close,
  title,
  description,
  question,
  approveLabel,
  declineLabel,
}: ConfirmationModalProps) {
  const onConfirmClick = useCallback(() => {
    onApprove?.();
    close();
  }, [close, onApprove]);

  const onCancelClick = useCallback(() => {
    onDecline?.();
    close();
  }, [close, onDecline]);

  return (
    <Container>
      <CloseDiv onClick={close}>
        <Icon.Close />
      </CloseDiv>
      <H3 style={{ textAlign: "center", paddingBottom: 10 }}>{title}</H3>

      <MessageCont>
        {description}
        {!!question && (
          <>
            <br />
            <br />
            <b>{question}</b>
          </>
        )}
      </MessageCont>
      <ButtonsWrapper>
        <Button variant="ghost" size="medium" onClick={onCancelClick}>
          {declineLabel}
        </Button>
        <Button variant="primary" size="medium" onClick={onConfirmClick}>
          {approveLabel}
        </Button>
      </ButtonsWrapper>
    </Container>
  );
}
