import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "src/models/store";
import { sequenceSelectors } from "src/models/Sequence.model";
import useLocalStorage from "src/hooks/useLocalStorage";
import { footageSelectors } from "src/models/Footage.model";
import { EntityId } from "@reduxjs/toolkit";
import { Footage } from "src/network/graphql/generatedGraphqlSDK";
import { SaveStatus } from "src/models/Session.model";
import apiClient from "src/network/ApiClient";

export default function useCaptionsSync(sequenceSid: string) {
  const dispatch = useDispatch<Dispatch>();
  const sequence = useSelector((state: RootState) => sequenceSelectors.selectById(state, sequenceSid ?? ""));
  const chapters = useSelector((state: RootState) => sequenceSelectors.selectVideoChapters(state, sequenceSid));
  const saveStatus = useSelector((state: RootState) => state.session?.saveStatus);
  const scenes = sequence?.scenes;
  const footageSid = sequence?.footageSid!;
  const assetSid = [...(chapters || []), ...(scenes || [])]?.find((chapter) => chapter?.assetSid)?.assetSid!;
  const chapterSid = [...(chapters || []), ...(scenes || [])]?.find(
    (chapter) => chapter?.cuePointType !== 33 && chapter?.cuePointType !== 32 && chapter?.sid,
  )?.sid;
  const footage = useSelector((state: RootState) => sequence?.footageSid && footageSelectors.selectById(state, footageSid as EntityId)) as Footage; // prettier-ignore

  const footageLanguageCode = footage?.languageCode;
  const sequenceLanguageCode = sequence?.languageCode;
  const useV2ClosedCaptions = sequence?.useV2ClosedCaptions;

  const [isOpenSyncCaptionsModal, setIsOpenSyncCaptionsModal] = useState(false);
  const [checkedOnce, setCheckedOnce] = useState(false);
  const [syncCcWithTranscript, setSyncCcWithTranscript] = useState(false);
  const [wordsLastVersion, setWordsLastVersion] = useState<null | number>(null);
  const [captionsLastVersion, setCaptionsLastVersion] = useState<null | number>(null);

  const [deniedRegenerateSequences, setDeniedRegenerateSequences] = useLocalStorage("deniedRegenerateSequences", [
    { sequenceSid: "", wordsVersion: undefined, captionsVersion: undefined },
  ]);

  const wasRegenerateDeniedBefore = useMemo(
    () => deniedRegenerateSequences.some((seq) => seq.sequenceSid === sequenceSid),
    [deniedRegenerateSequences, sequenceSid],
  );

  const allowRegenerateCC = useMemo(
    () => footageLanguageCode === sequenceLanguageCode && useV2ClosedCaptions,
    [footageLanguageCode, useV2ClosedCaptions, sequenceLanguageCode],
  );

  const regenerateCaptions = useCallback(async () => {
    if (sequenceSid) {
      try {
        await dispatch.sequenceCaptions.regenerateSequenceCaptions({
          sequenceSid,
        });

        await apiClient.updateSequence({
          sid: sequence?.sid as string,
          values: {
            updateTimeStamp: Math.floor(Date.now() / 1000),
            style: { captionFormatting: null },
          },
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }

      const visualEditorIframe = document.getElementById("visuals-editor-iframe") as HTMLIFrameElement;
      visualEditorIframe?.contentWindow?.postMessage("CAPTIONS_REGENERATED", "*");
      const filteredDeniedRegenerateSequences = deniedRegenerateSequences.filter(
        (seq) => seq.sequenceSid !== sequenceSid,
      );
      setDeniedRegenerateSequences(filteredDeniedRegenerateSequences);
      setSyncCcWithTranscript(true);
      isOpenSyncCaptionsModal && setIsOpenSyncCaptionsModal(false);
    }
  }, [
    deniedRegenerateSequences,
    dispatch.sequenceCaptions,
    isOpenSyncCaptionsModal,
    sequence?.sid,
    sequenceSid,
    setDeniedRegenerateSequences,
  ]);

  const denyRegenerateCaptions = useCallback(() => {
    if (!wasRegenerateDeniedBefore) {
      setDeniedRegenerateSequences([
        ...deniedRegenerateSequences,
        { sequenceSid, wordsVersion: wordsLastVersion, captionsVersion: captionsLastVersion },
      ]);
      isOpenSyncCaptionsModal && setIsOpenSyncCaptionsModal(false);
    }
  }, [
    wasRegenerateDeniedBefore,
    setDeniedRegenerateSequences,
    deniedRegenerateSequences,
    sequenceSid,
    wordsLastVersion,
    captionsLastVersion,
    isOpenSyncCaptionsModal,
  ]);

  const closeSyncCaptionsModal = useCallback(() => {
    setIsOpenSyncCaptionsModal(false);
  }, []);

  const handleCaptionRegeneration = useCallback(async () => {
    if (!allowRegenerateCC || !sequenceSid || !footageSid || !chapterSid || !assetSid) {
      // set check if not supported
      setCheckedOnce(true);
      return;
    }

    const captions = await dispatch.sequenceCaptions.fetchV2SequenceCaptions({ sequenceSid, chapterSid });
    const words = await dispatch.words.fetchWords({
      footageId: footageSid,
      externalAssetSid: assetSid,
      sequenceSid,
      useV2ClosedCaptions: !!useV2ClosedCaptions,
    });

    const transcriptWordsHasUpdates = words?.lastVersion > captions?.contentVersion;
    setCaptionsLastVersion(captions?.contentVersion);
    setWordsLastVersion(words?.lastVersion);
    setSyncCcWithTranscript(captions?.syncWithTranscript);

    // eslint-disable-next-line no-console
    console.log("[CC] [handleCaptionRegeneration]", "start handling", {
      syncCcWithTranscript,
      transcriptWordsHasUpdates,
      wordsLastVersion: words?.lastVersion,
      captionsLastVersion: captions?.contentVersion,
    });
    setCheckedOnce(true);

    const autoRegenerate = transcriptWordsHasUpdates && captions?.syncWithTranscript;
    const askUserBeforeRegenerate =
      transcriptWordsHasUpdates && !captions?.syncWithTranscript && !wasRegenerateDeniedBefore;

    if (autoRegenerate) {
      await regenerateCaptions();
      // eslint-disable-next-line no-console
      console.log("[CC] [handleCaptionRegeneration]", "trigger regenerateCaptions");
      return;
    }
    if (askUserBeforeRegenerate) {
      setIsOpenSyncCaptionsModal(true);
      // eslint-disable-next-line no-console
      console.log("[CC] [handleCaptionRegeneration]", "open modal");
      return;
    }
    // eslint-disable-next-line no-console
    console.log("[CC] [handleCaptionRegeneration]", "nothing to do");
  }, [
    allowRegenerateCC,
    assetSid,
    chapterSid,
    dispatch.sequenceCaptions,
    dispatch.words,
    footageSid,
    regenerateCaptions,
    sequenceSid,
    syncCcWithTranscript,
    useV2ClosedCaptions,
    wasRegenerateDeniedBefore,
  ]);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log("[cc]before A  handleCaptionRegeneration", { saveStatus });
    if (saveStatus !== SaveStatus.SAVED) return;
    // eslint-disable-next-line no-console
    console.log("[cc]before B  handleCaptionRegeneration", { saveStatus });
    handleCaptionRegeneration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveStatus]);

  return {
    isOpenSyncCaptionsModal,
    regenerateCaptions,
    denyRegenerateCaptions,
    closeSyncCaptionsModal,
    syncCcWithTranscript,
    checkedOnce,
    allowRegenerateCC,
  };
}
