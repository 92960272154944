export function areColorsIdentical(colorsCurrent: any, colorsPrev: any) {
  if (!colorsCurrent?.length || colorsCurrent?.length !== colorsPrev?.length) {
    return false;
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < colorsCurrent?.length; i++) {
    const color1 = colorsCurrent[i];
    const color2 = colorsPrev[i];
    if (
      color1.dominancy !== color2.dominancy ||
      color1.color !== color2.color
      // color1.percent !== color2.percent
      // color1.grayness !== color2.grayness
    ) {
      return false;
    }
  }

  return true;
}
