import styled from "styled-components/macro";

import * as cropAnalytics from "src/analytics/sequenceCropEditor.analytics";

import Icon from "src/components/common/Icon";
import { Stack } from "src/components/common/layout/Stack.styled";
import { useVideoCrop } from "src/components/features/VideoCropper/providers/VideoCropProvider/VideoCropContext";
import { ShortcutsKeys, getShortcutKeyLabel } from "src/constants/shortcuts.constants";
import ActionButton from "../VideoTrimmer/TimelineActionButtons/ActionButton";

const Container = styled(Stack)`
  align-items: center;
  height: 32px;
  flex: 1;
`;

const RightSideButtonsContainer = styled.div`
  display: flex;
  margin-left: auto;
  flex-direction: row;
  gap: 8px;
`;

export default function VideoCropperActionButtons() {
  const { cropsHistory } = useVideoCrop();
  const { canUndo, canRedo, undo, redo } = cropsHistory;
  return (
    <Container direction="row" spacing={10}>
      <RightSideButtonsContainer>
        <ActionButton
          label={`Undo ${getShortcutKeyLabel(ShortcutsKeys.undo)}`}
          disabled={!canUndo}
          onClick={() => {
            undo();
            cropAnalytics.trackUndo("undo-button");
          }}
        >
          <Icon.Undo size={10} color="black" />
          Undo
        </ActionButton>
        <ActionButton
          label={`Redo ${getShortcutKeyLabel(ShortcutsKeys.redo)}`}
          disabled={!canRedo}
          onClick={() => {
            redo();
            cropAnalytics.trackRedo("redo-button");
          }}
        >
          <Icon.Redo size={10} color="black" />
          Redo
        </ActionButton>
      </RightSideButtonsContainer>
    </Container>
  );
}
