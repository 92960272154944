import BaseUploadService, { FileUploadStatus, IUploadOptions } from "./BaseUpload.service";

export interface IGcsUploadOptions extends IUploadOptions {
  signedUrl: string;
}

export default class UploadToGcsService extends BaseUploadService {
  private isCancelled: boolean;
  private fileLocation: string | null;
  private signedUrl: string;
  private stopUploadLastChunk: boolean;

  constructor(file: File, uploadOptions: IGcsUploadOptions) {
    super(file, uploadOptions);
    this.isCancelled = false;
    this.fileLocation = null;
    this.signedUrl = uploadOptions.signedUrl;
    this.stopUploadLastChunk = false;
  }

  set waitForLastChunk(value: boolean) {
    this.stopUploadLastChunk = value;
    const isLastChunk = Math.min(this.uploadedBytes + this.uploadOptions.chunkSize!, this.file.size) === this.file.size;
    if (!value && isLastChunk && this.uploadedBytes < this.file.size) {
      // eslint-disable-next-line
      console.log("resume upload last chunk");
      this.uploadChunk(
        this.uploadedBytes,
        Math.min(this.uploadedBytes + this.uploadOptions.chunkSize!, this.file.size),
      );
    }
  }

  get waitForLastChunk() {
    return this.stopUploadLastChunk;
  }

  async start() {
    super.start();
    // TODO: need to check if need to resume upload of a new upload

    // get location if start new upload
    this.status = FileUploadStatus.UPLOADING;
    this.fileLocation = await this.getFileLocation();
    try {
      const chunkSize = this.uploadOptions.chunkSize!;
      const startBytes = 0;
      const endBytes = Math.min(startBytes + chunkSize, this.file.size);

      await this.uploadChunk(startBytes, endBytes);
    } catch (error) {
      const err = new Error(`Upload failed with status ${error}`);
      this.onError(err);
      throw err;
    }
  }

  // get the file upload id with the signUrl
  async getFileLocation() {
    try {
      const response = await fetch(this.signedUrl, {
        method: "POST",
        headers: {
          "x-goog-resumable": "start",
          "Content-Type": "application/octet-stream",
        },
      });

      const location = response.headers.get("location");

      if (location) {
        return location;
      }
      const err = new Error(`Upload failed with status ${response.status}`);
      this.onError(err);
      throw err;
    } catch (error) {
      const err = new Error(`Upload failed with status ${error}`);
      this.onError(err);
      throw err;
    }
  }

  // upload a file chunk and retry if needed
  async uploadChunk(startByte: number, endByte: number, retries = 0): Promise<void> {
    try {
      const isLastChunk = endByte === this.file.size;
      if (isLastChunk && this.stopUploadLastChunk) {
        // eslint-disable-next-line
        console.log("stop upload last chunk");
        return;
      }

      const chunk = this.file.slice(startByte, endByte);
      const contentRange = `bytes ${startByte}-${endByte - 1}/${this.file.size}`;

      const response = await fetch(this.fileLocation!, {
        method: "PUT",
        headers: {
          "Content-Type": "application/octet-stream", // TODO: used the file type when backed is support
          "Content-Range": contentRange,
          "x-goog-resumable": "start",
        },
        body: chunk,
      });

      if (response.status === 308) {
        const range = response.headers.get("range");
        if (range) {
          const [, endByteResponse] = range.split("-").map(Number);
          this.uploadedBytes = endByteResponse + 1;
          if (!this.isCancelled) {
            // TODO: we can cancel the upload fetch call
            this.eventEmitter.emit("progress", this.progress);
            await this.uploadChunk(
              this.uploadedBytes,
              Math.min(this.uploadedBytes + this.uploadOptions.chunkSize!, this.file.size),
            );
          }
        }
      } else if (response.status === 200) {
        this.uploadedBytes = this.file.size;
        this.eventEmitter.emit("progress", this.progress);
        this.onSuccess();
      } else {
        const err = new Error(`Upload failed with status ${response.status}`);
        this.onError(err);
        throw err;
      }
    } catch (e) {
      if (retries < this.uploadOptions.maxRetries!) {
        await this.sleep(this.uploadOptions.retryDelay!);
        await this.uploadChunk(startByte, endByte, retries + 1);
      } else {
        const err = e as Error;
        this.onError(err);
      }
    }
  }

  // use to resume where we left off the upload
  // TODO: need to work on this function
  async getRangeForResume() {
    const response = await fetch(this.fileLocation!, {
      method: "PUT",
      headers: {
        "Content-Range": `bytes */${this.file.size}`,
      },
    });
    // eslint-disable-next-line
    console.log(response.headers.get("range"));
  }

  async cancel() {
    this.isCancelled = true;
  }
}
