import React, { Fragment, useCallback, useEffect, useMemo } from "react";
import styled from "styled-components/macro";
import { compact, flow, groupBy, some } from "lodash/fp";

import { ChapterTimeInterval, TimeInterval } from "src/types/video-trimmer.types";

import { LINE_HEIGHT } from "src/constants/video-trimmer.constants";

import useMousetrap from "src/hooks/useMousetrap";

import { themeColor } from "src/utils/styledComponents.utils";
import { timeIntervalUtils } from "src/utils/timeInterval.utils";

import Icon from "src/components/common/Icon";
import Popper from "src/components/common/popovers/Popper";
import { Stack } from "src/components/common/layout/Stack.styled";
import { useVideoChapters } from "src/components/features/VideoTrimmer/providers/VideoChaptersProvider/VideoChaptersContext";
import { ifProp } from "styled-tools";

export const SelectionActionButtonStack = styled(Stack)`
  display: flex;
  user-select: none;
  padding: 3px;
  background: ${themeColor("white")};
  border-radius: 6px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  white-space: pre;
`;

export const SelectionActionButton = styled.button<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 4px 5px;
  border: none;
  outline: none;
  background-color: transparent;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: ${themeColor("gray.500")};
  opacity: ${ifProp("disabled", "0.5", "1")};

  &:not(:disabled):hover {
    background: ${themeColor("gray.200")};
  }

  svg {
    flex: 0 0 auto;
    margin-right: 6px;
  }
`;

const Divider = styled.hr`
  width: 2px;
  border-radius: 2px;
  margin-block: 4px;
  border: none;
  background: ${themeColor("gray.275")};
`;

export type Props = {
  selectionTimeInterval: TimeInterval | null;
  recentInteractedSelectionEdge: "start" | "end";
  cancelSelection(): void;
  isPlayable: boolean;
  isInitialChapter: boolean;
};

export default function ClipCreatorSelectionActionsPopover({
  selectionTimeInterval,
  recentInteractedSelectionEdge,
  cancelSelection,
  isPlayable,
  isInitialChapter,
}: Props) {
  const {
    chapters,
    addTimeIntervals,
    subtractTimeIntervals,
    allowedActions: chaptersAllowedActions,
  } = useVideoChapters();

  const isRemoveHidden = useMemo(() => {
    if (!chaptersAllowedActions?.removeChapter?.isAllowed) return true;
    if (!selectionTimeInterval || !isPlayable) return true;

    if (
      !chapters.some(
        (chapter) =>
          timeIntervalUtils.intersectsTimeInterval(chapter, selectionTimeInterval) ||
          timeIntervalUtils.containsTimeInterval(chapter, selectionTimeInterval) ||
          timeIntervalUtils.containsTimeInterval(selectionTimeInterval, chapter) ||
          timeIntervalUtils.includesTimePoint(selectionTimeInterval, chapter.start, true) ||
          timeIntervalUtils.includesTimePoint(selectionTimeInterval, chapter.end, true) ||
          timeIntervalUtils.includesTimePoint(chapter, selectionTimeInterval.start, true) ||
          timeIntervalUtils.includesTimePoint(chapter, selectionTimeInterval.end, true),
      )
    ) {
      return true;
    }

    return flow(
      groupBy<ChapterTimeInterval>(({ domain }) => JSON.stringify(domain)),
      some<ChapterTimeInterval[]>((chapterGroup) =>
        chapterGroup.every((chapter) => timeIntervalUtils.containsTimeInterval(selectionTimeInterval, chapter)),
      ),
    )(chapters);
  }, [selectionTimeInterval, isPlayable, chaptersAllowedActions?.removeChapter?.isAllowed, chapters]);

  const isAddHidden = useMemo(() => {
    if (!chaptersAllowedActions?.addChapter?.isAllowed) return true;
    if (!selectionTimeInterval) return true;
    return timeIntervalUtils
      .resolveOverlaps(chapters, true)
      .some((chapter) => timeIntervalUtils.containsTimeInterval(chapter, selectionTimeInterval));
  }, [chaptersAllowedActions?.addChapter?.isAllowed, selectionTimeInterval, chapters]);

  const keepOnlySelectedWords = useCallback(() => {
    if (!selectionTimeInterval) return;

    const startChapter = chapters.find((c) => timeIntervalUtils.includesTimePoint(c.domain, selectionTimeInterval.start)); // prettier-ignore
    const endChapter = chapters.find((c) => timeIntervalUtils.includesTimePoint(c.domain, selectionTimeInterval.end));

    if (!startChapter || !endChapter) return;

    const { start } = startChapter.domain;
    const { end } = endChapter.domain;

    addTimeIntervals([selectionTimeInterval]);
    subtractTimeIntervals(timeIntervalUtils.invertTimeIntervals([selectionTimeInterval], 0, { start, end }));
    cancelSelection();
    // TODO: add analytics
  }, [addTimeIntervals, cancelSelection, chapters, selectionTimeInterval, subtractTimeIntervals]);

  const removeSelectedWords = useCallback(() => {
    if (isInitialChapter) {
      // If there is only one chapter and it covers the whole video, we use keep only to start a new chapter
      keepOnlySelectedWords();
      return;
    }
    if (selectionTimeInterval) {
      subtractTimeIntervals([selectionTimeInterval]);
      cancelSelection();
      // TODO: add analytics
    }
  }, [cancelSelection, isInitialChapter, keepOnlySelectedWords, selectionTimeInterval, subtractTimeIntervals]);

  const addSelectedWords = useCallback(() => {
    if (selectionTimeInterval) {
      addTimeIntervals([selectionTimeInterval]);
      cancelSelection();
      // TODO: add analytics
    }
  }, [addTimeIntervals, cancelSelection, selectionTimeInterval]);

  const onCancelSelection = useCallback(() => {
    cancelSelection();
    // TODO: add analytics
  }, [cancelSelection]);

  useEffect(() => {
    /** when pointer (mouse/trackpad/etc...) text selection ends, it triggers a click event right away
     *  so to prevent an immediate call to onCancelSelection on component mount, this click must be ignored */
    const timeoutId = setTimeout(() => {
      document.addEventListener("click", onCancelSelection);
    });

    return () => {
      clearTimeout(timeoutId);
      document.removeEventListener("click", onCancelSelection);
    };
  }, [onCancelSelection]);

  useMousetrap("escape", onCancelSelection);

  const actions = compact([
    !isRemoveHidden ? (
      <SelectionActionButton onClickCapture={removeSelectedWords}>
        {isInitialChapter ? <Icon.Plus size={14} color="blue.500" /> : <Icon.Minus size={14} color="blue.500" />}
        {isInitialChapter ? "Add" : "Remove"}
      </SelectionActionButton>
    ) : null,

    !isAddHidden ? (
      <SelectionActionButton onClickCapture={addSelectedWords}>
        <Icon.Plus size={14} color="gray.500" />
        Add
      </SelectionActionButton>
    ) : null,
  ]);

  return (
    <Popper
      isOpen
      useClickOutside={false}
      placement={recentInteractedSelectionEdge === "end" ? "bottom" : "top"}
      offsetY={recentInteractedSelectionEdge === "end" ? 5 : LINE_HEIGHT + 5}
      content={
        <SelectionActionButtonStack direction="row" spacing={4}>
          {actions.map((action, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={index}>
              {action}
              {index < actions.length - 1 && <Divider />}
            </Fragment>
          ))}
        </SelectionActionButtonStack>
      }
    />
  );
}
