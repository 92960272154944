import useVariableRef from "src/hooks/useVariableRef";
import { useEffect } from "react";

export function useUnmountEffect(onUnmount: () => void) {
  const onUnmountRef = useVariableRef(onUnmount);

  useEffect(
    () => () => {
      onUnmountRef.current();
    },
    [onUnmountRef],
  );
}
