import { useMemo } from "react";

import { useGetAssetImageURL } from "src/hooks/useGetAssetURL";

import styled from "styled-components/macro";
import { themeColor } from "src/utils/styledComponents.utils";

import { CircularLoader } from "src/components/common/loaders/CircularLoader";
import MediaSource from "src/components/common/media/MediaSource";
import { CheckerboardBg } from "src/components/common/background/Checkerboard.styled";
import { FormContextInterface } from "src/components/features/AutomaticCreateWizard/FormContext.interface";

const LogoWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  margin-inline: 10px;
  max-height: 80px;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid ${themeColor("gray.300")};
  padding: 5px;
  ${CheckerboardBg}
`;

const LogoImage = styled.img`
  width: fit-content;
  max-width: 100%;
  max-height: 80px;
  object-fit: contain;
`;

export default function BrandButtonLogo({ formContext }: FormContextInterface) {
  const { watch } = formContext;
  const getAssetImageURL = useGetAssetImageURL();
  const logoAssetSid = watch("brandKit.logo.assetId");
  const logoUrl = useMemo(() => logoAssetSid && getAssetImageURL(logoAssetSid), [getAssetImageURL, logoAssetSid]); // prettier-ignore

  if (!logoAssetSid) {
    return null;
  }

  return (
    <LogoWrapper>
      <MediaSource src={logoUrl} placeholder={<CircularLoader size={40} />}>
        {(src) => <LogoImage src={src} alt="logo" />}
      </MediaSource>
    </LogoWrapper>
  );
}
