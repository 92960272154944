/* eslint-disable react/no-unescaped-entities */

import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import styled from "styled-components/macro";
import * as yup from "yup";

import { Dispatch, RootState } from "src/models/store";

import GoogleLogin from "src/components/auth/common/GoogleLogin";
import { H6, H2 } from "src/components/common/layout/typography.styled";
import { Button } from "src/components/common/buttons/Button.styled";
import { Field } from "src/components/common/form/Field.styled";
import { Input, PasswordInput, FieldError, NetworkError } from "src/components/common/form/smart-form-components";
import {
  AlternativeAuthButton,
  AlternativeAuthLabel,
  Container,
  ContentContainer,
  Divider,
  FormFieldStack,
  SubmitButton,
} from "src/components/auth/common/layout.styled";
import { absoluteRoutes } from "src/utils/routes.utils";
import NotificationBar from "src/components/auth/common/NotificationBar";
import useOauthNetworkError, { PERSONAL_EMAIL_ERROR_TEXT } from "src/components/auth/common/useOauthNetworkError";

const ForgotPasswordButton = styled(Button)`
  position: absolute;
  top: 37px;
  right: 0;
  padding: 0;
  font-weight: 600;
`;

const loginFormSchema = yup.object({
  email: yup.string().required("please enter your email address"),
  password: yup.string().required("please enter your password"),
});

type LoginForm = yup.InferType<typeof loginFormSchema>;

export default function Login() {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();
  const loginForm = useForm<LoginForm>({ resolver: yupResolver(loginFormSchema) });
  const { handleSubmit } = loginForm;
  const networkError = useSelector((state: RootState) => state.loading.effects.users.loginUser.error as Error);
  const { isPersonalEmailAddress, resetLoginUserOauthError } = useOauthNetworkError();

  const onSubmit = useCallback(
    ({ email, password }: LoginForm) => dispatch.users.loginUser({ email, password }),
    [dispatch],
  );

  return (
    <Container>
      <ContentContainer>
        <H2>Welcome to Peech</H2>
        <H6>Let’s log in to start creating new videos</H6>

        <FormProvider {...loginForm}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormFieldStack>
              <GoogleLogin text="signin_with" />
              <NotificationBar
                isOpen={isPersonalEmailAddress}
                onClose={resetLoginUserOauthError}
                message={PERSONAL_EMAIL_ERROR_TEXT}
                type="error"
              />
              <Divider />

              <Field>
                <Input<LoginForm> name="email" placeholder="Email" data-cy="login-email-input" inputSize="medium" />
                <FieldError<LoginForm> name="email" />
              </Field>

              <Field>
                <PasswordInput<LoginForm>
                  name="password"
                  placeholder="Password"
                  data-cy="login-password-input"
                  inputSize="medium"
                />
                <FieldError<LoginForm> name="password" />

                <ForgotPasswordButton
                  type="button"
                  variant="link2"
                  size="small"
                  onClick={() => navigate(absoluteRoutes.forgotPassword.self)}
                  onKeyDown={(e) => e.preventDefault()}
                >
                  Forgot Password?
                </ForgotPasswordButton>
              </Field>
            </FormFieldStack>

            <SubmitButton type="submit" data-cy="login-submit">
              Log in
            </SubmitButton>
            <NetworkError error={networkError} data-cy="network-error" />
          </form>
        </FormProvider>

        <AlternativeAuthLabel>
          Don't have an account yet?
          <AlternativeAuthButton
            type="button"
            onClick={() => navigate(absoluteRoutes.signup.self)}
            data-cy="signup-email-btn"
          >
            Sign up
          </AlternativeAuthButton>
        </AlternativeAuthLabel>
      </ContentContainer>
    </Container>
  );
}
