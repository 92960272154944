import styled from "styled-components/macro";

import successAnimation from "src/assets/lottie-animations-data/success.json";

import Lottie from "src/components/common/media/Lottie";
import { Box } from "src/components/common/layout/Box.styled";
import { OnboardingStepTitle } from "src/components/features/Onboarding/onboarding.styled";

const Container = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export default function FinishStep() {
  return (
    <Container>
      <div style={{ width: 200, height: 200, overflow: "hidden" }}>
        <Lottie animationData={successAnimation} translate="-100px -60px" width={400} height={300} />
      </div>

      <OnboardingStepTitle marginTop={40}>
        How exciting! Peech is generating a lot of amazing video content for you
      </OnboardingStepTitle>
    </Container>
  );
}
