import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import SignUp from "src/components/auth/SignUp";
import useLocalStorage from "src/hooks/useLocalStorage";

export default function SignUpPage() {
  const [searchParams] = useSearchParams();
  const signupCampaign = searchParams.get("signupCampaign");
  const [allowAnyEmailFromStorage, setValue] = useLocalStorage("YWxsb3dBbnlFbWFpbA==", false); // base64 encoded string of "allowAnyEmail"
  const allowAnyEmailParams = searchParams.get("aAEmail"); // short for allowAnyEmail
  const allowAnyEmail = allowAnyEmailParams === null ? allowAnyEmailFromStorage : allowAnyEmailParams === "true";

  useEffect(() => {
    setValue(allowAnyEmail);
  }, [allowAnyEmail, setValue]);

  return <SignUp campaign={signupCampaign} allowAnyEmail={allowAnyEmail} />;
}
