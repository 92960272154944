export interface CropCords {
  topx: number;
  topy: number;
  width: number;
  height: number;
}

export interface CropDataDetail {
  crop: CropCords;
  draw: CropCords;
}

export interface CropDataItemSize {
  width: number;
  height: number;
}

export interface DragPosition {
  x: number;
  y: number;
  w: number | string;
  h: number | string;
}

export const getCropData = (itemRect: DOMRect, stageRect: DOMRect): CropCords => {
  const cropData: CropCords = {
    topx: 0,
    topy: 0,
    width: 1,
    height: 1,
  };
  const leftDiff = Math.round(itemRect.left - stageRect.left);
  const rightDiff = Math.round(itemRect.right - stageRect.right);
  const topDiff = Math.round(itemRect.top - stageRect.top);
  const bottomDiff = Math.round(itemRect.bottom - stageRect.bottom);

  if (leftDiff < 0) {
    cropData.topx = Math.abs(leftDiff) / itemRect.width;
    cropData.width = 1 - cropData.topx;
  }

  if (rightDiff > 0) {
    cropData.width -= rightDiff / itemRect.width;
  }

  if (topDiff < 0) {
    cropData.topy = Math.abs(topDiff) / itemRect.height;
    cropData.height = 1 - cropData.topy;
  }

  if (bottomDiff > 0) {
    cropData.height -= bottomDiff / itemRect.height;
  }

  return cropData;
};

export const getDrawData = (itemRect: DOMRect, stageRect: DOMRect): CropCords => {
  const drawData: CropCords = {
    topx: 0,
    topy: 0,
    width: 1,
    height: 1,
  };
  const leftDiff = Math.round(itemRect.left - stageRect.left);
  const rightDiff = Math.round(itemRect.right - stageRect.right);
  const topDiff = Math.round(itemRect.top - stageRect.top);
  const bottomDiff = Math.round(itemRect.bottom - stageRect.bottom);

  if (leftDiff > 0) {
    drawData.topx = leftDiff / stageRect.width;
    drawData.width = 1 - drawData.topx;
  }

  if (rightDiff < 0) {
    drawData.width -= Math.abs(rightDiff) / stageRect.width;
  }

  if (topDiff > 0) {
    drawData.topy = topDiff / stageRect.height;
    drawData.height = 1 - drawData.topy;
  }

  if (bottomDiff < 0) {
    drawData.height -= Math.abs(bottomDiff) / stageRect.height;
  }

  return drawData;
};

export const scaleLimitBy = (item: CropDataItemSize, stage: CropDataItemSize): "width" | "height" => {
  const itemAspectRatio = item.width / item.height;
  const stageAspectRatio = stage.width / stage.height;

  if (itemAspectRatio > stageAspectRatio) {
    return "height";
  }
  return "width";
};

export const getFillCropData = (item: CropDataItemSize, stage: CropDataItemSize, scale = 1): DragPosition => {
  const itemAspectRatio = item.width / item.height;
  const stageAspectRatio = stage.width / stage.height;

  // item new size
  let W;
  let H;

  if (itemAspectRatio > stageAspectRatio) {
    // Video's height is the limiting factor
    H = stage.height * scale;
    W = H * itemAspectRatio;
  } else {
    // Video's width is the limiting factor
    W = stage.width * scale;
    H = W / itemAspectRatio;
  }

  return {
    w: W,
    h: H,
    x: (stage.width - W) / 2,
    y: (stage.height - H) / 2,
  };
};

export const getFitCropData = (item: CropDataItemSize, stage: CropDataItemSize): DragPosition => {
  const itemAspectRatio = item.width / item.height;
  const stageAspectRatio = stage.width / stage.height;

  // item new size
  let W;
  let H;

  if (itemAspectRatio > stageAspectRatio) {
    // Video's height is the limiting factor
    W = stage.width;
    H = W / itemAspectRatio;
  } else {
    // Video's width is the limiting factor
    H = stage.height;
    W = H * itemAspectRatio;
  }

  return {
    w: W,
    h: H,
    x: (stage.width - W) / 2,
    y: (stage.height - H) / 2,
  };
};

export const getDraggablePosition = (
  cropData: CropDataDetail,
  item: CropDataItemSize,
  stage: CropDataItemSize,
): DragPosition => {
  const { crop, draw } = cropData;
  const scale = (stage.width * draw.width) / (stage.width * crop.width);

  const videoOriginRatio = item.height / item.width;
  const videoWidth = Math.round(stage.width * scale);
  const videoHeight = Math.round(stage.width * videoOriginRatio * scale);

  const dragPosition: DragPosition = {
    w: videoWidth,
    h: videoHeight,
    x: Math.round(crop.topx ? 0 - crop.topx * videoWidth : draw.topx * stage.width),
    y: Math.round(crop.topy ? 0 - crop.topy * videoHeight : draw.topy * stage.height),
  };

  return dragPosition;
};

export const getItemScaleRelativeToStage = (draggablePosition: DragPosition, stage: CropDataItemSize): number => {
  const { w, h } = draggablePosition;
  const W = parseFloat(w.toString());
  const H = parseFloat(h.toString());
  const videoAspectRatio = W / H;
  const viewportAspectRatio = stage.width / stage.height;

  if (videoAspectRatio > viewportAspectRatio) {
    // Video's height is the limiting factor
    return H / stage.height;
  }
  // Video's width is the limiting factor
  return W / stage.width;
};
