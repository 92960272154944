import { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";

import analyticsService from "src/services/Analytics.service";

export default function SequencePage() {
  const { sequenceSid } = useParams();

  useEffect(() => {
    analyticsService.setContextProps({ sequenceSid });

    return () => {
      analyticsService.resetContextProps({ sequenceSid });
    };
  }, [sequenceSid]);

  return <Outlet />;
}
