import { PropsWithChildren } from "react";
import styled from "styled-components/macro";

import { themeColor } from "src/utils/styledComponents.utils";

import { useAppConfig } from "src/components/providers/AppConfigProvider";
import { Text6 } from "src/components/common/layout/typography.styled";
import { Button } from "src/components/common/buttons/Button.styled";
import { Center } from "src/components/common/layout/Box.styled";
import SplashScreen from "src/components/auth/common/LoginSignupLayout/SplashScreen";

const Container = styled.div`
  display: flex;
  flex: 1;
  background-color: ${themeColor("gray.50")};
`;

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 20px;
`;

const RightSide = styled(Center)`
  flex: 2;
  background-color: ${themeColor("pink.500")};
  text-align: center;

  * {
    color: ${themeColor("white")};
  }

  @media (max-width: 1200px) {
    display: none;
  }
`;

const CenterSmallText = styled(Text6)`
  padding-inline: 20px;
  text-align: center;

  div,
  ${Button} {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    min-height: unset;
  }
`;

export default function LoginSignupLayout({ children }: PropsWithChildren) {
  const { HOMEPAGE_URL } = useAppConfig();

  return (
    <Container>
      <LeftSide>
        {children}

        <CenterSmallText>
          <div>By creating an account, I declare that I have read and accepted</div>

          <div>
            Peech-Ai’s
            <Button
              onClick={() => {
                window.open(`${HOMEPAGE_URL}/terms-of-service`, "_blank");
              }}
              variant="link2"
              size="small"
            >
              Terms of Service
            </Button>
            And
            <Button
              onClick={() => {
                window.open(`${HOMEPAGE_URL}/privacy-policy`, "_blank");
              }}
              variant="link2"
              size="small"
            >
              Privacy Policy
            </Button>
          </div>
        </CenterSmallText>
      </LeftSide>

      <RightSide>
        <SplashScreen />
      </RightSide>
    </Container>
  );
}
