import { BrowserTracing } from "@sentry/tracing";
import * as Sentry from "@sentry/react";

import { APP_ENV } from "src/constants/env.constants";
import { AppConfig } from "src/components/providers/AppConfigProvider";
import { User } from "src/network/graphql/generatedGraphqlSDK";

import blockServiceForEnv from "src/services/blockServiceForEnv";
import BaseService from "src/services/Base.service";
import appMonitoringService from "src/services/AppMonitoring.service";

@blockServiceForEnv(["local"])
class ErrorMonitoringService implements BaseService {
  init(appConfig: AppConfig) {
    Sentry.init({
      dsn: appConfig.SENTRY_TOKEN,
      integrations: [new BrowserTracing()],
      tracesSampleRate: appConfig.SENTRY_TRACES_SAMPLE_RATE ?? 1.0,
      environment: APP_ENV,
    });
  }

  identify(user: User): void {
    Sentry.configureScope(async (scope) => {
      scope.setUser({
        id: user.sid!,
        firstName: user.firstName!,
        lastName: user.lastName!,
        email: user.email!,
        created: user.insertTime,
        environment: APP_ENV,
      });

      // move to init or trackError?
      scope.setExtra("sessionURL", await appMonitoringService.getSessionURL());
    });
  }

  async trackError(error: Error) {
    Sentry.captureException(error);
  }
}

const errorMonitoringService = new ErrorMonitoringService();

export default errorMonitoringService;
