import React, { useState, useRef, useEffect, useCallback, HTMLProps } from "react";
import styled from "styled-components/macro";
import tinycolor from "tinycolor2";
import Color from "color";

import Input from "src/components/common/form/inputs/Input.styled";

const InputStyled = styled(Input)`
  width: 100%;
  padding-block: 8px;
  text-align: center;
`;

export const isValidHex = (hex: string): boolean => {
  // disable hex4 and hex8
  const lh = String(hex).charAt(0) === "#" ? 1 : 0;
  return !!lh && hex.length !== 4 + lh && hex.length < 7 + lh && tinycolor(hex).isValid();
};

interface ColorInputProps
  extends Omit<HTMLProps<HTMLInputElement>, "color" | "onChange" | "value" | "ref" | "as" | "type"> {
  onChange(color: Color): void;
  color: Color;
}

export default function ColorInput({ color, onChange, ...props }: ColorInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [colorString, setColorString] = useState(color.hex().toString());

  const update = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>): void => {
      setColorString(evt.currentTarget.value);
      if (inputRef.current && isValidHex(inputRef.current.value)) {
        onChange(Color(inputRef.current.value));
      }
    },
    [onChange],
  );

  useEffect(() => {
    if (document.activeElement !== inputRef.current) {
      setColorString(color.hex().toString());
    }
  }, [color]);

  return (
    <InputStyled
      ref={inputRef}
      inputSize="medium"
      data-cy="color-input"
      value={colorString}
      onChange={update}
      {...props}
    />
  );
}
